import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

import OTP from 'components/OTP';


const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: saturate(180%) blur(10px);
    padding-bottom: 25px;
    margin-top: 70px;
  }
`;

const OTPModal = (
  {
    isOpen,
    phoneNumber,
    onComplete,
    onSuccess,
    onCancel,
    onPhoneNumberClick,
    cancelText,
  }) => {

  return (
    <Modal
      visible={isOpen}
      closable={false}
      maskStyle={{background: 'rgba(0, 0, 0, 0.15)'}}
      footer={null}
    >
      <OTP
        phoneNumber={phoneNumber}
        onCancel={onCancel}
        onComplete={onComplete}
        onSuccess={onSuccess}
        onPhoneNumberClick={onPhoneNumberClick}
        cancelText={cancelText}
      />
    </Modal>
  );
};

OTPModal.propTypes = {
  isOpen: PropTypes.bool,
  phoneNumber: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onPhoneNumberClick: PropTypes.func,
  cancelText: PropTypes.string,
};

export default OTPModal;