import { ProviderAPI } from '.';
import { FileAPI } from '.';

export const uploadFile = (signedUrl, file) => {
  const options = {
    method: 'PUT',
    body: file,
    headers: {
      'x-amz-acl': 'private',
      'Content-type': file.type,
    }
  };

  return fetch(signedUrl, options)
    .then(async response => {
      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      return response;
    });
};

export const uploadFileWithProgress = (signedUrl, file, setProgress, setSuccess) => {
  const xhr = new XMLHttpRequest();

  xhr.onerror = function() {
    throw new Error(`${xhr.status}: ${xhr.statusText}`);
  };

  xhr.upload.onprogress = function(event) {
    const progress = (event.loaded/event.total) * 100;
    setProgress(progress);
  };

  xhr.onloadend = function() {
    if (xhr.status === 200) {
      setSuccess(true);
    } else {
      throw new Error(`${xhr.status}: ${xhr.statusText}`);
    }
  };

  xhr.upload.onerror = function() {
    throw new Error(`Error during upload: ${xhr.status}: ${xhr.statusText}`);
  };

  xhr.open('PUT', signedUrl);
  xhr.setRequestHeader('Content-Type', file.type);
  xhr.setRequestHeader('x-amz-acl', 'private');
  xhr.send(file);

};

export const uploadProviderImage = async(providerUuid, file) => {
  const response = await ProviderAPI.getProviderImageUploadUrl(providerUuid, encodeURI(file.name), file.type);
  const {url, signed_url} = response.payload;
  return uploadFile(signed_url, file).then(() => url);
};

export const uploadListingImage = async(providerUuid, listingUuid, file) => {
  const response = await ProviderAPI.getListingImageUploadUrl(providerUuid, listingUuid, encodeURI(file.name), file.type);
  const {url, signed_url} = response.payload;
  await uploadFile(signed_url, file);
  return url;
};

export const uploadParticipantFile = async(userUuid, file, setProgress, setSuccess) => {
  const response = await FileAPI.getUploadUrl('participant', userUuid, file.name, file.type);
  const { signed_url } = response.payload;
  return uploadFileWithProgress(signed_url, file, setProgress, setSuccess);
};
