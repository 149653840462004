import React from 'react';

import QuestionsComponent from 'providerSite/components/LandingPage/Questions';


const Questions = () => {

  const questions = [
    {
      key: 1,
      header: 'Can Support Coordinators use Clickability Connect?',
      content: (
        <p>
          Yes! We specifically built Clickability Connect to optimise the workflow
          for Support Coordinators and LACs. We understand that you spend most of
          your time chasing providers and so we created a tool that allows you to
          connect to a dynamic and engaged provider base in real time
        </p>
      )
    },
    {
      key: 2,
      header: 'What details do we share with providers?',
      content: (
        <p>
          We will not share your clients’ personal details with the provider.
          We only share information that providers need to determine if they
          can provide a service, including the type of support needed, your clients’
          age range, and the location where the service is needed. You can also add
          more information if needed.
        </p>
      )
    },
    {
      key: 3,
      header: 'Can you guarantee that I’ll always get responses to my requests?',
      content: (
        <p>
          No – we’re dealing with the same market realities as everyone in the
          NDIS! But what we can guarantee is that we’ll get your requests in
          front of as many providers as possible in as short a time as possible.
          The best part is you and your participant are always in control: we find
          you the providers, but you choose who you want to go with.
        </p>
      )
    }
  ];

  return (
    <QuestionsComponent
      questions={questions}
      padding={false}
    />
  );
};


export default Questions;
