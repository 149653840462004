import ClaimMatchPage from './pages/ClaimMatchPage';
import React from 'react';
import { Route } from 'react-router-dom';

// modules return fragments containing routes.
// passing the component as a prop ensures match params are passed in props
// see https://stackoverflow.com/questions/46197178/cannot-read-property-params-of-undefined-react-router-4

const routes = () => {
  return [
    <Route key="/match/claim" exact path="/match/:uuid/:action" component={ClaimMatchPage} />,
  ];
};

export default routes;
