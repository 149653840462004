import Page from '../../../components/Page';
import PermissionDenied from '../../../components/PermissionDenied';
import React from 'react';
import { useSelector } from 'react-redux';

const withAdminOnly = Component => {
  const hoc = props => {
    const profile = useSelector(state => state.auth.profile);
    if (!profile || !profile.is_site_admin) {
      return (
        <Page title="Permission Denied">
          <PermissionDenied/>;
        </Page>
      );
    }
    return <Component {...props} profile={profile}/>;
  };
  hoc.displayName = 'withAdminOnly';
  return hoc;
};

export default withAdminOnly;
