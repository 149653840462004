import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import camelcaseKeys from 'camelcase-keys';

import style from 'style';
import Loader from 'components/Loader';
import BlogPost from 'components/BlogPost';
import { CmsAPI } from 'api';
import {
  SERVICE_CATEGORY_ALLIED_HEALTH,
  SERVICE_CATEGORY_CLEANING,
  SERVICE_CATEGORY_GARDENING,
  SERVICE_CATEGORY_PLAN_MANAGEMENT,
  SERVICE_CATEGORY_SUPPORT_COORDINATION,
  SERVICE_CATEGORY_SUPPORT_WORK
} from '../../constants';


const SERVICE_CATEGORY_TO_TAG_MAP = {
  [SERVICE_CATEGORY_ALLIED_HEALTH]: 'matching allied health',
  [SERVICE_CATEGORY_SUPPORT_COORDINATION]: 'matching support coordinator',
  [SERVICE_CATEGORY_SUPPORT_WORK]: 'matching support work',
  [SERVICE_CATEGORY_PLAN_MANAGEMENT]: 'matching plan management',
  [SERVICE_CATEGORY_CLEANING]: 'matching cleaning',
  [SERVICE_CATEGORY_GARDENING]: 'matching gardening',
};


const Container = styled.div`
  .title {
    margin-top: 90px;
    text-align: center;
    color: ${style.colors.palette.nightBlue};
    font-size: 20px;
    font-weight: 400;
  }
  
  .subtitle {
    text-align: center;
    font-size: 16px;
    color: ${style.colors.palette.battleshipGrey};
  }
  
  .post-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${style.sizes.large}) {
      flex-direction: column;
      row-gap: 40px;
      align-items: center;
    }
  }
`;

const BlogPosts = ({ serviceCategory }) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    CmsAPI.getBlogSearch('', SERVICE_CATEGORY_TO_TAG_MAP[serviceCategory], 3, 0)
      .then((response) => {
        setData(camelcaseKeys(response.payload, {deep: true}));
      });
  }, []);

  return (
    <Container>
      <div className="title">
        You might be interested in...
      </div>
      <div className="subtitle">
        Visit our blog for NDIS resources, participant stories, and more.
      </div>
      <div className="post-container">
        {!data.length && <Loader/>}
        {data.map((item, index) => (
          <BlogPost key={index} text={item.title} imageUrl={item.featuredImage} path={item.path}/>
        ))}
      </div>
    </Container>
  );
};

BlogPosts.propTypes = {
  serviceCategory: PropTypes.string.isRequired,
};


export default BlogPosts;

