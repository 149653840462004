import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { formatComponentId } from '../../lib/util.js';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// Must use 1.x versions of date-io:
// https://material-ui-pickers.dev/getting-started/installation
// https://github.com/mui-org/material-ui-pickers/issues/1440

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '1rem 0',
  },
  label: {
    width: '20%',
  },
  field: {
    flexGrow: 1,
    marginLeft: '1.5rem',
  },
});

// Converts input/output to ISO format
const DateInputField = ({label, value=null, onChange, ...dateFieldProps}) => {
  const classes = useStyles();
  const dateValue = (value === null) ? moment() : moment(value);
  const handleChange = event => {
    const isoValue = event.format();
    onChange(isoValue);
  };
  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          value={dateValue}
          onChange={handleChange}
          id={formatComponentId('text-input', label)}
          {...dateFieldProps}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

DateInputField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DateInputField;
