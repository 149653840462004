import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Tag } from 'antd';

import style from 'style';
import placeholderImage from 'assets/physiotherapist-and-boy-holding-ball-285x161.png';
import { NAV_SIZE_MIN, NAV_SIZE_MAX } from '../../constants';
import { useGetApi, ProviderAPI } from 'providerSite/api';


const Container = styled.div`
  display: flex;
  padding: 30px 20px 15px 20px;
  align-items: center;
  column-gap: 20px;
  ${({navSize}) => navSize === NAV_SIZE_MIN && css`
    padding-left: 10px;
    padding-right: 0;
  `};
  transition: all 0.4s;
`;

const LeftContainer = styled.div`
  .image {
    --image-width: ${({navSize}) => navSize === NAV_SIZE_MIN ? '45px' : '59px'};
    width: var(--image-width);
    height: var(--image-width);
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid ${style.colors.primary};
    box-shadow: rgb(0 36 72 / 25%) 0 0 4px 0, rgb(22 64 105 / 25%) 2px -2px 20px 0px;
    transition: all 0.4s;
  }
`;

const RightContainer = styled.div`
  max-width: 133px;
  overflow: hidden;
  .top {
    font-size: 11px;
    line-height: 11px;
    white-space: nowrap;
  }
  .bottom {
    font-size: 16px;
    font-weight: 500;
    max-height: 65px;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-tag {
    font-size: 11px;
    line-height: 14px;
  }
`;


const Header = ({ providerUuid, navSize }) => {

  const { data } = useGetApi(ProviderAPI.GET_PROVIDER, {params: {uuid: providerUuid}});
  const profile = useSelector(state => state.auth.profile);

  const imagePath = data?.imagePath ? data.imagePath : placeholderImage;

  return (
    <>
      <Container navSize={navSize} >
        <LeftContainer navSize={navSize}>
          <img className="image" src={imagePath} alt="Profile Image" />
        </LeftContainer>
        {navSize === NAV_SIZE_MAX && (
          <RightContainer>
            <div className="top">MY DASHBOARD</div>
            <div className="bottom">{data?.name}</div>
          </RightContainer>
        )}
      </Container>
      {profile?.is_site_admin && navSize === NAV_SIZE_MAX && (
        <StatusContainer>
          <Tag
            color={data?.hasConnectAccess ? 'green' : 'red'}
          >
            {data?.hasConnectAccess ? 'Connect Access' : 'No Connect Access'}
          </Tag>
        </StatusContainer>
      )}
    </>
  );
};

Header.propTypes = {
  providerUuid: PropTypes.string.isRequired,
  navSize: PropTypes.string.isRequired,
};


export default Header;

