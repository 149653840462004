import React from 'react';

import Page from 'components/Page.js';
import { brokerageFlow } from '../flows';
import BrokerageFormFlow from './BrokerageFormFlow';


const BrokerageFlow = () => {
  return (
    <Page title="Clickability Service Request">
      <BrokerageFormFlow flow={brokerageFlow} defaultsToLastValidPage />
    </Page>
  );
};

export default BrokerageFlow;