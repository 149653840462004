import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from 'components/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as track from 'lib/track';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1rem',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.common.darkSlateBlue,
    position: 'fixed',
    bottom: 0,
    zIndex: 999,
  },
  hidden: {
    display: 'none',
  },
  left: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  close: {
    border: `1px solid ${theme.palette.common.white}`,
    padding: '0.5rem',
  },
  icon: {
    color: theme.palette.common.white,
  },
  text: {
    padding: '0 1rem',
    maxWidth: '40rem',
    fontWeight: 700,
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
  button: {
    width: '10rem',
    backgroundColor: '#99F2BF',
    color: theme.palette.common.darkSlateBlue,
    borderRadius: '0.75rem',
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.25)',
    padding: '0.7rem 1rem',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    [theme.breakpoints.down('sm')]: {
      width: '14rem',
      padding: '0.5rem',
      fontSize: '1rem',
    },
  },
  team: {
    height: '7rem',
  },
}));

const BlogBrokerageCTA = () => {
  const classes = useStyles();
  const history = useHistory();
  const [rootClass, setRootClass] = useState(classes.root);

  const onClose = () => {
    setRootClass(classes.hidden);
  };

  const onClick = () => {
    track.sendEvent('Search page brokerage CTA button', 'click');
    history.push('/requestmatch');
  };

  return (
    <div className={rootClass}>
      <div className={classes.left}>
        <IconButton aria-label="close" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon}/>
        </IconButton>
        <Typography variant="h2" className={classes.text}>
          Save time. Let us connect you with the right providers.
        </Typography>
      </div>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        label="Get started"
        onClick={onClick}
      />
    </div>
  );
};

export default BlogBrokerageCTA;
