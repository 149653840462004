import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ImgCrop from 'antd-img-crop';
import styled from 'styled-components';
import { faTrash, faCloudArrowUp } from '@fortawesome/pro-duotone-svg-icons';
import { Upload, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from 'style';


const { Dragger: _Dragger } = Upload;

const Title = styled.div`
  color: ${style.colors.primary};
  font-weight: 500;
  margin-bottom: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  
  .ant-upload-list {
    display: none;
  }
`;

const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  margin-top: 10px;
  
  .icon-button {
    color: ${style.colors.primary};
    font-size: 20px;
    cursor: pointer;
    transition-duration: .2s;
    &:hover {
      color: ${style.colors.secondary};
    }
  }
`;

const Dragger = styled(_Dragger)`
  .ant-upload-drag-icon {
    margin: 20px;
  }
`;

const FileUpload = ({defaultValue, title, multiple, setFile, onDelete}) => {
  const [imageUrl, setImageUrl] = useState(defaultValue);

  const beforeUpload = (file) => {
    const objectUrl = URL.createObjectURL(file);
    setImageUrl(objectUrl);
    setFile(file);
  };

  const onDeleteHandler = () => {
    onDelete();
    setImageUrl('');
    setFile(null);
  };

  return (
    <Container>
      <Title>{title}</Title>
      <ImgCrop aspect={16/9}>
        <Dragger
          name='file'
          multiple={multiple}
          beforeUpload={beforeUpload}
        >
          {imageUrl ? <img src={imageUrl} alt='uploaded image' width="100%"/> : (
            <p className="ant-upload-drag-icon">
              <FontAwesomeIcon icon={faCloudArrowUp} size="3x"/>
            </p>
          )}
          <p className="ant-upload-hint">
            We recommend 1280x720 pixel JPG or PNG images for profile images.
          </p>
        </Dragger>
      </ImgCrop>
      <NotificationContainer>
        <Typography.Text>Click or drag file to the above area to upload</Typography.Text>
        <FontAwesomeIcon className="icon-button" icon={faTrash} onClick={onDeleteHandler}/>
      </NotificationContainer>
    </Container>
  );
};

FileUpload.propTypes = {
  defaultValue: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.string,
  multiple: PropTypes.bool,
  setFile: PropTypes.func,
  onDelete: PropTypes.func,
};


export default FileUpload;