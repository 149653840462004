import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  section: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: '1rem',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    section:{
      justifyContent: 'center',
    },
    icon: {
      margin: '0 .5rem',
    },
    shareText: {
      margin: 'auto',
    },
  },
}));

const ShareButtonPanel = () => {
  const classes = useStyles();
  const pageUrl = window.location.href;
  const twitterUrl = `https://twitter.com/share?url=${pageUrl}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`;
  const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`;
  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <a className={classes.link} href={facebookUrl} target="_blank" rel="noopener noreferrer">
          <IconButton aria-label="share-on-facebook" className={classes.iconButton} >
            <FacebookIcon className={classes.icon} fontSize="large"/>
          </IconButton>
        </a>
        <a className={classes.link} href={twitterUrl} target="_blank" rel="noopener noreferrer">
          <IconButton aria-label="share-on-twitter" className={classes.iconButton} >
            <TwitterIcon className={classes.icon} fontSize="large"/>
          </IconButton>
        </a>
        <a className={classes.link} href={linkedInUrl} target="_blank" rel="noopener noreferrer">
          <IconButton aria-label="share-on-linkedin" className={classes.iconButton} >
            <LinkedInIcon className={classes.icon} fontSize="large" />
          </IconButton>
        </a>
      </div>
      <Typography variant="body2" color="primary" className={classes.shareText}>
        <em>Share on social media</em>
      </Typography>
    </div>
  );
};

export default ShareButtonPanel;
