import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.battleshipGrey,
    textAlign: 'center',
  },
}));

const SubTitle = ({ text, className='' }) => {
  const classes = useStyles();
  return <Typography variant="h3" className={`${classes.root} ${className}`}>{text}</Typography>;
};

SubTitle.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

export default SubTitle;