import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useParams, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, notification } from 'antd';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';

import {
  COMMUNICATION_AIDS_FIELD,
  CONNECT_FIELD,
  CONTACT_METHOD_FIELD,
  EMAIL_FIELD,
  FIRST_NAME_FIELD,
  INTERPRETER_REQUIRED_FIELD,
  LANGUAGE_FIELD,
  LAST_NAME_FIELD,
  LOCATION_FIELD,
  NDIS_PLAN_FIELD,
  PARTICIPANT_AGE_RANGE_FIELD,
  PARTICIPANT_FIRST_NAME_FIELD,
  PARTICIPANT_LAST_NAME_FIELD,
  PHONE_FIELD,
  RELATIONSHIP_FIELD,
  SERVICE_FOR_TYPE_MY_CLIENT,
  SERVICE_FOR_TYPE_MYSELF,
  SERVICES_FOR_FIELD,
  PLAN_START_DATE_FIELD,
  NDIS_PLAN_PERIOD_FIELD,
  TELEHEALTH_FIELD,
  ONLINE_SERVICE_FIELD,
  PREFERRED_CONTACT_METHOD_PHONE_CALL,
  RELATION_SUPPORT_COORDINATOR, SERVICE_REQUEST_ANSWERS_FIELD,
} from 'modules/brokerageFlow/constants';
import {
  useGetApi,
  useMutateApi,
  AuthAPI,
  ProviderAPI,
  ServiceRequestAPI as NewServiceRequestAPI,
} from 'providerSite/api';
import Spinner from 'components/Spinner';
import {
  FIELD_AGE_GROUP,
  FIELD_COMMUNICATION_AIDS,
  FIELD_CONTACT_PARTICIPANT,
  FIELD_FIRST_NAME,
  FIELD_INTERPRETER_REQUIRED,
  FIELD_LANGUAGES,
  FIELD_LAST_NAME,
  FIELD_LOCATION,
  FIELD_PLAN_TYPE,
  FIELD_PLAN_START_DATE,
  FIELD_PLAN_DURATION, FIELD_TELEHEALTH, FIELD_ONLINE_SERVICE,
} from '../../../components/ParticipantDetailsForm/constants';
import styled from 'styled-components';
import ServiceSpecificQuestions from 'modules/brokerageFlow/Dashboard/questions/ServiceSpecificQuestions';
import { ServiceRequestAPI } from 'api';


const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px 50px;
  
  .header-title {
    font-size: 30px;  
    margin-bottom: 30px;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;


const AdditionalQuestionFlow = ({ participantData, serviceData }) => {

  const { uuid: providerUuid } = useParams();
  const [serviceInquiryUuid, setServiceInquiryUuid] = useState();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState();

  const updateMutation = useMutateApi(NewServiceRequestAPI.PATCH_SERVICE_INQUIRY, 'PATCH', {
    params: {
      serviceInquiryUuid,
    },
    onSuccess: () => {
      history.push('connect');
      notification.success({
        message: 'Service Request created successfully!',
        description: 'It might take a few moments to show-up on your dashboard.',
        duration: 10,
        placement: 'bottomLeft',
      });
    },
  });

  const { data: _profile } = useGetApi(AuthAPI.GET_PROFILE, {
    staleTime: 60,
  });

  const profile = _profile?.profile;

  const { data: provider } = useGetApi(ProviderAPI.GET_PROVIDER, {
    params: {uuid: providerUuid},
    staleTime: 60,
  });

  const [serviceAnswers, setServiceAnswers] = useState({});

  const setServiceAnswer = key => value => {
    setServiceAnswers(prevState => ({...prevState, [key]: value}));
  };

  const participantFirstName = participantData[FIELD_FIRST_NAME];
  const participantLastName = participantData[FIELD_LAST_NAME];

  const shouldContactParticipant =
    participantData[FIELD_CONTACT_PARTICIPANT].toLowerCase() === 'participant';

  const planStartDate = participantData?.[FIELD_PLAN_START_DATE];

  const dataForUpdatingServiceRequest = {
    [RELATIONSHIP_FIELD]: !shouldContactParticipant && RELATION_SUPPORT_COORDINATOR,
    [LAST_NAME_FIELD]: shouldContactParticipant ? participantLastName : profile?.lastName,
    [CONTACT_METHOD_FIELD]: PREFERRED_CONTACT_METHOD_PHONE_CALL,
    [PARTICIPANT_LAST_NAME_FIELD]: participantLastName,
    [LANGUAGE_FIELD]: participantData?.[FIELD_LANGUAGES].join(', '),
    [INTERPRETER_REQUIRED_FIELD]: participantData[FIELD_INTERPRETER_REQUIRED],
    [COMMUNICATION_AIDS_FIELD]: participantData?.[FIELD_COMMUNICATION_AIDS],
    [PLAN_START_DATE_FIELD]: planStartDate && dayjs(planStartDate).format('DD/MM/YYYY'),
    [NDIS_PLAN_PERIOD_FIELD]: participantData?.[FIELD_PLAN_DURATION],
    [TELEHEALTH_FIELD]: participantData?.[FIELD_TELEHEALTH],
    [ONLINE_SERVICE_FIELD]: participantData?.data?.[FIELD_ONLINE_SERVICE],
    [SERVICE_REQUEST_ANSWERS_FIELD]: serviceAnswers,
  };

  useEffect(() => {
    if (!serviceInquiryUuid || !isLoading) return;

    updateMutation.mutate(dataForUpdatingServiceRequest);
  }, [serviceInquiryUuid, isLoading]);

  if (!profile || !provider)
    return <Spinner />;

  // Handles creating service request after passing the OTP verification
  const handleCreateServiceRequest = () => {
    setIsLoading(true);
    return ServiceRequestAPI.createRequest(
      snakecaseKeys({...dataForCreatingServiceRequest, otp: null}, {deep: true}))
      .then(res => camelcaseKeys(res, {deep: true}))
      .then(res => setServiceInquiryUuid(res.payload.serviceInquiryUuid));
  };

  // Formatted data for sending it via API to create initial SR
  const dataForCreatingServiceRequest = {
    services: [{
      id: serviceData.serviceId,
      name: serviceData.serviceName,
      qualifiedServiceCategory: serviceData.serviceCategoryName,
    }],
    [EMAIL_FIELD]: shouldContactParticipant ? participantData.email : profile.email,
    [PHONE_FIELD]: shouldContactParticipant ? participantData.phone : profile.contactPhone,
    [LOCATION_FIELD]: participantData[FIELD_LOCATION],
    [NDIS_PLAN_FIELD]: participantData[FIELD_PLAN_TYPE],
    [PARTICIPANT_AGE_RANGE_FIELD]: participantData[FIELD_AGE_GROUP],
    [SERVICES_FOR_FIELD]: shouldContactParticipant ?
      SERVICE_FOR_TYPE_MYSELF : SERVICE_FOR_TYPE_MY_CLIENT,
    [FIRST_NAME_FIELD]: shouldContactParticipant ? participantFirstName : profile.firstName,
    [PARTICIPANT_FIRST_NAME_FIELD]: participantFirstName,
    [CONNECT_FIELD]: {
      connectMode: true,
      participantClientUuid: participantData.uuid,
    },
  };

  return (
    <Container>
      <div className="header-title">
        Additional Questions
      </div>
      <QuestionContainer>
        <ServiceSpecificQuestions
          categoryName={serviceData.serviceCategoryName}
          setAnswer={setServiceAnswer}
        />
      </QuestionContainer>
      <Button
        type="primary"
        onClick={handleCreateServiceRequest}
        loading={isLoading}
        style={{
          marginTop: '40px',
          height: '40px',
          width: '100%',
          maxWidth: '300px',
          borderRadius: '12px',
        }}
      >
        Submit
      </Button>
    </Container>
  );
};

AdditionalQuestionFlow.propTypes = {
  participantData: PropTypes.object,
  serviceData: PropTypes.object,
};

export default AdditionalQuestionFlow;