import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    padding: '2rem 1rem',
    '& h1': {
      padding: '0 0 1rem 0',
    },
    '& h2': {
      padding: '2rem 0 0 0',
    },
    '& h3': {
      padding: '1rem 0 0 0',
    },
    '& p': {
      padding: '0 0 1rem 0',
    },
    '& p strong': {
      marginTop: '2rem',
    },
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  title: {
    fontWeight: 600,
    padding: '1rem 0 2rem 0',
  },
});

const ContentContainer = ({children, title=null, maxWidth='lg'}) => {
  const classes = useStyles();
  return (
    <Container maxWidth={maxWidth} fixed disableGutters={true} className={classes.root}>
      {title &&
        <Typography variant="h1" color="primary" className={classes.title}>
          {title}
        </Typography>
      }
      {children}
    </Container>
  );
};

ContentContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default ContentContainer;
