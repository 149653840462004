import React  from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';

import style from 'style';


const Container = styled.div`
  position: relative;
  .input {
    border: 1px solid ${style.colors.secondary};
    border-radius: ${style.borderRadius.medium};
    padding-left: 10px;
    padding-right: 30px;
    font-size: 13px;
    height: 28px;
    outline: none;
    min-width: 250px;
    transition: min-width 0.3s;
    :focus {
      border-color: ${style.colors.primary};
      min-width: 350px;
    }
    :hover {
      border-color: ${style.colors.primary};
    }
  }
  .icon {
    position: absolute;
    right: 7px;
    top: 7px;
    font-size: 14px;
    color: ${style.colors.palette.battleshipGrey}
  }
`;


const Search = ({ placeholder, onSearch }) => {

  const debouncedSearch = _.debounce(onSearch, 700);

  return (
    <Container>
      <input
        className="input"
        placeholder={placeholder}
        onChange={e => debouncedSearch(e.target.value)}
      />
      <FontAwesomeIcon className="icon" icon={faMagnifyingGlass} />
    </Container>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};


export default Search;

