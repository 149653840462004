import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ProfilePage from './pages/ProfilePage.js';
import ProviderPage from './pages/ProviderPage';
import { getProductDashboardUrl } from 'providerSite/components/Navbar/components/utils';

// modules return fragments containing routes.
// passing the component as a prop ensures match params are passed in props
// see https://stackoverflow.com/questions/46197178/cannot-read-property-params-of-undefined-react-router-4

const ListingRedirect = ({history, match: {params: {listing_uuid}}}) => {
  history.replace('/listing/' + listing_uuid);
};

const DashboardRedirect = ({ history }) => {
  const { isAuthenticated, profile } = useSelector(state => state.auth);

  if (!isAuthenticated) {
    history.push('/providers/login', { redirectTo: '/provider/dashboard' });
  }

  let redirectedUrl = '/profile';
  if (profile.roles) {
    let hasConnectAccess = false;
    let hasAccreditationAccess = false;

    for (let role of profile.roles) {
      hasConnectAccess = hasConnectAccess || role.has_connect_access;
      hasAccreditationAccess = hasAccreditationAccess || role.has_accreditation_access;
    }

    if ( hasAccreditationAccess && hasConnectAccess ) {
      redirectedUrl = '/profile';
    } else if ( hasAccreditationAccess ) {
      redirectedUrl = getProductDashboardUrl('accreditation', profile);
    } else if ( hasConnectAccess ) {
      redirectedUrl = getProductDashboardUrl('connect', profile);
    }
  }
  history.push(redirectedUrl);
};

const routes = () => {
  return [
    <Route key="/provider/listing" exact path="/provider/:provider_uuid/listing/:listing_uuid" render={ListingRedirect} />,
    <Route key="/provider/dashboard" exact path="/provider/dashboard" component={DashboardRedirect}/>,
    <Route key="/provider" exact path="/provider/:uuid" component={ProviderPage}/>,
    <Route key="/provider/old" exact path="/provider/old/:uuid" component={ProfilePage}/>,
  ];
};

export default routes;
