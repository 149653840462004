import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import camelcaseKeys from 'camelcase-keys';
import {makeStyles} from '@material-ui/core/styles';

import Page from 'components/Page.js';
import {viewModeBrokerageFlow} from '../flows';
import FormFlow from 'components/formflow/FormFlow';
import {AuthStore, TaxonomyStore} from 'store';
import {ServiceRequestAPI} from 'api';
import {
  PAGE_COMMUNICATION,
  PAGE_CONTACT_INFO,
  PAGE_DISABILITY_INFO,
  PAGE_NDIS_INFO,
  PAGE_PARTICIPANT_DETAILS
} from '../constants';
import Loader from 'components/Loader';
import withAdminOnly from '../../siteadmin/components/AdminOnly';


const useStyles = makeStyles({
  loaderContainer: {
    marginTop: '35%'
  },
});

const BrokerageFlowViewMode = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [completedPages, setCompletedPages] = useState(new Set());
  const [data, setData] = useState();
  const [serviceRequestStatusData, setServiceRequestStatusData] = useState({});
  const _ageGroups = useSelector(state => state.taxonomy.ageGroups);

  useEffect(() => {
    dispatch(TaxonomyStore.refreshTaxonomy(TaxonomyStore.QUALIFIED_SERVICE_TAXONOMY));
    dispatch(TaxonomyStore.refreshTaxonomy(TaxonomyStore.AGE_GROUP_TAXONOMY));
    dispatch(AuthStore.refreshProfile());

    const ageGroups = _ageGroups?.map(item => item.name);

    ServiceRequestAPI.getServiceInquiry(uuid).then(r => {
      const dataFromAPI = camelcaseKeys(r.payload, {deep: true});
      const { answerData, completedServicePages, serviceRequestStatusMap } = loadData(dataFromAPI);
      answerData['_ageGroups'] = ageGroups;
      setData(answerData);
      setCompletedPages(
        new Set([
          ...completedServicePages,
          PAGE_CONTACT_INFO,
          PAGE_PARTICIPANT_DETAILS,
          PAGE_COMMUNICATION,
          PAGE_NDIS_INFO,
          PAGE_DISABILITY_INFO
        ]));
      setServiceRequestStatusData(serviceRequestStatusMap);
    });
  }, [uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = payloadData => {
    const {
      _matchedServices,
      _servicesNeeded,
      _completedServicePages,
      _serviceRequestStatusMap,
    } = payloadData.serviceRequests.reduce((acc, item) => {
      acc._matchedServices.push({id: item.serviceId, name: item.serviceName, categoryName: item.categoryName});
      acc._servicesNeeded.push({id: item.serviceId, name: item.serviceName});
      acc._completedServicePages.push(item.serviceName.replaceAll(' ', '-').toLowerCase());
      acc._serviceRequestStatusMap = {...acc._serviceRequestStatusMap, [item.serviceName]: item.status};
      return acc;
    }, {_matchedServices: [], _servicesNeeded: [], _completedServicePages: [], _serviceRequestStatusMap: {}});


    const _qualifiedServicesData = {matchedServices: _matchedServices, unMatchedServices: []};

    const _serviceAnswers = camelcaseKeys(
      payloadData.serviceRequests.reduce((acc, item) => {
        const _answers = Object.keys(item.data).reduce((_acc, ansKey) => {
          return {..._acc, [`${item.serviceName}_${ansKey}`]: item.data[ansKey]};
        }, {});
        return {...acc, ..._answers};
      }, {}),
      {deep: true}
    );

    const _answerData = {
      ...payloadData,
      ...payloadData.data,
      ..._serviceAnswers,
      _qualifiedServices: _qualifiedServicesData,
      servicesNeeded: _servicesNeeded
    };

    delete _answerData['data'];
    delete _answerData['serviceRequests'];

    return {
      answerData: _answerData,
      completedServicePages: _completedServicePages,
      serviceRequestStatusMap: _serviceRequestStatusMap
    };
  };

  if (!data)
    return <div className={classes.loaderContainer}><Loader /></div>;

  return (
    <Page title="Clickability Service Request">
      <FormFlow
        flow={viewModeBrokerageFlow} onComplete={() => {}}
        completedPages={completedPages}
        setCompletedPages={setCompletedPages}
        defaultsToLastValidPage={true}
        forcedDefaults={data}
        isViewMode={true}
        serviceRequestStatusData={serviceRequestStatusData}
      />
    </Page>
  );
};

export default withAdminOnly(BrokerageFlowViewMode);