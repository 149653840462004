import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import style from 'style';
import Container from 'providerSite/components/Container';


const Main = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  background: url(${({ img }) => img}) rgb(18, 20, 64, 70%);
  background-blend-mode: overlay;
  background-size: cover;
  @media (max-width: ${style.screens.mobile}) {
    height: 400px;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .title {
    color: ${style.colors.palette.white};
    margin-bottom: 0;
    line-height: 38px;
  }
`;

const Bottom = ({children, img}) => {
  return (
    <Main img={img}>
      <Container size="medium" className="container">
        {children}
      </Container>
    </Main>
  );
};

Bottom.propTypes = {
  children: PropTypes.node,
  img: PropTypes.string
};


export default Bottom;
