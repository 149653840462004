import * as Abn from '../lib/abn';
import * as EmailValidator from 'email-validator';

import { AuthAPI } from '../api';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Validators take a value, a setter for an error message, and a flag to indicate if empty is allowed.
// The return value is true if validation succeeded, false if validation failed.

//----- email -----

const isEmpty = value => value === '' || value === undefined || value === null;

export const validateEmail = (value, setError, allowEmpty=false) => {
  if (isEmpty(value)) {
    if (!allowEmpty) {
      setError('Email cannot be empty.');
      return false;
    }
  } else if (!EmailValidator.validate(value)) {
    setError('This doesn\'t look like an email address.');
    return false;
  }
  setError();
  return true;
};

export const validateProviderEmail = async (value, setError, allowEmpty=false) => {
  if (!validateEmail(value, setError, allowEmpty)) return false;
  if (!isEmpty(value)) {
    const response = await AuthAPI.providerAvailable({email: value});
    if (!response.payload.email) {
      setError('An account already exists for that email address.');
      return false;
    }
  }
  setError();
  return true;
};

export const validateParticipantEmail = async (value, setError, allowEmpty=false) => {
  if (!validateEmail(value, setError, allowEmpty)) return false;
  if (!isEmpty(value)) {
    const response = await AuthAPI.available({email: value});
    if (!response.payload.email) {
      setError('An account already exists for that email address.');
      return false;
    }
  }
  setError();
  return true;
};

//----- phone number -----

export const validatePhoneNumber = (value, setError, allowEmpty=false) => {
  if (isEmpty(value)) {
    if (!allowEmpty) {
      setError('Phone number is required.');
      return false;
    }
  } else {
    const phoneNumber = parsePhoneNumberFromString(value, 'AU');
    if (!phoneNumber || !phoneNumber.isValid()) {
      setError('That doesn\'t look like a valid telephone number.');
      return false;
    }
  }
  setError();
  return true;
};

//----- username -----

export const validateUsername = (value, setError, allowEmpty=false) => {
  if (isEmpty(value)) {
    if (!allowEmpty) {
      setError('Username cannot be empty.');
      return false;
    }
  } else if (value.match(/^ /)) {
    setError('Username cannot begin with a space');
    return false;
  } else if (value.match(/ $/)) {
    setError('Username cannot end with a space.');
    return false;
  } else if (!value.match(/^[a-zA-Z0-9.\-@_+]+$/)) {
    setError('Username can only contain letters, numbers and \'-\', \'_\' and \'@\'.');
    return false;
  }
  setError();
  return true;
};

export const validateProviderUsername = async (value, setError, allowEmpty=false) => {
  if (!validateUsername(value, setError, allowEmpty)) return false;
  if (!isEmpty(value)) {
    const response = await AuthAPI.providerAvailable({username: value});
    if (!response.payload.username) {
      setError('That username is not available.');
      return false;
    }
  }
  setError();
  return true;
};

export const validateParticipantUsername = async (value, setError, allowEmpty=false) => {
  if (!validateUsername(value, setError, allowEmpty)) return false;
  if (!isEmpty(value)) {
    const response = await AuthAPI.available({username: value});
    if (!response.payload.username) {
      setError('That username is not available.');
      return false;
    }
  }
  setError();
  return true;
};

//----- password -----

export const validatePassword = (value, setError, allowEmpty=false) => {
  if (isEmpty(value)) {
    if (!allowEmpty) {
      setError('Password cannot be empty.');
      return false;
    }
  } else if (value.length < 8) {
    setError('Password must have at least 8 characters.');
    return false;
  } else if (
    !value.match(/[0-9]/) ||
    !value.match(/[A-Z]/) ||
    !value.match(/[a-z]/)) {
    setError('Password must have lower-case, upper-case and digits.');
    return false;
  }
  setError();
  return true;
};

//---- abn ----

export const validateAbn = (value, setError, allowEmpty) => {
  if (isEmpty(value)) {
    if (!allowEmpty) {
      setError('ABN is required.');
      return false;
    }
  } else {
    const message = Abn.validateAbn(value);
    if (message) {
      setError(message);
      return false;
    }
  }
  setError();
  return true;
};

export const formatAbn = (value) => {
  return isEmpty(value) ? value : Abn.formatAbn(value);
};

//---- names ----

export const validateFirstName = (value, setError, allowEmpty) => {
  if (isEmpty(value)) {
    if (!allowEmpty) {
      setError('First name is required.');
      return false;
    }
  }
  setError();
  return true;
};

export const validateLastName = (value, setError, allowEmpty=false) => {
  if (isEmpty(value)) {
    if (!allowEmpty) {
      setError('Last name is required.');
      return false;
    }
  }
  setError();
  return true;
};

export const validateOrganisation = (value, setError, allowEmpty=false) => {
  if (isEmpty(value)) {
    if (!allowEmpty) {
      setError('Trading name is required.');
      return false;
    }
  }
  setError();
  return true;
};

export const validateEntityName = (value, setError, allowEmpty=false) => {
  if (isEmpty(value)) {
    if (!allowEmpty) {
      setError('Registered name is required.');
      return false;
    }
  }
  setError();
  return true;
};

export const validateLocation = (value, setError, allowEmpty=false) => {
  if (isEmpty(value)) {
    if (!allowEmpty) {
      setError('Suburb name is required.');
      return false;
    }
  }
  setError();
  return true;
};