import { CmsAPI } from '../api';

const SET_LOADING       = 'cms/SET_LOADING';
const SET_CONTENT       = 'cms/SET_CONTENT';
const INVALIDATE_CACHE  = 'cms/INVALIDATE_CACHE';
const CACHE_TTL = 30 * 60 * 1000;  // long cache, but cache is invalidated at app reload.

// state structure:
// {
//   lastLoaded: {
//     <path>: <set with Date.now()
//   },
//   pages: {
//     <path>: <payload.content from api>
//   }
// }
const initialState = {
  lastLoaded: {},
  pages: {},
};

// Action Creators

export function setLoading(path, time) {
  return {
    type: SET_LOADING,
    path,
    time,
  };
}

export function setContent(path, content) {
  return {
    type: SET_CONTENT,
    path: path,
    content: content,
  };
}

export function invalidateCache() {
  return {
    type: INVALIDATE_CACHE,
  };
}

// Async Action Creators

export function getPage(path, pageType) {
  return (dispatch, getState) => {
    const now = Date.now();
    const state = getState().cms;
    const lastLoaded = state.lastLoaded[path];
    if (lastLoaded === undefined || (now - lastLoaded) > CACHE_TTL) {
      dispatch(setLoading(path, now));
      CmsAPI.getPage(path, pageType).then((response) => dispatch(setContent(path, response.payload, now)));
    }
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
  case SET_LOADING:
    return {
      ...state,
      lastLoaded: {
        ...state.lastLoaded,
        [action.path]: action.time
      },
    };

  case SET_CONTENT:
    return {
      ...state,
      pages: {
        ...state.pages,
        [action.path]: action.content,
      },
    };

  case INVALIDATE_CACHE:
    return {
      ...state,
      lastLoaded: {},
    };

  default:
    return state;
  }
}
