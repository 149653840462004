import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '4rem',
    backgroundColor: '#d7f4f1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    fontSize: '26px',
  },
  cards: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  card: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    margin: '2rem',
    borderRadius: '12px',
    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 4px 0px, rgb(145 158 171 / 20%) 2px -2px 20px 0px',
  },
  cardTitle: {
    marginTop: '1rem',
    textAlign: 'center',
    color: '#168378',
    fontSize: '1.2rem',
    fontWeight: 410,
  },
  cardImg: {
    width: 72,
  },
  cardBody: {
    maxWidth: 200,
  },
}));

const CardList = ({title, data, className, id}) => {
  const classes = useStyles();

  return (
    <div id={id} className={classNames(classes.root, className)}>
      <Typography variant="h2" color="primary" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.cards}>
        {data.map(card =>
          <div key={card.title} className={classes.card}>
            <img
              src={card.image}
              className={classes.cardImg}
              style={{
                width: card.imageWidth && card.imageWidth,
                height: card.imageHeight && card.imageHeight,
              }}
              alt="Clickability"
            />
            <div className={classes.cardTitle}>
              {card.title}
            </div>
            <div className={classes.cardBody}>
              {card.body}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CardList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  id: PropTypes.string,
};


export default CardList;
