import React  from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';
import { SPECIAL_TIER_NICHE_AND_REGIONAL } from '../tiers';


const Container = styled.div`
  width: 100%;
  border-radius: 24px;
  padding: 20px;
  display: flex;
  column-gap: 40px;
  align-items: center;
  border: 1px solid #f4d18a;
  user-select: none;
  box-shadow: ${style.shadow(20)};
  color: ${style.colors.primary};
  background: #faf5eb;

  @media (max-width: ${style.sizes.medium}) {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    max-width: 350px;
  }
`;

const Header = styled.div`
  max-width: 250px;
  .name {
    font-size: 15px;
    line-height: 15px;
    font-weight: 700;
  }
  
  .sub-title {
    font-size: 11px;
    color: #6F6C90;
  }
`;

const LogoContainer = styled.div`
  height: 43px;
  width: 43px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E7E7E7;
`;

const DescriptionContainer = styled.div`
  font-size: 12px;
  width: 100%;
  color: #6F6C90;
  margin-top: 10px;
`;

const FeaturesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  max-width: 500px;
  margin-top: 10px;
  
  @media (max-width: ${style.sizes.medium}) {
    flex-direction: column;
  }
`;

const Body = styled.div`
  display: flex;
  column-gap: 20px;
  @media (max-width: ${style.sizes.medium}) {
    flex-direction: column;
  }
  .title {
    font-size: 13px;
    font-weight: 700;
    margin-top: 10px;
    width: 100%;
  }
`;

const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  
  .value {
    font-size: 40px;
    font-weight: 700;
    margin-right: 6px;
    line-height: 40px;
  }
  
  .monthly {
    font-size: 14px;
    font-weight: 500;
    color: #6F6C90;
  }
  
  .billing-cycle {
    font-size: 12px;
    font-weight: 400;
    margin-left: 2px;
    color: #6F6C90;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 100px;
  background: ${style.colors.palette.dustyOrange};
  color: ${style.colors.palette.white};
  font-size: 12px;
  font-weight: 700;
  max-width: 308px;
  margin-top: auto;
  padding: 0 15px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
  
  :hover {
    cursor: pointer;
    background: ${style.colors.secondary};
    color: ${style.colors.palette.white};
  }
  
  ${({disabled}) => disabled && css`
    background: rgba(154, 154, 154, 0.16);
    color: #a1a1a1;
    :hover {
      cursor: not-allowed;
      background: rgba(154, 154, 154, 0.16);
      color: #a1a1a1;
    }
  `};
`;


const SpecialTier = ({ disabled, onClick, buttonNode }) => {

  return (
    <Container>
      <Header>
        <div style={{display: 'flex', alignItems: 'center', columnGap: '10px'}}>
          <LogoContainer>
            <img
              src={SPECIAL_TIER_NICHE_AND_REGIONAL.icon}
              height="20px"
              width="20px"
              alt={SPECIAL_TIER_NICHE_AND_REGIONAL.name}
            />
          </LogoContainer>
          <div>
            <div className="sub-title">
              {SPECIAL_TIER_NICHE_AND_REGIONAL.subTitle}
            </div>
            <div className="name">
              {SPECIAL_TIER_NICHE_AND_REGIONAL.name}
            </div>
          </div>
        </div>
        <DescriptionContainer>
          {SPECIAL_TIER_NICHE_AND_REGIONAL.description}
        </DescriptionContainer>
      </Header>
      <Body>
        <div>
          <div className="title">
            Requirements
          </div>
          <FeaturesContainer>
            <Benefit text="Regional location"/>
            <Benefit text="Servicing niche services"/>
          </FeaturesContainer>
        </div>
        <div>
          <div className="title">
            Limitations
          </div>
          <FeaturesContainer>
            <Benefit warning text="Only one service request" />
            <Benefit warning text="List only a single service" />
          </FeaturesContainer>
        </div>
      </Body>
      <div style={{flexShrink: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <PriceContainer>
          <div>
            <span className="value">${SPECIAL_TIER_NICHE_AND_REGIONAL.price.quarterly}</span>
            <span className="monthly">/monthly</span>
          </div>
          <div className="billing-cycle">
            Billed ${SPECIAL_TIER_NICHE_AND_REGIONAL.billedPrice.quarterly} + GST {SPECIAL_TIER_NICHE_AND_REGIONAL.billedType.quarterly}
          </div>
        </PriceContainer>
        <Button disabled={disabled} onClick={() => !disabled && onClick()}>
          {buttonNode ? buttonNode : 'Get 30-Day Free Trial Now!'}
        </Button>
      </div>
    </Container>
  );
};

SpecialTier.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  buttonNode: PropTypes.node,
};

const BenefitContainer = styled.div`
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 2px 0;
  min-width: 165px;

  .icon {
    font-size: 16px;
    margin-right: 8px;
  }
`;

const Benefit = ({text, warning}) => {
  return (
    <BenefitContainer>
      <FontAwesomeIcon
        color={warning ? style.colors.warning : style.colors.secondary}
        className="icon"
        icon={warning ? faCircleExclamation : faCircleCheck}
      />
      {text}
    </BenefitContainer>
  );
};

Benefit.propTypes = {
  text: PropTypes.string,
  warning: PropTypes.bool,
};

export default SpecialTier;

