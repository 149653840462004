import React, {useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { TaxonomyStore } from 'store';
import Loader from 'components/Loader';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiAutocomplete-root': {
      width: '100%',
    },
  },
  input: {
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: theme.palette.common.paleGrey,
      paddingTop: 0,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 0,
    },
  },
}));

const LocationFilter = ({handleFilterClick, urlParam}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const taxonomyLanguages = useSelector(state => state.taxonomy.languages);
  const [loading, setLoading] = useState(true);

  const setLangFromUrlAction = 'setFromUrl';
  const selectedLangReducer = (state, action) => {
    switch (action.type) {
    case setLangFromUrlAction:
      return taxonomyLanguages.find(item => item.name === urlParam?.language);
    default:
      throw new Error('Invalid action provided');
    }
  };
  const [selectedLanguage, selectedLanguageDispatch] = useReducer(selectedLangReducer, null);

  useEffect(() => {
    !taxonomyLanguages.length && dispatch(TaxonomyStore.refreshTaxonomy(TaxonomyStore.LANGUAGE_TAXONOMY));
    selectedLanguageDispatch({type: setLangFromUrlAction});
    setLoading(false);
  }, [taxonomyLanguages.length, dispatch]);

  if (loading) {
    return (<Loader />);
  }

  return (
    <div className={classes.root}>
      <Autocomplete
        options={taxonomyLanguages}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.name === value.name}
        defaultValue={selectedLanguage}
        onChange={(event, value) => {
          value && handleFilterClick('language', value.name);
        }}
        renderInput={(params) => (
          <TextField
            className={classes.input}
            placeholder="Select a Language"
            variant="filled"
            {...params}
          />
        )}
      />
    </div>
  );
};

LocationFilter.propTypes = {
  handleFilterClick: PropTypes.func.isRequired,
  urlParam: PropTypes.object.isRequired,
};

export default LocationFilter;
