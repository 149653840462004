import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ExplanationText from './ExplanationText';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.paleGrey,
    padding: '10px 22px 40px 22px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentContainer: {
    position: 'relative',
    width: '100%',
    height: '400px',
    [theme.breakpoints.down('sm')]: {
      height: '550px',
    },
  },
  title: {
    color: theme.palette.primary.main,
  },
  slide: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  explanation: {
    height: '400px',
    backgroundColor: theme.palette.common.paleGrey,
    [theme.breakpoints.down('sm')]: {
      height: '550px',
    },
  },
  button: {
    position: 'relative',
    zIndex: '1',
    fontSize: '64px',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '42px',
    },
  },
  paginationContainer: {
    display: 'flex',
    columnGap: '10px',
  },
  paginationCircle: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    backgroundColor: theme.palette.common.darkSlateBlue,
    border: '2px solid',
    borderColor: theme.palette.common.darkSlateBlue,
  },
}));

const Slider = ({data, className}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [slide, setSlide] = useState(0);
  const [direction, setDirection] = useState('right');
  const [isMouseOver, setIsMouseOver] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const interval = setInterval(() => {
      if (isMobile)
        return;
      !isMouseOver && handleIncrease();
    }, 4 * 1000);
    return () => clearInterval(interval);
  }, [isMouseOver, isMobile]);   // eslint-disable-line react-hooks/exhaustive-deps

  const handleIncrease = () => {
    setDirection('right');
    setSlide(prevState => {
      if (prevState === data.length - 1) {
        return 0;
      }
      return prevState + 1;
    });
  };

  const handleDecrease = () => {
    setDirection('left');
    setSlide(prevState => {
      if (prevState === 0) {
        return data.length - 1;
      }
      return prevState - 1;
    });
  };

  if (!data || !data.length)
    return null;

  const handleEnter = () => setIsMouseOver(true);
  const handleLeave = () => setIsMouseOver(false);

  return (
    <div
      className={classNames(classes.root, className)}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <Container maxWidth="lg" fixed disableGutters={true} className={classes.container}>
        <FontAwesomeIcon icon={faChevronLeft} className={classes.button} onClick={handleDecrease} />
        <div className={classes.contentContainer}>
          {data.map((item, index) => (
            <Slide
              className={classes.slide}
              key={index}
              direction={direction}
              in={slide === index}
              mountOnEnter
              unmountOnExit
              timeout={{
                enter: 700,
                exit: 200,
              }}
              appear={false}
            >
              <div className={classes}>
                <ExplanationText
                  text={item.text}
                  image={{src: item.image}}
                  reverse
                  className={classes.explanation}
                />
              </div>
            </Slide>
          ))}
        </div>
        <FontAwesomeIcon icon={faChevronRight} className={classes.button} onClick={handleIncrease} />
      </Container>
      <div className={classes.paginationContainer}>
        {data.map((_, index) => (
          <div
            key={index}
            className={classes.paginationCircle}
            style={{backgroundColor: slide === index && 'white'}}
          />
        ))}
      </div>
    </div>
  );
};

Slider.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

export default Slider;
