import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  faFiles,
  faPeopleGroup,
  faWallet,
  faBriefcase,
  faChild,
  faChildReaching,
  faPerson,
  faPersonCane,
  faPersonWalking,
  faClock,
  faFaceThinking,
  faUserVneckHair,
  faUserGroup,
} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import Grow from '@material-ui/core/Grow';
import Lottie from 'lottie-react';

import MultiChoiceSelection from '../../components/MultiChoiceSelection';
import {
  AGE_RANGE_ADULTS,
  AGE_RANGE_CHILDREN,
  AGE_RANGE_EARLY_CHILDHOOD,
  AGE_RANGE_MATURE_AGE,
  AGE_RANGE_YOUNG_PEOPLE, NDIS_PLAN_FIELD, PARTICIPANT_AGE_RANGE_FIELD,
  PLAN_TYPE_AGENCY_MANAGED,
  PLAN_TYPE_NOT_WITH_NDIS,
  PLAN_TYPE_PLAN_MANAGED,
  PLAN_TYPE_SELF_MANAGED,
  PLAN_TYPE_UNSURE,
  PLAN_TYPE_WAITING_FOR_APPROVAL,
} from '../../../constants';
import animationAsset from 'assets/trimming-plant-leaves.json';


const AGE_RANGE_TYPES = {
  [AGE_RANGE_MATURE_AGE]: {
    icon: faPersonCane,
    style: {flexBasis: '40%'},
  },
  [AGE_RANGE_ADULTS]: {
    icon: faPersonWalking,
    style: {flexBasis: '40%'},
  },
  [AGE_RANGE_YOUNG_PEOPLE]: {
    icon: faPerson,
    style: {flexBasis: '20%'},
  },
  [AGE_RANGE_CHILDREN]: {
    icon: faChild,
    style: {flexBasis: '20%'},
  },
  [AGE_RANGE_EARLY_CHILDHOOD]: {
    icon: faChildReaching,
    style: {flexBasis: '20%'},
  },
};

const NDIS_PLAN_TYPES = {
  [PLAN_TYPE_PLAN_MANAGED]: {
    icon: faWallet,
    style: {flexBasis: '30%'},
  },
  [PLAN_TYPE_SELF_MANAGED]: {
    icon: faUserVneckHair,
    style: {flexBasis: '30%'},
  },
  [PLAN_TYPE_AGENCY_MANAGED]: {
    icon: faBriefcase,
    style: {flexBasis: '30%'},
  },
  [PLAN_TYPE_WAITING_FOR_APPROVAL]: {
    icon: faClock,
  },
  [PLAN_TYPE_NOT_WITH_NDIS]: {
    icon: faUserGroup,
  },
  [PLAN_TYPE_UNSURE]: {
    icon: faFaceThinking,
  },
};


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  width: 100%;
  opacity: ${({$isDomLoaded}) => $isDomLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  
  .lottie-container {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));
    overflow: hidden;
  }
`;

const FinalDetails = ({ value, onChange, scrollToFlowControlButton }) => {

  const [isDomLoaded, setIsDomLoaded] = useState(false);

  const handleOnChange = key => values => {
    onChange(value ? {...value, [key]: values[0]} : {});
  };

  return (
    <Container $isDomLoaded={isDomLoaded}>
      <div className="lottie-container">
        <Lottie
          loop
          animationData={animationAsset}
          onDOMLoaded={() => setIsDomLoaded(true)}
          style={{width: '350px', marginBottom: '-60px', marginTop: '-45px'}}
        />
      </div>
      <MultiChoiceSelection
        title="Particiapnts age range"
        icon={faPeopleGroup}
        data={AGE_RANGE_TYPES}
        selectedValues={value ? [value?.[PARTICIPANT_AGE_RANGE_FIELD]] : []}
        onChange={values => {
          scrollToFlowControlButton();
          handleOnChange(PARTICIPANT_AGE_RANGE_FIELD)(values);
        }}
        isSingleChoice={true}
      />
      <Grow in={!!value?.[PARTICIPANT_AGE_RANGE_FIELD]}>
        <div>
          <MultiChoiceSelection
            title="Who manages the NDIS plan"
            icon={faFiles}
            data={NDIS_PLAN_TYPES}
            selectedValues={value ? [value?.[NDIS_PLAN_FIELD]] : []}
            onChange={handleOnChange(NDIS_PLAN_FIELD)}
            isSingleChoice={true}
          />
        </div>
      </Grow>
    </Container>
  );
};

FinalDetails.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  scrollToFlowControlButton: PropTypes.func.isRequired,
};

export default FinalDetails;