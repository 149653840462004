import React from 'react';
import SearchPage from './pages/SearchPage.js';
import { Route } from 'react-router-dom';

// modules return fragments containing routes.
// passing the component as a prop ensures match params are passed in props
// see https://stackoverflow.com/questions/46197178/cannot-read-property-params-of-undefined-react-router-4

const routes = () => {
  return [
    <Route key="/search" exact path="/search" component={SearchPage} />,
  ];
};

export default routes;
