import { TaxonomyAPI } from '../api';

const SET_LOADING = 'taxonomy/SET_LOADING';
const SET_TAXONOMY = 'taxonomy/SET_TAXONOMY';
const INVALIDATE_CACHE = 'taxonomy/INVALIDATE_CACHE';
const CACHE_TTL = 60 * 60 * 1000;  // long cache, but cache is invalidated at app reload.

// list of all supported taxonomies
export const SERVICE_TAXONOMY = 'services';
export const SCHEME_TAXONOMY = 'schemes';
export const LOCATION_TAXONOMY = 'locations';
export const ACCESS_METHOD_TAXONOMY = 'accessMethods';
export const AGE_GROUP_TAXONOMY = 'ageGroups';
export const SPECIALISATION_TAXONOMY = 'specialisations';
export const LANGUAGE_TAXONOMY = 'languages';
export const QUALIFIED_SERVICE_TAXONOMY = 'qualifiedServices';

// map state field names to api taxonomy names
const apiMap = {
  [SERVICE_TAXONOMY]: 'service',
  [SCHEME_TAXONOMY]: 'scheme',
  [LOCATION_TAXONOMY]: 'location',
  [ACCESS_METHOD_TAXONOMY]: 'access_method',
  [AGE_GROUP_TAXONOMY]: 'age_group',
  [SPECIALISATION_TAXONOMY]: 'specialisation',
  [LANGUAGE_TAXONOMY]: 'language',
  [QUALIFIED_SERVICE_TAXONOMY]: 'qualified_service',
};

const initialState = {
  [SCHEME_TAXONOMY]: [],
  [SERVICE_TAXONOMY]: [],
  [LOCATION_TAXONOMY]: [],
  [ACCESS_METHOD_TAXONOMY]: [],
  [AGE_GROUP_TAXONOMY]: [],
  [SPECIALISATION_TAXONOMY]: [],
  [LANGUAGE_TAXONOMY]: [],
  [QUALIFIED_SERVICE_TAXONOMY]: [],
  lastLoaded: {},
};

// Action Creators

export function setLoading(name, time) {
  return {
    type: SET_LOADING,
    name,
    time,
  };
}

export function setTaxonomy(name, payload) {
  return {
    type: SET_TAXONOMY,
    name,
    payload,
  };
}

export function invalidateCache() {
  return {
    type: INVALIDATE_CACHE,
  };
}

// Async action Creators

export function refreshTaxonomy(name, force) {
  return async (dispatch, getState) => {
    const state = getState().taxonomy;
    const lastLoaded = state.lastLoaded[name];
    const now = Date.now();
    const age = now - lastLoaded;
    if (!force && age < CACHE_TTL) { // age might be NaN, which compares false
      return;
    }
    // We set lastLoaded here, before the request returns, so that
    // any additional requests that arrive while loading will not trigger more requests.
    dispatch(setLoading(name, now));
    try {
      const { payload } = await TaxonomyAPI.getTaxonomy(apiMap[name]);
      dispatch(setTaxonomy(name, payload));
    } catch (err) {
      dispatch(setLoading(name, undefined)); // needs loading.
    }
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case SET_LOADING:
    return {
      ...state,
      lastLoaded: { ...state.lastLoaded, [action.name]: action.time },
    };

  case SET_TAXONOMY:
    return {
      ...state,
      [action.name]: action.payload,
    };

  case INVALIDATE_CACHE:
    return {
      ...state,
      lastLoaded: {},
    };

  default:
    return state;
  }
}
