import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthAPI } from '../../api';
import ErrorPage from '../../components/ErrorPage';
import Loader from '../../components/Loader';
import Page from '../../components/Page';
import { decodeState } from '../../lib/cognito';
import { AuthStore } from '../../store';

// example error url:
// ?error_description=Already+found+an+entry+for+username+Google_104453901673205203973+&error=invalid_request#

const useStyles = makeStyles(theme => ({
  main: {
    paddingLeft: '4.4rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  text: {
    width: '60%',
    margin: '1rem 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

// If presignup fails (e.g. social login without providing an email address) we will get an error message in this format:
// PreSignUp failed with error An email address must be provided to signup to Clickability.
// We want to strip off the prefix.
const cleanErrorMessage = (message) => message?.replace(/^\S+ failed with error /g, '');

const OAuthLoginPage = ({dispatch, location}) => {
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const code = query.get('code');
  const errorDescription = query.get('error_description');
  const state = decodeState(query.get('state'));
  const error = query.get('error');
  const redirectTo = (state && state.redirectTo) || '/';
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState(cleanErrorMessage(errorDescription));

  const login = async (code) => {
    try {
      const redirectUrl = window.location.origin + window.location.pathname;
      const { payload } = await AuthAPI.oAuthLogin(code, redirectUrl);
      const { profile, id, token_ttl } = payload;
      dispatch(AuthStore.login(profile, id, token_ttl));
      history.replace(redirectTo);
    } catch (err) {
      console.error(err);   // eslint-disable-line no-console
      //setError('An error occurred logging in.  Please try again.');
      setErrorMessage(err.body ? err.body.message : 'Unable to verify login token');
    }
  };

  if (code) {
    login(code);
  }

  if (errorMessage) {
    return (
      <ErrorPage title="Authentication Error">
        <Typography variant="h1" color="primary" className={classes.text}>
          Whoops! We were not able to log you in.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          The error message was:
        </Typography>
        <Alert severity="error">
          <AlertTitle>{error}</AlertTitle>
          {errorMessage}
        </Alert>
      </ErrorPage>
    );
  }

  return (
    <Page title="Clickability Login">
      <Loader/>
    </Page>
  );
};

OAuthLoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect()(OAuthLoginPage);
