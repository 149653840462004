import React from 'react';
import ContentContainer from '../../components/ContentContainer';
import { useHistory, useLocation } from 'react-router-dom';
import AuthFormPage from './components/AuthFormPage';

const PasswordConfirmationPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { email } = location.state || {};
  if (email === undefined) {
    history.push('/password/recover');
  }

  return (
    <AuthFormPage title="Clickability Password Reset">
      <ContentContainer maxWidth='sm' title="Check Your Email To Complete Password Reset">
        <p>
          We&apos;ve sent an email to <b>{email}</b> with a link to reset your password.
        </p>
        <p>
          Please follow the instructions in the email to complete your password reset.
        </p>
      </ContentContainer>
    </AuthFormPage>
  );
};

export default PasswordConfirmationPage;
