import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

import useScript from '../../lib/hooks.js';
import config from '../../config';
import { BillingAPI } from '../../api';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import style from 'style';
import Spinner from 'components/Spinner';

// Operates in two distinct modes:
// If providerUuid is not passed or is null, creates a new braintree customer that will be used for
// validating the card payment method.  That customer should be passed along and attached to the provider at some point.
// If a providerUuid is passed, uses the braintree customer associated with the provider (creating one if necessary).
// In that case, the customer will already be already associated with the provider.

// Data returned from the payment gateway looks like this:
// {
//    "description" : "ending in 11",
//    "details" : {
//       "cardholderName" : "Jo Jo",
//       "cardType" : "Visa",
//       "expirationMonth" : "12",
//       "expirationYear" : "2032",
//       "bin" : "411111",
//       "lastFour" : "1111",
//       "lastTwo" : "11"
//    },
//    "nonce" : "tokencc_bj_jw9gn4_js3ngt_fz2kp3_zmxnny_pd7",
//    "binData" : {
//       "healthcare" : "Unknown",
//       "durbinRegulated" : "Unknown",
//       "countryOfIssuance" : "Unknown",
//       "payroll" : "Unknown",
//       "commercial" : "Unknown",
//       "issuingBank" : "Unknown",
//       "debit" : "Unknown",
//       "prepaid" : "Unknown",
//       "productId" : "Unknown"
//    },
//    "type" : "CreditCard"
// }

const useStyles = makeStyles({
  container: {
    minHeight: 300,
  }
});

const Button = styled(AntdButton)`
  width: 100%;
  height: 50px;
  max-width: 500px;
  background-color: ${style.colors.palette.lightSeaGreen};
  color: ${style.colors.palette.blackSlate};
  border-color: ${style.colors.lightBorder};
`;

const CardPayment = ({providerUuid=null, divId='card-input', footer, allowDelete=true, onComplete, buttonLabel='Continue', busy=false, disabled=false, className}) => {
  const [clientToken, setClientToken] = useState();
  const [customerId, setCustomerId] = useState();
  const [instance, setInstance] = useState();
  const [error, setError] = useState();
  const [cardBusy, setCardBusy] = useState();  // button shows busy if busy or cardBusy are true
  const [isInitiating, setIsInitiating] = useState(true);
  const classes = useStyles();

  useScript(config.settings.BRAINTREE_SCRIPT);

  useEffect(() => {
    const getClientToken = async () => {
      try {
        if (providerUuid === null) {
          const response = await BillingAPI.getClientToken({anonymous: false});
          setClientToken(response.payload.client_token);
          setCustomerId(response.payload.customer_id);
        } else {
          const response =  await BillingAPI.getProviderClientToken(providerUuid);
          setClientToken(response.payload.client_token);
        }
      } catch {
        setError('Unable to initialise payment gateway.');
      }
    };
    getClientToken();
  }, [providerUuid]);

  // see https://github.com/braintree/braintree-web-drop-in/commit/f2b4ce67e28a4816b74cbe8a247209d48ab34f84
  // for docs on preventDeletingPaymentMethodWithSubscription
  useEffect(() => {
    let inst;
    const createInstance = () => {
      window.braintree.dropin.create({
        authorization: clientToken,
        container: '#' + divId,
        vaultManager: allowDelete,
        card: {
          cardholderName: {
            required: true,
          }
        },
      }, function (createErr, instance) {
        inst = instance;
        if (createErr) {
          setError(JSON.stringify(createErr));  // never seen this happen
        } else {
          //instance.on('paymentMethodRequestable', console.log);
          setInstance(instance);
        }
      });
    };
    if (clientToken && window.braintree) {
      createInstance();
      setIsInitiating(false);
      return () => { if (inst) inst.teardown(); };
    }
  }, [clientToken, allowDelete, divId]);

  const submitPayment = () => {
    if (!instance) return;
    setError();
    setCardBusy(true);
    instance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
      if (requestPaymentMethodErr) {
        setError(requestPaymentMethodErr.message);
        setCardBusy(false);
      } else {
        onComplete({
          billing_payment_method_nonce: payload.nonce,
          billing_customer_id: customerId,
        });
        setCardBusy(false);
      }
    });
  };

  return (
    <div className={className}>
      {error && <Alert severity="error">{error}</Alert>}
      <div id={divId} className={classes.container}/>
      {isInitiating && <Spinner />}
      {footer}
      <Button
        type="primary"
        loading={busy || cardBusy}
        disabled={disabled || isInitiating}
        size="large"
        onClick={submitPayment}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

CardPayment.propTypes = {
  providerUuid: PropTypes.string,
  divId: PropTypes.string,
  allowDelete: PropTypes.bool,
  onError: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  footer: PropTypes.node,
  busy: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default CardPayment;
