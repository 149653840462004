import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0.5rem 0.5rem 0.5rem 0rem',
    display: 'flex',
    flexWrap: 'nowrap',
    alignContent: 'left',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    padding: 0,
    paddingLeft: '0px',
    fontSize: '0.9rem',
    color: theme.palette.primary.main,
  },
  list: {
    padding: '0.5rem',
    listStyleType: 'none',
    paddingInlineStart: 0,
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
  iconText: {
    marginLeft: '6px',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

// TODO: Add share functionality
const ShareIconButton = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const pageUrl = window.location.href;
  const twitterUrl = `https://twitter.com/share?url=${pageUrl}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`;
  const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`;

  return (
    <div className={classes.root}>
      <Popover
        id="share-links"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List className={classes.list}>
          <ListItem button>
            <a className={classes.link} href={facebookUrl} target="_blank" rel="noopener noreferrer">
              <IconButton aria-label="share-on-facebook" className={classes.iconButton} >
                <FacebookIcon className={classes.icon} />
                <Typography variant="body2" className={classes.iconText}>Facebook</Typography>
              </IconButton>
            </a>
          </ListItem>
          <ListItem button>
            <a className={classes.link} href={twitterUrl} target="_blank" rel="noopener noreferrer">
              <IconButton aria-label="share-on-twitter" className={classes.iconButton} >
                <TwitterIcon className={classes.icon} />
                <Typography variant="body2" className={classes.iconText}>Twitter</Typography>
              </IconButton>
            </a>
          </ListItem>
          <ListItem button>
            <a className={classes.link} href={linkedInUrl} target="_blank" rel="noopener noreferrer">
              <IconButton aria-label="share-on-linkedin" className={classes.iconButton} >
                <LinkedInIcon className={classes.icon} />
                <Typography variant="body2" className={classes.iconText}>LinkedIn</Typography>
              </IconButton>
            </a>
          </ListItem>
        </List>
      </Popover>
      <IconButton aria-label="share icon" onClick={handleClick}>
        <ShareIcon className={classes.icon}/>
      </IconButton>
      <Typography variant="caption" color="primary" className={classes.text}>Share</Typography>
    </div>
  );
};

export default ShareIconButton;
