import { useEffect } from 'react';

const scriptId = '_RECAPTCHA';
// default value for retURL is our own favicon, because it will be loaded and is small.

// cleanup when this component is unmounted.
const onUnmount = () => {
  const script = document.getElementById(scriptId);
  if (script) {
    script.parentNode.removeChild(script);
  }
};

// load the recaptcha script on mount
const onMount = () => {
  const script = document.createElement('script');
  script.id = scriptId;
  script.async = true;
  script.defer = true;
  script.src = 'https://www.google.com/recaptcha/api.js';
  document.body.appendChild(script);
  return onUnmount;
};

const RecaptchaProvider = ({children}) => {
  useEffect(() => onMount(), []);
  return children;
};

export default RecaptchaProvider;
