import React from 'react';
import PropTypes from 'prop-types';
import { ReviewAPI } from '../../api';
import { ReviewStore } from '../../store';
import { connect } from 'react-redux';
import ReviewCardList from './ReviewCardList.js';

const Reviews = ({data, reviews, isLoading, dispatch}) => {

  const fetchData = async () => {
    dispatch(ReviewStore.isLoading('reviewList', true));
    try {
      if (data.scope === 'provider') {
        const { payload } = await ReviewAPI.getProviderReviews(data.provider_uuid);
        dispatch(ReviewStore.setReviewList(payload, payload.length, payload.length)); // update API...
      } else {
        const { payload } = await ReviewAPI.getListingReviews(data.uuid);
        dispatch(ReviewStore.setReviewList(payload, payload.length, payload.length)); // update API...
      }
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  return (
    <React.Fragment>
      <ReviewCardList
        isLoading={isLoading}
        reviews={reviews}
        providerName={data.provider_name}
      />
    </React.Fragment>
  );

};

Reviews.propTypes = {
  data: PropTypes.object.isRequired,
  reviews: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    reviews: state.review.reviewList,
    isLoading: state.review.reviewListLoading,
  };
};

export default connect(mapStateToProps)(Reviews);
