import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.lightSeaGreen,
  },
  circleDeterminate: {
    strokeLinecap: 'round',
  },
  text: {
    color: theme.palette.common.white,
    fontSize: ({textSize}) => `${textSize}px`,
    fontWeight: '600',
  },
}));

const CircularProgressWithLabel = ({value, size=55, thickness=5, textSize=14}) => {
  const classes = useStyles({textSize: textSize});

  return (
    <Box className={classes.root} position="relative" display="inline-flex">
      <CircularProgress
        classes={{circleDeterminate: classes.circleDeterminate}}
        size={size}
        thickness={thickness}
        variant="determinate"
        color="inherit"
        value={value}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <div className={classes.text}>
          {`${Math.round(value)}%`}
        </div>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number,
  size: PropTypes.number,
  thickness: PropTypes.number,
  textSize: PropTypes.number,
};

export default CircularProgressWithLabel;