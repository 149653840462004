import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import dayjs from 'dayjs';
import camelcaseKeys from 'camelcase-keys';
import { makeStyles } from '@material-ui/core/styles';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';
import { ServiceRequestAPI } from 'api';
import FormFlow from 'components/formflow/FormFlow';
import flow from './flow';
import Spinner from 'components/Spinner';
import complete from 'assets/complete.json';
import Lottie from 'components/Lottie';


dayjs.extend(utc);
dayjs.extend(tz);


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    minHeight: '70vh',
    minWidth: '80vw',
    boxShadow: theme.shadows[5],
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '80vh',
      margin: '0 20px',
    },
    '&::-webkit-scrollbar': {
      width: '0',
      background: 'transparent',
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '13px',
    color: theme.palette.common.white,
    fontWeight: '500',
    background: theme.palette.common.nightBlue,
    padding: '15px 30px',
    width: '100%',
    borderBottom: '3px solid',
    borderBottomColor: theme.palette.common.dustyOrange,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
      padding: '8px 30px',
    },
  },
  container: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '20px',
    width: '100%',
    padding: '40px 30px 20px 30px',
  },
  errorContainer: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  button: {
    width: '200px',
    borderRadius: '10px',
  },
  errorText: {
    fontSize: '28px',
    fontWeight: '500',
    marginBottom: '10px',
    color: theme.palette.primary.main,
  },
}));


const ServiceRequestSurvey = () => {

  const location = useLocation();
  const urlParams = QueryString.parse(location.search);

  const shouldShowSurvey = 'survey' in urlParams && Object.keys(urlParams).length <= 2;

  if (!shouldShowSurvey) return null;

  const [data, setData] = useState();
  const [preFilledSelection, setPreFilledSelection] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(!!shouldShowSurvey);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const history = useHistory();

  const classes = useStyles({isLoading: isLoading});

  const surveyUuid = urlParams.survey;
  const preFilledParam = urlParams.contacted;

  useEffect(() => {
    setError(null);
    ServiceRequestAPI.getSurvey(surveyUuid)
      .then(r => camelcaseKeys(r.payload, {deep: true}))
      .then(r => {
        setData(r);
      })
      .catch(err => {
        const errBody = err?.body;
        setError(errBody ? errBody.status : 'other_error');
      }).finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    storePrefilledData();
  }, [data]);

  const date = data?.createdAt &&
    dayjs(data.createdAt).utc('z').tz().format('ddd, DD MMM YYYY');

  const handleExit = () => {
    setIsModalOpen(false);
    history.replace('');
  };


  const storePrefilledData = () => {
    const _data = {contactedProviders: {selection: []}};

    switch (preFilledParam) {
    case 'all': {
      const _allProviders = data?.acceptedProviders?.map(item => item.uuid);
      const allProviders = _allProviders ? _allProviders : [];
      _data.contactedProviders.selection.push(...allProviders);
      setPreFilledSelection(_data);
      break;
    }
    case 'none': {
      _data.contactedProviders.selection.push('none');
      setPreFilledSelection(_data);
      break;
    }
    default:
      setPreFilledSelection({});
    }
  };

  const renderFlow = () => (
    <FormFlow
      flow={flow}
      onComplete={handleExit}
      surveyUuid={surveyUuid}
      providerData={data?.acceptedProviders}
      forcedDefaults={preFilledSelection}
    />
  );

  const renderError = () => (
    <>
      {error === 'already_completed' ? (
        <div className={classes.errorContainer}>
          <Lottie animation={complete} width="300px" options={{loop: false}} />
          <div className={classes.errorText}>
            Thank you, your feedback has already been submitted.
          </div>
          If you need some help email&nbsp;
          <a href="mailto:hello@clickability.com.au">
            <strong>hello@clickability.com.au</strong>
          </a>.
        </div>
      ) : (
        <div className={classes.errorContainer}>
          <div className={classes.errorText}>
              Whoops! Looks like something went wrong<br/>
              with the feedback page you are looking for.
          </div>
            If you need some help email&nbsp;
          <a href="mailto:hello@clickability.com.au">
            <strong>hello@clickability.com.au</strong>
          </a>.
        </div>
      )}
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleExit}
      >
        Exit
      </Button>
    </>
  );

  return (
    <Modal
      isOpen={isModalOpen}
      modalClassName={classes.modal}
      paperClassName={classes.paper}
    >
      <div className={classes.header}>
        <div>Customer Feedback</div>
        {!isLoading && !error && (
          <div>
            {data?.serviceInquiry?.contactName} •&nbsp;
            {data?.serviceRequest?.serviceName} •&nbsp;
            {date}
          </div>
        )}
      </div>
      <div className={classes.container}>
        {isLoading ? <Spinner /> : (error ? renderError() : renderFlow())}
      </div>
    </Modal>
  );
};

export default ServiceRequestSurvey;
