import React from 'react';
import PropTypes from 'prop-types';

import Lottie from 'components/Lottie';
import spinner from 'assets/clickability-spinner.json';


const Spinner = ({ size='90px' }) => {

  return <Lottie animation={spinner} height={size} width={size} />;

};

Spinner.propTypes = {
  size: PropTypes.string,
};

export default Spinner;