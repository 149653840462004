import React  from 'react';
import PropTypes from 'prop-types';

import styled  from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';
import freeIcon from 'assets/free-icon.svg';


const Container = styled.div`
  width: 100%;
  border-radius: 24px;
  padding: 20px;
  display: flex;
  column-gap: 40px;
  align-items: center;
  border: 1px solid ${style.colors.lightBorder};
  user-select: none;
  box-shadow: ${style.shadow(20)};
  color: ${style.colors.primary};
  background: ${style.colors.palette.white};
  
  @media (max-width: ${style.sizes.medium}) {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    max-width: 350px;
  }
`;

const Header = styled.div`
  max-width: 250px;
  .name {
    font-size: 15px;
    line-height: 15px;
    font-weight: 700;
  }
  
  .sub-title {
    font-size: 11px;
    color: #6F6C90;
  }
`;

const LogoContainer = styled.div`
  height: 43px;
  width: 43px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E7E7E7;
`;

const DescriptionContainer = styled.div`
  font-size: 12px;
  width: 100%;
  color: #6F6C90;
  margin-top: 10px;
`;

const ExcludedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  max-width: 500px;
  margin-top: 10px;
  
  @media (max-width: ${style.sizes.medium}) {
    flex-direction: column;
  }
`;


const FreeTier = () => {

  return (
    <Container>
      <Header>
        <div style={{display: 'flex', alignItems: 'center', columnGap: '10px'}}>
          <LogoContainer>
            <img src={freeIcon} height="20px" width="20px" alt="accreditation-plus" />
          </LogoContainer>
          <div>
            <div className="sub-title">
              Limited
            </div>
            <div className="name">
              Free Listing
            </div>
          </div>
        </div>
        <DescriptionContainer>
          Create a free directory listing with targeting towards 200k population coverage
        </DescriptionContainer>
      </Header>
      <div>
        <div
          style={{
            fontSize: '13px',
            fontWeight: 700,
            marginTop: '10px',
            width: '100%',
          }}
        >
          What’s missing
        </div>
        <ExcludedContainer>
          <Benefit text="No service requests"  />
          <Benefit text="Connect only via email"  />
          <Benefit text="List only a single service"  />
          <Benefit text="Not prioritised in the directory"  />
        </ExcludedContainer>
      </div>
      <div
        style={{
          fontSize: '12px',
          whiteSpace: 'nowrap',
          marginTop: '5px',
          color: style.colors.palette.textGrey,
          textAlign: 'center',
        }}
      >
        Please select a plan above in order<br/>to get a free trial account
      </div>
    </Container>
  );
};

const BenefitContainer = styled.div`
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 2px 0;
  min-width: 165px;
  
  .icon {
    font-size: 16px;
    margin-right: 8px;
  }
`;

const Benefit = ({ text }) => {
  return (
    <BenefitContainer>
      <FontAwesomeIcon
        color={style.colors.error}
        className="icon"
        icon={faCircleXmark}
      />
      {text}
    </BenefitContainer>
  );
};

Benefit.propTypes = {
  text: PropTypes.string,
};

export default FreeTier;

