import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useCurrentWidth } from 'lib/hooks';

const useStyles = makeStyles(theme => ({
  pad: props => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: props.padWidth,
    height: '3.5rem',
    backgroundColor: theme.palette.secondary.light,
    zIndex: 0,
  }),
  stepper: {
    width: '100%',
    height: '3.5rem',
    padding: 0,
    paddingLeft: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.common.paleGrey,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  stepContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  step: {
    height: '3.5rem',
    padding: '0.5rem',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  completedStep: {
    backgroundColor: theme.palette.secondary.light,
  },
  activeStep: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '0 1rem 1rem 0',
  },
  number: {
    width: '2.2rem',
    height: '2.2rem',
    lineHeight: '2.2rem',
    marginRight: '1rem',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      marginRight: '0',
    },
  },
  stepText: {
    fontSize: '1rem',
    marginRight: '0.2rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  narrative: {
    width: '100%',
    padding: 0,
    paddingLeft: '1rem',
    marginBottom: '2.5rem',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  narrativeText: {
    fontSize: '1.3rem',
  },
}));

const ProgressBar = ({steps, activeStep}) => {

  const padWidth = currentWidth => {
    // The width of the left coloured padding must be calculated in JS
    // and update when window resize events fire. This is passed through props
    // to the style hook so the screen updates, avoiding gaps when rezising.
    if (currentWidth <= 1280) return 20;
    return (currentWidth-1280)/2 + 10;
  };

  const currentWidth = useCurrentWidth();
  const classes = useStyles({padWidth: padWidth(currentWidth)});

  const chooseContentClass = (i, activeStep) => {
    if (i < activeStep - 1) {
      return classNames(classes.step, classes.completedStep);
    }
    if (i === activeStep - 1) {
      return classNames(classes.step, classes.activeStep);
    }
    return classes.step;
  };

  return (
    <Fragment>
      <div>
        <div className={classes.pad} />
        <div className={classes.stepper}>
          <Container maxWidth="lg" disableGutters>
            <Grid container className={classes.stepContainer} spacing={0}>
              {steps.map((step, i) => {
                return (
                  <Fragment key={i}>
                    <div className={chooseContentClass(i, activeStep)}>
                      <Typography variant="subtitle2" align="center" className={classes.number}>
                        {step.number}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.stepText}>
                        {step.title}
                      </Typography>
                    </div>
                  </Fragment>
                );
              })}
            </Grid>
          </Container>
        </div>
      </div>
      <div className={classes.narrative}>
        <Container maxWidth="lg" disableGutters>
          <Grid container spacing={0}>
            <Typography variant="body2" className={classes.narrativeText}>
              {steps[activeStep-1].narrative}
            </Typography>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );

};

ProgressBar.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default ProgressBar;
