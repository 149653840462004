import * as track from 'lib/track';

import { CmsAPI, ProviderAPI } from 'api';
import React, { useEffect, useState } from 'react';
import StarRating, {ReviewCount} from 'components/widgets';

import { ADVERTISEMENT_FEATURE } from 'lib/advertisement';
import BlogBrokerageCTA from 'components/cta/BlogBrokerageCTA';
import BlogLinkList from './components/BlogLinkList';
import BlogListBlurb from './components/BlogListBlurb';
import BlogPostContent from './components/BlogPostContent';
import BlogPostTitle from './components/BlogPostTitle';
import BlogRelatedList from './components/BlogRelatedList';
import BlogSearchBar from './components/BlogSearchBar';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MetaTags from 'components/MetaTags';
import Page from 'components/Page';
import PropTypes from 'prop-types';
import SearchBrokerageCTA from 'components/cta/SearchBrokerageCTA';
import ShareButtonPanel from 'components/widgets/ShareButtonPanel';
import Typography from '@material-ui/core/Typography';
import config from 'config';
import icon from '../../assets/logo-icon.svg';
import img from '../../assets/physiotherapist-and-boy-holding-ball-455x256.png';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  leftColumn: {
    padding: '1rem 3rem 4rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem 1rem 2rem',
    },
  },
  rightColumn: {
    padding: '12rem 0 4rem 2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
    },
  },
  sponsoredProvider: {
    border: '2px solid #264F73',
    boxShadow:  theme.shadow(20),
    borderRadius: '14px',
    backgroundColor: theme.palette.common.white,
    width: 'auto',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  serviceImage: {
    width: '100%',
    height: 'auto',
    borderRadius: '14px 14px 0 0',
  },
  serviceDetail: {
    width: '100%',
    padding: '20px',
    position: 'relative',
  },
  ratingContainer: {
    display: 'flex',
    columnGap: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px',
  },
  reviewCount: {
    fontSize: '12px',
    fontWeight: '500',
    marginTop: '3px',
  },
  rating: {
    fontSize: '12px',
    fontWeight: '500',
  },
  title: {
    width: '80%',
    margin: 'auto',
    fontSize: '1.2rem',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  descriptionText: {
    WebkitLineClamp: 3,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    padding: '0rem 0.5rem 0.2rem 1.0rem',
    flexGrow: 1,
  },
  icon:{
    position: 'absolute',
    top: '15px',
    right: '15px',
    height: '1.5rem',
  }

}));


const BlogPostPage = ({match}) => {
  const history = useHistory();
  const { slug } = match.params;

  // for SEO reasons we are guaranteeing a trailing slash on the path,
  // as it was on the original wordpress site.  We recognise the path
  // without the trailing / but always canonicalise it to the trailing /

  const path = '/blog/' + slug + '/';
  if (match.url !== path) {
    history.push(path);
  }
  const [content, setContent] = useState();
  const [recent, setRecent] = useState([]);
  const [sponsoredService, setSponsoredService] = useState({});

  useEffect(() => {
    CmsAPI.getBlogPage(path).then(
      content => {
        setContent(content.payload);
        window.scrollTo(0, 0);
      }).catch(
      err => {
        if (err.status === 404) {
          history.replace('/404-page-not-found');
        } else {
          throw err;
        }
      });
  }, [path]);

  useEffect(() => {
    if (content?.uuid) {
      ProviderAPI.getBlogFeaturedServices(
        ADVERTISEMENT_FEATURE.SPONSORED_BLOG,
        content.uuid
      ).then(
        response => setSponsoredService(response.payload.service)
      ).catch (err => {
        if (err.status !== 404) {  // we expect 404 errors if the blog is not sponsored
          throw err;
        }
      });
    }
  }, [content?.uuid]);

  useEffect(() => {
    CmsAPI.getBlogRecent().then((content) => setRecent(content.payload));
  }, []);

  useEffect(() => {
    track.sendEvent('blog post', 'view', {slug: slug});
  }, [slug]);

  const onSearch = (query) => {
    history.push({ pathname: '/blog/search', search: '?query='+encodeURI(query)});
  };

  const classes = useStyles();

  return (
    <Page title={content ? content.title : 'Clickability Blog'}>
      {content && <MetaTags
        article
        title={content.title}
        description={content.leader}
        image={config.website.URL + content.featured_image}
        url={config.website.URL + content.path}
      />}
      <BlogBrokerageCTA />
      <Container maxWidth="lg" fixed disableGutters={true}>
        <Grid container spacing={0}>
          <Grid item md={8} sm={12}>
            <div className={classes.leftColumn}>
              {content && <BlogPostTitle content={content}/>}
              {content && <BlogPostContent content={content}/>}
              {content && <SearchBrokerageCTA />}
              {content && <ShareButtonPanel />}
              {content && content.related.length > 0 && <BlogRelatedList related={content.related}/>}
            </div>
          </Grid>
          <Grid item md={4} sm={12}>
            <div className={classes.rightColumn}>
              <BlogSearchBar onSubmit={onSearch}/>
              {Object.keys(sponsoredService).length !== 0 && (
                <div key={sponsoredService.uuid} className={classes.sponsoredProvider} onClick={() => history.push(sponsoredService.url)}>
                  <img
                    src={sponsoredService.image_path || img}
                    alt={sponsoredService.title}
                    className={classes.serviceImage}
                  />
                  <div className={classes.serviceDetail}>
                    {sponsoredService.accredited && <img src={icon} className={classes.icon} alt="icon" width="24" height="24"/>}
                    <Typography variant="h6" color="primary" align='center' className={classes.title}>{sponsoredService.title}</Typography>
                    <div className={classes.ratingContainer}>
                      <StarRating rating={sponsoredService.rating}/>
                      <ReviewCount count={sponsoredService.review_count} />
                    </div>
                    <Typography variant="body2" align='center' className={classes.descriptionText}>{sponsoredService.short_description}</Typography>
                  </div>
                </div>
              )}
              <BlogLinkList title="Recent posts" content={recent} />
              <Divider variant="middle" />
              <BlogListBlurb />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

BlogPostPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
  })
};

export default BlogPostPage;
