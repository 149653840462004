import React from 'react';
import PropTypes from 'prop-types';
import SiteDownPage from './modules/pages/SiteDown';

/*
 * This is a wrapper that provides a conditional site-down page.
 * If down=true, the site will show a site-down page for all users
 * visiting the normal url (clickability.com.au)
 * However users visiting clickability-prod.com.au (or clickability-test.com.au)
 * will get access to the site.
 */

const SiteDown = ({children, down}) => {
  const isDev = window.location.href.match(/^https:\/\/clickability-(prod|test)\./);
  const siteDown = down && !isDev;
  return siteDown ? <SiteDownPage/> : children;
};

SiteDown.propTypes = {
  children: PropTypes.node.isRequired,
  down: PropTypes.bool.isRequired,
};

export default SiteDown;
