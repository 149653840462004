import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Button as AntdButton } from 'antd';

import style from 'style';
import _Container from 'providerSite/components/Container';
import _InputWithButton from 'providerSite/components/InputWithButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { currentProductUrl } from '../Navbar/components/utils';


const Main = styled.div`
  position: relative;
  height: 650px;
  background-color: ${style.colors.primary};
  display: flex;
  justify-content: center;
  overflow: hidden;
  
  .image-layer {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 50vw;
    top: 0;
    background-image: url(${({img}) => img});
    background-size: contain;
    background-repeat: no-repeat;
    
    // Custom size to keep the background image as contain as long as possible, 
    // then make it cover.
    ${({cover}) => cover && css`
      @media (min-width: 1750px) {
        background-size: cover;
        width: 50%;
      }
    `}
  }
  
  @media (max-width: ${style.screens.tab}) {
    height: 500px;
    background: linear-gradient(35deg, rgba(5,14,64,1) 0%, rgba(22,17,48,1) 48%, 
    rgba(25,25,25,1) 100%);
    
    .image-layer {
      display: none; 
    }
  }
`;

const Container = styled(_Container)`
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 50px;
  @media (max-width: ${style.screens.tab}){
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

const Text = styled.div`
  color: ${style.colors.palette.white};
  font-weight: 400;
  font-size: 48px;
  max-width: 600px;
  .underline {
    text-decoration: underline;
    text-decoration-color: ${style.colors.secondary};
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
  }
`;

const InputWithButton = styled(_InputWithButton)`
  
  .ant-input {
    background: ${style.colors.palette.white};
    color: ${style.colors.palette.black};
  }
  
  @media (max-width: ${style.screens.tab}){
    justify-content: center;
  }
`;

const IconWithButton = styled(AntdButton)`
  margin-top: 30px;
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  line-height: normal;
  height: 45px;
`;

const Hero = (
  {
    text,
    img,
    useIconBtn = false,
    useImageCover = true,
    buttonText='GET STARTED'
  }) => {
  const history = useHistory();
  const inputRef = useRef(null);
  const goal = currentProductUrl() === '/connect' ? 'connect' : 'growth';

  const handleSignupPush = params => {
    history.push('/business/signup', { goal: goal, ...params });
  };

  const onSubmitHandler = () => {
    const inputEl = inputRef.current;
    handleSignupPush({ email : inputEl.input?.value });
  };

  return (
    <Main img={img} cover={useImageCover}>
      <div className="image-layer" />
      <Container flex>
        <LeftContainer>
          <Text>
            {text}
          </Text>
          {useIconBtn ? (
            <IconWithButton
              type="primary"
              size="large"
              onClick={() => handleSignupPush({})}
            >
              {buttonText}
              <FontAwesomeIcon icon={faAngleRight} color={style.colors.palette.white}/>
            </IconWithButton>
          ) : (
            <InputWithButton
              text={buttonText}
              inputProps={{placeholder: 'Your email address', ref: inputRef}}
              onSubmit={onSubmitHandler}
            />
          ) }
        </LeftContainer>
      </Container>
    </Main>
  );
};

Hero.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  img: PropTypes.string,
  useIconBtn: PropTypes.bool,
  useImageCover: PropTypes.bool,
  buttonText: PropTypes.string,
};

export default Hero;
