import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import { CmsAPI } from 'api';
import SuggestedServices from 'components/suggestions/SuggestedServices';
import Page from 'components/Page.js';
import UserFeaturesCTA from 'components/widgets/UserFeaturesCTA';
import * as track from 'lib/track';
import { prettyDate} from 'lib/time';
import PaginationController from '../admin/components/Match/MatchingDashboard/PaginationController';
import BlogList, { BlogListSkeleton } from './components/BlogList.js';
import BlogLinkList from './components/BlogLinkList.js';
import BlogSearchBar from './components/BlogSearchBar.js';
import BlogListBlurb from './components/BlogListBlurb.js';


const PAGE_SIZE = 10;

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: '4rem',
  },
  searchRow: {
    padding: '4rem 6rem 0 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem 1rem 2rem',
    },
  },
  leftColumn: {
    padding: '1rem 3rem 4rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem 1rem 2rem',
    },
  },
  rightColumn: {
    padding: '0 0 4rem 2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
    },
  },
}));

const BlogListPage = ({location}) => {

  const history = useHistory();
  const [paginationMetaData, setPaginationMetaData] = useState({
    page: 0,
    count: 0,
    hasPrevPage: false,
    hasNextPage: true,
    pageSize: PAGE_SIZE
  });
  const [blogList, setBlogList] = useState([]);
  const [recent, setRecent] = useState([]);
  const [busy, setBusy] = useState(false);

  const pageCount = Math.floor((paginationMetaData.count + PAGE_SIZE - 1) / PAGE_SIZE);
  const urlParams = new URLSearchParams(location.search);
  let pageNumber = Math.max(urlParams.get('page'), 1);
  // only enforce the upper limit if we have a non-zero page count.
  if (pageCount > 0 && pageNumber > pageCount) {
    pageNumber = pageCount;
  }

  useEffect(() => {
    CmsAPI.getBlogRecent().then((content) => setRecent(content.payload));
  }, []);

  useEffect(() => {
    const offset = (pageNumber - 1) * PAGE_SIZE;
    setBusy(true);
    CmsAPI.getBlogRecent(PAGE_SIZE, offset).then((response) => {
      setPaginationMetaData({
        count: response.total,
        page: pageNumber,
        pageSize: PAGE_SIZE,
        hasPrevPage: pageNumber > 1,
        hasNextPage: pageNumber * PAGE_SIZE <= response.total,
      });
      setBlogList(response.payload);
    }).finally(() => setBusy(false));
    track.sendEvent('blog list', 'view', {page_number: pageNumber});
  }, [pageNumber]);

  const setPage = (pageNo) => {
    history.push({ pathname: '/blog', search: '?page='+pageNo});
    window.scrollTo(0, 0);
  };

  const onSearch = (query) => {
    history.push({ pathname: '/blog/search', search: '?query='+encodeURI(query)});
  };

  const pagination = () => {
    return (
      <PaginationController
        data={paginationMetaData} onClick={setPage} />
    );
  };

  const title = (pageNumber === 1 || blogList.length === 0) ? 'Latest' : prettyDate(blogList[0].date);
  const classes = useStyles();
  return (
    <Page title="Clickability Blog">
      <Container maxWidth="lg" fixed disableGutters={true} className={classes.root}>
        <Grid container spacing={0}>
          <Grid item md={8} sm={12} xs={12} className={classes.searchRow}>
            <BlogSearchBar onSubmit={onSearch}/>
          </Grid>
          <Grid item md={8} sm={12}>
            <div className={classes.leftColumn}>
              {busy && paginationMetaData.count === 0 ?
                <BlogListSkeleton count={PAGE_SIZE}/> :
                <BlogList title={title} content={blogList} />
              }
              {pageCount > 1 && paginationMetaData.count > 1 && pagination()}
            </div>
          </Grid>
          <Grid item md={4} sm={12}>
            <div className={classes.rightColumn}>
              <UserFeaturesCTA />
              <SuggestedServices />
              <Divider variant="middle" />
              <BlogLinkList title="Recent posts" content={recent}/>
              <Divider variant="middle" />
              <BlogListBlurb />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

BlogListPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default BlogListPage;
