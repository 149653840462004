import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import InputContainer from './InputContainer';
import CustomOutlinedInput from './CustomOutlinedInput';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { formatComponentId } from 'lib/util.js';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '1.4rem',
  },
}));

const CurrencyInput = ({ label, value: initialValue, errorText, helperText, onChange, maximum=1000000, decimals=2 }) => {
  const classes = useStyles();
  const [validationActive, setValidationActive] = useState(false);
  const [value, setValue] = useState(initialValue);
  const componentId = formatComponentId('input', label);
  const error = validationActive && errorText !== undefined && errorText !== '';

  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value < maximum) return inputObj;
  };

  return (
    <InputContainer
      label={label}
      componentId={componentId}
      validationActive={validationActive}
      errorText={errorText}
      helperText={helperText}
    >
      <NumberFormat
        value={value}
        thousandSeparator={true}
        isAllowed={withValueLimit}
        decimalScale={decimals}
        customInput={CustomOutlinedInput}
        onValueChange={({ value: v }) => setValue(v)}
        onBlur={() => {
          onChange(value);
          setValidationActive(true);
        }}
        startAdornment={(
          <InputAdornment position="start">
            <AttachMoneyIcon className={classes.icon} />
          </InputAdornment>
        )}
        error={error}
      />
    </InputContainer>
  );

};

CurrencyInput.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.string,
  errorText: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  maximum: PropTypes.number,
  decimals: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default CurrencyInput;
