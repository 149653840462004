import styled from 'styled-components';
import style from '../../../style';

const List = styled.div`
  margin: 5px 0;
  display: flex;
  &::before {
    content: '◼';
    color: ${style.colors.secondary};
    vertical-align: -1px;
    margin-right: 10px;
  }
`;


export default List;