import React, { useState } from 'react';
import AuthForm from './AuthForm';
import AuthField from './AuthField';
import { AuthAPI } from '../../../api';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const DEFAULT_ERROR_MESSAGE = 'Error requesting password recovery: check your details and try again.';

const PasswordRecoveryForm = ({onComplete, isProvider, title}) => {
  const [recoveryError, setRecoveryError] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailWarning, setEmailWarning] = React.useState();
  const [busy, setBusy] = useState(false);

  const recover = async () => {
    setRecoveryError();
    if (!validateEmail(true)) return;
    setBusy(true);
    try {
      await AuthAPI.recoverPassword(email, isProvider);
      setBusy(false);
      onComplete(email);
    } catch (err) {
      setBusy(false);
      console.error(err);   // eslint-disable-line no-console
      const message = (err.body && err.body.message) ? err.body.message : DEFAULT_ERROR_MESSAGE;
      setRecoveryError(message);
    }
  };

  const validateEmail = (onSubmit=false) => {
    if (email === '' && onSubmit) {
      setEmailWarning('Email address is required');
      return false;
    }
    setEmailWarning();
    return true;
  };

  const disabled = emailWarning !== undefined;
  return (
    <AuthForm
      buttonLabel="Reset Password"
      buttonAction={recover}
      disabled={disabled}
      busy={busy}
      error={recoveryError}
    >
      <Typography variant="h1" color="primary">
        {title}
      </Typography>
      <AuthField
        id="email"
        label="Email Address"
        autoComplete="email"
        type="email"
        name="email"
        value={email}
        onChange={setEmail}
        error={emailWarning}
        onBlur={() => validateEmail()}
      />
    </AuthForm>
  );
};

PasswordRecoveryForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  isProvider: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default PasswordRecoveryForm;
