import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorPage from '../../components/ErrorPage';

const useStyles = makeStyles(theme => ({
  text: {
    width: '60%',
    margin: '1rem 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  email: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Error404 = () => {
  const classes = useStyles();
  return (
    <ErrorPage title="Clickability - Page not found">
      <Typography variant="h1" color="primary" className={classes.text}>
        Whoops! Seems we cant find the page you&apos;re looking for.
      </Typography>
      <Typography variant="body2" className={classes.text}>
        This page could be in the process of being updated or moved.
      </Typography>
    </ErrorPage>
  );
};

export default Error404;
