import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const CustomTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: '1px',
  },
  icon: {
    fontSize: '1.6rem',
    color: theme.palette.primary.main,
  },
}));

const Helper = ({ helperText }) => {
  const classes = useStyles();
  if (helperText===undefined) return null;
  const arrayValue = helperText.constructor === Array;

  const renderStringTooltip = () => {
    return (
      <Typography variant="body2">{helperText}</Typography>
    );
  };

  const renderArrayTooltip = () => {
    return (
      <React.Fragment>
        {helperText.map((v, i) => <Typography variant="body2" gutterBottom key={i}>{v}</Typography>)}
      </React.Fragment>
    );
  };

  return (
    <CustomTooltip
      arrow
      placement="bottom-end"
      title={arrayValue ? renderArrayTooltip() : renderStringTooltip()}
    >
      <IconButton
        aria-label="show help text"
        className={classes.iconButton}
      >
        <HelpIcon className={classes.icon}/>
      </IconButton>
    </CustomTooltip>
  );
};

Helper.propTypes = {
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

export default Helper;
