import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'lottie-react';

import Spinner from 'components/Spinner';
import SpinnerDone from 'components/SpinnerDone';
import { getHexWithOpacity } from 'lib/util';
import animationAsset from 'assets/mobile-otp.json';


const useStyles = makeStyles(theme => ({
  container: {
    padding: '30px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    height: '125px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputContainer: {
    display: 'flex',
    columnGap: '10px',
    justifyContent: 'center',
    marginBottom: '20px',
    '& ::selection': {
      background: 'none',
    }
  },
  input: {
    fontSize: '26px',
    textTransform: 'uppercase',
    width: '45px',
    height: '45px',
    textAlign: 'center',
    outline: 'none',
    border: '1px solid',
    background: 'none',
    borderColor: theme.palette.common.lightBorder,
    borderRadius: '12px',
    color: theme.palette.secondary.main,
    fontWeight: '700',
    '&:disabled': {
      color: theme.palette.grey[300],
      cursor: 'not-allowed',
    },
  },
  icon: {
    fontSize: '120px',
    color: theme.palette.common.nightBlue,
  },
  title: {
    fontSize: '28px',
    fontWeight: '600',
    marginTop: '20px',
  },
  subTitle: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  error: {
    marginBottom: '20px',
    color: theme.palette.error.main,
    fontWeight: '500',
  },
  button: {
    border: '1px solid',
    borderRadius: '6px',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[700],
    borderColor: theme.palette.grey[300],
    padding: '2px 10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.common.dustyOrange,
      color: theme.palette.grey[50],
      borderColor: getHexWithOpacity(theme.palette.common.dustyOrange, 50),
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[400],
      borderColor: theme.palette.grey[200],
      cursor: 'not-allowed',
    },
  },
  phoneNumber: {
    fontWeight: 700,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  lottie: {
    filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.4))',
  }
}));

const OTP = ({ phoneNumber, onComplete, onSuccess, onCancel, onPhoneNumberClick, cancelText }) => {

  const classes = useStyles();

  const inputRefs = [...Array(6)].map(() => useRef(null));

  const initData = () => [...Array(6)].map(() => '');

  const [data, setData] = useState(initData());
  const [isComplete, setIsComplete] = useState(false);
  const [error, setError] = useState();
  const [isSuccessful, setIsSuccessful] = useState(false);

  useEffect(() => {
    if (data.every(item => !!item.length && item !== '')) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
      setError(null);
    }
  }, [data]);

  useEffect(() => {
    if (isComplete) {
      onComplete(data.join('')).then(() => {
        setIsSuccessful(true);
      }).catch(err => {
        console.error(err);   // eslint-disable-line no-console
        setError(err.body?.message);
        setIsSuccessful(false);
        setIsComplete(false);
      });
    }
  }, [isComplete]);

  useEffect(() => {
    setData(initData());
    setIsComplete(false);
    setError(null);
    setIsSuccessful(false);
  }, []);

  const handleChange = (event, inputIndex) => {
    const value = event.target.value;

    setData(prevState => {
      if (value.length < 2) {
        prevState[inputIndex] = value;
      } else {
        value.split('').slice(0, prevState.length).forEach((char, index) => {
          prevState[index] = char;
        });
      }
      return [...prevState];
    });

    const nextInputRef = inputRefs[inputIndex+1];
    value && nextInputRef && nextInputRef.current && nextInputRef.current.focus();
  };

  const handleSelect = inputIndex => {
    const currentInputRef = inputRefs[inputIndex];
    currentInputRef && currentInputRef.current && currentInputRef.current.select();
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {isComplete ? (isSuccessful
          ? <SpinnerDone onComplete={onSuccess} /> : <Spinner />)
          : (
            <div className={classes.lottie}>
              <Lottie animationData={animationAsset} loop style={{height: '160px'}}/>
            </div>
          )}
      </div>
      <div className={classes.title}>
        SMS Verification
      </div>
      <div className={classes.subTitle}>
        Please enter the 6 digit verification code sent to&nbsp;
        <span className={classes.phoneNumber} onClick={onPhoneNumberClick}>{phoneNumber}</span>
      </div>
      <div className={classes.inputContainer}>
        {inputRefs.map((ref, index) => (
          <input
            key={index}
            type="text"
            className={classes.input}
            tabIndex={index}
            ref={ref}
            onChange={event => handleChange(event, index)}
            onClick={() => handleSelect(index)}
            onSelect={() => handleSelect(index)}
            value={data[index]}
            disabled={isComplete}
            autoFocus={index === 0}
          />
        ))}
      </div>
      <div className={classes.error}>{!!error && error}</div>
      <button
        className={classes.button}
        onClick={() => {
          setData(initData());
          onCancel();
        }}
        disabled={isComplete}
      >
        {cancelText ? cancelText : 'Cancel'}
      </button>
    </div>
  );
};

OTP.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onPhoneNumberClick: PropTypes.func,
  cancelText: PropTypes.string,
};

export default OTP;