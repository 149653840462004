import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import {Button, CircularProgress, Typography} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/pro-duotone-svg-icons';
import { Alert } from '@material-ui/lab';

import confettiImage from 'assets/confetti.png';
import { statuses } from './constants';
import { getHexWithOpacity } from 'lib/util';
import Service from 'modules/admin/components/Match/ServiceRequestMatching/Service';
import Funding from 'modules/admin/components/Match/ServiceRequestMatching/Funding';
import ParticipantInfo from 'modules/admin/components/Match/ServiceRequestMatching/ParticipantInfo';
import Modal from 'components/Modal';
import { useGetApi } from 'providerSite/api';
import { GET_AVAILABLE_SR_CREDIT } from 'providerSite/api/serviceRequest';
import CreditStats from 'components/CreditStats';
import { SERVICE_PLAN_MANAGEMENT } from 'lib/constants';
import ReserveModal from 'components/ReserveModal';
import { SERVICE_REQUEST_EVENT_ACCEPT } from '../../../components/constants';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '3rem 2rem',
  },
  rootClaimed: {
    backgroundColor: theme.palette.common.white,
    backgroundImage: `url(${confettiImage})`,
    backgroundSize: '75%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
  },
  matchSuccessTitle: {
    fontSize: '1.6rem',
    color: theme.palette.common.fauxTiffanyBlue,
    textAlign: 'center',
  },
  matchTitle: {
    fontSize: '1.6rem',
    color: theme.palette.common.dustyOrange,
    textAlign: 'center',
  },
  matchFooter: {
    marginTop: '1.5rem',
    fontSize: '1rem',
    textAlign: 'center',
    '&:nth-child(2)': {
      marginTop: '10rem',
    }
  },
  grow: {
    flexGrow: 1,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    marginTop: '20px',
  },
  loader: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  spinner: {
    position: 'absolute',
    left: '45%',
    top: '45%',
  },
  icon: {
    filter: 'none',
    marginRight: '0.3rem',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '15px',
    marginBottom: '32px',
    marginTop: '25px',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      rowGap: '15px',
    },
  },
  acceptButton: {
    backgroundColor: theme.palette.common.darkSlateBlue,
    color: theme.palette.common.white,
    width: '200px',
    height: '45px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: getHexWithOpacity(theme.palette.common.darkSlateBlue, 20),
    },
  },
  declineButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    width: '200px',
    height: '45px',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    '&:disabled': {
      backgroundColor: getHexWithOpacity(theme.palette.error.main, 20),
    },
  },
  progress: {
    color: theme.palette.common.white,
  },
  recaptchaContainer: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  directReqIcon: {
    color: theme.palette.common.squash,
  },
  directReqText: {
    fontWeight: '500',
  },
  directReqAlert: {
    marginTop: '20px',
  },
  paper: {
    backdropFilter: 'blur(20px)',
    boxShadow: theme.shadows[5],
    borderRadius: '12px',
    maxWidth: '600px',
    marginLeft: '200px',
    marginTop: '-200px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 30px',
    },
  },
  modalContainer: {
    padding: '5px 30px 30px 30px',
  },
  modalButtonContainer: {
    display: 'flex',
    marginTop: '30px',
    justifyContent: 'center',
  },
  modalHeader: {
    fontSize: '20px',
    fontWeight: '500',
    textShadow: '1px 1px 2px #dfdfdf',
    borderBottom: '1px solid #e2e2e2',
    padding: '20px 30px 15px 30px',
    color: theme.palette.warning.main,
    textAlign: 'center',
  },
  alertText: {
    fontWeight: '500',
  },
}));

const MESSAGES = {
  [statuses.ACCEPTED]: {
    success: true,
    title: 'Congratulations!',
    footer: 'You are 1 of 3 providers to have accepted this service request.' +
      ' Login to your dashboard to see the Participant’s contact details ' +
      'so you can call them.',
  },
  [statuses.DECLINED]: {
    success: false,
    title: 'Service Request Declined',
    footer: <>Sorry to hear this wasn&apos;t right for you. To modify the types of requests you receive, login to update your Service Offerings.</>,
  },
  [statuses.ALREADY_ACCEPTED]: {
    success: true,
    title: <>Service Request already accepted<br/>Now it&apos;s time to connect</>,
    footer: 'Login to see more information and reveal contact details. Your potential client has been told to expect your call within 2 business days.',
  },
  [statuses.ALREADY_DECLINED]: {
    success: false,
    title: 'Service Request already declined',
    footer: 'This request has already been declined by your organisation. For more information, login to see your Service Request Dashboard.',
  },
  [statuses.NOT_ACCREDITED]: {
    success: false,
    title: 'This feature is only available to Accredited Providers.',
    footer: 'Login to upgrade your account and become one of our Accredited providers.',
  },
  [statuses.INVALID_REQUEST]: {
    success: false,
    title: 'Oops... Something went wrong',
    footer: 'You may not have access to this request, or the request may no longer exist. Please login to your Dashboard for more or contact our support team.',
  },
  [statuses.CLOSED]: {
    success: false,
    title: 'Sorry… This participant has already been connected with providers.',
    footer: <><p>This service request may have already been accepted by the maximum number of providers, or the request withdrawn by the participant.</p><p>Clickability will reach out to you again if the participant needs further assistance.</p></>,
  },
  [statuses.EXPIRED]: {
    success: false,
    title: 'Sorry, this service request has expired.',
    footer: <><p>This service request was not responded to in time and has now expired.</p><p>Clickability will reach out to you again if the participant needs further assistance.</p></>,
  },
  [statuses.AVAILABLE]: {
    success: true,
    title: 'Service Request available!',
    footer: 'Login to see more information and reveal contact details. Your potential client has been told to expect your call within 2 business days.',
  }
};

const MatchPanel = ({match, status, isBusy, onClick, providerUuid, ...props}) => {

  const classes = useStyles(props);
  const { uuid: serviceRequestUuid } = useParams();
  const [clickedButton, setClickedButton] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const [isReserveModalOpen, setIsReserveModalOpen] = useState(false);
  const [reserveModalKey, setReserveModalKey] = useState(1);

  const { isLoading: isCreditLoading, data: creditData } = useGetApi(GET_AVAILABLE_SR_CREDIT, {
    params: {
      providerUuid
    },
    staleTime: 0,   // No caching
    refetchOnWindowFocus: true,
  });

  const Loader = () => (
    <div className={classes.loader}>
      <CircularProgress className={classes.spinner} size={40} thickness={5}/>
    </div>
  );

  const handleButtonClick = action => {
    setClickedButton(action);
    if (action === SERVICE_REQUEST_EVENT_ACCEPT) {
      setIsReserveModalOpen(true);
    } else {
      onClick(action, () => setIsModalOpen(true));
    }
  };

  const handleAccept = action => {
    onClick(action, () => setIsModalOpen(true));
  };

  if (!status) return (
    <div className={classes.root}>
      <Loader/>
    </div>
  );

  const messages = MESSAGES[status];
  const headerClass = messages.success ? classes.matchSuccessTitle : classes.matchTitle;
  const rootClass = messages.success ? classNames(classes.root, classes.rootClaimed) : classes.root;

  useEffect(() => {
    if (status !== statuses.AVAILABLE) {
      setIsModalOpen(true);
    }
  }, [status]);

  const hasNoCredits = (
    creditData?.isExclusiveProvider && match?.serviceName === SERVICE_PLAN_MANAGEMENT ?
      false : creditData?.availableCredits != null && creditData?.availableCredits <= 0 && !match?.isFree
  );

  const renderButtons = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent:
              'center',
            alignItems: 'center',
            marginTop: '30px',
            marginBottom: hasNoCredits && '30px',
          }}
        >
          <CreditStats
            style={{maxWidth: '350px'}}
            availableCredits={creditData?.availableCredits}
            totalCredits={creditData?.totalCredits}
            resetDate={creditData?.creditsResetDate}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.acceptButton}
            variant="contained"
            onClick={() => handleButtonClick('accept')}
            disabled={isBusy || hasNoCredits || isCreditLoading}
          >
            {isBusy && clickedButton === 'accept' ?
              <CircularProgress className={classes.progress} size={25} /> : 'Accept'}
          </Button>
          <Button
            className={classes.declineButton}
            variant="contained"
            onClick={() => handleButtonClick('decline', '')}
            disabled={isBusy || hasNoCredits || isCreditLoading}
          >
            {isBusy && clickedButton === 'decline' ?
              <CircularProgress className={classes.progress} size={25} /> : 'Decline'}
          </Button>
        </div>
        {hasNoCredits && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Alert
              severity="error"
              icon={<FontAwesomeIcon icon={faCoins}/>}
            >
              <div>
                You do not have any credits available
              </div>
            </Alert>
          </div>
        )}
        {isReserveModalOpen && (
          <ReserveModal
            key={`reserve-modal-${reserveModalKey}`}
            data={match?.serviceInquiry}
            serviceRequestUuid={serviceRequestUuid}
            providerUuid={providerUuid}
            onCancel={() => setIsReserveModalOpen(false)}
            onReQueue={() => setReserveModalKey(prevState => prevState + 1)}
            onAccept={handleAccept}
          />
        )}
      </>
    );
  };

  const renderFreeSRAlert = () => (
    <Alert
      style={{borderRadius: '14px', marginTop: '20px'}}
      severity="success"
    >
      <div className={classes.alertext}>
        <b>Free Service Request!</b><br/>
        We love what you do and want to gift you this Service Request
        for <b>FREE!</b> Accepting this Service Request won&apos;t
        count towards your credit.
      </div>
    </Alert>
  );

  const renderServiceRequestInfo = () => (
    <div className={classes.infoContainer}>
      <ParticipantInfo data={match} isLoading={false} />
      <Service data={match} isLoading={false} />
      <Funding data={match} isLoading={false} />
    </div>
  );

  return (
    <div className={rootClass}>
      <Modal
        isOpen={isModalOpen}
        paperClassName={classes.paper}
      >
        <div className={classes.modalHeader}>{messages.title}</div>
        <div className={classes.modalContainer}>
          <Typography variant="h1" color="primary" className={classes.matchFooter}>
            {messages.footer}
          </Typography>
          <div className={classes.modalButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIsModalOpen(false);
                const redirectTo = messages.success ?
                  `/provider/${providerUuid}/admin/serviceRequests?item=${serviceRequestUuid}` : null;
                history.push('/providers/login', { redirectTo });
              }}
            >Continue</Button>
          </div>
        </div>
      </Modal>
      <Typography variant="h1" color="primary" className={headerClass}>
        {messages.title}
      </Typography>
      {match?.isFree && renderFreeSRAlert()}
      {match && renderServiceRequestInfo()}
      {match?.isFree && renderFreeSRAlert()}
      {status === statuses.AVAILABLE && renderButtons()}
    </div>
  );
};

MatchPanel.propTypes = {
  match: PropTypes.object,
  status: PropTypes.string,
  isBusy: PropTypes.bool,
  providerUuid: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default MatchPanel;