import React, { useState } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/pro-light-svg-icons';
import { Tooltip, Modal } from 'antd';
import { useMeasure } from 'react-use';

import Card from './Card';
import QualifiedServiceIcon from 'components/QualifiedServiceIcon';
import LocationTree from './LocationTree';
import { ACCESS_METHOD_ICON_MAP } from './constants';
import style from 'style';
import * as track from 'lib/track';
import DirectConnectModal from 'components/DirectConnectModal';


const MAX_DESCRIPTION_WIDTH = 460;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  @media (max-width: ${style.screens.tab}) {
    row-gap: 35px;
  }
`;

const Services = ({ data, isLoading, disableServiceClick, providerUuid, providerName }) => {
  const [directConnectOpen, setDirectConnectOpen] = useState(false);
  const [directConnectService, setDirectConnectService] = useState();

  return (
    <Card
      title="Services"
      icon={faTags}
      isLoading={isLoading}
      noData={_.isEmpty(data)}
      collapsable={data?.length > 6}
      collapsableButtonText="See all services..."
    >
      <Container>
        {data?.map((item, index) => (
          <ServiceTile
            key={index}
            data={item}
            onClick={serviceName => {
              setDirectConnectService(serviceName);
              setDirectConnectOpen(true);
            }}
            disableServiceClick={disableServiceClick}
          />
        ))}
      </Container>
      <DirectConnectModal
        open={directConnectOpen}
        serviceData={data}
        service={directConnectService}
        onCancel={() => {
          setDirectConnectService(null);
          setDirectConnectOpen(false);
        }}
        providerUuid={providerUuid}
        providerName={providerName}
      />
    </Card>
  );
};

Services.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  disableServiceClick: PropTypes.bool,
  providerUuid: PropTypes.string,
  providerName: PropTypes.string,
};


const ServiceContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  
  @media (max-width: ${style.screens.tab}) {
    flex-direction: column;
    align-items: start;
    row-gap: 10px;
  }
  
  .icon {
    font-size: 21px;
    color: ${style.colors.palette.darkSlateBlue};
    cursor: ${({$disableServiceClick}) => !$disableServiceClick && 'pointer'};
    :hover {
      color: ${({$disableServiceClick}) => !$disableServiceClick && style.colors.secondary};
    }
  }
  
  .name {
    font-weight: 500;
    line-height: 16px;
    color: ${style.colors.palette.darkSlateBlue};
    cursor: ${({$disableServiceClick}) => !$disableServiceClick && 'pointer'};
    :hover {
      color: ${({$disableServiceClick}) => !$disableServiceClick && style.colors.secondary};
    }
  }
  
  .description {
    margin-top: 4px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    max-width: ${MAX_DESCRIPTION_WIDTH}px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (max-width: ${style.screens.mobile}) {
      max-width: ${({mobileDescriptionWidth}) => mobileDescriptionWidth && `${mobileDescriptionWidth}px`}
    }
  }
  
  .service-areas {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    :hover {
      color: ${style.colors.secondary};
    }
  }
  
  .access-method-container {
    display: flex;
    column-gap: 10px;
    
    .icon {
      font-size: 18px;
      color: black;
    } 
  }
`;

const InnerLeftContainer = styled.div`

`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 14px;
`;

const RightContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: end;
  column-gap: 20px;
  
  @media (max-width: ${style.screens.tab}) {
    margin-left: 40px;
    flex-direction: column;
    align-items: start;
    row-gap: 8px;
  }
`;


const ServiceTile = ({ data, disableServiceClick, onClick }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ref, { width: containerWidth }] = useMeasure();

  const _mobileDescriptionWidth = containerWidth - 45;
  const mobileDescriptionWidth = _mobileDescriptionWidth < MAX_DESCRIPTION_WIDTH ? _mobileDescriptionWidth : MAX_DESCRIPTION_WIDTH;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    if (disableServiceClick || !data.directConnectConfig.eligible) return;

    track.sendEvent(track.CONNECT_ME_BUTTON_CLICK, track.EVENT_CLICK, {
      listing_uuid: data.uuid,
    }, [data?.providerUuid]);

    onClick(data.service);
  };

  return (
    <ServiceContainer ref={ref} mobileDescriptionWidth={mobileDescriptionWidth} $disableServiceClick={disableServiceClick}>
      <LeftContainer>
        <div style={{minWidth: '25px'}} onClick={handleClick}>
          <QualifiedServiceIcon.Regular serviceName={data.service} className="icon" />
        </div>
        <InnerLeftContainer>
          <div className="name" onClick={handleClick}>
            {data.service}
          </div>
          <div className="description">
            {data.shortDescription}
          </div>
        </InnerLeftContainer>
      </LeftContainer>
      <RightContainer>
        {!!data.accessMethods && !!data.accessMethods.length && (
          <div className="access-method-container">
            {data.accessMethods.map((item, index) => (
              <Tooltip
                key={index}
                title={item}
                overlayInnerStyle={{
                  fontSize: '10px',
                  fontWeight: 500,
                  padding: '5px 8px 2px 8px',
                  margin: 0,
                  minHeight: 0,
                }}
              >
                <FontAwesomeIcon className="icon" icon={ACCESS_METHOD_ICON_MAP[item]}/>
              </Tooltip>
            ))}
          </div>
        )}
        <div className="service-areas" onClick={showModal}>
          View Service Areas
        </div>
      </RightContainer>
      <Modal title="Service Areas" visible={isModalOpen} onCancel={handleCancel} footer={null}>
        <LocationTree data={data.locations} />
      </Modal>
    </ServiceContainer>
  );
};

ServiceTile.propTypes = {
  data: PropTypes.object,
  disableServiceClick: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Services;

