import { v4 as uuidv4 } from 'uuid';

const SET = 'session/SET';
const INVALID_ID = 'INVALID SESSION ID';

const initialState = {id: INVALID_ID};

export const set = (sessionId) => {
  return {
    type: SET,
    data: {id: sessionId},
  };
};

export const getSessionId = () => {
  return (dispatch, getState) => {
    if (getState().sessionId.id === INVALID_ID)
      dispatch(set(uuidv4()));
    return getState().sessionId.id;
  };
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case SET:
    return action.data;
  default:
    return state;
  }
};

export default reducer;
