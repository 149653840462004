import React from 'react';
import PropTypes from 'prop-types';

import { Input, Alert, Button, Rate } from 'antd';
import { makeStyles } from '@material-ui/core/styles';

import { ReviewAPI } from '../../api';
import * as track from '../../lib/track';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '5px',
  },
  row: {
    width: '100%',
    padding: '0.5rem 0',
  },
  alert: {
    marginTop: '0.5rem',
  },
  buttons: {
    display: 'flex',
    columnGap: '10px',
    flexWrap: 'nowrap',
  },
  label: {
    width: '100%',
    fontWeight: '500',
    marginBottom: '8px',
  },
}));

export const submitReview = async (provider_uuid, review, temporary_credentials, onComplete, onError) => {
  try {
    const { payload } = await ReviewAPI.createReview(provider_uuid, review, temporary_credentials);
    track.sendEvent('review form', 'submit', { provider_uuid: provider_uuid });
    if (onComplete) {
      onComplete(payload);
    } else {
      return payload;
    }
  } catch (err) {
    onError(err);
  }
};

const ReviewForm = ({ data, onClose, onComplete, onSubmit, initialReview }) => {
  const classes = useStyles();
  const [busy, setBusy] = React.useState(false);
  const [content, setContent] = React.useState(initialReview ? initialReview.content : '');
  const [rating, setRating] = React.useState(initialReview ? initialReview.rating : null);
  const [contentError, setContentError] = React.useState(null);
  const [ratingError, setRatingError] = React.useState(null);
  const [submissionError, setSubmissionError] = React.useState(null);

  const review = {
    content: content,
    rating: rating,
    item_type: 'provider',
    item_uuid: data.uuid,
  };

  const validateContent = () => {
    const contentLength = content.length;
    const spaceCount = (content.split(' ').length - 1);
    if (contentLength > 10 && spaceCount > 1) {
      setContentError(null);
      return true;
    } else {
      setContentError('You must write at least 3 words in your review.');
      return false;
    }
  };

  const validateRating = () => {
    const ratingValid = rating !== null;
    if (ratingValid) {
      setRatingError(null);
      return true;
    } else {
      setRatingError('You must provide a rating.');
      return false;
    }
  };

  const onError = () => {
    setSubmissionError('Opps, we seem to have a technical problem. Please try again later or contact us.');
  };

  const submit = async (review) => {
    setBusy(true);
    if (validateRating() && validateContent()) {
      if (onSubmit) {
        onSubmit(review);
      } else {
        await submitReview(data.uuid, review, onComplete, onError);
      }
    }
    setBusy(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.label}>
          Please rate your experience:
        </div>
        <Rate value={rating} onChange={val => setRating(val)} />
        {ratingError === null ? null : (
          <Alert className={classes.alert} showIcon type="error" message={ratingError} />
        )}
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          Please tell us about your experience:
        </div>
        <Input.TextArea
          placeholder="Write a review...."
          style={{padding: '10px'}}
          value={content}
          onChange={e => setContent(e.target.value)}
          autoSize={{minRows: 4, maxRows: 6}}
        />
        {contentError === null ? null : (
          <Alert className={classes.alert} showIcon type="error" message={contentError} />
        )}
      </div>
      <div className={classes.row}>
        <div className={classes.buttons}>
          {onClose === null ? null : (
            <Button
              type="primary"
              ghost
              onClick={onClose}
              loading={busy}
            >
              Cancel
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => submit(review)}
            loading={busy}
          >
            Submit
          </Button>
        </div>
        {submissionError === null ? null : (
          <Alert className={classes.alert} showIcon type="error" message={submissionError} />
        )}
      </div>
    </div>
  );
};

ReviewForm.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  onSubmit: PropTypes.func,
  initialReview: PropTypes.object,
};

export default ReviewForm;
