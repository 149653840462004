import React from 'react';

import {
  PARTICIPANT_FIRST_NAME_FIELD,
} from './constants';
import {
  dashboardFlowPageContactInfo,
  dashboardFlowPageParticipantDetails,
  questionFlowPageLocation,
  questionFlowPageService,
  questionFlowPageServiceFor,
  questionFlowPageSuccess,
  questionFlowPageFinalDetails,
  questionFlowPageVerification,
} from './flowPages';
import {getFromMyselfServiceType} from './flows/utils';
import DashboardFlowContainer from './flows/DashboardFlow/DashboardFlowContainer';
import DashboardFlow from './flows/DashboardFlow';

export const brokerageFlow = {
  pages: [
    // QuestionFlow Pages
    questionFlowPageService,
    questionFlowPageLocation,
    questionFlowPageServiceFor,
    questionFlowPageFinalDetails,
    questionFlowPageVerification,
  ],
  modalityPageTemplate: {
    name: null,
    Container: DashboardFlowContainer,
    QuestionContainer: DashboardFlow,
    isModalityQuestion: true,
    questionCount: true,
    data: {
      info: null,
      // eslint-disable-next-line react/display-name
      helpPrompt: answers => <>Tell us about the service&nbsp;
        <strong>
          {getFromMyselfServiceType(
            answers, 'you need', `${answers[PARTICIPANT_FIRST_NAME_FIELD]} needs`
          )}</strong>. These questions help us better understand the service needed,
        so we can find you a provider that has capacity.</>,
    },
    questions: null,
  },
};

export const viewModeBrokerageFlow = {
  ...brokerageFlow,
  pages: [
    // DashboardFlow Pages
    dashboardFlowPageContactInfo,
    dashboardFlowPageParticipantDetails,
    // dashboardFlowPageCommunication,
    // dashboardFlowPageNdisInfo,
    // dashboardFlowPageDisabilityInfo,
    // Dynamically Generated Service Pages
    // <Service 1>
    // <Service 2>
    // ...
    // EndOfJourney/QuestionFlow Pages
  ],
};

export const connectBrokerageFlow = {
  ...brokerageFlow,
  pages: [
    // DashboardFlow Pages
    dashboardFlowPageContactInfo,
    dashboardFlowPageParticipantDetails,
    // dashboardFlowPageCommunication,
    // dashboardFlowPageNdisInfo,
    // dashboardFlowPageDisabilityInfo,
    // Dynamically Generated Service Pages
    // <Service 1>
    // <Service 2>
    // ...
    questionFlowPageSuccess,  // This page won't be shown due to custom submission logic
  ],
};
