import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from './Card';


const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[500],
  },
  icon: {
    fontSize: '70px',
  },
  title: {
    marginTop: '7px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '500',
  },
}));

const StaticCard = ({ title, icon, onSelect, isSelected, isDisabled }) => {

  const classes = useStyles();

  const handleSelect = () => {
    title && onSelect(title);
  };

  return (
    <div className={classes.root}>
      <Card disabled={isDisabled} isSelected={isSelected} onSelect={handleSelect}>
        <>
          <FontAwesomeIcon className={classes.icon} icon={icon} />
          <div className={classes.title}>{_.capitalize(title)}</div>
        </>
      </Card>
    </div>
  );
};

StaticCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default StaticCard;