import React from 'react';
import PropTypes from 'prop-types';

import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import {Step, StepConnector, StepLabel, Stepper} from '@material-ui/core';

import stepperOneImg from 'assets/stepper-one.png';
import stepperTwoImg from 'assets/stepper-two.png';
import stepperThreeImg from 'assets/stepper-three.png';


const useStyles = makeStyles(theme => ({
  root: {
    display: ({isVertical}) => isVertical && 'flex',
    justifyContent: ({isVertical}) => isVertical && 'center',
  },
  stepLabel: {
    imageRendering: '-webkit-optimize-contrast',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: ({isVertical}) => isVertical ? 'start' : 'center',
    marginLeft: ({isVertical}) => isVertical ? '40px' : '0',
  },
  stepImageContainer: {
    height: '100px',
  },
  stepLabelText: {
    marginTop: ' 20px',
    fontSize: '18px',
    fontWeight: '400',
    width: '220px',
    textAlign: 'center',
    color: theme.palette.common.nightBlue,
  },
  lineHorizontal: {
    borderTop: `1px dashed ${theme.palette.common.silver}`,
  },
  lineVertical: {
    borderLeft: `1px dashed ${theme.palette.common.silver}`,
    minHeight: '70px',
  },
}));

const FlowStepper = ({activeStep}) => {
  const isMobile = useMediaQuery({query: '(max-width: 945px)'});

  const classes = useStyles({isVertical: isMobile});

  const steps = [
    {
      image: stepperOneImg,
      text: 'We have sent your de-identified request to matching providers',
      width: '173.6px',
      height: '98.4px',
    },
    {
      image: stepperTwoImg,
      text: 'We will let you know which providers have responded to your request',
      width: '181.2px',
      height: '92px',
    },
    {
      image: stepperThreeImg,
      text: 'No more than 3 providers will get in touch with you directly',
      width: '201.6px',
      height: '94.4px',
    },
  ];

  const renderStepLabel = (image, text, width, height) => {
    return (
      <div className={classes.stepLabel}>
        <div className={classes.stepImageContainer}>
          <img src={image} alt="step" width={width} height={height} />
        </div>
        <div className={classes.stepLabelText}>{text}</div>

      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        connector={
          <StepConnector
            classes={{lineHorizontal: classes.lineHorizontal, lineVertical: classes.lineVertical}}
          />}
        alternativeLabel={!isMobile}
        orientation={isMobile ? 'vertical' : 'horizontal'}
      >
        {steps.map((item, index) => (
          <Step key={index}>
            <StepLabel>
              {renderStepLabel(item.image, item.text, item.width, item.height)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

FlowStepper.propTypes = {
  activeStep: PropTypes.number,
};

export default FlowStepper;