
export const makeSubtitle = urlParams => {
  const serviceText = ('service' in urlParams) ? (Array.isArray(urlParams.service) ? urlParams.service.join(', ') : urlParams.service) : 'all';
  const locText = ('service_location' in urlParams) ? ` delivered in ${urlParams.service_location}` : '';
  const searchText = ('search_text' in urlParams) ? ` mentioning '${urlParams.search_text}'` : '';
  const ndisRegText = ('ndis_registered' in urlParams) ? ' that are NDIS registered' : '';
  const ndisecRegText = ('ndisec_registered' in urlParams) ? ' and are NDIS Early Childhood registered' : '';
  const specText = ('specialisation' in urlParams) ? (Array.isArray(urlParams.specialisation) ? urlParams.specialisation.join(', ') : urlParams.specialisation) : '';
  const ageText = ('age_group' in urlParams) ? ` for ${urlParams.age_group}` : '';
  const accessText = ('access_method' in urlParams) ? ` accessed by ${urlParams.access_method}` : '';
  return `Find ${serviceText} services${locText}${searchText}${ndisRegText}${ndisecRegText}${specText.length ? ` specialising in ${specText}` : specText}${ageText}${accessText}.`;
};