import Button from '../Button';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPopover-paper': {
      borderRadius: '12px',
      marginTop: '28px',
    },
  },
  container: {
    maxWidth: '350px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    marginTop: '30px',
  },
  button: {
    fontSize: '1rem',
    padding: '10px 30px',
    borderRadius: '10px',
    margin: 0,
  },
  header: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.common.lightBorder,
    padding: '10px 20px',
    color: theme.palette.common.battleshipGrey,
    fontSize: '15px',
  },
  title: {
    fontSize: '16px',
    color: theme.palette.common.black,
    textAlign: 'center',
  },
}));

const AuthMenu = ({authMenu, anchorEl, setAuthMenu}) => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const redirectTo = location.pathname + location.search;

  const handleClick = (userType) => {
    if (userType === 'participant') {
      if (authMenu === 'login') {
        history.push({pathname: '/login', state:{redirectTo}});
      } else {
        history.push('/signup');
      }
    } else {
      if (authMenu === 'login') {
        history.push({pathname: '/providers/login', state:{redirectTo}});
      } else {
        history.push('/providers/signup');
      }
    }
    setAuthMenu(null);
  };

  const closeOnEscape = (event) => {
    if (event.keyCode === 27) {
      setAuthMenu(null);
    }
  };

  const authText = authMenu === 'signup' ? 'Register' : 'Log In';

  return (
    <>
      <Popover
        className={classes.root}
        open={!!authMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={3}
        onClose={() => setAuthMenu(null)}
        onKeyUp={closeOnEscape}
      >
        <div className={classes.header}>
          {authText}
        </div>
        <div className={classes.container}>
          <div className={classes.title}>
            Select which user type you <br/>would like to <b>{authText}</b> as
          </div>
          <div className={classes.buttonContainer}>
            <Button
              label="I am an NDIS participant"
              onClick={()=>handleClick('participant')}
              variant="contained"
              pointer="right"
              className={classes.button}
              disableRipple
            />
            <Button
              label="I am a provider"
              onClick={()=>handleClick('provider')}
              variant="outlined"
              pointer="right"
              className={classes.button}
              disableRipple
            />
          </div>
        </div>
      </Popover>
    </>
  );
};

AuthMenu.propTypes = {
  authMenu: PropTypes.string,
  setAuthMenu: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
};

export default AuthMenu;
