import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  hidden: {
    display: 'none',
  },
});

const DashboardFlow = ({ children, hidden }) => {
  const classes = useStyles();

  return (
    <Collapse key={children.key} in={!hidden} classes={{hidden: classes.hidden}}>
      {children}
    </Collapse>
  );
};

DashboardFlow.propTypes = {
  children: PropTypes.node,
  hidden: PropTypes.bool,
};

export default DashboardFlow;