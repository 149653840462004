import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

import QualifiedServiceIcon from 'components/QualifiedServiceIcon';
import style from 'style';


const Container = styled.div`
  max-width: 750px;
  .title {
    margin-bottom: 15px;
    font-size: 13px;
    font-weight: 500;
  }
`;

const SelectorContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  flex-wrap: wrap;
`;

const Selector = styled.div`
  border: 1px solid ${style.colors.lightBorder};
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  flex-grow: 1;
  padding: 10px 20px;
  border-radius: 16px;
  box-shadow: ${style.shadow(15)};
  cursor: pointer;

  :hover {
    border-color: #76e8dc;
  }

  .icon-container {
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 6px;
  }

  .icon {
    width: 20px;
    color: ${style.colors.palette.black};
  }

  .text {
    font-size: 13px;
    font-weight: 500;
  }
`;

const ServiceSelection = ({ title, data, onSelect, className='' }) => {

  const onClick = item => {
    if (!item)
      return;

    const data = {
      id: item.id,
      name: item.name,
      qualifiedServiceCategory: item?.qualifiedServiceCategory
    };

    onSelect(data);
  };

  return (
    <Container className={className}>
      <div className="title">{title}</div>
      <SelectorContainer>
        {data.map((item, index) => (
          <Selector
            key={`selector-${index}`}
            onClick={() => onClick(item)}
          >
            {item ? (
              <>
                <div className="icon-container">
                  <QualifiedServiceIcon.Regular serviceName={item?.name} className="icon" />
                </div>
                <div className="text">{item?.name}</div>
              </>) : <CircularProgress size={25} />}
          </Selector>
        ))}
      </SelectorContainer>
    </Container>
  );
};

ServiceSelection.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ServiceSelection;