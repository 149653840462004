import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import NavMenu from 'components/navmenu';
import PassportFooter from 'components/PassportFooter';
import PassportIconBlueText from '../assets/passport-logo-blue-text.svg';
import PropTypes from 'prop-types';
import React from 'react';
import backgroundImg from '../assets/background-2.svg';
import config from 'config';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.text.secondary,
  },
  background: {
    width: '100%',
    minHeight: '60vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundImage: `url(${backgroundImg})`,
    backgroundColor: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    padding: '1.5rem 2rem',
    borderRadius: 6,
    backgroundColor: theme.palette.background.default,
    width: '90%',
    margin: 'auto',
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  backgroundImg: {
    width: '8rem',
    margin: '0.573rem 4.698rem 0 1.563rem',
  },
  main: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
}));

const ErrorPage = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NavMenu hideNav hideAuth aria-label="Header navigation bar and menu">
        <Link
          to={{ pathname: config.link.passportWebsite }}
          target="_blank"
          className={classes.link}
        >
          <img src={PassportIconBlueText} alt="clickability-logo" height="60" />
        </Link>
      </NavMenu>
      <div className={classes.background}>
        <Container
          maxWidth="lg"
          fixed
          disableGutters={true}
        >
          <Grid container className={classes.content}>
            <Grid item md={12} className={classes.main}>
              {props.alert}
            </Grid>
            <Grid item md={12} className={classes.main}>
              <img
                src={props.image}
                className={classes.backgroundImg}
                alt="icon"
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      <PassportFooter aria-label="Footer section and links" />
    </div>
  );
};

ErrorPage.propTypes = {
  alert: PropTypes.node,
  image: PropTypes.any,
};

export default ErrorPage;
