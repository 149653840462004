const API_STACK = 'servicerequest/';

export const GET_CONNECT_SERVICE_REQUESTS = `${API_STACK}connect/:providerUuid`;
export const GET_CONNECT_SERVICE_REQUEST = `${API_STACK}connect/:providerUuid/:serviceRequestUuid`;
export const GET_CONNECT_SERVICE_REQUESTS_FILTER_COUNT = `${API_STACK}connect/:providerUuid/filters/counts`;
export const GET_AVAILABLE_SR_CREDIT = API_STACK + 'credit/:providerUuid';
export const PATCH_SERVICE_REQUEST_MATCH = API_STACK + 'srm/:srmId';
export const GET_SERVICE_INQUIRY = API_STACK + 'inquiry/:serviceInquiryUuid';
export const PATCH_SERVICE_INQUIRY = GET_SERVICE_INQUIRY;
export const GET_SERVICE_REQUEST = API_STACK + '/:serviceRequestUuid';
export const PATCH_SERVICE_REQUEST_COMPLETE_ACTION = API_STACK + '/:serviceRequestUuid/complete';

export const CREATE_INCOMPLETE_SERVICE_REQUEST = API_STACK + 'incomplete-sr';
export const DELETE_INCOMPLETE_SERVICE_REQUEST = CREATE_INCOMPLETE_SERVICE_REQUEST + '/:sessionId';

export const CREATE_DIRECT_CONNECT = API_STACK + 'direct-connect' + '/:providerUuid';
export const GET_DIRECT_CONNECT_REQUESTS = CREATE_DIRECT_CONNECT;
export const PATCH_DIRECT_CONNECT_REQUEST = CREATE_DIRECT_CONNECT + '/:uuid';

export const POST_SR_QUEUE = API_STACK + 'queue';
export const GET_SR_QUEUE = API_STACK + 'queue' + '/:uuid';
export const DELETE_SR_QUEUE = GET_SR_QUEUE;

export const POST_BOOST = API_STACK + 'boost';
