import React from 'react';
import { SvgIcon } from '@material-ui/core';

const EndorsementIcon = (props) => {
  return(
    <SvgIcon viewBox="0 0 250 250" {...props}>
      <g id="endorsement" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(-35.000000, -36.000000)" fillRule="nonzero">
          <circle id="Mask" fill="#2EBCAE" transform="translate(160.500000, 160.500000) rotate(-23.250000) translate(-160.500000, -160.500000) " cx="160.5" cy="160.5" r="122.035345"></circle>
          <path d="M205,80.5 L281.794891,174.090025 C277.033858,216.224525 250.417717,254.686647 208.670407,272.622707 C187.974566,281.514342 166.17933,284.236208 145.338029,281.607501 L105,239 L205,80.5 Z" id="Combined-Shape" fill="#199D8E"></path>
        </g>
        <g id="Group-2" transform="translate(55.000000, 30.000000)">
          <circle id="Oval" fill="#FFFFFF" cx="70" cy="70" r="70"></circle>
          <path d="M113.103486,65.1955313 L95.842982,82.0420022 L99.9261121,105.869055 C100.278746,107.84409 99.4697339,109.846792 97.8458817,111.018648 C96.2220295,112.190505 94.0716814,112.323432 92.3166424,111.360446 L71.0658063,100.191515 L49.7221718,111.360446 C47.9671328,112.323432 45.8167847,112.190505 44.1929325,111.018648 C42.5690803,109.846792 41.7600685,107.84409 42.1127021,105.869055 L46.1958322,82.0420022 L28.8425293,65.1955313 C27.230694,63.806465 26.6041965,61.5847726 27.2518784,59.5547467 C27.8995603,57.5247208 29.6956511,56.0805625 31.8120785,55.8880888 L55.5684717,52.444335 L66.2402889,30.757994 C67.1683417,29.0574356 68.9473219,28 70.8802095,28 C72.813097,28 74.5920773,29.0574356 75.52013,30.757994 L86.1919473,52.444335 L110.041139,55.8880888 C112.211216,55.9879318 114.090394,57.4310778 114.752476,59.506239 C115.414558,61.5814003 114.719824,63.8506479 113.010688,65.1955313 L113.103486,65.1955313 Z" id="Path" fill="#199D8E"></path>
          <polygon id="Path-18" fill="#FFFFFF" points="41.8181818 123 15 173 37.7954545 170.333333 46.5113636 189 74 137.666667"></polygon>
          <polygon id="Path-18" fill="#FFFFFF" transform="translate(94.500000, 156.000000) scale(-1, 1) translate(-94.500000, -156.000000) " points="91.8181818 123 65 173 87.7954545 170.333333 96.5113636 189 124 137.666667"></polygon>
        </g>
      </g>
    </SvgIcon>
  );
};

export default EndorsementIcon;
