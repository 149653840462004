import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ProviderCard from './ProviderCard.js';
import LoadingCards from '../LoadingCards.js';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',    
    padding: '1rem 0',
  },    
});

const ProviderCardList = (
  {
    isLoading,
    data,
    nullMessage,
    externalDirectoryMode,
  }) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <LoadingCards count={10}/>
    );
  }

  if (data.length === 0) {
    return (
      <div className={classes.root}>
        <Alert severity="info">{nullMessage}</Alert>
      </div>
    );
  }  

  return (
    <div className={classes.root}>
      {data.map((item, i) =>
        <ProviderCard 
          key={i} 
          data={item}
          externalDirectoryMode={externalDirectoryMode}
          matchingData={{}}
          handleMatchingData={() => {}}
        /> 
      )}
    </div>        
  );
};

ProviderCardList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,  
  nullMessage: PropTypes.string.isRequired,
  externalDirectoryMode: PropTypes.bool,
};

export default ProviderCardList;
