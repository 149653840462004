import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import styled, {css} from 'styled-components';
import {useMediaQuery} from 'react-responsive';
import {Typography, Carousel} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faAngleUp,
  faAngleDown
} from '@fortawesome/pro-solid-svg-icons';

import style from 'style';
import _Container from 'providerSite/components/Container';
import Card from 'providerSite/components/Card';


const EXTENDED_TAB_SCREEN_WIDTH = `${parseInt(style.screens.tab, 10) + 100}px`;
const CUSTOM_LARGE_BREAKPOINT = `${parseInt(style.sizes.large, 10) + 10}px`;

const servicesRequestData = [
  {
    title: 'Physiotherapist',
    date: 'Melbourne, 3012',
    description: 'Male participant aged 22-59. Seeking Physio appointment at my home.',
  },
  {
    title: 'Support Worker',
    date: 'Sydney, 2095',
    description: 'Female participant aged 22-59. Seeking English speaking Support Worker.',
  },
  {
    title: 'Occupational Therapist',
    date: 'Newcastle, 2300',
    description: 'Female participant aged 17 – 21. Seeking OT with prior experience with CP.',
  },
  {
    title: 'Gardening',
    date: 'Gold Coast, 4220',
    description: 'Male participant aged 8-16. Seeking lawn moving services.',
  },
  {
    title: 'Psychologist',
    date: 'Brisbane, 4101',
    description: 'Male participant aged 17 – 21. Telehealth is ok.',
  }
];

const Main = styled.div`
  padding: 80px 0;
  background: ${style.colors.primary};
  color: ${style.colors.palette.white};
  display: flex;
  justify-content: center;
  overflow: hidden;
  @media (max-width: ${CUSTOM_LARGE_BREAKPOINT}) {
    padding-top: 50px;
  }
  @media (max-width: ${EXTENDED_TAB_SCREEN_WIDTH}) {
    padding-top: 50px;
    padding-bottom: 60px;
  }
`;

const Container = styled(_Container)`
  column-gap: 5px;
  flex-direction: row;
  text-align: left;
  .title {
    color: ${style.colors.palette.white};
    @media (max-width: ${EXTENDED_TAB_SCREEN_WIDTH}) {
      text-align: center;
    }
  }

  @media (max-width: ${CUSTOM_LARGE_BREAKPOINT}) {
    flex-direction: column;
    row-gap: 10px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${EXTENDED_TAB_SCREEN_WIDTH}) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  display: flex;
  column-gap: 20px;
  justify-content: center;
  position: relative;
  min-height: 0;
  min-width: 0;
  max-width: 1000px;

  @media (max-width: ${EXTENDED_TAB_SCREEN_WIDTH}) {
    flex-direction: column;
    row-gap: 20px;
    margin: 0;
  }
`;

const Divider = styled.div`
  width: 3px;
  background: #20222e;
  height: 140px;
  margin-left: 10px;
  margin-right: 15px;

  @media (max-width: ${CUSTOM_LARGE_BREAKPOINT}) {
    width: 200px;
    height: 1px;
    background: ${style.colors.secondary};
    margin-bottom: 30px;
  }
`;

const ButtonContainer = styled.div`
  --size: 26px;
  height: var(--size);
  width: var(--size);
  border-radius: 50%;
  background: ${style.colors.secondary};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  ${({isDesktop}) => isDesktop ? css`
    top: calc(50% - calc(var(--size) / 2));
  ` : css`
    left: calc(50% - calc(var(--size) / 2));
  `}
  color: ${style.colors.palette.white};
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    background: ${style.colors.palette.white};
    color: ${style.colors.secondary};
  }
`;

const CarouselContainer = styled.div`
  min-height: 0;
  min-width: 0;
  
  .slick-slide > div {
    margin: 0 10px;
  }
  
  .slick-list {
    padding: 0 -10px;
  }

  @media (max-width: ${EXTENDED_TAB_SCREEN_WIDTH}) {
    width: 300px;
    margin: auto;
    height: 500px;
    
    .slick-slide > div {
      margin: 10px 5px;
    }
  
    .slick-list {
      padding: -10px 0;
    }
  }
`;

const ServiceRequests = () => {
  const isDesktop = useMediaQuery({minWidth: EXTENDED_TAB_SCREEN_WIDTH});
  const carouselRef = useRef(null);

  return (
    <Main>
      <Container size={isDesktop ? 'large' : 'full'} flex>
        <LeftContainer>
          <Typography.Title level={2} className="title">
            Service Requests on <span style={{color: style.colors.secondary}}>Clickability</span>.
          </Typography.Title>
        </LeftContainer>
        <Divider/>
        <RightContainer>
          <ButtonContainer
            style={isDesktop ? {left: '-6px'} : {top: '-6px'}}
            isDesktop={isDesktop}
            onClick={() => carouselRef?.current.prev()}
          >
            <FontAwesomeIcon icon={isDesktop ? faAngleLeft : faAngleUp}/>
          </ButtonContainer>
          <CarouselContainer>
            <Carousel
              ref={carouselRef}
              slidesToShow={3}
              speed={500}
              infinite={true}
              dots={false}
              slidesToScroll={1}
              vertical={!isDesktop}
              adaptiveHeight={isDesktop}
              variableWidth={isDesktop}
              autoplay
            >
              {servicesRequestData.map(
                (data, index) => (<SRCard key={index} {...data} />))}
            </Carousel>
          </CarouselContainer>
          <ButtonContainer
            style={isDesktop ? {right: '-10px'} : {bottom: '-6px'}}
            isDesktop={isDesktop}
            onClick={() => carouselRef?.current.next()}
          >
            <FontAwesomeIcon icon={isDesktop ? faAngleRight : faAngleDown}/>
          </ButtonContainer>
        </RightContainer>
      </Container>
    </Main>
  );
};

const LargeText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${style.colors.secondary};
`;

const SmallText = styled.div`
  font-size: 13px;
`;

const SRCard = ({title, date, description}) => {
  return (
    <Card style={{width: '290px', border: 0}} strength={0}>
      <LargeText>
        {title}
      </LargeText>
      <SmallText>
        {date}
      </SmallText>
      <SmallText style={{marginTop: '20px'}}>
        {description}
      </SmallText>
    </Card>
  );
};

SRCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
};

export default ServiceRequests;
