import React from 'react';
import PlanCard from './PlanCard';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  row: {
    width: '100%',
    display: 'flex',
    columnGap: '12px',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      rowGap: '15px',
    },
  },
}));

const PlanCardGroup = ({plans, planId, onChange}) => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      {plans.map(plan => <PlanCard key={plan.id} plan={plan} selected={planId === plan.id} onClick={()=>onChange(plan.id)}/>)}
    </div>
  );
};

PlanCardGroup.propTypes = {
  plans: PropTypes.array.isRequired,
  planId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PlanCardGroup;
