import * as track from '../lib/track';

import Footer from './Footer';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import logo from 'assets/logo-tagline-white.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => (
  {
    root: {
      minHeight: '100vh',
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      position: 'relative',
      zIndex: 1,
      padding: 0,
      margin: 0,
    },
    navBar: {
      backgroundColor: theme.palette.common.nightBlue,
      position: 'relative',
      zIndex: 998,
      height: 80,
      width: '100%',
      boxShadow: '0px -2px 46px rgba(0, 0, 0, 0.07), ' +
        '0px -0.730033px 16.7908px rgba(0, 0, 0, 0.0482987), ' +
        '0px -0.354417px 8.1516px rgba(0, 0, 0, 0.0389404), ' +
        '0px -0.173742px 3.99607px rgba(0, 0, 0, 0.0310596), ' +
        '0px -0.0686979px 1.58005px rgba(0, 0, 0, 0.0217013)',
      borderRadius: '0px 0px 32px 32px',
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      display: 'block',
      marginLeft: '3rem',
      [theme.breakpoints.down('xs')]: {
        width: '80%',
      },
    },
  }
));


const ModalPage = ({children, title, footer=true, HeaderComponent=null}) => {
  const classes = useStyles();
  
  useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  useEffect(() => {
    window.scrollTo(0, 0);
    track.sendEvent('page', 'view', {title: title});
  }, []);

  return (
    <div className={classes.root}>
      {HeaderComponent ? <HeaderComponent /> : (
        <div className={classes.navBar}>
          <Link to="/">
            <img src={logo} alt="clickability-logo" className={classes.logo} height="45" />
          </Link>
        </div>
      )}
      <div className={classes.content}>
        {children}
      </div>
      {footer && <Footer aria-label="Footer section and links"/>}
    </div>
  );
};

// Must pass a single component. Wrap multiple components in React.Fragment to avoid warnings
ModalPage.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  footer: PropTypes.bool,
  HeaderComponent: PropTypes.elementType
};

export default ModalPage;
