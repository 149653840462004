import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import { ProviderAPI } from 'api';
import SpeechBubble from 'components/SpeechBubble';
import StarRating from 'components/widgets/StarRating.js';
import classNames from 'classnames';
import defaultImage from 'assets/physiotherapist-and-boy-holding-ball-150x84.png';
import { makeStyles } from '@material-ui/core/styles';
import { getImageHeightAndSize } from 'lib/util';

const imageWidth = 130;
const { imageHeight, imageSize } = getImageHeightAndSize(imageWidth);

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  providers: {
    marginTop: '15px',
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column'
  },
  speechBubbleContainer: {
    width: 380,
  },
  speechBubbleText: {
    textAlign: 'center',
  },
});

const providerCardStyles = makeStyles(theme => ({
  providerCard: {
    left: '20%',
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    background: theme.palette.common.white,
    margin: 6,
    borderRadius: 16,
    width: 300,
    transition: 'opacity 2.5s',
    cursor: 'pointer',
    opacity: 1,
    height: '95px',
    border: '1px solid transparent',
    boxShadow: theme.shadow(25),
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  providerImage: {
    width: imageWidth,
    height: imageHeight,
    marginRight: 10,
  },
  providerDetails: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
  },
  title: {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    width: '140px',
    maxHeight: '2.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 410,
  },
  toCall: {
    position: 'sticky',
    '&:before': {
      content: '\'TO CALL\'',
      fontSize: '0.8rem',
      color: theme.palette.common.darkSlateBlue,
      backgroundColor: theme.palette.common.lightSeaGreen,
      position: 'absolute',
      borderRadius: 20,
      padding: '2px 20px 0px 20px',
      left: -10,
      top: -10,
      fontWeight: 401,
    }
  },
  ratingContainer: {
    display: 'flex',
    columnGap: '5px',
    alignItems: 'center',
    marginTop: '-2px',
  },
  reviewCount: {
    fontSize: '12px',
    fontWeight: '500',
    marginTop: '3px',
  },
  rating: {
    fontSize: '12px',
    fontWeight: '500',
  },
  skeletonContainer: {
    display: 'flex',
    columnGap: '10px'
  },
  skeletonInnerRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },
}));


const MatchingExample = () => {
  const [providers, setProviders] = useState([...Array(3)].map(() => ({})));
  const classes = useStyles();
  const speechBubbleClasses = { speechContainer: classes.speechBubbleContainer, text: classes.SpeechBubbleText };


  useEffect(() => {
    const getProviders = async () => {
      try {
        const { payload } = await ProviderAPI.getExamples();
        setProviders(payload.providers);
      } catch {
        // fail gracefully if api call fails. 
      }
    };
    getProviders();
  }, []);

  return <div className={classes.root}>
    <SpeechBubble direction="left" image="agent" classes={speechBubbleClasses}>
      Great news Jac. We&apos;ve matched you with the following providers. You can expect their call soon!
    </SpeechBubble>
    <div className={classes.providers}>
      {providers && providers.map((provider, index) => (
        <ProviderCard key={index} provider={provider} toCall={index===0}/>
      ))}
    </div>
    <SpeechBubble direction="right" image="client" classes={speechBubbleClasses}>
      Thanks so much Kerry, I look forward to hearing from them.
    </SpeechBubble>
  </div>;
};

const ProviderCard = ({provider, toCall}) => {
  const classes = providerCardStyles();
  const history = useHistory();

  const isEmpty = !Object.keys(provider).length;


  const profileImg = provider.image_path ? provider.image_path + imageSize : defaultImage;

  const renderContent= () => {
    return (
      <>
        <img src={profileImg} alt={provider.name} className={classes.providerImage}/>
        <div className={classes.providerDetails}>
          <div className={classes.title}>{provider.name}</div>
          <div>
            <div className={classes.rating}>
              {provider.rating ? `Rated ${provider.rating.toFixed(1)}` : 'Be the first to review this service.'}
            </div>
            <div className={classes.ratingContainer}>
              <StarRating rating={provider.rating} size="small"/>
              {!!provider.rating && <div className={classes.reviewCount}>({provider.review_count})</div>}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderSkeleton = () => {
    return (
      <div className={classes.skeletonContainer}>
        <Skeleton variant="rect" animation="wave" width={130} height={73} />
        <div className={classes.skeletonInnerRightContainer}>
          <Skeleton variant="rect" animation="wave" width={100} height={20} />
          <Skeleton variant="rect" animation="wave" width={135} height={16} />
          <Skeleton variant="rect" animation="wave" width={135} height={16} />
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(classes.providerCard, toCall && classes.toCall)}
      onClick={() => history.push(provider.url)}
    >
      {isEmpty ? renderSkeleton() : renderContent()}
    </div>
  );
};
ProviderCard.propTypes = {
  provider: PropTypes.object.isRequired,
  toCall: PropTypes.bool,
};

export default MatchingExample;

