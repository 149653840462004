import React from 'react';
import PropTypes from 'prop-types';
import NavButton from './NavButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToLine } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SiteAdminIcon from '@material-ui/icons/Mood';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { AuthAPI } from '../../api';
import { AuthStore } from '../../store';
import Typography from '@material-ui/core/Typography';
import ProviderSearch from '../ProviderSearch';
import ServiceRequestNotification from '../ServiceRequestNotification';
import { getProductDashboardUrl } from 'providerSite/components/Navbar/components/utils';

const useStyles = makeStyles(theme => ({
  menu: {
    '& .MuiMenu-paper': {
      borderRadius: '12px',
      marginTop: '15px',
      maxWidth: '250px',
    },
    '& .MuiMenuItem-root': {
      '&:hover': {
        background: theme.palette.common.highlightBlue,
      },
    },
  },
  title: {
    padding: '5px 16px 10px 16px',
    margin: '0 0 0.5rem 0',
    background: theme.palette.common.white,
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    borderBottom: `1px solid ${theme.palette.common.lightBorder}`,
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  viewProfile: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  switchTo: {
    marginLeft: '20px',
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  switchToItem: {
    marginLeft: '15px',
  },
  avatar: {
    fontSize: '13px',
    background: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
  },
  srNotification: {
    marginTop: '12px',
  },
}));

const UserNavMenu = ({isAuthenticated=false, userProfile, providerProfile, dispatch, toggleAuthMenu}) => {
  const classes = useStyles();
  const history = useHistory();

  const [showAdminToggle, setShowAdminToggle] = React.useState(false);
  const [toggleText, setToggleText] = React.useState('');
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const openMenu = (event) => setMenuAnchorEl(event.currentTarget);
  const closeMenu = () => setMenuAnchorEl(null);
  const adminEnabled = history.location.pathname.includes('/admin');

  const handleShowAdminToggle = () => {
    const locationCondition = history.location.pathname.includes('/provider');
    const providerCondition = providerProfile.uuid !== undefined;
    const profileCondition = userProfile.is_provider;
    if (locationCondition && providerCondition && profileCondition) {
      let allowed_providers = userProfile.roles.map(r => r.provider_uuid);
      if (userProfile.is_site_admin || allowed_providers.includes(providerProfile.uuid)) {
        setShowAdminToggle(true);
      }
    } else {
      setShowAdminToggle(false);
    }
  };

  const toggleAdmin = () => {
    if (adminEnabled) {
      history.push(`/provider/${providerProfile.slug}?tab=1`);
    } else {
      history.push(`/provider/${providerProfile.uuid}/admin/listings`);
    }
  };

  const refreshProfile = async () => {
    try {
      const { payload } = await AuthAPI.refreshProfile();
      dispatch(AuthStore.profile(payload.profile));
    } catch (err) {
      if (err.status === 401) dispatch(AuthStore.logout());
    }
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      handleShowAdminToggle();
      (adminEnabled) ? setToggleText('Exit Admin') : setToggleText('Admin Mode');
    }
  }, [providerProfile.uuid, history.location]); // eslint-disable-line

  React.useEffect(() => {
    if (menuAnchorEl !== null) {
      refreshProfile();
    }
  }, [menuAnchorEl]);  // eslint-disable-line

  if (!isAuthenticated) {
    return (
      <React.Fragment>
        <NavButton
          variant="outlined"
          onClick={(event)=>toggleAuthMenu('signup', event.currentTarget)}
        >
          Register
        </NavButton>
        <NavButton
          variant="contained"
          color="primary"
          onClick={(event)=>toggleAuthMenu('login', event.currentTarget)}
        >
          Login
        </NavButton>
      </React.Fragment>
    );
  }

  // Duplicate logic from the provider site. This Nav Menu will be replaced by
  // new component.
  const getInitials = () => {
    if (userProfile.first_name && userProfile.last_name)
      return (userProfile.first_name[0] + userProfile.last_name[0]).toUpperCase();
    if (userProfile.first_name && userProfile.first_name.length > 1)
      return userProfile.first_name.slice(0, 2).toUpperCase();
    if (userProfile.last_name && userProfile.last_name.length > 1)
      return userProfile.last_name.slice(0, 2).toUpperCase();
    return userProfile.username.slice(0, 2).toUpperCase();
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        {userProfile?.is_matching_available && userProfile?.features?.includes('matching') && (
          <ServiceRequestNotification className={classes.srNotification}/>
        )}
        <NavButton onClick={openMenu}>
          <Avatar className={classes.avatar}>
            {getInitials()}
          </Avatar>
          {menuAnchorEl ? (<KeyboardArrowUpIcon />) : (<KeyboardArrowDownIcon />)}
        </NavButton>
      </div>
      <Menu
        anchorEl={menuAnchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(menuAnchorEl)}
        className={classes.menu}
        onClose={closeMenu}
        elevation={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.title}>
          {userProfile.first_name} {userProfile.last_name}
          <div
            className={classes.viewProfile}
            onClick={() => history.push('/providers/profile')}
          >
            View Profile
          </div>
        </div>
        {showAdminToggle ? (
          <MenuItem onClick={toggleAdmin}>
            <ListItemIcon>
              <SettingsOutlinedIcon className={classes.icon}/>
            </ListItemIcon>
            <Typography variant="subtitle2" color="primary">{toggleText}</Typography>
          </MenuItem>
        ) : null}
        {userProfile.is_provider && [
          <div
            className={classes.switchTo}
            key='switch'
          >
            Switch to
            <FontAwesomeIcon style={{marginLeft: '5px'}} icon={faArrowRightToLine} />
          </div>,
          <MenuItem
            component={Link}
            to={getProductDashboardUrl('accreditation', userProfile)}
            key='accreditation'
          >
            <Typography
              className={classes.switchToItem}
              variant="subtitle2"
              color="primary"
            >
              Accreditation
            </Typography>
          </MenuItem>,
          <MenuItem
            component={Link}
            to={{
              pathname: getProductDashboardUrl('connect', userProfile),
              state: {from: 'connect'}
            }}
            key='connect'
          >
            <Typography
              className={classes.switchToItem}
              variant="subtitle2"
              color="primary"
            >
              Connect
            </Typography>
          </MenuItem>
        ]}
        <MenuItem component={Link} to='/logout'>
          <ListItemIcon>
            <ExitToAppIcon className={classes.icon}/>
          </ListItemIcon>
          <Typography variant="subtitle2" color="primary">Logout</Typography>
        </MenuItem>
        {userProfile.is_site_admin && (
          [
            <MenuItem key="site-admin-menu-1" component={Link} to='/siteadmin/user'>
              <ListItemIcon>
                <SiteAdminIcon className={classes.icon}/>
              </ListItemIcon>
              <Typography variant="subtitle2" color="primary">Site Admin</Typography>
            </MenuItem>,
            <MenuItem key="site-admin-menu-2" component={Link} to='/siteadmin/service-matching'>
              <ListItemIcon>
                <OfflineBoltIcon className={classes.icon}/>
              </ListItemIcon>
              <Typography variant="subtitle2" color="primary">Service Matching</Typography>
            </MenuItem>,
            <MenuItem key="site-admin-menu-3" component={Link} to='/portal/provider-dashboard'>
              <ListItemIcon>
                <SupervisorAccountIcon className={classes.icon}/>
              </ListItemIcon>
              <Typography variant="subtitle2" color="primary">Portal</Typography>
            </MenuItem>,
            <MenuItem onKeyDown={event => event.stopPropagation()} key="site-admin-menu-3">
              <ProviderSearch
                placeholder='Providers...'
                onClick={(uuid) => history.push(`/provider/${uuid}/admin/profile`)}
              />
            </MenuItem>
          ]
        )}
      </Menu>

    </React.Fragment>
  );
};

UserNavMenu.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  userProfile: PropTypes.object,
  providerProfile: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  toggleAuthMenu: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    userProfile: state.auth.profile,
    providerProfile: state.provider.profile,
  };
};

export default connect(mapStateToProps)(UserNavMenu);
