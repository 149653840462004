import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';

import Button from 'components/Button';


const baseButtonStyle = {
  minWidth: '120px',
  height: '45px',
  borderRadius: '12px',
  whiteSpace: 'nowrap',
};

const useStyles = makeStyles(theme => ({
  nextButton: {
    ...baseButtonStyle,
    background: theme.palette.secondary.main,
    marginRight: '0px',
    boxShadow: theme.shadow(30),
    minWidth: '160px',
    '&:hover': {
      background: theme.palette.common.fauxTiffanyBlue,
    }
  },
  backButton: {
    ...baseButtonStyle,
    background: theme.palette.common.white,
    color: theme.palette.common.battleshipGrey,
    border: '1px solid',
    borderColor: theme.palette.common.lightBorder,
    '&:hover': {
      background: theme.palette.grey[100],
    }
  },
}));

const FlowButton = ({ type, className='', ...props }) => {
  const classes = useStyles();
  const styleClass = `${type === 'back' ? classes.backButton : classes.nextButton} ${className}`;

  return <Button className={styleClass} {...props} />;
};

FlowButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
};

export default FlowButton;