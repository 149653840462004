import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import CustomInput from './CustomInput';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '1.4rem',
  },
}));

const PhoneNumberInput = ({ label, value, errorText, helperText, onChange }) => {
  const classes = useStyles();
  const [validationActive, setValidationActive] = useState(false);
  return (
    <CustomInput
      label={label}
      value={value}
      onChange={e=>onChange(e.target.value)}
      onBlur={()=>setValidationActive(true)}
      validationActive={validationActive}
      errorText={errorText}
      helperText={helperText}
      startAdornment={(
        <InputAdornment position="start">
          <PhoneIcon className={classes.icon} />
        </InputAdornment>
      )}
    />
  );
};

PhoneNumberInput.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.string,
  errorText: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  onChange: PropTypes.func.isRequired,
};

export default PhoneNumberInput;
