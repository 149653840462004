import CardList from './components/CardList';
import Container from '@material-ui/core/Container';
import ContentHero from './components/ContentHero';
import ExplanationText from './components/ExplanationText';
import {Helmet} from 'react-helmet';
import Markdown from '../../components/Markdown';
import Page from '../../components/Page';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from './components/Slider';
import Team from './components/Team';
import config from 'config';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import myPlanManagerLogo from 'assets/partners/my-plan-manager.svg';
import aaaLogo from 'assets/partners/aaa.svg';
import hicapsLogo from 'assets/partners/hicaps.svg';
import ndspLogo from 'assets/partners/ndsp.svg';
import youFirstLogo from 'assets/partners/you-first-logo.png';
import mypLogo from 'assets/partners/myp.svg';


const PARTNERS = [
  {
    image: youFirstLogo,
    body: 'You First delivers quality NDIS and Aged Care supports. With tailored ' +
      'services in support work, capacity building, support coordination and ' +
      'plan management, they empower participants to achieve their goals, offering ' +
      'accessible, safe, reliable care through dedicated and experienced teams.',
    imageWidth: '110px',
    imageHeight: '50px',
  },
  {
    image: myPlanManagerLogo,
    body: 'We’re introducing Clickability’s directory and service request feature ' +
      'to My Plan Manager’s participants, ensuring they have greater choice and' +
      ' control when finding suitable providers. This also brings increased visibility' +
      ' to our accredited providers.',
    imageWidth: '120px',
    imageHeight: '50px',
  },
  {
    image: aaaLogo,
    body: 'Since 2007, Autism Awareness Australia (AAA) has supported autistic ' +
      'people and their families. In collaboration with AAA, we hope to empower ' +
      'autistic people and help them find tailored therapies and supports, so that ' +
      'they can live the lives they wish to live. ',
    imageWidth: '100px',
    imageHeight: '50px',
  },
  {
    image: hicapsLogo,
    body: 'HICAPS is Australia\'s top claims solution, offering NDIS providers a ' +
      'quick and easy claims process. Integrating Clickability service requests' +
      ' with their platform means participants have enhanced choice and control, ' +
      'with more providers to choose from.',
    imageWidth: '130px',
    imageHeight: '50px',
  },
  {
    image: ndspLogo,
    body: 'NDSP participants and their families can now effortlessly discover ' +
      'quality providers on Clickability\'s directory, which has been integrated ' +
      'into the NDSP dashboard. Partnering with NDSP has allowed us to simplify ' +
      'search and offer participants access to a diverse range of providers. ',
    imageWidth: '150px',
    imageHeight: '50px',
  },
  {
    image: mypLogo,
    body: 'Clickability elevates client management for MYP plan managers, so they ' +
      'can in turn support their participant through enhanced choice and control ' +
      'when searching for providers. Together, we propel NDIS businesses forward, ' +
      'allowing them to focus on the work that truly matters.',
    imageWidth: '114px',
    imageHeight: '50px',
  },
];

const useStyles = makeStyles(theme => ({
  block1: {
    marginTop: '50px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 28px',
      textAlign: 'justify',
      '& .MuiTypography-h2': {
        textAlign: 'center',
      },
    },
  },
  explanationText: {
    backgroundColor: theme.palette.common.white,
  },
  team: {
    margin: '60px 0',
  },
}));

const AboutPage = ({content}) => {
  const classes = useStyles();

  const history = useHistory();

  if (!content) return null;

  return (
    <Page title={content.title}>
      <Helmet 
        link = {[{'rel': 'canonical', 'href': `${config.website.URL}${content.path}`}]}
      />
      <ContentHero
        text={content.heroText}
        buttonText={content.heroButtonText}
        image={{src: content.heroImage}}
        onClick={() => history.push('/requestmatch')}
      />
      <Container maxWidth="md" fixed disableGutters>
        <div className={classes.block1}>
          <Markdown content={content.missionText}/>
        </div>
      </Container>
      <Container maxWidth="lg" fixed disableGutters>
        <ExplanationText
          className={classes.explanationText}
          text={content.explanationTextOne}
          image={{src: content.explanationImageOne}}
        />
      </Container>
      <Slider data={content.slide}/>
      <CardList id="partners" title="Our Partners" data={PARTNERS} />
      <Container maxWidth="md" fixed disableGutters>
        <Team title="Meet our friendly team" data={content.person} className={classes.team} />
      </Container>
      <CardList title="How Clickability can help" data={content.cards} />
      <Container maxWidth="lg" fixed disableGutters>
        <ExplanationText
          className={classes.explanationText}
          text={content.explanationTextTwo}
          image={{src: content.explanationImageTwo}}
        />
      </Container>

    </Page>
  );
};

AboutPage.propTypes = {
  content: PropTypes.object,
};

export default AboutPage;
