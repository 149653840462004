import { QueryClient } from '@tanstack/react-query';
import React from 'react';
import camelcaseKeys from 'camelcase-keys';
import { notification } from 'antd';

const handleError = error => {
  const err = error?.body ? camelcaseKeys(error.body, {deep: true}) : {};

  const description = (
    <>
      Status Code: {err.statusCode}
      <br/>
      Message: {err.message}
      <br/><br/>
      Please contact Customer Support.
    </>
  );

  notification.destroy();
  notification.error({
    message: 'Oops something went wrong',
    description: description,
    duration: 10,
    placement: 'bottomLeft',
  });
};

// Global React Query client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: handleError,
      retry: 1,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: handleError,
    },
  },
});