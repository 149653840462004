import MailTo from 'components/MailTo.js';
import PropTypes from 'prop-types';
import React from 'react';
import config from 'config';
import { withStyles } from '@material-ui/core/styles';

// USAGE:
// import ContactText from 'components/ContactText';
// ...
// return <div>For assistance or more information {ContactText}.</div>;

const emailStyle = theme => ({
  email: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

// This component provides a styled, clickable email address component using config.socials.infoEmail
const EmailContact = withStyles(emailStyle)(
  ({classes, email=config.socials.infoEmail}) => <MailTo className={classes.email} email={email}><b>{email}</b></MailTo>
);

const PhoneContact = ({phone=config.phone}) => <b>{phone}</b>;

PhoneContact.propTypes = {
  phone: PropTypes.string,
};

// ContactText will either display as:
//   give us a call on 1800 123 456 or email hello@clickability.com.au
// or
//   email hello@clickability.com.au
// It is intended to be used as follows:
//   <div>For assistance or more information <ContactText/>.</div>;
const ContactText = ({email=config.socials.infoEmail, phone=config.phone}) => 
  phone ? 
    <React.Fragment>give us a call on <PhoneContact phone={phone}/> or email <EmailContact email={email}/></React.Fragment> :
    <React.Fragment>email <EmailContact email={email}/></React.Fragment>;

ContactText.propTypes = {
  phone: PropTypes.string,
  email: PropTypes.string,
};

export default ContactText;

export {
  PhoneContact,
  EmailContact,
};