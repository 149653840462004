import ItemList from './ItemList.js';
import { ItemListContainer } from './ItemList';
import PropTypes from 'prop-types';
import React from 'react';
import SectionDivider from 'components/SectionDivider';
import SocialLinks from './SocialLinks.js';
import { SpecialisationChipList } from 'components/specialisation/SpecialisationChip';
import TextContent from './TextContent.js';
import TreeListDisplay from 'components/tree/TreeListDisplay';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  tick: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.common.tealish,
    marginRight: '0.5rem',
  },
}));

const ProfileInfoPanel = ({ orgProfile }) => {
  const classes = useStyles();
  const registrations = [];
  const showSocialLinks = (orgProfile.facebook_url !== null || orgProfile.twitter_url !== null || orgProfile.instagram_url !== null);

  if (orgProfile.ndis_registered) {
    registrations.push({ id: 1, name: 'NDIS Registered' });
  }

  if (orgProfile.ndis_early_childhood_registered) {
    registrations.push({ id: 2, name: 'NDIS Early Childhood Registered' });
  }

  return (
    <div className={classes.root}>
      {orgProfile.services && orgProfile.services.length ? (
        <React.Fragment>
          <SectionDivider />
          <TreeListDisplay title="Services available" data={orgProfile.services} />
        </React.Fragment>
      ) : null}
      {orgProfile.service_locations && orgProfile.service_locations.length ? (
        <React.Fragment>
          <SectionDivider />
          <TreeListDisplay title="Service locations" data={orgProfile.service_locations} />
        </React.Fragment>
      ) : null}
      {orgProfile.specialisations && orgProfile.specialisations.length ? (
        <React.Fragment>
          <SectionDivider />
          <ItemListContainer title="Specialisations">
            <SpecialisationChipList specialisations={orgProfile.specialisations} />
          </ItemListContainer>
        </React.Fragment>
      ) : null}
      {orgProfile.access_methods && orgProfile.access_methods.length ? (
        <React.Fragment>
          <SectionDivider />
          <ItemList title="Access methods" data={orgProfile.access_methods} />
        </React.Fragment>
      ) : null}
      {registrations && registrations.length ? (
        <React.Fragment>
          <SectionDivider />
          <ItemList title="NDIS Registrations" data={registrations} />
        </React.Fragment>
      ) : null}
      {orgProfile.age_groups && orgProfile.age_groups.length ? (
        <React.Fragment>
          <SectionDivider />
          <ItemList title="Age groups" data={orgProfile.age_groups} />
        </React.Fragment>
      ) : null}
      {orgProfile.organisation_type === null ? null : (
        <React.Fragment>
          <SectionDivider />
          <TextContent title="Organisation type" data={orgProfile.organisation_type} capitalize />
        </React.Fragment>
      )}
      {showSocialLinks ? (
        <React.Fragment>
          <SectionDivider />
          <SocialLinks data={orgProfile} />
        </React.Fragment>
      ) : null}
      {orgProfile.good_to_know === null ? null : (
        <React.Fragment>
          <SectionDivider />
          <TextContent title="Good to know" data={orgProfile.good_to_know} />
        </React.Fragment>
      )}
      <SectionDivider />
      <ItemList title="Languages" data={orgProfile.languages} />
    </div>
  );
};

ProfileInfoPanel.propTypes = {
  orgProfile: PropTypes.object.isRequired,
};

export default ProfileInfoPanel;
