import * as URI from 'uri-js';

import BuzzsproutPlayer from 'components/media/BuzzsproutPlayer';
import { Link as InternalLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import MailTo from './MailTo';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    fontWeight: '500',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '& thead th': {
      color: '#264f73',
      fontWeight: '900',
      backgroundColor: 'rgb(244, 245, 247)',
      borderColor: 'rgb(193, 199, 208)',
      borderStyle: 'solid',
      borderWidth: '1px',
      textAlign: 'left',
      padding: '8px 8px 8px 10px',
    },
    '& tbody td': {
      padding: '8px',
      borderColor: 'rgb(193, 199, 208)',
      borderStyle: 'solid',
      borderWidth: '1px',
    }
  },
  paragraph: {
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
  },
}));

const Header = ({children, level}) => {
  return (
    <Typography variant={'h'+level} color="primary">{children}</Typography>
  );
};
Header.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.number.isRequired,
};

// we change paragraphs from <p> to <div> to avoid 
// warnings about divs embedded in p's when we use
// eg Tweets within paragraphs.
const Paragraph = ({node, ...props}) => {
  const classes = useStyles();
  if (node) return <div className={classes.paragraph} {...props}/>;
};
Paragraph.propTypes = {
  node: PropTypes.object,
};

const Tweet = ({args}) => {
  const [tweetId] = args;  // matches from the regular expression
  return <TwitterTweetEmbed tweetId={tweetId}/>;
};
Tweet.propTypes = {
  args: PropTypes.array.isRequired,
};

const YouTubeVideo = ({args}) => {
  const [videoId] = args;
  return <ReactPlayer url={videoId}/>;
};
YouTubeVideo.propTypes = {
  args: PropTypes.array.isRequired,
};

const BuzzsproutAudio = ({args}) => {
  const [id1, id2] = args;
  return <BuzzsproutPlayer id1={id1} id2={id2}/>;
};
BuzzsproutAudio.propTypes = {
  args: PropTypes.array.isRequired,
};

const specialLinks = [
  [/https:\/\/twitter.com\/.*\/status\/([0-9]+)/, Tweet],
  [/(https:\/\/youtube.com\/.*)/, YouTubeVideo],
  [/https:\/\/www.buzzsprout.com\/([0-9]+)\/([0-9]+)/, BuzzsproutAudio],
];

const Address = ({children='', href, ...props}) => {
  // markdown does not pass children any more.  We default to '' to avoid errors from Link which requires children.
  const classes = useStyles();
  const uri = URI.parse(href);
  const scheme = uri.scheme;
  // we distinguish three types of links:
  //   relative links are handled with the react-router-dom Link component
  //   mailto links are handled with the MailTo component
  //   other links are handled with the MUI Link component
  if (scheme === undefined) {
    // relative links are <Link to=...>
    return <InternalLink to={uri.path} className={classes.link} {...props}>{children}</InternalLink>;
  } else if (scheme === 'mailto') {
    return <MailTo email={uri.to[0]} className={classes.link} {...props}>{children}</MailTo>;
  }

  // special links matched by regex
  for (const [re, Component] of specialLinks) {
    const match = href.match(re);
    if (match) return <Component args={match.slice(1)}/>;
  }

  // default link
  return <Link href={href} className={classes.link} underline="always" target="_blank" rel="noopener noreferrer" {...props}>{children}</Link>;
};
Address.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
  props: PropTypes.object,
};

const Table = ({columnAlignment, ...props}) => {  // eslint-disable-line no-unused-vars
  const classes = useStyles();
  return <table className={classes.table} {...props}/>;
};
Table.propTypes = {
  columnAlignment: PropTypes.array,  // passed by markdown, ignored in our renderer.
};

const baseRenderers = {
  'h1': Header,
  'h2': Header,
  'h3': Header,
  'h4': Header,
  'h5': Header,
  'h6': Header,
  'a': Address,
  'p': Paragraph,
  'table': Table,
};

const Markdown = ({content, renderers, ...props}) => {
  return (
    <ReactMarkdown components={{...baseRenderers, ...renderers}} {...props}>
      {content}
    </ReactMarkdown>
  );
};

Markdown.propTypes = {
  content: PropTypes.string,
  renderers: PropTypes.object,
};

export default Markdown;
