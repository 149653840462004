import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {default as MuiLink} from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sendEvent } from 'lib/track';

const styles = makeStyles(theme => ({
  button: {
    padding: '1.25rem 0 0.5rem 0',
    marginLeft: '1rem',
    marginRight: '1rem',
    fontSize: '1.2rem',
    fontWeight: 500,
    boxShadow: 'none',
    border: 'none',
    borderBottom: `3px solid ${theme.palette.common.white}`,
    borderRadius: 0,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      border: 'none',
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    }
  },
  fullButton: {
    minWidth: '100px',
    marginLeft: '1rem',
    fontWeight: 500,
    borderRadius: '10px',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1.1rem',
    '&:focus': {
      outline: '-webkit-focus-ring-color auto 1px',
    },
  },
  menuItem: {
    fontSize: '16px',
    fontWeight: '400',
    color: theme.palette.primary.main,
    padding: '10px 100px 10px 20px',
    '&:first-child': {
      paddingTop: '15px',
    },
    '&:last-child': {
      paddingBottom: '15px',
    },
    '&:hover': {
      background: theme.palette.common.highlightBlue,
    },
  },
  link: {
    display: 'block',
    textAlign: 'center',
    fontSize: '1.1rem',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  menu: {
    position: 'relative',
    '& .MuiMenu-paper': {
      borderRadius: '12px',
      marginTop: '18px',
    },
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

const NavButton = ({children, variant, to, ...props}) => {
  const classes = styles();
  const defaults = {
    to,
    variant: variant || 'text',
    color: 'primary',
    className: classes.button,
  };

  // for contained and outlined use alternative css
  if (['contained', 'outlined'].includes(variant)) {
    defaults.className = classes.fullButton;
  }

  // if to prop is defined, component need to be set to Link
  if (to !== undefined) {
    defaults.component = Link;
  }

  return (
    <Button disableRipple disableFocusRipple {...defaults} {...props}>
      {children}
    </Button>
  );
};

NavButton.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  to: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
};

const ExternalLink = ({href, children}) => {
  const classes = styles();
  return <MuiLink className={classes.link} href={href} target="_blank" rel="noopener noreferrer">{children}</MuiLink>;
};

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const NavButtonWithMenu = ({children, items}) => {
  const [anchorEl, setAnchorEl] = React.useState();
  const onOpen = (event) => setAnchorEl(event.currentTarget);
  const onClose = (eventName) => {
    setAnchorEl(undefined);
    if (eventName) sendEvent(eventName, 'click');
  };
  const open = anchorEl !== undefined;
  const classes = styles();

  return (
    <>
      <NavButton onClick={onOpen}>
        {children}{open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
      </NavButton>
      <Menu
        elevation={3}
        className={classes.menu}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={() => onClose()}
      >
        {items.map(({label, link, href}) => {
          const eventName = `navbar ${children}/${label}`;
          if (link) {
            return (
              <MenuItem
                classes={{root: classes.menuItem}}
                component={Link}
                to={link}
                key={link}
                onClick={() => onClose(eventName)}>
                {label}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                classes={{root: classes.menuItem}}
                key={href}
                onClick={() => onClose(eventName)}>
                <ExternalLink href={href}>{label}</ExternalLink>
              </MenuItem>
            );
          }
        })}
      </Menu>
    </>
  );
};

NavButtonWithMenu.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.array.isRequired,
};


export default NavButton;
export { NavButton, NavButtonWithMenu, ExternalLink };