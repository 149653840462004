import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles( theme =>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '300px',
    marginLeft: '-10px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      borderRadius: '12px',
    },
  },
}));

const PickList = ({ title, availableOptions, onSelect, hideOther }) => {
  const classes = useStyles();

  const otherValue = 'other';

  const [selectedValue, setSelectedValue] = useState('');

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>{title}</InputLabel>
        <Select
          value={selectedValue}
          onChange={event => {
            const val = event.target.value;
            setSelectedValue(val);
            if (val === otherValue) {
              onSelect(null);
            } else {
              onSelect(val);
            }
          }}
          label={title}
        >
          {availableOptions.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
          {!hideOther && <MenuItem value={otherValue}>Other (give details)</MenuItem>}
        </Select>
      </FormControl>
      {selectedValue === otherValue && (
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            label="Details"
            variant="outlined"
            onChange={event => onSelect(event.target.value)}
          />
        </FormControl>
      )}
    </div>
  );
};

PickList.propTypes = {
  title: PropTypes.string,
  availableOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  hideOther: PropTypes.bool,
};

export default PickList;