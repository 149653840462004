import AddIcon from '@material-ui/icons/Add';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { default as BaseButton } from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const baseStyle = {
  button: {
    textTransform: 'none',
    boxShadow: 'none',
    '& .MuiSvgIcon-root': {
      transition: 'transform 300ms ease-in-out',
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        transform: 'translate(5px, 0px)',
      },
      textDecoration: 'none',
    },
  },
  spinner: {
    color: '#ffffff',
    marginLeft: '0.66rem',
  }
};

const largeStyle = makeStyles(() => ({
  ...baseStyle,
  button: {
    ...baseStyle.button,
    padding: '0.4rem 1.5rem',
    fontWeight: 700,
    marginRight: '15px',
  },
}));

const regularStyle = makeStyles(() => ({
  ...baseStyle,
  button: {
    ...baseStyle.button,
    boxShadow: 'none',
    fontSize: '0.75rem',
    marginRight: '15px',
  },
}));

const backStyle = makeStyles(() => ({
  ...baseStyle,
  button: {
    ...baseStyle.button,
    padding: '0.4rem 1.5rem',
    fontWeight: 700,
    marginRight: '15px',
    boxShadow: 'none',
    '& .MuiSvgIcon-root': {
      transition: 'transform 300ms ease-in-out',
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        transform: 'translate(-5px, 0px)',
      },
    },
  },
}));

const classNames = (...names) => {
  return names.filter(name => name !== undefined).join(' ');
};

const Button = ({label, to, onClick, variant='contained', color='primary', cssStyle='large', pointer='right', disabled=false, busy=false, className=undefined, ...props}) => {
  let classes = largeStyle();
  if (cssStyle === 'regular') {
    classes = regularStyle();
  }
  if (cssStyle === 'back') {
    classes = backStyle();
  }
  const action = busy ? {} : to ? {component: Link, to: to} : {onClick : onClick};
  return (
    <BaseButton
      className={classNames(classes.button, className)}
      variant={variant}
      color={color}
      disabled={disabled}
      {...props}
      {...action}
    >
      {!busy && pointer === 'left' ? <ChevronLeftIcon /> : null}
      {label}
      {!busy && pointer === 'right' ? <ChevronRightIcon /> : null}
      {!busy && pointer === 'plus' ? <AddIcon /> : null}
      {!busy && pointer === 'check' ? <CheckIcon /> : null}
      {!busy && pointer === 'alt-right' ? <ArrowRightAltIcon /> : null}
      {busy ? <CircularProgress className={classes.spinner} color="primary" size={15} thickness={6}/> : null}
    </BaseButton>
  );
};

Button.propTypes = {
  label: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onClick: PropTypes.func,
  variant: PropTypes.string,
  color: PropTypes.string,
  cssStyle: PropTypes.string,
  pointer: PropTypes.string,
  disabled: PropTypes.bool,
  busy: PropTypes.bool,
  className: PropTypes.string,
};

export default Button;
