import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Typography } from 'antd';

import style from 'style';
import _Container from '../Container';
import Circle from './Circle';


const TAB_SWITCH_CONTAINER_HEIGHT = '52px';
const EXTENDED_TAB_SCREEN_WIDTH = `${parseInt(style.screens.tab, 10) + 140}px`;

const Main = styled.div`
  display: flex;
  justify-content: center;
  background: ${style.colors.primary};
  padding: 70px 0;
  position: relative;
  overflow: hidden;
  
  .circle {
    position: absolute;
    
    &-left {
      left: -70px;
      bottom: 80px;
    }
    
    &-right {
      top: -200px;
      right: -200px;
    }
    
    @media (max-width: ${style.screens.tab}){
      &-left, &-right {
        display: none;
      }
    }
  }
`;

const Container = styled(_Container)`
  .title {
    margin-bottom: 50px;
    color: ${style.colors.palette.white};
  }
  
  @media (max-width: ${EXTENDED_TAB_SCREEN_WIDTH}) {
    margin-bottom: 30px;
  }
`;

const TabContainer = styled.div`
  width: calc(100% - 100px);
  border-radius: ${style.borderRadius.large};
  box-shadow: ${style.shadow(20)};
  height: 500px;
  background: ${style.colors.palette.white};
  position: relative;
  z-index: 1;
  
  @media (max-width: ${EXTENDED_TAB_SCREEN_WIDTH}) {
    height: fit-content;
  }
  
  @media (max-width: ${style.screens.mobile}) {
    flex-direction: row;
    width: 100%;
  }
`;

const TabSwitchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: ${4 * TAB_SWITCH_CONTAINER_HEIGHT};
  
  @media (max-width: ${style.screens.mobile}) {
    flex-direction: column;
  }
`;

const TabSwitch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-color: ${style.colors.lightBorder};
  width: 100%;
  text-align: center;
  padding: 15px 20px;
  background: ${style.colors.palette.white};
  color: ${style.colors.palette.black};
  transition: all 0.5s;
  font-weight: 500;
  &:first-child {
    border-top-left-radius: ${style.borderRadius.large};
  }
  &:last-child {
    border-top-right-radius: ${style.borderRadius.large};
    border-right: 0;
  }
  &:hover {
    cursor: pointer;
    background: ${style.colors.secondary};
    color: ${style.colors.palette.white};
  }
  
  @media (max-width: ${style.screens.mobile}) {
    padding: 15px 20px;
    border-right: 0;
    &:first-child {
      border-top-right-radius: ${style.borderRadius.large};
    }
    &:last-child {
      border-top-right-radius: 0;
    }
  }
  
  ${({isSelected}) => isSelected && css`
    background: ${style.colors.secondary};
    color: ${style.colors.palette.white};
  `}
`;

const Benefits = ({ title, tabsContent }) => {
  const isDesktop = useMediaQuery({minWidth: EXTENDED_TAB_SCREEN_WIDTH});
  const [tab, setTab] = useState(0);

  const handleTabClick = value => setTab(value);

  return (
    <Main>
      <Circle className="circle circle-right" diameter="400px"/>
      <Container size="large" flex>
        <Typography.Title className="title" level={isDesktop ? 1 : 2}>
          {title}
        </Typography.Title>
        <TabContainer>
          <Circle className="circle circle-left" diameter="100px"/>
          <TabSwitchContainer>
            {tabsContent.map(({ key, title }) => (
              <TabSwitch key={key} isSelected={tab === key} onClick={() => handleTabClick(key)}>
                {title}
              </TabSwitch>
            ))}
          </TabSwitchContainer>
          {tabsContent.find(({key}) => key === tab)?.tab}
        </TabContainer>
      </Container>
    </Main>
  );
};

Benefits.propTypes = {
  title: PropTypes.string,
  tabsContent: PropTypes.array
};

const TabContentContainer = styled.div`
  display: flex;
  column-gap: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  height: calc(100% - ${TAB_SWITCH_CONTAINER_HEIGHT});
  overflow: hidden;
  .right {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
  
  @media (max-width: ${EXTENDED_TAB_SCREEN_WIDTH}) {
    padding: 20px 30px 0 30px;
    column-gap: 40px;
    height: fit-content;
    align-items: center;
    .left {
      flex-basis: 45%;
      padding : 20px 0;
    }
    .right {
      height: fit-content;
      flex-basis: 45%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  
  @media (max-width: ${style.screens.mobile}) {
    flex-direction: column-reverse;
    padding: 20px 30px;
    .left {
      flex-basis: 100%;
    }
    .right {
      flex-basis: 100%;
      margin-bottom: 20px;
      img {
        width: 80%;
        margin: auto;
      }
    }
  }
`;

const TabContent = ({ title, left, right }) => {
  const isDesktop = useMediaQuery({minWidth: EXTENDED_TAB_SCREEN_WIDTH});
  return (
    <TabContentContainer>
      <div className="left">
        <Typography.Title level={isDesktop ? 2 : 3} style={{color: style.colors.secondary}}>
          {title}
        </Typography.Title>
        {left}
      </div>
      <div className="right">
        {right}
      </div>
    </TabContentContainer>
  );
};

TabContent.propTypes = {
  title: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
};


export {
  Benefits,
  TabContent
};
