import React from 'react';
import PropTypes from 'prop-types';
import { prettyLocalDate } from '../../../lib/time';
import { makeStyles } from '@material-ui/core/styles';

import styled from 'styled-components';
import { Tag as _Tag } from 'antd';
import style from 'style';

const useStyles = makeStyles({
  container: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '14px',
    borderColor: style.colors.lightBorder,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: '1rem',
    color: '#000000',
    padding: '.8rem',
    boxShadow: style.shadow(10),
  },
  inner: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const Tag = styled(_Tag)`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
`;

const Tag2 = styled(Tag)`
  font-size: 11px;
  color: #767676;
  font-weight: 500;
`;

const PlanSummary = ({plan, discountedPrice, allowFreeTrial=true}) => {
  const classes = useStyles();
  const billingDate = prettyLocalDate(plan.first_billing_date);
  const price = discountedPrice ? discountedPrice : plan.price;
  var dueToday = '0.00';
  var dueLater = price;
  if (plan.trial_days === 0 || !allowFreeTrial) {
    dueToday = price;
    dueLater = undefined;
  }

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <div className={classes.row}>
          <span>Due Today</span>
          <Tag color="green">A${dueToday} (Incl. GST)</Tag>
        </div>
        {dueLater &&
          <div className={classes.row} style={{marginTop: '5px'}}>
            <span>Due {billingDate}</span>
            <Tag2>A${dueLater} (Incl. GST)</Tag2>
          </div>
        }
      </div>
    </div>
  );
};

PlanSummary.propTypes = {
  plan: PropTypes.object,
  allowFreeTrial: PropTypes.bool,
  discountedPrice: PropTypes.number,
};

export default PlanSummary;
