import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { Button as AntdButton, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faCircleCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';
import { AnalyticsAPI, ProviderAPI, ServiceRequestAPI, useGetApi, useMutateApi } from 'providerSite/api';
import Spinner from 'components/Spinner';
import DateTimeFrom from 'components/DateTimeFrom';


const Container = styled.div`
  padding: 5px 20px 10px 20px;
  min-height: 231px;
  font-size: 13px;
  
  .section-title {
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .flex-container {
    display: flex;
    column-gap: 20px;
    margin-bottom: 30px;
  }
`;

const DataItemContainer = styled.div`
  display: flex;
  column-gap: 50px;
  row-gap: 5px;
  flex-wrap: wrap;
  max-width: 600px;
`;

const BoostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 600px;
  max-height: 155px;
  overflow: auto;
`;

const Button = styled(AntdButton)`
  font-size: 12px;
  border-radius: 8px;
`;


const ExpandedRow = ({ providerUuid }) => {

  const { isLoading, data: _data } = useGetApi(ProviderAPI.GET_PROVIDER_FOR_OPERATION, {
    params: {
      uuid: providerUuid,
    },
  });

  const { data: _statsData, isLoading: isStatsDataLoading } = useGetApi(AnalyticsAPI.GET_PROVIDER_VALUES, {
    params: {
      providerUuid: providerUuid,
    },
    queryParams: {
      period: '30_days',
    },
  });

  const createBoostMutation = useMutateApi(ServiceRequestAPI.POST_BOOST, 'POST', {
    invalidation: [
      {
        endpoint: ProviderAPI.GET_PROVIDER_FOR_OPERATION,
        params: {
          uuid: providerUuid,
        },
      }
    ],
  });

  const data = _data ? _data : {};
  const statsData = _statsData ? _statsData : [];

  const renderStats = () => (
    <DataItemContainer>
      {statsData.map((item, idx) => (
        <Data
          key={idx}
          title={_.startCase(item.title.replaceAll('_', ' '))}
          width="120px"
        >
          {item.value}
        </Data>
      ))}
    </DataItemContainer>
  );

  const renderDetails = () => (
    <DataItemContainer>
      <Data title="Name">
        {data.name}
      </Data>
      <Data title="Slug">
        {data.slug}
      </Data>
      <Data title="UUID">
        {data.uuid}
      </Data>
      <Data title="Fresh Reviews">
        {data.freshReviewCount}
      </Data>
      <Data title="Total Responses">
        {data.totalResponseCount}
      </Data>
      <Data title="Total Users">
        {data.userCount}
      </Data>
    </DataItemContainer>
  );

  const renderBoost = () => (
    <BoostsContainer>
      {data.boosts?.map((item, idx) => (
        <Tag key={idx} color={item.usedAt ? 'success' : 'blue'}>
          <div style={{display: 'flex', columnGap: '3px', alignItems: 'center'}}>
            <FontAwesomeIcon
              icon={item.usedAt ? faCircleCheck : faArrowsRotate}
              color={item.usedAt ? style.colors.success : style.colors.info}
              spin={!item.usedAt}
              fade={item.usedAt}
              style={{marginRight: '5px'}}
            />
            <div>Created:</div>
            <DateTimeFrom value={item.createdAt} />
            {item.usedAt && (
              <>
                |
                <div>Used:</div>
                <DateTimeFrom value={item.usetAt}/>
              </>
            )}
          </div>
        </Tag>
      ))}
    </BoostsContainer>
  );

  return (
    <Container>
      <div className="flex-container">
        <div>
          <div className="section-title">
            Provider Details
          </div>
          {isLoading ? <Spinner/> : renderDetails()}
        </div>
        <div>
          <div className="section-title">
            Analytics (Last 30 days)
          </div>
          {isStatsDataLoading ? <Spinner/> : renderStats()}
        </div>
      </div>
      <div className="flex-container">
        <div>
          <div className="section-title">
            Service Request Boost ({data.boosts?.length})
            <Button
              size="small"
              type="primary"
              onClick={() => createBoostMutation.mutate({providerUuid})}
              disabled={!data}
              isLoading={createBoostMutation.isLoading}
              style={{marginLeft: '10px'}}
              danger
            >
              <FontAwesomeIcon
                icon={faStar}
                color={style.colors.palette.white}
                style={{
                  marginBottom: '1px',
                  marginRight: '3px',
                }}
              />
              Give Boost
            </Button>
          </div>
          {isLoading ? <Spinner/> : renderBoost()}
        </div>
      </div>
      <div className="flex-container" style={{marginBottom: '0px'}}>
        <Button
          size="small"
          type="primary"
          onClick={() => window.open(`/provider/${data.uuid}/admin/listings`, '_blank')}
          disabled={!data}
        >
          Profile
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => window.open(`https://clicka.zendesk.com/agent/organizations/${data.supportCrmRef}/tickets`, '_blank')}
          disabled={!data.supportCrmRef}
        >
          Zendesk
        </Button>
      </div>
    </Container>
  );
};

ExpandedRow.propTypes = {
  providerUuid: PropTypes.string.isRequired,
};


const DataContainer = styled.div`
  margin-top: auto;
  width: ${({width}) => width && width};

  .title {
    font-weight: 500;
    color: ${style.colors.palette.battleshipGrey};
    margin-bottom: ${({extraGap}) => extraGap ? '2px' : '-1px'};
    margin-left: ${({extraGap}) => extraGap && '2px'};
    font-size: 11px;
  }
  
  .ellipsis {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  },
`;

const Data = ({children, title, extraGap, ellipsis, width}) => {
  return (
    <DataContainer extraGap={extraGap} width={width}>
      <div className="title">
        {title}
      </div>
      <div className={ellipsis && 'ellipsis'}>
        {children}
      </div>
    </DataContainer>
  );
};

Data.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  extraGap: PropTypes.bool,
  ellipsis: PropTypes.bool,
  width: PropTypes.string,
};


export default ExpandedRow;

