import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../components/Page.js';
import { AuthStore } from '../../store';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const LogoutPage = ({dispatch}) => {
  const history = useHistory();
  dispatch(AuthStore.logoutUser());
  history.push('/');

  return (
    <Page title="Clickability Logout">
    </Page>
  );
};

LogoutPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(LogoutPage);
