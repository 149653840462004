import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, IconButton } from '@material-ui/core';
import CustomOutlinedInput from './CustomOutlinedInput';
import ClearIcon from '@material-ui/icons/Clear';
import InputContainer from './InputContainer';
import { formatComponentId } from 'lib/util.js';

const baseStyles = makeStyles(() => ({
  endIconButton: {
    padding: '3px',
    marginRight: '4px',
  },
  icon: {
    fontSize: '1.4rem',
  },
}));

const tagInputStyles = makeStyles(() => ({
  ...baseStyles,
  input: {
    flexWrap: 'wrap',
  },
}));

const CustomInput = ({value, label, placeholder, multiline=false, rows, validationActive, errorText, helperText, inputRef, inputProps, InputLabelProps, InputProps, onChange, onBlur, onClearInput, startAdornment}) => {
  const tagInput = inputProps !== undefined && inputProps.type === 'tag';
  const classes = tagInput ? tagInputStyles() : baseStyles();
  const componentId = formatComponentId('input', label);
  const error = validationActive && errorText !== undefined && errorText !== '';

  const clearInputAdornment = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="clear selection"
          className={classes.endIconButton}
          onClick={onClearInput}
        >
          <ClearIcon className={classes.icon}/>
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <InputContainer
      label={label}
      componentId={componentId}
      validationActive={validationActive}
      errorText={errorText}
      helperText={helperText}
      {...InputLabelProps}
    >
      <CustomOutlinedInput
        className={classes.input}
        value={value}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
        inputRef={inputRef}
        inputProps={inputProps}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        startAdornment={startAdornment}
        endAdornment={onClearInput === undefined ? null : clearInputAdornment()}
        {...InputProps}
      />
    </InputContainer>
  );
};

CustomInput.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  validationActive: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  inputRef: PropTypes.func,
  inputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClearInput: PropTypes.func,
  startAdornment: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default CustomInput;
