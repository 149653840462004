import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Page from '../../components/Page.js';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AuthAPI } from '../../api';
import { AuthStore } from '../../store';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(() => ({
  alert: {
    maxWidth: '20rem',
    marginLeft: '1rem',
  },
  center: {
    marginTop: '4rem',
    marginBottom: '4rem',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ProviderCallbackPage = ({dispatch}) => {
  const classes = useStyles();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');
  const [error, setError] = useState(query.get('error'));
  const login = async (code) => {
    try {
      const redirectUrl = window.location.origin + window.location.pathname;
      const { payload } = await AuthAPI.providerLogin(code, redirectUrl);
      const { profile, id, token_ttl } = payload;
      dispatch(AuthStore.login(profile, id, token_ttl));
      history.replace('/');
    } catch (err) {
      console.error(err);   // eslint-disable-line no-console
      setError('An error occurred logging in.  Please try again.');
    }
  };

  if (code) {
    login(code);
  }

  return (
    <Page title="Clickability Provider Login">
      <Container className={classes.center}>
        {error &&
          <Alert severity="error" className={classes.alert}>
            {error}
          </Alert>
        }
        {!error &&
          <CircularProgress color="primary" />
        }
      </Container>
    </Page>
  );
};

ProviderCallbackPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  success: PropTypes.string,
};

export default connect()(ProviderCallbackPage);
