import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Divider } from 'antd';
import styled from 'styled-components';
import _BackButton from './BackButton';


const Container = styled.div`
  position: relative;
`;


const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BackButton = styled(_BackButton)`
  position: absolute;
  top: -30px;
  left: -2px;
`;


const Header = ({ children, text, backButton }) => {

  return (
    <Container>
      {backButton && <BackButton />}
      <TitleContainer>
        <Typography.Title>{text}</Typography.Title>
        {children}
      </TitleContainer>
      <Divider />
    </Container>
  );
};


Header.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
  backButton: PropTypes.bool,
};


export default Header;
