import React, { useRef, useState } from 'react';

import styled from 'styled-components';


const RenderTimeContainer = styled.div`
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .timer-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .time-wrapper {
    position: relative;
    width: 80px;
    height: 30px;
    font-size: 40px;
    font-weight: 500;
    color: ${({$color}) => $color && $color};
    margin-bottom: 10px;
  }
  
  .time-wrapper .time {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    opacity: 1;
    transition: all 0.2s;
  }
  
  .time-wrapper .time.up {
    opacity: 0;
    transform: translateY(-100%);
  }
  
  .time-wrapper .time.down {
    opacity: 0;
    transform: translateY(100%);
  }
  
  .name {
    font-size: 12px;
    text-align: center;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
  }
`;

export const renderTime = (remainingTime, color) => {
  const currentTime = useRef(remainingTime);
  const prevTime = useRef(null);
  const isNewTimeFirstTick = useRef(false);
  const [, setOneLastRerender] = useState(0);

  if (currentTime.current !== remainingTime) {
    isNewTimeFirstTick.current = true;
    prevTime.current = currentTime.current;
    currentTime.current = remainingTime;
  } else {
    isNewTimeFirstTick.current = false;
  }

  // force one last re-render when the time is over to tirgger the last animation
  if (remainingTime === 0) {
    setTimeout(() => {
      setOneLastRerender((val) => val + 1);
    }, 20);
  }

  const isTimeUp = isNewTimeFirstTick.current;

  return (
    <RenderTimeContainer $color={color}>
      <div className="time-wrapper">
        <div key={remainingTime} className={`time ${isTimeUp ? 'up' : ''}`}>
          {remainingTime}
        </div>
        <div className="name">Seconds</div>
        {prevTime.current !== null && (
          <div
            key={prevTime.current}
            className={`time ${!isTimeUp ? 'down' : ''}`}
          >
            {prevTime.current}
          </div>
        )}
      </div>
    </RenderTimeContainer>
  );
};

