import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SectionTitle from '../../../components/SectionTitle.js';
import Typography from '@material-ui/core/Typography';

const baseStyle = {
  root: {
    width: '100%',
    padding: '1rem 0',
  },
  text: {
    paddingTop: '0.5rem',
  }
};

const normStyle = makeStyles({
  ...baseStyle,
});

const capStyle = makeStyles({
  ...baseStyle,
  text: {
    paddingTop: '0.5rem',
    textTransform: 'capitalize',
  },
});


const TextContent = ({title, data, capitalize=false}) => {
  const classes = capitalize ? capStyle() : normStyle();
  return (
    <div className={classes.root}>
      <SectionTitle title={title} />
      <Typography aria-label={title} variant="body2" className={classes.text}>
        {data}
      </Typography>
    </div>
  );
};

TextContent.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  capitalize: PropTypes.bool,
};

export default TextContent;
