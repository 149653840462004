import React from 'react';

import Page from 'components/Page.js';
import {
  Hero,
  HowWeHelp,
  CallToAction,
  ServicesCovered,
  ConnectWithProviders,
} from './components';
import ServiceRequestSurvey from 'components/ServiceRequestSurvey';
import Partners from 'components/Partners';


const Home = () => {

  return (
    <Page title="Clickability">
      <Hero />
      <Partners />
      <HowWeHelp />
      <ServicesCovered />
      <ConnectWithProviders />
      <CallToAction />
      <ServiceRequestSurvey />
    </Page>
  );
};

export default Home;
