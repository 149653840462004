import React from 'react';
import PropTypes from 'prop-types';

import {
  PLAN_TYPE_AGENCY_MANAGED, PLAN_TYPE_NOT_WITH_NDIS,
  PLAN_TYPE_PLAN_MANAGED,
  PLAN_TYPE_SELF_MANAGED, PLAN_TYPE_UNSURE,
  PLAN_TYPE_WAITING_FOR_APPROVAL
} from '../../../constants';
import MultiChoiceSelection from '../../components/MultiChoiceSelection';


const NDISPlan = ({ value, onChange, setPagename, nextPage }) => {

  const planTypes = [
    PLAN_TYPE_SELF_MANAGED,
    PLAN_TYPE_PLAN_MANAGED,
    PLAN_TYPE_AGENCY_MANAGED,
    PLAN_TYPE_WAITING_FOR_APPROVAL,
    PLAN_TYPE_NOT_WITH_NDIS,
    PLAN_TYPE_UNSURE,
  ];

  const handleGoNextPageOnClick = () => {
    setPagename(nextPage.name);
  };

  const handleOnChange = values => {
    onChange(values[0]);
  };

  return (
    <MultiChoiceSelection
      data={planTypes}
      selectedValues={value ? [value] : []}
      onChange={handleOnChange}
      isSingleChoice={true}
      handleSingleChoiceClick={handleGoNextPageOnClick}
    />
  );
};

NDISPlan.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  setPagename: PropTypes.func,
  nextPage: PropTypes.object,
};

export default NDISPlan;