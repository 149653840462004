import React from 'react';

import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';

import Page from 'components/Page';
import { useGetApi, ServiceRequestAPI } from 'providerSite/api';
import AdditionalQuestions from './components/AdditionalQuestions';
import Spinner from 'components/Spinner';
import Tracking from './components/Tracking';


const Container = styled.div`
  min-height: calc(100vh - 300px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const Dashboard = () => {

  const { uuid } = useParams();
  const location = useLocation();

  const {
    isLoading,
    data: _data,
  } = useGetApi(ServiceRequestAPI.GET_SERVICE_INQUIRY, {
    params: {
      serviceInquiryUuid: uuid,
    },
    staleTime: 0,   // No caching
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 30,   // Auto re-fetch every 30 seconds
  });

  const data = _data ? _data : {};

  if (isLoading) return (
    <Page title="Service Request Dashboard" style={{alignItems: 'center'}}>
      <Spinner />
    </Page>
  );

  const showQuestions = location?.state?.showAdditionalQuestions;

  return (
    <Page title="Service Request Dashboard" contentStyle={!showQuestions ? {marginTop: '-60px'} : {}}>
      <Container>
        {showQuestions ? (
          <AdditionalQuestions data={data} serviceInquiryUuid={uuid} />
        ) : (
          <Tracking data={data} />
        )}
      </Container>
    </Page>
  );
};

Dashboard.propTypes = {};


export default Dashboard;

