import {
  CheckboxInputField,
  RadioItemListArrays,
} from 'components/input';

import AssistantOutlinedIcon from '@material-ui/icons/AssistantOutlined';
import DirectionsOutlinedIcon from '@material-ui/icons/DirectionsOutlined';
import EmojiPeopleOutlinedIcon from '@material-ui/icons/EmojiPeopleOutlined';
import FilterPanelItem from './FilterPanelItem';
import FingerprintOutlinedIcon from '@material-ui/icons/FingerprintOutlined';
import LocationFilter from './LocationFilter';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import TranslateOutlinedIcon from '@material-ui/icons/TranslateOutlined';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import SearchBox from 'components/SearchBox.js';
import SectionTitle from 'components/SectionTitle.js';
import Skeleton from '@material-ui/lab/Skeleton';
import {AuthStore, TaxonomyStore} from 'store';
import { connect } from 'react-redux';
import { itemFromArray } from 'lib/util.js';
import { makeStyles } from '@material-ui/core/styles';
import LanguageFilter from './LanguageFilter';
import CheckboxItemList from 'components/input/CheckboxItemList';
import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';
import style from 'style';
import QualifiedServiceIcon from 'components/QualifiedServiceIcon';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    'box-shadow': 'rgb(145 158 171 / 15%) 0px 0px 4px 0px, rgb(145 158 171 / 10%) 2px -2px 20px 0px',
    borderRadius: '12px',
    padding: '12px 14px 8px 14px',
    border: '1px solid #e2e2e2',
  },
  skeleton: {
    width: '100%',
  },
  icon: {
    color: theme.palette.common.tealish,
    marginRight: '8px',
  },
  checkboxList: {
    '& .MuiFormControlLabel-root': {
      width: '200px',
    }
  }
}));

const Select = styled(AntdSelect)` 
  margin-bottom: 5px;
  .ant-select-selector {
    min-height: 40px !important;
  }
  .ant-select-selection-item {
    height: auto !important;
  }
  .ant-select-selection-item-remove {
    margin-top: 5px !important;
    margin-right: 5px !important;
  }
`;

const FilterPanel = (
  {
    filterClick,
    filterClose,
    searchSubmit,
    params,
    setLocation,
    serviceTaxonomy,
    accessMethodTaxonomy,
    ageGroupTaxonomy,
    specialisationTaxonomy,
    dispatch,
    handleFilterClick,
    excludedServices,
  }) => {
  const classes = useStyles();

  const [selectedSpecialisations, setSelectedSpecialisations] = useState([]);

  const specialisationIdToNameMap = {};
  const specialisationNameToIdMap = {};

  specialisationTaxonomy.forEach(item => {
    specialisationIdToNameMap[item.id] = item.name;
    specialisationNameToIdMap[item.name] = item.id;
  });

  const handleSelectedSpecialisations = event => {
    const value = Number.parseInt(event.target.value);
    handleFilterClick('specialisation', specialisationIdToNameMap[value]);
    setSelectedSpecialisations(prevState => {
      const previousValues = new Set(prevState);
      if (previousValues.has(value)) {
        previousValues.delete(value);
      }
      else {
        previousValues.add(value);
      }
      return Array.from(previousValues);
    });
  };

  useEffect(() => {
    const specialisationParams = params?.specialisation;
    if (!specialisationParams) {
      setSelectedSpecialisations([]);
    } else {
      const urlParams = Array.isArray(specialisationParams) ? specialisationParams : [specialisationParams];
      setSelectedSpecialisations(urlParams.map(item => specialisationNameToIdMap[item]));
    }
  }, [params?.specialisation, specialisationTaxonomy]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (value) => {
    searchSubmit(value);
  };

  const handleCheckboxClick = (event, name) => {
    if (name in params) {
      filterClose(name);
    } else {
      filterClick(name, true);
    }
  };

  const radioChangeHandler = (filter, taxonomy) => (value) => {
    const filterName = value ? itemFromArray(taxonomy, 'id', parseInt(value)).name : undefined;
    filterClick(filter, filterName);
  };

  const handleServiceSelectionClick = (value) => {

    const serviceParents = serviceTaxonomy.map(item => item.name);

    if (!!value && !serviceParents.includes(value))
      handleFilterClick('service', value);
  };

  React.useEffect(() => {
    // make sure all taxonomies are loaded
    dispatch(TaxonomyStore.refreshTaxonomy('services'));
    dispatch(TaxonomyStore.refreshTaxonomy('accessMethods'));
    dispatch(TaxonomyStore.refreshTaxonomy('ageGroups'));
    dispatch(TaxonomyStore.refreshTaxonomy('specialisations'));
    dispatch(TaxonomyStore.refreshTaxonomy(TaxonomyStore.LANGUAGE_TAXONOMY));
    dispatch(AuthStore.refreshProfile());
  }, [dispatch]);

  if (!serviceTaxonomy.length || !specialisationTaxonomy ||!ageGroupTaxonomy.length || !accessMethodTaxonomy.length) {
    return (
      <div className={classes.skeleton}>
        <SectionTitle title="Refine results" variant="h6" />
        <Skeleton animation="wave" height={100}/>
        <Skeleton animation="wave" height={100}/>
        <Skeleton animation="wave" height={100}/>
        <Skeleton animation="wave" height={100}/>
        <Skeleton animation="wave" height={100}/>
      </div>
    );
  }

  const ndis_reg = ('ndis_registered' in params) ? (params.ndis_registered === 'true') : false;
  const ndisec_reg = ('ndisec_registered' in params) ? (params.ndisec_registered === 'true') : false;

  let selectedAge = null;
  if ('age_group' in params) {
    const ageItem = itemFromArray(ageGroupTaxonomy, 'name', params.age_group);
    selectedAge = ageItem.id;
  }

  let selectedAccess = null;
  if ('access_method' in params) {
    const accessItem = itemFromArray(accessMethodTaxonomy, 'name', params.access_method);
    selectedAccess = accessItem.id;
  }

  const _getKey = index => `Option Row - ${index}`;
  const _serviceNames = serviceTaxonomy ? serviceTaxonomy.reduce((acc, item) => {
    const services = item.children?.map((item, idx) => ({value: item.name, label: <Row key={_getKey(idx)} name={item.name} />}));
    acc.push(...services);
    return acc;
  }, []) : [];

  const excludedServicesSet = Array.isArray(excludedServices) ? new Set(excludedServices) : new Set([]);

  const serviceNames = _serviceNames.filter(item => !excludedServicesSet.has(item.value.toLowerCase()));

  function compare( a, b ) {
    if ( a.value < b.value ){
      return -1;
    }
    if ( a.value > b.value ){
      return 1;
    }
    return 0;
  }

  serviceNames.sort(compare);

  return (
    <div className={classes.root}>
      {/*<UserFeaturesCTA />*/}
      <SectionTitle title="Refine results" variant="h6" />

      <div style={{marginBottom: '1rem'}}>
        <SearchBox
          onSubmit={handleSearch}
          value={params.search_text}
        />
      </div>

      <FilterPanelItem
        title="Service Location"
        icon={<LocationOnOutlinedIcon className={classes.icon} />}
      >
        <LocationFilter
          onEnter={setLocation}
          value={params.service_location}
        />
      </FilterPanelItem>

      <FilterPanelItem
        title="Service"
        icon={<AssistantOutlinedIcon className={classes.icon} />}
      >
        <Select
          mode="multiple"
          style={{
            width: '100%',
          }}
          value={params?.service}
          placeholder="Support Work, Cleaning ..."
          onSelect={handleServiceSelectionClick}
          onDeselect={handleServiceSelectionClick}
          options={serviceNames}
        />
      </FilterPanelItem>

      <FilterPanelItem
        title="NDIS Registration"
        icon={<PlaylistAddCheckOutlinedIcon className={classes.icon}/>}
      >
        <React.Fragment>
          <CheckboxInputField
            label="NDIS Registered"
            value={ndis_reg}
            onChange={(event) => handleCheckboxClick(event, 'ndis_registered')}
          />
          <CheckboxInputField
            label="NDIS Early Childhood Registered"
            value={ndisec_reg}
            onChange={(event) => handleCheckboxClick(event, 'ndisec_registered')}
          />
        </React.Fragment>
      </FilterPanelItem>

      <FilterPanelItem
        title="Age Group"
        icon={<EmojiPeopleOutlinedIcon className={classes.icon} />}
      >
        <RadioItemListArrays
          levels="single"
          items={ageGroupTaxonomy}
          value={selectedAge}
          onChange={radioChangeHandler('age_group', ageGroupTaxonomy)}
          allowDeselect={true}
        />
      </FilterPanelItem>

      <FilterPanelItem
        title="Access Method"
        icon={<DirectionsOutlinedIcon className={classes.icon} />}
      >
        <RadioItemListArrays
          levels="single"
          items={accessMethodTaxonomy}
          value={selectedAccess}
          onChange={radioChangeHandler('access_method', accessMethodTaxonomy)}
          allowDeselect={true}
        />
      </FilterPanelItem>

      <FilterPanelItem
        title="Language"
        icon={<TranslateOutlinedIcon className={classes.icon} />}
      >
        <LanguageFilter handleFilterClick={handleFilterClick} urlParam={params}/>
      </FilterPanelItem>

      <FilterPanelItem
        title="Specialisation"
        icon={<FingerprintOutlinedIcon className={classes.icon} />}
      >
        <CheckboxItemList
          items={specialisationTaxonomy}
          checkedItems={selectedSpecialisations}
          onChange={handleSelectedSpecialisations}
          className={classes.checkboxList}
        />
      </FilterPanelItem>

    </div>
  );
};

FilterPanel.propTypes = {
  filterClick: PropTypes.func.isRequired,
  filterClose: PropTypes.func.isRequired,
  searchSubmit: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  setLocation: PropTypes.func.isRequired,
  serviceTaxonomy: PropTypes.array.isRequired,
  accessMethodTaxonomy: PropTypes.array.isRequired,
  ageGroupTaxonomy: PropTypes.array.isRequired,
  specialisationTaxonomy: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleFilterClick: PropTypes.func,
  excludedServices: PropTypes.array,
};

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 30px;
  
  .icon {
    width: 16px;
    color: ${style.colors.palette.blackSlate};
  }
`;

const Row = ({ name }) => {
  return (
    <RowContainer>
      <QualifiedServiceIcon.Regular serviceName={name} className="icon" />
      <div>{name}</div>
    </RowContainer>
  );
};

Row.propTypes = {
  name: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    serviceTaxonomy: state.taxonomy.services,
    accessMethodTaxonomy: state.taxonomy.accessMethods,
    ageGroupTaxonomy: state.taxonomy.ageGroups,
    specialisationTaxonomy: state.taxonomy.specialisations,
  };
};

export default connect(mapStateToProps)(FilterPanel);
