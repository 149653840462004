import React from 'react';

import camelcaseKeys from 'camelcase-keys';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import ProviderCardList from 'components/provider/ProviderCardList.js';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '1rem',
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.common.silver}`,
  },
  tabContent: {
    padding: '1rem 0',
  },
  paginationText: {
    padding: '1rem 0',
  },
}));

const TabsPanel = (
  {
    providers,
    onPageChange,
    isLoading,
    externalDirectoryMode,
  }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {providers.total > 0 ? (
        <Typography variant="body2" className={classes.paginationText}>
          {providers.paginationText} Service Providers.
        </Typography>
      ) : null}
      <ProviderCardList
        isLoading={isLoading}
        data={providers.data ? camelcaseKeys(providers.data, {deep: true}) : {}}
        externalDirectoryMode={externalDirectoryMode}
        nullMessage='Sorry, we didn&apos;t find any providers that matched your search query.'
      />
      {providers.total > 0 ? (
        <React.Fragment>
          <Typography variant="body2" className={classes.paginationText}>
            {providers.paginationText} Service Providers. Select page:
          </Typography>
          <Pagination
            count={providers.pageCount}
            page={providers.currentPage}
            variant="outlined"
            shape="rounded"
            onChange={onPageChange}
          />
        </React.Fragment>
      ) : null}
    </div>
  );

};

TabsPanel.propTypes = {
  providers: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  externalDirectoryMode: PropTypes.bool,
};

export default TabsPanel;
