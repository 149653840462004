import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/pro-solid-svg-icons';


const AdminAccessButton = ({ uuid }) => {

  const history = useHistory();

  return (
    <Button
      type="primary"
      danger
      style={{height: '45px'}}
      disabled={!uuid}
      onClick={() => history.push({pathname: `/provider/${uuid}/admin/profile`})}
    >
      <FontAwesomeIcon icon={faWandMagicSparkles} style={{marginRight: '8px'}}/>
      Edit Profile
      <div style={{fontSize: '11px', lineHeight: '11px', marginTop: '-2px'}}>Admin Access</div>
    </Button>
  );
};

AdminAccessButton.propTypes = {
  uuid: PropTypes.string,
};


export default AdminAccessButton;

