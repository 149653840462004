import AuthFormPage from './components/AuthFormPage';
import React from 'react';
import SignupForm from './components/SignupForm';
import { useHistory } from 'react-router-dom';

const SignUpPage = () => {
  const history = useHistory();

  const signup = (email) => {
    history.push('/signup/confirm', {email});
  };

  return (
    <AuthFormPage title="Clickability Signup">
      <SignupForm
        onComplete={signup}
        title="Create your Clickability account"
        loginLink="/login"
      />
    </AuthFormPage>
  );
};

export default SignUpPage;
