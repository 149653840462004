import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.white,
    width: '240px',
    height: '160px',
    filter: 'drop-shadow(0 0 3px #e0e0e0)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '12px',
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
    color: theme.palette.secondary.dark,
    marginTop: '10px',
  },
  subTitle: {
    textAlign: 'center',
    color: theme.palette.common.battleshipGrey,
  },
}));

const InfoBox = ({ icon, title, subTitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={icon} alt="icon" />
      <div className={classes.title}>{title}</div>
      <div className={classes.subTitle}>{subTitle}</div>
    </div>
  );
};

InfoBox.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default InfoBox;