import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';

import style from 'style';
import QualifiedServiceIcon from 'components/QualifiedServiceIcon';


const Container = styled.div`
  width: 100%;
  max-width: 750px;
  box-shadow: ${style.shadow(10)};
  border: 1px solid ${style.colors.lightBorder};
  padding: 15px 20px 15px 20px;
  border-radius: 20px;
  .title {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;

const Select = styled(AntdSelect)` 
  margin-bottom: 5px;
  .ant-select-selector {
    border-bottom: 1px solid ${style.colors.lightBorder} !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }
  .ant-select-selection-item {
    height: auto !important;
    margin-top: -4px;
  }

  .ant-select-selection-search {
    left: 0 !important;
  }
  .ant-select-selection-search-input {
    // To stop mobile devices auto zoom while typing inputs
    @media (max-width: ${style.sizes.small}) {
      font-size: 16px;
    }
  }
`;

const SearchBox = (
  {
    title,
    options,
    placeholder,
    selectedValue,
    onSearchChange,
    onSelect,
  }) => {

  const [serviceValue, setServiceValue] = useState();
  const selectRef = useRef();

  function compare( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }

  const _SortedServiceTaxonomy = options?.sort(compare);

  const _getKey = index => `Option Row - ${index}`;
  const { serviceTaxonomy, serviceTaxonomyMap } = _SortedServiceTaxonomy?.reduce((acc, item, index) => {
    acc.serviceTaxonomy.push({
      label: (
        <Row
          key={_getKey(index)}
          name={item.name}
        />
      ),
      value: item.name,
      data: item,
    });
    acc.serviceTaxonomyMap[item.name] = item;
    return acc;
  }, {serviceTaxonomy: [], serviceTaxonomyMap: {}}) || {};


  const handleServiceSelect = value => {
    setServiceValue(value);
    onSearchChange(serviceTaxonomyMap[value]);
    selectRef?.current?.blur();
    onSelect && onSelect();
  };

  const handleServiceClear = () => {
    setServiceValue(null);
    onSearchChange(null);
  };

  useEffect(() => {
    setServiceValue(selectedValue?.name);
  }, [selectedValue]);

  return (
    <Container>
      <div className="title">
        {title}
      </div>
      <Select
        ref={selectRef}
        allowClear
        showSearch
        style={{
          width: '100%',
        }}
        value={serviceValue}
        placeholder={placeholder}
        onSelect={handleServiceSelect}
        onClear={handleServiceClear}
        options={serviceTaxonomy}
        bordered={false}
      />
    </Container>
  );
};

SearchBox.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  selectedValue: PropTypes.object,
  onSearchChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 38px;
  font-weight: 500;

  .icon-container {
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    border-radius: 6px;
  }

  .icon {
    width: 20px;
    color: ${style.colors.palette.black};
  }
`;

const Row = ({ name }) => {
  return (
    <RowContainer>
      <div className="icon-container">
        <QualifiedServiceIcon.Regular serviceName={name} className="icon" />
      </div>
      <div>{name}</div>
    </RowContainer>
  );
};

Row.propTypes = {
  name: PropTypes.string,
};


export default SearchBox;