import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import config from 'config';


const useStyles = makeStyles(theme => ({
  root: {
    width: '205px',
    fontSize: '16px',
  },
  text: {
    marginTop: '8px',
    color: theme.palette.common.nightBlue,
    fontWeight: '450',
  },
  image: {
    width: '205px',
    height: '144px',
    borderRadius: '14px',
    filter: 'drop-shadow(0 0 3px #e0e0e0)',
  },
  path: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    marginTop: '16px',
    '& a': {
      color: theme.palette.secondary.dark,
      fontWeight: '600',
    },
    '& a:hover': {
      color: theme.palette.common.nightBlue,
    },
  },
}));

const BlogPost = ({text, imageUrl, path}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={`${config.website.URL}${imageUrl}`} alt="blog"/>
      <div className={classes.text}>{text}</div>
      <div className={classes.path}>
        <a href={`${config.website.URL}${path}`} target="_blank" rel="noopener noreferrer">Read more</a>
      </div>
    </div>
  );
};

BlogPost.propTypes = {
  text: PropTypes.string,
  imageUrl: PropTypes.string,
  path: PropTypes.string,
};

export default BlogPost;