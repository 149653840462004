import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/pro-duotone-svg-icons';

import Spinner from 'components/Spinner';
import Pagination from './components/Pagination';
import TableBody from './components/TableBody';
import TableHeader from './components/TableHeader';
import style from 'style';



const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const NoDataContainer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  align-items: center;
  border: 1px solid ${style.colors.lightBorder};
  border-radius: ${style.borderRadius.large};
  box-shadow: ${style.shadow(15)};
`;

const Table = ({
  isLoading,
  onPaginationClick,
  onRowClick,
  metaData,
  columns,
  data,
  filters,
  onFilterClick,
  showSearchBar,
  onSearch,
  searchPlaceHolder,
}) => {
  const renderTableBody = (isLoading, data, metaData) => {
    if (isLoading) {
      return (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      );
    }

    if ( data.length > 0 && metaData ) {
      return (
        <>
          <TableBody data={data} columns={columns} onRowClick={onRowClick}/>
          <Pagination data={metaData} onClick={onPaginationClick}/>
        </>
      );
    }

    return (
      <NoDataContainer>
        <FontAwesomeIcon icon={faExclamation} size="4x" color={style.colors.error}/>
        <Typography.Text>Sorry, No Data Available</Typography.Text>
      </NoDataContainer>
    );
  };

  return (
    <>
      <TableHeader
        columns={columns}
        filters={filters}
        onFilterClick={onFilterClick}
        showSearchBar={showSearchBar}
        onSearch={onSearch}
        searchPlaceHolder={searchPlaceHolder}
      />
      {renderTableBody(isLoading, data, metaData)}
    </>
  );
};

Table.propTypes = {
  isLoading: PropTypes.bool,
  onPaginationClick: PropTypes.func,
  onRowClick: PropTypes.func,
  metaData: PropTypes.shape({
    count: PropTypes.number,
    pageSize: PropTypes.number,
    page: PropTypes.number,
    numPages: PropTypes.number,
    hasNextPage: PropTypes.bool,
    hasPrevPage: PropTypes.bool
  }),
  columns: PropTypes.arrayOf(PropTypes.shape({
    dataIndex: PropTypes.string,
  })),
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string
  })),
  filters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    count: PropTypes.number,
  })),
  onFilterClick: PropTypes.func,
  showSearchBar: PropTypes.bool,
  onSearch: PropTypes.func,
  searchPlaceHolder: PropTypes.string
};

export default Table;
