import { useHistory, useLocation } from 'react-router-dom';

import Button from '../Button';
import { ExternalLink } from './NavButton';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import Zoom from '@material-ui/core/Zoom';
import { makeStyles } from '@material-ui/core/styles';
import { sendEvent } from 'lib/track';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPopover-paper': {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      borderRadius: '0 0 50px 50px',
      maxWidth: 'unset',
      boxShadow: '0px 10px 15px -4px rgba(0,0,0,0.32)'
    },
  },
  container: {
    padding: '0px 32px 20px 32px',
  },
  subMenu: {
    paddingLeft: '1rem',
    borderTop: `1px solid ${theme.palette.common.lightBorder}`,
    borderBottom: `1px solid ${theme.palette.common.lightBorder}`,
  },
  authSubMenu: {
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderTopColor: theme.palette.common.lightBorder,
    borderBottomColor: theme.palette.common.lightBorder,
    margin: '15px 0',
    padding: '10px 0 0 0',
  },
  authButton: {
    fontWeight: 500,
    fontSize: '1.2rem',
    margin: '10px 0',
    width: '100%',
    padding: '8px',
    borderRadius: '10px',
    maxWidth: '500px',
  },
  textButton: {
    justifyContent: 'flex-start',
    fontWeight: 500,
    fontSize: '1.2rem',
    margin: '0.5rem 0',
    padding: '5px 0',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

const MobileMenu = ({appBarRef, isOpen, items, hideNav, hideAuth, authMenu, setAuthMenu, close}) => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const redirectTo = location.pathname + location.search;
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const toggleAuthMenu = value => {
    if (authMenu !== null) {
      setAuthMenu(null);
    } else {
      setAuthMenu(value);
    }
  };

  const handleAuthClick = userType => {
    if (userType === 'participant') {
      if (authMenu === 'login') {
        history.push({pathname: '/login', state:{redirectTo}});
      } else {
        history.push('/signup');
      }
    } else {
      if (authMenu === 'login') {
        history.push({pathname: '/providers/login', state:{redirectTo}});
      } else {
        history.push('/providers/signup');
      }
    }
    setAuthMenu(null);
  };

  const MobileLinkItem = ({label, href, link, onClick, eventPrefix=''}) => {
    const labelOrExternalLink = link ? label : <ExternalLink href={href}>{label}</ExternalLink>;
    const clickHandler = () => {
      if (onClick) onClick();
      if (close) close();
      if (link) history.push(link);
      sendEvent(`navbar ${eventPrefix}${label}`, 'click');
    };

    return (
      <Button
        key={label}
        variant="text"
        color="primary"
        fullWidth
        disableRipple
        label={labelOrExternalLink}
        className={classes.textButton}
        onClick={clickHandler}
        pointer="none"
      />
    );
  };

  MobileLinkItem.propTypes = {
    label: PropTypes.string.isRequired,
    href: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func,
    eventPrefix: PropTypes.string,  // pass parent menu label for generating nested component name
  };

  const MobileSubmenu = ({label, items}) => {
    const [open, setOpen] = React.useState(false);
    return (
      <>
        <Button
          variant="text"
          color="primary"
          fullWidth
          disableRipple
          label={label}
          className={classes.textButton}
          onClick={() => setOpen(!open)}
        />
        {open && 
          <div className={classes.subMenu}>
            {items.map(item =>
              <MobileLinkItem 
                key={item.label}
                label={item.label}
                link={item.link}
                href={item.href}
                eventPrefix={`${label}/`}
                onClick={() => setOpen(false)}
              />
            )}
          </div>
        }
      </>
    );
  };
  MobileSubmenu.propTypes = {
    label: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
  };

  const MobileMenuItem = ({label, link, href, submenu}) => {
    if (submenu) {
      return <MobileSubmenu label={label} items={submenu}/>;
    }
    return <MobileLinkItem label={label} link={link} href={href}/>;
  };

  MobileMenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    link: PropTypes.string,
    href: PropTypes.string,
    submenu: PropTypes.array,
  };

  const renderAuthenticatedMenu = () => {
    return (
      <React.Fragment>
        <div style={{width: '100%'}}>
          <Button
            variant="text"
            color="primary"
            disableRipple
            label="My Profile"
            className={classes.textButton}
            to="/profile"
          />
        </div>
        <div style={{width: '100%'}}>
          <Button
            variant="outlined"
            color="primary"
            disableRipple
            label="Logout"
            className={classes.authButton}
            to="/logout"
          />
        </div>
      </React.Fragment>
    );
  };

  const renderUnauthenticatedMenu = () => {
    return (
      <React.Fragment>
        <div style={{width: '100%'}}>
          <Button
            variant="contained"
            color="primary"
            disableRipple
            label="Login"
            className={classes.authButton}
            onClick={()=>toggleAuthMenu('login')}
          />
        </div>
        {authMenu === 'login' ? (
          <div className={classes.authSubMenu}>
            <Button
              variant="contained"
              color="primary"
              disableRipple
              label="Log in as an NDIS participant"
              className={classes.authButton}
              style={{width: '20rem'}}
              onClick={()=>handleAuthClick('participant')}
            />
            <Button
              variant="text"
              color="primary"
              fullWidth
              disableRipple
              label="Log in as a Provider"
              className={classes.textButton}
              onClick={()=>handleAuthClick('provider')}
            />
          </div>
        ) : null}
        <div style={{width: '100%'}}>
          <Button
            variant="outlined"
            color="primary"
            disableRipple
            label="Register"
            className={classes.authButton}
            onClick={()=>toggleAuthMenu('signup')}
          />
        </div>
        {authMenu === 'signup' ? (
          <div className={classes.authSubMenu}>
            <Button
              variant="outlined"
              color="primary"
              disableRipple
              label="Sign up as an NDIS participant"
              style={{width: '20rem'}}
              className={classes.authButton}
              onClick={()=>handleAuthClick('participant')}
            />
            <Button
              variant="text"
              color="primary"
              fullWidth
              disableRipple
              label="Sign up as a Provider"
              className={classes.textButton}
              onClick={()=>handleAuthClick('provider')}
            />
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <Popover
      className={classes.root}
      open={isOpen}
      anchorEl={appBarRef}
      anchorReference="anchorEl"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={0}
      onClose={() => {
        setAuthMenu(null);
        close(false);
      }}
      transitionDuration={500}
      TransitionComponent={Zoom}
    >
      <div className={classes.container}>
        {!hideNav && items.map(item => <MobileMenuItem key={item.label} {...item}/>)}
        {!hideAuth && isAuthenticated ? renderAuthenticatedMenu() : null}
        {!hideAuth && !isAuthenticated ? renderUnauthenticatedMenu() : null}
      </div>
    </Popover>
  );
};

MobileMenu.propTypes = {
  appBarRef: PropTypes.instanceOf(Element),
  isOpen: PropTypes.bool,
  items: PropTypes.array.isRequired,
  hideNav: PropTypes.bool,
  hideAuth: PropTypes.bool,
  authMenu: PropTypes.string,
  setAuthMenu: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default MobileMenu;
