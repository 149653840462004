import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookReader,
  faPeopleArrows,
  faSearch,
} from '@fortawesome/pro-solid-svg-icons';

import background from 'assets/background-three-circles.svg';
import * as track from 'lib/track';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '3rem 0 6rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none',
    zIndex: 0,
    backgroundColor: theme.palette.common.white,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center right',
    backgroundSize: 'auto',
    backgroundImage: `url(${background})`,
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      padding: '1rem',
    },
  },
  content: {
    padding: '1rem 3rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      marginBottom: '1rem',
    },
  },
  header: {
    padding: '2rem 8rem',
    width: '100%',
    textAlign: 'center',
    fontSize: '28px',
    fontWeight: '600',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0',
    },
  },
  services: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2.563rem',
    columnGap: '40px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  service: {
    padding: '30px',
    border: '1px solid',
    borderColor: theme.palette.common.lightBorder,
    boxShadow: 'rgb(145 158 171 / 15%) 0px 0px 4px 0px, rgb(145 158 171 / 15%) 2px -2px 20px 0px',
    borderRadius: '14px',
    backgroundColor: theme.palette.common.white,
    width: '300px',
    height: '200px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      border: '1px solid #264F73',
      '& .MuiSvgIcon-root': {
        transform: 'translate(10px, 0px)',
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      marginBottom: '2rem',
    },
  },
  iconContainer: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '26px',
    filter: 'drop-shadow(0 0 2px #545456)',
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: '500',
    textAlign: 'center',
    marginTop: '20px',
  },
}));


const CallToAction = () => {
  const classes = useStyles();

  const onClick = (text) => {
    track.sendEvent(`${text} button`, 'click');
  };

  const renderService = (text, to, icon) => (
    <Link to={to} className={classes.service} onClick={()=> onClick(text)}>
      <div className={classes.iconContainer}>
        <FontAwesomeIcon icon={icon} className={classes.icon} />
      </div>
      <Typography variant="h3" className={classes.text}>
        {text}
      </Typography>
    </Link>
  );

  return (
    <div className={classes.root} aria-hidden="true">
      <Container maxWidth="lg" fixed disableGutters={true}>
        <div className={classes.content}>
          <Typography variant="h2" className={classes.header}>
            Whether you’re ready to go or just starting your journey, we&apos;re here to help.
          </Typography>
          <div className={classes.services}>
            {renderService('I haven\'t started my NDIS journey and need more info', '/blog', faBookReader)}
            {renderService('I have an NDIS plan and want to connect with providers', '/requestmatch', faPeopleArrows)}
            {renderService('I\'m familiar with how the NDIS works and want to browse services', '/search', faSearch)}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CallToAction;
