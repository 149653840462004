import IconSheet from './IconSheet';
import React from 'react';
import { Route } from 'react-router-dom';
import StyleGuidePage from './StyleGuidePage';
import config from '../../config';

const routes = () => {
  if (config.stage !== 'test') return [];
  return [
    <Route key="/style" exact path="/style" component={StyleGuidePage}/>,
    <Route key="/style/icons" exact path="/style/icons" component={IconSheet}/>
  ];
};

export default routes;
