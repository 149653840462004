import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TabScrollButton from '@material-ui/core/TabScrollButton';

export const TabPane = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
};

TabPane.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

export const StyledTabs = withStyles(theme => ({
  root: {
    borderBottom: 'none',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(props => <Tabs {...props} />);


export const StyledTabScrollButton = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
  },
}))(props => <TabScrollButton {...props} />);


export const StyledTab = withStyles(theme => ({
  root: {
    fontSize: '1.1rem',
    textTransform: 'none',
    padding: 0,
    minWidth: 0,
    marginRight: '3rem',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);
