import { request, makePath } from './http';
const API_NAME = 'report';

export function getReports() {
  return request('GET', makePath(API_NAME));
}

export function getReport(reportName) {
  return request('GET', makePath(API_NAME, reportName));
}

export function getReportUrl(reportName) {
  return request('GET', makePath(API_NAME, reportName, 'url'));
}
