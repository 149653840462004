import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ConnectDashboard from '../../components/ConnectDashboard';
import Header from '../../components/Header';
import NotificationSettings from 'modules/admin/components/NotificationSettings';


const ConnectNotificationSettings = () => {
  const { uuid: providerUuid } = useParams();
  const profile = useSelector(state => state.auth.profile);

  return (
    <ConnectDashboard title="Notification Settings">
      <Header text="Notification Settings" />
      <NotificationSettings
        providerUuid={providerUuid}
        userUuid={profile?.user_uuid}
        isConnect
      />
    </ConnectDashboard>
  );
};


export default ConnectNotificationSettings;