import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { useSessionStorage } from 'react-use';
import styled from 'styled-components';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from 'style';
import Item from './components/Item';
import { pages } from '../../pages';
import { hasPagePermission } from '../utils';
import Header from './components/Header';
import { NAV_SIZE_MIN, NAV_SIZE_MAX, NAV_SIZE_STORAGE_KEY } from '../constants';


const Container = styled.div`
  background: #f5f5f5;
  height: 100%;
  width: ${({navSize}) => navSize === NAV_SIZE_MIN ? '72px' : '240px'};
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid ${style.colors.lightBorder};
  border-top: none;
  box-shadow: ${style.shadow(15)};
  position: relative;
  transition: width 0.4s;
  flex-shrink: 0;
`;

const Divider = styled.div`
  --margin: ${({navSize}) => navSize === NAV_SIZE_MIN ? '10px' : '20px'};
  margin-left: var(--margin);
  height: 1px;
  width: calc(100% - var(--margin) * 2);
  background: #e9e9e9;
  margin-top: auto;
  margin-bottom: 30px;
`;

const CircleButton = styled.div`
  --size: 22px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: ${style.colors.secondary};
  position: absolute;
  top: 22px;
  right: calc(-1 * var(--size) / 2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${style.colors.palette.white};
  font-size: 12px;
  border: 1px solid ${style.colors.lightBorder};
  box-shadow: ${style.shadow(15)};
  &:hover {
    background: ${style.colors.primary};
  }
`;

const Top = styled.div`
  overflow: hidden;
  height: 160px;
  display: flex;
  flex-direction: column;
`;

const SideNav = ({ navSize, pulseItems }) => {
  const location = useLocation();

  const { uuid: providerUuid } = useParams();
  const [_navSize, _setNavSize] = useSessionStorage(NAV_SIZE_STORAGE_KEY, NAV_SIZE_MAX);
  const path = location?.pathname;
  const profile = useSelector(state => state.auth.profile);

  const regex = /.+\/dashboard\/([\w-]+)/;
  const currentPageUri = path.match(regex)[1];

  const handleNavSizeButtonClick = () => {
    _setNavSize(_navSize === NAV_SIZE_MIN ? NAV_SIZE_MAX : NAV_SIZE_MIN);
  };

  useEffect(() => {
    if (![NAV_SIZE_MAX, NAV_SIZE_MIN].includes(navSize)) return;
    _setNavSize(navSize);
  }, [navSize]);

  return (
    <Container navSize={_navSize}>
      <CircleButton onClick={handleNavSizeButtonClick}>
        <FontAwesomeIcon icon={_navSize === NAV_SIZE_MIN ? faChevronRight : faChevronLeft} />
      </CircleButton>
      <Top>
        <Header providerUuid={providerUuid} navSize={_navSize} />
        <Divider navSize={_navSize} />
      </Top>
      {pages.reduce((acc, item, index) => {
        if (!hasPagePermission(profile, providerUuid, item.permission) || item.hidden)
          return acc;
        const _item = (
          <Item
            key={`Item-${index}`}
            name={item.name}
            icon={item.icon}
            uri={item.uri}
            isSelected={item.uri === currentPageUri || item.childItemUri === currentPageUri}
            navSize={_navSize}
            pulse={pulseItems && pulseItems.has(item.uri)}
          />
        );
        acc.push(_item);
        return acc;
      }, [])}
    </Container>
  );
};

SideNav.propTypes = {
  navSize: PropTypes.string,
  pulseItems: PropTypes.instanceOf(Set).isRequired,
};

export default SideNav;
