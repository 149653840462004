import React, { useState } from 'react';

import Alert from '@material-ui/lab/Alert';
import { AuthAPI } from '../../../api';
import AuthField from './AuthField';
import AuthForm from './AuthForm';
import Checkbox from '@material-ui/core/Checkbox';
import FacebookLoginButton from '../../../components/social/FacebookLoginButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GoogleLoginButton from '../../../components/social/GoogleLoginButton';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const DEFAULT_ERROR_MESSAGE = 'An error occurred creating your account.  Please try again later.';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: '1.5rem',
  },
  content: {
    maxWidth: '30rem',
  },
  divider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  border: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    width: '100%',
  },
  dividerText: {
    padding: '0 10px 0 10px',
    color: `1px solid ${theme.palette.primary.main}`,
  },
  social: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));

const SignupForm = ({onComplete, title, loginLink, signupToken, hideLinks=false, onCancel, redirectTo, getTemporaryCredentials}) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [emailWarning, setEmailWarning] = useState();
  const [usernameWarning, setUsernameWarning] = useState();
  const [signupError, setSignupError] = useState();
  const [busy, setBusy] = useState(false);
  const [emailOptIn, setEmailOptIn] = useState(true);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showTermsWarning, setShowTermsWarning] = useState(false);

  const signup = async () => {
    setSignupError();
    if (!validateEmail(true) || !validateUsername(true) || !validateTermsAgreed()) return;
    try {
      setBusy(true);
      const { payload } = await AuthAPI.signup(email, username, signupToken, emailOptIn, getTemporaryCredentials);
      setBusy(false);
      onComplete(email, payload.temporary_credentials);
    } catch (err) {
      setBusy(false);
      console.error(err);  // eslint-disable-line no-console
      const message = (err.body && err.body.message) ? err.body.message : DEFAULT_ERROR_MESSAGE;
      setSignupError(message);
    }
  };

  const validateEmail = (onSubmit=false) => {
    if (email === '' && onSubmit) {
      // only perform this validation if they are actually ready to submit
      setEmailWarning('Email is required');
      return false;
    }
    setEmailWarning();
    return true;
  };

  const validateUsername = (onSubmit=false) => {
    if (username === '' && onSubmit) {
      setUsernameWarning('Username is required');
      return false;
    }
    setUsernameWarning();
    return true;
  };

  const validateTermsAgreed = () => {
    if (!termsAgreed) {
      setShowTermsWarning(true);
      return false;
    }
    setShowTermsWarning(false);
    return true;
  };

  const emailOptInCheckboxHandler = () => {
    setEmailOptIn(!emailOptIn);
  };

  const termsCheckboxHandler = () => {
    const currentState = termsAgreed;
    setTermsAgreed(!currentState);
    if (!currentState) {
      setShowTermsWarning(false);
    }
  };

  const disabled = emailWarning !== undefined || usernameWarning !== undefined;
  const oauthState = redirectTo;

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.content}>
        <div className={classes.social}>
          <FacebookLoginButton state={oauthState}>Sign up with Facebook</FacebookLoginButton>
          <GoogleLoginButton state={oauthState}>Sign up with Google</GoogleLoginButton>
        </div>
        <div className={classes.divider}>
          <div className={classes.border} />
          <span className={classes.dividerText}>
            or
          </span>
          <div className={classes.border} />
        </div>
        <AuthForm
          buttonLabel="Sign Up"
          buttonAction={signup}
          disabled={disabled}
          busy={busy}
          error={signupError}
          onCancel={onCancel}
        >
          <AuthField
            id="email"
            placeholder="Your email address"
            label="Email address"
            autoComplete="email"
            type="email"
            name="email"
            value={email}
            onChange={setEmail}
            error={emailWarning}
            onBlur={() => validateEmail()}
          />
          <AuthField
            id="username"
            placeholder="Select a username (not your real name)"
            label="Username (not your real name)"
            autoComplete="username"
            type="text"
            name="username"
            value={username}
            onChange={setUsername}
            error={usernameWarning}
            onBlur={() => validateUsername()}
            onEnter={() => !disabled && signup()}
          />
          <FormControlLabel
            control={<Checkbox checked={emailOptIn} onChange={emailOptInCheckboxHandler} />}
            label="Keep me posted about updates, features and offers!"
          />
          <FormControlLabel
            control={<Checkbox checked={termsAgreed} onChange={termsCheckboxHandler} />}
            label={
              (
                <Typography variant="body2">
                  I agree to Clickability&apos;s&nbsp;
                  <Link component={RouterLink} to="/terms">
                    <b>Terms and Conditions</b>
                  </Link>
                  &nbsp;&&nbsp;
                  <Link component={RouterLink} to="/privacy">
                    <b>Privacy Policy.</b>
                  </Link>*
                </Typography>
              )
            }
          />
          {showTermsWarning ? (<Alert severity="error">You must agree to the terms and privacy policy</Alert>) : null}
        </AuthForm>
        {hideLinks ? null : (
          <Typography variant="body2">
            Already a member?&nbsp;
            <Link component={RouterLink} to={loginLink}>
              <b>Log in</b>
            </Link>.
          </Typography>
        )}
      </div>
    </div>
  );

};

SignupForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  loginLink: PropTypes.string,
  signupToken: PropTypes.string,
  hideLinks: PropTypes.bool,
  onCancel: PropTypes.func,
  redirectTo: PropTypes.object,
  getTemporaryCredentials: PropTypes.bool,
};

export default SignupForm;
