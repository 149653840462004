import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons';

import Card from 'providerSite/components/Card';
import providerGoalConnect from 'assets/provider-goal-connect.png';
import providerGoalGrowth from 'assets/provider-goal-growth.png';
import style from 'style';


const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: center;
  column-gap: 100px;
  @media (max-width: ${style.screens.mobile}){
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
  }
`;


const GOAL_GROWTH = 'growth';
const GOAL_CONNECT = 'connect';


const GoalPage = ({ onChange, value }) => {

  const [goal, setGoal] = useState(value);

  const handleClick = val => {
    setGoal(val);
    onChange(val);
  };

  return (
    <Container>
      <Goal
        asset={providerGoalGrowth}
        title="I am a Provider and want to grow my business, get NDIS leads"
        onClick={() => handleClick(GOAL_GROWTH)}
        selected={goal === GOAL_GROWTH}
        disabled={goal !== GOAL_GROWTH && !!goal}
      />
      <Goal
        asset={providerGoalConnect}
        title="I am Support Coordinator and want to help my participants find
        NDIS providers"
        onClick={() => handleClick(GOAL_CONNECT)}
        selected={goal === GOAL_CONNECT}
        disabled={goal !== GOAL_CONNECT && !!goal}
      />
    </Container>
  );
};

GoalPage.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

const GoalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .card {
    position: relative;
    padding: 20px;
    cursor: pointer;
    border-color: ${({selected}) => selected && style.colors.secondary};
    :hover {
      border-color: ${style.colors.secondary};
    }
  }
  
  .icon-container {
    position: absolute;
    right: 15px;
    font-size: 24px;
    top: 10px;
    color: ${style.colors.secondary};
  }
  
  .title {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    max-width: 240px;
  }
  
  .img {
    filter: ${({disabled}) => disabled && 'grayscale(1)'};
  }
`;

const Goal = ({ asset, title, selected, disabled, onClick }) => {

  return (
    <GoalContainer selected={selected} disabled={disabled}>
      <Card className="card" onClick={onClick}>
        {selected && (
          <div className="icon-container">
            <FontAwesomeIcon icon={faCircleCheck}/>
          </div>
        )}
        <img className="img" src={asset} width="200px" alt="goal" />
      </Card>
      <div className="title">
        {title}
      </div>
    </GoalContainer>
  );
};

Goal.propTypes = {
  asset: PropTypes.object,
  title: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default GoalPage;

