import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import Card from './Card';


dayjs.extend(utc);
dayjs.extend(tz);


const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    padding: '10px 0',
    overflow: 'hidden',
  },
  body: {
    padding: '20px',
    display: 'flex',
  },
  stepperContainer: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '5px',
      marginBottom: '25px',
    },
  },
  progressCircleFilled: {
    backgroundColor: theme.palette.secondary.main,
  },
  progressLine: {
    marginTop: '7px',
  },
  skeletonContainer: {
    width: '100%',
    padding: '0 10px',
  },
  skeleton: {
    transform: 'unset',
    width: '100%',
  },
}));

const progressCircleStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    width: '100%',
  },
  circle: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ({filled}) => filled ? theme.palette.secondary.main
      : '#D3F2EF',
    fontSize: '14px',
    color: ({filled}) => filled ? theme.palette.common.white : theme.palette.grey[500],
  },
  hiddenLine: {
    visibility: 'hidden',
  },
  line: {
    backgroundColor: ({filled}) => filled ? `${theme.palette.secondary.main} !important`
      : '#D3F2EF !important',
    marginTop: '7px',
  },
  title: {
    marginTop: '10px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '11px',
      fontWeight: '500',
    },
  },
  datetime: {
    fontSize: '11px',
    fontWeight: '500',
    textTransform: 'uppercase',
    color: theme.palette.common.battleshipGrey,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '11px',
    },
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '60px',
    },
  },
}));

const progressLineStyles = makeStyles(theme => ({
  root: {
    height: '9px',
    flexGrow: '1',
    backgroundColor: '#D3F2EF',
    marginLeft: '-1px',
    marginRight: '-1px',
  },
  filled: {
    height: '9px',
    width: ({filled}) => filled ? (filled === 'half' ? '50%' : '100%') : '0px',
    backgroundColor: theme.palette.secondary.main,
    borderTopRightRadius: ({filled}) => filled === 'half' && '8px',
    borderBottomRightRadius: ({filled}) => filled === 'half' && '8px',
  },
}));

/*
Not very proud of this component. It's unnecessarily over complicated. Should
be simplified, specially around the 'filling' logic.
*/

const ProgressStepper = (
  {
    steps,
    currentStep,
    isLoading,
  }) => {

  const classes = useStyles();

  const renderStepper = () => {
    return (
      <div className={classes.stepperContainer}>
        {steps.reduce((acc, item, index) => {
          index && acc.components.push(
            <ProgressLine
              key={`progress-line-${index}`}
              filled={acc.shouldFill ? 'full' : (acc.shouldHalfFill && 'half')}
              className={classes.progressLine}
            />
          );
          acc.components.push(
            <ProgressCircle
              key={`progress-circle-${index}`}
              icon={item.icon}
              title={item.name}
              datetime={item.datetime}
              filled={acc.shouldFill}
              leftLine={!!index}
              rightLine={index !== steps.length-1}
            />
          );
          if (!acc.shouldFill && acc.shouldHalfFill)
            acc.shouldHalfFill = false;

          if (acc.shouldFill && item.key === currentStep) {
            acc.shouldHalfFill = true;
            acc.shouldFill = false;
          }
          return acc;
        }, {components: [], shouldFill: true, shouldHalfFill: false}).components}
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={classes.skeletonContainer}>
        <Skeleton animation="wave" height={72} className={classes.skeleton} />
      </div>
    );
  };

  return (
    <Card title="Progress" className={classes.root}>
      <div className={classes.body}>
        {isLoading ? renderLoading() : renderStepper()}
      </div>
    </Card>
  );
};


const ProgressCircle = ({ icon, title, datetime, filled, leftLine, rightLine }) => {
  const classes = progressCircleStyles({filled: filled});

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <ProgressLine className={classNames(classes.line, (!leftLine && classes.hiddenLine))}/>
        <div className={classes.circle}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <ProgressLine className={classNames(classes.line, (!rightLine && classes.hiddenLine))}/>
      </div>
      <div className={classes.dataContainer}>
        <div className={classes.title}>
          {title}
        </div>
        <div className={classes.datetime}>
          {datetime && dayjs(datetime).utc('z').tz().format('DD MMM hh:mm A')}
        </div>
      </div>
    </div>
  );
};


const ProgressLine = ({ filled, className }) => {
  const classes = progressLineStyles({filled: filled});

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.filled} />
    </div>
  );
};

ProgressCircle.propTypes = {
  icon: PropTypes.object,
  filled: PropTypes.bool,
  title: PropTypes.string,
  datetime: PropTypes.string,
  leftLine: PropTypes.bool,
  rightLine: PropTypes.bool,
};

ProgressLine.propTypes = {
  filled: PropTypes.oneOf(['half', 'full', false, null]),
  className: PropTypes.string,
};

ProgressStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentStep: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default ProgressStepper;
