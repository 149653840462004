import React from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Typography, Button as AntdButton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';
import BottomContainer from 'providerSite/components/LandingPage/Bottom';
import bottomImg from 'assets/connect-bottom.png';


const Button = styled(AntdButton)`
  margin-top: 30px;
  display: flex;
  column-gap: 6px;
  align-items: center;
  line-height: normal;
`;

const Bottom = () => {
  const history = useHistory();

  return (
    <BottomContainer img={bottomImg}>
      <Typography.Title className="title">
        <span style={{color: style.colors.secondary}}>Simplify your work flow</span> with
        Clickability Connect
      </Typography.Title>
      <Button
        type="primary"
        onClick={() => history.push('/business/signup', { goal: 'connect' })}
      >
        GET STARTED
        <FontAwesomeIcon icon={faGreaterThan} color={style.colors.palette.white}/>
      </Button>
    </BottomContainer>
  );
};


export default Bottom;
