import {
  SERVICE_CATEGORY_ALLIED_HEALTH,
  SERVICE_CATEGORY_CLEANING,
  SERVICE_CATEGORY_GARDENING,
  SERVICE_CATEGORY_PLAN_MANAGEMENT,
  SERVICE_CATEGORY_SUPPORT_COORDINATION,
  SERVICE_CATEGORY_SUPPORT_WORK,
  SERVICE_CATEGORY_GENERAL
} from '../../constants';
import {
  AH_QUESTIONS,
  PM_QUESTIONS,
  SC_QUESTIONS,
  SW_GARDENING_CLEANING_QUESTIONS,
  GENERAL_QUESTIONS
} from './questions/modalitySpecific';
import {
  EXTENSION_CLEANING_QUESTIONS,
  EXTENSION_GARDENING_QUESTIONS,
  EXTENSION_SW_QUESTIONS
} from './questions/extension';


export const MODALITY_QUESTION_MAP = {
  [SERVICE_CATEGORY_SUPPORT_COORDINATION]: SC_QUESTIONS,
  [SERVICE_CATEGORY_SUPPORT_WORK]: SW_GARDENING_CLEANING_QUESTIONS,
  [SERVICE_CATEGORY_GARDENING]: SW_GARDENING_CLEANING_QUESTIONS,
  [SERVICE_CATEGORY_CLEANING]: SW_GARDENING_CLEANING_QUESTIONS,
  [SERVICE_CATEGORY_ALLIED_HEALTH]: AH_QUESTIONS,
  [SERVICE_CATEGORY_PLAN_MANAGEMENT]: PM_QUESTIONS,
  [SERVICE_CATEGORY_GENERAL]: GENERAL_QUESTIONS,
};

export const MODALITY_EXTENSION_QUESTION_MAP = {
  [SERVICE_CATEGORY_SUPPORT_WORK]: EXTENSION_SW_QUESTIONS,
  [SERVICE_CATEGORY_CLEANING]: EXTENSION_CLEANING_QUESTIONS,
  [SERVICE_CATEGORY_GARDENING]: EXTENSION_GARDENING_QUESTIONS,
};