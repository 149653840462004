import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { formatComponentId } from '../../lib/util.js';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '1rem 0',
  },
  label: {
    width: '100%',
    marginBottom: '0.5rem',
  },
});

// Currently supports positive integers only
// The value prop must be null or a number (not a string).
// the event.target.value for the event returned by onChange() will either be null or a number,
const NumberInputField = ({label, onChange, value=null, ...textFieldProps}) => {
  const strToNumber = (value) => {
    value = value.replace(/\D/g,'');
    return value === '' ? null : parseInt(value);
  };
  const numberToStr = (value) => {
    return value === null ? '' : String(value);
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      <TextField
        required
        id={formatComponentId('text-input', label)}
        value={numberToStr(value)}
        variant="outlined"
        onChange={e => onChange({target: { value: strToNumber(e.target.value) }})}
        {...textFieldProps}
      />
    </div>
  );
};

NumberInputField.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default NumberInputField;
