import PropTypes from 'prop-types';
import React from 'react';
import img from 'assets/physiotherapist-and-boy-holding-ball-455x256.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1rem 0',
    width: '100%',
    height: '100%',
  },
  img: {
    maxHeight: '16rem',
    maxWidth: '100%',
    objectFit: 'contain',
  },
}));

const TitleImage = ({data}) => {
  const classes = useStyles();
  const imageSize = '@455x256';
  const profileImg = data.image_path ? data.image_path + imageSize : img;
  return (
    <div className={classes.root}>
      <img
        width="455"
        height="256"
        src={profileImg}
        alt={`Provider ${data.name} logo`}
        className={classes.img}
        aria-label="Provider headline image"
      />
    </div>
  );
};

TitleImage.propTypes = {
  data: PropTypes.object,
};

export default TitleImage;
