import { Result, Alert, Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: 0,
  },
  section: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  group: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bordered: {
    padding: '1rem 0',
    borderTop: `1px solid ${theme.palette.common.silver}`,
    borderBottom: `1px solid ${theme.palette.common.silver}`,
  },
  header: {
    paddingTop: '1rem',
  },
  icon: {
    marginRight: '1rem',
  },
  text: {
    width: '100%',
    marginBottom: '1rem',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    margin: '1rem 1rem 1rem 0',
  },
}));

const ReviewCompleted = ({ onClose, onEndorse }) => {

  const classes = useStyles();

  return (
    <Result
      status="success"
      title="Thank you for your review!"
      subTitle="We double-check all reviews to make sure we meet quality and
      compliance standards. This may take up to 48 hours so please be patient.
      Once approved, your review will be live on Clickability!"
      extra={(
        <>
          <Alert
            showIcon
            type="info"
            style={{width: '100%', textAlign: 'left', marginBottom: '25px'}}
            message={<>Is this provider great in a specialised area? If so, please
              consider <span className={classes.link} onClick={onEndorse}><b>endorsing them</b></span> for
              a specialisation.</>}
          />
          <Button type="primary" onClick={onClose}>Back to site</Button>
        </>
      )}
    />
  );
};

ReviewCompleted.propTypes = {
  onClose: PropTypes.func.isRequired,
  onEndorse: PropTypes.func.isRequired,
};

const EndorsementCompleted = ({ onClose, onReview, onEndorseAnother }) => {

  const classes = useStyles();

  return (
    <Result
      style={{marginTop: '-1rem'}}
      status="success"
      title="Thank you for your endorsement!"
      subTitle="Your endorsement will be visible on their profile and will help
      other participants choose services that are a great match for them."
      extra={(
        <>
          <Alert
            showIcon
            type="info"
            style={{width: '100%', textAlign: 'left', marginBottom: '25px'}}
            message={<>Would you like to share your experience with this provider?
              If so, please <span className={classes.link} onClick={onReview}><b>write a review</b></span>.</>}
          />
          <div style={{display: 'flex', columnGap: '10px'}}>
            <Button
              type="primary"
              ghost
              onClick={onClose}
            >
              Back to site
            </Button>
            <Button
              type="primary"
              onClick={onEndorseAnother}
            >
              Endorse for another specialiasation
            </Button>
          </div>
        </>
      )}
    />
  );
};

EndorsementCompleted.propTypes = {
  onClose: PropTypes.func.isRequired,
  onReview: PropTypes.func.isRequired,
  onEndorseAnother: PropTypes.func.isRequired,
};

export {  
  ReviewCompleted,
  EndorsementCompleted,
};