import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import style from 'style';


const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-size: 12px;
  padding: 0 5px;
  white-space: nowrap;
  margin-top: 15px;
  margin-bottom: 2px;
  .line {
    height: 1px;
    background: ${style.colors.lightBorder};
    width: 100%;
  }
`;

const Divider = ({ name, ...props }) => {
  return (
    <Container {...props}>
      <div className="line" />
      {name}
      <div className="line" />
    </Container>
  );
};

Divider.propTypes = {
  name: PropTypes.string,
};


export default Divider;

