import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from 'components/Button';
import * as track from 'lib/track';
import { useHistory } from 'react-router-dom';
import team from 'assets/cta-team.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1.5rem 1rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.darkSlateBlue,
    borderRadius: '0.75rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  text: {
    width: '100%',
    padding: '1rem 1rem 0 1rem',
    fontWeight: 700,
    textAlign: 'center',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0 0 0',
    },
  },
  button: {
    backgroundColor: '#99F2BF',
    color: theme.palette.common.darkSlateBlue,
    borderRadius: '0.75rem',
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.25)',
    padding: '0.7rem 1rem',
    fontSize: '1rem',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  team: {
    height: '5rem',
  },
}));

const ProfileBrokerageCTA = () => {
  const classes = useStyles();
  const history = useHistory();

  const onClick = () => {
    track.sendEvent('Profile page brokerage CTA button', 'click');
    history.push('/requestmatch');
  };

  return (
    <div className={classes.root}>
      <img src={team} alt="clickability-team" className={classes.team} />
      <Typography variant="h3" className={classes.text}>
        Save time. Let our friendly team help you connect with the right providers.
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        label="Get started"
        onClick={onClick}
      />
    </div>
  );
};

export default ProfileBrokerageCTA;
