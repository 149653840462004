import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import camelcaseKeys from 'camelcase-keys';
import {makeStyles} from '@material-ui/core/styles';
import * as EmailValidator from 'email-validator';

import {checkServiceAvailability, formatListToString} from '../../utils';
import Loader from 'components/Loader';
import {
  PAGE_LOCATION,
  PAGE_NDIS_PLAN,
  PAGE_SERVICE,
  PAGE_SERVICE_FOR,
  PAGE_TELEHEALTH,
  PAGE_AGE_RANGE,
  PLAN_TYPE_AGENCY_MANAGED,
  PLAN_TYPE_NOT_WITH_NDIS,
  PLAN_TYPE_PLAN_MANAGED,
  PLAN_TYPE_SELF_MANAGED,
  PLAN_TYPE_UNSURE,
  PLAN_TYPE_WAITING_FOR_APPROVAL,
  TELEHEALTH_NO,
  TELEHEALTH_YES,
  TELEHEALTH_UNSURE,
  DIRECT_CONNECT_FIELD,
  PARTICIPANT_AGE_RANGE_FIELD, PAGE_ONLINE_SERVICE,
} from '../../../constants';
import TextBox from '../components/TextBox';
import FlowButton from '../../components/FlowButton';
import { ServiceRequestAPI, useMutateApi } from 'providerSite/api';
import * as track from 'lib/track';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '60px',
  },
  clickableText: {
    color: theme.palette.common.tealish,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.common.dustyOrange,
      cursor: 'pointer',
    }
  },
  coloredText: {
    color: theme.palette.common.tealish,
  },
  text: {
    color: theme.palette.common.textGrey,
    fontSize: '17px',
    fontWeight: '500',
  },
  tNc: {
    marginTop: 'auto',
    fontSize: '14px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px',
  },
}));

const Summary = ({ answers, onChange, setPagename, progressAction, setAnswers, nextPage }) => {

  const [isLoading, setIsLoading] = useState(true);

  const isDirectConnect = !!answers[DIRECT_CONNECT_FIELD];

  const qualifiedServices = camelcaseKeys(
    useSelector(state => state.taxonomy.qualifiedServices), {deep: true}
  );

  const serviceToServiceCatMap = qualifiedServices.reduce((acc, item) => {
    return {...acc, [item.serviceName]: item.serviceCategoryName};
  }, {});

  const getServices = () => answers.servicesNeeded.reduce((acc, item) => {
    if (item.name in serviceToServiceCatMap) {
      acc.matchedServices.push(
        {
          name: item.name,
          id: item.id,
          categoryName: serviceToServiceCatMap[item.name],
        }
      );
    } else {
      acc.unMatchedServices.push({name: item.name});
    }
    return acc;
  }, {matchedServices: [], unMatchedServices: []});

  useEffect(() => {
    onChange(null);
    checkServiceAvailability(
      answers.servicesNeeded, answers.ndisPlan, answers.location, answers.telehealth,
      answers[PARTICIPANT_AGE_RANGE_FIELD])
      .then(isServiceAvailable => {
        if (isServiceAvailable || isDirectConnect) {
          onChange(getServices());
        } else {
          onChange(
            {
              matchedServices: [],
              unMatchedServices: [{name: answers.servicesNeeded[0].name}]
            });
        }
      })
      .then(() => setIsLoading(false))
      .catch(err => console.error(err));  // eslint-disable-line no-console
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading)
    return <Loader />;

  return (
    <SummaryComponent
      services={answers.servicesNeeded.map(item => item.name)}
      location={answers.location}
      telehealth={answers?.telehealth}
      onlineService={answers?.onlineService}
      serviceFor={answers.servicesFor}
      ndisPlan={answers.ndisPlan}
      ageRange={answers[PARTICIPANT_AGE_RANGE_FIELD]}
      setPagename={setPagename}
      progressAction={progressAction}
      isDirectConnect={isDirectConnect}
      answers={answers}
      setAnswers={setAnswers}
      nextPage={nextPage}
    />
  );
};

const SummaryComponent = (
  {
    services,
    location,
    telehealth,
    onlineService,
    serviceFor,
    ndisPlan,
    ageRange,
    setPagename,
    progressAction,
    isDirectConnect,
    answers,
    setAnswers,
    nextPage,
  }) => {

  const classes = useStyles();

  const incompleteSrMutation = useMutateApi(ServiceRequestAPI.CREATE_INCOMPLETE_SERVICE_REQUEST, 'POST');

  const toServicePage = () => {
    progressAction.set(0);
    setPagename(PAGE_SERVICE);
  };
  const toServiceForPage = () => {
    progressAction.set(1);
    setPagename(PAGE_SERVICE_FOR);
  };
  const toAgeRangePage = () => {
    progressAction.set(2);
    setPagename(PAGE_AGE_RANGE);
  };
  const toNdisPage = () => {
    progressAction.set(2);
    setPagename(PAGE_NDIS_PLAN);
  };
  const toLocationPage = () => {
    progressAction.set(3);
    setPagename(PAGE_LOCATION);
  };
  const toTelehealthPage = () => {
    progressAction.set(4);
    setPagename(PAGE_TELEHEALTH);
  };
  const toOnlineServicePage = () => {
    progressAction.set(4);
    setPagename(PAGE_ONLINE_SERVICE);
  };

  const updateAnswer = (field, value) => setAnswers(
    prevState => ({...prevState, [field]: value})
  );

  const genericNdisText = 'These supports will be funded through';

  const getTemplatedText = (firstPart, secondPart, onClick) => (
    <>
      {firstPart} <span className={classes.clickableText} onClick={onClick}>{secondPart}</span>
    </>
  );

  const ndisTextMap = {
    [PLAN_TYPE_SELF_MANAGED]: getTemplatedText(`${genericNdisText} a`, 'self-managed plan.', toNdisPage),
    [PLAN_TYPE_AGENCY_MANAGED]: getTemplatedText(`${genericNdisText} an`, 'agency-managed plan.', toNdisPage),
    [PLAN_TYPE_PLAN_MANAGED]: getTemplatedText(`${genericNdisText} a`, 'plan-managed plan.', toNdisPage),
    [PLAN_TYPE_WAITING_FOR_APPROVAL]: getTemplatedText('These supports are waiting for the', 'NDIS funding to be approved.', toNdisPage),
    [PLAN_TYPE_NOT_WITH_NDIS]: getTemplatedText('These supports will be funded', 'outside of the NDIS.', toNdisPage),
    [PLAN_TYPE_UNSURE]: getTemplatedText('I am unsure how the', 'Plan is funded.', toNdisPage),
  };

  const _telehealthYesUnsureText = getTemplatedText('delivered either', 'face-to-face or via telehealth', toTelehealthPage);
  const telehealthTextMap = {
    [TELEHEALTH_YES]: _telehealthYesUnsureText,
    [TELEHEALTH_UNSURE]: _telehealthYesUnsureText,
    [TELEHEALTH_NO]: getTemplatedText('delivered', 'face-to-face', toTelehealthPage),
  };

  const _onlineServiceYesUnsureText = getTemplatedText('delivered either', 'face-to-face or via online', toOnlineServicePage);
  const onlineServiceTextMap = {
    [TELEHEALTH_YES]: _onlineServiceYesUnsureText,
    [TELEHEALTH_UNSURE]: _onlineServiceYesUnsureText,
    [TELEHEALTH_NO]: getTemplatedText('delivered', 'face-to-face', toOnlineServicePage),
  };

  const getAgeRangeText = () => {
    const _range = ageRange.match(/\((.+)\)/)[1];
    if (_range.includes('-')) return `between ${_range}.`;
    return `${_range}.`;
  };

  const handleClick = () => {
    const data = {...answers};
    delete data['_ageGroups'];
    delete data['_qualifiedServices'];
    incompleteSrMutation.mutate(data);
    track.sendEvent('service request flow', 'start', data);
    setPagename(nextPage.name);
  };

  return (
    <div className={classes.root}>
      <TextBox
        defaultValue={answers.email}
        placeholder="Please enter your email address"
        validator={EmailValidator.validate}
        onChange={val => updateAnswer('email', val)}
        errorText="Please enter a valid email address"
      />
      <div className={classes.text}>
        I want&#8203; <span className={isDirectConnect ? classes.coloredText : classes.clickableText} onClick={isDirectConnect ? () => {} : toServicePage}>
          {formatListToString(services)}</span>
        &#8203; in <span className={classes.clickableText} onClick={toLocationPage}>{location.split(/^\d+ - (.+)/)[1]}</span>
        {telehealth && <>&#8203; {telehealthTextMap[telehealth]}</>}
        {onlineService && <>&#8203; {onlineServiceTextMap[onlineService]}</>}
        <br/> for <span className={classes.clickableText} onClick={toServiceForPage}>{serviceFor}</span>,
        aged <span className={classes.clickableText} onClick={toAgeRangePage}>{getAgeRangeText()}</span>
      </div>
      <div className={classes.text}>
        {ndisTextMap[ndisPlan]}
      </div>
      <div className={classes.buttonContainer}>
        <FlowButton
          label="Next"
          pointer={null}
          onClick={handleClick}
          disabled={!answers.email}
        />
      </div>
    </div>
  );
};

SummaryComponent.propTypes = {
  services: PropTypes.array,
  location: PropTypes.string,
  telehealth: PropTypes.string,
  onlineService: PropTypes.string,
  serviceFor: PropTypes.string,
  ndisPlan: PropTypes.string,
  ageRange: PropTypes.string,
  setPagename: PropTypes.func,
  progressAction: PropTypes.object,
  isDirectConnect: PropTypes.bool,
  answers: PropTypes.object,
  nextPage: PropTypes.object,
  setAnswers: PropTypes.func,
};

Summary.propTypes = {
  answers: PropTypes.object,
  setPagename: PropTypes.func,
  progressAction: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  nextPage: PropTypes.object,
  setAnswers: PropTypes.func,
};

export default Summary;