import TextboxQuestion from '../questionComponents/TextboxQuestion';
import DropdownQuestion from '../questionComponents/DropdownQuestion';
import MultiChoiceQuestion from '../questionComponents/MultiChoiceQuestion';


export const EXTENSION_SW_QUESTIONS = [
  {
    field: 'assistanceType',
    Component: MultiChoiceQuestion,
    text: 'Please select the type of assistance you would like the support worker to provide.',
    helperText: 'This information helps us narrow down which providers can offer the support you need.',
    options: ['Medication delivery', 'Peg feeding', 'Catheter care', 'Travel/transport',
      'Community access', 'Companionship', 'Domestic tasks (E.g. light cleaning, ironing)',
      'Meal preparation'],
    isValid: answer => Array.isArray(answer) && answer.length,
  },
];

export const EXTENSION_CLEANING_QUESTIONS = [
  {
    field: 'propertyType',
    Component: DropdownQuestion,
    text: 'What type of property is the cleaning service for?',
    options: ['House', 'Unit', 'Townhouse', 'Shared accommodation'],
    isValid: () => true,
    isOptional: () => true,
  },
  {
    field: 'bedrooms',
    Component: DropdownQuestion,
    text: 'How many bedrooms need cleaning in the property?',
    options: ['1', '2', '3', '4', '5', '6+'],
    isValid: () => true,
    isOptional: () => true,
  },
  {
    field: 'bathrooms',
    Component: DropdownQuestion,
    text: 'How many bathrooms need cleaning in the property?',
    options: ['1', '2', '3+'],
    isValid: () => true,
    isOptional: () => true,
  },
  {
    field: 'oneOffDeepClean',
    Component: DropdownQuestion,
    text: 'Do you also need a one-off spring/deep-clean?',
    options: ['Yes', 'No', 'I don\'t know'],
    isValid: () => true,
    isOptional: () => true,
  },
  {
    field: 'anythingElse',
    Component: TextboxQuestion,
    text: 'Is there anything else you want the cleaners to know?',
    placeholder: 'E.g. pets on premise',
    isOptional: () => true,
    isValid: () => true,
  },
];

export const EXTENSION_GARDENING_QUESTIONS = [
  {
    field: 'workType',
    Component: DropdownQuestion,
    text: 'Please select the type of work you’ll want done in the garden',
    options: ['Weeding', 'Lawn Mowing', 'Hedge trimming', 'Green waste removal'],
    isValid: answer => !!answer,
  },
];