import config from '../config';

// https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html

// state is used to pass arbitrary context through the oauth process.
// e.g. a redirect url to go to after login.
export function decodeState(encodedState) {
  try {
    return JSON.parse(decodeURIComponent(encodedState));
  } catch (err) {
    console.error(err);  // eslint-disable-line no-console
  }
  return {};  // on error return default empty state
}

function cognitoLoginUrl(provider, scopes, state) {
  const url = [
    config.cognito.BASE_URL,
    '/oauth2/authorize?identity_provider=',
    provider,
    '&redirect_uri=',
    config.website.URL,
    '/oauth/login&response_type=CODE&client_id=',
    config.cognito.CLIENT_ID,
    '&scope=',
    scopes.join(' '),
    '&state=',
    encodeURIComponent(JSON.stringify(state)),
  ].join('');
  return url;
}

export function facebookLoginUrl(state) {
  const scopes = ['aws.cognito.signin.user.admin', 'email','openid', 'phone', 'profile'];
  return cognitoLoginUrl('Facebook', scopes, state);
}

export function googleLoginUrl(state) {
  const scopes = ['aws.cognito.signin.user.admin', 'email','openid', 'phone', 'profile'];
  return cognitoLoginUrl('Google', scopes, state);
}
