import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Popconfirm } from 'antd';
import { toast } from 'react-hot-toast';

import { useGetApi, ConnectAPI, useMutateApi } from 'providerSite/api';
import styled from 'styled-components';

import ParticipantDetailsForm from '../../../components/ParticipantDetailsForm';
import Spinner from 'components/Spinner';
import { useHistory, useLocation } from 'react-router-dom';
import AddButton from '../../../components/AddButton';
import { NEW_PARTICIPANT_KEY } from '../constants';
import Header from '../../../components/Header';
import { checkCompleted } from '../../../components/ParticipantDetailsForm/utils';


const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  column-gap: 20px;
  padding: 20px;
  .button {
    min-width: 150px;
  }
  .icon {
    margin-right: 10px;
  }
`;


const ParticipantDetails = ({ providerUuid, participantUuid, onSelect }) => {

  const [updatedData, setUpdatedData] = useState({});
  const location = useLocation();
  const history = useHistory();


  const apiParams = {
    providerUuid: providerUuid,
    participantUuid: participantUuid,
  };

  const { data, isLoading } = useGetApi(ConnectAPI.GET_SINGLE_PARTICIPANT, {
    params: apiParams,
  });

  const updateMutation = useMutateApi(ConnectAPI.PATCH_PARTICIPANT, 'PATCH', {
    params: apiParams,
    invalidation: [
      {
        endpoint: ConnectAPI.GET_SINGLE_PARTICIPANT,
        params: apiParams,
      },
      {
        endpoint: ConnectAPI.GET_PARTICIPANTS,
        params: {
          providerUuid: providerUuid,
        },
      },
    ],
    onSuccess: () => toast.success('Successfully updated'),
  });

  const deleteMutation = useMutateApi(ConnectAPI.DELETE_PARTICIPANT, 'DELETE', {
    params: apiParams,
    onSuccess: () => {
      history.replace(location.pathname);
      toast.success('Successfully deleted');
    },
  });

  const handleChange = (key, val) => {
    setUpdatedData(prevState => {
      return {...prevState, [key]: val};
    });
  };

  const handleUpdate = () => {
    updateMutation.mutate(updatedData);
    setUpdatedData({});
  };

  if (isLoading)
    return <Spinner />;

  return (
    <>
      <Header text="View/Edit Participant Details" backButton>
        <AddButton text="New Participant" onClick={() => onSelect(NEW_PARTICIPANT_KEY)} />
      </Header>
      <ParticipantDetailsForm data={data} handleChange={handleChange} />
      <Divider />
      <ButtonContainer>
        <Popconfirm
          title={
            <><b>Are you sure you want to delete this participant?</b><br/><br/>
              Submitted requests will remain visible to providers<br/>
              and you may be contacted by them.<br/><br/>
              <b>This cannot be undone</b>.
            </>}
          okText="Yes"
          cancelText="No"
          onConfirm={() => deleteMutation.mutate({})}
        >
          <Button
            className="button"
            size="large"
            type="primary"
            danger
            icon={<FontAwesomeIcon className="icon" icon={faTrash} />}
            loading={deleteMutation.isLoading}
          >
            Delete
          </Button>
        </Popconfirm>
        <Button
          className="button"
          size="large"
          type="primary"
          icon={<FontAwesomeIcon className="icon" icon={faPen} />}
          disabled={!Object.keys(updatedData).length || !checkCompleted(updatedData, false)}
          onClick={handleUpdate}
          loading={updateMutation.isLoading}
        >
          Update
        </Button>
      </ButtonContainer>
    </>
  );
};


ParticipantDetails.propTypes = {
  providerUuid: PropTypes.string,
  participantUuid: PropTypes.string,
  onSelect: PropTypes.func,
};


export default ParticipantDetails;
