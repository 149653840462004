import Container from './Container';
import Footer from './Footer';
import Navbar from 'providerSite/components/Navbar';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import style from 'style';
import styled from 'styled-components';

const Main = styled.div`
  color: ${style.colors.palette.black};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  padding-top: ${style.constants.navBarHeight};
  display: flex;
  justify-content: center;
  flex-grow: 2;
`;

// default page title for provider site pages 
const DEFAULT_TITLE = 'Clickability, Your Complete NDIS Business Platform';


const Page = ({ children, fullWidth, title, plain, secondaryLogo }) => {

  useEffect(() => {
    document.title = title ? `${title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE;
  }, [title]);

  return (
    <Main>
      <Navbar plain={plain} secondaryLogo={secondaryLogo} />
      <Body>
        <Container size={fullWidth && 'full'}>
          {children}
        </Container>
      </Body>
      <Footer />
    </Main>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  title: PropTypes.string,
  plain: PropTypes.bool,
  secondaryLogo: PropTypes.string,
};


export default Page;
