import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
  divider: {
    margin: '1rem 0',
  },
});

const SectionDivider = () => {
  const classes = useStyles();
  return (
    <Divider className={classes.divider} variant="fullWidth" />
  );
};

export default SectionDivider;
