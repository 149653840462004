import {
  faCheckCircle,
  faBolt,
  faTimesCircle,
  faBan,
  faFlagCheckered,
  faSparkles,
  faArrowRotateLeft,
  faMagnifyingGlass,
  faStopwatch,
} from '@fortawesome/pro-duotone-svg-icons';

import * as constants from '../constants';


export const getStatusData = (theme, status) => {
  switch (status) {
  case constants.SERVICE_REQUEST_STATUS_NEW:
    return {text: 'New', color: theme.palette.warning.main, icon: faSparkles};
  case constants.SERVICE_REQUEST_STATUS_OPEN:
    return {text: 'Open', color: theme.palette.info.main, icon: faSparkles};
  case constants.SERVICE_REQUEST_STATUS_ACCEPTED:
    return {text: 'Accepted', color: theme.palette.success.main, icon: faCheckCircle};
  case constants.SERVICE_REQUEST_STATUS_DECLINED:
    return {text: 'Declined', color: theme.palette.error.main, icon: faTimesCircle};
  case constants.SERVICE_REQUEST_STATUS_CONTACTED:
  case constants.SERVICE_REQUEST_STATUS_IN_PROGRESS:
    return {text: 'In Progress', color: theme.palette.warning.main, icon: faBolt};
  case constants.SERVICE_REQUEST_STATUS_CLOSED:
    return {text: 'Closed', color: theme.palette.common.textGrey, icon: faFlagCheckered};
  case constants.SERVICE_REQUEST_STATUS_EXPIRED:
    return {text: 'Expired', color: theme.palette.common.dustyOrange, icon: faFlagCheckered};
  case constants.SERVICE_REQUEST_STATUS_CANCELLED:
    return {text: 'Cancelled', color: theme.palette.error.main, icon: faBan};
  case constants.SERVICE_REQUEST_STATUS_SUCCESSFUL:
    return {text: 'Successful', color: theme.palette.common.fauxTiffanyBlue, icon: faFlagCheckered};
  case constants.SERVICE_REQUEST_STATUS_UNSUCCESSFUL:
    return {text: 'Unsuccessful', color: theme.palette.common.nightBlue, icon: faFlagCheckered};
  default:
    return {text: null, color: null, icon: null};
  }
};

export const getEventData = (theme, event) => {
  switch (event) {
  case constants.SERVICE_REQUEST_EVENT_ACCEPT:
    return {text: 'Accept', color: theme.palette.success.main, icon: faCheckCircle};
  case constants.SERVICE_REQUEST_EVENT_SEARCH:
    return {text: 'Search', color: theme.palette.primary.main, icon: faMagnifyingGlass};
  case constants.SERVICE_REQUEST_EVENT_DECLINE:
    return {text: 'Decline', color: theme.palette.error.main, icon: faTimesCircle};
  case constants.SERVICE_REQUEST_EVENT_IN_PROGRESS:
    return {text: 'In Progress', color: theme.palette.warning.main, icon: faBolt};
  case constants.SERVICE_REQUEST_EVENT_COMPLETE:
    return {text: 'Complete', color: theme.palette.common.textGrey, icon: faFlagCheckered};
  case constants.SERVICE_REQUEST_EVENT_OPEN:
    return {text: 'Re-Open', color: theme.palette.secondary.main, icon: faArrowRotateLeft};
  case constants.SERVICE_REQUEST_EVENT_CANCEL:
    return {text: 'Cancel', color: theme.palette.error.main, icon: faBan};
  case constants.SERVICE_REQUEST_EVENT_RESERVE:
    return {text: 'Reserve', color: theme.palette.warning.dark, icon: faStopwatch};
  default:
    return {text: null, color: null, icon: null};
  }
};