import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AuthForm from './AuthForm';
import AuthField from './AuthField';
import { AuthAPI } from '../../../api';
import { AuthStore } from '../../../store';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import AlertList from './AlertList';
import { connect } from 'react-redux';

const DEFAULT_ERROR_MESSAGE = 'An error occurred activating your account.  Please try again later.';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '0.5rem 0 0 7rem',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: '1.5rem',
  },
  content: {
    maxWidth: '30rem',
  },
  divider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  border: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    width: '100%',
  },
  dividerText: {
    padding: '0 10px 0 10px',
    color: `1px solid ${theme.palette.primary.main}`,
  },
}));

const OAuthActivateForm = ({email, username: initialUsername, onComplete, dispatch}) => {
  const classes = useStyles();
  const [username, setUsername] = useState(initialUsername);
  const [usernameWarning, setUsernameWarning] = useState();
  const [busy, setBusy] = useState(false);
  const [emailOptIn, setEmailOptIn] = useState(true);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const termsAlert = {severity: 'error', text: 'You must agree to the terms and privacy policy', id:'terms'};

  const addAlerts = (newAlerts) => {
    setAlerts(alerts => [...newAlerts, ...alerts]);
  };

  const removeAlert = (alert) => {
    setAlerts(alerts => alerts.filter(a => a.id !== alert.id));
  };

  const activate = async () => {
    setAlerts([]);
    if (!validateUsername(true) || !validateTermsAgreed()) return;
    try {
      setBusy(true);
      // try updating username.  Abort activation if it fails.
      if (username !== initialUsername) {
        const { payload } = await AuthAPI.updateProfile({username});
        const hasErrors = payload.messages.some(a => a.severity === 'error');
        if (hasErrors) {
          addAlerts(payload.messages);
          setBusy(false);
          return;
        }
      }
      // activate account
      const { payload } = await AuthAPI.oauthActivate({email_opt_in: emailOptIn});
      dispatch(AuthStore.profile(payload.profile));
      setBusy(false);
      onComplete();
    } catch (err) {
      setBusy(false);
      console.error(err);  // eslint-disable-line no-console
      const message = (err.body && err.body.message) ? err.body.message : DEFAULT_ERROR_MESSAGE;
      addAlerts([{severity: 'error', text: message}]);
    }
  };

  const validateUsername = (onSubmit=false) => {
    if (username === '' && onSubmit) {
      setUsernameWarning('Username is required');
      return false;
    }
    setUsernameWarning();
    return true;
  };

  const validateTermsAgreed = () => {
    if (!termsAgreed) {
      addAlerts([termsAlert]);
      return false;
    }
    return true;
  };

  const emailOptInCheckboxHandler = () => {
    setEmailOptIn(!emailOptIn);
  };

  const termsCheckboxHandler = () => {
    if (!termsAgreed) removeAlert(termsAlert);
    setTermsAgreed(!termsAgreed);
  };

  const disabled = usernameWarning !== undefined;
  return (
    <div className={classes.root}>

      <div className={classes.content}>

        <AuthForm
          buttonLabel="Continue"
          buttonAction={activate}
          disabled={disabled}
          busy={busy}
        >
          <AuthField
            id="email"
            placeholder="Your email address"
            label="Email address"
            autoComplete="email"
            type="email"
            name="email"
            value={email}
            disabled={true}
          />
          <AuthField
            id="username"
            placeholder="Select a username (not your real name)"
            label="Username (not your real name)"
            autoComplete="username"
            type="text"
            name="username"
            value={username}
            onChange={setUsername}
            error={usernameWarning}
            onBlur={() => validateUsername()}
            onEnter={() => !disabled && activate()}
          />
          <FormControlLabel
            control={<Checkbox checked={emailOptIn} onChange={emailOptInCheckboxHandler} />}
            label="Keep me posted about updates, features and offers!"
          />
          <FormControlLabel
            control={<Checkbox checked={termsAgreed} onChange={termsCheckboxHandler} />}
            label={
              (
                <Typography variant="body2">
                  I agree to Clickability&apos;s&nbsp;
                  <Link component={RouterLink} to="/terms">
                    <b>Terms and Conditions</b>
                  </Link>
                  &nbsp;&&nbsp;
                  <Link component={RouterLink} to="/privacy">
                    <b>Privacy Policy.</b>
                  </Link>*
                </Typography>
              )
            }
          />
          <AlertList alerts={alerts} onClose={removeAlert}/>
        </AuthForm>
        <Typography variant="body2">
          If you do not wish to continue, click here to&nbsp;
          <Link component={RouterLink} to='/logout'>
            <b>cancel account activation</b>
          </Link>.
        </Typography>
      </div>

    </div>
  );
};

OAuthActivateForm.propTypes = {
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(OAuthActivateForm);
