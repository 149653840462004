import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import styled from 'styled-components';
import { Typography, Button as AntdButton } from 'antd';

import style from 'style';
import PricingComponent from 'providerSite/components/LandingPage/Pricing';
import List from 'providerSite/components/LandingPage/List';



const Text = styled.div`
  font-weight: 500;
  font-size: 26px;
  
  @media (max-width: ${style.screens.tab}) {
    font-size: 20px;
  }
`;

const Button = styled(AntdButton)`
  align-self: ${({isDesktop}) => isDesktop ? 'left': 'center'}; 
  width: 200px;
  margin-top: 20px;
`;

const Pricing = () => {
  const history = useHistory();
  const isDesktop = useMediaQuery({minWidth: style.screens.tab});

  const squareText = (
    <>
      <Text style={{fontWeight: 600, fontSize: '60px'}}>
        FREE
      </Text>
      <Text>
       access until<br/>further notice
      </Text>
    </>
  );

  const rectangleText = (
    <>
      <Typography.Title
        level={4}
        style={{
          color: style.colors.palette.white,
          textAlign: isDesktop ? 'default' : 'center'
        }}
      >
        Get all the benefits of Clickability Connect
      </Typography.Title>
      <List>
        Powerful Search
      </List>
      <List>
        Dynamic provider database
      </List>
      <List>
        Optimised for your needs
      </List>
      <Button
        isDesktop={isDesktop}
        onClick={() => history.push('/business/signup', { goal: 'connect' })}
      >
        PROVIDER SIGN UP
      </Button>
    </>
  );

  return (
    <PricingComponent
      squareText={squareText}
      rectangleText={rectangleText}
    />
  );
};


export default Pricing;
