import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

import {getHexWithOpacity} from 'lib/util';


dayjs.extend(utc);
dayjs.extend(tz);


const useStyles = makeStyles( theme =>({
  root: {
    fontSize: '12px',
    backgroundColor: getHexWithOpacity(theme.palette.common.fauxIndigo, 10),
    border: '1px solid',
    borderColor: getHexWithOpacity(theme.palette.common.fauxIndigo, 8),
    width: 'fit-content',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    height: '21px',
    cursor: 'default',
  },
  label: {
    fontWeight: '500',
    color: theme.palette.common.fauxIndigo,
    padding: '1px 8px',
    whiteSpace: 'nowrap',
  }
}));

const DateTimeStatus = ({value}) => {
  const classes = useStyles();
  const convertedDateTime = dayjs(value).utc('z').tz();
  const [fromNowValue, setFromNowValue] = useState(convertedDateTime.fromNow());

  useEffect(() => {
    const interval = setInterval(() => {
      setFromNowValue(convertedDateTime.fromNow());
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFromNowValue(convertedDateTime.fromNow());
  }, [convertedDateTime]);

  return (
    <div className={classes.root}>
      <Tooltip title={convertedDateTime.format('ddd, DD MMM YYYY hh:mm A')}>
        <div className={classes.label}>{fromNowValue}</div>
      </Tooltip>
    </div>
  );
};

DateTimeStatus.propTypes = {
  value: PropTypes.string.isRequired,
};

export default DateTimeStatus;