import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHands } from '@fortawesome/pro-light-svg-icons';

import Card from './Card';
import { ACCESS_METHOD_ICON_MAP } from './constants';



const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const AccessMethod = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  .icon-container {
    min-width: 20px;
  }
`;

const AccessMethods = ({ data, isLoading }) => {

  return (
    <Card
      title="Access Methods"
      icon={faHands}
      isLoading={isLoading}
      noData={_.isEmpty(data)}
    >
      <Container>
        {data?.map((item, index) => (
          <AccessMethod key={index}>
            <div className="icon-container">
              <FontAwesomeIcon icon={ACCESS_METHOD_ICON_MAP[item]} style={{fontSize: '16px'}}  />
            </div>
            {item}
          </AccessMethod>
        ))}
      </Container>
    </Card>
  );
};

AccessMethods.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default AccessMethods;
