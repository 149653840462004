import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import style from 'style';
import _Container from 'providerSite/components/Container';


const Main = styled.div`
  padding: 150px 0 150px 0;
  display: flex;
  justify-content: center;
  
  @media (max-width: ${style.screens.tab}) {
    padding: 100px 0;
  }
`;

const Container = styled(_Container)`
  flex-direction: row;
  
  @media (max-width: ${style.screens.tab}) {
    flex-direction: column;
  }
`;

const SquareBox = styled.div`
  --size: 330px;
  height: var(--size);
  width: var(--size);
  background: ${style.colors.secondary};
  border-radius: ${style.borderRadius.large};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: ${style.colors.primary};
  box-shadow: ${style.shadow(45)};
  
  @media (max-width: ${style.screens.tab}) {
    width: 100%;
    max-width: calc(100% - 100px);
    padding: 30px;
    height: fit-content;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  @media (max-width: ${style.screens.mobile}) {
    max-width: 100%;
  }

`;

const RectangleBox = styled.div`
  height: 270px;
  width: 600px;
  background: ${style.colors.primary};
  border: 2px solid ${style.colors.secondary};
  border-left: 0;
  border-top-right-radius: ${style.borderRadius.large};
  border-bottom-right-radius: ${style.borderRadius.large};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 10px 5px 40px;
  box-shadow: ${style.shadow(45)};
  color: ${style.colors.palette.white};
  text-align: left;
  
  @media (max-width: ${style.screens.tab}) {
    width: 100%;
    max-width: calc(100% - 100px);
    height: fit-content;
    padding: 30px 40px;
    border-bottom-left-radius: ${style.borderRadius.large};
    border-top-right-radius: 0;
    border: none;
  }
  
  @media (max-width: ${style.screens.mobile}) {
    max-width: 100%;
    padding: 20px 30px 25px 30px;
    height: auto;
  }
`;

const Pricing = ({squareText, rectangleText}) => {

  return (
    <Main>
      <Container size="medium" flex>
        <SquareBox>
          {squareText}
        </SquareBox>
        <RectangleBox>
          {rectangleText}
        </RectangleBox>
      </Container>
    </Main>
  );
};

Pricing.propTypes = {
  squareText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  rectangleText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default Pricing;
