import {
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
  faTwitterSquare,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';

import BrokerageFlowPartner from './BrokerageFlowPartner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React from 'react';
import { Typography } from 'antd';
import config from 'config';
import footerLogo from 'assets/partners/dummy_250x128_ccc_f8f8f8.png';
import logo from 'assets/partners/dummy_160x40_ccc_f8f8f8.png';
import style from 'style';
import styled from 'styled-components';

const BrokerageFlowClx = () => {

  return (
    <BrokerageFlowPartner
      source="clx"
      title="CLX - Service Request"
      FooterComponent={Footer}
      logo={logo}
      excludedServices={['Dietetics']}
    />
  );
};

const FooterMain = styled.div`
  width: 100%;
  background-color: #1A3092;
  display: flex;
  justify-content: center;
`;

const FooterContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  
  @media (max-width: ${style.screens.mobile}) {
    flex-direction: column;
  }
`;

const MypBottomLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  .logo {
    width: 250px;
    height: auto;
  }
  .logoText {
    color: ${style.colors.palette.white};
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  
  .link {
    color: ${style.colors.palette.white};
    :hover {
        color: ${style.colors.secondary};
    }
  }
  
  @media (max-width: ${style.screens.mobile}) {
    text-align: center;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  column-gap: 15px;
  font-size: 20px;

  .icon {
    cursor: pointer;
    font-size: 25px;
    color: ${style.colors.palette.white};
    &:hover {
      color: ${style.colors.secondary};
    }
  }
  
  @media (max-width: ${style.screens.mobile}) {
   justify-content: center;
  }
`;

const LeftContainer = styled.div`
  ul {
    list-style-type: none;
    
    li {
      margin: 15px 0 15px -38px;
    }
    .link {
      color: ${style.colors.palette.white};
      :hover {
        color: ${style.colors.secondary};
      }
    }
  }

  @media (max-width: ${style.screens.mobile}) {
    text-align: center;
    margin: 25px 0;
  }
`;

const TextTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${style.colors.palette.white};
`;

const Footer = () => {
  const handleSocialClick = url => window.open(`https://www.${url}`);
  return (
    <FooterMain>
      <FooterContainer>
        <MypBottomLogoContainer>
          <Link to="/">
            <img src={footerLogo} alt="aaa-footer-logo" className="logo" />
          </Link>
        </MypBottomLogoContainer>
        <LeftContainer>
          <TextTitle>
            Resources
          </TextTitle>
          <ul>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={{ pathname: config.link.participantFaq }}
              >
                <Typography className="link">Help & Support</Typography>
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                to={{ pathname: 'https://about.google/intl/ALL_au/' }}
              >
                <Typography className="link">
                  About Clx
                </Typography>
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={{
                  pathname: 'https://clickability.com.au/about/'
                }}
              >
                <Typography className="link">About Clickability</Typography>
              </Link>
            </li>
          </ul>
        </LeftContainer>
        <RightContainer>
          <SocialContainer>
            <FontAwesomeIcon
              className="icon"
              icon={faLinkedin}
              onClick={() => handleSocialClick('https://au.linkedin.com/company/clickability---rated-reviewed-reliable')}
            />
            <FontAwesomeIcon
              className="icon"
              icon={faFacebookSquare}
              onClick={() => handleSocialClick('https://www.facebook.com/clickability.com.au/')}
            />
            <FontAwesomeIcon
              className="icon"
              icon={faInstagramSquare}
              onClick={() => handleSocialClick('https://www.instagram.com/_clickability/?hl=en')}
            />
            <FontAwesomeIcon
              className="icon"
              icon={faTwitterSquare}
              onClick={() => handleSocialClick('https://twitter.com/_clickability?lang=en')}
            />
            <FontAwesomeIcon
              className="icon"
              icon={faYoutubeSquare}
              onClick={() => handleSocialClick('https://m.youtube.com/channel/UCyTsuJjsGPItTv5qPnhns7Q/videos')}
            />
          </SocialContainer>
          <Link
            target="_blank"
            to={{ pathname: 'https://policies.google.com/privacy?hl=en-AU'}}
          >
            <Typography className="link"><i>Partner</i> Privacy Policy</Typography>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={{
              pathname: 'https://clickability.com.au/privacy/'
            }}
          >
            <Typography className="link">Clickability Privacy Policy</Typography>
          </Link>
        </RightContainer>
      </FooterContainer>
    </FooterMain>
  );
};

export default BrokerageFlowClx;