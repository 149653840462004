import { clearHeader, makePath, request, setHeaders } from './http';
const API_NAME = 'auth';

export function setBearerToken(token) {
  if (!token) {
    clearHeader('authorization');
  } else {
    setHeaders({authorization: 'Bearer ' + token});
  }
}

export function clearBearerToken() {
  clearHeader('authorization');
}

export function login(username, password) {
  return request('POST', makePath(API_NAME, 'login'), {body: {username, password}})
    .then(response => {
      setBearerToken(response.payload.id);
      return response;
    });
}

export function verify() {
  return request('GET', makePath(API_NAME, 'verify'));
}

export function available({username, email}) {
  return request('GET', makePath(API_NAME, 'available'), {params: {username, email}});
}

export function refresh() {
  return request('POST', makePath(API_NAME, 'refresh'))
    .then(response => {
      setBearerToken(response.payload.id);
      return response;
    });
}

export function refreshProfile() {
  return request('GET', makePath(API_NAME, 'profile'));
}

export function updateProfile(args) {
  return request('PATCH', makePath(API_NAME, 'profile'), {body: args});
}

export function logout() {
  return request('POST', makePath(API_NAME, 'logout'))
    .then(response => {
      clearBearerToken();
      return response;
    })
    .catch(() => {
      // if the token has expired, logout will return a 401 error, but we are still logged out.
      clearBearerToken();
    });
}

export function signup(email, username, signupToken=null, emailOptIn=false, getTemporaryCredentials=false) {
  return request('POST', makePath(API_NAME, 'signup'), {
    body: {
      email,
      username,
      signup_token: signupToken,
      email_opt_in: emailOptIn,
      get_temporary_credentials: getTemporaryCredentials
    }
  });
}

export function activate(username, password, code) {
  return request('POST', makePath(API_NAME, 'activate'), {body: {username, password, code}});
}

export function recoverPassword(email, is_provider) {
  return request('POST', makePath(API_NAME, 'password/recover'), {body: {email, is_provider}});
}

export function resetPassword(username, password, code, is_provider) {
  return request('POST', makePath(API_NAME, 'password/reset'), {body: {username, password, code, is_provider}});
}

export function providerAvailable({username, email}) {
  return request('GET', makePath(API_NAME, 'provideravailable'), {params: {username, email}});
}

export function providerSignup(data) {
  return request('POST', makePath(API_NAME, 'providersignup'), {body: data});
}

export function recordProviderSignupData(data, step) {
  return request(
    'POST',
    makePath(API_NAME, 'record-signup'),
    {
      body: {
        ...data,
        step: step
      }
    });
}

export function providerClaimProfile({
  uuid,
  email,
  username,
  first_name,
  last_name,
  contact_phone,
  abn,
  entity_name,
  billing_customer_id,
  billing_plan_id,
  billing_payment_method_nonce,
  email_opt_in,
}) {
  const body = {
    uuid,
    email,
    username,
    first_name,
    last_name,
    contact_phone,
    abn,
    entity_name,
    billing_customer_id,
    billing_plan_id,
    billing_payment_method_nonce,
    email_opt_in,
  };
  return request('POST', makePath(API_NAME, 'claimprofile'), {body});
}

export function providerActivate(username, code) {
  return request('POST', makePath(API_NAME, 'provideractivate'), {body: {username, code}});
}

export function sendProviderActivateEmail(username) {
  return request('POST', makePath(API_NAME, 'activation-email/send'), {body: {username}});
}

export function providerLogin(username, password, data={}) {
  return request('POST', makePath(API_NAME, 'providerlogin'), {body: {username, password, ...data}})
    .then(response => {
      setBearerToken(response.payload.id);
      return response;
    });
}

export function updateProviderProfile(args) {
  return request('PATCH', makePath(API_NAME, 'providerprofile'), {body: args});
}

export function providerInvite(email, provider_uuid, role_names) {
  return request('POST', makePath(API_NAME, 'providerinvite'), {body: {email, provider_uuid, role_names}});
}

export function providerAcceptInvitation(code, password, first_name, last_name, contact_phone, location, created_mode) {
  const body = {
    code,
    password,
    first_name,
    last_name,
    contact_phone,
    location,
    created_mode
  };
  return request('POST', makePath(API_NAME, 'provideracceptinvitation'), {body});
}

export function providerRevokeInvitation(code) {
  return request('POST', makePath(API_NAME, 'providerrevokeinvitation'), {body: {code}});
}

export function userAutocomplete({query, limit, fields, providers, participants}) {
  return request('GET', makePath(API_NAME, 'user/autocomplete'),
    {params:{query, limit, fields, providers, participants}});
}

export function adminGetProviderProfile(uuid) {
  return request('GET', makePath(API_NAME, 'providerprofile', uuid));
}

export function adminUpdateProviderProfile({uuid, ...args}) {
  return request('PATCH', makePath(API_NAME, 'providerprofile', uuid), {body: args});
}

export function adminGetProfile(uuid) {
  return request('GET', makePath(API_NAME, 'profile', uuid));
}

export function adminUpdateProfile({uuid, ...args}) {
  return request('PATCH', makePath(API_NAME, 'profile', uuid), {body: args});
}

export function oAuthLogin(code, redirectUri) {
  return request('POST', makePath(API_NAME, 'oauthlogin'), {
    params: {
      code,
      redirect_uri: redirectUri,
    }
  }).then(response => {
    setBearerToken(response.payload.id);
    return response;
  });
}

export function oauthActivate(args) {
  return request('POST', makePath(API_NAME, 'oauthactivate'), {body: args});
}

export function verifyEmail(code) {
  return request('POST', makePath(API_NAME, 'verifyemail'), {params: {code}});
}
