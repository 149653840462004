import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  faPersonSimple,
  faHandshakeAngle,
  faUserTie,
  faInputText,
  faEnvelope,
  faPeoplePantsSimple
} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import Grow from '@material-ui/core/Grow';
import Lottie from 'lottie-react';
import * as EmailValidator from 'email-validator';

import {
  EMAIL_FIELD,
  FIRST_NAME_FIELD,
  PARTICIPANT_FIRST_NAME_FIELD,
  SERVICE_FOR_TYPE_MY_CLIENT,
  SERVICE_FOR_TYPE_MYSELF,
  SERVICE_FOR_TYPE_SOMEONE_I_CARE_FOR
} from '../../../constants';
import MultiChoiceSelection from '../../components/MultiChoiceSelection';
import Textbox from '../../components/Textbox';
import animationAsset from 'assets/personality-disorder-nobg.json';
import style from 'style';


const SERVICE_FOR_TYPES = {
  [SERVICE_FOR_TYPE_MYSELF]: {
    icon: faPersonSimple,
  },
  [SERVICE_FOR_TYPE_SOMEONE_I_CARE_FOR]: {
    icon: faHandshakeAngle,
  },
  [SERVICE_FOR_TYPE_MY_CLIENT]: {
    icon: faUserTie,
  },
};


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
  width: 100%;
  opacity: ${({$isDomLoaded}) => $isDomLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  
  .flex {
    width: 100%;
    display: flex;
    column-gap: 40px;
    margin-bottom: 30px;
    
    @media (max-width: ${style.sizes.small}) {
      flex-direction: column;
      row-gap: 25px;
    }
  }
  
  .lottie-container {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));
    overflow: hidden;
  }
`;

const ServiceFor = ({ value, onChange, scrollToFlowControlButton }) => {

  const [isDomLoaded, setIsDomLoaded] = useState(false);

  const handleOnChange = key => val => {
    onChange(value ? {...value, [key]: val} : {});
  };

  const valueFor = value?.for;

  const renderEmail = () => (
    <Textbox
      title="Where should we send the results?"
      icon={faEnvelope}
      placeholder="Please enter your email address"
      onChange={handleOnChange(EMAIL_FIELD)}
      defaultData={value?.[EMAIL_FIELD]}
      validator={EmailValidator.validate}
      errorMsg="Please enter a valid email address"
      type="email"
    />
  );

  return (
    <Container $isDomLoaded={isDomLoaded}>
      <div className="lottie-container">
        <Lottie
          loop
          animationData={animationAsset}
          onDOMLoaded={() => setIsDomLoaded(true)}
          style={{width: '500px', marginBottom: '-135px', marginTop: '-135px', overflow: 'hidden'}}
        />
      </div>
      <MultiChoiceSelection
        title="Select who this is for"
        icon={faPeoplePantsSimple}
        data={SERVICE_FOR_TYPES}
        selectedValues={value ? [valueFor] : []}
        onChange={values => {
          handleOnChange('for')(values[0]);
          scrollToFlowControlButton();
        }}
        isSingleChoice={true}
      />
      <Grow in={!!valueFor}>
        <div style={{width: '100%', maxWidth: '750px'}}>
          <div className="flex">
            <Textbox
              title="What is your name?"
              icon={faInputText}
              placeholder="Just first name is okay!"
              onChange={handleOnChange(FIRST_NAME_FIELD)}
              defaultData={value?.[FIRST_NAME_FIELD]}
              validator={val => !!val}
              errorMsg="Please enter a name"
            />
            {(!!valueFor && valueFor !== SERVICE_FOR_TYPE_MYSELF) && (
              <Textbox
                title={valueFor === SERVICE_FOR_TYPE_SOMEONE_I_CARE_FOR ? 'What is their name?' : 'What is your client name?'}
                icon={faInputText}
                placeholder="Just first name is fine!"
                onChange={handleOnChange(PARTICIPANT_FIRST_NAME_FIELD)}
                defaultData={value?.[PARTICIPANT_FIRST_NAME_FIELD]}
                validator={val => !!val}
                errorMsg="Please enter a name"
              />
            )}
            {valueFor === SERVICE_FOR_TYPE_MYSELF && renderEmail()}
          </div>
          {(!!valueFor && valueFor !== SERVICE_FOR_TYPE_MYSELF) && renderEmail()}
        </div>
      </Grow>
    </Container>
  );
};

ServiceFor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  scrollToFlowControlButton: PropTypes.func.isRequired,
};

export default ServiceFor;