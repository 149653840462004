import React, { useEffect, useState } from 'react';
import { AuthAPI } from '../../api';
import { useLocation } from 'react-router-dom';
import AuthFormPage from './components/AuthFormPage';
import AcceptInviteForm from './components/AcceptInviteForm';
import Page from '../../components/Page';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { useHistory } from 'react-router-dom';

const ProviderInvitePage = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');
  const [errorMessage, setErrorMessage] = useState();
  const [createMode, setCreateMode] = useState(false);  // if true, we are creating a new account.
  const acceptInvite = async(code, username=null, password=null) => {
    try {
      const response = await AuthAPI.providerAcceptInvitation(code, username, password);
      const { account_email } = response.payload;
      onComplete(account_email);
    } catch (err) {
      if (err.status === 409) {
        setCreateMode(true);
      } else {
        if (err.body) {
          const { message } = err.body;
          setErrorMessage(message);
        } else {
          setErrorMessage('An error occurred processing your request.  Please try again later.');
        }
      }
    }
  };

  const clearError = () => setErrorMessage();

  const goHome = () => history.push('/');

  const onComplete = (account_email) =>
    history.push(`/providers/login?username=${encodeURIComponent(account_email)}`);

  useEffect(() => {
    acceptInvite(code);
  }, [code]); // eslint-disable-line

  if (createMode) {
    return (
      <AuthFormPage title="Clickability Provider Account Creation">
        <AcceptInviteForm
          code={code}
          onComplete={onComplete}
          activateApi={AuthAPI.providerAcceptInvitation}
        />
      </AuthFormPage>
    );
  }

  return (
    <Page title="Clickability Invitation">
      <Dialog
        open={errorMessage !== undefined}
        onClose={clearError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Unable to Process Invitation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={goHome} color="primary" variant="outlined">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default ProviderInvitePage;
