/*
  Cross-tab authentication synchronization.
  We are using session-storage to keep the token out of local storage
  (based on advice from our penetration test results).  This creates
  the problem where newly opened tabs are not authenticated.  To solve
  this, if we find we are not authenticated, we send a request to
  other open tabs to see if we can get an auth token from them.

  https://medium.com/@marciomariani/sharing-sessionstorage-between-tabs-5b6f42c6348c
  https://blog.guya.net/2015/06/12/sharing-sessionstorage-between-tabs-for-secure-multi-tab-authentication/
*/

import * as AuthStore from './auth';


const GET_EVENT = 'getAuth';
const SET_EVENT = 'setAuth';
const TAB_ID = String(Math.random());
const haveLocalStorage = typeof(Storage) !== 'undefined';

const registerListener = (store) => {
  window.addEventListener('storage', event => {
    if (event.key === GET_EVENT && event.newValue) {
      const { auth } = store.getState();
      if (auth && auth.isAuthenticated) {
        sendSync(auth, event.newValue);
      }
    } else if (event.key === SET_EVENT && event.newValue) {
      const {tabId, auth} = JSON.parse(event.newValue);
      if (!tabId || tabId === TAB_ID) {
        store.dispatch(AuthStore.sync(auth));
      }
    }
  });
};

const requestSync = () => {
  if (haveLocalStorage) {
    localStorage.setItem(GET_EVENT, TAB_ID);
    localStorage.removeItem(GET_EVENT);
  }
};

const sendSync = (state, tabId=undefined) => {
  if (haveLocalStorage) {
    localStorage.setItem(SET_EVENT, JSON.stringify({auth: state, tabId: tabId}));
    localStorage.removeItem(SET_EVENT);
  }
};

export {
  sendSync,
  requestSync,
  registerListener,
};
