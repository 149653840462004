import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';


const Main = styled.div`
  font-size: 18px;
`;

const Text = ({ children, ...props }) => {

  return (
    <Main {...props}>
      {children}
    </Main>
  );
};

Text.propTypes = {
  children: PropTypes.node,
};


export default Text;
