import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import styled from 'styled-components';


dayjs.extend(utc);
dayjs.extend(tz);


const Container = styled.div`
  width: max-content;
  cursor: default;
`;


const DateTimeFrom = ({ value, tooltipPlacement = 'top', ...props }) => {

  const convertedDateTime = dayjs(value).utc('z').tz();
  const [fromNowValue, setFromNowValue] = useState(convertedDateTime.fromNow());

  useEffect(() => {
    const interval = setInterval(() => {
      setFromNowValue(convertedDateTime.fromNow());
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFromNowValue(convertedDateTime.fromNow());
  }, [convertedDateTime]);

  return (
    <Tooltip
      title={convertedDateTime.format('ddd, DD MMM YYYY hh:mm A')}
      placement={tooltipPlacement}
      overlayInnerStyle={{
        fontSize: '11px',
        fontWeight: 500,
        padding: '5px 8px 2px 8px',
        margin: 0,
        minHeight: 0,
      }}
    >
      <Container {...props}>{fromNowValue}</Container>
    </Tooltip>
  );
};

DateTimeFrom.propTypes = {
  value: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.string,
};

export default DateTimeFrom;