import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import  {useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faConciergeBell } from '@fortawesome/pro-solid-svg-icons';
import {makeStyles} from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';

import { ServiceRequestNotificationStore } from 'store';


const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    cursor: 'default',
  },
  badge: {
    '& .MuiBadge-badge': {
      padding: '0 3px',
      fontSize: '11px',
    },
  },
  icon: {
    fontSize: '26px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.info.dark,
    },
  },
  popover: {
    marginTop: '5px'
  },
}));

const ServiceRequestNotification = ({ className }) => {
  const classes = useStyles();

  const data = useSelector(state => state.serviceRequestNotification);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();


  const isOpen = Boolean(anchorEl);
  const isMultiProvider = data?.serviceRequests?.length && data?.serviceRequests?.length > 1;
  const toolTipText = !isMultiProvider ? getTooltipText(data.totalAvailable) : '';

  const handleClick = (event) => {
    if (isMultiProvider) {
      setAnchorEl(event.currentTarget);
    } else {
      if (!Array.isArray(data.serviceRequests) || !data.serviceRequests.length)
        return;
      history.push(`/provider/${data.serviceRequests[0].providerUuid}/admin/serviceRequests`);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(ServiceRequestNotificationStore.refreshNotification());
    const interval = setInterval(() => {
      dispatch(ServiceRequestNotificationStore.refreshNotification());
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tooltip title={toolTipText}>
      <div className={classNames(classes.root, className)}>
        <Badge className={classes.badge} badgeContent={data.totalAvailable} color="error">
          <FontAwesomeIcon className={classes.icon} icon={faConciergeBell} onClick={handleClick}/>
        </Badge>
        <Popover
          className={classes.popover}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {Array.isArray(data.serviceRequests) && data.serviceRequests.map((item, index) => (
            <Item
              key={`srn-item-${index}`}
              name={item.providerName}
              uuid={item.providerUuid}
              count={item.available}
            />
          ))}
        </Popover>
      </div>
    </Tooltip>
  );
};

const itemStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    columnGap: '20px',
    justifyContent: 'space-between',
    padding: '12px 15px',
    cursor: 'pointer',
    '&:not(:last-child)': {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.common.lightBorder,
    },
    '&:hover': {
      background: theme.palette.common.paleGrey,
    },
  },
  count: {
    background: theme.palette.error.main,
    minWidth: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px',
    color: theme.palette.common.white,
    fontSize: '11px',
  },
}));

const Item = ({ name, uuid, count }) => {
  const classes = itemStyles();

  const history = useHistory();

  const handleClick = uuid => {
    history.push(`/provider/${uuid}/admin/serviceRequests`);
  };

  return (
    <Tooltip title={getTooltipText(count)}>
      <div className={classes.root} onClick={() => handleClick(uuid)}>
        <div>{name}</div>
        <div className={classes.count}>{count}</div>
      </div>
    </Tooltip>
  );
};

const getTooltipText = number => {
  return `${number ? number : 'No'} New Service Requests Available`;
};

Item.propTypes = {
  name: PropTypes.string,
  uuid: PropTypes.string,
  count: PropTypes.number,
};

ServiceRequestNotification.propTypes = {
  className: PropTypes.string,
};

export default ServiceRequestNotification;