import PropTypes from 'prop-types';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Tag, Badge } from 'antd';


const useStyles = makeStyles(theme => ({
  specialisationContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '15px',
    rowGap: '10px',
  },
  specialisationLabel: {
    fontSize: '13px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
    }
  },
}));

const SpecialisationPanel = ({specialisations}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.specialisationLabel}>
        Participant Endorsements:
      </div>
      <div className={classes.specialisationContainer}>
        {specialisations.map(item => (
          <Badge
            key={item.id}
            count={item.endorsements}
            style={{fontSize: '10px', fontWeight: '500'}}
            color="cyan"
            size="small"
          >
            <Tag style={{margin: 0}}>{item.name}</Tag>
          </Badge>
        ))}
      </div>
    </React.Fragment>
  );
};

SpecialisationPanel.propTypes = {
  specialisations: PropTypes.array.isRequired,
};

export default SpecialisationPanel;
