import React from 'react';
import PropTypes from 'prop-types';

import * as QueryString from 'query-string';

import Page from 'components/Page.js';
import Search from '../components/Search';
import { makeSubtitle } from '../utils';


const SearchPage = ({ location }) => {

  const urlParams = QueryString.parse(location.search);
  const title = makeSubtitle(urlParams);

  return (
    <Page title={title}>
      <Search location={location} />
    </Page>
  );

};

SearchPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default SearchPage;
