const adminRole = 'Admin';
const editorRole = 'Editor';
const reviewsRole = 'Reviews';
const billingRole = 'Billing';
const intakeRole = 'Intake';
const connectRole = 'Connect User';

export {
  adminRole,
  editorRole,
  reviewsRole,
  billingRole,
  intakeRole,
  connectRole,
};
