import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { isValidPhoneNumber } from 'libphonenumber-js';
import styled from 'styled-components';

import { Textbox, Dropdown } from 'providerSite/components/inputs';
import { setIndividualAnswer } from '../utils';
import {
  FIELD_BUSINESS_NAME,
  FIELD_JOB_ROLE,
  FIELD_PHONE,
} from '../constants';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  justify-content: center;
  row-gap: 10px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 20px;
  width: 100%;
`;

const ROLE_OPTIONS = [
  'Owner/General Manager',
  'Marketing',
  'Support Coordinator',
  'Sales Manager or Sales staff',
  'Other',
];

const nameValidator = value => !!value?.match(/^[\w\-@&# ]+$/g);


const ProviderPage = ({ onChange, value }) => {

  const [data, setData] = useState(value ? value : {});

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <Container>
      <Row>
        <Textbox
          title="What is your business name?"
          defaultValue={value?.[FIELD_BUSINESS_NAME]}
          onChange={val => setIndividualAnswer(setData, FIELD_BUSINESS_NAME, val)}
          validator={nameValidator}
          errorMsg="Please only use valid characters"
          colouredBorder
        />
      </Row>
      <Row>
        <Dropdown
          title="What is your job role?"
          options={ROLE_OPTIONS}
          defaultValue={value?.[FIELD_JOB_ROLE]}
          onChange={val => setIndividualAnswer(setData, FIELD_JOB_ROLE, val)}
          colouredBorder
        />
      </Row>
      <Row>
        <Textbox
          title="What is your mobile phone number?"
          defaultValue={value?.[FIELD_PHONE]}
          onChange={val => setIndividualAnswer(setData, FIELD_PHONE, val)}
          validator={value => !!value && isValidPhoneNumber(value, 'AU')}
          errorMsg="Please enter a valid phone number"
          colouredBorder
        />
      </Row>
    </Container>
  );
};

ProviderPage.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};


export default ProviderPage;
