import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { formatComponentId } from '../../lib/util.js';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1rem 0',
  },
  label: {
    width: '100%',
    marginBottom: '0.5rem',
  },
  field: {
    width: '100%',
  },
});

const TextInputField = ({label, value=null, className='', ...textFieldProps}) => {
  const classes = useStyles();
  const inputValue = (value === null) ? '' : value;
  return (
    <div className={`${classes.root} ${className}`}>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      <TextField
        required
        id={formatComponentId('text-input', label)}
        value={inputValue}
        variant="outlined"
        className={classes.field}
        {...textFieldProps}
      />
    </div>
  );
};

TextInputField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default TextInputField;
