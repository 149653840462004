import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Modal, Alert, Button } from 'antd';

import style from 'style';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-bottom: 10px;
  padding-top: 30px;
  text-align: center;
  font-size: 22px;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  
  .phone {
    font-size: 32px;
    font-weight: 500;
  }
`;


const PhoneModal = ({ open, onCancel, data, onEmailClick }) => {

  const renderAlertContent = () => (
    <>
      If you don&apos;t get through why not send them an&nbsp;
      <span onClick={onEmailClick} style={{fontWeight: 700, textDecoration: 'underline', cursor: 'pointer'}}>email?</span>
    </>
  );

  return (
    <Modal visible={open} onCancel={onCancel} footer={null}>
      <Container>
        <div>
          Please mention <span style={{color: style.colors.secondary, fontWeight: 700}}>Clickability</span> when calling {data.name}.
        </div>
        <div className="phone">
          {data.phone}
        </div>
        <Button
          type="primary"
          style={{minWidth: '100px'}}
          onClick={onCancel}
        >
          Done
        </Button>
      </Container>
      {data.email && (
        <Alert
          message={renderAlertContent()}
          type="info"
          style={{textAlign: 'start', fontSize: '13px', fontWeight: 500}}
          showIcon
        />
      )}
    </Modal>
  );
};

PhoneModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  data: PropTypes.object,
  onEmailClick: PropTypes.func.isRequired,
};


export default PhoneModal;

