const SET_LOADING = 'review/SET_LOADING';
const SET_REVIEW_LIST = 'review/SET_REVIEW_LIST';
const SET_ACTIVE_REVIEW = 'review/SET_ACTIVE_REVIEW';
const CLEAR_ACTIVE_REVIEW = 'review/CLEAR_ACTIVE_REVIEW';

const initialState = {
  size: 0,
  total: 0,
  reviewList: [],
  reviewListLoading: true,
  activeReview: undefined,
};

// Action Creators

export function isLoading(payload) {
  return {
    type: SET_LOADING,
    payload: payload,
  };
}

export function setReviewList(payload, size, total) {
  return {
    type: SET_REVIEW_LIST,
    payload: payload,
    size: size,
    total: total,
  };
}

export function setActiveReview(payload) {
  return {
    type: SET_ACTIVE_REVIEW,
    payload: payload,
  };
}

export function clearActiveReview() {
  return {
    type: CLEAR_ACTIVE_REVIEW,
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case SET_LOADING:
    const key = `${action.attribute}Loading`;  // eslint-disable-line
    return {
      ...state,
      [key]: action.payload,
    };

  case SET_REVIEW_LIST:
    return {
      ...state,
      size: action.size,
      total: action.total,
      reviewList: action.payload,
      reviewListLoading: false,
    };

  case SET_ACTIVE_REVIEW:
    return {
      ...state,
      activeReview: action.payload,
    };

  case CLEAR_ACTIVE_REVIEW:
    return {
      ...state,
      activeReview: undefined,
    };

  default:
    return state;
  }
}
