import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  faBolt,
  faFlagCheckered,
  faShare
} from '@fortawesome/pro-duotone-svg-icons';

import ProgressStepper from 'modules/admin/components/Match/ServiceRequestMatching/ProgressStepper';
import * as constants from './constants';


const ProgressBar = ({ createdAt, events }) => {
  const [progressSteps, setProgressSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(constants.STEP_REQUEST_SUBMITTED);

  useEffect(() => formatProgressSteps(events), [events, createdAt]);

  const formatProgressSteps = (progressEvents) => {
    const combinedEvents = {};
    progressEvents.forEach(
      ({events: progressEventForEachProvider}) => {
        Object.entries(progressEventForEachProvider).forEach(
          ([eventName, event]) => {
            if  (
              !Object.hasOwnProperty.call(combinedEvents, eventName) ||
              combinedEvents[eventName].createdAt > event.createdAt) {
              combinedEvents[eventName] = event;
            }
          }
        );
      }
    );
    const steps = [
      {
        key: constants.STEP_REQUEST_SUBMITTED,
        name: 'Request Submitted',
        datetime: createdAt,
        icon: faShare,
      },
      {
        key: constants.STEP_IN_PROGRESS,
        name: 'Request In Progress',
        datetime: combinedEvents?.accept?.createdAt,
        icon: faBolt,
      },
      {
        key: constants.STEP_COMPLETE,
        name: 'Request Complete',
        datetime: combinedEvents?.successful ? combinedEvents?.successful?.createdAt : combinedEvents?.unsuccessful?.createdAt,
        icon: faFlagCheckered,
      },
    ];
    setProgressSteps(steps);
    setCurrentStep(() => {
      return steps.reduce((acc, item) => {
        if (item.datetime)
          return item.key;
        return acc;
      }, constants.STEP_REQUEST_SUBMITTED);
    });
  };

  return (
    <ProgressStepper
      steps={progressSteps}
      currentStep={currentStep}
    />
  );
};

ProgressBar.propTypes = {
  createdAt: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired
};

export default ProgressBar;