import React  from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';

import style from 'style';
import { NAV_SIZE_MIN, NAV_SIZE_MAX } from '../../constants';
import Dot from 'components/ServiceRequestStatus/Dot';


const Container = styled.div`
  height: 48px;
  width: 95%;
  display: flex;
  font-size: 15px;
  color: #6b6b76;
  align-items: center;
  padding-left: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  border-left: 2px solid transparent;
  overflow: hidden;
  white-space: nowrap;
  margin-left: -1px;
  transition: all 0.5s;
  
  ${({isSelected}) => isSelected && css`
    background: rgba(5, 14, 64, 0.1);
    border-left-color: ${style.colors.secondary};
    color: ${style.colors.primary};
  `}
  
  ${({navSize}) => navSize === NAV_SIZE_MIN && css`
    padding-right: 0;
  `}
  
  &:hover {
    background: #050E401A;
    border-left-color: ${style.colors.secondary};
    color: ${style.colors.primary};
  }
  
  .icon {
    margin-bottom: 4px;
    margin-right: ${({navSize}) => navSize === NAV_SIZE_MAX && '10px'};
    width: 20px;
    font-size: 18px;
    flex-shrink: 0;
  }
`;

const PulseContainer = styled.div`
  position: absolute;
  top: ${({navSize}) => navSize === NAV_SIZE_MAX ? '-12px' : '-15px'};
  right: -15px;
`;

const Item = ({ name, icon, uri, isSelected, navSize, pulse }) => {
  const history = useHistory();

  const isFullNave = navSize === NAV_SIZE_MAX;

  return (
    <Tooltip placement="right" title={!isFullNave && name}>
      <Container navSize={navSize} isSelected={isSelected} onClick={() => history.push(uri)}>
        <FontAwesomeIcon className="icon" icon={icon} />
        {isFullNave && name}
        <div style={{position: 'relative'}}>
          {pulse && (
            <PulseContainer navSize={navSize}>
              <Dot color={style.colors.error}/>
            </PulseContainer>
          )}
        </div>
      </Container>
    </Tooltip>
  );
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  uri: PropTypes.string.isRequired,
  navSize: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  pulse: PropTypes.bool,
};


export default Item;

