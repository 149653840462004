export const getDefaultWidth = columns => {
  let leftWidth = 100;
  let count = columns.length;
  for ( let column of columns ) {
    if ( column.width ) {
      leftWidth -= parseFloat(column.width);
      count -= 1;
    }
  }
  return `${leftWidth / count}%`;
};

export const getRowKey = row => row.uuid ? row.uuid : row.id;

export const getColumnKey = column => column.key ? column.key : column.dataIndex;

export const getRowValue = (row, dataIndex) => {
  if (!dataIndex.includes('.'))
    return row[dataIndex];

  const dataIndices = dataIndex.split('.');

  return dataIndices.reduce((acc, _dataIndex) => {
    return acc[_dataIndex];
  }, row);
};

