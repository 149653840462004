import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ReviewResponseCard from './ReviewResponseCard.js';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: 0,
  },
});

const ReviewResponseCardList = ({ data, providerName }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* Displaying all comments (regardless of status) because comments for reviews are not currently reviewed */}
      {data.map((comment, i) =>
        <ReviewResponseCard key={i} data={comment} providerName={providerName} />
      )}
    </div>
  );
};

ReviewResponseCardList.propTypes = {
  data: PropTypes.array,
  providerName: PropTypes.string.isRequired,
};

export default ReviewResponseCardList;