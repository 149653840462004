import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Alert } from 'antd';

const useStyles = makeStyles({
  container: {
    marginBottom: '8px',
  },
  navbar: {
    marginTop: '10px',
    paddingLeft: '18px',
    display: 'flex',
    columnGap: '10px',
  },
});

const SpecialisationFormContainer = ({flow, answers, onCancel, onComplete, complete, children}) => {

  const allQuestionsAnswered = () => {
    for (const page of flow.pages) {
      for (const question of page.questions) {
        if (!(question.field in answers)) return false;
      }
    }
    return true;
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      {children}
      <div className={classes.navbar}>
        <Button
          type="primary"
          ghost
          onClick={()=>onCancel(answers)}
        >
          Back
        </Button>
        <Button
          type="primary"
          onClick={()=>onComplete(answers)}
          disabled={!complete}
          style={{minWidth: '150px'}}
        >
          Submit
        </Button>
      </div>
      {allQuestionsAnswered() && !complete &&
        <Alert style={{marginTop: '20px'}} type="error" message={flow.incompleteMessage} />
      }
    </div>
  );
};

SpecialisationFormContainer.propTypes = {
  flow: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
  complete: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default SpecialisationFormContainer;