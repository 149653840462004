import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';


const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: `${theme.palette.common.darkSlateBlue} !important`,
    },
  },
}))(Button);

export default ColorButton;