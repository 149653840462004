export const FIELD_FIRST_NAME = 'firstName';
export const FIELD_LAST_NAME = 'lastName';
export const FIELD_EMAIL = 'email';
export const FIELD_PASSWORD = 'password';
export const FIELD_BUSINESS_NAME = 'businessName';
export const FIELD_JOB_ROLE = 'jobRole';
export const FIELD_PHONE = 'phone';
export const FIELD_GOAL = 'goal';

export const SIGNUP_FLOW_STEP_ONE = 'userCreated';
export const SIGNUP_FLOW_STEP_TWO = 'organisationCreated';
