import React from 'react';
import { Route } from 'react-router-dom';
import ConnectSignupPage from './ConnectSignup';


// modules return fragments containing routes.
// passing the component as a prop ensures match params are passed in props
// see https://stackoverflow.com/questions/46197178/cannot-read-property-params-of-undefined-react-router-4

export const routes = () => {
  return [
    <Route key="/connect/signup" exact path="/connect/signup" component={ConnectSignupPage} />
  ];
};