import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Typography } from 'antd';

import style from 'style';
import Container from 'providerSite/components/Container';


const Main = styled.div`
  padding: 200px 0 150px 0;
  display: flex;
  justify-content: center;
  .title {
    position: relative;
    &::before {
      position: absolute;
      content: '“';
      color: ${style.colors.secondary};
      opacity: 50%;
      font-size: 300px;
      top: -115px;
      left: 20px;
      z-index: -1;
    }
    &::after {
      position: absolute;
      content: '”';
      color: ${style.colors.secondary};
      opacity: 50%;
      font-size: 300px;
      bottom: -320px;
      right: 10px;
      z-index: -1;
    }
  }
  .underline {
    text-decoration: underline;
    text-decoration-color: ${style.colors.secondary};
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
  }
  
  @media (max-width: ${style.screens.tab}) {
    padding: 50px 20px 70px 20px;
    
    .title {
      &::before {
        font-size: 200px;
        top: -85px;
        left: 20px;
      }
      &::after {
        font-size: 200px;
        bottom: -200px;
        right: 10px;
      }
    }
  }
  
  @media (max-width: ${style.screens.mobile}) {
    .title {
      &::before {
        font-size: 150px;
        top: -55px;
        left: 0;
      }
      &::after {
        font-size: 150px;
        bottom: -140px;
        right: 10px;
      }
    }
  }
`;

const SectionThree = ({title}) => {

  const isTabletOrMobile = useMediaQuery({maxWidth: style.screens.tab});

  return (
    <Main>
      <Container size="medium" flex>
        <Typography.Title level={isTabletOrMobile ? 3 : 2} className="title">
          {title}
        </Typography.Title>
      </Container>
    </Main>
  );
};

SectionThree.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default SectionThree;
