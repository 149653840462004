import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: '1rem',
    padding: '0 1rem',
  },
  header: {
    textAlign: 'left',
    textTransform: 'uppercase',
    fontSize: '0.9rem',
    fontWeight: '900',
    color: theme.palette.primary.main,
    marginBottom: '0.5rem',
  },
  list: {
    listStyleType: 'none',
    paddingInlineStart: 0,
  },
}));


/**
 * This component assumes the following:
 * items is of the form:
 * [{“id”: 1, “name”: “something”, ...}, ...]
 * checkedItems is of the form:
 * [1, 2, ...]
 */
const CheckboxItemList = ({header=null, items, checkedItems=null, onChange, className='', additionalStyles=''}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      <ul className={`${classes.list} ${additionalStyles}`}>
        {header === null ? null : (
          <li className={classes.header}>
            {header}
          </li>
        )}
        {items.map(item => {
          const checked = (checkedItems !== null && checkedItems.includes(item.id)) ? true : false;
          return (
            <li key={item.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={onChange}
                    value={item.id}
                  />
                }
                label={item.name}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

CheckboxItemList.propTypes = {
  header: PropTypes.string,
  items: PropTypes.array.isRequired,
  checkedItems: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  additionalStyles: PropTypes.string,
};

export default CheckboxItemList;
