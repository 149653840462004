import DateInputField from './DateInputField';
import TextInputField from './TextInputField';
import SelectInputField from './SelectInputField';
import CheckboxInputField from './CheckboxInputField';
import CheckboxItemList from './CheckboxItemList';
import RadioItemListArrays from './RadioItemListArrays';
import NumberInputField from './NumberInputField';
import LocationInput from './LocationInput';
import SwitchInputField from './SwitchInputField';
import RichTextField from './RichTextField';

export {
  DateInputField,
  TextInputField,
  SelectInputField,
  CheckboxInputField,
  CheckboxItemList,
  RadioItemListArrays,
  NumberInputField,
  LocationInput,
  SwitchInputField,
  RichTextField,
};

export const charCountLabel = (label, value, maxChars) => {
  if (value && value.length) {
    return {
      label: `${label} (${value.length} of ${maxChars} characters)`,
      error: value.length > maxChars,
    };
  } else {
    return {
      label: `${label} (max ${maxChars} characters)`,
    };
  }
};
