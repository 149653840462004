import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsQuestion } from '@fortawesome/pro-duotone-svg-icons';


const useStyles = makeStyles(theme => ({
  root: {
    background: '#fffbe6',
    borderRadius: '12px',
    padding: '10px 14px',
    color: theme.palette.common.textGrey,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ffe58f',
  },
  text: {
    fontSize: '12px',
    fontWeight: '500',
    marginLeft: '10px',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.common.tealish,
    },
    '& a:hover': {
      color: theme.palette.common.dustyOrange,
    }
  },
  icon: {
    fontSize: '18px',
    color: '#f89f1a',
  },
}));

const InfoBar = ({ text, className='' }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      <FontAwesomeIcon icon={faCommentsQuestion} className={classes.icon} />
      <Typography variant="subtitle2" className={classes.text}>{text}</Typography>
    </div>
  );
};

InfoBar.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default InfoBar;