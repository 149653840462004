import ContentContainer from '../../components/ContentContainer.js';
import {Helmet} from 'react-helmet';
import Markdown from '../../components/Markdown';
import Page from '../../components/Page.js';
import PropTypes from 'prop-types';
import React from 'react';
import config from 'config';

const ContentPage = ({content}) => {
  if (!content) return null;

  return (
    <Page title={content.title}>
      <Helmet 
        link = {[{'rel': 'canonical', 'href': `${config.website.URL}${content.path}`}]}
      />
      <ContentContainer title={content.title}>
        <Markdown content={content.body}/>
      </ContentContainer>
    </Page>
  );
};

ContentPage.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    path: PropTypes.string,
  })
};

export default ContentPage;
