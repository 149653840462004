import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import visualOne from 'assets/how-we-help-1.svg';
import visualThree from 'assets/how-we-help-3.svg';
import visualTwo from 'assets/how-we-help-2.svg';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '3rem 0 6rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none',
    background: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      padding: '20px 16px',
    },
  },
  content: {
    padding: '1rem 3rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      marginBottom: '1rem',
    },
  },
  header: {
    fontSize: '28px',
    fontWeight: '600',
    color: theme.palette.primary.main,
    width: '100%',
    textAlign: 'center',
    marginBottom: '20px',
  },
  items: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '2.563rem',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      rowGap: '40px',
      paddingTop: '15px',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 1rem',
    width: '18.75rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '38px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '28px',
    },
  },
  circleText: {
    color: theme.palette.common.white,
    fontWeight: '600',
    fontSize: '22px',
  },
  itemText: {
    width: '100%',
    textAlign: 'center',
    fontSize: '16px',
  },
  itemTitle: {
    fontSize: '20px',
    fontWeight: '500',
    color: theme.palette.primary.main,
    width: '100%',
    textAlign: 'center',
    marginBottom: '10px',
  },
  visual: {
    height: '112px',
    marginBottom: '2rem',
  },
}));


const HowWeHelp = ({title = true, ...props}) => {
  const classes = useStyles(props);

  const renderItem = (number, visual, header, text) => (
    <div className={classes.item}>
      <div className={classes.circle}>
        <div className={classes.circleText}>
          {number}
        </div>
      </div>
      <img src={visual} alt="how-we-help-visual" className={classes.visual} />
      <div className={classes.itemTitle}>
        {header}
      </div>
      <div className={classes.itemText}>
        {text}
      </div>
    </div>
  );

  return (
    <div className={classes.root} aria-hidden="true">
      <Container maxWidth="lg" fixed disableGutters={true}>
        <div className={classes.content}>
          {title && 
            <Typography variant="h2" className={classes.header}>
              Here&apos;s how we help
            </Typography>
          }
          <div className={classes.items}>
            {renderItem(
              1,
              visualOne,
              'We find out what you need',
              'We only ask for your details once and always securely store them. ' +
              'Once you share them, we’ll do the rest.'
            )}
            {renderItem(
              2,
              visualTwo,
              'We connect you with providers',
              'We find and connect you with providers on your behalf, sharing ' +
              'relevant information to get your services started quickly.'
            )}
            {renderItem(
              3,
              visualThree,
              'Providers contact you directly',
              'We pass on your details with the trusted providers we\'ve matched ' +
              'you with and they’ll follow up with you.'
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

HowWeHelp.propTypes = {
  title: PropTypes.bool,
};

export default HowWeHelp;
