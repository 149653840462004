import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import clickaAgentImg from 'assets/clicka-agent.png';
import clickaClientImg from 'assets/clicka-client.png';
import {makeStyles} from '@material-ui/core/styles';

const images = {
  agent: clickaAgentImg,
  client: clickaClientImg,
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    columnGap: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: '20px',
    },
  },
  speechContainer: {
    width: '100%',
    minHeight: '70px',
    background: theme.palette.common.darkSlateBlue,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '12px',
    zIndex: '1',
    position: 'relative',
    display: 'flex',
  },
  text: {
    alignSelf: 'center',  // vertical centering with flexbox
    position: 'relative',
    zIndex: '2',
    color: theme.palette.common.white,
    fontWeight: '410',
    padding: '15px',
  },
  leftColumn: {
    width: '20%',
  },
  rightColumn: {
    width: '20%',
  },
  image: {
    width: '80px',
    height: '80px',
  },
  left: {
    '&:before': {
      content: '\'\'',
      width: '0px',
      height: '0px',
      position: 'absolute',
      borderLeft: '15px solid transparent',
      borderRight: `15px solid ${theme.palette.common.darkSlateBlue}`,
      borderTop: `15px solid ${theme.palette.common.darkSlateBlue}`,
      borderBottom: '15px solid transparent',
      left: '-16px',
      top: '0px',
      [theme.breakpoints.down('sm')]: {
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
        borderTop: '15px solid transparent',
        borderBottom: `15px solid ${theme.palette.common.darkSlateBlue}`,
        position: 'absolute',
        left: 'calc(50% - 15px)',
        top: '-29px',
      },
    },
  },
  right: {
    '&:after': {
      content: '\'\'',
      width: '0px',
      height: '0px',
      position: 'absolute',
      borderRight: '15px solid transparent',
      borderLeft: `15px solid ${theme.palette.common.darkSlateBlue}`,
      borderTop: `15px solid ${theme.palette.common.darkSlateBlue}`,
      borderBottom: '15px solid transparent',
      right: '-16px',
      top: '0px',
      [theme.breakpoints.down('sm')]: {
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
        borderBottom: '15px solid transparent',
        borderTop: `15px solid ${theme.palette.common.darkSlateBlue}`,
        position: 'absolute',
        left: 'calc(50% - 15px)',
        top: 'revert',
        bottom: '-29px',
      },
    },
  },
}));

export const SpeechBubble = ({ image, direction='right', children, ...props}) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.leftColumn}>
        {direction === 'left' && image && <img src={images[image]} className={classes.image} alt="clicka-agent"/>}
      </div>
      <div className={classNames(classes.speechContainer, classes[direction])}>
        <div className={classes.text}>
          {children}
        </div>
      </div>
      <div className={classes.rightColumn}>
        {direction === 'right' && image && <img src={images[image]} className={classes.image} alt="clicka-agentright"/>}
      </div>
    </div>
  );
};

SpeechBubble.propTypes = {
  image: PropTypes.string,
  direction: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classes: PropTypes.object,
};

export default SpeechBubble;