const SET_LOADING = 'provider/SET_LOADING';
const SET_PROFILE = 'provider/SET_PROFILE';
const SET_PROFILES = 'provider/SET_PROFILES';
const SET_PREMISES = 'provider/SET_PREMISES';
const SET_LISTING = 'provider/SET_LISTING';
const SET_LISTINGS = 'provider/SET_LISTINGS';

const initialState = {
  profile: {},
  profileLoading: true,
  profiles: {},
  profilesLoading: true,
  profilesSize: 0,
  profilesTotal: 0,
  premises: [],
  premisesLoading: true,
  listing: {},
  listingLoading: true,
  listings: [],
  listingsLoading: true,
  listingsSize: 0,
  listingsTotal: 0,
};

// Action Creators

export function isLoading(attribute, payload) {
  return {
    type: SET_LOADING,    
    attribute: attribute,
    payload: payload,
  };  
}

export function setProfile(payload) {
  return {
    type: SET_PROFILE,
    payload: payload,
  };
}

export function setProfiles(payload, size, total) {
  return {
    type: SET_PROFILES,
    payload: payload,
    size: size,
    total: total,
  };
}

export function setPremises(payload) {
  return {
    type: SET_PREMISES,
    payload: payload,
  };
}

export function setListing(payload) {
  return {
    type: SET_LISTING,
    payload: payload,
  };
}

export function setListings(payload, size, total) {
  return {
    type: SET_LISTINGS,
    payload: payload,
    size: size,
    total: total,
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case SET_LOADING:
    const key = `${action.attribute}Loading`;  // eslint-disable-line
    return {
      ...state,
      [key]: action.payload,
    };      

  case SET_PROFILE:
    return {
      ...state,
      profile: action.payload,
      profileLoading: false,
    };

  case SET_PROFILES:
    return {
      ...state,
      profiles: action.payload,
      profilesLoading: false,
      profilesSize: action.size,
      profilesTotal: action.total,
    };

  case SET_PREMISES:
    return {
      ...state,
      premises: action.payload,
      premisesLoading: false,
    };

  case SET_LISTING:
    return {
      ...state,
      listing: action.payload,
      listingLoading: false,
    };

  case SET_LISTINGS:
    return {
      ...state,
      listings: action.payload,
      listingsLoading: false,
      listingsSize: action.size,
      listingsTotal: action.total,
    };

  default:
    return state;
  }
}
