import ContentContainer from '../../components/ContentContainer';
import Grid from '@material-ui/core/Grid';
import {Helmet} from 'react-helmet';
import Markdown from '../../components/Markdown';
import Page from '../../components/Page';
import PropTypes from 'prop-types';
import React from 'react';
import RightPane from './components/RightPane';
import StarRating from '../../components/widgets/StarRating';
import Typography from '@material-ui/core/Typography';
import config from 'config';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '1rem',
  },
  content: {
    '& h2': {
      paddingTop: '2rem',
    },
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
  video: {
    margin: '3rem 0',
  },
}));

const ReviewPage = ({content}) => {
  const classes = useStyles();
  if (!content) return null;
  return (
    <Page title={content.title}>
      <Helmet 
        link = {[{'rel': 'canonical', 'href': `${config.website.URL}${content.path}`}]}
      />
      <ContentContainer title={content.title}>
        <Grid container spacing={6}>
          <Grid item md={8}>
            <div className={classes.content}>
              <Markdown content={content.body1}/>
              <Typography variant="h2" color="primary">
                How many stars should I give?
              </Typography>
              <div className={classes.rating}>
                <StarRating rating={5} />
                &nbsp;<b>5 stars</b>&nbsp;means you loved it and wouldn’t change a thing.
              </div>
              <div className={classes.rating}>
                <StarRating rating={3} />
                &nbsp;<b>3 stars</b>&nbsp;means it was good, but you have a suggestion as to how it could improve.
              </div>
              <div className={classes.rating}>
                <StarRating rating={1} />
                &nbsp;<b>1 star</b>&nbsp;means you didn’t like it.
              </div>
              <Markdown content={content.body2}/>
              <div className={classes.video}>
                <Markdown content={content.body3}/>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <RightPane />
          </Grid>
        </Grid>
      </ContentContainer>
    </Page>
  );
};

ReviewPage.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body1: PropTypes.string.isRequired,
    body2: PropTypes.string.isRequired,
    body3: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })
};

export default ReviewPage;
