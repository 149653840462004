// This is a static placeholder
// TODO: make this an interactive carousel
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import testimonialOne from 'assets/testimonial-1.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '3rem 0 6rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none',
    zIndex: 0,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      padding: '1rem',
    },
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '1rem 3rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      marginBottom: '1rem',
    },
  },
  header: {
    padding: '2rem 8rem',
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0',
    },
  },
  item: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    margin: '0 1rem',
    width: '44rem',
    height: '100%',
    paddingTop: '3rem',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      marginBottom: '2rem',
    },
  },
  left: {
    width: '22rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0 1rem',
    },
  },
  right: {
    width: '22rem',
    padding: '4rem 2rem 0 2rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '2rem 1rem',
    },
  },
  visual: {
    height: '29rem',
  },
  quote: {
    width: '100%',
    textAlign: 'left',
  },
  signature: {
    color: theme.palette.common.battleshipGrey,
  },
}));


const Testimonials = () => {
  const classes = useStyles();

  const renderItem = () => (
    <div className={classes.item}>
      <div className={classes.left}>
        <img src={testimonialOne} alt="testimonial" className={classes.visual} />
      </div>
      <div className={classes.right}>
        <Typography variant="h3" color="primary" className={classes.quote} gutterBottom>
          &quot;I had gone through over 20 NDIS providers in the past trying to find availability and how best to use my package.
        </Typography>
        <Typography variant="h3" color="primary" className={classes.quote} gutterBottom>
          Clickability were able to not only find me available providers in my area, but also suggested other services I didn’t know I was eligible for.&quot;
        </Typography>
        <Typography variant="h3" className={classes.signature}>
          J.R. Smitherson
        </Typography>
      </div>
    </div>
  );

  return (
    <div className={classes.root} aria-hidden="true">
      <Container maxWidth="lg" fixed disableGutters={true}>
        <div className={classes.content}>
          <Typography variant="h1" color="primary" className={classes.header}>
            We&apos;ve helped over 130,000 participants, carers and providers navigate the NDIS.
          </Typography>
          {renderItem()}
        </div>
      </Container>
    </div>
  );
};

export default Testimonials;
