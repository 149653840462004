import { AuthAPI } from '../api';
import notify from '../lib/notify';
import * as jwt from '../lib/jwt';
import { sendSync } from '../store/authSync';

const LOGOUT         = 'auth/LOGOUT';
const LOGIN          = 'auth/LOGIN';
const ERROR          = 'auth/ERROR';
const REFRESH        = 'auth/REFRESH';
const PROFILE        = 'auth/PROFILE';
const SYNC           = 'auth/SYNC';
const SHOW_OVERLAY   = 'auth/SHOW_OVERLAY';

const initialState = {
  loadTime: undefined,
  isAuthenticated: false,
  tokenExpiresAt: undefined,
  userId: undefined,
  acceptedTsAndCs: false,
  profile: {},
  token: undefined,
  socialLogin: undefined,
  overlayOpen: false,
  isProvider: false,
};

// Action Creators

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function login(profile, token, tokenTtl) {
  return {
    type: LOGIN,
    token,
    profile,
    tokenExpiresAt: Date.now() + 1000 * tokenTtl,
    userId: profile.uuid,
  };
}

export function profile(profile) {
  return {
    type: PROFILE,
    profile,
  };
}

export function refresh(token, tokenTtl) {
  return {
    type: REFRESH,
    token,
    tokenExpiresAt: Date.now() + 1000 * tokenTtl,
  };
}

export function error(error) {
  return {
    type: ERROR,
    error,
  };
}

export function sync(state) {
  return {
    type: SYNC,
    state,
  };
}

export function showOverlay(overlayOpen) {
  return {
    type: SHOW_OVERLAY,
    overlayOpen,
  };
}

// Async Action Creators

export function logoutUser() {
  return (dispatch) => {
    notify.reset();
    AuthAPI.logout()
      .then(() => dispatch(logout()));
  };
}

export function refreshProfile() {
  return async (dispatch) => {
    try {
      const { payload } = await AuthAPI.refreshProfile();
      await dispatch(profile(payload.profile));
    } catch(err) {
      if (err.status === 401) dispatch(logout());
    }
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  let newState;
  switch (action.type) {

  case LOGIN:
    const isProvider = action.profile.is_provider ? true : false; // eslint-disable-line
    newState = {
      ...state,
      ...jwt.tokenData(action.token),  // adds socialLogin: bool
      isAuthenticated: true,
      token: action.token,
      tokenExpiresAt: action.tokenExpiresAt,
      profile: action.profile,
      acceptedTsAndCs: action.profile.ts_and_cs_version,
      userId: action.profile.uuid, // used to detect user-change on login
      loadTime: Date.now(),
      isProvider: isProvider,
    };
    sendSync(newState);
    return newState;

  case LOGOUT:
    newState = {
      ...state,
      socialLogin: undefined,
      isAuthenticated: false,
      isSuperUser: false,
      token: undefined,
      tokenExpiresAt: undefined,
      profile: undefined,
      isProvider: false,
      // leave user_id unchanged
    };
    sendSync(newState);
    return newState;

  case REFRESH:
    newState = {
      ...state,
      token: action.token,
      tokenExpiresAt: action.tokenExpiresAt
    };
    sendSync(newState);
    return newState;

  case PROFILE:
    return {
      ...state,
      profile: action.profile,
    };

  case SYNC:
    newState = {
      ...state,
      ...action.state,
    };
    AuthAPI.setBearerToken(newState.token);
    return newState;

  case SHOW_OVERLAY:
    return {
      ...state,
      overlayOpen: action.overlayOpen,
    };

  default:
    return state;
  }
}
