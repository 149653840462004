import * as track from 'lib/track';

import {CmsAPI, ProviderAPI} from 'api';
import React, { useEffect, useState } from 'react';

import { ADVERTISEMENT_FEATURE } from 'lib/advertisement';
import BlogRelatedList from 'modules/blog/components/BlogRelatedList';
import Button from 'components/Button';
import CardList from './components/CardList';
import ContentHero from './components/ContentHero';
import ExplanationText from './components/ExplanationText';
import {Helmet} from 'react-helmet';
import { HowWeHelp } from 'modules/home/components';
import Markdown from 'components/Markdown';
import Page from 'components/Page';
import PropTypes from 'prop-types';
import { SERVICES } from 'modules/home/components/ServicesCovered';
import StarRating from 'components/widgets';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import config from 'config';
import { getImageHeightAndSize } from 'lib/util';
import img from 'assets/physiotherapist-and-boy-holding-ball-455x256.png';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import whyClickabilityIcon1 from 'assets/why-clickability-1.png';
import whyClickabilityIcon2 from 'assets/why-clickability-2.png';
import whyClickabilityIcon3 from 'assets/why-clickability-3.png';

const imageWidth = 140;
const { imageHeight, imageSize } = getImageHeightAndSize(imageWidth);


const useStyles = makeStyles(theme => ({
  block2: {
    display: 'flex',
    justifyContent: 'center',
    padding: '4rem',
  },
  block2text: {
    maxWidth: '40rem',
  },
  block4: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4rem',
  },
  block4text: {
    maxWidth: '40rem',
  },
  block4button: {
    borderRadius: '0.75rem',
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.25)',
    padding: '1rem 1.8rem',
    fontSize: '1.6rem',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      padding: '1rem 1rem',
      width: '100%',
    },
  },
  howWeHelp: {
    backgroundColor: theme.palette.common.white,
    padding: 'unset',
    paddingBottom: '2rem',
  },
  // block 6 - related blog posts
  block6: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '4rem',
  },
  services: {
    padding: '2rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      padding: '50px 28px',
    },
  },
  servicesTitle: {
    textAlign: 'center',
  },
  servicesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '40px',
    marginTop: '3rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  service: {
    border: '1px solid',
    borderColor: theme.palette.common.lightBorder,
    boxShadow:  theme.shadow(20),
    borderRadius: '14px',
    padding: '20px',
    backgroundColor: theme.palette.common.white,
    width: '245px',
    height: '190px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    '&:hover': {
      border: '1px solid #264F73',
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      marginBottom: '2rem',
    },
    [theme.breakpoints.between('sm', 'md')]:{
      height: 'auto',
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: '80%',
      maxWidth: '800px',
    }
  },
  serviceSponsoredBorder: {
    borderColor: theme.palette.common.squash,
  },
  sponsored: {
    position: 'absolute',
    top: '-25px',
    right: 0,
    color: theme.palette.common.squash,
    fontSize: '12px',
    fontWeight: '400',
    textShadow: '1px 1px #e8e8e8',
    cursor: 'default',
    border: '1px solid',
    borderColor: theme.palette.common.squash,
    padding: '0 4px',
    borderRadius: '6px',
  },
  serviceDetail: {
    marginTop: '1rem',
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: '.5rem',
      marginLeft: '1.5rem',
    },
  },
  serviceImage: {
    width: imageWidth,
    height: imageHeight,
  },
  title: {
    textAlign: 'center',
    maxHeight: '2.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',

    [theme.breakpoints.between('sm', 'md')]: {
      textAlign: 'left',
    },
  },
  ratingContainer: {
    display: 'flex',
    columnGap: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.between('sm', 'md')]: {
      justifyContent: 'flex-start',
      marginTop: '.5rem',
    },
  },
  reviewCount: {
    fontSize: '12px',
    fontWeight: '500',
    marginTop: '3px',
  },
  rating: {
    fontSize: '12px',
    fontWeight: '500',
  },
  captionText: {
    margin: '1rem 2rem 0 0',
    textAlign: 'right',
  }
}));

const block5cards = [
  {
    'title': 'Use our expertise',
    'body': 'Our team has both lived and learned experience of disability, so we know firsthand how to navigate the NDIS. Finding the perfect provider is what we do.',
    'image': whyClickabilityIcon1,
  },
  {
    'title': 'Save time and energy',
    'body': 'We’ll do the background work, and find and introduce you to providers with capacity, so you can start quickly and get back to the important things.',
    'image': whyClickabilityIcon2,
  },
  {
    'title': 'Don’t pay us a thing',
    'body': 'If we’re helping you find a provider, rest assured: we won’t charge you. We’re passionate about ensuring you have free access to this service.',
    'image': whyClickabilityIcon3,
  },
];

const maxRelatedBlogPosts = 4;

const LandingPage = ({content}) => {
  const classes = useStyles();
  const history = useHistory();
  const [relatedPosts, setRelatedPosts] = useState();
  const [featuredServices, setFeaturedServices] = useState([]);
  const [currentServiceName, setCurrentServiceName] = useState();

  const onClick = () => {
    track.sendEvent('Connect me with providers button', 'click');
    history.push('/requestmatch');
  };

  const fetchFeatureServices = serviceName => {
    ProviderAPI.getModalityFeaturedServices(
      ADVERTISEMENT_FEATURE.MODALITY,
      serviceName
    ).then(response => setFeaturedServices(response.payload.services));
  };

  useEffect(() => {
    if (content?.tags) {
      CmsAPI.getBlogSearch('', content.tags, maxRelatedBlogPosts, 0)
        .then((response) => {
          setRelatedPosts(response.payload);
        });
    }
  }, [content?.tags]);

  useEffect(() => {
    if (content?.path) {
      const currentService = SERVICES.find(service => content?.path.includes(service.urlKey));
      if (currentService) {
        setCurrentServiceName(currentService.name);
        fetchFeatureServices(currentService.name);
        const interval = setInterval(() => fetchFeatureServices(currentService.name), 15000);
        return () => clearInterval(interval);
      }
    }
  }, [content?.path]);
  
  if (!content) return null;

  return (
    <Page title={content.title}>
      <Helmet 
        meta={[ {name: 'description', content: content.description} ]}
        link = {[{'rel': 'canonical', 'href': `${config.website.URL}${content.path}`}]}
      />

      {/* block 1 */}
      <ContentHero
        text={content.block1text}
        buttonText="Connect me with providers"
        image={content.block1image}
        onClick={onClick}
      />

      {/* block 2 */}
      <div className={classes.block2}>
        <div className={classes.block2text}>
          <Markdown content={content.block2text}/>
        </div>
      </div>

      {/* block featured services */}
      {featuredServices.length > 0 && (
        <div className={classes.services}>
          <Typography variant="h2" color="primary" className={classes.servicesTitle}>
            Check out these {currentServiceName} Services
          </Typography>
          <div className={classes.servicesContainer}>
            {featuredServices.map(featuredService => (
              <div
                key={featuredService.uuid}
                className={classNames(classes.service, {[classes.serviceSponsoredBorder]: featuredService.is_sponsored})}
                onClick={() => {
                  track.sendEvent(
                    track.MODALITY_LANDING_PAGE_SERVICE_OFFERING_CLICK,
                    track.EVENT_CLICK, {listing_uuid: featuredService.uuid});
                  history.push(featuredService.url);
                }}
              >
                {featuredService.is_sponsored && <div className={classes.sponsored}>Sponsored</div>}
                <img
                  src={featuredService.image_path ? (featuredService.image_path + imageSize) : img}
                  alt={featuredService.title}
                  className={classes.serviceImage}
                />
                <div className={classes.serviceDetail}>
                  <div className={classes.title}>{featuredService.title}</div>
                  <div className={classes.ratingContainer}>
                    <StarRating rating={featuredService.rating} size="small"/>
                    <div className={classes.reviewCount}>({featuredService.review_count})</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* block 3 */}
      <ExplanationText text={content.block3text} image={content.block3image} />
      
      {/* block 4 */}
      <div className={classes.block4}>
        <div className={classes.block4text}>
          <Markdown content={content.block4text}/>
        </div>
        <HowWeHelp classes={{root: classes.howWeHelp}} title={false}/>
        <Button
          className={classes.block4button}
          variant="outlined"
          color="primary"
          label="Connect me with providers"
          onClick={onClick}
        />
      </div>

      {/* block 5 */}
      <CardList title="How Clickability can help" data={block5cards} />

      {/* block 6 */}
      {relatedPosts && 
        <div className={classes.block6}>
          <BlogRelatedList related={relatedPosts} title="You might be interested in..."/>
        </div>
      }
    </Page>
  );
};

LandingPage.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    block1text: PropTypes.string,
    block1image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      title: PropTypes.string,
    }),
    block2text: PropTypes.string,
    block3text: PropTypes.string,
    block3image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      title: PropTypes.string,
    }),
    block4text: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    path: PropTypes.string,
  })
};

export default LandingPage;
