import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  alert: {
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
  },
}));

const AlertList = ({alerts, onClose}) => {
  const classes = useStyles();
  return alerts && alerts.map((alert, i) => (
    <Alert
      className={classes.alert}
      severity={alert.severity}
      key={i}
      onClose={() => onClose(alert)}
    >
      {alert.text}
    </Alert>
  ));
};

AlertList.propTypes = {
  alerts: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AlertList;
