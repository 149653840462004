import CardQuestion from './CardQuestion';
import FlowContainer from './FlowContainer';
import QuestionContainer from './QuestionContainer';
import RatingQuestion from './RatingQuestion';
import TextQuestion from './TextQuestion';
import SubmitPage from './SubmitPage';
import * as C from './constants';

// Unselect 'chosen providers' which are not selected in 'contacted providers'
const handleSetter = (answers, setAnswers) => {
  const contactedProviders = answers[C.FIELD_CONTACTED_PROVIDERS]?.selection;
  const chosenProvider = answers[C.FIELD_CHOSEN_PROVIDERS]?.selection;

  if (!contactedProviders || !chosenProvider) return;

  const contactedProvidersSet = new Set([...contactedProviders]);
  const chosenProviderSet = new Set([...chosenProvider]);


  [...chosenProvider].forEach(item => {
    if (!contactedProvidersSet.has(item))
      chosenProviderSet.delete(item);
  });

  setAnswers(prevState => {
    const _answers = {...prevState};
    _answers[C.FIELD_CHOSEN_PROVIDERS].selection = [...chosenProviderSet];

    return _answers;
  });
};


const flow = {
  Container: FlowContainer,
  QuestionContainer: QuestionContainer,
  pages: [
    {
      name: 'contacted-providers',
      title: 'Which providers contacted you?',
      progress: true,
      questions: [
        {
          field: C.FIELD_CONTACTED_PROVIDERS,
          Component: CardQuestion,
          providerDataFunc: answers => answers?._providerData,
          otherData: [
            C.STATIC_CARD_NONE,
          ],
          isValid: answers => !!answers?.selection?.length,
          disableRate: true,
          setterCb: handleSetter,
        }
      ],
    },
    {
      name: 'chosen-providers',
      title: 'Which providers did you choose?',
      progress: true,
      isHidden: answers => answers[C.FIELD_CONTACTED_PROVIDERS]?.selection?.includes(C.STATIC_CARD_NONE.key),
      questions: [
        {
          field: C.FIELD_CHOSEN_PROVIDERS,
          Component: CardQuestion,
          otherData: [
            C.STATIC_CARD_STILL_DECIDING,
            C.STATIC_CARD_NONE,
          ],
          providerDataFunc: answers => {
            const selection = new Set(answers?.contactedProviders.selection);
            return answers?._providerData?.filter(item => selection.has(item.uuid));
          },
          disableRate: true,
          isValid: answers => !!answers?.selection?.length,
        },
      ],
    },
    {
      name: 'overall-experience',
      title: 'How would you rate your experience with Clickability?',
      progress: true,
      submitButton: true,
      questions: [
        {
          field: 'rating',
          Component: RatingQuestion,
          isValid: answers => !!answers,
        },
        {
          field: 'comment',
          text: 'Additional Comments (Optional)',
          Component: TextQuestion,
          isValid: () => true,
        },
      ],
    },
    {
      name: 'submit',
      progress: false,
      questions: [
        {
          field: '_submit',
          Component: SubmitPage,
          isValid: answer => !!answer,
        },
      ],
    },
  ]
};

export default flow;
