import BillingIcon from '@material-ui/icons/CreditCard';
import ChatIcon from '@material-ui/icons/Chat';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import OverdueIcon from '@material-ui/icons/Warning';
import ProviderIcon from '@material-ui/icons/Business';
import React from 'react';
import ReportIcon from '@material-ui/icons/InsertChart';
import UsersIcon from '@material-ui/icons/PeopleAlt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    padding: '1rem 0',
    width: '100%',
    height: '100%',
  },
});

const Menu = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <List>
        <ListItem button component={Link} to='/siteadmin/provider'>
          <ListItemIcon>
            <ProviderIcon />
          </ListItemIcon>
          <ListItemText primary="Providers" />
        </ListItem>
        <ListItem button component={Link} to='/siteadmin/user'>
          <ListItemIcon>
            <UsersIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem button component={Link} to='/siteadmin/reviewmoderation'>
          <ListItemIcon>
            <ChatIcon />
          </ListItemIcon>
          <ListItemText primary="Review Moderation" />
        </ListItem>
        <ListItem button component={Link} to='/siteadmin/reports'>
          <ListItemIcon>
            <ReportIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem>
        <ListItem button component={Link} to='/siteadmin/billing'>
          <ListItemIcon>
            <BillingIcon />
          </ListItemIcon>
          <ListItemText primary="Finance Reports" />
        </ListItem>
        <ListItem button component={Link} to='/siteadmin/billing/overdue'>
          <ListItemIcon>
            <OverdueIcon />
          </ListItemIcon>
          <ListItemText primary="Overdue Payments" />
        </ListItem>
        <ListItem button component={Link} to='/siteadmin/billing/operations'>
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Billing Operations" />
        </ListItem>
        <ListItem button component={Link} to='/siteadmin/logs'>
          <ListItemIcon>
            <DeveloperModeIcon />
          </ListItemIcon>
          <ListItemText primary="Logs" />
        </ListItem>
      </List>
    </div>
  );
};

export default Menu;
