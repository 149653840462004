import React, { useState } from 'react';

import { Switch } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import FreeTier from 'components/Subscription/components/FreeTier';
import TIERS from 'components/Subscription/tiers';
import Tier from 'components/Subscription/components/Tier';
import style from 'style';
import { BILLED_TYPE_ANNUALLY, BILLED_TYPE_QUARTERLY } from 'lib/constants';


const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const InnerContainer = styled.div`
  max-width: 1050px;
  margin-top: 20px;
  margin-bottom: 100px;
`;

const SwitchContainer = styled.div`
  display: flex;
  width: 100%;
  padding-right: 5px;
  justify-content: end;
  margin-bottom: 10px;
  .switch {
    width: 100px;
    font-weight: 700;
    .ant-switch-inner {
      font-size: 11px;
    }
  }
  .bottom-text {
    font-size: 11px;
    text-align: center;
    margin-top: 5px;
    color: ${style.colors.palette.textGrey};
  }
  .top-text {
    font-size: 11px;
    text-align: center;
    margin-bottom: 5px;
    font-weight: 500;
    color: ${style.colors.palette.textGrey};
  }
  
  @media (max-width: ${style.sizes.small}) {
    justify-content: center;
  }
`;

const TierContainer = styled.div`
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media (max-width: ${style.sizes.medium}) {
    flex-direction: column;
    row-gap: 20px;
  }
`;

const Pricing = () => {
  const history = useHistory();
  const [billedType, setBilledType] = useState(BILLED_TYPE_ANNUALLY);

  const handleClick = () => history.push('business/signup', { goal: 'growth' });

  return (
    <Container>
      <InnerContainer>
        <SwitchContainer>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <div className="top-text" style={{opacity: billedType !== BILLED_TYPE_ANNUALLY && 0}}>
              (Save 37%) 🔥
            </div>
            <Switch
              className="switch"
              checkedChildren="Annually"
              unCheckedChildren="Quarterly"
              checked={billedType === BILLED_TYPE_ANNUALLY}
              onChange={checked => setBilledType(checked ? BILLED_TYPE_ANNUALLY : BILLED_TYPE_QUARTERLY)}
            />
            <div className="bottom-text">
              Swap to {billedType === BILLED_TYPE_ANNUALLY ? 'Quarterly' : 'Annual'} pricing
            </div>
          </div>
        </SwitchContainer>
        <TierContainer>
          {TIERS.map(item => (
            <Tier
              key={item.name}
              name={item.name}
              subTitle={item.subTitle}
              description={item.description}
              price={item.price[billedType]}
              billedPrice={item.billedPrice[billedType]}
              billedType={item.billedType[billedType]}
              icon={item.icon}
              alternate={item.alternate}
              benefits={item.benefits}
              onClick={handleClick}
            />
          ))}
        </TierContainer>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px'}}>
          <FreeTier />
        </div>
      </InnerContainer>
    </Container>
  );
};


export default Pricing;
