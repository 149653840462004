import { makeStyles, withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import RoomIcon from '@material-ui/icons/Room';
import Typography from '@material-ui/core/Typography';

const StyledRoomIconIcon = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
  },
}))(RoomIcon);

const useStyles = makeStyles({
  root: {
    paddingTop: '1rem',
  },  
});

const LocationList = ({isLoading, premises, onClick}) => {
  const classes = useStyles();

  if (isLoading || premises === undefined) {
    return null;
  }

  if (premises.length === 0) {
    return (<Typography variant="body2">No locations listed.</Typography>); 
  }

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="secondary mailbox folders">
        {premises.map((premise, i) => 
          <ListItem button key={i} onClick={onClick(i)}>
            <ListItemIcon>
              <StyledRoomIconIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="subtitle2" color="primary">{premise.name === null ? `Location ${i+1}` : premise.name}</Typography>
              <Typography variant="body2">{premise.formatted_address}</Typography> 
              <Typography variant="body2">{premise.phone}</Typography> 
              <Typography variant="body2">{premise.email}</Typography> 
            </ListItemText>
          </ListItem>
        )}
      </List>
    </div>
  );
};

LocationList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  premises: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LocationList;
