// show only one of the components, but keep all of them mounted.

import React from 'react';
import PropTypes from 'prop-types';

const Switch = ({children, value}) => {
  return (
    <React.Fragment>
      {children.map(child => (
        <div style={{display: value === child.key ? 'block': 'none'}} key={child.key}>
          {child}
        </div>
      ))}
    </React.Fragment>
  );
};

Switch.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

export default Switch;
