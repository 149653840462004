import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { timeSinceText } from '../../lib/time.js';
import RichTextField from 'components/input/RichTextField.js';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    cursor: 'pointer',
    boxShadow: 'none',
    borderRadius: '3px',
    background: theme.palette.common.paleGrey,
    padding: '1rem',
  },
  widgets: {
    display: 'flex',
    flexWrap: 'no-wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.5rem 0',
  },
  widgetText: {
    paddingTop: '0.5rem',
  },
  richTextField: {
    marginBottom: 0
  }
}));

const ReviewResponseCard = ({data, providerName}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="subtitle2" color="primary">Response from {providerName}</Typography>
        <Typography variant="subtitle2" color="primary" className={classes.widgetText}>{timeSinceText(data.created_at)}</Typography>
        <RichTextField className={classes.richTextField} inputValue={data.content}/>
      </CardContent>
    </Card>
  );
};

ReviewResponseCard.propTypes = {
  data: PropTypes.object.isRequired,
  providerName: PropTypes.string.isRequired,
};

export default ReviewResponseCard;
