import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { ProviderAPI } from '../../api';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: '4px',
    '& .MuiAutocomplete-root': {
      width: '100%',
    },
  },
  input: {
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
  },
});

// lastQuery global is used to ensure we only update the options in response to the
// most recent request made.
let lastQuery = undefined;

const LocationInput = ({value: initialValue, onEnter, classes: overrides, ...props}) => {
  const classes = useStyles({classes:{...overrides}});
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(initialValue || '');
  const [options, setOptions] = React.useState([]);
  const [focus, setFocus] = React.useState(false);
  const open = focus && (options.length > 1 || (options.length === 1 && value !== inputValue));

  React.useEffect(() => {
    if (initialValue === null) {
      setValue('');
      setInputValue('');
    }
  }, [initialValue]);

  React.useEffect(() => {
    lastQuery = inputValue;
    if (inputValue.length < 2) return;
    ProviderAPI.locationAutocomplete(inputValue).then(r => {
      // avoid updating options if inputValue has since changed
      if (inputValue === lastQuery) setOptions(r.payload);
    });
  }, [inputValue]);

  const renderInput = (params) => {
    return (
      <TextField
        className={classes.input}
        placeholder="Postcode or Suburb"
        label="Postcode or Suburb"
        variant="outlined"
        {...params}
        {...props}
      />
    );
  };

  return (
    <Autocomplete
      options={options}
      open={open}
      autoComplete
      autoHighlight
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        setValue(value);
        onEnter(value);
      }}
      popupIcon={null}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      value={value}
      renderInput={renderInput}
    />
  );
};

LocationInput.propTypes = {
  value: PropTypes.string,
  onEnter: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

export default LocationInput;
