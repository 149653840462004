import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import {snakeCase} from 'snake-case';
import Skeleton from '@material-ui/lab/Skeleton';
import {makeStyles} from '@material-ui/core/styles';

import Card from './Card';
import Data from './Data';
import QualifiedServiceIcon from 'components/QualifiedServiceIcon';


const GRID_COLUMNS = 5;
// This is an approximate max character count before we push an element to take
// more spans in the grid. Adjust according to the needs.
const MAX_CHAR_IN_A_SPAN = 10;

const useStyles = makeStyles(theme => ({
  answerContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(${GRID_COLUMNS}, 1fr)`,
    gridRowGap: '20px',
  },
  divider: {
    borderBottom: '1px solid #f5f5f5',
    gridColumn: '1 / -1',
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: '600',
    display: 'flex',
    columnGap: '20px',
    paddingBottom: '20px',
    marginBottom: '20px',
    borderBottom: '1px solid #f5f5f5',
  },
  headerTitle: {
    marginTop: '-3px',
  },
  noData: {
    gridColumn: '1 / -1',
  },
  skeleton: {
    gridColumn: '1 / -1',
  },
}));

const Service = ({data, isLoading}) => {

  const classes = useStyles();

  const _data = data.data && Object.keys(data.data).map((key) => {
    const detailsData = data.data[key];
    const details = Array.isArray(detailsData) ? detailsData.join(', ') : detailsData;
    return {title: _.capitalize(snakeCase(key).replaceAll('_', ' ')), details: details};
  });

  const sortedData = _data?.sort(
    (a,b) => (a.details.length - b.details.length)
  );

  const dataElements = sortedData && sortedData.reduce((acc, item, index) => {
    const _span = Math.ceil(Math.max(item.details.length, item.title.length) / MAX_CHAR_IN_A_SPAN);
    const span = _span > GRID_COLUMNS ? GRID_COLUMNS : _span;
    acc.dividerCounter += span;

    if (acc.dividerCounter > GRID_COLUMNS) {
      acc.dataArray.push(<div key={`divider-${index}`} className={classes.divider} />);
      acc.dividerCounter = acc.dividerCounter - GRID_COLUMNS;
    }

    acc.dataArray.push(
      <Data
        key={index}
        title={item.title}
        details={item.details}
        gridColumn={`span ${span}`}
        autoSpan={false}
      />);

    return acc;
  }, {dividerCounter: 0, dataArray: []}).dataArray;

  const renderBody = () => {
    return (
      <>
        <div className={classes.header}>
          <QualifiedServiceIcon.Regular serviceName={data.serviceName} />
          <div className={classes.headerTitle}>{data.serviceName}</div>
        </div>
        <div className={classes.answerContainer}>
          {(data.data && Object.keys(data.data).length)  ?
            dataElements :
            <div className={classes.noData}>No additional information given</div>
          }
        </div>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Skeleton animation="wave" height={60} width={200} />
        <Skeleton animation="wave" height={110} className={classes.skeleton} />
        <Skeleton animation="wave" height={110} className={classes.skeleton} />
      </>
    );
  };

  return (
    <Card title="Requested service">
      {isLoading ? renderLoading() : renderBody()}
    </Card>
  );
};

Service.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};


export default Service;
