import config from 'config';

// NDIS Plans
export const PLAN_TYPE_SELF_MANAGED = 'Self managed';
export const PLAN_TYPE_AGENCY_MANAGED = 'Agency managed';
export const PLAN_TYPE_PLAN_MANAGED = 'Plan managed';
export const PLAN_TYPE_WAITING_FOR_APPROVAL = 'Waiting for approval';
export const PLAN_TYPE_NOT_WITH_NDIS = 'Not with NDIS';
export const PLAN_TYPE_UNSURE = 'Unsure';

// NDIS Plan Periods
export const PLAN_PERIOD_ONE_YEAR = 'One year';
export const PLAN_PERIOD_TWO_YEARS = 'Two years';
export const PLAN_PERIOD_THREE_YEARS = 'Three years';

// Service For Types
export const SERVICE_FOR_TYPE_MYSELF = 'Myself';
export const SERVICE_FOR_TYPE_SOMEONE_I_CARE_FOR = 'Someone I care for';
export const SERVICE_FOR_TYPE_MY_CLIENT = 'My Client';

// Page Names
// Question Flow
export const PAGE_SERVICE = 'service';
export const PAGE_SERVICE_FOR = 'service-for';
export const PAGE_AGE_RANGE = 'age-range';
export const PAGE_FINAL_DETAILS = 'final-details';
export const PAGE_NDIS_PLAN = 'ndis-plan';
export const PAGE_LOCATION = 'location';
export const PAGE_TELEHEALTH = 'telehealth';
export const PAGE_ONLINE_SERVICE = 'online-service';
export const PAGE_RESULT = 'result';
export const PAGE_WHAT_HAPPENS_NEXT = 'what-happens-next';
export const PAGE_ACCOUNT = 'account';
export const PAGE_SUMMARY = 'summary';
export const PAGE_SUCCESS = 'success';
export const PAGE_SUCCESS_DIRECT_REQUEST = 'success-direct-request';
export const PAGE_EXIT = 'exit';
export const PAGE_VERIFICATION = 'verification';

// Dashboard Flow
export const PAGE_CONTACT_INFO = 'contact';
export const PAGE_COMMUNICATION = 'communication';
export const PAGE_NDIS_INFO = 'ndis';
export const PAGE_DISABILITY_INFO = 'disability';
export const PAGE_PARTICIPANT_DETAILS = 'participant-details';

// Service Categories
export const SERVICE_CATEGORY_ALLIED_HEALTH = 'Allied Health';
export const SERVICE_CATEGORY_SUPPORT_COORDINATION = 'Support Coordination';
export const SERVICE_CATEGORY_SUPPORT_WORK = 'Support Work';
export const SERVICE_CATEGORY_PLAN_MANAGEMENT = 'Plan Management';
export const SERVICE_CATEGORY_CLEANING = 'Cleaning';
export const SERVICE_CATEGORY_GARDENING = 'Gardening';
export const SERVICE_CATEGORY_GENERAL = 'General';

// Age Range Options
export const AGE_RANGE_EARLY_CHILDHOOD = 'Early Childhood (0-7 years)';
export const AGE_RANGE_CHILDREN = 'Children (8-16 years)';
export const AGE_RANGE_YOUNG_PEOPLE = 'Young people (17-21 years)';
export const AGE_RANGE_ADULTS = 'Adults (22-59 years)';
export const AGE_RANGE_MATURE_AGE = 'Mature Age (60+ years)';

// Global Fields
export const SERVICES_NEEDED_FIELD = 'servicesNeeded';
export const SERVICES_FOR_FIELD = 'servicesFor';
export const NDIS_PLAN_FIELD = 'ndisPlan';
export const LOCATION_FIELD = 'location';
export const FINAL_DETAILS_FIELD = 'finalDetails';
export const FOR_FIELD = 'for';

export const FIRST_NAME_FIELD = 'firstName';
export const LAST_NAME_FIELD = 'lastName';
export const PHONE_FIELD = 'phone';
export const EMAIL_FIELD = 'email';
export const RELATIONSHIP_FIELD = 'relationship';
export const CONTACT_METHOD_FIELD = 'contactMethod';

export const PARTICIPANT_FIRST_NAME_FIELD = 'participantFirstName';
export const PARTICIPANT_LAST_NAME_FIELD = 'participantLastName';
export const PARTICIPANT_PHONE_FIELD = 'participantPhone';
export const PARTICIPANT_EMAIL_FIELD = 'participantEmail';
export const PARTICIPANT_GENDER_FIELD = 'participantGender';
export const PARTICIPANT_AGE_RANGE_FIELD = 'participantAgeRange';

export const LANGUAGE_FIELD = 'language';
export const INTERPRETER_REQUIRED_FIELD = 'interpreterRequired';
export const COMMUNICATION_AIDS_FIELD = 'communicationAids';
export const PLAN_START_DATE_FIELD = 'planStartDate';
export const NDIS_PLAN_PERIOD_FIELD = 'ndisPlanPeriod';

export const EMAIL_OPT_OUT_FIELD = 'emailOptOut';
export const TERMS_AND_CONDITION_FIELD = 'termsAndCondition';
export const PARTICIPANT_SMS_OPT_IN_FIELD = 'participantSmsOptIn';
export const PREFERRED_CONTACT_METHOD_PHONE_CALL = 'Phone Call';
export const PREFERRED_CONTACT_METHOD_EMAIL = 'Email';

// Private Fields
export const RECAPTCHA_CODE_FIELD = '_recaptchaCode';
export const DIRECT_CONNECT_FIELD = '_directConnect';
export const CONNECT_FIELD = '_connect';
export const PROVIDER_COUNT_FIELD = '_providerCount';
export const EXTRA_PROVIDER_COUNT_FIELD = '_extraProviderCount';
export const PROVIDER_SEARCH_NAMES_FIELD = '_providerSearchNames';
export const PROVIDER_SEARCH_NAME_LOADING_FIELD = '_isProviderSearchNameLoading';
export const AGE_GROUPS_FIELD = '_ageGroups';

export const TELEHEALTH_FIELD = 'telehealth';
export const ONLINE_SERVICE_FIELD = 'onlineService';

export const ANSWER_YES = 'Yes';
export const ANSWER_NO = 'No';
export const ANSWER_UNSURE = 'Unsure';

// Telehealth Options
export const TELEHEALTH_YES = ANSWER_YES;
export const TELEHEALTH_NO = ANSWER_NO;
export const TELEHEALTH_UNSURE = ANSWER_UNSURE;

// Online Service Options
export const ONLINE_SERVICE_YES = ANSWER_YES;
export const ONLINE_SERVICE_NO = ANSWER_NO;
export const ONLINE_SERVICE_UNSURE = ANSWER_UNSURE;

// Generic Dropdown Options
export const DROPDOWN_OPTION_DAILY = 'Daily';
export const DROPDOWN_OPTION_WEEKLY = 'Weekly';
export const DROPDOWN_OPTION_FORTNIGHTLY = 'Fortnightly';
export const DROPDOWN_OPTION_ONE_OFF = 'One-off';
export const DROPDOWN_OPTION_ONE_OFF_ASSESSMENT = 'One-off Assessment';

// Relationships
export const RELATION_PARENT = 'Parent';
export const RELATION_CHILD = 'Child';
export const RELATION_PARTNER = 'Partner';
export const RELATION_RELATIVE = 'Relative';
export const RELATION_SUPPORT_COORDINATOR = 'Support Coordinator';
export const RELATION_OTHER = 'Other';

export const SERVICE_REQUEST_ANSWERS_FIELD = 'ServiceRequestAnswers';

export const ACCESS_METHOD_TELEHEALTH = 'Telehealth';
export const ACCESS_METHOD_ONLINE_SERVICE = 'Online service';

// Style Constants
export const QUESTION_BORDER_COLOR = '#e8e8e8';
export const ERROR_CONTAINER_HEIGHT = '24px';

// URLs
export const TELEHEALTH_BLOG_URL = `${config.website.URL}/blog/telehealth-what-is-it-and-how-does-it-work/`;
export const NDIS_BLOG_URL = `${config.website.URL}/blog/managing-ndis-funds-agency-plan-and-self-management/`;
export const TNC_URL = `${config.website.URL}/terms/`;