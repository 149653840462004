import React from 'react';
import PropTypes from 'prop-types';

import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Card from './Card';
import Data from './Data';


const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridRowGap: '20px',
  },
  row: {
    display: 'flex',
  },
  divider: {
    borderBottom: '1px solid #f5f5f5',
    gridColumn: '1 / -1',
  },
  skeleton: {
    gridColumn: '1 / -1',
  },
});

const ParticipantInfo = ({data, isLoading}) => {

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const serviceInquiry = data.serviceInquiry ? data.serviceInquiry : {};
  const serviceInquiryData = serviceInquiry.data ? serviceInquiry.data : {};

  const renderBody = () => {
    return (
      <>
        <Data
          title="First name"
          details={serviceInquiry.participantFirstName}
          gridColumn={isMobile ? 'span 2' : 'span 1'}
        />
        <Data
          title="Surname"
          details={serviceInquiry.participantLastName}
          gridColumn={isMobile ? 'span 2' : 'span 1'}
          isCeiling={!!isMobile}
        />
        <Data
          title="Gender"
          details={serviceInquiry.participantGender}
        />
        <Data
          title="Age Range"
          details={data.serviceInquiry.participantAgeRange}
          gridColumn={isMobile ? 'span 2' : 'span 1'}
        />

        <div className={classes.divider} />

        <Data
          title="City"
          details={serviceInquiry.suburb}
          maxCharInSpan={isMobile ? 8 : 12}
          gridColumn="span 2"
          isCeiling={!!isMobile}
        />
        <Data
          title="State"
          details={serviceInquiry.state}
        />
        <Data
          title="Postcode"
          details={serviceInquiry.postCode}
          maxCharInSpan={isMobile ? 7 : 10}
          isCeiling={!!isMobile}
        />

        <div className={classes.divider} />

        {/*<Data*/}
        {/*  title="Language"*/}
        {/*  details={serviceInquiryData.language}*/}
        {/*  maxCharInSpan={isMobile ? 7 : 10}*/}
        {/*  isCeiling={!!isMobile}*/}
        {/*/>*/}
        {/*<Data*/}
        {/*  title="Interpreter required"*/}
        {/*  details={serviceInquiryData.interpreterRequired}*/}
        {/*  gridColumn="span 2"*/}
        {/*/>*/}
        <Data
          title="Telehealth"
          details={serviceInquiryData.telehealth}
          gridColumn="span 2"
        />
        <Data
          title="Online Service"
          details={serviceInquiryData.onlineService}
          gridColumn="span 2"
        />
        {/*<Data*/}
        {/*  title="Communication aids"*/}
        {/*  details={serviceInquiryData.communicationAids}*/}
        {/*  gridColumn="span 2"*/}
        {/*/>*/}

        {/*<div className={classes.divider} />*/}

        {/*<Data*/}
        {/*  title="Formal diagnosis"*/}
        {/*  details={serviceInquiryData.formalDiagnosis}*/}
        {/*  gridColumn="span 2"*/}
        {/*/>*/}
        {/*<Data*/}
        {/*  title="Impact of the disability"*/}
        {/*  details={serviceInquiryData.impactOfTheDisability}*/}
        {/*  gridColumn="span 2"*/}
        {/*/>*/}
        {/*<Data*/}
        {/*  title="Behaviour support plan"*/}
        {/*  details={serviceInquiryData.behaviourSupportPlan}*/}
        {/*  gridColumn="span 2"*/}
        {/*/>*/}
        {/*<Data*/}
        {/*  title="Behaviour support plan additional supports"*/}
        {/*  details={serviceInquiryData.behaviourSupportsNeeded}*/}
        {/*  gridColumn="span 2"*/}
        {/*/>*/}
      </>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Skeleton animation="wave" height={70} className={classes.skeleton} />
        <Skeleton animation="wave" height={70} className={classes.skeleton} />
        <Skeleton animation="wave" height={70} className={classes.skeleton} />
      </>
    );
  };

  return (
    <Card title="Participant Information" className={classes.root}>
      {isLoading ? renderLoading() : renderBody()}
    </Card>
  );
};

ParticipantInfo.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};


export default ParticipantInfo;
