import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputContainer from './InputContainer';
import { formatComponentId } from 'lib/util.js';


const optionsToMap = options => Array.isArray(options) ? options.reduce((acc, key) => {acc[key]=key; return acc;},{}) : options;

// eslint-disable-next-line no-unused-vars
const RadioInput = ({ label, options, value='', errorText, helperText, onChange, disabled=false, ...inputProps }) => {
  options = optionsToMap(options);  // allow array or object to be passed.
  const componentId = formatComponentId('radio-input');
  return (
    <InputContainer
      label={label}
      componentId={componentId}
      validationActive={false}
      helperText={helperText}
    >
      <RadioGroup row aria-label={label} value={value} disabled={disabled} onChange={e => onChange(e.target.value)} {...inputProps}>
        {Object.entries(options).map(([key, optionLabel]) =>
          <FormControlLabel
            key={key}
            value={key}
            control={<Radio size="small" />}
            label={optionLabel}
          />
        )}
      </RadioGroup>
    </InputContainer>
  );
};

RadioInput.propTypes = {
  label: PropTypes.node.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  value: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
};

export default RadioInput;
