import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Card as AntdCard } from 'antd';

import style from 'style';


const Card = styled(AntdCard)`
  width: fit-content;
  margin-left: auto;
  margin-top: 15px;
  padding: 5px 20px;
  
  .ant-card-body {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    height: 30px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ disabled }) => disabled ? style.colors.palette.silver : 'default'};
  cursor: ${({ disabled }) => !disabled ? 'pointer' : 'not-allowed'};
  &:hover: {
    color: ${({ disabled }) => !disabled ? style.colors.palette.tealish : 'default'};
  }
`;

const PaginationController = ({data, onClick}) => {
  const _startingNumber = ((data.pageSize * data.page) - data.pageSize) + 1;
  const startingNumber = data.count && _startingNumber;
  const toNumber = data.count < (data.pageSize * data.page) ? data.count : data.pageSize * data.page;

  return (
    <Card>
      {!isNaN(toNumber) && (
        <div>
          {startingNumber} - {toNumber} of {data.count}
        </div>
      )}
      <IconContainer>
        <Icon
          icon={faChevronLeft}
          disabled={!data.hasPrevPage}
          onClick={() => data.hasPrevPage && onClick(data.page - 1)}
        />
        <Icon
          icon={faChevronRight}
          disabled={!data.hasNextPage}
          onClick={() => data.hasNextPage && onClick(data.page + 1)}
        />
      </IconContainer>
    </Card>
  );
};

PaginationController.propTypes = {
  data: PropTypes.shape({
    count: PropTypes.number,
    pageSize: PropTypes.number,
    page: PropTypes.number,
    numPages: PropTypes.number,
    hasNextPage: PropTypes.bool,
    hasPrevPage: PropTypes.bool
  }),
  onClick: PropTypes.func,
};

export default PaginationController;