import * as track from '../../../lib/track';

import { useHistory, useLocation } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import EndorseService from '../../../components/dialogues/EndorseService';
import EndorsementIcon from 'icons/EndorsementIcon';
import PropTypes from 'prop-types';
import RatingIcon from 'icons/RatingIcon';
import React from 'react';
import WriteReview from '../../../components/dialogues/WriteReview';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    padding: '1rem 0',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  button: {
    width: '48%',
    textTransform: 'none',
    fontSize: '0.9rem',
    padding: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem',
    },
  },
  icon: {
    marginRight: '0.5rem',
  },
}));

const ReviewButtons = ({ data }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [writeReviewOpen, setWriteReviewOpen] = React.useState(location.hash === '#review' ? true : false);
  const [endorseServiceOpen, setEndorseServiceOpen] = React.useState(location.hash === '#endorsement' ? true : false);
  const eventArgs = (data.scope === 'provider') ? null : { listing_uuid: data.uuid };

  const clickWriteReview = () => {
    setEndorseServiceOpen(false);
    track.sendEvent(`write ${data.scope} review button`, 'click', eventArgs, [data.provider_uuid]);
    setWriteReviewOpen(true);
    history.push(location.pathname + '#review');
  };

  const clickEndorseService = () => {
    setWriteReviewOpen(false);
    track.sendEvent(`endorse ${data.scope} button`, 'click', eventArgs, [data.provider_uuid]);
    setEndorseServiceOpen(true);
    history.push(location.pathname + '#endorsement');
  };

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        color="primary"
        aria-label="Write a review button"
        onClick={clickWriteReview}
        className={classes.button}
        disableRipple
      >
        <RatingIcon className={classes.icon}/>
        Write a review
      </Button>
      <WriteReview
        open={writeReviewOpen}
        data={data}
        onClose={()=>{
          setWriteReviewOpen(false);
          history.push(location.pathname);
        }}
        onEndorse={clickEndorseService}
      />
      <Button
        variant="outlined"
        color="primary"
        aria-label="Endorse service button"
        onClick={clickEndorseService}
        className={classes.button}
        disableRipple
      >
        <EndorsementIcon className={classes.icon}/>
        Endorse this service
      </Button>
      <EndorseService
        open={endorseServiceOpen}
        data={data}
        onClose={()=>{
          setEndorseServiceOpen(false);
          history.push(location.pathname);
        }}
        onReview={clickWriteReview}
      />
    </div>
  );
};

ReviewButtons.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReviewButtons;
