import {faFaceThinking, faFrown} from '@fortawesome/pro-solid-svg-icons';

export const FIELD_CONTACTED_PROVIDERS = 'contactedProviders';
export const FIELD_CHOSEN_PROVIDERS = 'chosenProviders';

export const STATIC_CARD_NONE = {
  title: 'None',
  key: 'none',
  icon: faFrown
};
export const STATIC_CARD_STILL_DECIDING = {
  title: 'Still Deciding',
  key: 'still_deciding',
  icon: faFaceThinking
};
