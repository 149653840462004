import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FacebookIcon from '@material-ui/icons/Facebook';
import { facebookLoginUrl } from '../../lib/cognito';

const FBButton = withStyles(theme => ({
  root: {
    width: '100%',
    padding: '0.5rem',
    fontSize: '1.2rem',
    textTransform: 'none',
    color: '#ffffff',
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'none',
    backgroundColor: '#1877f2',
    marginBottom: '1.5rem',
    '&:hover': {
      backgroundColor: '#1877f2',
    },
  },
}))(Button);

const FacebookLoginButton = ({ children, state={} }) => {
  const url = facebookLoginUrl(state);
  return (
    <FBButton variant="contained" color="primary"
      href={url}
      startIcon={<FacebookIcon style={{ fontSize: 24 }}/>}>
      {children}
    </FBButton>
  );
};

FacebookLoginButton.propTypes = {
  children: PropTypes.node.isRequired,
  state: PropTypes.object,
};

export default FacebookLoginButton;
