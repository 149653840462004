import React, { useState, useEffect } from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AuthField from './AuthField';
import AuthForm from './AuthForm';
import AuthFormGroup from './AuthFormGroup';
import * as Validate from 'components/Validate';
import LocationInput from 'components/input/LocationInput';
import Markdown from 'components/Markdown';



import { useContent } from 'components/ContentProvider';

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: theme.palette.common.paleGrey,
  },
}));

const ProviderSignupForm = (
  {
    onComplete,
    loginLink,
    busy,
    signupError,
    provider,
    title='Create your Clickability Provider Account',
    buttonLabel='Sign Up',
    isConnect = false
  }) => {
  const content = useContent('/providers/signup') || {};
  const pageLocation = useLocation();

  const [email, setEmail] = useState('');
  const [emailWarning, setEmailWarning] = useState();
  const [username, setUsername] = useState('');
  const [usernameWarning, setUsernameWarning] = useState();
  const [firstName, setFirstName] = useState('');
  const [firstNameWarning, setFirstNameWarning] = useState();
  const [lastName, setLastName] = useState('');
  const [lastNameWarning, setLastNameWarning] = useState();
  const [contactPhone, setContactPhone] = useState('');
  const [contactPhoneWarning, setContactPhoneWarning] = useState();
  const [location, setLocation] = useState('');
  const [locationWarning, setLocationWarning] = useState();
  const [emailOptIn, setEmailOptIn] = useState(true);
  const [termsAgreed, setTermsAgreed] = useState(isConnect);
  const [showTermsWarning, setShowTermsWarning] = useState(false);
  const [organisation, setOrganisation] = useState(provider ? provider.name : '');
  const [organisationWarning, setOrganisationWarning] = useState();
  const [abn, setAbn] = useState('');
  const [abnWarning, setAbnWarning] = useState();
  const [entityName, setEntityName] = useState('');
  const [entityNameWarning, setEntityNameWarning] = useState();
  const classes = useStyles();

  useEffect(() => {
    if (pageLocation.state?.email) {
      setEmail(pageLocation.state?.email);
    }
  }, [pageLocation.state?.email]);

  const signup = async () => {
    const valid = [
      await Validate.validateProviderEmail(email, setEmailWarning),
      await Validate.validateProviderUsername(username, setUsernameWarning),
      Validate.validateFirstName(firstName, setFirstNameWarning),
      Validate.validateLastName(lastName, setLastNameWarning),
      Validate.validatePhoneNumber(contactPhone, setContactPhoneWarning),
      Validate.validateOrganisation(organisation, setOrganisationWarning),
      Validate.validateAbn(abn, setAbnWarning),
      Validate.validateEntityName(entityName, setEntityNameWarning),
      Validate.validateLocation(location, setLocationWarning),
      validateTermsAgreed(),
      true,
    ];
    if (valid.every(v=>v)) {
      onComplete({
        email: email,
        username: username,
        organisation: organisation,
        first_name: firstName,
        last_name: lastName,
        contact_phone: contactPhone,
        location: location,
        abn: abn,
        entity_name: entityName,
        email_opt_in: emailOptIn,
      });
    }
  };

  const validateTermsAgreed = () => {
    if (!termsAgreed) {
      setShowTermsWarning(true);
      return false;
    }
    setShowTermsWarning(false);
    return true;
  };

  const emailOptInCheckboxHandler = () => {
    setEmailOptIn(!emailOptIn);
  };

  const termsCheckboxHandler = () => {
    const currentState = termsAgreed;
    setTermsAgreed(!currentState);
    if (!currentState) {
      setShowTermsWarning(false);
    }
  };


  return (
    <React.Fragment>
      <AuthForm
        buttonLabel={buttonLabel}
        buttonAction={signup}
        busy={busy}
      >
        <Typography variant="h1" color="primary">{title}</Typography>
        {isConnect && (
          <Typography variant="subtitle1">
            Already have a Clickability Account?&nbsp;
            <Link component={RouterLink} to={{pathname: loginLink, state: {redirectTo: '/connect/activation'}}}>
              <b>Activate Clickability Connect</b>
            </Link>.
          </Typography>
        )}
        {!isConnect && <Markdown content={content.body1}/>}
        {signupError &&
          <Alert severity="error">
            {signupError}
          </Alert>
        }
        <AuthFormGroup title="Contact Details">

          <AuthField
            id="firstname"
            placeholder="Your first name"
            label="First Name*"
            autoComplete="given-name"
            type="text"
            name="firstname"
            value={firstName}
            onChange={setFirstName}
            error={firstNameWarning}
            onBlur={() => Validate.validateFirstName(firstName, setFirstNameWarning, true)}
          />

          <AuthField
            id="lastname"
            placeholder="Your last name"
            label="Last Name*"
            autoComplete="family-name"
            type="text"
            name="lastname"
            value={lastName}
            onChange={setLastName}
            error={lastNameWarning}
            onBlur={() => Validate.validateLastName(lastName, setLastNameWarning, true)}
          />

          <AuthField
            id="email"
            placeholder="Your email address"
            label="Email*"
            autoComplete="email"
            type="email"
            name="email"
            value={email}
            onChange={setEmail}
            error={emailWarning}
            onBlur={() => Validate.validateProviderEmail(email, setEmailWarning, true)}
          />

          <AuthField
            id="contactphone"
            placeholder="Contact Telephone Number"
            label="Contact Telephone*"
            autoComplete="tel-national"
            type="text"
            name="contactphone"
            value={contactPhone}
            onChange={setContactPhone}
            error={contactPhoneWarning}
            onBlur={() => Validate.validatePhoneNumber(contactPhone, setContactPhoneWarning, true)}
          />

          <div>
            <LocationInput
              id="location"
              placeholder="Postcode or Suburb"
              label="Suburb*"
              autoComplete="off"
              type="text"
              name="location"
              value={location}
              onEnter={setLocation}
              error={locationWarning !== undefined}
              classes={{input: classes.input}}
              onBlur={() => Validate.validateLocation(location, setLocationWarning, true)}
            />
            {locationWarning && <Alert severity="error">{locationWarning}</Alert>}
          </div>

          <AuthField
            id="username"
            placeholder="This is the name you will be known by on Clickability."
            label="Username*"
            autoComplete="username"
            type="text"
            name="username"
            value={username}
            onChange={setUsername}
            error={usernameWarning}
            onBlur={() => {
              const value = username.trim();
              setUsername(value);
              Validate.validateProviderUsername(value, setUsernameWarning, true);
            }}
          />

        </AuthFormGroup>

        <AuthFormGroup title="Company Details">

          <AuthField
            id="organisation"
            placeholder="Your organisation's trading name"
            label="Trading Name*"
            type="text"
            name="organisation"
            value={organisation}
            onChange={setOrganisation}
            disabled={provider !== undefined}
            error={organisationWarning}
            onBlur={() => Validate.validateOrganisation(organisation, setOrganisationWarning, true)}
          />

          <AuthField
            id="abn"
            placeholder="Your organisation's ABN"
            label="ABN*"
            type="text"
            name="abn"
            value={abn}
            onChange={setAbn}
            error={abnWarning}
            onBlur={() => Validate.validateAbn(abn, setAbnWarning, true) && setAbn(Validate.formatAbn(abn))}
          />

          <AuthField
            id="entityName"
            placeholder="Your organisation's registered legal name"
            label="Registered Name*"
            type="text"
            name="entityName"
            value={entityName}
            onChange={setEntityName}
            error={entityNameWarning}
            onBlur={() => Validate.validateEntityName(entityName, setEntityNameWarning, true)}
          />


        </AuthFormGroup>
        {!isConnect && (
          <>
            <FormControlLabel
              control={<Checkbox checked={emailOptIn} onChange={emailOptInCheckboxHandler}/>}
              label="Keep me posted about updates, features and offers!"
            />
            <FormControlLabel
              control={<Checkbox checked={termsAgreed} onChange={termsCheckboxHandler} />}
              label={(
                <Typography variant="body2">
                  I agree to Clickability&apos;s&nbsp;
                  <Link component={RouterLink} to="/terms">
                    <b>Terms and Conditions</b>
                  </Link>
                  &nbsp;&&nbsp;
                  <Link component={RouterLink} to="/privacy">
                    <b>Privacy Policy.</b>
                  </Link>*
                </Typography>
              )}
            />
          </>
        )}
        {showTermsWarning ? (<Alert severity="error">You must agree to the terms and privacy policy</Alert>) : null}
        {isConnect ? (
          <Typography variant="body2">
            By clicking “Sign up” I agree to Clickability&apos;s&nbsp;
            <Link component={RouterLink} to="/terms">
              <b>Terms and Conditions</b>
            </Link>&nbsp;&&nbsp;
            <Link component={RouterLink} to="/privacy">
              <b>Privacy Policy.</b>
            </Link>&nbsp;
            A basic Clickability profile will be created using the
            Trading name provided above for the purpose of submitting Service Requests
            to Providers.
          </Typography>
        ) : <Markdown content={content.body2}/>}
      </AuthForm>
      <Typography variant="body2">
        Already a member?&nbsp;
        <Link component={RouterLink} to={loginLink}>
          <b>Log in</b>
        </Link>.
      </Typography>
    </React.Fragment>
  );
};

ProviderSignupForm.propTypes = {
  title: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  loginLink: PropTypes.string.isRequired,
  busy: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string,
  signupError: PropTypes.string,
  provider: PropTypes.object,
  isConnect: PropTypes.bool,
};

export default ProviderSignupForm;
