import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: '800',
    marginBottom: '10px',
  },
  card: {
    background: theme.palette.common.white,
    boxShadow: 'rgb(145 158 171 / 15%) 0px 0px 4px 0px, rgb(145 158 171 / 15%) 2px -2px 20px 0px',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: '#e2e2e2',
    padding: '25px 30px',
  },
}));

const Card = ({children, title, className}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.title}>
        {title}
      </div>
      <div className={classNames(classes.card, className)}>
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string
};


export default Card;