import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import styled from 'styled-components';

import Page from 'providerSite/components/Page';
import PermissionDenied from 'components/PermissionDenied';
import { hasPagePermission } from './utils';


const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const withPermissionOnly = (Component, permission) => {
  const hoc = props => {
    const profile = useSelector(state => state.auth.profile);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const history = useHistory();
    const location = useLocation();
    const { uuid:  providerUuid} = useParams();

    if (!isAuthenticated || !profile?.is_provider) {
      history.replace('/providers/login', {redirectTo: location.pathname + location.search});
    }

    if (!hasPagePermission(profile, providerUuid, permission)) {
      return (
        <Page>
          <Container>
            <PermissionDenied />
          </Container>
        </Page>
      );
    }
    return <Component {...props} profile={profile}/>;
  };
  hoc.displayName = 'withPermissionOnly';
  return hoc;
};

export default withPermissionOnly;
