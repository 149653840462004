import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';


const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px',
    marginTop: '40px',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
    },
  },
  title: {
    color: theme.palette.primary.main,
  },
}));

const Team = ({title, data, className}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      <Container maxWidth="md" fixed disableGutters={true} className={classes.container}>
        {!!data && data.map(item => (
          <Card
            key={item.name}
            title={item.name}
            image={item.photo}
            description={item.position}
          />
        ))}
      </Container>
    </div>
  );
};

Team.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

const cardStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: theme.palette.common.lightBorder,
    height: '250px',
    width: '200px',
    fontSize: '14px',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: 'rgb(145 158 171 / 15%) 0px 0px 4px 0px, rgb(145 158 171 / 15%) 2px -2px 20px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '185px',
      height: '230px',
    },
  },
  img: {
    width: '130px',
    borderRadius: '50%',
  },
  title: {
    marginTop: '10px',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
}));

const Card = ({title, image, description}) => {
  const classes = cardStyles();

  return (
    <div className={classes.root}>
      <img src={image} alt="Team" className={classes.img}/>
      <div className={classes.title}>
        {title}
      </div>
      <div>
        {description}
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
};

export default Team;
