import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NewsIcon from '../../icons/NewsIcon';
import { useSelector } from 'react-redux';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom';
import * as track from '../../lib/track';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '15px',
    padding: '0.5rem',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '2rem',
    cursor: 'pointer',
    backgroundColor: theme.palette.common.paleGrey,
    border: `2px solid ${theme.palette.common.tealish}`,
    '& $chevron .MuiSvgIcon-root': {
      transition: 'transform 300ms ease-in-out',
    },
    '&:hover': {
      '& $chevron .MuiSvgIcon-root': {
        transform: 'translate(5px, 0px)',
      },
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '98%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  icon: {
    marginRight: '1rem',
  },
  text: {
    fontSize: '0.9rem',
  },
  chevron: {
    padding: 0, // Requried to activate the class for targetting by root element
  },
  chevronIcon: {
    marginBottom: '-4px',
  },
}));

const UserFeaturesCTA = () => {
  const classes = useStyles();
  const history = useHistory();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const onClick = () => {
    track.sendEvent('new features CTA button', 'click', {});
    history.push('/signup');
  };

  if (isAuthenticated) return null;

  return (
    <div className={classes.root} onClick={onClick}>
      <NewsIcon fontSize="large" className={classes.icon} />
      <Typography variant="h3" color="primary" className={classes.text}>Try our new account features that help you manage your NDIS journey, <b><em>for free!</em></b></Typography>
      <div className={classes.chevron}>
        <ChevronRightIcon fontSize="large" color="primary" className={classes.chevronIcon} />
      </div>
    </div>
  );
};

export default UserFeaturesCTA;
