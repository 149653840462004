import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Typography, Collapse as AntdCollapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';
import _Container from 'providerSite/components/Container';


const Main = styled.div`
  padding: ${({padding}) => padding ? '100px' : 0} 0 100px 0;
  display: flex;
  justify-content: center;
  @media (max-width: ${style.screens.tab}) {
    padding-top: ${({padding}) => padding ? '50px' : 0};
    padding-bottom: 80px;
  }
`;

const Container = styled(_Container)`
  justify-content: space-between;
  flex-direction: row;
  text-align: left;
  @media (max-width: ${style.screens.tab}) {
    flex-direction: column;
    row-gap: 25px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

const RightContainer = styled.div`
  width: 50%;
  @media (max-width: ${style.screens.tab}) {
    width: 100%;
  }
`;

const Collapse = styled(AntdCollapse)`
  font-size: 20px;
  font-weight: 500;
  background: none;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-color: ${style.colors.lightBorder};
  
  .ant-collapse-header {
    outline: none;
    border-radius: 0;
  }
  
  .ant-collapse-item {
    border-color: ${style.colors.lightBorder};
    
    &:last-child {
      border-radius: 0;
    }
  }
  
  .ant-collapse-expand-icon {
    color: ${style.colors.secondary};
  }
  
  .ant-collapse-content {
    border-top: none;
    
    &-box{
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const Questions = ({questions, padding = true}) => {

  return (
    <Main padding={padding}>
      <Container size="medium" flex>
        <LeftContainer>
          <Typography.Title level={2} style={{color: style.colors.secondary}}>
            Popular Questions.
          </Typography.Title>
        </LeftContainer>
        <RightContainer>
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="end"
            expandIcon={({isActive}) => {
              const icon = isActive ? faAngleUp : faAngleDown;
              return <FontAwesomeIcon icon={icon} style={{fontSize: '20px'}} />;
            }}
          >
            {questions.map((question) => (
              <AntdCollapse.Panel header={question.header} key={question.key}>
                {question.content}
              </AntdCollapse.Panel>
            ))}
          </Collapse>
        </RightContainer>
      </Container>
    </Main>
  );
};

Questions.propTypes = {
  questions: PropTypes.array,
  padding: PropTypes.bool
};


export default Questions;
