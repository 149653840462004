import AppBar from '@material-ui/core/AppBar';
import AuthMenu from './AuthMenu.js';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import MenuControls from './MenuControls.js';
import MobileMenu from './MobileMenu.js';
import { NAV_BAR_HEIGHT } from '../constants';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import config from 'config';
import logo from '../../assets/logo-tagline.svg';
import { makeStyles } from '@material-ui/core/styles';

const helpMenu = [
  {label: 'Participant FAQs', href: config.link.participantFaq},
  {label: 'Provider FAQs', href: config.link.providerFaq},
  {label: 'Contact Us', link: '/contact'},
];

const businessMenu = [
  {label: 'Service Providers', link: '/providers'},
  {label: 'Intermediaries', link: '/connect'},
];

const mainMenu = [
  {label: 'Services', link: '/requestmatch#service'},
  {label: 'Directory', link: '/search'},
  {label: 'About Us', link: '/about'},
  {label: 'Blog', link: '/blog'},
  {label: 'For Business', submenu: businessMenu},
  {label: 'Help', submenu: helpMenu},
];

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: 998,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px -2px 46px rgba(0, 0, 0, 0.07), ' +
      '0px -0.730033px 16.7908px rgba(0, 0, 0, 0.0482987), ' +
      '0px -0.354417px 8.1516px rgba(0, 0, 0, 0.0389404), ' +
      '0px -0.173742px 3.99607px rgba(0, 0, 0, 0.0310596), ' +
      '0px -0.0686979px 1.58005px rgba(0, 0, 0, 0.0217013)',
    borderRadius: ({isMobileMenuShowing}) => isMobileMenuShowing ? '0' : '0px 0px 32px 32px',
    transition: ({isMobileMenuShowing}) => isMobileMenuShowing ? 'border-radius 0.7s' : 'border-radius 3s',
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: 'none',
    backgroundColor: 'transparent',
    padding: 0,
  },
  toolbar: {
    padding: '0 1rem',
    height: NAV_BAR_HEIGHT,
    width: '100%',
  },
  logo: {
    display: 'block',
    marginRight: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  testBar: {
    zIndex: 99999,
    padding: '0 100px',
    borderRadius: '0 0 12px 12px',
    position: 'fixed',
    left: '50%',
    whiteSpace: 'nowrap',
    transform: 'translate(-50%, 0)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    backgroundColor: theme.palette.common.dustyOrange,
    color: theme.palette.common.white,
    fontWeight: '500',
    boxShadow: 'rgb(145 158 171 / 25%) 0px 0px 4px 0px, rgb(145 158 171 / 25%) 2px -2px 20px 0px',
  },
}));

const NavMenu = ({ hideNav = false, hideAuth = false, children }) => {
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const [authMenu, setAuthMenu] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState();

  const classes = useStyles({isMobileMenuShowing: showMobileMenu});
  const appBarRef = React.useRef();

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const toggleAuthMenu = (value, anchorEl) => {
    if (authMenu !== value) {
      setAuthMenu(value);
      setAnchorEl(anchorEl);
    } else {
      setAuthMenu(null);
      setAnchorEl(null);
    }
  };

  return (
    <div className={classes.root}>
      {config.stage === 'test' && (
        <div className={classes.testBar}>
          TEST ENVIRONMENT
        </div>
      )}
      <AppBar position="static" className={classes.appBar} ref={appBarRef}>
        <Container maxWidth="lg" disableGutters>
          <Toolbar className={classes.toolbar} disableGutters={true}>
            {children ?
              children
              :
              <Link to="/">
                <img src={logo} alt="clickability-logo" className={classes.logo} height="45" />
              </Link>
            }
            <MenuControls
              items={mainMenu}
              hideNav={hideNav}
              hideAuth={hideAuth}
              toggleMobileMenu={toggleMobileMenu}
              toggleAuthMenu={toggleAuthMenu}
            />
          </Toolbar>
        </Container>
      </AppBar>
      <MobileMenu
        appBarRef={appBarRef.current}
        isOpen={showMobileMenu}
        items={mainMenu}
        hideNav={hideNav}
        hideAuth={hideAuth}
        authMenu={authMenu}
        setAuthMenu={setAuthMenu}
        close={()=>setShowMobileMenu(false)}
      />
      {authMenu !== null && showMobileMenu === false ? (
        <AuthMenu anchorEl={anchorEl} authMenu={authMenu} setAuthMenu={setAuthMenu} />
      ) : null}
    </div>
  );
};

NavMenu.propTypes = {
  hideNav: PropTypes.bool,
  hideAuth: PropTypes.bool,
  children: PropTypes.node,
};

export default NavMenu;
