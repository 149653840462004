import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  input: {
    backgroundColor: theme.palette.common.paleGrey,
  },
}));

const AuthField = ({onChange, onEnter, error, ...props}) => {
  const classes = useStyles();

  const onKeyUp = (event) => {
    if (event.keyCode === 13 && onEnter) {
      onEnter();
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <div className={classes.holder}>
        <TextField
          fullWidth
          variant="outlined"
          onChange={(event) => onChange && onChange(event.target.value)}
          error={error !== undefined}
          onKeyUp={onEnter ? onKeyUp : undefined}
          InputProps={{className: classes.input}}
          {...props}
        />
        {error && <Alert severity="error">{error}</Alert>}
      </div>
    </FormControl>
  );
};

AuthField.propTypes = {
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  error: PropTypes.string,
};

export default AuthField;
