import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import CircularProgressWithLabel from './CircularProgressWithLabel';


const useStyles = makeStyles(theme => ({
  root: {
    '--bgColorHovered': '#2D597F',
    '--bgColor': ({isClicked, isHovered}) => (isClicked || isHovered) ?
      'var(--bgColorHovered)' : theme.palette.common.darkSlateBlue,
    backgroundImage: 'radial-gradient(circle at center 6px,transparent 36px, var(--bgColor) 37px)',
    filter: 'drop-shadow(0 0 4px #424242)',
    position: 'fixed',
    bottom: '0%',
    left: '0%',
    width: '100%',
    zIndex: '1',
    height: '60px',
    padding: '0 10px',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '10px',
    cursor: 'pointer',
  },
  expandInfo: {
    width: '70px',
  },
  icon: {
    marginTop: '7px',
  },
  progressContainer: {
    height: '53px',
    width: '53px',
    background: ({isHovered}) => isHovered ? 'var(--bgColorHovered)' : 'var(--bgColor)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-48px',
  },
}));

export const BottomMobilePanel = ({ value=0, onClick, isClicked=false }) => {
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles({isClicked: isClicked, isHovered: isHovered});

  return (
    <div
      className={classes.root}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={classes.expandInfo}>
        <MenuOpenIcon className={classes.icon} />
      </div>
      <div className={classes.progressContainer}>
        <CircularProgressWithLabel value={value} thickness={4}/>
      </div>
      <div className={classes.expandInfo}>
      </div>
    </div>
  );
};

BottomMobilePanel.propTypes = {
  value:PropTypes.number,
  onClick: PropTypes.func,
  isClicked: PropTypes.bool,
};

export default BottomMobilePanel;