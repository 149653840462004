import camelcaseKeys from 'camelcase-keys';

import {ServiceRequestAPI} from '../api';

const SET = 'serviceRequestNotification/SET';

const initialState = {
  totalAvailable: 0,
  serviceRequests: [],
};

const set = payload => {
  return {
    type: SET,
    data: payload,
  };
};

export const refreshNotification = () => {
  return dispatch => {
    ServiceRequestAPI.getAvailableServiceRequestCount()
      .then(r => camelcaseKeys(r.payload, {deep: true}))
      .then(r => dispatch(set(r)))
      .catch(err => console.error(err));  //eslint-disable-line no-console
  };
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case SET:
    return action.data;
  default:
    return state;
  }
};

export default reducer;
