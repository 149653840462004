import React  from 'react';
import PropTypes from 'prop-types';

import Search from 'modules/search/components/Search';


const Directory = ({ location }) => {

  return (
    <div>
      <Search
        location={location}
        externalDirectoryMode
      />
    </div>
  );
};

Directory.propTypes = {
  location: PropTypes.object,
};


export default Directory;

