// Define permissions - avoid using the literal permission names to reduce
// the chance of a typo.

export const EDIT_PROVIDER = 'Edit Provider';
export const CREATE_LISTINGS = 'Create Listings';
export const EDIT_LISTINGS = 'Edit Listings';
export const VIEW_USERS = 'View Users';
export const INVITE_USERS = 'Invite Users';
export const EDIT_USER_ROLES = 'Edit User Roles';
export const REMOVE_USERS = 'Remove Users';
export const VIEW_ANALYTICS = 'View Provider Analytics';
export const CREATE_REVIEW_RESPONSES = 'Create Review Responses';
export const EDIT_REVIEW_RESPONSES = 'Edit Review Responses';
export const CREATE_PROVIDER = 'Create Provider';
export const VIEW_BILLING = 'View Billing';
export const EDIT_BILLING = 'Edit Billing';
export const VIEW_SERVICE_REQUESTS = 'View Service Requests';
export const EDIT_SERVICE_REQUESTS = 'Edit Service Requests';

// return true if the profile has site admin role
export const isSiteAdmin = (profile) => {
  return profile && profile.is_site_admin;
};

// return a list of roles held by this profile for this provider.
export const providerRoles = (profile, providerUuid) => {
  const roles = profile && profile.roles && profile.roles.filter(r => r.provider_uuid === providerUuid);
  return roles || [];
};

// return true if either is site admin, or has specific permission within specified provider org.
// If strictSiteAdmin is passed and true then it will not treat admin differently than regular
// users and check if the admin has permission within specified provider org.
export const hasPermission = (profile, providerUuid, permission, strictSiteAdmin=false) => {
  return (!strictSiteAdmin && isSiteAdmin(profile)) || providerRoles(profile, providerUuid).some(role => role.permissions.includes(permission));
};
