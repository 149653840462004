export const ADVERTISEMENT_PACKAGE = {
  PREMIUM: 'Premium',
  VISIBILITY_BOOST: 'Visibility Boost',
  CONTENT_MARKETING: 'Content Marketing',
};

export const ADVERTISEMENT_FEATURE = {
  DIRECT_CONNECT: 'Direct Connect',
  DIRECTORY: 'Directory Search for Service Offering',
  MODALITY: 'Modality Landing Page for Service Offering',
  HOMEPAGE: 'Home Page Placement',
  SPONSORED_BLOG: 'Sponsored Blog Post',
};