import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { prettyDate } from '../../../lib/time';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '15.579rem',
    backgroundColor: theme.palette.common.white,
  },
  cardImage: {
    height: '15.579rem',
    objectFit: 'cover',
    width: '100%',
  },
  cardLeft: {
    height: '100%',
  },
  cardRight: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardButton: {
    position: 'absolute',
    right: '1.5rem',
    bottom: '1rem',
    '&>a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '&>a:hover': {
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
  text: {
    padding: '0.563rem 1.375rem 2.8rem 1.375rem',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    marginBottom: '0.5rem',
    lineHeight: '2rem',
    height: '4rem',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  titleLink: {
    textDecoration: 'none',
    '& h2': {
      fontWeight: 400,
    },
  },
  leader: {
    marginTop: '1rem',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    lineHeight: '1.8rem',
    height: '5.2rem',
    overflowY: 'hidden',
  },
}));

export const BlogListItemSkeleton = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.card} spacing={0}>
      <Grid item xs={5} className={classes.cardLeft}>
        <Skeleton variant="rect" className={classes.cardImage}/>
      </Grid>
      <Grid item xs={7} className={classes.cardRight}>
        <div className={classes.text}>
          <Typography variant="h2" color="primary" component="div" className={classes.title}>
            <Skeleton style={{width: 300}}/>
          </Typography>
          <Typography variant="body2" className={classes.date}>
            <Skeleton style={{width: 100}}/>
          </Typography>
          <Typography variant="body1" component="div" className={classes.leader}>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

const BlogListItem = ({item}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.card} spacing={0}>
      <Grid item xs={5} className={classes.cardLeft}>
        <Link to={item.path}>
          <img src={item.featured_image} className={classes.cardImage} alt=""/>
        </Link>
      </Grid>
      <Grid item xs={7} className={classes.cardRight}>
        <div className={classes.text}>
          <Link to={item.path} className={classes.titleLink}>
            <Typography variant="h2" color="primary" className={classes.title}>
              {item.title}
            </Typography>
          </Link>
          <Typography variant="body2" className={classes.date}>
            {prettyDate(item.date)}
          </Typography>
          <Typography variant="body2" className={classes.leader} component="div">
            {item.leader}
          </Typography>
        </div>
        <div className={classes.cardButton}>
          <Link to={item.path} aria-label="link to article">
            <b>Read more</b>
          </Link>
        </div>
      </Grid>
    </Grid>
  );
};

BlogListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default BlogListItem;
