import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  question: {
    paddingLeft: '1rem',
    borderLeft: 'solid 3px white',
    transitionProperty: 'border',
    transitionDuration: '1000ms',
    transitionDelay: '1000ms',
  },
  nextQuestion: {
    paddingLeft: '1rem',
    borderLeft: 'solid 3px #21bfaf',
  },
});

const QuestionContainer = ({hidden, isNextQuestion, onClick, children}) => {
  const classes = useStyles();
  const className = isNextQuestion ? classes.nextQuestion : classes.question;
  return (
    <Collapse in={!hidden} className={className} onClick={onClick}>
      {children}
    </Collapse>
  );
};

QuestionContainer.propTypes = {
  hidden: PropTypes.bool.isRequired,
  isNextQuestion: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default QuestionContainer;