import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  block: {
    padding: '1.5rem',
    '&>div': {
      paddingTop: '1.5rem',
    },
    boxShadow: 'rgb(145 158 171 / 8%) 0px 0px 4px 0px, rgb(145 158 171 / 8%) 2px -2px 20px 0px',
  }
}));

const AuthFormGroup = ({title, children}) => {
  const classes = useStyles();
  return (
    <Paper variant="outlined" className={classes.block}>
      <Typography variant="h2" color="primary">
        {title}
      </Typography>
      {children}
    </Paper>
  );
};

AuthFormGroup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AuthFormGroup;
