import {
  faCircleNodes,
  faUsers,
  faPerson,
  faHouseBlank,
  faBellOn
} from '@fortawesome/pro-regular-svg-icons';

import { EDIT_USER_ROLES } from 'lib/permissions';
import ClickaConnect from './pages/ClickaConnect';
import Participants from './pages/Participants';
import User from './pages/User';
import Profile from './pages/Profile';
import withPermissionOnly from './components/permissionOnly';
import ServiceRequest from './pages/ServiceRequest';
import NotificationSettings from './pages/NotificationSettings';


// Temporary
const VIEW_CONNECT_REQUESTS = '';


export const pages = [
  {
    name: 'Clickability Connect',
    uri: 'connect',
    childItemUri: 'service-request',    // Highlights the nav item on this uri
    Component: withPermissionOnly(ClickaConnect, VIEW_CONNECT_REQUESTS),
    icon: faCircleNodes,
  },
  {
    name: 'Participants',
    uri: 'participants',
    Component: withPermissionOnly(Participants, VIEW_CONNECT_REQUESTS),
    icon: faPerson,
  },
  {
    name: 'Company Profile',
    uri: 'profile',
    Component: withPermissionOnly(Profile, VIEW_CONNECT_REQUESTS),
    icon: faHouseBlank,
  },
  {
    name: 'Service Request',
    uri: 'service-request',
    Component: withPermissionOnly(ServiceRequest, VIEW_CONNECT_REQUESTS),
    icon: faPerson,
    hidden: true,
  },
  {
    name: 'Users',
    uri: 'users',
    Component: withPermissionOnly(User, EDIT_USER_ROLES),
    icon: faUsers,
  },
  {
    name: 'Notification Settings',
    uri: 'notification-settings',
    Component: withPermissionOnly(NotificationSettings, VIEW_CONNECT_REQUESTS),
    icon: faBellOn,
  }
];
