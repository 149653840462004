import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';

import { getHexWithOpacity } from 'lib/util';
import { ServiceRequestAPI } from 'api';
import ErrorAlert from './ErrorAlert';
import OptionItem from './OptionItem';
import ConfirmationModal from './ConfirmationModal';
import Dot from './Dot';
import { getStatusData, getEventData } from './utils';
import Spinner from 'components/Spinner';
import * as constants from 'components/constants';
import style from 'style';
import { SERVICE_REQUEST_EVENT_RESERVE } from 'components/constants';
import ReserveModal from '../ReserveModal';


const useStyles = makeStyles( theme => ({
  root: {
    whiteSpace: 'nowrap',
    display: 'flex',
    columnGap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    boxShadow: 'rgb(145 158 171 / 10%) 0px 0px 4px 0px, rgb(145 158 171 / 10%) 2px -2px 20px 0px',
    borderColor: ({color}) => getHexWithOpacity(color ? color : theme.palette.common.textGrey, 15),
    backgroundColor: ({color}) => getHexWithOpacity(color ? color : theme.palette.common.textGrey, 10),
    width: '128px',
    height: '32px',
    padding: '0 12px 0 12px',
    borderRadius: '8px',
    fontWeight: '500',
    fontSize: '13px',
    cursor: ({isOptionsAvailable}) => isOptionsAvailable ? 'pointer' : 'default',
    color: ({color}) => color ? color : theme.palette.common.textGrey,
    '&:hover': {
      color: ({color}) => getHexWithOpacity(color ? color : theme.palette.common.textGrey, 70),
    },
  },
  text: {
    marginTop: '1px',
  },
  popover: {
    marginTop: '5px',
    '& .MuiPopover-paper': {
      boxShadow: 'rgb(145 158 171 / 10%) 0px 0px 4px 0px, rgb(145 158 171 / 10%) 2px -2px 20px 0px',
      borderRadius: '8px',
      minWidth: '128px',
      border: '1px solid',
      borderColor: theme.palette.common.lightBorder,
    },
  },
}));


const ServiceRequestStatus = (
  {
    serviceRequestUuid,
    status,
    availableOptions,
    closureReason,
    closureReasonOptions,
    isLoading,
    handleGlobalUpdatedCellData=() => {},
    setLoading=() => {},
    providerUuid,
    confirmationMessages={},
    errorMessages={},
    noCredits,
    globalUpdatedCellData,
    serviceInquiryData,
  }) => {
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReserveModalOpen, setIsReserveModalOpen] = useState(false);
  const [reserveModalKey, setReserveModalKey] = useState(1);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [currentAvailableOptions, setCurrentAvailableOptions] = useState(availableOptions);
  const [selectedEvent, setSelectedEvent] = useState();
  const [closureReasonValue, setClosureReasonValue] = useState(closureReason);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleOpen = event => {
    if (Array.isArray(availableOptions) && availableOptions.length > 0) {
      event.stopPropagation();
    }
    !isModalOpen && setAnchorEl(event.currentTarget);
  };
  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleErrorClose = () => setError(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setAnchorEl(null);
    setIsModalOpen(false);
  };

  const handleSelect = (value, event) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (value === SERVICE_REQUEST_EVENT_RESERVE) {
      setIsReserveModalOpen(true);
    } else {
      setSelectedEvent(value);
      handleModalOpen();
    }
  };

  const handleCancellationSelection = value => setClosureReasonValue(value);

  const handleUpdateServiceReqStatus = event => {
    setAnchorEl(null);
    handleModalClose();
    setLoading(true);

    const _data = closureReasonValue ?
      snakecaseKeys({closureReason: closureReasonValue}, {deep: true})
      : null;
    const eventData = {event: event, data: _data};

    // If Provider UUID is provided then it means, it will use the api which uses
    // provider uuid to updated the data.
    const updateApi = providerUuid ?
      ServiceRequestAPI.updateServiceRequestStatusForProviders(
        providerUuid, serviceRequestUuid, eventData) :
      ServiceRequestAPI.updateServiceRequestStatus(serviceRequestUuid, eventData);

    updateApi
      .then(r => camelcaseKeys(r, {deep: true}))
      .then(r => {
        const status = r.payload.status;
        setCurrentStatus(status.statusName);
        setCurrentAvailableOptions(status.availableDropdownEvents);
        handleGlobalUpdatedCellData(
          serviceRequestUuid, r.payload);
      })
      .catch(error => {
        console.error(error);   // eslint-disable-line no-console
        setError(true);
        setErrorMessage(error.body?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setCurrentStatus(status);
  }, [serviceRequestUuid, status]);

  useEffect(() => {
    setCurrentAvailableOptions(availableOptions);
  }, [serviceRequestUuid, availableOptions]);

  useEffect(() => {
    if (!globalUpdatedCellData) return;

    const updatedData = globalUpdatedCellData[serviceRequestUuid];
    if (!updatedData)
      return;

    setCurrentStatus(updatedData.status.statusName);
    setCurrentAvailableOptions(updatedData.status.availableDropdownEvents);
  }, [serviceRequestUuid, globalUpdatedCellData]);


  const {text, color} = getStatusData(theme, currentStatus);

  const classes = useStyles({
    color: noCredits ? style.colors.error : color,
    isOptionsAvailable: !!currentAvailableOptions && currentAvailableOptions.length
  });

  const isOptionsAvailable = !!(currentAvailableOptions && currentAvailableOptions.length);

  const renderBody = () => {
    return (
      <div className={classes.root} onClick={handleOpen}>
        <Dot color={color} noPulse={!isOptionsAvailable} />
        <div className={classes.text}>{text}</div>
        {isOptionsAvailable && (
          <ExpandMoreIcon
            fontSize="small"
            style={{marginLeft: '-5px', marginRight: '-5px'}}
          />
        )}
        {isOptionsAvailable && (
          <Popover
            className={classes.popover}
            open={isOpen}
            onClose={handleClose}
            anchorEl={anchorEl}
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {currentAvailableOptions.map(item => {
              const {text: eventName, color: eventColor, icon} = getEventData(theme, item);
              return (
                <OptionItem
                  key={item}
                  color={eventColor}
                  onClick={(event) => handleSelect(item, event)}
                  name={eventName}
                  icon={icon}
                />
              );
            })}
          </Popover>
        )}
        <ConfirmationModal
          isModalOpen={isModalOpen}
          status={currentStatus}
          selectedEvent={selectedEvent}
          handleModalClose={handleModalClose}
          handleUpdateServiceReqStatus={handleUpdateServiceReqStatus}
          handleCancellationSelection={handleCancellationSelection}
          confirmationMessages={confirmationMessages}
          closureReasonOptions={closureReasonOptions}
          closureReasonValue={closureReasonValue}
        />
        {isReserveModalOpen && (
          <ReserveModal
            key={`reserve-modal-${reserveModalKey}`}
            data={serviceInquiryData}
            serviceRequestUuid={serviceRequestUuid}
            providerUuid={providerUuid}
            onCancel={() => setIsReserveModalOpen(false)}
            onReQueue={() => setReserveModalKey(prevState => prevState + 1)}
            onAccept={handleUpdateServiceReqStatus}
          />
        )}
      </div>
    );
  };

  if (noCredits) {
    return (
      <Tooltip title="Please upgrade your subscription to have more credits">
        <div className={classes.root} style={{cursor: 'not-allowed', userSelect: 'none', fontSize: '12px'}}>
          No Credits Available
        </div>
      </Tooltip>
    );
  }

  if (!text || isLoading)
    return (
      <div className={classes.root} style={{cursor: 'not-allowed'}}>
        <Spinner size="35px" />
      </div>
    );

  return (
    <>
      {(currentStatus === constants.SERVICE_REQUEST_STATUS_CANCELLED
        && closureReasonValue && !isModalOpen) ? (
          <Tooltip title={`Reason: ${closureReasonValue}`}>{renderBody()}</Tooltip>
        ) : (
          <>{renderBody()}</>
        )}
      <ErrorAlert
        error={error}
        errorMessages={errorMessages}
        errorMessage={errorMessage}
        handleErrorClose={handleErrorClose}
      />
    </>
  );
};


ServiceRequestStatus.propTypes = {
  status: PropTypes.string,
  serviceRequestUuid: PropTypes.string,
  availableOptions: PropTypes.arrayOf(PropTypes.string),
  closureReason: PropTypes.string,
  closureReasonOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool,
  handleGlobalUpdatedCellData: PropTypes.func,
  setLoading: PropTypes.func,
  providerUuid: PropTypes.string,
  confirmationMessages: PropTypes.object,
  errorMessages: PropTypes.object,
  noCredits: PropTypes.bool,
  globalUpdatedCellData: PropTypes.objectOf(PropTypes.object),
  serviceInquiryData: PropTypes.object,
};

export default ServiceRequestStatus;