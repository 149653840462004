import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import ProviderSignupForm from './components/ProviderSignupForm';
import { useHistory, useLocation } from 'react-router-dom';
import AuthFormPage from './components/AuthFormPage';
import Switch from '../../components/Switch';
import CardPayment from '../../components/braintree/CardPayment';
import PlanCardGroup from './components/PlanCardGroup';
import PlanSummary from './components/PlanSummary';
import Markdown from '../../components/Markdown';
import { BillingAPI } from '../../api';
import { AuthAPI } from '../../api';
import { useDispatch } from 'react-redux';
import { AuthStore } from '../../store';
import { useContent } from '../../components/ContentProvider';
import { ProviderAPI } from '../../api';
import Button from '../../components/Button';
import { makeStyles } from '@material-ui/core/styles';

const DEFAULT_ERROR_MESSAGE = 'An error occurred creating your account.  Please try again later.';

const useStyles = makeStyles({
  planRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
});

const pages = ['1', '2'];

const ProviderClaimProfilePage2 = ({match}) => {
  const content = useContent('/providers/signup/') || {};
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState();
  const [planId, setPlanId] = useState();
  const [busy, setBusy] = useState(false);
  const [signupError, setSignupError] = useState();
  const [page1Args, setPage1Args] = useState({complete: false});
  const [provider, setProvider] = useState();
  const page = location.hash.substring(1) || '1';
  const plan = plans && plans.find(({ id }) => id === planId);
  const uuid = match.params.uuid;
  const classes = useStyles();

  const page1Complete = (args) => {
    setPage1Args({...args, complete: true});
    history.push('#2');
    window.scrollTo(0, 0);
  };

  const page2Complete = async (args) => {
    try {
      dispatch(AuthStore.showOverlay(true));
      setBusy(true);
      await AuthAPI.providerClaimProfile({billing_plan_id: planId, ...page1Args, ...args, uuid});
      setBusy(false);
      dispatch(AuthStore.showOverlay(false));
      history.push('/signup/confirm', {email: page1Args.email});
    } catch (err) {
      setBusy(false);
      dispatch(AuthStore.showOverlay(false));
      console.error(err);  // eslint-disable-line no-console
      const message = (err.body && err.body.message) ? err.body.message : DEFAULT_ERROR_MESSAGE;
      setSignupError(message);
      history.replace('#1');
    }
  };

  // Get the provider profile data

  React.useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { payload } = await ProviderAPI.getProfile(uuid);
        setProvider(payload);
        if (payload && payload.active === false) {
          history.replace('/404-page-not-found');
        }
      } catch (err) {
        if (err.status === 404) {
          history.replace('/404-page-not-found');
        }
        console.error(err); // eslint-disable-line no-console
      }
    };
    fetchProfile();
  }, [uuid, history]);

  useEffect(()=> {
    const getPlans = async() => {
      const { payload: { plans }} = await BillingAPI.getPlans();
      setPlans(plans);
      setPlanId(plans[0].id);  // set default plan to first plan
    };
    getPlans();
  }, []);

  useEffect(() => {
    if (!pages.includes(page) || (page !== '1' && !page1Args.complete)) {
      history.replace('#1');
    }
  }, [page, history, page1Args]);

  if (provider == null) {
    return null;
  }

  const title = `Claim Clickability Provider profile for ${provider.name}`;

  return (
    <AuthFormPage title={title}>
      <Switch value={page}>
        <ProviderSignupForm
          title={title}
          busy={busy}
          signupError={signupError}
          key="1"
          onComplete={page1Complete}
          loginLink="/providers/login"
          buttonLabel="Continue"
          provider={provider}
        />
        <div key="2">
          <Markdown content={content.paymentheader}/>
          <div className={classes.planRow}>
            {plans && planId && <PlanCardGroup plans={plans} planId={planId} onChange={setPlanId}/>}
            <div>
              <Button variant="outlined" label="Maybe&nbsp;later" onClick={()=>page2Complete({billing_plan_id: null})}/>
            </div>
          </div>
          <CardPayment
            onComplete={page2Complete}
            onError={setSignupError}
            anonymous={true}
            buttonLabel="Sign Up"
            footer={plan && <PlanSummary plan={plan} />}
          />
          <Markdown content={content.paymentfooter}/>
        </div>
      </Switch>
    </AuthFormPage>
  );
};

ProviderClaimProfilePage2.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ProviderClaimProfilePage2;
