import { useEffect, useState } from 'react';

import Alert from '@material-ui/lab/Alert';
import { AuthAPI } from '../../api';
import PassportErrorPage from 'components/PassportErrorPage';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PassportErrorImg from '../../assets/passport-error-image.svg';
import PassportLoadingImg from '../../assets/passport-loading-image.svg';
import PassportSuccessImg from '../../assets/passport-handshake-logo.svg';

const useStyles = makeStyles(theme => ({
  lighterFontWeight: {
    fontWeight: 300,
  },
  successAlert: {
    backgroundColor: '#4c867e'
  },
  errorAlert: {
    backgroundColor: theme.palette.common.vermillion
  },
  infoAlert: {
    backgroundColor: theme.palette.common.squash
  },
  title: {
    marginBottom: '0.573rem'
  }
}));

const defaultErrorMessage = 'An unexpected error occurred verifying your email.  Please try again later.';
const loadingMessage = 'Verifying...';
const successMessage = 'Thank you for verifying your email address.  You are all set!';


const VerifyPassportEmailPage = () => {
  const classes = useStyles();
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');
  const [message, setMessage] = useState(<React.Fragment><div>We&apos;ll be right with you</div><div className={classes.lighterFontWeight}>{loadingMessage}</div></React.Fragment>);
  const [status, setStatus] = useState('info');
  const [icon, setIcon] = useState(<ErrorOutlineIcon fontSize="large" />);
  const [className, setClassName] = useState(classes.infoAlert);
  const [backgroundImg, setBackgroundImg] = useState(PassportLoadingImg);

  useEffect(() => {
    const verifyEmail = async (code) => {
      try {
        await AuthAPI.verifyEmail(code);
        setStatus('success');
        setIcon(<CheckCircleOutlineIcon fontSize="large" />);
        setMessage(successMessage);
        setMessage(<React.Fragment><div>Verification complete!</div><div className={classes.lighterFontWeight}>{successMessage}</div></React.Fragment>);
        setClassName(classes.successAlert);
        setBackgroundImg(PassportSuccessImg);
      } catch (err) {
        const message = err.body && err.body.message ? err.body.message : defaultErrorMessage;
        setStatus('error');
        setIcon(<SentimentVeryDissatisfiedIcon fontSize="large" />);
        setMessage(<React.Fragment><div>Email verification failed with the following error:</div><div className={classes.lighterFontWeight}>{message}</div></React.Fragment>);
        setClassName(classes.errorAlert);
        setBackgroundImg(PassportErrorImg);
      }
    };
    verifyEmail(code);
  }, [code, classes]);

  return (
    <PassportErrorPage
      image={backgroundImg}
      alert={
        <div>
          <Typography variant="h1" className={classes.title}>Verifying Email Address</Typography>
          <Alert severity={status} icon={icon} className={className} variant="filled">
            {message}
          </Alert>
        </div>
      }
    />
  );
};

export default VerifyPassportEmailPage;