export default {
  socials: {
    facebook: 'https://facebook.com/clickability.com.au',
    linkedIn: 'https://www.linkedin.com/company/clickability---rated-reviewed-reliable',
    twitter: 'https://twitter.com/_clickability',
    blogEmail: 'blog@clickability.com.au',
    infoEmail: 'hello@clickability.com.au',
  },
  api: {
    URL: process.env.REACT_APP_API_URL,
  },
  sentry: {
    DSN: process.env.REACT_APP_SENTRY_DSN,
  },
  website: {
    URL: process.env.REACT_APP_WEBSITE_URL,
    URI: process.env.REACT_APP_WEBSITE_URI,
  },
  features: {
    iphoneGiveaway: {
      enabled: true,  // set to false to disable landing page, SR landing page content, SR email content
      url: 'https://gleam.io/a7Eex/clickabilitys-apple-watch-giveaway',
      code: 'SR1XW6',
    }
  },
  DISABLED_phone: process.env.REACT_APP_PHONE, // remove DISABLED_ prefix to restore phone contact details
  settings: {
    GTM_ID: process.env.REACT_APP_GTM_ID,
    CHAT_SCRIPT: process.env.REACT_APP_CHAT_SCRIPT,
    RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
    BRAINTREE_SCRIPT: 'https://js.braintreegateway.com/web/dropin/1.42.0/js/dropin.min.js',
    BRAINTREE_CLIENT: 'https://js.braintreegateway.com/web/3.102.0/js/client.min.js',
    BRAINTREE_HOSTED_FIELDS: 'https://js.braintreegateway.com/web/3.102.0/js/hosted-fields.min.js',
    ACCREDITED_MAXIMUM_LISTINGS: 5,
  },
  lanternpay_auth: {
    CLIENT_ID: process.env.REACT_APP_LPAUTH_CLIENT_ID,
    AUTH_ENDPOINT: process.env.REACT_APP_LPAUTH_AUTH_ENDPOINT,
    SIGNUP_ENDPOINT: process.env.REACT_APP_LPAUTH_SIGNUP_ENDPOINT,
  },
  cognito: {
    BASE_URL: process.env.REACT_APP_COGNITO_BASE_URL,
    CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
  stage: process.env.REACT_APP_STAGE,
  link: {
    faq: process.env.REACT_APP_FAQ_URL,
    participantFaq: process.env.REACT_APP_PARTICIPANT_FAQ_URL,
    providerFaq: process.env.REACT_APP_PROVIDER_FAQ_URL,
    endorsementsFaq: process.env.REACT_APP_ENDORSEMENTS_FAQ_URL,
    providerEndorsementFaq: process.env.REACT_APP_PROVIDER_ENDORSEMENT_FAQ_URL,
    connectFaq: process.env.REACT_APP_CONNECT_FAQ_URL,
    providerHelpCenterTab: 'https://community.clickability.com.au/s/?tabset-f5569=2',
    passportWebsite: 'https://www.mypassportapp.com.au/',
    passportPrivacy: 'https://www.mypassportapp.com.au/legal-and-privacy',
    passportHelp: 'https://lanternpay.force.com/passport/s/',
  },
  hotjar: {
    id: parseInt(process.env.REACT_APP_HOTJAR_ID),
    sv: parseInt(process.env.REACT_APP_HOTJAR_SV),
  },
  googleOptimizeExpIds: {
    providerHome: process.env.REACT_APP_GOOGLE_OPTIMIZE_PROVIDER_EXP_ID,
  },
  pusher: {
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  },
};
