import React, { useState } from 'react';

import Button from 'components/Button';
import ListingMatchCard from 'components/listing/ListingMatchCard';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '2rem 1rem',
    marginBottom: '2rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  cards: {
    width: '100%',
    padding: '2rem 0',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  text: {
    width: '100%',
    marginBottom: '1rem',
  },
}));

const ServiceMatchSelector = ({ listings, handleSelection }) => {
  const classes = useStyles();
  const [shortlist, setShortlist] = useState([]);

  const handleShortlistClick = uuid => {
    if (shortlist.includes(uuid)) {
      setShortlist(shortlist.filter(d => d !== uuid));
    } else {
      setShortlist([...shortlist, uuid]);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2" color="primary" gutterBottom>
        Based on your responses, we&apos;ve found the following matches for you.
      </Typography>
      <Typography variant="body2" gutterBottom>
        Please choose up to 3 providers to contact you and arrange an initial consultation by clicking the <b>Shortlist</b> buttons.
      </Typography>
      <div className={classes.cards}>
        {listings.map((d, i) => (
          <ListingMatchCard
            key={i}
            data={d}
            shortlisted={shortlist.includes(d.uuid)}
            handleClick={()=>handleShortlistClick(d.uuid)}
          />
        ))}
      </div>
      <Typography variant="h2" color="primary" align="center" className={classes.text} gutterBottom>
        All done? Press <b>Continue</b> below and we&apos;ll do the rest!
      </Typography>
      <Typography variant="body2" align="center" className={classes.text}>
        Need more options? Click <b>here</b> to let us know.
      </Typography>
      <Button
        label="Continue"
        onClick={()=>handleSelection(shortlist)}
      />
    </div>
  );

};

ServiceMatchSelector.propTypes = {
  listings: PropTypes.array.isRequired,
  handleSelection: PropTypes.func.isRequired,
};

export default ServiceMatchSelector;
