import Footer from 'components/Footer';
import NavMenu from 'components/navmenu';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  content: {
    position: 'relative',
    zIndex: 1,
    padding: 0,
    margin: 0,
  },
});

/*
 * title construction:
 * For SEO purposes the page title will always include "Australian Disability Directory"
 * and Clickability.  We only append Clickability if it is not already present.
 */
const formatTitle = title => {
  const parts = [title, 'Australian Disability Directory'];
  if (title.search(/clickability/i) === -1) {
    parts.push('Clickability');
  }
  return parts.join(' | ');
};

const FormPage = ({children, title}) => {
  const classes = useStyles();

  useEffect(() => {
    if (title) document.title = formatTitle(title);
  }, [title]);

  return (
    <div className={classes.root}>
      <NavMenu hideNav hideAuth aria-label="Header navigation bar and menu" />
      <div className={classes.content}>
        {children}
      </div>
      <Footer aria-label="Footer section and links"/>
    </div>
  );
};

// Must pass a single component. Wrap multiple components in React.Fragment to avoid warnings
FormPage.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default FormPage;
