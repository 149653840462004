import * as track from 'lib/track';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {

  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: '1.4rem',
    marginBottom: '1rem',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  card: {
    margin: 5,
    '& img': {
      maxWidth: 195,
      maxHeight: 130,
      borderRadius: 10,
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        maxHeight: 'none',
      },
    },
    '& :link, & :visited': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    '& :hover': {
      textDecoration: 'underline',
    },
    textAlign: 'center',
    width: 220,
    fontWeight: 410,
  },
}));

const blogPostType = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

const defaultTitle = 'Looking for more information?  Check these out!';

const BlogRelatedList = ({related, title=defaultTitle}) => {
  const classes = useStyles();

  const trackClick = (post) => {
    track.sendEvent('blog related', 'click', {path: post.path});
  };

  const BlogRelatedCard = ({post}) => {
    return (
      <div className={classes.card}>
        <Link to={post.path} onClick={() => trackClick(post)}>
          <img src={post.featured_image} alt={post.title}/>
          <div>
            {post.title}
          </div>
        </Link>
      </div>
    );
  };
  
  BlogRelatedCard.propTypes = {
    post: blogPostType.isRequired,
  };
  
  return (
    <div className={classes.root}>
      <Typography variant="h1" color="primary" className={classes.heading}>
        {title}
      </Typography>
      <div className={classes.cardContainer}>
        {related.map(p => <BlogRelatedCard key={p.uuid} post={p}/>)}
      </div>
    </div>
  );
};

BlogRelatedList.propTypes = {
  related: PropTypes.arrayOf(blogPostType).isRequired,
  title: PropTypes.string,
};
  
export default BlogRelatedList;