import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Button as AntdButton, Input as AntdInput } from 'antd';


import style from 'style';


const Main = styled.div`
  display: flex;
  width: 100%;
`;

const Input = styled(AntdInput)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 40px;
  background-color: #020723;
  border: 1px solid transparent;
  border-top: none;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 39%), 0 -1px 1px #262b4b, 0 1px 0 #2e2e2e;
  color: ${style.colors.palette.white};
  max-width: 350px;
  transition: all 0s;
  &:focus {
    border: 1px solid ${style.colors.secondary};
    border-right-width: 0;
  }
  &:hover {
    border: 1px solid ${style.colors.secondary};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const Button = styled(AntdButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
  min-width: max-content;
`;

const InputWithButton = ({ text, onSubmit, inputProps, buttonProps, className }) => {

  return (
    <Main className={className}>
      <Input {...inputProps} />
      <Button type="primary" onClick={onSubmit} {...buttonProps}>{text}</Button>
    </Main>
  );
};

InputWithButton.propTypes = {
  text: PropTypes.string,
  onSubmit: PropTypes.func,
  inputProps: PropTypes.object,
  buttonProps: PropTypes.object,
  className: PropTypes.string,
};


export default InputWithButton;
