import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';
import { Button, Tag, Tooltip } from 'antd';
import camelcaseKeys from 'camelcase-keys';

import Page from 'providerSite/components/Page';
import Card from 'providerSite/components/Card';
import { ProviderAPI } from 'api';
import { AuthStore } from 'store';


const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .title {
    font-size: 18px;
    font-weight: 500;
  }
`;

const ProductActivation = () => {
  const _profile = useSelector(state => state.auth.profile);
  const profile = _profile ? camelcaseKeys(_profile, {deep: true}) : {};
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  if (!profile?.username) {
    history.replace('/providers/login', {redirectTo: location.pathname + location.search});
  }

  const handleButtonClick = (providerUuid, hasAccess) => {
    if (hasAccess) {
      history.push(`/connect/${providerUuid}/dashboard/`);
      return new Promise(() => {});
    }
    const _data = {has_connect_access: true};
    // Using old update profile API because the way it works. It's complicated to
    // replicate that with hooks.
    return ProviderAPI.updateProfile(providerUuid, _data)
      .then(() => {
        dispatch(AuthStore.refreshProfile());
        toast.success('Successfully activated Clickability Connect');
      });
  };

  useEffect(() => {
    dispatch(AuthStore.refreshProfile());
  }, []);

  return (
    <Page>
      <Container>
        <Card title="Product Activation: Clickability Connect" style={{minWidth: '450px'}}>
          <div className="title">Provider Organisation</div>
          {profile.roles.map((item, index) => (
            <Row
              key={`row-${index}`}
              name={item.providerName}
              uuid={item.providerUuid}
              isAdmin={item.roleNames.includes('Admin')}
              hasAccess={item.hasConnectAccess}
              onClick={handleButtonClick}
            />
          ))}
        </Card>
      </Container>
    </Page>

  );
};

const RowContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin: 10px 0;
  
  .right-container {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    min-width: 210px;
  }
`;

const Row = ({ name, uuid, isAdmin, hasAccess, onClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <RowContainer>
      <div>
        {name}
      </div>
      <div className="right-container">
        <Tooltip title="Status">
          <div>
            <Tag color={hasAccess ? 'green' : 'orange'}>{hasAccess ? 'Activated' : 'Not Activated'}</Tag>
          </div>
        </Tooltip>
        <Tooltip placement="right" title={!isAdmin && 'Only admins can activate Clickability Connect'}>
          <Button
            type="primary"
            disabled={!isAdmin}
            loading={isLoading}
            onClick={() => {
              setIsLoading(true);
              onClick(uuid, hasAccess).finally(() => setIsLoading(false));
            }}>
            {hasAccess ? 'Go To Connect' : isLoading ? 'Activating...' : 'Activate'}
          </Button>
        </Tooltip>
      </div>
    </RowContainer>
  );
};

Row.propTypes = {
  name: PropTypes.string,
  uuid: PropTypes.string,
  isAdmin: PropTypes.bool,
  hasAccess: PropTypes.bool,
  onClick: PropTypes.func,
};


export default ProductActivation;

