import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';

import Loader from 'components/Loader';
import {getDirectoryUrl} from '../../utils';
import stepperTwoImg from 'assets/stepper-two.png';
import iconSpecialisation from 'assets/icon-specialisation.png';
import iconDirectRef from 'assets/icon-direct-ref.png';
import iconThumbUp from 'assets/icon-thumb-up.png';
import Title from '../components/Title';
import SubTitle from '../components/SubTitle';
import InfoBox from '../components/InfoBox';
import FlowButton from '../../components/FlowButton';
import config from 'config';
import { ServiceRequestAPI, useMutateApi } from 'providerSite/api';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    imageRendering: '-webkit-optimize-contrast',
  },
  title: {
    marginTop: '35px',
  },
  subTitle: {
    marginTop: '20px',
    maxWidth: '700px',
  },
  infoContainer: {
    marginTop: '50px',
    display: 'flex',
    columnGap: '30px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: '40px',
    },
  },
  browseDirButton: {
    marginTop: '200px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '100px',
    },
    background: theme.palette.common.darkSlateBlue,
    color: theme.palette.common.white,
    paddingLeft: '10px',
    paddingRight: '10px',
    minWidth: '200px',
    height: '60px',
    '&:hover': {
      background: theme.palette.common.tealish,
      color: theme.palette.common.paleGrey,
    }
  },
  warningText: {
    marginTop: '10px',
  },
}));

const Exit = ({ answers, source }) => {
  const classes = useStyles();

  const history = useHistory();

  const [dirUrl, setDirUrl] = useState();
  const [isDirUrlLoading, setIsDirUrlLoading] = useState(true);

  const incompleteSrDelete = useMutateApi(ServiceRequestAPI.DELETE_INCOMPLETE_SERVICE_REQUEST, 'DELETE', {
    params: {
      sessionId: answers?._sr_session,
    },
  });

  const handleBrowseDirClick = () => source ?
    window.open(`${config.website.URL}/search`, '_self') : history.push(dirUrl);

  useEffect(() => {
    getDirectoryUrl(
      answers._qualifiedServices.unMatchedServices,
      answers.ndisPlan,
      answers.location,
      answers.telehealth).then(res => {
      setDirUrl(res);
      setIsDirUrlLoading(false);
    });
  }, [answers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!answers?._sr_session) return;
    incompleteSrDelete.mutate({});
  }, [answers]);

  if (isDirUrlLoading) return <Loader />;

  return (
    <div className={classes.root}>
      <img src={stepperTwoImg} alt="clicka-agent" width="226px" height="115px" />
      <Title className={classes.title} text="Sorry, we couldn’t connect you with providers" />
      <SubTitle
        text="We unfortunately haven’t been able to connect you directly with a
        provider this time, however we still have a directory with over 3000 providers
        to choose from – and you can use it for free. Click below to get started. "
        className={classes.subTitle}
      />
      <div className={classes.infoContainer}>
        <InfoBox
          icon={iconSpecialisation}
          title="Rated and reliable"
          subTitle="Browse providers that have been rated and reviewed"
        />
        <InfoBox
          icon={iconDirectRef}
          title="Compare and connect"
          subTitle="Filter your search based on your specific needs"
        />
        <InfoBox
          icon={iconThumbUp}
          title="Obligation free"
          subTitle="Contact the providers you choose"
        />
      </div>
      <FlowButton
        label="Browse directory"
        pointer={null}
        onClick={handleBrowseDirClick}
        className={classes.browseDirButton}
      />
      {source && (
        <div className={classes.warningText}>
          This will take you to Clickability website
        </div>
      )}
    </div>
  );
};

Exit.propTypes = {
  answers: PropTypes.object,
  source: PropTypes.string,
};

export default Exit;