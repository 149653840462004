import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import style from 'style';
import Logo from './components/Logo';
import AuthMenu from './components/AuthMenu';
import Menu from './components/Menu';
import MobileMenu from './components/MobileMenu';


const Main = styled.div`
  position: fixed;
  background: ${style.colors.primary};
  height: ${style.constants.navBarHeight};
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  z-index: ${style.zIndexes.navBar};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${style.shadow(35)};
  border-bottom: 2px solid;
  border-bottom-color: ${style.colors.secondary};
`;

const NavContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  @media (max-width: ${style.screens.mobile}) {
    justify-content: center;
  }
`;

const AuthContainer = styled.div`
  margin-left: auto;
  display: flex;
  column-gap: 10px;
  align-items: center;
  height: 100%;
  @media (max-width: ${style.screens.mobile}) {
    width: 15%;
  }
`;

const LogoMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 40px;
  @media (max-width: ${style.screens.mobile}) {
    justify-content: center;
    width: 70%;
  }
`;


const Navbar = ({ userProfile={}, isAuthenticated, plain, secondaryLogo }) => {

  const isProviderAuthenticated = isAuthenticated && !!userProfile.is_provider;
  const isMobile = useMediaQuery({maxWidth: style.screens.mobile});

  return (
    <Main>
      <NavContainer>
        {isMobile && <MobileMenu userProfile={userProfile} />}
        <LogoMenuContainer>
          <Logo secondaryLogo={secondaryLogo} />
          {!plain && !isMobile && <Menu />}
        </LogoMenuContainer>
        <AuthContainer>
          {/*Do not show Auth component when it's mobile and not logged in*/}
          {!plain && !(isMobile && !isProviderAuthenticated) && (
            <AuthMenu
              hideDashboardButton={isMobile}
              userProfile={userProfile}
              isAuthenticated={isProviderAuthenticated}
            />
          )}
        </AuthContainer>
      </NavContainer>
    </Main>
  );
};

Navbar.propTypes = {
  userProfile: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  plain: PropTypes.bool,
  secondaryLogo: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    userProfile: state.auth.profile,
  };
};

export default connect(mapStateToProps)(Navbar);
