import React from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import style from 'style';
import myPlanManagerLogo from 'assets/partners/my-plan-manager.svg';
import aaaLogo from 'assets/partners/aaa.svg';
import hicapsLogo from 'assets/partners/hicaps.svg';
import ndspLogo from 'assets/partners/ndsp.svg';
import youFirstLogo from 'assets/partners/you-first-logo.png';
import mypLogo from 'assets/partners/myp.svg';


const PARTNER_LOGOS = [
  {
    logo: youFirstLogo,
    width: '110px',
    onClick: () => window.open('https://www.youfirstservices.com.au', '_blank'),
  },
  {
    logo: myPlanManagerLogo,
  },
  {
    logo: aaaLogo,
  },
  {
    logo: hicapsLogo,
    width: '145px',
  },
  {
    logo: ndspLogo,
    width: '150px',
  },
  {
    logo: mypLogo,
    width: '114px',
  },
];


const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const InnerContainer = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 25px;
  
  .text {
    text-align: center;
    color: ${style.colors.palette.battleshipGrey};
    font-size: 16px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
`;

const Partners = () => {

  const history = useHistory();

  return (
    <Container>
      <InnerContainer>
        <div className="text">
          Proudly partnered with
        </div>
        <LogoContainer>
          {PARTNER_LOGOS.map((item, idx) => (
            <img
              key={idx}
              height="50px"
              width={item.width && item.width}
              src={item.logo}
              alt="Clickability Partner"
              onClick={() => item.onClick ? item.onClick() : history.push('/about#partners')}
              style={{cursor: 'pointer'}}
            />
          ))}
        </LogoContainer>
      </InnerContainer>
    </Container>
  );
};


export default Partners;

