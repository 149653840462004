import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from '..//SectionTitle.js';
import TreeView from '@material-ui/lab/TreeView';
import { TreeItemStyled, PlusSquare, MinusSquare, CloseSquare } from './TreeItemStyled';
// https://material-ui.com/components/tree-view/

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingTop: '0.5rem',
    paddingBottom: '1rem',
  },
});

const TreeListDisplay = ({title, data}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SectionTitle title={title} />
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
      >
        {data.map(item => {
          return (
            <TreeItemStyled 
              nodeId={item.name} 
              key={item.id} 
              label={(<Typography variant="body2">{item.name}</Typography>)}
              endIcon={<CloseSquare />}
            >
              {item.children.map(child => {
                return (
                  <TreeItemStyled 
                    nodeId={child.name} 
                    key={child.id} 
                    label={(<Typography variant="body2">{child.name}</Typography>)}
                  />
                );}
              )}
            </TreeItemStyled>
          );})}
      </TreeView>
    </div>
  );
};

TreeListDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default TreeListDisplay;