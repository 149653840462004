import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import style from 'style';


const Main = styled.div`
  color: ${style.colors.palette.black};
  width: ${({size}) => style.sizes[size]};
  ${({flex}) => flex && css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  `}
  padding: ${({size}) => size !== 'full' && '0 30px'};
`;

const Container = ({ children, size = 'large', flex, ...props }) => {

  return (
    <Main size={size} flex={flex} {...props}>
      {children}
    </Main>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['full', 'large', 'medium', 'small']),
  flex: PropTypes.bool,
};


export default Container;
