import React from 'react';

import styled from 'styled-components';

import laptopSr from 'assets/laptop-sr.png';


const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  .title {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
  }
`;

const ProviderAsset = () => {

  return (
    <Container>
      <div>
        <img src={laptopSr} width="400px" alt="service-request" />
        <div className="title">
          Easily find and connect with new clients directly
        </div>
      </div>
    </Container>
  );
};


export default ProviderAsset;
