import Container from '@material-ui/core/Container';
import {Helmet} from 'react-helmet';
import Markdown from '../../components/Markdown';
import Page from '../../components/Page.js';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import config from 'config';
import { makeStyles } from '@material-ui/core/styles';
import policyStyle from './components/policyStyle';

const useStyles = makeStyles(policyStyle);

const TermsPage = ({content}) => {
  const classes = useStyles();
  if (!content) return null;
  return (
    <Page title={content.title}>
      <Helmet 
        link = {[{'rel': 'canonical', 'href': `${config.website.URL}${content.path}`}]}
      />
      <Container className={classes.container} fixed disableGutters={true} maxWidth="md">
        <Typography variant="h1" color="primary">{content.title}</Typography>
        <Markdown content={content.body}/>
      </Container>
    </Page>
  );
  
};

TermsPage.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })
};

export default TermsPage;
