import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input as _Input } from 'antd';
import styled from 'styled-components';

import style from 'style';
import Card from '../components/Card';


const Container = styled.div`
  position: relative;
  
  .title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;

const Input = styled(_Input)`
  font-size: 14px;
  line-height: 14px;
  width: 100%;
  &.ant-input {
    border-bottom: 1px solid;
    border-bottom: 1px solid #efefef;
    border-radius: 0;
    padding: 0;
    
    ::placeholder {
      font-size: 14px;
    }
  }
  
  // To stop mobile devices auto zoom while typing inputs
  @media (max-width: ${style.sizes.small}) {
    font-size: 16px;
  }
`;

const ErrorTextContainer = styled.div`
  position: absolute;
  bottom: -40px;
  left: 0;
  font-size: 11px;
  color: ${style.colors.error}
`;

const CharLimitContainer = styled.div`
  position: absolute;
  top: -35px;
  right: -10px;
  font-size: 12px;
`;


const Textbox = (
  {
    title,
    icon,
    defaultData,
    onChange,
    maxCharLimit,
    isValid,
    errorMsg,
    placeholder,
    disabled,
  }) => {

  const [hasError, setHasError] = useState(false);
  const [length, setLength] = useState(defaultData ? defaultData.length : 0);

  const setter = (event) => {
    const val = event.target.value;

    if (isValid && !isValid(val)) {
      setHasError(true);
      onChange(null);
      return;
    }

    setHasError(false);

    if (!maxCharLimit) {
      setLength(val.length);
      onChange(val);
    } else if (val.length <= maxCharLimit) {
      setLength(val.length);
      onChange(val);
    }

  };


  return (
    <Card
      title={title}
      icon={icon}
      error={hasError}
    >
      <Container>
        <Input
          defaultValue={defaultData}
          onChange={setter}
          bordered={false}
          maxLength={maxCharLimit}
          placeholder={placeholder}
          disabled={disabled}
        />
        {hasError && (
          <ErrorTextContainer>
            {errorMsg ? errorMsg : 'Please insert valid information'}
          </ErrorTextContainer>
        )}
        {maxCharLimit && (
          <CharLimitContainer>
            {maxCharLimit - length} Characters remaining
          </CharLimitContainer>
        )}
      </Container>
    </Card>
  );
};

Textbox.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  maxCharLimit: PropTypes.number,
  isValid: PropTypes.func,
  errorMsg: PropTypes.string,
  placeholder: PropTypes.string,
  defaultData: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};


export default Textbox;