import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Divider, Typography } from 'antd';
import styled from 'styled-components';

import ConnectDashboard from '../../../components/ConnectDashboard';
import { useGetApi } from 'providerSite/api';
import { GET_CONNECT_SERVICE_REQUEST } from 'providerSite/api/serviceRequest';
import SpinnerModal from 'components/SpinnerModal';
import style from 'style';
import ProgressBar from './components/ProgressBar';
import RequestDetail from './components/RequestDetail';
import ProvidersInfo from './components/ProvidersInfo';
import BackButton from '../../../components/BackButton';
import QualifiedServiceIcon from 'components/QualifiedServiceIcon';

const PageTitle = styled(Typography.Title)`
  display: flex;
  align-items: center;
  column-gap: 15px;
  .service-inquiry-info {
    color: ${style.colors.secondary};
  }
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    --size: 64px;
    font-size: 38px;
    background: ${style.colors.secondary};
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
  }
  .icon {
    font-size: 24px;
    color: ${style.colors.palette.white};
    filter: drop-shadow(0 0 2px #545456);
  }
`;

const BodyContainer = styled.div`
  display: flex;
  column-gap: 10px;
  
  @media (max-width: ${style.screens.tab}) {
    flex-direction: column;
    row-gap: 15px;
  }
`;

const LeftBodyContainer = styled.div`
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const RightBodyContainer = styled.div`
  flex-basis: 30%
`;

const ServiceRequestDetail = ({ providerUuid, serviceRequestUuid }) => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const {
    isLoading: isServiceRequestDataLoading,
    data: apiServiceRequestData
  } = useGetApi(GET_CONNECT_SERVICE_REQUEST, {
    params: {
      providerUuid,
      serviceRequestUuid
    }
  });

  useEffect(() => {
    if (isServiceRequestDataLoading) {
      setIsPageLoading(true);
    } else {
      setIsPageLoading(false);
    }
  }, [isServiceRequestDataLoading]);

  const renderTitle = serviceRequestData => {
    const { serviceName, serviceInquiry } = serviceRequestData;
    return (
      <PageTitle>
        <div className="circle">
          <QualifiedServiceIcon.Regular className="icon" serviceName={serviceName} />
        </div>
        <div>
          {serviceName} request for<br/>
          <span className="service-inquiry-info">
            {serviceInquiry.participantFirstName} {serviceInquiry.participantLastName},&nbsp;
            {serviceInquiry.state}
          </span>
        </div>
      </PageTitle>
    );
  };

  return (
    <ConnectDashboard>
      <SpinnerModal isLoading={isPageLoading}/>
      <BackButton />
      {apiServiceRequestData && renderTitle(apiServiceRequestData)}
      <Divider />
      {apiServiceRequestData && (
        <BodyContainer>
          <LeftBodyContainer>
            <ProgressBar
              events={apiServiceRequestData.events}
              createdAt={apiServiceRequestData.createdAt}
            />
            <ProvidersInfo
              data={apiServiceRequestData}
              setPageLoading={setIsPageLoading}
            />
          </LeftBodyContainer >
          <RightBodyContainer>
            <RequestDetail
              serviceInquiry={apiServiceRequestData.serviceInquiry}
              participant={apiServiceRequestData.participantClient}
              serviceName={apiServiceRequestData.serviceName}
            />
          </RightBodyContainer>
        </BodyContainer>
      )}
    </ConnectDashboard>
  );
};


ServiceRequestDetail.propTypes = {
  serviceRequestUuid: PropTypes.string,
  providerUuid: PropTypes.string,
};


export default ServiceRequestDetail;
