import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import snakecaseKeys from 'snakecase-keys';
import { makeStyles } from '@material-ui/core/styles';

import * as C from './constants';
import Spinner from '../Spinner';
import SpinnerDone from '../SpinnerDone';
import Lottie from 'components/Lottie';
import rocket from 'assets/rocket.json';
import { ServiceRequestAPI } from 'api';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  text: {
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  header: {
    height: '150px',
  },
}));

const SubmitPage = ({ answers, onChange }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const animationRef = useRef();
  const spinnerDoneRef = useRef();

  const surveyUuid = answers?._surveyUuid;

  const formatAnswers = () => {
    const _result = {...answers};

    _result[C.FIELD_CONTACTED_PROVIDERS] = _result[C.FIELD_CONTACTED_PROVIDERS].selection;

    if (_result[C.FIELD_CHOSEN_PROVIDERS])
      _result[C.FIELD_CHOSEN_PROVIDERS] = _result[C.FIELD_CHOSEN_PROVIDERS].selection;

    ['_providerData', '_submit', '_surveyUuid'].forEach(key => delete _result[key]);

    return _result;
  };

  useEffect(() => {
    setIsLoading(true);
    const data = formatAnswers();

    ServiceRequestAPI.updateSurvey(surveyUuid, snakecaseKeys(data, {deep: true}))
      .catch(err => console.error(err))   // eslint-disable-line no-console
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    onChange(!isLoading);
  }, [isLoading]);

  const getMessage = () => {
    const _contactedSelection = answers[C.FIELD_CONTACTED_PROVIDERS]?.selection;
    if (_contactedSelection?.includes(C.STATIC_CARD_NONE.key)) return MESSAGE['noContact'];

    const _chosenSelection = answers[C.FIELD_CHOSEN_PROVIDERS]?.selection;
    if (_chosenSelection?.includes(C.STATIC_CARD_NONE.key)) return MESSAGE['noChosen'];

    return 'Great, we\'ve heard you';
  };

  const handleComplete = () => {
    if (animationRef.current)
      animationRef.current.style.display = 'block';
    if (spinnerDoneRef.current)
      spinnerDoneRef.current.style.display = 'none';
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {isLoading ? <Spinner/> : (
          <div ref={spinnerDoneRef}>
            <SpinnerDone onComplete={handleComplete}/>
          </div>
        )}
        <div ref={animationRef} style={{display: 'none'}}>
          <Lottie animation={rocket} width="120px" />
        </div>
      </div>
      <div className={classes.text}>
        {getMessage()}
        <div>Thank you for your feedback!</div>
      </div>
    </div>
  );
};

const MESSAGE = {
  noContact: 'We\'re sorry to hear none of the providers contacted you!',
  noChosen: 'We\'re sorry to hear you didn\'t like any of the providers!',
};

SubmitPage.propTypes = {
  answers: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default SubmitPage;