import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';

const HomePage = React.lazy(() => import('./pages/HomePage'));
const DocumentsPage = React.lazy(() => import('./pages/DocumentsPage'));
const SettingsPage = React.lazy(() => import('./pages/SettingsPage'));
const FeedbackPage = React.lazy(() => import('./pages/FeedbackPage'));
const NewsPage = React.lazy(() => import('./pages/NewsPage'));
const ReviewsPage = React.lazy(() => import('./pages/ReviewsPage'));

const HomeRedirect = ({history}) => {
  useEffect(() => {
    history.replace('/profile/home');
  }, [history]);
  return null;
};

HomeRedirect.propTypes = {
  history: PropTypes.any,
};

const routes = () => [
  <Route key="/profile" exact path="/profile" component={HomeRedirect} />,
  <Route key="/profile/home" exact path="/profile/home" component={HomePage} />,
  <Route key="/profile/documents" exact path="/profile/documents" component={DocumentsPage} />,
  // <Route key="/profile/people" exact path="/profile/people" component={BasePage} />,
  // <Route key="/profile/calendar" exact path="/profile/calendar" component={BasePage} />,
  // <Route key="/profile/information" exact path="/profile/information" component={BasePage} />,
  <Route key="/profile/news" exact path="/profile/news" component={NewsPage} />,
  <Route key="/profile/my-reviews" exact path="/profile/my-reviews" component={ReviewsPage} />,
  <Route key="/profile/settings" exact path="/profile/settings" component={SettingsPage} />,
  <Route key="/profile/feedback" exact path="/profile/feedback" component={FeedbackPage} />,
];
export default routes;
