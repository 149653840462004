import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import { AuthAPI, ProviderAPI } from '../../../api';
import { AuthStore } from '../../../store';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import {default as StdButton} from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { prettyLocalDate } from '../../../lib/time';
import * as EmailValidator from 'email-validator';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SecurityIcon from '@material-ui/icons/Security';
import UserRolesDialogue from './UserRolesDialogue';
import { adminRole } from '../../../lib/roles';
import RoleList from './RoleList';
import Modal from 'components/Modal';
import NotificationSettings from './NotificationSettings';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    //maxWidth: '9rem',
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    padding: '0',
  },
  title: {
    marginBottom: '1rem',
  },
  controls: {
    padding: '1rem 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: '1rem',
  },
  alert: {
    width: '100%',
  },
  dialogMessage: {
    minHeight: 50,
  },
  modalPaper: {
    padding: '25px 35px 35px 35px',
    borderRadius: '12px',
    margin: '0 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 25px 25px 25px',
    },
  },
  modalHeader: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  modalTitle: {
    fontSize: '22px',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  modalSubTitle: {
    fontSize: '16px',
    fontWeight: '500',
    color: theme.palette.common.battleshipGrey,
    marginBottom: '15px',
  },
}));

const UserManager = ({providerUuid}) => {

  const dispatch = useDispatch();
  const profile = useSelector(state => state.auth.profile);
  const [users, setUsers] = React.useState([]);
  const [invitations, setInvitations] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState();
  const [email, setEmail] = React.useState('');
  const [busy, setBusy] = React.useState(false);
  const [invitationToRevoke, setInvitationToRevoke] = React.useState();
  const [removeUserOpen, setRemoveUserOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState();
  const [changeRoleOpen, setChangeRoleOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [inviteRoles, setInviteRoles] = React.useState([adminRole]);
  const [invitePermissionDialogOpen, setInvitePermissionDialogOpen] = React.useState(false);
  const [isNotificationConfigOpen, setIsNotificationConfigOpen] = React.useState(false);

  const classes = useStyles();

  const openDialog = () => {
    setDialogMessage(undefined);
    setDialogOpen(true);
  };

  const onInvite = async () => {
    if (email === '') return;
    if (!inviteRoles.length) return;
    if (!EmailValidator.validate(email)) {
      setDialogMessage('That doesn\'t look like a valid email address');
      return;
    }
    setBusy(true);
    try {
      await AuthAPI.providerInvite(email, providerUuid, inviteRoles);
      setDialogOpen(false);
      setEmail('');
      fetchData();
    } catch (e) {
      const msg = (e.body && e.body.message) ? e.body.message : 'Unable to send invitation.';
      setDialogMessage(msg);
    } finally {
      setBusy(false);
    }
  };

  const closeDialog = () => setDialogOpen(false);

  const fetchData = async() => {
    try {
      const response = await ProviderAPI.getUsers(providerUuid);
      setUsers(response.payload.users);
      setInvitations(response.payload.invitations);
      setRoles(response.payload.roles);
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    } finally {
      setLoading(false);
    }
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const openMenu = (event, row) => {
    setSelectedUser(row);
    setMenuAnchorEl(event.currentTarget);
  };

  const confirmRevokeInvitation = (invite) => {
    setInvitationToRevoke(invite);
  };

  const refreshProfile = async() => {
    const response = await AuthAPI.refreshProfile();
    if (response && response.payload) {
      dispatch(AuthStore.profile(response.payload.profile));
    }
  };

  const revokeInvitation = async(invite) => {
    setBusy(true);
    try {
      await AuthAPI.providerRevokeInvitation(invite.token_uuid);
      fetchData();
    } finally {
      setBusy(false);
      setInvitationToRevoke();
    }
  };

  const confirmRevokePermissions = () => {
    closeMenu();
    setRemoveUserOpen(true);
  };

  const revokePermissions = async() => {
    setBusy(true);
    try {
      await ProviderAPI.removeRole(providerUuid, selectedUser.user_uuid);
      fetchData();
    } finally {
      refreshProfile();
      setBusy(false);
      setRemoveUserOpen(false);
    }
  };

  const confirmChangeRole = () => {
    closeMenu();
    setChangeRoleOpen(true);
  };

  const changeRoles = async(roleIds) => {
    setBusy(true);
    setErrorMessage();
    try {
      await ProviderAPI.changeRoles(providerUuid, selectedUser.user_uuid, roleIds);
      fetchData();
    } catch(err) {
      setErrorMessage(err.body.message);
    } finally {
      refreshProfile();
      setBusy(false);
      setChangeRoleOpen(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [providerUuid]);  // eslint-disable-line

  return (
    <div>
      {errorMessage && <Alert severity="error" onClose={()=>setErrorMessage()}>{errorMessage}</Alert>}
      {!loading && users.length>0 && (
        <TableContainer>
          <Table className={classes.table} aria-label="User table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Username</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Roles</StyledTableCell>
                <StyledTableCell>Last Login</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => (
                <TableRow
                  key={row.user_uuid}
                  selected={selectedUser === row}
                >
                  <StyledTableCell>{row.username}</StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell><RoleList roleNames={row.role_names}/></StyledTableCell>
                  <StyledTableCell>{row.user_last_login ? prettyLocalDate(row.user_last_login, 'LLL') : 'never'}</StyledTableCell>
                  <StyledTableCell>{row.user_activated ? 'Active': 'Inactive'}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton color="primary" aria-label="more options" component="span" onClick={(e)=>openMenu(e, row)}>
                      <MoreHorizIcon/>
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!loading && invitations.length>0 && (
        <TableContainer>
          <Table className={classes.table} aria-label="Invitation table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Invitations</StyledTableCell>
                <StyledTableCell>Roles</StyledTableCell>
                <StyledTableCell>Issued</StyledTableCell>
                <StyledTableCell>Expires</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {invitations.map((row) => (
                <TableRow
                  key={row.token_uuid}
                >
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell><RoleList roleNames={row.role_names}/></StyledTableCell>
                  <StyledTableCell>{prettyLocalDate(row.created_at)}</StyledTableCell>
                  <StyledTableCell>{prettyLocalDate(row.expires_at)}</StyledTableCell>
                  <StyledTableCell>{row.expired ? 'Expired' : 'Pending'}</StyledTableCell>
                  <StyledTableCell>{
                    <IconButton color="primary" aria-label="delete invitation" component="span" onClick={()=>confirmRevokeInvitation(row)}>
                      <DeleteIcon/>
                    </IconButton>}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className={classes.controls}>
        <Button
          variant="contained"
          color="primary"
          onClick={openDialog}
          label="Invite User"
          className={classes.button}
          pointer="plus"
        />
      </div>

      <Dialog open={dialogOpen} onClose={closeDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Invite More Users</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To invite another user to join this organisation, enter their email address below.
            We will send them an email inviting them to join this organisation on Clickability.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            value={email}
            onChange={e => {setEmail(e.target.value); setDialogMessage();}}
            onKeyUp={e => e.keyCode === 13 && onInvite()}
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          />
          <DialogContentText>
            Select the initial roles for the new user.  You can change these at any time after the user has activated.
          </DialogContentText>
          <RoleList editable={true} allRoles={roles} roleNames={inviteRoles} onChange={setInviteRoles}/>
        </DialogContent>
        <div className={classes.dialogMessage}>
          {dialogMessage && <Alert severity="warning">{dialogMessage}</Alert>}
        </div>
        <DialogActions>
          <StdButton onClick={closeDialog} color="primary">
            Cancel
          </StdButton>
          <Button onClick={onInvite} color="primary" variant="contained" label="Invite" className={classes.button} busy={busy} disabled={email==='' || !inviteRoles.length}/>
        </DialogActions>
      </Dialog>

      <Menu
        id="long-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={menuAnchorEl !== null}
        onClose={closeMenu}
      >
        <MenuItem onClick={confirmChangeRole}>
          <ListItemIcon>
            <SecurityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Change Roles" />
        </MenuItem>
        <MenuItem onClick={confirmRevokePermissions}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Remove User" />
        </MenuItem>
        {(profile?.user_uuid !== selectedUser?.user_uuid) && profile?.is_site_admin && (
          <MenuItem onClick={() => setIsNotificationConfigOpen(true)}>
            <ListItemIcon>
              <NotificationsActive fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary="Notification Settings"/>
          </MenuItem>
        )}
      </Menu>

      { invitationToRevoke && (
        <Dialog open={true} onClose={() => setInvitationToRevoke()} aria-labelledby="form2-dialog-title">
          <DialogTitle id="form2-dialog-title">Revoke Invitation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to revoke the invitation to {invitationToRevoke.email}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <StdButton onClick={() => setInvitationToRevoke()} color="primary">
              Cancel
            </StdButton>
            <Button onClick={() => revokeInvitation(invitationToRevoke)} color="primary" variant="contained" label="Revoke" className={classes.button} busy={busy}/>
          </DialogActions>
        </Dialog>
      )}

      { removeUserOpen && (
        <Dialog open={true} onClose={() => setRemoveUserOpen(false)} aria-labelledby="form3-dialog-title">
          <DialogTitle id="form3-dialog-title">Remove User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove all permissions to this organisation for {selectedUser.email}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <StdButton onClick={() => setRemoveUserOpen(false)} color="primary">
              Cancel
            </StdButton>
            <Button onClick={revokePermissions} color="primary" variant="contained" label="Revoke" className={classes.button} busy={busy}/>
          </DialogActions>
        </Dialog>
      )}

      { changeRoleOpen &&
        <UserRolesDialogue
          initialRoles={selectedUser.role_names}
          isSelf={selectedUser.user_uuid === profile.user_uuid}
          allRoles={roles}
          subtitle={`for ${selectedUser.email}`}
          busy={busy}
          onChange={(roles) => changeRoles(roles)}
          onCancel={() => setChangeRoleOpen(false)}
        />
      }

      { invitePermissionDialogOpen &&
        <UserRolesDialogue
          initialRoles={inviteRoles}
          allRoles={roles}
          subtitle={'for invited user'}
          onChange={(roles) => {setInvitePermissionDialogOpen(false); setInviteRoles(roles);}}
          onCancel={() => setInvitePermissionDialogOpen(false)}
        />
      }
      <Modal
        paperClassName={classes.modalPaper}
        isOpen={isNotificationConfigOpen}
        onClose={() => {
          setIsNotificationConfigOpen(false);
          closeMenu();
        }}
      >
        <div className={classes.modalHeader}>
          <div className={classes.modalTitle}>
            Notification Settings
          </div>
          <div className={classes.modalSubTitle}>
            {selectedUser?.username} - {selectedUser?.email}
          </div>
        </div>
        <NotificationSettings providerUuid={providerUuid} userUuid={selectedUser?.user_uuid} />
      </Modal>
    </div>
  );
};

UserManager.propTypes = {
  providerUuid: PropTypes.string.isRequired,
};

export default UserManager;
