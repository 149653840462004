import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';

import Spinner from './Spinner';
import Modal from './Modal';

const useStyles = makeStyles( {
  modalBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  modalPaper: {
    borderRadius: '12px',
    maxWidth: '600px',
    boxShadow: 'none',
    background: 'none',
  },
});

const SpinnerModal = ({isLoading}) => {

  const classes = useStyles();

  return (
    <Modal
      isOpen={isLoading}
      backdropClassName={classes.modalBackdrop}
      paperClassName={classes.modalPaper}
      onClick={e => e.stopPropagation()}
    >
      <Spinner />
    </Modal>
  );
};

SpinnerModal.propTypes = {
  isLoading: PropTypes.bool,
};

export default SpinnerModal;