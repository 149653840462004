import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SectionTitle from '../../../components/SectionTitle.js';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import * as track from '../../../lib/track';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1rem 0',
  },
  holder: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '0.5em',
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: '1rem',
  },
}));

const SocialLinks = ({data}) => {
  const classes = useStyles();
  const schemifyUrl = url => (url.match('^https?://')) ? url : `https://${url}`;
  const providerUuid = 'title' in data ? data.provider_uuid : data.uuid;
  const handleClick = (item) => {
    track.sendEvent(`provider ${item} link`, 'click', {}, [providerUuid]);
  };
  return (
    <div className={classes.root}>
      <SectionTitle title="Social media" />
      <div className={classes.holder}>
        {data.facebook_url === null ? null : (
          <a href={schemifyUrl(data.facebook_url)} target="_blank" rel="noopener noreferrer" className={classes.link} onClick={()=>handleClick('facebook')}>
            <FacebookIcon className={classes.icon} fontSize="large"/>
          </a>
        )}
        {data.twitter_url === null ? null : (
          <a href={schemifyUrl(data.twitter_url)} target="_blank" rel="noopener noreferrer" className={classes.link} onClick={()=>handleClick('twitter')}>
            <TwitterIcon className={classes.icon} fontSize="large"/>
          </a>
        )}
        {data.instagram_url === null ? null : (
          <a href={schemifyUrl(data.instagram_url)} target="_blank" rel="noopener noreferrer" className={classes.link} onClick={()=>handleClick('instagram')}>
            <InstagramIcon className={classes.icon} fontSize="large"/>
          </a>
        )}
        {data.linkedin_url && (
          <a
            href={schemifyUrl(data.linkedin_url)}
            target="_blank" rel="noopener noreferrer"
            className={classes.link}
            onClick={()=>handleClick('linkedin')}
          >
            <LinkedInIcon className={classes.icon} fontSize="large"/>
          </a>
        )}
      </div>
    </div>
  );
};

SocialLinks.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SocialLinks;
