import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

const panelItemStyles = makeStyles(theme => ({
  panel: {
    borderRadius: '12px',
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    '& .MuiPaper-root': {
      border: 'none',
    },
    '& .Mui-expanded': {
      backgroundColor: theme.palette.common.white,
      border: 'none',
    },
  },
  roundedExpansionPanel: {
    '&:last-child': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    }
  },
  summary: {
    borderRadius: '12px',
    padding: '5px 15px 5px 10px',
    backgroundColor: theme.palette.common.white,
    border: 'none',
    '&:focus-within': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiExpansionPanelSummary-content': {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: 0,
    },
  },
  details: {
    padding: '5px',
  },
}));

const FilterPanelItem = ({title, icon, children}) => {
  const classes = panelItemStyles();
  const label = `search filter ${title}`;
  return (
    <ExpansionPanel className={classes.panel} classes={{rounded: classes.roundedExpansionPanel}}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label={label}
        className={classes.summary}
      >
        {icon}
        <Typography variant="subtitle2" color="primary">
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        className={classes.details}
      >
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

FilterPanelItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.object.isRequired,
};

export default FilterPanelItem;
