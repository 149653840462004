import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  row: {
    //marginTop: '1rem',
  },
  label: {
    marginTop: '2.2rem',
  }
}));

export const ConfigPanelRow = ({label, children}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.row}>
      <Grid xs={12} sm={4} item container direction="row">
        <Typography variant="h3" color="textPrimary" className={classes.label}>
          {label}
        </Typography>
      </Grid>
      <Grid xs={12} sm={8} item container direction="row">
        {children}
      </Grid>
    </Grid>
  );
};

ConfigPanelRow.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

const ConfigPanel = ({children, title, ...props}) => {
  return (
    <ExpansionPanel {...props}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="h3" color="textSecondary">
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ConfigPanel.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
export default ConfigPanel;
