import React from 'react';
import PropTypes from 'prop-types';

import Lottie from 'components/Lottie';
import spinnerDone from 'assets/spinner-done.json';


const SpinnerDone = ({ size='110px', onComplete }) => {

  const options = {
    loop: false,
    initialSegment: [1, 60],
    onComplete: onComplete,
  };

  return <Lottie animation={spinnerDone} height={size} width={size} options={options} />;

};

SpinnerDone.propTypes = {
  size: PropTypes.string,
  onComplete: PropTypes.func,
};

export default SpinnerDone;