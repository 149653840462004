import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  faSignature,
  faUser,
  faLocationDot,
  faClock,
} from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-hot-toast';
import { Avatar } from 'antd';

import Table from 'providerSite/components/Table';
import { useGetApi, ConnectAPI } from 'providerSite/api';
import DateTimeStatus from 'components/DateTimeStatus';
import AddButton from '../../../components/AddButton';
import Header from '../../../components/Header';
import { NEW_PARTICIPANT_KEY } from '../constants';
import { VERIFICATION_CREATE_PARTICIPANTS_ERROR } from '../../../components/constants';



const ParticipantList = ({ providerUuid, onSelect }) => {

  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState();
  const profile = useSelector(state => state.auth.profile);

  const { data, isLoading } = useGetApi(ConnectAPI.GET_PARTICIPANTS, {
    params: {
      providerUuid: providerUuid,
    },
    queryParams: {
      page: page,
      search: searchText,
      page_size: 10,
    }
  });

  const handlePaginationClick = page => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setPage(page);
  };

  return (
    <>
      <Header text="Participants">
        <AddButton text="New Participant" onClick={() => {
          if (profile?.activated){
            onSelect(NEW_PARTICIPANT_KEY);
          } else {
            toast.error(VERIFICATION_CREATE_PARTICIPANTS_ERROR);
          }
        }} />
      </Header>
      <Table
        columns={columns}
        data={data?.data}
        metaData={data?.metaData}
        isLoading={isLoading}
        onPaginationClick={handlePaginationClick}
        onRowClick={row => onSelect(row.uuid)}
        showSearchBar={true}
        onSearch={text => {
          setSearchText(text);
          setPage(1);
        }}
        searchPlaceHolder="Search name, location or phone"
      />
    </>
  );
};


const columns = [
  {
    dataIndex: 'avatar',
    width: '10%',
    render: ({ firstName, lastName }) => (
      <Avatar
        style={{backgroundColor: '#87d068'}}
      >
        {`${firstName[0]}${lastName[0]}`}
      </Avatar>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: '25%',
    icon: faSignature,
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    width: '20%',
    icon: faUser,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    width: '32%',
    icon: faLocationDot,
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    icon: faClock,
    render: ({ createdAt }) => <DateTimeStatus value={createdAt} />,
  },
];


ParticipantList.propTypes = {
  providerUuid: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};


export default ParticipantList;
