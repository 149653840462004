import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import style from 'style';
import Divider from '../../../../components/Divider';


const Container = styled.div`
  border: 1px solid ${style.colors.lightBorder};
  padding: 25px 40px;
  border-radius: 30px;
  box-shadow: ${style.shadow(15)};
  background: white;
  flex-shrink: 3;

  .title {
    font-size: 32px;
    font-weight: 700;
    color: black;
  }

  .subtitle {
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 20px;
    color: black;
  }

  .number {
    color: ${style.colors.secondary};
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
  }

  font-size: 14px;
  color: #7d7d7d;
`;

const HowItWorksCard = ({ name, providerCount, tip, ...props }) => {

  return (
    <Container {...props}>
      <div className="title">
        You can relax now, <span style={{color: style.colors.secondary}}>{name}</span>
      </div>
      <div className="subtitle">
        We understand that finding the right provider can be time-consuming and frustrating. Let us handle the
        legwork for you.
      </div>
      <div>
        Instead of you contacting the <span className="number">{providerCount}</span> providers
        you found, our system will confirm their ability to meet your needs and availability. Meanwhile, you can
        relax. We&apos;ll then present up to <span
          className="number">{providerCount < 3 ? providerCount : 3}</span> suitable
        providers, and you make the final
        decision.
      </div>
      {!!tip && (
        <>
          <Divider name="Pro Tip" style={{margin: '12px 0'}}/>
          <div>
            While we are matching the right providers for you, please consider answering these additional questions to
            help us find even better matches. These questions are optional.
          </div>
        </>
      )}

    </Container>
  );
};

HowItWorksCard.propTypes = {
  name: PropTypes.string,
  providerCount: PropTypes.number,
  tip: PropTypes.bool,
};


export default HowItWorksCard;

