import React from 'react';
import PropTypes from 'prop-types';

import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const WrongUser = ({ onClose, action }) => {

  const history = useHistory();

  return (
    <Result
      status="warning"
      title={<>Only Participants can submit {action}s!</>}
      subTitle="You are logged in as a Provider. Clickability does not allow
      providers to review or endorse their own, or other providers, services.
      If you are also a NDIS participant, please log in with your Participant account."
      extra={(
        <>
          <Button type="primary" ghost onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={() => history.push('/logout')}>Logout</Button>
        </>
      )}
    />
  );
};

WrongUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
};

export default WrongUser;
