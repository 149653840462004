import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiRating-sizeLarge': {
      fontSize: '45px',
    },
    width: '600px',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
    }
  },
});

const TextQuestion = ({ text, value, onChange, className }) => {

  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <TextField
        className={classes.textField}
        label={text}
        multiline
        fullWidth
        maxRows={4}
        value={value}
        onChange={e => onChange(e.target.value)}
        variant="outlined"
      />
    </div>
  );
};

TextQuestion.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default TextQuestion;
