const RESET = 'serviceRequest/RESET';
const SET_ITEM = 'serviceRequest/SET_ITEM';

const initialState = {
  request_type: '',
  participant_type: '',
  management_type: '',
  age_group: '',
  location: '',
  considerations: [],
  other: '',
  services: [],
  contact: {
    'first_name': '',
    'last_name': '',
    'phone': '',
    'email': '',
  },
  account_uuid: null,
  provider_uuid: null,
  provider_name: null,
  listing_uuid: null,
  listing_title: null,
  slug: null,
};

// Action Creators

export const reset = () => {
  return {
    type: RESET,
  };
};

export const setItem = (item, data) => {
  return {
    type: SET_ITEM,
    item: item,
    data: data,
  };
};

// Reducer

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {

  case RESET:
    return {
      ...initialState,
    };

  case SET_ITEM:
    return {
      ...state,
      [action.item]: action.data,
    };

  default:
    return state;
  }
};

export default reducer;
