import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '4rem 0',
  },
  left: {
    paddingRight: '3rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
      width: '95vw',
    },
  },
  right: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
      width: '95vw',
    },
  },
}));

const LoadingSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg" fixed disableGutters={true}>
        <Grid container spacing={6}>
          <Grid item md={8}>
            <div className={classes.left}>
              <Skeleton variant='rect' animation="wave" height={300} />
              <Skeleton animation="wave" height={150}/>
              <Skeleton variant='rect' animation="wave" height={300}/>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className={classes.right}>
              <Skeleton variant='rect' animation="wave" height={100}/>
              <Skeleton animation="wave" height={100}/>
              <Skeleton animation="wave" height={100}/>
              <Skeleton animation="wave" height={100}/>
              <Skeleton animation="wave" height={100}/>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default LoadingSkeleton;
