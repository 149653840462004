import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Lottie from 'lottie-react';
import { Button as AntdButton } from 'antd';

import animationAsset from 'assets/panicking-woman.json';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 20px;
  user-select: none;
  
  .lottie-container {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
    overflow: hidden;
  }
  
  .title {
    font-size: 18px;
    line-height: 22px;
    color: black;
    font-weight: 500;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
  }
  
  .subtitle {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
  }
  
  .button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
`;

const Button = styled(AntdButton)`
  height: 35px;
  border-radius: 12px;
  font-size: 13px;
`;

const NotAvailable = ({ name, onPrimaryButtonClick, onSecondaryButtonClick, hidePrimaryButton }) => {

  return (
    <Container>
      <div className="lottie-container">
        <Lottie animationData={animationAsset} loop/>
      </div>
      {!!name && (
        <div className="title" style={{marginBottom: '-10px'}}>
          Sorry, {name}!
        </div>
      )}
      <div className="title">
        Unfortunately, we are unable to locate any providers who can meet your
        current requirements.
      </div>
      <div className="subtitle">
        Please consider <u>modifying</u> your requirements or <u>browse</u> our
        directory of <b>over 3,700</b> providers to find a suitable match.
      </div>
      <div className="button-container">
        {!hidePrimaryButton && (
          <Button type="primary" onClick={onPrimaryButtonClick}>Update Requirements</Button>
        )}
        <Button onClick={onSecondaryButtonClick}>Browse Directory</Button>
      </div>
    </Container>
  );
};

NotAvailable.propTypes = {
  name: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  hidePrimaryButton: PropTypes.bool,
};


export default NotAvailable;

