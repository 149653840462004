import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';
import Container from 'providerSite/components/Container';
import Text from 'providerSite/components/Text';


const Main = styled.div`
  padding: 80px 0 60px 0;
  display: flex;
  justify-content: center;
  .icon {
    font-size: 48px;
    color: ${style.colors.secondary};
    margin-top: 30px;
    cursor: pointer;
    &:hover {
      color: ${style.colors.primary};
    }
  }
  @media (max-width: ${style.screens.tab}) {
    padding: 50px 0 30px 0;
  }
`;

const SectionOne = ({ title, text, onClick }) => {

  return (
    <Main>
      <Container size="medium" flex>
        <Typography.Title level={2} style={{color: style.colors.secondary}}>
          {title}
        </Typography.Title>
        <Text style={{maxWidth: style.sizes.small}}>
          {text}
        </Text>
        <FontAwesomeIcon
          icon={faAngleDown}
          className="icon"
          bounce style={{animationDuration: '1.5s'}}
          onClick={onClick}
        />
      </Container>
    </Main>
  );
};

SectionOne.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};


export default SectionOne;
