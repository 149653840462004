import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Confirmation from 'components/Confirmation';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';

const styles = makeStyles(theme => {
  return {
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    button:{
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      '&:hover':{
        borderColor: theme.palette.error.dark
      },
      '&:disabled':{
        borderColor: theme.palette.error.light
      }
    }
  };
});

const conciseWordList = (list, maxLen=3) => {
  if (!list || list.length === 0) return '';  // unsupported cases
  if (list.length === 1) {
    return list[0];
  }
  if (list.length > maxLen) {
    return list.slice(0, maxLen).join(', ') + ' and ' + (list.length-maxLen) + ' more';
  } else {
    return list.slice(0, list.length - 1).join(', ') + ' and ' + list[list.length - 1];
  }
};

const endorsementSummary = (specialisation, endorsements) => {
  if (endorsements === undefined) {
    return (
      <React.Fragment>
        <p>
          You don&apos;t have any endorsements yet for {specialisation.name}.
        </p>
        <p>
          To make this specialisation visible in your profile, ask your clients to endorse you for this specialisation.
        </p>
      </React.Fragment>
    );
  }
  return (
    <div>
      You have been endorsed for {specialisation.name} by {conciseWordList(endorsements, 3)}.
    </div>
  );
};

const DeletePanel = ({specialisation, endorsements, onDelete, onCancel}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [busy, setBusy] = useState(false);

  const classes = styles();
  if (!showDeleteConfirmation) {
    return (
      <Dialog open={true} onClose={onCancel} aria-labelledby="form2-dialog-title">
        <DialogTitle>
          Specialisation in {specialisation.name}
          <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {endorsementSummary(specialisation, endorsements)}
          <p>If you no longer wish to list <b>{specialisation.name}</b> as a specialisation, you can remove it here.</p>
        </DialogContent>
        <DialogActions>

          <Button 
            onClick={() => setShowDeleteConfirmation(true)}
            variant="outlined"
            color="primary"
            endIcon={<DeleteIcon />}
          >
            Remove
          </Button>
          <Button 
            onClick={onCancel}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Confirmation
        title = "Remove Specialisation"
        message = {`Are you sure you want to remove ${specialisation.name} as a specialisation?`}
        action = "Remove"
        onConfirm = {() => {setBusy(true); onDelete(specialisation); setShowDeleteConfirmation(false); setBusy(false);}}
        onCancel = {() => setShowDeleteConfirmation(false)}
        busy = {busy}
      />
    );
  }
};

DeletePanel.propTypes = {
  specialisation: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  endorsements: PropTypes.array,
};

export default DeletePanel;