export const FIELD_FIRST_NAME = 'firstName';
export const FIELD_LAST_NAME = 'lastName';
export const FIELD_LOCATION = 'location';
export const FIELD_EMAIL = 'email';
export const FIELD_PHONE = 'phone';
export const FIELD_AGE_GROUP = 'ageGroup';
export const FIELD_GENDER = 'gender';
export const FIELD_LANGUAGES = 'languages';
export const FIELD_INTERPRETER_REQUIRED = 'interpreterRequired';
export const FIELD_COMMUNICATION_AIDS = 'communicationAids';
export const FIELD_PLAN_TYPE = 'planType';
export const FIELD_CONTACT_PARTICIPANT = 'contactParticipant';
export const FIELD_TELEHEALTH = 'telehealth';
export const FIELD_ONLINE_SERVICE = 'onlineService';
export const FIELD_PLAN_START_DATE = 'planStartDate';
export const FIELD_PLAN_DURATION = 'planDuration';
export const FIELD_EXTRA_DATA = 'data';
