import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { Tag } from 'antd';
import { faLanguage } from '@fortawesome/pro-light-svg-icons';

import Card from './Card';


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Languages = ({ data, isLoading }) => {

  return (
    <Card title="Languages" icon={faLanguage} isLoading={isLoading} noData={_.isEmpty(data)}>
      <Container>
        {data?.map((item, index) => (
          <Tag key={index} style={{margin: 0}}>{item}</Tag>
        ))}
      </Container>
    </Card>
  );
};

Languages.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default Languages;
