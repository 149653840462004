import { request, makePath } from './http';
const API_NAME = 'cms';


export function getBlogPage(path) {
  return request('GET', makePath(API_NAME, 'blog/page'), {params: {path}});
}

export function getBlogRecent(limit=10, offset=0) {
  return request('GET', makePath(API_NAME, 'blog/recent'), {params:{limit, offset}});
}

export function getBlogSearch(query, tags, limit=10, offset=0) {
  return request('GET', makePath(API_NAME, 'blog/search'), {params:{query, tags, limit, offset}});
}

export function getPage(path, pageType) {
  return request('GET', makePath(API_NAME, 'page'), {params: {path, page_type: pageType}});
}
