import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const ReviewSnippets = ({ data, reviews, isLoading }) => {
  if (isLoading || (data && data.reviewCount <= 0)) {
    return null;
  }

  const description = (
    (data.fullDescriptionConverted && data.fullDescriptionConverted.length) ?
      data.fullDescriptionConverted.slice(0, 250) : null
  );

  const reviewJSONLD = (review) => {
    return {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: review.authorUsername,
      },
      datePublished: review.publishedAt,
      reviewBody: review.content,
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: review.rating,
        worstRating: '1',
      },
    };
  };

  const dataJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: data.name,
    image: data.imagePath,
    description: description,
    brand: data.name,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: data.rating,
      reviewCount: data.reviewCount,
      description: description,
      name: data.name,
    },
    review: reviews?.length ? [reviews.map((r) => reviewJSONLD(r))] : null,
  };

  return (
    <Helmet>
      <script className="structured-data-list" type="application/ld+json">
        {JSON.stringify(dataJSONLD)}
      </script>
    </Helmet>
  );
};

ReviewSnippets.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  reviews: PropTypes.array,
};

export default ReviewSnippets;
