import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Card, { CardTitle } from 'providerSite/components/Card';
import styled from 'styled-components';
import { Typography } from 'antd';

import ProviderDetail from './ProviderDetail';
import style from 'style';
import Dot from 'components/ServiceRequestStatus/Dot';
import * as constants from 'components/constants';
import { getHexWithOpacity } from 'lib/util';
import { ConnectAPI, useGetApi } from 'providerSite/api';


const ProviderTabsContainer = styled.div`
  display: flex;
  column-gap: 12px;
  width: 100%;
  margin-bottom: 10px;
`;

const TabContainer = styled(Card)`
  position: relative;
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2px;
  background-color: ${({selected}) => selected && getHexWithOpacity(style.colors.secondary, 7)};
  padding: 12px 20px;
  cursor: pointer;
  border-color: ${({selected}) => selected && style.colors.secondary};
  :hover {
    background-color: ${getHexWithOpacity(style.colors.secondary, 7)};
    border-color: ${style.colors.secondary};
  }
  
  .tab-title {
    font-size: 16px;
    margin: 0;
  }
  
  .tab-status {
    font-size: 12px;
    opacity: 0.45;
  }
`;

const PulseContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
`;

const STATUS_IN_PROGRESS = 'IN PROGRESS';
const STATUS_COMPLETE = 'COMPLETE';
const STATUS_CLOSED = 'CLOSED';

const statusMap = {
  [constants.SERVICE_REQUEST_STATUS_CONTACTED] : STATUS_IN_PROGRESS,
  [constants.SERVICE_REQUEST_STATUS_IN_PROGRESS] : STATUS_IN_PROGRESS,
  [constants.SERVICE_REQUEST_STATUS_SUCCESSFUL] : STATUS_COMPLETE,
  [constants.SERVICE_REQUEST_STATUS_UNSUCCESSFUL] : STATUS_CLOSED
};

const Tabs = ({ tabData, onTabClick, currentProvider, notifications }) => {

  return (
    <>
      {tabData.map((data, index) => {
        const status = Object.hasOwnProperty.call(statusMap, data.status) ?
          statusMap[data.status] : data.status.toUpperCase();
        const providerUuid = data.providerUuid;
        return (
          <TabContainer
            key={index}
            onClick={() => onTabClick(providerUuid)}
            selected={currentProvider === providerUuid}
          >
            <Typography.Title className="tab-title">Provider {index + 1}</Typography.Title>
            <Typography.Text className="tab-status">{status}</Typography.Text>
            {notifications.has(providerUuid) && (
              <PulseContainer><Dot color={style.colors.error}/></PulseContainer>
            )}
          </TabContainer>
        );
      })}
    </>);
};


Tabs.propTypes = {
  tabData: PropTypes.array,
  onTabClick: PropTypes.func,
  currentProvider: PropTypes.string,
  notifications: PropTypes.instanceOf(Set).isRequired,
};

const ProvidersInfo = ({ data, setPageLoading }) => {
  const { serviceRequestMatches, uuid } = data;

  const [currentProviderUuid, setCurrentProviderUuid] = useState(
    serviceRequestMatches.length > 0 ? serviceRequestMatches[0].providerUuid : null
  );

  const queryClient = useQueryClient();
  const { uuid: providerUuid } = useParams();
  const [ notifications, setNotifications ] = useState(new Set([]));
  const [ notificationMap, setNotificationMap ] = useState({});
  const { data: notificationData } = useGetApi(ConnectAPI.GET_NOTIFICATIONS, {
    params: {
      providerUuid: providerUuid,
    },
    queryParams: {
      withDetails: true,
      serviceRequest: uuid,
    },
  });

  useEffect(() => {
    if (!notificationData) return;

    const { _map, _set } = notificationData.reduce((acc, item) => {
      acc._set.add(item.provider);
      acc._map[item.provider] = item;
      return acc;
    }, {_map: {}, _set: new Set([])});

    setNotifications(_set);
    setNotificationMap(_map);
  }, [notificationData]);

  return (
    <div>
      <CardTitle>Providers</CardTitle>
      {currentProviderUuid ? (
        <>
          <ProviderTabsContainer>
            <Tabs
              tabData={serviceRequestMatches}
              currentProvider={currentProviderUuid}
              onTabClick={val => {
                queryClient.invalidateQueries(
                  [
                    ConnectAPI.GET_NOTIFICATIONS.replace(':providerUuid', providerUuid)
                  ],
                  {
                    refetchType: 'active'
                  }).finally(() => setCurrentProviderUuid(val));
              }}
              notifications={notifications}
            />
          </ProviderTabsContainer>
          <ProviderDetail
            setPageLoading={setPageLoading}
            providerUuid={currentProviderUuid}
            serviceRequestUuid={uuid}
            status={serviceRequestMatches.filter(
              (match) => match.providerUuid === currentProviderUuid)[0]?.status}
            notification={notificationMap[currentProviderUuid]}
          />
        </>
      ) : (
        <Card>
            Providers have not yet accepted this request
        </Card>
      )}
    </div>
  );
};

ProvidersInfo.propTypes = {
  data: PropTypes.object,
  setPageLoading: PropTypes.func,
};

export default ProvidersInfo;