import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import TagCloud from 'TagCloud';


const Container = styled.div`
  position: relative;
  background: #1b5f6a;
  border-radius: 50%;
  height: 350px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 0 0.75rem #56b9a5);
  overflow: hidden;
`;

const TextCloud = ({ texts }) => {

  const container = '.provider-cloud';
  const options = {
    radius: 170,
    // animation speed
    // slow, normal, fast
    maxSpeed: 'fast',
    initSpeed: 'fast',
    // 0 = top
    // 90 = left
    // 135 = right-bottom
    direction: 90,
    // interact with cursor move on mouse out
    keep: false
  };

  useEffect(() => {
    TagCloud(container, texts, options);
  }, []);

  return (
    <Container>
      <span className="provider-cloud"></span>
    </Container>
  );
};

TextCloud.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string),
};


export default TextCloud;

