import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';

import Question from './Question';
import {ERROR_CONTAINER_HEIGHT, QUESTION_BORDER_COLOR} from '../../../constants';


const useStyles = makeStyles(theme => ({
  root: {
  },
  buttonContainer : {
    marginTop: '15px',
    display: 'flex',
    columnGap: '20px',
  },
  button: {
    height: '55px',
    minWidth: '150px',
    maxWidth: '200px',
    width: '100%',
    background: theme.palette.common.paleGrey,
    border: '1px solid',
    borderColor: QUESTION_BORDER_COLOR,
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.common.darkSlateBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: '0',
      border: 'none',
      boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}`,
    },
  },
  selected: {
    background: theme.palette.common.darkSlateBlue,
    color: theme.palette.common.white,
  },
  errorContainer: {
    height: `calc(${ERROR_CONTAINER_HEIGHT} - 3px)`,
    fontSize: '13px',
    color: theme.palette.error.main,
    marginLeft: '15px',
    marginTop: '3px',
  },
}));

const YesNoQuestion = (
  {
    text,
    helperText,
    onChange,
    value: defaultValue,
    isOptional,
    answers,
  }) => {

  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState(defaultValue ? defaultValue : null);

  useEffect(() => {
    selectedValue !== null && onChange(selectedValue);
  }, [selectedValue]);  // eslint-disable-line react-hooks/exhaustive-deps

  const renderButton = (text) => {
    const style = selectedValue === text ? classNames(classes.button, classes.selected) : classes.button;
    const handleClick = text => {
      setSelectedValue(prevState => {
        if (prevState === text)
          return '';
        return text;
      });
    };

    return (
      <div className={style} tabIndex="0" onClick={() => handleClick(text)} >{text}</div>
    );
  };

  return (
    <div className={classes.root}>
      <Question title={text} subTitle={helperText} isOptional={isOptional} answers={answers}>
        <div className={classes.buttonContainer}>
          {renderButton('Yes')}
          {renderButton('No')}
        </div>
        <div className={classes.errorContainer}>
          {selectedValue === '' && 'Please select an option'}
        </div>
      </Question>
    </div>
  );
};

YesNoQuestion.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  isOptional: PropTypes.func,
  answers: PropTypes.object,
};


export default YesNoQuestion;