import * as Validate from 'components/Validate';

import React, { useState } from 'react';

import Alert from '@material-ui/lab/Alert';
import { AuthAPI } from '../../../api';
import AuthField from './AuthField';
import AuthForm from './AuthForm';
import LocationInput from 'components/input/LocationInput';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: theme.palette.common.paleGrey,
  },
}));

const DEFAULT_ERROR_MESSAGE = 'An error occurred creating your account.  Please try again later.';

const ActivateForm = ({code, onComplete}) => {
  const [password, setPassword] = useState('');
  const [passwordWarning, setPasswordWarning] = useState();
  const [password2, setPassword2] = useState('');
  const [password2Warning, setPassword2Warning] = useState();
  const [firstName, setFirstName] = useState('');
  const [firstNameWarning, setFirstNameWarning] = useState();
  const [lastName, setLastName] = useState('');
  const [lastNameWarning, setLastNameWarning] = useState();
  const [contactPhone, setContactPhone] = useState('');
  const [contactPhoneWarning, setContactPhoneWarning] = useState();
  const [location, setLocation] = useState('');
  const [locationWarning, setLocationWarning] = useState();
  const [activateError, setActivateError] = useState();
  const [busy, setBusy] = useState(false);
  const classes = useStyles();
  
  const activate = async () => {
    setActivateError();
    const checks = [
      validateFirstName(true),
      validateLastName(true),
      validateContactPhone(true),
      Validate.validateLocation(location, setLocationWarning),
    ];
    if (!checks.every(v=>v)) return;
    try {
      setBusy(true);
      const result = await AuthAPI.providerAcceptInvitation(
        code,
        password,
        firstName,
        lastName,
        contactPhone,
        location,
        true
      );
      const { account_email } = result.payload;
      setBusy(false);
      onComplete(account_email);
    } catch (err) {
      setBusy(false);
      console.error(err);  // eslint-disable-line no-console
      const message = (err.body && err.body.message) ? err.body.message : DEFAULT_ERROR_MESSAGE;
      setActivateError(message);
    }
  };

  const validatePassword = (onSubmit=false) => {
    if (password === '' && onSubmit) {
      setPasswordWarning('Password is required');
      return false;
    }
    setPasswordWarning();
    return true;
  };


  const validateFirstName = (onSubmit=false) => {
    if (firstName === '' && onSubmit) {
      setFirstNameWarning('First name is required');
      return false;
    }
    setFirstNameWarning();
    return true;
  };

  const validateLastName = (onSubmit=false) => {
    if (lastName === '' && onSubmit) {
      setLastNameWarning('Last name is required');
      return false;
    }
    setLastNameWarning();
    return true;
  };

  const validateContactPhone = (onSubmit=false) => {
    if (contactPhone === '' && onSubmit) {
      setContactPhoneWarning('Contact phone number is required');
      return false;
    }
    setContactPhoneWarning();
    return true;
  };

  const validatePassword2 = (onSubmit=false) => {
    if (onSubmit) {
      if (password2 === '') {
        setPassword2Warning('Please repeat password here to confirm it.');
        return false;
      }
      if (password2 !== password) {
        setPassword2Warning('Passwords do not match');
        return false;
      }
    }
    setPassword2Warning();
    return true;
  };

  const disabled = passwordWarning !== undefined || password2Warning !== undefined;
  return (
    <AuthForm
      buttonLabel="Join Clickability"
      buttonAction={activate}
      disabled={disabled}
      busy={busy}
      error={activateError}
    >

      <Typography variant="h1" color="primary">Create your Clickability User Account</Typography>

      <AuthField
        id="firstname"
        placeholder="Your first name"
        label="First Name*"
        autoComplete="given-name"
        type="text"
        name="firstname"
        value={firstName}
        onChange={setFirstName}
        error={firstNameWarning}
        onBlur={() => validateFirstName()}
      />

      <AuthField
        id="lastname"
        placeholder="Your last name"
        label="Last Name*"
        autoComplete="family-name"
        type="text"
        name="lastname"
        value={lastName}
        onChange={setLastName}
        error={lastNameWarning}
        onBlur={() => validateLastName()}
      />

      <AuthField
        id="contactphone"
        placeholder="Contact Telephone Number"
        label="Contact Telephone*"
        autoComplete="tel-national"
        type="text"
        name="contactphone"
        value={contactPhone}
        onChange={setContactPhone}
        error={contactPhoneWarning}
        onBlur={() => validateContactPhone()}
      />

      <div>
        <LocationInput
          id="location"
          placeholder="Postcode or Suburb"
          label="Suburb*"
          autoComplete="off"
          type="text"
          name="location"
          value={location}
          onEnter={setLocation}
          error={locationWarning !== undefined}
          classes={{input: classes.input}}
          onBlur={() => Validate.validateLocation(location, setLocationWarning, true)}
        />
        {locationWarning && <Alert severity="error">{locationWarning}</Alert>}
      </div>

      <AuthField
        id="password"
        label="Password*"
        autoComplete="current-password"
        type="password"
        name="password"
        value={password}
        onChange={setPassword}
        error={passwordWarning}
        onBlur={() => validatePassword()}
      />

      <AuthField
        id="password2"
        label="Repeat password*"
        autoComplete="current-password"
        type="password"
        name="password2"
        value={password2}
        onChange={setPassword2}
        error={password2Warning}
        onBlur={() => validatePassword2()}
        onEnter={() => !disabled && activate()}
      />

    </AuthForm>
  );
};

ActivateForm.propTypes = {
  code: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  activateApi: PropTypes.func.isRequired,
};

export default ActivateForm;
