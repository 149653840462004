import {
  checkboxInput,
  yesNoInput,
} from 'components/formflow/default/questions';

import LinkIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';
import QuestionContainer from 'components/formflow/default/QuestionContainer';
import React from 'react';
import SpecialisationFormContainer from './SpecialisationFormContainer';

const isYes = (value) => value === 'yes';

const NoteText = ({children}) => <i style={{display: 'block',  marginTop: '0.5rem'}}>{children}</i>;
NoteText.propTypes = { children: PropTypes.node.isRequired };

const Link = ({url}) => <span>&nbsp;<a href={url} target="_blank" rel="noopener noreferrer"><LinkIcon/></a>&nbsp;</span>;
Link.propTypes = { url: PropTypes.string.isRequired };

const sensoryImpairment = {
  name: 'sensory-impairment',
  title: 'Sensory Impairment Specialisation',
  questions: [
    yesNoInput({
      field: 'website_meets_WCAG2_AA',
      label: 'As a bare minimum, does your website meet WCAG 2 AA standards?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'accessible_information',
      label: 'Do you provide information in an accessible format? This includes media being captioned, images featuring alt-text, and documents being available in text or html.',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'real_time_communications',
      label: 'Do you provide accessible, real-time communication for participants to contact you?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'consulted_lived_experience',
      label: 'Have you consulted with someone who has lived experience with sensory impairment, when developing your strategies, procedures, and policies?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'flexibility_for_workers',
      label: 'Do you offer flexibility with appointments or shift times, to accommodate participants who work full-time?',
      isValid: isYes,
    }),
    checkboxInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: (value) => value,
    }),
  ]
};

const psychosocialDisbility = {
  name: 'psychosocial-disability',
  title: 'Psychosocial Disability Specialisation',
  questions: [
    yesNoInput({
      field: 'service_flexibility',
      label: 'Does your service offer flexibility, so that participants can choose programs or activities that suit their interests or skills?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'service_1_to_1_or_group',
      label: 'Can they choose 1-to-1 support or group-based programs?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'service_embedded_in_community',
      label: 'Is your service embedded in the local community, e.g. do you help participants engage with community groups, churches, sporting clubs or other groups?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'consulted_lived_experience',
      label: 'Do you employ people with psychosocial disability, consult with people with psychosocial disability, or offer participants peer mentoring?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'staff_development',
      label: 'Has your service actively worked with staff to develop their psychosocial disability skills, knowledge and experience?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'safe_feedback',
      label: 'A participant’s brother reflected, “They addressed my brother, the person for whom the service was for. They made him feel at ease.” Does your service foster an environment where participants feel safe to provide feedback or raise concerns?',
      isValid: isYes,
    }),
    checkboxInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: (value) => value,
    }),
  ]
};

const lgbtiq = {
  name: 'lgbtiq+',
  title: 'LGBTIQ+ Specialisation',
  questions: [
    yesNoInput({
      field: 'service_flexibility',
      label:
        <React.Fragment>
          Does your mission statement, vision and values reflect inclusive practice standards
          <Link url="https://www.rainbowhealthvic.org.au/media/pages/rainbow-tick/3547223994-1605661765/lgbti-inclusive-practice-audit-tool.pdf"/>
          particularly with safety and acceptance?
        </React.Fragment>,
      isValid: isYes,
    }),
    yesNoInput({
      field: 'consulted_lgbtqi',
      label: 'Are LGBTQI+ consumers and stakeholders consulted when developing or reviewing services?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'employ_lgbtqi',
      label: 'Do you employ staff who identify as LGBTQI+?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'staff_development',
      label: 'Do you offer staff appropriate diversity training programs?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'safe_feedback',
      label: 'Do you ensure participants have autonomy over their body and how it is described, e.g. do you follow etiquette when it comes to names and pronouns on documents and when talking to individuals?',
      isValid: isYes,
    }),
    checkboxInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: (value) => value,
    }),
  ]
};

const intellectualDisability = {
  name: 'intellectual-disability',
  title: 'Intellectual Disability Specialisation',
  questions: [
    yesNoInput({
      field: 'easy_read',
      label: 
        <React.Fragment>  
          Does your service explain things in everyday language and provide “Easy Read” documents for participants?
          <NoteText>
            The Council for Intellectual Disability defines “Easy Read” as size 14 font, 1.5 line spacing, and headings to signpost important points.
          </NoteText>
        </React.Fragment>,
      isValid: isYes,
    }),
    yesNoInput({
      field: 'service_flexibility',
      label: 'Does your service offer flexibility, so that participants can choose programs or activities that suit their interests or skills? Can they choose 1-to-1 support or group-based programs?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'safe_feedback',
      label: 'Does your service foster an environment where participants feel safe to provide feedback or raise concerns?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'low_staff_turnover',
      label: 
        <React.Fragment>
          A participant’s sister and NDIS Plan Nominee reflected, “One of the big things that makes me happy is the low churn of staff.”  Does your service have a staff turnover rate of less than 5% per quarter?
          <NoteText>5 per cent is the industry standard, according to NDS in 2018.</NoteText>
        </React.Fragment>,
      isValid: isYes,
    }),    
    yesNoInput({
      field: 'change_management_policies',
      label: 'Do you have change management policies in place to support participants and their families through transitional periods?',
      isValid: isYes,
    }),
    checkboxInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: (value) => value,
    }),
  ],
};

const cald  = {
  name: 'cald',
  title: 'Cultural and Linguistic Diversity Specialisation',
  questions: [
    yesNoInput({
      field: 'consulted_community',
      label: 'Have you networked and liaised with appropriate ethnic agencies and communities?', 
      isValid: isYes,
    }),
    yesNoInput({
      field: 'staff_training',
      label: 'Do you ensure cultural competency of staff through training and ongoing monitoring, complemented by feedback from participants?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'accessible_information',
      label: 'Do you provide information about services in culturally and linguistically accessible ways, whether in simple English, community languages or alternative formats?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'real_time_communication',
      label: 'Do you facilitate real-time communication with participants, by funding professional interpreters and translators or employing bi-lingual and bi-cultural staff?',
      isValid: isYes,
    }),    
    yesNoInput({
      field: 'family_and_community_consultation',
      label: 'Do you provide for accompanied family and community consultation where culturally appropriate, and invite said people to meetings and appointments where participant consent is given?',
      isValid: isYes,
    }),
    checkboxInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: (value) => value,
    }),
  ],
};

const autism = {
  name: 'autism',
  title: 'Autism Spectrum Specialisation',
  questions: [
    yesNoInput({
      field: 'consulted_peak_bodies',
      label: 
        <React.Fragment>
          Have you consulted with peak bodies (such as Autism Spectrum
          <Link url="https://www.autismspectrum.org.au/get-involved/making-australia-autism-friendly"/>
          ) or participants with autism to assess these environments, as well as your strategies, procedures, and policies?
        </React.Fragment>,
      isValid: isYes,
    }),
    yesNoInput({
      field: 'diversity_flexibility_strengths',
      label: 'Do your programs respect difference and diversity, offer flexibility, and build a person’s skills based on their individual strengths?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'autism_specific_training',
      label: 
        <React.Fragment>
          Have your workers attended autism-specific training, such as Autism QLD
          <Link url="https://autismqld.com.au/page/workshops"/>
          or Autism Spectrum 
          <Link url="https://www.autismspectrum.org.au/how-can-we-help/attend-a-workshop"/>
          workshops?
        </React.Fragment>,
      isValid: isYes,
    }),
    yesNoInput({
      field: 'prior_experience',
      label: 'Do your workers have prior experience with autism?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'tailored_supports',
      label: 'Can participants tailor their supports to their respective needs, including finding employment or undertaking everyday activities?',
      isValid: isYes,
    }),
    checkboxInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: (value) => value,
    }),
  ],
};

const abi = {
  name: 'abi',
  title: 'Aquired Brain Injury Specialisation',
  questions: [
    yesNoInput({
      field: 'abi_training',
      label: 
        <React.Fragment>
          Have your workers undertaken and completed ABI training, including (as an example) Working with People with ABI 
          <Link url="http://www.abistafftraining.info/"/>
          training modules?
        </React.Fragment>,
      isValid: isYes,
    }),
    yesNoInput({
      field: 'tailored_supports',
      label: 'ABI isn’t a static disability, with recovery and rehab able to deliver positive long-term outcomes; as such, do you tailor your supports to suit and respect where a participant is in their ABI journey?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'written_information',
      label: 'ABI culture can be very verbally oriented. Do you provide participants with written information prior to meetings and appointments, whether in person or on the phone, to support them in processing information?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'appropriate_resources',
      label: 'A participant said, “I feel what I want, but I may not know what I want.” Do you have resources available for participants to better understand what your service offers? Are these resources easily accessible?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'lived_experience',
      label: 'Have you consulted with someone who has lived experience with ABI, when developing your strategies, procedures, and policies?',
      isValid: isYes,
    }),
    checkboxInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: (value) => value,
    }),
  ],
};

const atsi = {
  name: 'atsi',
  title: 'Aboriginal and Torres Strait Islander Specialisation',
  questions: [
    yesNoInput({
      field: 'tailored_supports',
      label: 'Do you provide tailored, culturally appropriate services?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'atsi_managed',
      label: 'Are your services managed by Aboriginal and Torres Strait Islander people?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'consulted_community',
      label: 
        <React.Fragment>
          Have you networked and liaised with appropriate Aboriginal and Torres Strait Island communities and peak bodies (such as First Peoples Disability Network
          <Link url="https://fpdn.org.au/"/>
          )?
        </React.Fragment>,
      isValid: isYes,
    }),
    yesNoInput({
      field: 'staff_development',
      label: 'Do you ensure cultural competency of staff through training and ongoing monitoring, complemented by feedback from participants?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'appropriate_resources',
      label: 'Do you provide information about services in culturally accessible ways, whether in simple English, community languages or alternative formats?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'family_and_community_consultation',
      label: 'Do you provide for accompanied family and community consultation where culturally appropriate, and invite said people to meetings and appointments where participant consent is given?',
      isValid: isYes,
    }),
    checkboxInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: (value) => value,
    }),
  ],
};

const createFlow = page => (
  {
    pages: [page],
    incompleteMessage: 'You have not met all of the criteria to list this specialisation.',
    Container: SpecialisationFormContainer,
    QuestionContainer: QuestionContainer,
  }
);

export default {
  'Sensory Impairment': createFlow(sensoryImpairment),
  'Psychosocial Disability': createFlow(psychosocialDisbility),
  'LGBTIQ+': createFlow(lgbtiq),
  'Intellectual Disability': createFlow(intellectualDisability),
  'CALD': createFlow(cald),
  'Autism': createFlow(autism),
  'Acquired Brain Injury': createFlow(abi),  
  'Aboriginal and Torres Strait Islander': createFlow(atsi),
};
