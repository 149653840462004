import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '../../../components/Button.js';
import SectionDivider from '../../../components/SectionDivider.js';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '3rem',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  title: {
    padding: '1rem 0 1.5rem 0',
    marginBottom: '2rem',
  },
  section: {
    marginBottom: '3rem',
  },
  item: {
    marginBottom: '1rem',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.dark,
    },
  },
}));

// Moved these out of about.json in content repo as this right pane component
// is generic and used on all pages. Future plan is to dynamically populate
// content in this pane.
const links = [
  {
    'link': 'https://www.sbs.com.au/topics/life/health/article/2017/03/08/clickability-new-disability-services-review-site-go-national',
    'source': 'SBS',
    'text': 'Clickability: New disability services review site to go national'
  },
  {
    'link': 'https://probonoaustralia.com.au/news/2016/03/giving-the-ndis-clickability/',
    'source': 'ProBono',
    'text': 'Giving the NDIS Clickability'
  },
  {
    'link': 'https://probonoaustralia.com.au/news/2017/02/ndis-service-provider-rating-site-expands-nsw/',
    'source': 'ProBono',
    'text': 'NDIS Service Provider rating site expands to NSW'
  },
  {
    'link': 'https://www.smh.com.au/business/small-business/tipping-point-disability-support-start-ups-are-coming-of-age-20190206-p50vxt.html',
    'source': 'Sydney Morning Herald',
    'text': '‘Tipping point’: Disability support start-ups are coming of age'
  },
  {
    'link': 'https://www.smartcompany.com.au/startupsmart/news/victorian-startup-clickability-expands-interstate-with-tripadvisor-inspired-platform-for-disability-services/',
    'source': 'SmartCompany',
    'text': 'Victorian startup Clickability expands interstate with TripAdvisor-inspired platform for disability services'
  }
];

const RightPane = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Typography variant="h2" color="primary" className={classes.title}>
          Have a disability service that people need to know about?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          label="Register my business"
          pointer="right"
          to="/providers/signup"
        />
      </div>
      <SectionDivider />
      <div className={classes.section}>
        <Typography variant="h2" color="primary" className={classes.title}>
          Want to share your experience with a disability service?
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          label="Write a review"
          pointer="right"
          to="/review"
        />
      </div>
      <SectionDivider />
      <div className={classes.section}>
        <Typography variant="h2" color="primary" className={classes.title}>
          As seen in...
        </Typography>
        <ul className={classes.list}>
          {links.map((l, i) =>
            <li key={i}>
              <a href={l.link} className={classes.link} target="_blank" rel="noopener noreferrer">
                <Typography variant="body2" className={classes.item}>
                  {l.source}:&nbsp;{l.text}
                </Typography>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default RightPane;
