import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Spinner from './Spinner';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

/*
This is a legacy loader which uses the latest Spinner component. Please do not use
this component. Use the Spinner component ('components/Spinner.js') instead.
*/
const Loader = ({loading=true}) => {
  const classes = useStyles();

  if (!loading)
    return null;

  return (
    <div className={classes.root}>
      <Spinner />
    </div>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool
};

export default Loader;
