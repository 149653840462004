import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Button as AntdButton, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';
import Container from 'providerSite/components/Container';
import { currentProductUrl } from '../Navbar/components/utils';
import providerLocation from 'assets/provider-location-services.png';
import providerSearch from 'assets/provider-search-ndis.png';
import providerNewsletter from 'assets/provider-newsletter.png';


const Main = styled.div`
  padding: 80px 0 60px 0;
  display: flex;
  justify-content: center;
  .icon {
    font-size: 48px;
    color: ${style.colors.secondary};
    margin-top: 30px;
    cursor: pointer;
    &:hover {
      color: ${style.colors.primary};
    }
  }
  @media (max-width: ${style.screens.tab}) {
    padding: 50px 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  
  @media (max-width: ${style.screens.tab}) {
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  flex-basis: 30%;
  align-items: center;
  
  img {
    height: 200px;
    width: auto;
  }
`;

const IconWithButton = styled(AntdButton)`
  margin-top: 35px;
  display: flex;
  column-gap: 6px;
  align-items: center;
  justify-content: center;
  line-height: normal;
`;

const SectionOne = ({ title }) => {
  const history = useHistory();
  const currentUrl = currentProductUrl();
  return (
    <Main>
      <Container size="medium" flex>
        <Typography.Title level={2} style={{color: style.colors.secondary}}>
          {title}
        </Typography.Title>
        <ContentContainer>
          <ItemContainer>
            <img src={providerLocation} alt="Provider Location"/>
            <Typography.Title level={4} style={{color: style.colors.primary}}>
              10,000+ services viewed per day
            </Typography.Title>
          </ItemContainer>
          <ItemContainer>
            <img src={providerSearch} alt="Provider Search"/>
            <Typography.Title level={4} style={{color: style.colors.primary, maxWidth: '400px'}}>
              Seen by over half a million people searching for NDIS Providers
            </Typography.Title>
          </ItemContainer>
          <ItemContainer>
            <img src={providerNewsletter} alt="Provider Newsletter"/>
            <Typography.Title level={4} style={{color: style.colors.primary}}>
              20,000+ engaged community members
            </Typography.Title>
          </ItemContainer>
        </ContentContainer>
        <IconWithButton
          type="primary"
          size="large"
          onClick={() => history.push(`${currentUrl}/signup`)}
        >
          Get Started For Free
          <FontAwesomeIcon icon={faGreaterThan} color={style.colors.palette.white}/>
        </IconWithButton>
      </Container>
    </Main>
  );
};

SectionOne.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};


export default SectionOne;
