import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import {makeStyles} from '@material-ui/core/styles';

import RowCard from './RowCard';


const useStyles = makeStyles( {
  root: {
    display: 'flex'
  },
  container: {
    width: 'auto',
    height: '50px',
    marginLeft: 'auto',
    display: 'flex',
  },
  buttonContainer: {
    display: 'flex',
    columnGap: '30px',
  },
});

const iconButtonStyles = makeStyles( theme => ({
  root: {
    color: ({disabled}) => disabled && theme.palette.common.silver,
    cursor: ({disabled}) => !disabled && 'pointer',
    '&:hover': {
      color: ({disabled}) => !disabled && theme.palette.common.tealish,
    },
  },
}));

const PaginationController = ({data, onClick}) => {
  const classes = useStyles();

  const _startingNumber = ((data.pageSize * data.page) - data.pageSize) + 1;
  const startingNumber = data.count && _startingNumber;
  const toNumber = data.count < (data.pageSize * data.page) ? data.count : data.pageSize * data.page;

  return (
    <div className={classes.root}>
      <RowCard className={classes.container}>
        {!isNaN(toNumber) && (
          <div>
            {startingNumber} - {toNumber} of {data.count}
          </div>
        )}
        <div className={classes.buttonContainer}>
          <IconButton
            icon={faChevronLeft}
            disabled={!data.hasPrevPage}
            onClick={() => onClick(data.page - 1)}
          />
          <IconButton
            icon={faChevronRight}
            disabled={!data.hasNextPage}
            onClick={() => onClick(data.page + 1)}
          />
        </div>
      </RowCard>
    </div>
  );
};

const IconButton = ({icon, onClick, disabled=false}) => {
  const classes = iconButtonStyles({disabled: disabled});

  return (
    <FontAwesomeIcon
      className={classes.root}
      icon={icon}
      onClick={() => !disabled && onClick()}
    />
  );
};

PaginationController.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
};

IconButton.propTypes = {
  icon: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PaginationController;