import PropTypes from 'prop-types';
import React from 'react';
import accreditedImg from 'assets/accredited-provider-badge.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '1rem 0',
  },
  img: {
    height: '3rem',
  },
});

const AccreditationBadge = ({data}) => {
  const classes = useStyles();
  if (!data.accredited) {
    return null;
  }
  return (
    <div className={classes.root}>
      <img
        src={accreditedImg}
        aria-label="Provider is accredited by Clickability"
        alt="Clikability-accredited provider icon"
        className={classes.img}
      />
    </div>
  );
};

AccreditationBadge.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AccreditationBadge;
