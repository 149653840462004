import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { useLottie } from 'lottie-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
});

const Lottie = ({animation, width, height, className, options={}}) => {
  const classes = useStyles();

  const _options = {
    animationData: animation,
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
    ...options,
  };

  const { View } = useLottie(_options, {width: width && width, height: height && height});

  return <div className={classNames(classes.root, className)}>{View}</div>;
};

Lottie.propTypes = {
  animation: PropTypes.object.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.object,
};

export default Lottie;