import { makePath, request } from './http';
const API_NAME = 'provider';

export function getProfile(uuid) {
  return request('GET', makePath(API_NAME, uuid));
}

export function searchProfiles(params) {
  return request('GET', makePath(API_NAME), {params: params});
}

export function updateProfile(uuid, data) {
  return request('PATCH', makePath(API_NAME, uuid), {body: data});
}

export function adminUpdateProfile(uuid, data) {
  return request('PATCH', makePath(API_NAME, 'admin', uuid), {body: data});
}

export function createProvider(data) {
  return request('POST', makePath(API_NAME), {body: data});
}

export function getProviderAutocomplete(query, limit=10) {
  return request('GET', makePath(API_NAME, 'autocomplete'), {params: {query, limit}});
}

export function deleteProvider(provider_uuid) {
  return request('DELETE', makePath(API_NAME, provider_uuid));
}

export function getListings(provider_uuid, exclude_inactive=true) {
  return request('GET', makePath(API_NAME, provider_uuid, 'listing'), {params: {exclude_inactive}});
}

export function getListing(uuid_or_slug) {
  return request('GET', makePath(API_NAME, 'listing', uuid_or_slug));
}

export function searchListings(params) {
  return request('GET', makePath(API_NAME, 'listing'), {params: params});
}

export function updateListing(provider_uuid, listing_uuid, data) {
  return request('PATCH', makePath(API_NAME, provider_uuid, 'listing', listing_uuid), {body: data});
}

export function createListing(provider_uuid, data) {
  return request('POST', makePath(API_NAME, provider_uuid, 'listing'), {body: data});
}

export function getPremises(uuid, exclude_inactive=true) {
  return request('GET', makePath(API_NAME, uuid, 'premise'), {params: {exclude_inactive}});
}

export function getPremise(provider_uuid, premise_uuid) {
  return request('GET', makePath(API_NAME, provider_uuid, 'premise', premise_uuid));
}

export function updatePremise(provider_uuid, premise_uuid, data) {
  return request('PATCH', makePath(API_NAME, provider_uuid, 'premise', premise_uuid), {body: data});
}

export function createPremise(provider_uuid, data) {
  return request('POST', makePath(API_NAME, provider_uuid, 'premise'), {body: data});
}

export function getUsers(provider_uuid) {
  return request('GET', makePath(API_NAME, provider_uuid, 'users'));
}

export function removeRole(provider_uuid, account_uuid) {
  return request('POST', makePath(API_NAME, provider_uuid, 'removerole'), {body: {account_uuid}});
}

export function changeRoles(provider_uuid, account_uuid, role_names) {
  return request('POST', makePath(API_NAME, provider_uuid, 'changeroles'), {body: {account_uuid, role_names}});
}

export function webToLead(data) {
  return request('POST', makePath(API_NAME, 'lead'), {body: data});
}

export function submitContactUsForm(data) {
  return request('POST', makePath(API_NAME, 'contact'), {body: data});
}

export function locationAutocomplete(query, limit=10) {
  return request('GET', makePath(API_NAME, 'location/autocomplete'), {params: {query, limit}});
}

export function getSA4(location) {
  return request('GET', makePath(API_NAME, 'location/sa4'), {params: {location}});
}

export function locationFromLatLng(latitude, longitude) {
  return request('GET', makePath(API_NAME, 'location'), {params: {latitude, longitude}});
}

export function getProviderImageUploadUrl(provider_uuid, file_name, content_type, image_type=null) {
  return request('GET', makePath(API_NAME, provider_uuid, 'imageuploadurl'),
    {params: {
      file_name,
      content_type,
      image_type,
    }}
  );
}

export function getListingImageUploadUrl(provider_uuid, listing_uuid, file_name, content_type) {
  return request('GET', makePath(API_NAME, provider_uuid, 'listing', listing_uuid, 'imageuploadurl'),
    {params: {
      file_name,
      content_type,
    }}
  );
}

export function removeGalleryImage(provider_uuid, image_uuid) {
  return request('DELETE', makePath(API_NAME, provider_uuid, 'images/gallery', image_uuid));
}

export function addSpecialisation(provider_uuid, specialisation_id) {
  return request('POST', makePath(API_NAME, provider_uuid, 'specialisation', specialisation_id));
}

export function removeSpecialisation(provider_uuid, specialisation_id) {
  return request('DELETE', makePath(API_NAME, provider_uuid, 'specialisation', specialisation_id));
}

export function endorseProvider(provider_uuid, specialisation_id, temporary_credentials) {
  return request('POST', makePath(API_NAME, provider_uuid, 'endorsement', specialisation_id), {body: {temporary_credentials}});
}

export function removeEndorsement(provider_uuid, specialisation_id) {
  return request('DELETE', makePath(API_NAME, provider_uuid, 'endorsement', specialisation_id));
}

export function getEndorsements(provider_uuid) {
  return request('GET', makePath(API_NAME, provider_uuid, 'endorsement'));
}

export function getExamples() {
  return request('GET', makePath(API_NAME, 'example'));
}

export function getModalityFeaturedServices(featuredName, serviceName) {
  return request('GET', makePath(API_NAME, 'modality-featured-services'), {
    params: {
      advertisement_feature_name: featuredName,
      service_name: serviceName
    }
  });
}

export function getBlogFeaturedServices(featuredName, blog_uuid) {
  return request('GET', makePath(API_NAME, 'blog-featured-service'), {
    params: {
      advertisement_feature_name: featuredName,
      blog_uuid: blog_uuid
    }
  });
}


export const getNotificationConfig = (providerUuid, userUuid) => {
  return request('GET',
    makePath(API_NAME, 'notification-config', providerUuid, userUuid)
  );
};

export const updateNotificationConfig = (providerUuid, userUuid, data) => {
  return request(
    'PATCH',
    makePath(API_NAME, 'notification-config', providerUuid, userUuid),
    {body: data}
  );
};

export const updateDirectConnectConfig = (provider_uuid, listing_uuid, data) => {
  return request(
    'PATCH',
    makePath(API_NAME, 'direct-connect-config', provider_uuid, listing_uuid),
    {body: data}
  );
};

export function createConnectParticipant(providerUuid, data) {
  return request(
    'POST',
    makePath('connect', providerUuid, 'participants'),
    {body: data}
  );
}

export const getOffers = (type_name) => {
  return request('GET', makePath(API_NAME,'offers'), {params: {type_name}});
};

export const authPusher = data => {
  return request('POST', makePath(API_NAME, 'pusher/auth'), {body: data});
};
