import authRoutes, { ActivationRoute } from './modules/auth';
import brokerageFlowRoutes, { aaaRoutes, clxRoutes, mpmRoutes, mypRoutes, ndspRoutes } from './modules/brokerageFlow';
import {
  publicRoutes as providerSitePublicRoutes,
  routes as providerSiteRoutes
} from './providerSite/routes';

import Error404 from './modules/pages/Error404';
import Home from './modules/home';
import React from 'react';
import { Route } from 'react-router-dom';
import accountRoutes from './modules/account';
import adminRoutes from './modules/admin';
import blogRoutes from './modules/blog';
import brokerageRoutes from './modules/brokerage';
import pageRoutes from './modules/pages';
import providerRoutes from './modules/provider';
import searchRoutes from './modules/search';
import siteAdminRoutes from './modules/siteadmin';
import portalRoutes from './modules/portal';
import styleGuideRoutes from './modules/styleguide';

const defaultRoutes = () => [
  <Route exact path="/" key="home" component={Home} />,
  <Route path="/" key="404" component={Error404} />,
];

// this list is used to generate the sitemap.xml
// it should contain all routes Google can index.
export const publicRoutes = [
  ...pageRoutes(),
  ...blogRoutes(),
  ...providerRoutes(),
  ...searchRoutes(),
  ...providerSitePublicRoutes(),
  ...defaultRoutes(),
];

// These routes cannot be accessed if activation_required is set on the current profile.
const subroutes = [
  ...blogRoutes(),
  ...providerRoutes(),
  ...providerSiteRoutes(),
  ...adminRoutes(),
  ...accountRoutes(),
  ...searchRoutes(),
  ...brokerageRoutes(),
  ...styleGuideRoutes(),
  ...siteAdminRoutes(),
  ...portalRoutes(),
  ...brokerageFlowRoutes(),
  ...defaultRoutes(),
];

const routes = [
  ...pageRoutes(),
  ...authRoutes(),
  ActivationRoute(subroutes),
];

export const subdomainRoutes = {
  myp: mypRoutes(),
  aaa: aaaRoutes(),
  clx: clxRoutes(),
  ndsp: ndspRoutes(),
  mpm: mpmRoutes(),
};

export default routes;
