import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import {Checkbox} from '@material-ui/core';

import Question from './Question';
import {ERROR_CONTAINER_HEIGHT} from '../../../constants';


const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: '-12px',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    marginTop: '12px',
  },
  errorContainer: {
    height: `calc(${ERROR_CONTAINER_HEIGHT} - 3px)`,
    fontSize: '13px',
    color: theme.palette.error.main,
    marginLeft: '15px',
    marginTop: '3px',
  },
}));

const CheckboxQuestion = (
  {
    text,
    onChange,
    value: defaultValue,
    isOptional,
    preChecked,
  }) => {

  const classes = useStyles();

  const [isChecked, setIsChecked] = useState(
    typeof defaultValue === 'boolean' ? defaultValue : (preChecked ? true : null));

  useEffect(() => {
    isChecked !== null && onChange(isChecked);
  }, [isChecked]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <div className={classes.checkboxContainer}>
        <Checkbox
          value={!!isChecked}
          checked={!!isChecked}
          onChange={event => setIsChecked(event.target.checked)}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <div className={classes.text}><Question title={text} /></div>
      </div>
      {!isOptional && (
        <div className={classes.errorContainer}>
          {isChecked === false && 'Please select the checkbox'}
        </div>
      )}
    </div>
  );
};

CheckboxQuestion.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  isOptional: PropTypes.bool,
  preChecked: PropTypes.bool,
};

export default CheckboxQuestion;