import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Button from 'components/Button';
import {NAV_BAR_HEIGHT} from 'components/constants';
import Markdown from 'components/Markdown';
import landingPageBG from 'assets/landing-page-bg.svg';


const useStyles = makeStyles(theme => ({
  root: {
    padding: `calc(${NAV_BAR_HEIGHT} + 12px) 0 12px 0`,
    marginTop: `-${NAV_BAR_HEIGHT}`,
    borderRadius: '0px 0px 0px 100px',
    [theme.breakpoints.down('sm')]: {
      padding: `${NAV_BAR_HEIGHT} 16px 40px 16px`,
    },
    width: '100%',
    border: 'none',
    backgroundColor: theme.palette.common.darkSlateBlue,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.common.white,
  },
  text: {
    color: theme.palette.common.white,
    width: '100%',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '4rem 1rem',
    [theme.breakpoints.down('sm')]: {
      padding: '4rem 1rem 1rem 1rem',
    },
    '&>p': {
      fontSize: '1.125rem',
    }
  },
  imageContainer: {
    backgroundImage: `url(${landingPageBG})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 60%',
    padding: '4rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  img: {
    width: '18rem',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
  },
  button: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '0.75rem',
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.25)',
    padding: '0.8rem 1.2rem',
    fontSize: '1.2rem',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      padding: '1rem 1rem',
      width: '100%',
    },
  },
}));

const ContentHero = ({text, buttonText, image, onClick, className}) => {
  const classes = useStyles();

  const components = {
    'h1': LightHeader,
  };

  return (
    <div className={classNames(classes.root, className)}>
      <Container maxWidth="lg" fixed disableGutters={true} className={classes.container}>
        <div className={classes.text}>
          <Markdown content={text} components={components}/>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            label={buttonText}
            onClick={onClick}
          />
        </div>
        {image &&
          <div className={classes.imageContainer}>
            <img
              src={image.src}
              alt={image.alt}
              title={image.title}
              className={classes.img}
            />
          </div>
        }
      </Container>
    </div>
  );
};

ContentHero.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  image: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

// unlike the standard header renderer, this doesn't override the text colour.
const LightHeader = ({children, level}) => {
  return (
    <Typography
      style={{color: 'white', marginBottom: '20px'}}
      variant={'h'+level}
    >
      {children}
    </Typography>
  );
};
LightHeader.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.number.isRequired,
};

export default ContentHero;
