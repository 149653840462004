import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import sessionStorage from 'redux-persist/lib/storage/session';
import auth from './auth';
import provider from './provider';
import taxonomy from './taxonomy';
import review from './review';
import cms from './cms';
import serviceRequest from './serviceRequest';
import report from './report';
import file from './file';
import sessionId from './sessionId';
import serviceRequestNotification from './serviceRequestNotification';
import { AuthAPI } from '../api';
import * as AuthStore from './auth';
import * as ProviderStore from './provider';
import * as TaxonomyStore from './taxonomy';
import * as ReviewStore from './review';
import * as CmsStore from './cms';
import * as ServiceRequestStore from './serviceRequest';
import * as ReportStore from './report';
import * as FileStore from './file';
import * as SessionIdStore from './sessionId';
import * as ServiceRequestNotificationStore from './serviceRequestNotification';
import { registerListener, requestSync } from './authSync';

import thunk from 'redux-thunk';
const reducers = combineReducers({
  auth,
  provider,
  taxonomy,
  review,
  cms,
  serviceRequest,
  report,
  file,
  sessionId,
  serviceRequestNotification,
});

const rootReducer = (state, action) => {
  // drop user-specific saved state if user changes.
  if (action.type === 'auth/LOGIN' &&
      state.auth &&
      state.auth.userId !== action.profile.user_id) {
    state = {
      ...state,
      auth: undefined,
      // add any other stores here that should be reset on login
    };
  }
  return reducers(state, action);
};

const tokenHydrate = () => next => action => {
  if (action.type === 'persist/REHYDRATE') {
    if (action.payload && action.payload.auth && action.payload.auth.token) {
      AuthAPI.setBearerToken(action.payload.auth.token);
    } else {
      requestSync();
    }
  }
  return next(action);
};

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk, tokenHydrate));
const persistor = persistStore(store);
registerListener(store);

export {
  persistor,
  AuthStore,
  ProviderStore,
  TaxonomyStore,
  ReviewStore,
  CmsStore,
  ServiceRequestStore,
  ReportStore,
  FileStore,
  SessionIdStore,
  ServiceRequestNotificationStore,
};
export default store;
