import Alert from '@material-ui/lab/Alert';
import ListingCard from './ListingCard.js';
import LoadingCards from '../LoadingCards.js';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '1rem 0',
  },
});

const ListingsCardList = (
  {
    isLoading,
    data,
    nullMessage,
    isMatchingMode,
    matchingData,
    handleMatchingData
  }) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <LoadingCards />
    );
  }

  if (data.length === 0) {
    return (
      <div className={classes.root}>
        <Alert severity="info">{nullMessage}</Alert>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {data.map((item) =>
        <ListingCard
          key={item.uuid}
          data={item}
          matchingData={matchingData}
          handleMatchingData={handleMatchingData}
          isMatchingMode={isMatchingMode}
        />
      )}
    </div>
  );
};

ListingsCardList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  nullMessage: PropTypes.string.isRequired,
  isMatchingMode: PropTypes.bool,
  matchingData: PropTypes.object,
  handleMatchingData: PropTypes.func.isRequired,
};

export default ListingsCardList;
