import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1rem 0',
    width: '100%',
    '& > *': {
      margin: theme.spacing(0.5),
      textTransform: 'capitalize',
    },
    chip: {
      marginRight: '8px',
    },
  },
}));

// Sets which items in params to show in the filter display
const filterItems = new Set([
  'service_location',
  'service',
  'search_text',
  'ndis_registered',
  'ndisec_registered',
  'specialisation',
  'age_group',
  'access_method',
  'language',
]);

const setIntersection = (setA, setB) => new Set([...setA].filter(item => setB.has(item)));

const FilterDisplay = ({params, handleFilterClick}) => {
  const classes = useStyles();

  if (!setIntersection(filterItems, new Set(Object.keys(params))).size) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" aria-label="Filters">Filters: </Typography>
      <React.Fragment>
        {Object.keys(params).reduce((acc, key, index) => {
          if (!filterItems.has(key)) return acc;
          if (Array.isArray(params[key])) {
            const listFilters = params[key].map((item, index) => {
              return (
                <FilterChip
                  key={`${item}_${index}`}
                  text={item}
                  onDelete={() => handleFilterClick(key, item)}
                />);
            });
            return [...acc, ...listFilters];
          }
          else {
            acc.push(
              <FilterChip
                key={`${params[key]}_${index}`}
                text={params[key]}
                onDelete={() => handleFilterClick(key, params[key])}
              />);
          }
          return acc;
        }, [])}
      </React.Fragment>
    </div>
  );
};

const FilterChip = ({ text, onDelete }) => {
  const classes = useStyles();
  return (
    <Chip
      label={text}
      aria-label={text}
      onDelete={onDelete}
      variant="outlined"
      color="primary"
      className={classes.chip}
    />
  );
};

FilterChip.propTypes = {
  text: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

FilterDisplay.propTypes = {
  params: PropTypes.object,
  handleFilterClick: PropTypes.func.isRequired,
};

export default FilterDisplay;
