import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Checkbox, Tooltip } from 'antd';

import style from 'style';


const Container = styled.div`
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
  margin-top: 5px;
  
  .text {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
  .highlight {
    color: ${style.colors.secondary};
    font-size: 15px;
    font-weight: 600;
  }
`;

const TelehealthOnlineService = ({ isTelehealthAllowed, checked, onChange, count }) => {

  if (!count) return null;

  return (
    <Container>
      <Checkbox
        onChange={onChange}
        checked={checked}
      >
        <div className="text">
          Include&nbsp;
          <Tooltip
            title={(isTelehealthAllowed ?
              'Telehealth is the delivery of health care online. Benefits include ' +
              'shorter wait-times, more practitioner options, and access anywhere.'
              : null)}
            placement="bottom"
            overlayInnerStyle={{
              fontSize: '11px',
              fontWeight: 500,
              padding: '5px 8px 5px 12px',
              margin: 0,
              minHeight: 0,
            }}
          >
            <span className="highlight">{isTelehealthAllowed ? 'Telehealth' : 'Online Service'}</span>
          </Tooltip>
          &nbsp;to add
          extra <span className="highlight">{count} provider{count > 1 && 's'}</span>
        </div>
      </Checkbox>
    </Container>
  );
};

TelehealthOnlineService.propTypes = {
  isTelehealthAllowed: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  count: PropTypes.number,
};


export default TelehealthOnlineService;

