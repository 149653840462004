import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Switch as AntdSwitch, Tag } from 'antd';

import style from 'style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border: 1px solid;
  border-color: ${({$isChecked, $activeColor, $inactiveColor}) => $isChecked ? $activeColor : $inactiveColor};
  padding: 15px 20px;
  border-radius: 16px;
  height: max-content;
  filter: drop-shadow(0px 1px 2px rgba(46, 46, 46, 0.3));
  background: white;
  user-select: none;

  .title {
    font-size: 14px;
    font-weight: 700;
    color: #313131;
    margin-bottom: 5px;
  }
  
  .nodes {
    font-size: 13px;
    color: #858585;
    line-height: 17px;
  }
  
  .rec-container {
    position: absolute;
    right: 0;
    top: 5px;
  }
  
  .tag {
    font-size: 11px;
    line-height: 11px;
    padding: 3px 5px;
  }
`;

const Switch = styled(AntdSwitch)`
  background: ${({ $inactiveColor }) => $inactiveColor && $inactiveColor};
  &.ant-switch-checked {
    background: ${({ $activeColor }) => $activeColor && $activeColor};
  }
`;

const SwitchCard = (
  {
    title,
    icon,
    first_node,
    last_node,
    recommended,
    onChange,
    value,
    disabled,
    activeColor=style.colors.success,
    inactiveColor=style.colors.error,
  }) => {

  return (
    <Container $isChecked={value} $activeColor={activeColor} $inactiveColor={inactiveColor}>
      <div>
        <div className="title">
          <FontAwesomeIcon icon={icon} style={{marginRight: '5px', verticalAlign: '-2px'}} />
          {title}
        </div>
        <div className="nodes">
          <div>{first_node}</div>
          <div>{last_node}</div>
        </div>
        {!!recommended && (
          <div className="rec-container">
            <Tag color="success" className="tag">Recommended</Tag>
          </div>
        )}
      </div>
      <Switch
        checked={value}
        $activeColor={activeColor}
        $inactiveColor={inactiveColor}
        onChange={checked => onChange(checked)}
        disabled={disabled}
      />
    </Container>
  );
};


SwitchCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  first_node: PropTypes.node,
  last_node: PropTypes.node,
  recommended: PropTypes.bool,
  activeColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
};


export default SwitchCard;

