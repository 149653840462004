import React from 'react';
import BlogListPage from './BlogListPage';
import BlogPostPage from './BlogPostPage';
import BlogSearchPage from './BlogSearchPage';
import { Route } from 'react-router-dom';

// modules return fragments containing routes.

const routes = () => {
  return [
    <Route key="/blog" exact path="/blog" component={BlogListPage}/>,
    <Route key="/blog/search" path="/blog/search" component={BlogSearchPage}/>,
    <Route key="/blog/*" path="/blog/:slug" component={BlogPostPage}/>,
  ];
};

export default routes;
