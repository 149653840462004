import React, { useState } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';
import PickList from 'components/PickList';
import ColorButton from './ColorButton';
import * as constants from '../constants';
import { getEventData } from './utils';


const useStyles = makeStyles( theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    background: 'none',
  },
  paper: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(20px)',
    boxShadow: theme.shadows[5],
    borderRadius: '12px',
    maxWidth: '600px',
    marginLeft: '200px',
    marginTop: '-200px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 30px',
    },
  },
  modalContainer: {
    padding: '5px 30px 30px 30px',
  },
  modalButtonContainer: {
    display: 'flex',
    marginTop: '30px',
  },
  modalHeader: {
    fontSize: '20px',
    fontWeight: '500',
    textShadow: '1px 1px 2px #dfdfdf',
    borderBottom: '1px solid #e2e2e2',
    padding: '20px 30px 15px 30px',
    color: ({color}) => color ? color : theme.palette.warning.main,
  },
  modalQuestion: {
    fontSize: '16px',
    marginTop: '20px',
    marginBottom: '15px',
    textShadow: '1px 1px 2px #dfdfdf',
  },
  coloredText: {
    color: theme.palette.common.tealish,
  },
}));


const ConfirmationModal = (
  {
    isModalOpen,
    status,
    selectedEvent,
    handleModalClose,
    handleUpdateServiceReqStatus,
    handleCancellationSelection,
    confirmationMessages,
    closureReasonOptions,
    closureReasonValue,
  }) => {

  const theme = useTheme();
  const { color } = getEventData(theme, selectedEvent);
  
  const classes = useStyles({color: color});

  const [pickListEvent, setPickListEvent] = useState();

  const _question = confirmationMessages[`${status}_${selectedEvent}`];
  const questionBody =  _question ? _question.body : 
    <>Are you sure you want to perform <span className={classes.coloredText}>
      {_.capitalize(selectedEvent)}</span> action on this request?</>;
  const questionHeader = _question && _question.header ? _question.header : 'Attention!';
  const questionPicklist = _question?.picklist ? _question.picklist : null;
  const questionFooter = _question && _question.footer && _question.footer;

  const isDisableButton = (selectedEvent === constants.SERVICE_REQUEST_EVENT_CANCEL && !closureReasonValue)
    || (questionPicklist && !pickListEvent);

  const _handleModalClose = () => {
    handleModalClose();
    setPickListEvent(null);
  };
  
  return (
    <Modal
      modalClassName={classes.modal}
      isOpen={isModalOpen}
      onClose={_handleModalClose}
      paperClassName={classes.paper}
      backdropClassName={classes.backdrop}
    >
      <div className={classes.modalHeader}>{questionHeader}</div>
      <div className={classes.modalContainer}>
        <div className={classes.modalQuestion}>{questionBody}</div>
        {selectedEvent === constants.SERVICE_REQUEST_EVENT_CANCEL && (
          <PickList
            title="Reason"
            availableOptions={closureReasonOptions}
            onSelect={handleCancellationSelection}
          />
        )}
        {questionPicklist && (
          <PickList
            title={questionPicklist.title}
            availableOptions={questionPicklist.options}
            onSelect={val => setPickListEvent(val.toLowerCase())}
            hideOther
          />
        )}
        {questionFooter && <div className={classes.modalQuestion}>{questionFooter}</div>}
        <div className={classes.modalButtonContainer}>
          <ColorButton
            variant="contained"
            disableElevation
            style={{width: '200px', backgroundColor: !isDisableButton && color}}
            onClick={() => handleUpdateServiceReqStatus(questionPicklist ? pickListEvent : selectedEvent)}
            disabled={isDisableButton}
          >
            Yes
          </ColorButton>
          <Button
            style={{borderRadius: '8px', marginLeft: '15px', width: '100px'}}
            variant="outlined"
            disableElevation
            onClick={_handleModalClose}
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isModalOpen: PropTypes.bool,
  status: PropTypes.string,
  selectedEvent: PropTypes.string,
  handleModalClose: PropTypes.func,
  handleUpdateServiceReqStatus: PropTypes.func,
  handleCancellationSelection: PropTypes.func,
  confirmationMessages: PropTypes.object,
  closureReasonOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  closureReasonValue: PropTypes.string,
};

export default ConfirmationModal;