import React from 'react';

import styled from 'styled-components';

import { Benefits as BenefitsComponent, TabContent } from 'providerSite/components/LandingPage/Benefits';
import _List from 'providerSite/components/LandingPage/List';
import participantImg from 'assets/tab-connect-participants.png';
import connectDashboardImg from 'assets/tab-connect-dashboard.png';
import participantDetailImg from 'assets/tab-connect-participant-detail.png';


const List = styled(_List)`
  text-align: left;
`;

const TabOne = () => {
  const right = <img src={participantImg} alt="Dashboard" height="400px"/>;

  const left = (
    <div>
      Create a master list of participants, initiate Service Requests
      and set contact preferences. All in your own dashboard.
    </div>
  );

  return (
    <TabContent
      title="Manage and support your Participants in one place"
      left={left}
      right={right}
    />
  );
};

const TabTwo = () => {
  const right = <img src={connectDashboardImg} alt="Clickability Social" height="400px"/>;

  const left = (
    <div>
      See which providers are opting into your Service Requests,
      chat with them directly within your dashboard and view their
      details, reviews, locations and more.
    </div>
  );

  return (
    <TabContent
      title="Connect with providers who have capacity"
      left={left}
      right={right}
    />
  );
};

const TabThree = () => {
  const right = <img src={participantDetailImg} alt="Service Request Email" height="360px"/>;

  const left = (
    <>
      <List>Track and filter Service Requests by participant, status and more</List>
      <List>Real time updates – no more Google Sheets and overflowing voicemail</List>
      <List>Update, chat and monitor progress</List>
    </>
  );

  return (
    <TabContent
      title="Keep everything straight with a powerful central dashboard"
      left={left}
      right={right}
    />
  );
};

const Benefits = () => {
  const tabsContent = [
    {
      key: 0,
      title: 'Search',
      tab: <TabOne key={0} />,
    },
    {
      key: 1,
      title: 'Connect',
      tab: <TabTwo key={1} />,
    },
    {
      key: 2,
      title: 'Track',
      tab: <TabThree key={2} />,
    },
  ];

  return (
    <BenefitsComponent
      title="Benefits of Clickability Connect"
      tabsContent={tabsContent}
    />
  );
};


export default Benefits;
