import React, { useCallback, useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { EndorsementCompleted } from 'components/review/Completed';
import { EndorsementFlows } from 'components/specialisation';
import FormFlow from 'components/formflow/FormFlow';
import ParticipantLoginForm from 'modules/auth/components/ParticipantLoginForm';
import PropTypes from 'prop-types';
import { ProviderAPI } from 'api';
import SignupForm from 'modules/auth/components/SignupForm';
import {TaxonomyStore} from '../../store';
import { Typography } from '@material-ui/core';
import WrongUser from 'components/review/WrongUser';
import config from 'config';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { Modal, Alert, Button } from 'antd';

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: '40rem',
    minHeight: '10rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  header: {
    marginBottom: '1rem',
    fontSize: '20px',
  },
  text: {
    marginBottom: '1rem',
  },
  buttonArray: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1rem',
    border: `1px solid ${theme.palette.common.silver}`,
    borderRadius: '8px',
    gap: '10px',
  },
  buttons: {
    padding: '1rem 0',
    display: 'flex',
    flexWrap: 'nowrap',
    columnGap: '10px',
  },
  button: {
    margin: '1rem 1rem 1rem 0',
  },
  alert: {
    marginBottom: '1rem',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const EndorseService = ({ open, data, onClose, onReview }) => {
  const classes = useStyles();
  const location = useLocation();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isProvider = useSelector(state => state.auth.isProvider);
  const initialStep = isProvider ? 'wrongUser' : 'menu';
  const [step, setStep] = React.useState(initialStep);  // 'menu', 'form', 'completed', 'auth', 'login', 'register', 'wrongUser'
  const [temporaryCredentials, setTemporaryCredentials] = React.useState();
  const [specialisation, setSpecialisation] = useState();
  const [endorsements, setEndorsements] = useState();
  const allSpecialisations = useSelector(state => state.taxonomy.specialisations);
  const redirectTo = {redirectTo: location.pathname + '#endorsement'};
  const title = specialisation ? `Endorse ${data.name} for ${specialisation.name}` : `Endorse ${data.name} for a specialisation`;
  const dispatch = useDispatch();

  const taxonomySpecialisations = useSelector(state => state.taxonomy.specialisations);

  let alreadyEndorsed = false;
  if (specialisation && endorsements) {
    alreadyEndorsed = endorsements.find(e => e.specialisation_id === specialisation.id);
  }

  const getEndorsements = useCallback(async () => {
    try {
      const { payload } = await ProviderAPI.getEndorsements(data.uuid);
      setEndorsements(payload.endorsements);
    } catch(err) {
      console.log(err); // eslint-disable-line
    }
  }, [data.uuid]);

  const handleClose = () => {
    !isProvider && setStep('menu');
    onClose();
  };

  const onSubmit = async (email, tempCredentials) => {
    if ((isAuthenticated || tempCredentials) && !isProvider) {
      if (tempCredentials) {
        setTemporaryCredentials(tempCredentials);
      }
      try {
        await ProviderAPI.endorseProvider(data.uuid, specialisation.id, tempCredentials);
        setStep('completed');
      } catch(err) {
        console.log(err); // eslint-disable-line
      }
    } else {
      setStep('auth');
    }
  };

  // TODO: enable alert with link guide when content page is created
  const renderMenu = () => (
    <div className={classes.content}>
      <Typography variant="body2" className={classes.text}>
        Please select the <b>specialisation</b> that you would like to endorse this provider for.
      </Typography>
      <Alert
        showIcon
        type="info"
        className={classes.alert}
        message={<>For help on endorsements, check out our <a className={classes.link} href={config.link.endorsementsFaq}><b>handy guide</b></a>.</>}
      />
      <div className={classes.buttonArray}>
        {allSpecialisations.map(s =>
          <Button
            key={s.id}
            type="primary"
            onClick={async ()=>{
              await getEndorsements();
              setSpecialisation({id: s.id, name: s.name});
              setStep('form');
            }}
          >
            {s.name}
          </Button>
        )}
      </div>
      <div className={classes.buttons}>
        <Button type="primary" ghost onClick={onClose}>Cancel</Button>
      </div>
    </div>
  );

  const renderForm = () => {
    if (alreadyEndorsed) {
      setStep('completed');
      return null;
    }
    const flow = EndorsementFlows[specialisation.name];
    return (
      <div className={classes.content}>
        {temporaryCredentials ? (
          <Alert
            showIcon
            type="success"
            className={classes.alert}
            message={<>You are signed up and can now submit your endorsement! Remember to <b>check your email</b> to verify your Clickability account and set up your login password.</>}
          />
        ) : null}
        <FormFlow
          flow={flow}
          onComplete={onSubmit}
          onCancel={()=>setStep('menu')}
        />
      </div>
    );
  };

  const renderCompleted = () => (
    <div className={classes.content}>
      {alreadyEndorsed &&
        <Alert
          showIcon
          type="warning"
          className={classes.alert}
          message={<>You have previously endorsed this service for this specialisation.</>}
        />}
      <EndorsementCompleted
        onClose={onClose}
        onReview={onReview}
        onEndorseAnother={()=>setStep('menu')}
      />
    </div>
  );

  const renderWrongUser = () => (
    <div className={classes.content}>
      <WrongUser
        onClose={onClose}
        action="endorsement"
      />
    </div>
  );

  const renderAuthOptions = () => (
    <div className={classes.content}>
      <div className={classes.header}>
        Almost there, we just need a <b>username</b> and <b>email</b> to validate this action!
      </div>
      <div className={classes.text}>
        Clickability users rely on the assurance that the reviews and experiences posted on this site are genuine. Please log in to your Clickaility account or if you don&apos;t have one, please register (don&apos;t worry, you don&apos;t need to pay and we won&apos;t spam you!).
      </div>
      <div className={classes.text}>
        <b><i>Registration and login takes less than 1 minute!</i></b>
      </div>
      <div className={classes.buttons}>
        <Button type="primary" ghost onClick={()=>setStep('form')}>Back</Button>
        <Button type="primary" ghost onClick={()=>setStep('register')}>Register</Button>
        <Button type="primary" onClick={()=>setStep('login')}>Login to my account</Button>
      </div>
    </div>
  );

  const renderLogin = () => (
    <div className={classes.content}>
      <ParticipantLoginForm
        title="Log in to Clickability"
        onComplete={()=>setStep('form')}
        onCancel={()=>setStep('auth')}
        hideLinks={true}
        oauthState={redirectTo}
      />
    </div>
  );

  const renderRegister = () => (
    <div className={classes.content}>
      <SignupForm
        title="Create your Clickability account"
        onComplete={(_, tempCredentials)=>{setTemporaryCredentials(tempCredentials); setStep('form');}}
        onCancel={()=>setStep('auth')}
        hideLinks={true}
        redirectTo={redirectTo}
        getTemporaryCredentials={true}
      />
    </div>
  );

  useEffect(() => {
    if (isAuthenticated && open) {
      getEndorsements();
    }
  }, [getEndorsements, isAuthenticated, open]);

  useEffect(() => {
    !taxonomySpecialisations.length && dispatch(TaxonomyStore.refreshTaxonomy('specialisations'));
    if (isProvider) setStep('wrongUser');
  }, []); // eslint-disable-line

  return (
    <Modal
      visible={open}
      title={title}
      onCancel={handleClose}
      footer={null}
    >
      {step === 'menu' && renderMenu()}
      {step === 'form' && renderForm()}
      {step === 'wrongUser' && renderWrongUser()}
      {step === 'completed' && renderCompleted()}
      {step === 'auth' && renderAuthOptions()}
      {step === 'login' && renderLogin()}
      {step === 'register' && renderRegister()}
    </Modal>
  );

};

EndorseService.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onReview: PropTypes.func,
};

export default EndorseService;
