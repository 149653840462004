import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Page from '../../components/Page';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Pagination from '@material-ui/lab/Pagination';
import BlogList, { BlogListSkeleton } from './components/BlogList';
import BlogLinkList from './components/BlogLinkList';
import BlogSearchBar from './components/BlogSearchBar';
import BlogSocialLinks from './components/BlogSocialLinks';
import BlogListBlurb from './components/BlogListBlurb';
import { useHistory } from 'react-router-dom';
import { CmsAPI } from '../../api';
import Pluralize from 'react-pluralize';
import { makeStyles } from '@material-ui/core/styles';
import SuggestedServices from '../../components/suggestions/SuggestedServices';
import * as track from '../../lib/track';
import UserFeaturesCTA from '../../components/widgets/UserFeaturesCTA';

const PAGE_SIZE = 10;

const useStyles = makeStyles(theme => ({
  searchRow: {
    marginTop: '6.25rem',
    marginBottom: '1.563rem',
  },
  leftColumn: {
    padding: '1rem 3rem 4rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem 1rem 2rem',
    },
  },
  rightColumn: {
    padding: '0 0 4rem 2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
    },
  },
}));

const BlogListPage = ({location}) => {
  const urlParams = new URLSearchParams(location.search);
  const history = useHistory();
  const query = urlParams.get('query') || '';
  const tags = urlParams.get('tags');
  const [blogList, setBlogList] = useState([]);
  const [blogCount, setBlogCount] = useState(0);
  const [recent, setRecent] = useState([]);
  const [busy, setBusy] = useState(false);

  const pageCount = Math.floor((blogCount + PAGE_SIZE - 1) / PAGE_SIZE);
  let pageNumber = Math.max(urlParams.get('page'), 1);
  // only enforce the upper limit if we have a non-zero page count.
  if (pageCount > 0 && pageNumber > pageCount) {
    pageNumber = pageCount;
  }

  useEffect(() => {
    CmsAPI.getBlogRecent().then((content) => setRecent(content.payload));
  }, []);

  useEffect(() => {
    const offset = (pageNumber - 1) * PAGE_SIZE;
    track.sendEvent('blog search', 'submit', {query, tags});
    setBusy(true);
    CmsAPI.getBlogSearch(query, tags, PAGE_SIZE, offset).then((response) => {
      setBlogList(response.payload);
      setBlogCount(response.total);
    }).finally(() => setBusy(false));
  }, [pageNumber, query, tags]);

  const setPage = (query, pageNo) => {
    history.push({ pathname: '/blog/search', search: '?query='+encodeURI(query) + '&page='+pageNo});
  };

  const pagination = () => {
    return (
      <Pagination
        page={pageNumber}
        count={pageCount}
        variant="outlined"
        shape="rounded"
        onChange={(event, pageNo)=>setPage(query, pageNo)}
      />
    );
  };

  const title = () => {
    if (!query) return <span>Recent Blog Posts</span>;  // shown in the case that no search query was used
    if (blogList.length > 0) {
      return (<span><Pluralize zero={'No'} singular={'result'} count={blogCount}/> for &ldquo;{query}&rdquo;</span>);
    }
    return (<span>No results for &ldquo;{query}&rdquo;</span>);
  };

  const classes = useStyles();
  return (
    <Page title="Clickability Blog Search">
      <Container maxWidth="lg" fixed disableGutters={true}>
        <Grid container spacing={0}>
          <Grid item md={8} sm={12} xs={12} className={classes.searchRow}>
            <BlogSearchBar initialQuery={query} onSubmit={query => {setPage(query, 1);}}/>
          </Grid>
          <Grid item md={8} sm={12}>
            <div className={classes.leftColumn}>
              {busy && blogCount === 0 ?
                <BlogListSkeleton count={PAGE_SIZE}/> :
                <BlogList title={title()} content={blogList} loading={busy}/>
              }
              {pageCount>1 && blogCount>1 && pagination()}
            </div>
          </Grid>
          <Grid item md={4} sm={12}>
            <div className={classes.rightColumn}>
              <UserFeaturesCTA />
              <SuggestedServices query={query} />
              <Divider variant="middle" />
              <BlogLinkList title="Recent posts" content={recent}/>
              <Divider variant="middle" />
              <BlogListBlurb />
              <Divider variant="middle" />
              <BlogSocialLinks />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

BlogListPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default BlogListPage;
