import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';

import styled from 'styled-components';
import { Button as AntdButton, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-solid-svg-icons';

import { ProviderAPI, useGetApi, useMutateApi } from 'providerSite/api';
import Spinner from 'components/Spinner';
import CompanyDetailForm from './CompanyDetailForm';
import { uploadFile } from 'api/s3';
import { FIELD_IMAGE_PATH } from './contants';


const Button = styled(AntdButton)`
  min-width: 150px;
  display: block;
  margin-left: auto;
  .icon {
    margin-right: 10px;
  }
`;

const CompanyDetail = ({providerUuid}) => {
  const [companyUpdatedData, setCompanyUpdatedData] = useState({});
  const [file, setFile] = useState(null);

  const apiParams = {
    uuid: providerUuid
  };

  const { data, isLoading } = useGetApi(ProviderAPI.GET_PROVIDER, {
    params: apiParams
  });

  const updateMutation = useMutateApi(ProviderAPI.PATCH_PROVIDER, 'PATCH', {
    params: apiParams,
    invalidation: [
      {
        endpoint: ProviderAPI.GET_PROVIDER,
        params: apiParams,
      }
    ],
    onSuccess: () => toast.success('Successfully updated'),
  });

  const { data: uploadedUrlData, isLoading: isGetUploadUrlLoading } = useGetApi(ProviderAPI.GET_PROVIDER_IMAGE_UPLOAD_URL, {
    params: {
      uuid: providerUuid
    },
    queryParams: {
      fileName: file?.name,
      contentType: file?.type
    },
    enabled: !!file
  });

  const onFormDataUpdate = (fieldKey, value) => {
    setCompanyUpdatedData(prevState => ({...prevState, [fieldKey]: value}));
  };

  useEffect(() => {
    if (uploadedUrlData) {
      onFormDataUpdate(FIELD_IMAGE_PATH, uploadedUrlData.url);
    }
  }, [uploadedUrlData]);

  const handleUpdate = async () => {
    if (file) {
      try {
        await uploadFile(uploadedUrlData.signedUrl, file);
      } catch {
        toast.error('Image Upload Failed');
      }
    }
    updateMutation.mutate(companyUpdatedData);
    setCompanyUpdatedData({});
  };

  if (isLoading)
    return <Spinner />;

  return (
    <>
      <CompanyDetailForm
        data={data}
        onUpdate={onFormDataUpdate}
        setFile={setFile}
      />
      <Divider />
      <Button
        className="button"
        size="large"
        type="primary"
        icon={<FontAwesomeIcon className="icon" icon={faPen} />}
        disabled={!Object.keys(companyUpdatedData).length ||
          (!!file && isGetUploadUrlLoading)}
        onClick={handleUpdate}
        loading={updateMutation.isLoading}
      >
        Update
      </Button>
    </>
  );
};

CompanyDetail.propTypes = {
  providerUuid: PropTypes.string
};


export default CompanyDetail;
