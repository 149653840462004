import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import WhitePassportIcon from '../assets/passport-logo-white-text.svg';
import config from 'config';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.nightBlue,
    padding: '2rem 6rem 3rem',
    minHeight: '30vh'
  },
  title: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    color: theme.palette.common.white,
  },
  captionText: {
    width: '100%',
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  list: {
    listStyleType: 'none',
    paddingInlineStart: 0,
  },
  listItem: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.common.white,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  column: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  image: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));

const PassportFooter = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.column}>
        <Grid item md={6} xs={12}>
          <img
            src={WhitePassportIcon}
            alt="clickability-passport-logo"
            height="70"
            className={classes.image}
          />
          <Typography className={classes.captionText}>
            © Lantern Claims 2021 All rights reserved
          </Typography>
          <Typography className={classes.captionText}>
            The Passport App is provided by Lantern Claims Pty Ltd (ABN 28 606
            978 796)
          </Typography>
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography variant="h6" className={classes.title}>
            Links
          </Typography>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              <a
                className={classes.link}
                href={config.link.passportHelp}
                target="_blank"
                rel="noopener noreferrer"
              >
                Help and Support
              </a>
            </li>
            <li className={classes.listItem}>
              <a
                className={classes.link}
                href={config.link.passportPrivacy}
                target="_blank"
                rel="noopener noreferrer"
              >
                Legal and Privacy
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};

export default PassportFooter;
