import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import ClickaConnectTable from './components/ClickaConnectTable';
import ConnectDashboard from '../../components/ConnectDashboard';
import AddButton from '../../components/AddButton';
import Header from '../../components/Header';
import ServiceRequestDetail from './ServiceRequestDetail';
import {
  NAV_SIZE_MAX,
  VERIFICATION_CREATE_SR_ERROR
} from '../../components/constants';


const ClickaConnect = ({ isItemSelected, selectedItem, onItemSelect }) => {
  const { uuid: providerUuid } = useParams();
  const history = useHistory();
  const location = useLocation();
  const profile = useSelector(state => state.auth.profile);

  const fromServiceRequestSubmission = location.state?.fromServiceRequestSubmission;

  useEffect(() => {
    fromServiceRequestSubmission && history.replace(location.pathname, {
      ...location.state,
      fromServiceRequestSubmission: false
    });
  }, [location.pathname]);

  if (isItemSelected) {
    return <ServiceRequestDetail serviceRequestUuid={selectedItem} providerUuid={providerUuid} />;
  }

  return (
    <ConnectDashboard
      title="Clickability Connect"
      navSize={fromServiceRequestSubmission && NAV_SIZE_MAX}
    >
      <Header text="Clickability Connect">
        <AddButton
          text="New Service Request"
          onClick={() => {
            if (profile?.activated) {
              history.push('service-request');
            } else {
              toast.error(VERIFICATION_CREATE_SR_ERROR);
            }
          }}
        />
      </Header>
      <ClickaConnectTable
        providerUuid={providerUuid}
        onRowClick={row => onItemSelect(row.uuid)}
      />
    </ConnectDashboard>
  );
};

ClickaConnect.propTypes = {
  isItemSelected: PropTypes.bool,
  selectedItem: PropTypes.string,
  onItemSelect: PropTypes.func,
};


export default ClickaConnect;
