const ACCESS_MAP = {
  'clickability-test.com.au': {
    url: 'https://clickability.com.au',
    query: 'test-access-please',
    storageKey: 'test-access',
  },
};

// This hook is to prevent the public from wandering onto our test site.
// To use a restricted site, you must visit the site ONCE with the magic query string:
//   eg https://clickability-test.com.au?test-access-please
// and this will set a local storage value that will allow continued access.
// If that query string is NOT present, and the local storage flag is not set, you will
// be redirected to the prod site.

const restrictAccess = () => {
  const access = ACCESS_MAP[window.location.hostname];
  if (access) {
    const params = new URLSearchParams(window.location.search.substring(1));
    if (params.get(access.query) !== null) {
      // if magic query string is present, set the flag and allow access
      localStorage.setItem(access.storageKey, true);
    } else if (!localStorage.getItem(access.storageKey)) {
      // else if the flag is not set, redirect
      window.location = access.url + window.location.pathname + window.location.search + window.location.hash;
    }
  }
};

export default restrictAccess;
