// Services
export const SERVICE_PLAN_MANAGEMENT = 'Plan Management';

// Access Methods
export const ACCESS_METHOD_TELEHEALTH = 'Telehealth';
export const ACCESS_METHOD_ONLINE_SERVICE = 'Online service';

// Subscription Tiers
export const SUBSCRIPTION_TIER_FREE = 'free';
export const SUBSCRIPTION_TIER_STARTER = 'starter';
export const SUBSCRIPTION_TIER_ACCREDITATION_PLUS = 'accreditation_plus';
export const SUBSCRIPTION_TIER_ENTERPRISE = 'enterprise';
export const SUBSCRIPTION_TIER_TEMPORARY_ACCREDITATION = 'temporary_accreditation';

// Billing Type
export const BILLED_TYPE_ANNUALLY = 'annually';
export const BILLED_TYPE_QUARTERLY = 'quarterly';
