import { makePath, request } from './http';
const API_NAME = 'billing';

export const STATUS_ACTIVE = 'Active';
export const STATUS_CANCELED = 'Canceled';
export const STATUS_EXPIRED = 'Expired';
export const STATUS_PAST_DUE = 'Past Due';
export const STATUS_PENDING = 'Pending';
export const STATUS_NONE = 'None';

export const FINANCE_REPORT = {
  TRANSACTIONS: 'transactions',
  ACCREDITATION: 'accreditation',
};

// this version is unauthenticated, and generates a token for a new braintree customer
// created specifically for this operation.  It returns {customer_id, client_token}.
export function getClientToken({anonymous=false}) {
  return request('GET', makePath(API_NAME, 'clienttoken'), {params:{anonymous}});
}

// this version is authenticated, and generates a token for the braintree customer
// associated with the provider (creating one if none exists).
export function getProviderClientToken(providerUuid) {
  return request('GET', makePath(API_NAME, 'clienttoken', providerUuid));
}

export function getPaymentMethods(providerUuid) {
  return request('GET', makePath(API_NAME, 'paymentmethod', providerUuid));
}

export function selectPaymentMethod(providerUuid, paymentMethodToken) {
  return request('PUT', makePath(API_NAME, 'paymentmethod', providerUuid, paymentMethodToken));
}

export function addPaymentMethod(providerUuid, paymentMethodNonce, makeDefault) {
  return request('POST', makePath(API_NAME, 'paymentmethod', providerUuid, paymentMethodNonce), { params: {make_default: makeDefault} });
}

export function deletePaymentMethod(providerUuid, paymentMethodToken) {
  return request('DELETE', makePath(API_NAME, 'paymentmethod', providerUuid, paymentMethodToken));
}

export function getPlans() {
  return request('GET', makePath(API_NAME, 'plans'));
}

export function getSubscription(providerUuid) {
  return request('GET', makePath(API_NAME, 'subscription', providerUuid));
}

export function createSubscription(providerUuid, paymentMethodNonce, planId, subscriptionTierName, offerId, contactUserUuid=null) {
  return request('POST', makePath(API_NAME, 'subscription', providerUuid), {
    params: {
      'payment_method_nonce': paymentMethodNonce,
      plan_id: planId,
      subscription_tier: subscriptionTierName,
      contact_user_uuid: contactUserUuid,
      offer_id: offerId,
    }
  });
}

export function getProviderBillingEnrolment(code) {
  return request('GET', makePath(API_NAME, 'enroll'), {params: {code}});
}

export function setProviderBillingEnrolment(code, paymentMethodNonce, planSelection) {
  return request('POST', makePath(API_NAME, 'enroll'), {params: {code, 'payment_method_nonce': paymentMethodNonce, plan_selection: planSelection}});
}

export function cancelSubscription(providerUuid, cancelledReasons) {
  return request('DELETE', makePath(API_NAME, 'subscription', providerUuid), {params: {reasonsForCancellation: cancelledReasons}});
}

export function updateSubscription(providerUuid, extraListings, prorateBilling) {
  return request('PATCH', makePath(API_NAME, 'subscription', providerUuid), {
    params: {
      extra_listings: extraListings,
      prorate_billing: prorateBilling
    }
  });
}

export function getFinanceReportUrl(reportType, fromDate, toDate) {
  return request('GET', makePath(API_NAME, 'financereport'), {params: {report_type: reportType, from_date: fromDate, to_date: toDate}});
}

export function getOverdueSubscriptions() {
  return request('GET', makePath(API_NAME, 'overduesubscriptions'));
}

export function getFailedMatchingPayments() {
  return request('GET', makePath(API_NAME, 'failedmatchingpayments'));
}

export function retrySubscriptionPayment(subscriptionId) {
  return request('POST', makePath(API_NAME, 'retrysubscriptionpayment'), {params: {subscription_id: subscriptionId}});
}

export function getInvoiceItems(providerUuid) {
  return request('GET', makePath(API_NAME, 'invoiceitem', providerUuid));
}

export function getNextMatchingFees(providerUuid) {
  return request('GET', makePath(API_NAME, 'nextmatchingfees', providerUuid));
}

export function deleteInvoiceItem(providerUuid, invoiceItemId) {
  return request('DELETE', makePath(API_NAME, 'invoiceitem', providerUuid, invoiceItemId));
}

export function createInvoiceItem(providerUuid, {due_date, amount_excl, description, repeat_count}) {
  const item = {
    due_date,  // required
    amount_excl,  // required
    description,  // required
    repeat_count,
  };
  return request('POST', makePath(API_NAME, 'invoiceitem', providerUuid), {body: item});
}

export function getInvoiceDetails(providerUuid) {
  return request('GET', makePath(API_NAME, 'invoice', providerUuid));
}

export function updateAllowFreeTrial(providerUuid, allowFreeTrial) {
  return request('POST', makePath(API_NAME, 'allowfreetrial', providerUuid), {body: {allow_free_trial: allowFreeTrial}});
}
