import PropTypes from 'prop-types';
import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
});

const SpecialisationMenu = ({allSpecialisations, specialisations, onClick}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const handleClick = (specialisation) => {
    setAnchorEl(null);
    onClick(specialisation);
  };
  const newSpecialisations = allSpecialisations.filter(s => !(s.id in specialisations));
  return (
    <div className={classes.container}>
      <Button
        aria-controls="specialisation-menu"
        aria-haspopup="true"
        onClick={e => setAnchorEl(e.currentTarget)}
        startIcon={<AddIcon />}
        variant="contained"
        color="primary"
        disabled={newSpecialisations.length === 0}
      >
        Add a specialisation
      </Button>
      <Menu
        id="specialisation-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={()=>setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        {allSpecialisations.map(s =>
          <MenuItem
            key={s.id}
            onClick={() => handleClick(s)}
            disabled={s.id in specialisations}
          >
            {s.name}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

SpecialisationMenu.propTypes = {
  allSpecialisations: PropTypes.array.isRequired,
  specialisations: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SpecialisationMenu;
