import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  question: {
    width: '100%',
    paddingTop: '4rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
    },
  },
  options: {
    width: '100%',
    padding: '4rem',
    marginTop: '2rem',
    marginBottom: '16rem',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '4rem 0',
      marginTop: 0,
      marginBottom: '1rem',
    },
  },
  option: {
    margin: '0 2rem',
    maxWidth: '30rem',
    padding: '3rem 2rem',
    border: `2px solid ${theme.palette.common.silver}`,
    cursor: 'pointer',
    borderRadius: '3px',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '&:hover': {
      border: `2px solid ${theme.palette.secondary.main}`,
      boxShadow: `0px 0px 2px 1px ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      marginBottom: '4rem',
    },
  },
  optionText: {
    fontSize: '1.3rem',
    textAlign: 'left',
    paddingLeft: '1rem',
  },
  number: {
    width: '3rem',
    height: '3rem',
    padding: '0 1rem',
    lineHeight: '3rem',
    marginRight: '0.5rem',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
      marginRight: '0',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

const DecisionInput = ({ question, options }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h2" color="primary" align="center" className={classes.question}>
        {question}
      </Typography>
      <div className={classes.options}>
        {options.map((opt, i) => (
          <Link key={i} to={opt.link} className={classes.link}>
            <div className={classes.option}>
              <Typography variant="subtitle2" align="center" className={classes.number}>
                {i + 1}
              </Typography>
              <Typography variant="body2" className={classes.optionText}>
                {opt.text}
              </Typography>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

DecisionInput.propTypes = {
  question: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default DecisionInput;
