import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker as AntdDatePicker } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import Card from '../components/Card';


const Container = styled.div`
  position: relative;
  margin-top: 2px;
  
  .title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;

const DatePicker = styled(AntdDatePicker)`
  width: 100%;
  input {
    cursor: pointer;
    caret-color: transparent;
    font-weight: 500;
  }
  .ant-picker-input {
    border-bottom: 1px solid;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
  }
`;


const Datepicker = (
  {
    title,
    onChange,
  }) => {

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  return (
    <Card
      title={title}
      style={{padding: '6px 5px 7px 5px'}}
    >
      <Container>
        <DatePicker
          disabledDate={disabledDate}
          onChange={(_, value) => onChange(value)}
          bordered={false}
          allowClear
          showToday={false}
        />
      </Container>
    </Card>
  );
};

Datepicker.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};


export default Datepicker;