import React from 'react';
import PropTypes from 'prop-types';

import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';

import Card from '../components/Card';


const Container = styled.div`
  position: relative;
  margin-top: 3px;
  
  .title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;

const Select = styled(AntdSelect)`
  margin-bottom: 5px;

  .ant-select-selector {
    border-bottom: 1px solid #efefef !important;
    border-radius: 0 !important;
    padding: 0 !important;
    height: 28px !important;
  }

  .ant-select-selection-item {
    height: auto !important;
    margin-top: 0;
    font-weight: 500 !important;
  }

  .ant-select-selection-search {
    left: 0 !important;
  }
`;


const Dropdown = (
  {
    title,
    icon,
    defaultData,
    onChange,
    options,
    placeholder = 'Select an option',
  }) => {

  const handleServiceSelect = value => {
    onChange(value);
  };

  const handleServiceClear = () => {
    onChange(null);
  };

  return (
    <Card
      title={title}
      icon={icon}
      style={{paddingTop: '5px', paddingBottom: '7px'}}
    >
      <Container>
        <Select
          style={{
            width: '100%',
          }}
          defaultData={defaultData}
          onSelect={handleServiceSelect}
          onClear={handleServiceClear}
          options={options.map(item => ({label: item, value: item}))}
          bordered={false}
          placeholder={placeholder}
        />
      </Container>
    </Card>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  placeholder: PropTypes.string,
  defaultData: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
};


export default Dropdown;