import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Button as AntdButton, Typography } from 'antd';

import style from 'style';


const Container = styled.div`
  display: flex;
  column-gap: 40px;
  width: 100%;
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 400;
`;

const Content = styled.div`
  margin: 50px 0;
  @media (max-width: ${style.screens.mobile}){
    margin: 30px 0;
  }
`;

const LeftContainer = styled.div`
  width: ${({pageWidth}) => pageWidth ? pageWidth : '70%'};
  position: relative;
  @media (max-width: ${style.screens.mobile}){
    width: 100%;
  }
`;

const RightContainer = styled.div`
  @media (max-width: ${style.screens.mobile}){
    display: none;
  }
`;

const Button = styled(AntdButton)`
  width: 100%;
  height: 50px;
  background-color: ${style.colors.palette.lightSeaGreen};
  color: ${style.colors.palette.blackSlate};
  border-color: ${style.colors.lightBorder};
  max-width: 400px;
  @media (max-width: ${style.screens.mobile}){
    max-width: 100%;
  }
`;

const TextButton = styled.div`
  position: absolute;
  top: -15px;
  left: 0;
  color: ${style.colors.secondary};
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const ProgressContainer = styled.div`
  position: absolute;
  left: 400px;
  top: -10px;
  font-size: 13px;
  color: ${style.colors.secondary};
  font-weight: 500;
  white-space: nowrap;
  @media (max-width: ${style.screens.mobile}){
    left: unset;
    right: 0;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  max-width: ${({pageWidth}) => pageWidth ? pageWidth : '400px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 7px;
  @media (max-width: ${style.screens.mobile}){
    max-width: 100%;
  }
`;

const Footer = styled.div`
  font-size: 11px;
`;


const FlowContainer = (
  {
    children,
    flow,
    setPagename,
    currentPage,
    next,
    prev,
    complete,
    progress,
    progressAction,
    answers,
    setAnswers,
  }) => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [currentPage]);

  const handleNextClick = () => {
    setPagename(next.name);
    progressAction.increase();
  };

  const handleBackClick = () => {
    setPagename(prev.name);
    progressAction.decrease();
  };

  const totalValidPages = flow.pages.filter(item => !item.ignoreCount).length;

  const RightComponent = currentPage.RightComponent;

  const isBlankPage = !!currentPage.blankPage && currentPage.blankPage(answers);

  if (isBlankPage) {
    return (
      <Container>
        <Content>
          {children}
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <LeftContainer pageWidth={currentPage.pageWidth}>
        {!!prev && (
          <TextButton type="text" onClick={handleBackClick}>
            {'< Back'}
          </TextButton>
        )}
        {currentPage.progressbar && (
          <ProgressContainer>
            Step {progress + 1} of {totalValidPages}
          </ProgressContainer>
        )}
        <Typography.Title level={2}>
          {currentPage.title}
        </Typography.Title>
        <Subtitle>
          {currentPage.subtitle}
        </Subtitle>
        <Content>
          {children}
        </Content>
        <FooterContainer pageWidth={currentPage.pageWidth}>
          <Footer>
            {currentPage.footer}
          </Footer>
          {!currentPage.hideButton && (
            <Button
              type="primary"
              size="large"
              onClick={() => {
                !!currentPage.onClick && currentPage.onClick(answers);
                handleNextClick();
              }}
              disabled={!complete}
            >
              {currentPage.buttonText}
            </Button>
          )}
        </FooterContainer>
      </LeftContainer>
      <RightContainer pageWidth={currentPage.pageWidth}>
        {RightComponent && <RightComponent setAnswers={setAnswers} />}
      </RightContainer>
    </Container>
  );
};

FlowContainer.propTypes = {
  children: PropTypes.node.isRequired,
  flow: PropTypes.object.isRequired,
  setPagename: PropTypes.func.isRequired,
  currentPage: PropTypes.object.isRequired,
  next: PropTypes.object,
  prev: PropTypes.object,
  complete: PropTypes.bool,
  progress: PropTypes.number,
  progressAction: PropTypes.object.isRequired,
  answers: PropTypes.object,
  setAnswers: PropTypes.func,
};

export default FlowContainer;