import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import {
  AutoComplete as AntdAutoComplete,
  Button as AntdButton,
  Tabs as AntdTabs,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';
import { TaxonomyAPI, useGetApi } from '../providerSite/api';
import QualifiedServiceIcon from './QualifiedServiceIcon';
import { ProviderAPI } from '../api';
import UseMyLocation from './location/UseMyLocation';
import * as track from 'lib/track';


const TEXTBOX_HEIGHT = '42px';
const FONT_SIZE = '13px';
const SERVICE_REQUEST_TAB_KEY = 'service-request';
const DIRECTORY_TAB_KEY = 'directory';
const CATEGORY_SERVICE_NAMES = [
  'Occupational Therapy',
  'Speech Therapy',
  'Support Work',
  'Plan Management',
  'Psychology',
  'Support Coordination',
];


const TextboxContainer = styled.div`
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  
  @media (max-width: ${style.sizes.large}) {
    flex-direction: column;
    row-gap: 20px;
  }
  
  .use-my-location {
    margin-top: 7px;
    color: ${style.colors.primary};
  }
  
  .use-my-location-button {
    div {
      font-size: 12px;
    }
    :hover {
      color: ${style.colors.secondary};
    }
  }
  
  .use-my-location-spinner {
    color: ${style.colors.secondary};
  }
  
  .use-my-location-icon {
    font-size: 15px;
    margin-top: 1px;
  }
`;

const Question = styled.div`
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 14px;
  color: ${style.colors.primary};
`;

const SubTitle = styled.div`
  font-size: 14px;
  color: ${style.colors.primary};
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .tag-line {
    color: #008275;
  }

  @media (max-width: ${style.sizes.large}) {
    flex-direction: column;
  }
`;

const AutoComplete = styled(AntdAutoComplete)`
  min-width: 250px;
  height: ${TEXTBOX_HEIGHT};
  font-size: ${FONT_SIZE};

  .ant-select-selector {
    height: ${TEXTBOX_HEIGHT} !important;
    border-color: ${style.colors.secondary} !important;
    background: rgba(255, 255, 255, 0.20) !important;
    color: ${style.colors.primary} !important;
  }

  .ant-select-selection-search-input {
    height: ${TEXTBOX_HEIGHT} !important;
    font-weight: 500 !important;
  }

  .ant-select-selection-placeholder {
    line-height: ${TEXTBOX_HEIGHT} !important;
    color: rgba(5, 14, 64, 0.6) !important;
  }
  
  .ant-select-clear {
    background: none;
  }
  
  @media (max-width: ${style.sizes.large}) {
    min-width: 150px;
  }
`;

const Tabs = styled(AntdTabs)`
  filter: drop-shadow(0px 3px 3px rgba(77, 77, 77, 0.2));

  &.ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
  }

  &.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #90e0d8;
    background: linear-gradient(170deg, #90e0d8 30%, #bfe8e5 100%);
    border-radius: 20px;
    border-top-left-radius: 0;
    padding: 25px 30px 20px 30px;
    min-height: 330px;

    @media (max-width: 400px) {
      border-top-right-radius: 0;
    }
  }

  &.ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }

  &.ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .ant-tabs-card .ant-tabs-tab {
    background: ${style.colors.secondary};
    border-color: transparent;
    color: ${style.colors.palette.white};
    min-width: 220px;
    text-align: center;
    display: flex;
    justify-content: center;
    font-weight: 500;
    min-height: 48px;

    @media (max-width: ${style.sizes.large}) {
      min-width: 150px;
      font-size: 12px;
    }
  }

  &.ant-tabs-card .ant-tabs-tab-active,
  [data-theme='compact'] .ant-tabs-card .ant-tabs-tab-active {
    background: #90e0d8;
    border-color: transparent;
    font-weight: 700;

    .ant-tabs-tab-btn {
      color: ${style.colors.primary};
    }
  }

  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-color: transparent;
  }
`;

const Button = styled(AntdButton)`
  background-color: ${style.colors.secondary};
  border-color: ${style.colors.secondary};
  color: #f6f6f6;
  font-size: 13px;
  font-weight: 700;
  border-radius: 20px;
  width: 200px; 
  height: 45px;
  
  @media (max-width: ${style.sizes.medium}) {
    width: 100%;
  }

  :disabled {
    color: #f6f6f6;
    background-color: ${style.colors.secondary};
    border-color: ${style.colors.secondary};

    :hover {
      background-color: ${style.colors.secondary};
      color: #f6f6f6;
      border-color: ${style.colors.secondary};
    }
  }

  :hover {
    background-color: ${style.colors.secondary};
    color: ${style.colors.primary};
    border-color: ${style.colors.secondary};
  }
`;

const CategoriesContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  row-gap: 10px;
`;


const SearchPanel = () => {
  const history = useHistory();
  const [serviceValue, setServiceValue] = useState();
  const [selectedServiceValue, setSelectedServiceValue] = useState(null);

  const [locationOptions, setLocationOptions] = useState([]);
  const [locationInputValue, setLocationInputValue] = useState('');
  const [locationValue, setLocationValue] = useState(null);

  const [currentTab, setCurrentTab] = useState('service-request');

  const {
    data: _serviceTaxonomy
  } = useGetApi(TaxonomyAPI.GET_TAXONOMY, {
    queryParams: {
      name: 'qualified_service',
    },
    staleTime: 60,   // 1 hour
  });

  function compare( a, b ) {
    if ( a.serviceName < b.serviceName ){
      return -1;
    }
    if ( a.serviceName > b.serviceName ){
      return 1;
    }
    return 0;
  }

  const _SortedServiceTaxonomy = _serviceTaxonomy?.sort(compare);

  const _getKey = index => `Option Row - ${index}`;
  const { serviceTaxonomy } = _SortedServiceTaxonomy?.reduce((acc, item, index) => {
    acc.serviceTaxonomy.push({
      label: (
        <Row
          key={_getKey(index)}
          name={item.serviceName}
        />
      ),
      value: item.serviceName,
      data: item,
    });
    acc.serviceTaxonomyMap[item.serviceName] = item;
    return acc;
  }, {serviceTaxonomy: [], serviceTaxonomyMap: {}}) || {};

  const handleServiceChange = value => {
    setServiceValue(value);
    setSelectedServiceValue(null);
  };

  const handleServiceSelect = (value, option) => {
    setServiceValue(value);
    setSelectedServiceValue(option.data);
  };

  const handleServiceLeaving = () => {
    selectedServiceValue === null && setServiceValue(null);
  };

  const onLocationClick = location => {
    setLocationValue(location);
  };

  const handleButtonClick = () => {
    if (currentTab === SERVICE_REQUEST_TAB_KEY) {
      track.sendEvent('SearchPanel button - connect me', 'click');
      history.push(`/requestmatch#service?name=${serviceValue}&location=${locationValue}`);
    } else {
      track.sendEvent('SearchPanel button - find providers', 'click');
      history.push(`/search?service=${serviceValue}&service_location=${locationValue}`);
    }
  };

  useEffect(() => {
    if (locationInputValue.length < 2) return;
    ProviderAPI.locationAutocomplete(locationInputValue).then(r => {
      setLocationOptions(r.payload?.map(item => ({label: item, value: item})));
    });
  }, [locationInputValue]);

  const render = () => {
    return (
      <Container>
        {currentTab === SERVICE_REQUEST_TAB_KEY && (
          <SubTitle>
            Find your perfect provider, with personalised recommendations that match your exact needs.
          </SubTitle>
        )}
        <TextboxContainer>
          <div>
            <Question>
              What are you looking for?
            </Question>
            <AutoComplete
              allowClear
              filterOption={true}
              value={serviceValue}
              options={serviceTaxonomy}
              style={{width: '100%'}}
              size="large"
              onChange={handleServiceChange}
              onSelect={handleServiceSelect}
              onBlur={handleServiceLeaving}
              placeholder="Support Work ..."
            />
          </div>
          <div>
            <Question>
              Where do you need the service?
            </Question>
            <AutoComplete
              allowClear
              filterOption={true}
              value={locationValue}
              options={locationOptions}
              style={{width: '100%'}}
              size="large"
              onChange={val => setLocationValue(val)}
              onSearch={val => setLocationInputValue(val)}
              placeholder="Suburb or postcode"
            />
            <UseMyLocation
              className="use-my-location"
              classNames={{
                button: 'use-my-location-button',
                spinner: 'use-my-location-spinner',
                icon: 'use-my-location-icon',
              }}
              onSuccess={onLocationClick}
            />
          </div>
        </TextboxContainer>
        {currentTab === DIRECTORY_TAB_KEY && (
          <>
            <div style={{marginBottom: '10px', color: style.colors.primary}}>
              or browse our list of providers by category
            </div>
            <CategoriesContainer>
              {CATEGORY_SERVICE_NAMES.map((item, idx) => (
                <CategoryButton key={idx} name={item} onClick={setServiceValue} />
              ))}
            </CategoriesContainer>
          </>
        )}
        <BottomContainer>
          {currentTab === DIRECTORY_TAB_KEY ? <div /> : (
            <div className="tag-line">
              It only takes a few minutes to get started.<br/>
              We will match you up <strong>FREE</strong>, and you decide.
            </div>
          )}
          <Button
            type="primary"
            onClick={handleButtonClick}
            disabled={!serviceValue || !locationValue}
          >
            {currentTab === SERVICE_REQUEST_TAB_KEY ? 'Find My Perfect Provider' : 'Browse Directory'}
            <FontAwesomeIcon icon={faSearch} style={{marginLeft: '5px', verticalAlign: '-2px'}}/>
          </Button>
        </BottomContainer>
      </Container>
    );
  };

  return (
    <Tabs type="card" onChange={ak => setCurrentTab(ak)}>
      <Tabs.TabPane tab="Connect me with providers" key={SERVICE_REQUEST_TAB_KEY}>
        {render()}
      </Tabs.TabPane>
      <Tabs.TabPane tab="Search the directory" key={DIRECTORY_TAB_KEY}>
        {render()}
      </Tabs.TabPane>
    </Tabs>
  );
};

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 38px;
  
  .icon {
    width: 20px;
    color: ${style.colors.primary};
  }
`;

const Row = ({ name }) => {
  return (
    <RowContainer>
      <QualifiedServiceIcon.Regular serviceName={name} className="icon" />
      <div>{name}</div>
    </RowContainer>
  );
};

Row.propTypes = {
  name: PropTypes.string,
};

const CategoryButtonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  justify-content: center;
  background: rgba(255, 255, 255, 0.30);
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 13px;
  flex-grow: 1;
  border: 1px solid ${style.colors.secondary};
  box-shadow: ${style.shadow(10)};
  cursor: pointer;
  font-weight: 500;
  
  :hover {
    background: rgba(255, 255, 255, 0.50);
  }

  .icon {
    width: 20px;
    font-size: 12px;
    color: ${style.colors.primary};
  }
`;

const CategoryButton = ({ name, onClick }) => {
  return (
    <CategoryButtonContainer onClick={() => onClick(name)}>
      <QualifiedServiceIcon.Regular serviceName={name} className="icon" />
      <div>{name}</div>
    </CategoryButtonContainer>
  );
};

CategoryButton.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default SearchPanel;

