import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1rem 0',
  },
  label: {
    width: '100%',
    marginBottom: '0.5rem',
  },
});

const SwitchInputField = ({label, switchLabel, value, ...props}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      <FormControlLabel
        label={switchLabel}
        control={<Switch checked={value} {...props} />}
      />
    </div>
  );
};

SwitchInputField.propTypes = {
  label: PropTypes.string.isRequired,
  switchLabel: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

export default SwitchInputField;
