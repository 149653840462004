import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {Helmet} from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    padding: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const DEFAULT_MESSAGE = 'You do not have permission to view this page';

const PermissionDenied = ({message}) => {
  const classes = useStyles();
  const text = message || DEFAULT_MESSAGE;
  return (
    <div className={classes.root}>
      <Helmet meta={[ {name: 'robots', content: 'noindex'} ]} />
      <Alert severity="error">{text}</Alert>
    </div>
  );
};

PermissionDenied.propTypes = {
  message: PropTypes.string,
};

export default PermissionDenied;
