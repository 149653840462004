import React from 'react';
import PropTypes from 'prop-types';

import {
  faHandsHoldingCircle, faClipboardList, faDumbbell, faHandHoldingSeedling,
  faHandsHelping, faHeadSideBrain, faLips, faToolbox, faUserFriends,
  faVacuum, faWallet, faPeopleRoof, faHouseHeart, faLanguage, faPlateUtensils,
  faUsersMedical, faBed, faWheelchair, faSteeringWheel, faGraduationCap,
  faBriefcase, faBookOpenReader, faCar, faCartCircleCheck, faUniversalAccess,
  faHouseChimneyUser, faMobileScreenButton, faPeopleGroup, faHandHoldingHeart,
  faDogLeashed, faPersonHalfDress, faPaintbrushPencil, faPlaneDeparture,
  faCalendarHeart, faIcons, faHeartPulse, faPalette, faEarListen, faBowlSpoon,
  faChildren, faPersonWalking, faHands, faUserMusic, faShoePrints, faUmbrellaBeach,
  faPersonCircleQuestion, faLaptopMobile, faMemoCircleInfo, faBuildingColumns,
  faBedAlt, faUsersClass, faHandBackFist, faHand, faMapPin, faHeadSideGoggles,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {faClipboardCheck} from '@fortawesome/pro-regular-svg-icons';
import {makeStyles} from '@material-ui/core/styles';


const ICON_MAP = {
  'Support Work': faUserFriends,
  'Plan Management': faWallet,
  'Occupational Therapy': faHandsHoldingCircle,
  'Cleaning': faVacuum,
  'Speech Therapy': faLips,
  'Physiotherapy': faDumbbell,
  'Psychology': faHeadSideBrain,
  'Psychosocial Recovery Coaching': faHandsHelping,
  'Support Coordination': faClipboardList,
  'Specialist Support Coordination': faClipboardCheck,
  'Gardening': faHandHoldingSeedling,
  'Behavioural Support': faToolbox,
  'Day Centre': faPeopleRoof,
  'Housing': faHouseHeart,
  'Interpreters': faLanguage,
  'Meals': faPlateUtensils,
  'Nursing Services': faUsersMedical,
  'Respite Accommodation': faBed,
  'Transport': faWheelchair,
  'Driving': faSteeringWheel,
  'Education Support': faGraduationCap,
  'Employment Services': faBriefcase,
  'SLES and DES': faBriefcase,
  'Training':faBookOpenReader,
  'Car Modifications': faCar,
  'Consumables': faCartCircleCheck,
  'Equipment':  faUniversalAccess,
  'Home Modifications and Maintenance': faHouseChimneyUser,
  'Software and Apps': faMobileScreenButton,
  'Advocacy and Peer Support': faPeopleGroup,
  'Therapy and Assessment': faPeopleGroup,
  'Crisis Services': faHandHoldingHeart,
  'Service Animals and Pets':faDogLeashed,
  'Sexuality': faPersonHalfDress,
  'Creative Arts':faPaintbrushPencil,
  'Holidays and Travel':  faPlaneDeparture,
  'School holiday activities':faCalendarHeart,
  'Social Activities': faIcons,
  'Sport and Fitness': faHeartPulse,
  'Art Therapy': faPalette,
  'Audiology': faEarListen,
  'Dietetics': faBowlSpoon,
  'Early Childhood Therapy': faChildren,
  'Exercise Physiology': faPersonWalking,
  'Massage': faHands,
  'Music Therapy': faUserMusic,
  'Osteopathy': faHands,
  'Podiatry, Orthopaedics and Prosthetics': faShoePrints,
  'Therapy Assistants': faHandHoldingHeart,
  'Daily Living and Access': faUniversalAccess,
  'Recreation and Leisure': faUmbrellaBeach,
  'Other': faPersonCircleQuestion,
  'Equipment and Technology': faLaptopMobile,
  'Information and Advocacy': faMemoCircleInfo,
  'Education and Jobs': faBuildingColumns,
  'Respite Accomodation': faBedAlt,
  'Social Work and Counselling': faUsersClass,
  'Chiropractor':faHandBackFist,
  'Myotherapy':faHand,
  'Acupuncture':faMapPin,
  'Innovative Community Participation':faHeadSideGoggles,
};

// Due to icon shapes, some icons might not look entirely centered, hence this
// config is used to center them.
const CUSTOM_ICON_CONFIG = {
  'Occupational Therapy': {
    size: '16px',
  },
  'Specialist Support Coordination': {
    size: '18px',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '30px',
    height: '30px',
    backgroundColor: theme.palette.common.tealish,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid',
    borderColor: theme.palette.common.white,
    filter: 'drop-shadow(0 0 4px #9e9e9e)',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: ({iconSize}) => iconSize ? iconSize : '12px',
    marginLeft: ({marginLeft}) => marginLeft && marginLeft,
  },
  regularIcon: {
    fontSize: ({iconSize}) => iconSize ? iconSize : '16px',
    marginLeft: ({marginLeft}) => marginLeft && marginLeft,
  },
}));

const QualifiedServiceIconCircle = ({serviceName, ...props}) => {
  const customConfig = serviceName && CUSTOM_ICON_CONFIG[serviceName];
  const iconSize = customConfig?.size;
  const marginLeft = customConfig?.marginLeft;

  const classes = useStyles({iconSize: iconSize, marginLeft: marginLeft, ...props});

  if (!serviceName)
    return null;

  return (
    <div className={classes.root}>
      <FontAwesomeIcon icon={ICON_MAP[serviceName]} className={classes.icon}/>
    </div>
  );
};

const QualifiedServiceIconRegular = ({serviceName, className}) => {
  const customConfig = serviceName && CUSTOM_ICON_CONFIG[serviceName];
  const iconSize = customConfig?.size;
  const marginLeft = customConfig?.marginLeft;

  const classes = useStyles({iconSize: iconSize, marginLeft: marginLeft});

  if (!serviceName)
    return null;

  return (
    <FontAwesomeIcon
      icon={ICON_MAP[serviceName]}
      className={classNames(classes.regularIcon, className)}
    />
  );
};

QualifiedServiceIconCircle.propTypes = {
  serviceName: PropTypes.string,
};

QualifiedServiceIconRegular.propTypes = {
  serviceName: PropTypes.string,
  className: PropTypes.string,
};

export default {
  Circle: QualifiedServiceIconCircle,
  Regular: QualifiedServiceIconRegular,
};