const SET_LOADING = 'document/SET_LOADING';
const SET_FILES = 'document/SET_FILES';

const initialState = {
  isLoading: true,
  files: [],
  total: 0,
  limit: 25,
  offset: 0,
};

// Action Creators

export function isLoading(attribute, payload) {
  return {
    type: SET_LOADING,
    attribute: attribute,
    payload: payload,
  };
}

export function setFiles(payload, total, limit, offset) {
  return {
    type: SET_FILES,
    payload: payload,
    total: total,
    limit: limit,
    offset: offset,
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case SET_LOADING:
    return {
      ...state,
      isLoading: action.payload,
    };

  case SET_FILES:
    return {
      ...state,
      files: action.payload,
      total: action.total,
      limit: action.limit,
      offset: action.offset,
      isLoading: false,
    };

  default:
    return state;
  }
}
