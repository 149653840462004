import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import classNames from 'classnames';


const StyledRating = withStyles(theme => ({
  iconFilled: {
    color: theme.palette.common.nightBlue,
  },
  iconHover: {
    color: theme.palette.common.nightBlue,
  },
}))(Rating);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiRating-sizeLarge': {
      fontSize: '70px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '55px',
      },
    }
  },
  title: {
    fontSize: '28px',
    fontWeight: '500',
    textAlign: 'center',
    color: theme.palette.common.black,
  },
}));

const RatingQuestion = ({ title, value, onChange, className }) => {

  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.title}>{title}</div>
      <StyledRating
        name="massive-stars"
        size="large"
        value={value === undefined ? 0 : value}
        onChange={(_, value) => onChange(value)}
      />
    </div>
  );
};

RatingQuestion.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default RatingQuestion;
