import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import Markdown from 'components/Markdown';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4rem',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 28px',
      textAlign: 'justify',
    },
    backgroundColor: theme.palette.common.paleGrey,
    columnGap: '64px',
  },
  text: {
    maxWidth: '40rem',
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-h2': {
        textAlign: 'center',
      },
    },
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    order: ({reverse}) => reverse && '1',
  },
  img: {
    borderRadius: '1rem',
    width: '25rem',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
  },
}));

const ExplanationText = ({text, image, className, reverse=false}) => {
  const classes = useStyles({reverse: reverse});

  return (
    <div className={classNames(classes.root, className)}>
      {image &&
        <div className={classes.imageContainer}>
          <img
            src={image.src}
            alt={image.alt}
            title={image.title}
            className={classes.img}
          />
        </div>
      }
      <div className={classes.text}>
        <Markdown content={text}/>
      </div>
    </div>
  );
};

ExplanationText.propTypes = {
  text: PropTypes.string,
  image: PropTypes.object,
  className: PropTypes.string,
  reverse: PropTypes.bool,
};

export default ExplanationText;
