
const actions = {
  ACCEPT: 'accept',
  DECLINE: 'decline',
};

const messages = {
  CLAIMED: 'Service Request claimed',
  ALREADY_ACCEPTED: 'Service Request already accepted',
  ALREADY_DECLINED: 'Service Request already declined',
  CLOSED: 'Service Request not available',
  EXPIRED: 'Service Request expired',
  NOT_ACCREDITED: /Provider .+ is not accredited/,
  AVAILABLE: 'Service Request available',
};

const statuses = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  ALREADY_ACCEPTED: 'already accepted',
  ALREADY_DECLINED: 'already declined',
  NOT_ACCREDITED: 'not accredited',
  INVALID_REQUEST: 'invalid request',
  CLOSED: 'closed',
  EXPIRED: 'expired',
  AVAILABLE: 'available',
};

export {
  actions,
  messages,
  statuses,
};

