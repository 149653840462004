import PropTypes from 'prop-types';
import React from 'react';
import SectionTitle from 'components/SectionTitle';
import Typography from '@material-ui/core/Typography';
import icon from 'assets/logo-icon.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '1rem 0',
  },
  list:{
    paddingInlineStart: '0',
    listStyleType: 'none',
  },
  item: {
    padding: '0.3rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  img: {
    height: '1.5rem',
    marginRight: '0.5rem',
  },
});

const ItemListContainer = ({title, children}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SectionTitle title={title} />
      {children}
    </div>
  );
};

ItemListContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const ItemList = ({title, data}) => {
  const classes = useStyles();
  return (
    <ItemListContainer title={title}>
      <ul className={classes.list}>
        {data?.map(item =>
          <li key={item.id} className={classes.item}>
            <img src={icon} className={classes.img} alt="icon" width="24" height="24"/>
            <Typography aria-label={item.name} variant="body2">{item.name}</Typography>
          </li>
        )}
      </ul>
    </ItemListContainer>
  );
};

ItemList.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default ItemList;

export { ItemListContainer };
