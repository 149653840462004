import * as QueryString from 'query-string';
// eslint-disable-next-line
import * as gtm from 'lib/gtm';
import * as track from 'lib/track';

import {
  AccreditationBadge,
  AdminAlertButton,
  ClaimProfile,
  LoadingSkeleton,
  MainImage,
  PageTitles,
  ProfileInfoPanel,
  ReviewButtons,
  TabsPanel,
  WidgetBar,
} from '../components';
import { EDIT_PROVIDER, hasPermission } from 'lib/permissions';

import Container from '@material-ui/core/Container';
import Error404 from 'modules/pages/Error404';
import Grid from '@material-ui/core/Grid';
import LocationPanel from 'components/location/LocationPanel';
import MetaTags from 'components/MetaTags';
import Page from 'components/Page';
import PropTypes from 'prop-types';
import { ProviderAPI } from 'api';
import { ProviderStore } from 'store';
import ProfileBrokerageCTA from 'components/cta/ProfileBrokerageCTA.js';
import React from 'react';
import config from 'config';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

const commonStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    minHeight: '65vh',
    padding: '2rem 1rem',
  },
  leftPane: {
    paddingRight: '3rem',
  },
  mobilePane: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
}));

const ProfilePage = ({ dispatch, match, data, isLoading, location }) => {
  const classes = commonStyles();
  const history = useHistory();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const uuidOrSlug = match.params.uuid;
  const query = QueryString.parse(location.search);
  const initialTab = (('tab' in query) ? parseInt(query.tab) : 1) - 1; // 1 indexed is easier for normal people
  const [tab, setTab] = React.useState(initialTab);
  const [notFound, setNotFound] = React.useState(false);
  const providerUuid = data.uuid;
  const {isAuthenticated, profile} = useSelector(state => state.auth);
  const hasEditProfilePermission = isAuthenticated && hasPermission(profile, providerUuid, EDIT_PROVIDER);

  // simplify subsequent components calls
  data['scope'] = 'provider';
  data['provider_uuid'] = data.uuid;
  data['provider_name'] = data.name;

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    track.sendEvent('provider profile tab', 'click', { value: newValue });
    history.push(`${location.pathname}?tab=${newValue + 1}`);
  };

  React.useEffect(() => {
    const fetchProfile = async () => {
      dispatch(ProviderStore.isLoading('profile', true));
      try {
        const { payload } = await ProviderAPI.getProfile(uuidOrSlug);
        dispatch(ProviderStore.setProfile(payload));
        // gtm.sendEvent('provider', { uuid: payload.uuid });
        track.sendEvent('provider profile', 'view', {
          slug: payload.slug,
        }, [payload.uuid]);
        history.replace('/provider/old/' + payload.slug + location.search);
        setNotFound(false);
      } catch (err) {
        if (err.status === 404) {
          setNotFound(true);
        }
        console.error(err); // eslint-disable-line no-console
      }
    };
    fetchProfile();
  }, [isAuthenticated, uuidOrSlug]); // eslint-disable-line

  // required for browser back button to work with tabs
  React.useEffect(() => {
    setTab(initialTab);
  }, [initialTab]); // eslint-disable-line

  // Not found may indicate a profile is not public and the user is not authenticated or doesn't have access to view it.
  // We don't redirect to 404 because subsequent authentication may make the page visible - this is particularly important
  // when a new tab is opened, and it takes a moment for the tab to get an authentication token.
  if (notFound) {
    return <Error404/>;
  }

  if (isLoading) {
    return (
      <Page title={`${data.name} - Clickability`}>
        <LoadingSkeleton />
      </Page>
    );
  }

  const renderMobileLayout = () => {
    return (
      <Container maxWidth="sm" disableGutters={true}>
        <div className={classes.mobilePane}>
          <MainImage data={data} />
          <PageTitles data={data} />
          <ProfileBrokerageCTA />
          <ClaimProfile data={data} />
          <AccreditationBadge data={data} />
          {hasEditProfilePermission && <AdminAlertButton data={data} />}
          <ReviewButtons data={data} />
          <WidgetBar data={data} />
          <TabsPanel data={data} tab={tab} handleChange={handleTabChange} />
          <LocationPanel providerUuid={data.provider_uuid} />
          <ProfileInfoPanel orgProfile={data}/>
        </div>
      </Container>
    );
  };

  const renderDesktopLayout = () => {
    return (
      <Container maxWidth="lg" fixed disableGutters={true}>
        <Grid container spacing={6}>
          <Grid item md={8}>
            <div className={classes.leftPane}>
              <MainImage data={data} />
              <PageTitles data={data} />
              <WidgetBar data={data} />
              <TabsPanel data={data} tab={tab} handleChange={handleTabChange} />
              <LocationPanel providerUuid={data.provider_uuid} />
            </div>
          </Grid>
          <Grid item md={4}>
            <div>
              <ProfileBrokerageCTA />
              <ClaimProfile data={data} />
              <AccreditationBadge data={data} />
              {hasEditProfilePermission && <AdminAlertButton data={data} />}
              <ReviewButtons data={data} />
              <ProfileInfoPanel orgProfile={data}/>
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <Page title={`${data.name} - Clickability`}>
      <MetaTags
        article
        title={data.name}
        description={`Read ratings and reviews from the people who use ${data.name}. Clickability is an Australian disability service directory.`}
        image={data.image_path}
        url={`${config.website.URL}/provider/${data.slug}`}
      />
      <div className={classes.root}>
        {mobile ? renderMobileLayout() : renderDesktopLayout()}
      </div>
    </Page>
  );

};

ProfilePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    data: state.provider.profile,
    isLoading: state.provider.profileLoading,
  };
};

export default connect(mapStateToProps)(ProfilePage);
