import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MatchingExample from './MatchingExample';
import MatchingExampleBg from 'assets/matching-example-bg.svg';
import {NAV_BAR_HEIGHT} from 'components/constants';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SearchPanel from 'components/SearchPanel';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: `calc(${NAV_BAR_HEIGHT} + 48px) 0 48px 0`,
    marginTop: `-${NAV_BAR_HEIGHT}`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none',
    zIndex: 0,
    background: 'linear-gradient(180deg, #8DE4DC 0%, #21BFB0 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    borderRadius: '0px 0px 0px 100px',
    [theme.breakpoints.down('sm')]: {
      padding: `${NAV_BAR_HEIGHT} 16px 12px 16px`,
    },
  },
  left: {
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '60px',
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    backgroundImage: `url(${MatchingExampleBg})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  text: {
    fontSize: '40px',
    lineHeight: '40px',
    fontWeight: 700,
    color: '#050E40',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      lineHeight: '2.7rem',
    },
  },
  button: {
    backgroundColor: '#264F73',
    borderRadius: '0.75rem',
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.25)',
    padding: '1rem 1.8rem',
    fontSize: '1.6rem',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: '#050E40',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      padding: '1rem 1rem',
      width: '100%',
    },
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));


const Hero = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} aria-hidden="true">
      <Container maxWidth="lg" fixed disableGutters={true}>
        <Grid container spacing={0}>
          <Grid item md={6} sm={12}>
            <div className={classes.left}>
              <div>
                <Typography variant="h1" className={classes.text} gutterBottom>
                  Save time.
                </Typography>
                <Typography variant="h1" className={classes.text} gutterBottom>
                  We&apos;ll connect you to highly rated NDIS providers.
                </Typography>
                <div style={{marginTop: '45px'}}>
                  <SearchPanel />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12} className={classes.grid}>
            <div className={classes.right}>
              <MatchingExample/>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Hero;
