import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-focused': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    borderRadius: '12px',
  },
  input: {
    height: '40px',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '12px',
    border: `1px solid ${theme.palette.common.silver}`,
    backgroundColor: theme.palette.common.white,
    padding: '0.5rem',
    flex: 1,
    '&>input::placeholder': {
      color: theme.palette.text.primary,
      opacity: 1,
    },
  },
}));

const classNames = (...names) => {
  return names.filter(name => name !== undefined).join(' ');
};

const SearchBox = ({initialQuery='', onSubmit=null, onSearchChange=null, value='', placeholder='Search', icon=<SearchIcon/>, className=undefined}) => {
  const classes = useStyles();
  const [query, setQuery] = React.useState(initialQuery);

  const submit = () => {
    if (onSubmit !== null && query !== '') {
      onSubmit(query);
    }
  };

  const onChange = query => {
    if (onSearchChange === null) {
      setQuery(query);
    } else {
      onSearchChange(query);
    }
  };

  const onKeyUp = (event) => {
    if (event.keyCode === 13) {
      submit();
    }
  };

  React.useEffect(() => {
    if (value === null) {
      setQuery('');
    } else {
      setQuery(value);
    }
  }, [value]); // eslint-disable-line

  return (
    <div className={classes.root}>
      <InputBase
        className={classNames(classes.input, className)}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search-providers' }}
        value={query}
        onChange={event => onChange(event.target.value)}
        onKeyUp={onKeyUp}
        endAdornment={icon}
      />
    </div>
  );
};

SearchBox.propTypes = {
  initialQuery: PropTypes.string,
  onSubmit: PropTypes.func,
  onSearchChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
};

export default SearchBox;
