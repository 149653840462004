import React from 'react';
import PropTypes from 'prop-types';
import AuthFormPage from './components/AuthFormPage';
import OAuthActivateForm from './components/OAuthActivateForm';
import { connect } from 'react-redux';

const OAuthActivationPage = ({profile}) => {
  return (
    <AuthFormPage
      title="Clickability Account Activation"
      displayTitle={true}
    >
      <OAuthActivateForm
        email={profile.email}
        username={profile.username}
        onComplete={() => {}}  // don't need to do anything, profile update will close this page.
      />
    </AuthFormPage>
  );
};

OAuthActivationPage.propTypes = {
  profile: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
  };
};

export default connect(mapStateToProps)(OAuthActivationPage);
