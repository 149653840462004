import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';


const Container = styled.div`
  background: #f5f5f5;
  width: 100%;
  padding: 8px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  border: 1px solid ${style.colors.lightBorder};
  box-shadow: ${style.shadow(8)};
  color: ${style.colors.palette.battleshipGrey};
  column-gap: 10px;
  
  .icon-container {
    font-size: 20px;
    margin-top: 2px;
  }
  
  .text-container {
    font-size: 13px;
    font-weight: 500;
  }
`;

const InfoBar = ({text, ...props}) => {

  return (
    <Container {...props}>
      <div className="icon-container">
        <FontAwesomeIcon icon={faCircleQuestion} />
      </div>
      <div className="text-container">
        {text}
      </div>
    </Container>
  );
};

InfoBar.propTypes = {
  text: PropTypes.string,
};


export default InfoBar;

