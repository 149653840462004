import React from 'react';
import PropTypes from 'prop-types';


const QuestionContainer = ({ children }) => {

  return (
    <>
      {children}
    </>
  );
};

QuestionContainer.propTypes = {
  children: PropTypes.node,
};

export default QuestionContainer;
