import React, { useState } from 'react';

import { AuthAPI } from 'api';
import AuthField from './AuthField';
import AuthForm from './AuthForm';
import { AuthStore } from '../../../store';
import FacebookLoginButton from '../../../components/social/FacebookLoginButton';
import GoogleLoginButton from '../../../components/social/GoogleLoginButton';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const DEFAULT_ERROR_MESSAGE = 'Error logging in: check your details and try again.';
const errorMessage = (err) => {
  if (err.status === 429) {
    return 'Too many failed logins from your IP address.  Try again later.';
  } else if (err.body && err.body.message) {
    return err.body.message;
  } else {
    return DEFAULT_ERROR_MESSAGE;
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '0.5rem',
  },
  text: {
    color: theme.palette.primary.main,
  },
  divider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  border: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    width: '100%',
  },
  dividerText: {
    padding: '0 10px 0 10px',
    color: `1px solid ${theme.palette.primary.main}`,
  },
  link: {
    color: theme.palette.primary.main,
    marginRight: '1rem',
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: '1.5rem',
  },
  socialLogin: {
    marginTop: '1.5rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const LoginForm = ({initialUsername, onComplete, loginApi=AuthAPI.login, recoveryLink, signUpLink, dispatch, title, oauthState, hideLinks=false, onCancel}) => {
  const [loginError, setLoginError] = React.useState('');
  const [usernameWarning, setUsernameWarning] = useState();
  const [passwordWarning, setPasswordWarning] = useState();
  const [username, setUsername] = React.useState(initialUsername || '');
  const [password, setPassword] = React.useState('');
  const [busy, setBusy] = useState(false);

  const classes = useStyles();
  const login = async () => {
    setLoginError();
    if (!validateUsername(true) || !validatePassword(true)) return;
    setBusy(true);
    try {
      const { payload } = await loginApi(username, password);
      const { profile, id, token_ttl } = payload;
      dispatch(AuthStore.login(profile, id, token_ttl));
      setBusy(false);
      if (onComplete) onComplete();
    } catch (err) {
      console.error(err);   // eslint-disable-line no-console
      setLoginError(errorMessage(err));
      setBusy(false);
    }
  };

  const validateUsername = (onSubmit=false) => {
    if (username === '' && onSubmit) {
      setUsernameWarning('Username is required');
      return false;
    }
    setUsernameWarning();
    return true;
  };

  const validatePassword = (onSubmit=false) => {
    if (password === '' && onSubmit) {
      setPasswordWarning('Password is required');
      return false;
    }
    setPasswordWarning();
    return true;
  };

  const disabled = passwordWarning !== undefined || usernameWarning !== undefined;

  return (
    <div className={classes.root}>

      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>

      <Grid container spacing={2} style={{marginTop: 6}}>
        <Grid item sm={12}>
          <FacebookLoginButton state={oauthState}>Log in with Facebook</FacebookLoginButton>
          <GoogleLoginButton state={oauthState}>Log in with Google</GoogleLoginButton>
        </Grid>
      </Grid>

      <div className={classes.divider}>
        <div className={classes.border} />
        <span className={classes.dividerText}>
          or
        </span>
        <div className={classes.border} />
      </div>

      <AuthForm
        buttonLabel="Login"
        buttonAction={login}
        disabled={disabled}
        busy={busy}
        error={loginError}
        cssStyle="default"
        onCancel={onCancel}
      >
        <AuthField
          id="username"
          label="Username or Email Address"
          autoComplete="username"
          type="text"
          name="username"
          value={username}
          onChange={setUsername}
          error={usernameWarning}
          onBlur={() => validateUsername()}
        />

        <AuthField
          id="password"
          label="Password"
          autoComplete="current-password"
          type="password"
          name="auth_password"
          glyph="password"
          value={password}
          onChange={setPassword}
          error={passwordWarning}
          onBlur={() => validatePassword()}
          onEnter={() => !disabled && login()}
        />
        {hideLinks ? null : (
          <div>
            <Link component={RouterLink} to={recoveryLink} className={classes.link}>
              Forgot password?
            </Link>
            <Typography variant="body2" className={classes.text} component={'span'}>
              Not a member yet?&nbsp;
              <Link component={RouterLink} to={signUpLink} className={classes.link}>
                <b>Sign up</b>.
              </Link>
            </Typography>
          </div>
        )}
      </AuthForm>

      {hideLinks ? null : (
        <Typography variant="body2" className={classes.text}>
          Are you a provider? Go to&nbsp;
          <Link component={RouterLink} to={'/providers/login'} className={classes.link}>
            <b>Provider Login.</b>
          </Link>
        </Typography>
      )}

    </div>
  );
};

LoginForm.propTypes = {
  initialUsername: PropTypes.string,
  onComplete: PropTypes.func,
  loginApi: PropTypes.func,
  recoveryLink: PropTypes.string,
  signUpLink: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  oauthState: PropTypes.object,
  hideLinks: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default connect()(LoginForm);
export { 
  errorMessage
};