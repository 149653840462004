import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import style from 'style';
import { Typography } from 'antd';


export const CardTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 10px;
  color: ${style.colors.primary};
`;

const Main = styled.div`
  border: 1px solid ${style.colors.lightBorder};
  background: ${style.colors.palette.white};
  border-radius: ${({borderRadius}) => borderRadius && borderRadius};
  box-shadow: ${({strength}) => strength && style.shadow(strength)};
  padding: 20px;
`;

const Card = (
  {
    title,
    children,
    strength=20,
    borderRadius=style.borderRadius.large,
    ...props
  }) => {

  return (
    <>
      {title && (
        <CardTitle>{title}</CardTitle>
      )}
      <Main strength={strength} borderRadius={borderRadius} {...props}>
        {children}
      </Main>
    </>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  strength: PropTypes.number,
  borderRadius: PropTypes.string,
  title: PropTypes.string,
};


export default Card;
