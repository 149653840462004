import React, { useState } from 'react';
import AuthForm from './AuthForm';
import AuthField from './AuthField';
import PropTypes from 'prop-types';

const DEFAULT_ERROR_MESSAGE = 'An error occurred activating your account.  Please try again later.';
const BAD_LINK_MESSAGE = 'Activation link is not valid.';

const ActivateForm = ({username, code, activateApi, onComplete}) => {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordWarning, setPasswordWarning] = useState();
  const [password2Warning, setPassword2Warning] = useState();
  const linkValid = username  && code;  // rejects null, undefined or empty strings
  const [activateError, setActivateError] = useState(linkValid ? undefined : BAD_LINK_MESSAGE);
  const [busy, setBusy] = useState(false);
  const activate = async () => {
    if (!linkValid) {
      setActivateError(BAD_LINK_MESSAGE);
      return;
    }
    setActivateError();
    if (!validatePassword(true) || !validatePassword2(true)) return;
    try {
      setBusy(true);
      await activateApi(username, password, code);
      setBusy(false);
      onComplete();
    } catch (err) {
      setBusy(false);
      console.error(err);  // eslint-disable-line no-console
      const message = (err.body && err.body.message) ? err.body.message : DEFAULT_ERROR_MESSAGE;
      setActivateError(message);
    }
  };

  const validatePassword = (onSubmit=false) => {
    if (password === '' && onSubmit) {
      // only perform this validation if they are actually ready to submit
      setPasswordWarning('Password is required');
      return false;
    }
    setPasswordWarning();
    return true;
  };

  const validatePassword2 = (onSubmit=false) => {
    if (onSubmit) {
      if (password2 === '') {
        setPassword2Warning('Please repeat password here to confirm it.');
        return false;
      }
      if (password2 !== password) {
        setPassword2Warning('Passwords do not match');
        return false;
      }
    }
    setPassword2Warning();
    return true;
  };

  const disabled = !linkValid || passwordWarning !== undefined || password2Warning !== undefined;
  return (
    <AuthForm
      buttonLabel="Activate Account"
      buttonAction={activate}
      disabled={disabled}
      busy={busy}
      error={activateError}
    >

      <AuthField
        id="username"
        label="Username"
        type="text"
        name="username"
        value={username}
        disabled={true}
      />

      <AuthField
        id="password"
        label="Password"
        autoComplete="current-password"
        type="password"
        name="password"
        value={password}
        onChange={setPassword}
        error={passwordWarning}
        onBlur={() => validatePassword()}
      />

      <AuthField
        id="password2"
        label="Repeat password"
        autoComplete="current-password"
        type="password"
        name="password2"
        value={password2}
        onChange={setPassword2}
        error={password2Warning}
        onBlur={() => validatePassword2()}
        onEnter={() => !disabled && activate()}
      />

    </AuthForm>
  );
};

ActivateForm.propTypes = {
  username: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  activateApi: PropTypes.func.isRequired,
};

export default ActivateForm;
