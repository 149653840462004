import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AuthForm from './AuthForm';
import AuthField from './AuthField';
import PropTypes from 'prop-types';
import { AuthStore } from '../../../store';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { HICAPS_BILLER_ID_KEY, UTM_SOURCE_KEY } from 'providerSite/constants';

const DEFAULT_ERROR_MESSAGE = 'Error logging in: check your details and try again.';
const errorMessage = (err) => {
  if (err.status === 429) {
    return 'Too many failed logins from your IP address.  Try again later.';
  } else if (err.body && err.body.message) {
    return err.body.message;
  } else {
    return DEFAULT_ERROR_MESSAGE;
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '0.5rem',
  },
  title: {
    marginBottom: '0.5rem',
    color: theme.palette.primary.main,
  },
  text: {
    color: theme.palette.primary.main,
  },
  link: {
    marginRight: '2rem',
  },
}));

const ProviderLoginForm = ({initialUsername, onComplete, loginApi, recoveryLink, signUpLink, dispatch, title, titleVariant='h1'}) => {
  const [loginError, setLoginError] = React.useState('');
  const [usernameWarning, setUsernameWarning] = useState();
  const [passwordWarning, setPasswordWarning] = useState();
  const [username, setUsername] = React.useState(initialUsername || '');
  const [password, setPassword] = React.useState('');
  const [busy, setBusy] = useState(false);

  const query = new URLSearchParams(useLocation().search);

  const setSessionStorageValue = key => {
    const paramData = query.get(key);
    paramData && window.sessionStorage.setItem(key, `${paramData}`);
  };

  useEffect(() => {
    setSessionStorageValue(UTM_SOURCE_KEY);
    setSessionStorageValue(HICAPS_BILLER_ID_KEY);
  }, []);

  const classes = useStyles();
  const login = async () => {
    setLoginError();
    if (!validateUsername(true) || !validatePassword(true)) return;
    setBusy(true);
    try {
      const hicapsBillerId = window.sessionStorage.getItem(HICAPS_BILLER_ID_KEY);
      const extraData = hicapsBillerId ? {hicaps_biller_id: hicapsBillerId} : {};
      const { payload } = await loginApi(username, password, extraData);
      window.sessionStorage.removeItem(HICAPS_BILLER_ID_KEY);
      window.sessionStorage.removeItem(UTM_SOURCE_KEY);
      const { profile, id, token_ttl } = payload;
      dispatch(AuthStore.login(profile, id, token_ttl));
      setBusy(false);
      if (hicapsBillerId) {
        window.open('', '_self');
        window.close();
      } else {
        onComplete(profile);
      }
    } catch (err) {
      console.error(err);   // eslint-disable-line no-console
      setLoginError(errorMessage(err));
      setBusy(false);
    }
  };

  const validateUsername = (onSubmit=false) => {
    if (username === '' && onSubmit) {
      setUsernameWarning('Username is required');
      return false;
    }
    setUsernameWarning();
    return true;
  };

  const validatePassword = (onSubmit=false) => {
    if (password === '' && onSubmit) {
      setPasswordWarning('Password is required');
      return false;
    }
    setPasswordWarning();
    return true;
  };

  const disabled = passwordWarning !== undefined || usernameWarning !== undefined;
  return (
    <div className={classes.root}>
      <AuthForm
        buttonLabel="Login"
        buttonAction={login}
        disabled={disabled}
        busy={busy}
        error={loginError}
      >
        <Typography variant={titleVariant} className={classes.title}>
          {title}
        </Typography>
        <AuthField
          id="username"
          label="Username or Email Address"
          autoComplete="username"
          type="text"
          name="username"
          value={username}
          onChange={setUsername}
          error={usernameWarning}
          onBlur={() => validateUsername()}
        />

        <AuthField
          id="password"
          label="Password"
          autoComplete="current-password"
          type="password"
          name="auth_password"
          glyph="password"
          value={password}
          onChange={setPassword}
          error={passwordWarning}
          onBlur={() => validatePassword()}
          onEnter={() => !disabled && login()}
        />
        <div>
          <Link component={RouterLink} to={recoveryLink} className={classes.link}>
            Forgot password?
          </Link>
          <Typography variant="body2" className={classes.text} component={'span'}>
            Not a member yet?&nbsp;
            <Link component={RouterLink} to={signUpLink} className={classes.link}>
              <b>Sign up</b>.
            </Link>
          </Typography>
        </div>
      </AuthForm>
    </div>
  );
};

ProviderLoginForm.propTypes = {
  initialUsername: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  loginApi: PropTypes.func.isRequired,
  recoveryLink: PropTypes.string.isRequired,
  signUpLink: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.string,
};

export default connect()(ProviderLoginForm);
