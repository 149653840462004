import {
  yesNoInput,
} from 'components/formflow/default/questions';

import QuestionContainer from 'components/formflow/default/QuestionContainer';
import SpecialisationFormContainer from './SpecialisationFormContainer';

const isYes = (value) => value === 'yes';

const sensoryImpairment = {
  name: 'sensory-impairment',
  title: 'Endorsement for Sensory Impairment Specialisation',
  questions: [
    yesNoInput({
      field: 'website_accessible',
      label: 'Is the provider’s website accessible?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'real_time_communication',
      label: 'Do you have an accessible, real-time means of communication with the provider?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'easy_to_communicate',
      label: 'Is it easy to communicate with them?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'encourage_feedback_or_lived_experience',
      label: 'Do they encourage you to provide feedback or input, or employ people with lived experience with your disability?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'flexibility_for_workers',
      label: 'Are they flexible with appointments or shift times?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct.',
      isValid: isYes,
    }),
  ]
};

const psychosocialDisbility = {
  name: 'psychosocial-disability',
  title: 'Endorsement for Sensory Impairment Specialisation',
  questions: [
    yesNoInput({
      field: 'appropriate_support',
      label: 'Does the provider support you how you want to be supported?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'community_engagement',
      label: 'Do they help you engage with community groups or activities?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'service_flexibility',
      label: 'Do they offer you flexibility when it comes to choosing services and supports?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'knowledgeable_staff',
      label: 'Are staff knowledgeable when it comes to your disability?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'encourage_feedback_or_lived_experience',
      label: 'Do they encourage you to provide feedback or input, or employ people with lived experience with your disability?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: isYes,
    }),
  ]
};

const lgbtiq = {
  name: 'lgbtiq+',
  title: 'LGBTIQ+ Specialisation',
  questions: [
    yesNoInput({
      field: 'lgbtqi_staff',
      label: 'Do they employ staff who identify as LGBTQI+?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'names_and_pronouns_best_practice',
      label: 'Do they follow best practice when it comes to names and pronouns on documents and when talking directly with you?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'safe_supportive_environment',
      label: 'Do you feel safe and supported?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'encourage_feedback',
      label: 'Do they encourage you to provide feedback or input?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: isYes,
    }),
  ]
};

const intellectualDisability = {
  name: 'intellectual-disability',
  title: 'Intellectual Disability Specialisation',
  questions: [
    yesNoInput({
      field: 'appropriate_support',
      label: 'Does the provider support you how you want to be supported?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'accessible_documents',
      label: 'Are documents easily accessible and easy to understand?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'service_flexibility',
      label: 'Do they offer you flexibility when it comes to choosing services and supports? ',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'knowledgeable_staff',
      label: 'Are staff knowledgeable when it comes to your disability?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'encourage_feedback_or_lived_experience',
      label: 'Do they encourage you to provide feedback or input, or employ people with lived experience with your disability?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'low_staff_turnover',
      label: 'Do they have a low turnover of staff?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: isYes,
    }),
  ]
};

const cald  = {
  name: 'cald',
  title: 'Cultural and Linguistic Diversity Specialisation',
  questions: [
    yesNoInput({
      field: 'contact_any_time',
      label: 'Can you contact them any time?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'translate_and_interpret',
      label: 'Do they translate and interpret information for you?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'bi-lingual-staff',
      label: 'Do they employ bi-lingual and bi-cultural staff?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'respect_culture',
      label: 'Do they respect your culture?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'connected_to_community',
      label: 'Are they connected with your community?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: isYes,
    }),
  ]
};

const autism = {
  name: 'autism',
  title: 'Autism Spectrum Specialisation',
  questions: [
    yesNoInput({
      field: 'appropriate_support',
      label: 'Does the provider support you how you want to be supported?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'safe_supportive_environment',
      label: 'Do they have autism-friendly environments where you feel safe and supported?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'service_flexibility',
      label: 'Do they offer you flexibility when it comes to choosing services and supports?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'encourage_feedback_or_lived_experience',
      label: 'Do they encourage you to provide feedback or input, or employ people with lived experience with your disability?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: isYes,
    }),
  ]
};

const abi = {
  name: 'abi',
  title: 'Aquired Brain Injury Specialisation',
  questions: [
    yesNoInput({
      field: 'feel_supported',
      label: 'Do you feel supported by staff?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'tailored_supports',
      label: 'Does the provider tailor their supports to suit and respect where you are in your ABI journey? Do they respect your individuality?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'written_information',
      label: 'Do they provide written information prior to meetings and appointments?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'accessible_resources',
      label: 'Are their resources easy to understand and accessible?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'encourage_feedback',
      label: 'Do they encourage you to provide feedback or input?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: isYes,
    }),
  ]
};


const atsi = {
  name: 'atsi',
  title: 'Aboriginal and Torres Strait Islander Specialisation',
  questions: [
    yesNoInput({
      field: 'tailored_supports',
      label: 'Do they tailor their services to suit you?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'encourage_feedback',
      label: 'Can you easily provide feedback?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'atsi_staff',
      label: 'Do they employ Aboriginal and Torres Strait Islander staff?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'respect_culture',
      label: 'Do they respect your culture?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'connected_to_community',
      label: 'Are they connected with your community?',
      isValid: isYes,
    }),
    yesNoInput({
      field: 'declare_true',
      label: 'I declare that my answers to the following questions are true and correct, and that any misinformation will result in Clickability removing my endorsement.',
      isValid: isYes,
    }),
  ]
};

const createFlow = page => (
  {
    pages: [page],
    incompleteMessage: 'This provider has not met the necessary conditions for endorsement.',
    Container: SpecialisationFormContainer,
    QuestionContainer: QuestionContainer,
  }
);

export default {
  'Sensory Impairment': createFlow(sensoryImpairment),
  'Psychosocial Disability': createFlow(psychosocialDisbility),
  'LGBTIQ+': createFlow(lgbtiq),
  'Intellectual Disability': createFlow(intellectualDisability),
  'CALD': createFlow(cald),
  'Autism': createFlow(autism),
  'Acquired Brain Injury': createFlow(abi),  
  'Aboriginal and Torres Strait Islander': createFlow(atsi),
};
