import { makePath, request } from './http';
const API_NAME = 'servicerequest';

export function createRequest(data) {
  return request('POST', makePath(API_NAME), {body: data});
}

export function getCachedResponses({fields, scope=null}) {
  return request('GET', makePath(API_NAME, 'responses'), {params: {fields, scope}});
}

export function setCachedResponses({values, scope=null}) {
  return request('POST', makePath(API_NAME, 'responses'), {body: {values, scope}});
}

export const listAllRequests = (params) => {
  return request('GET', makePath(API_NAME), {params: params});
};

export const listAllFilters = () => {
  return request('GET', makePath(API_NAME, 'filters'));
};

export const listStats = () => {
  return request('GET', makePath(API_NAME, 'stats'));
};

export const getServiceInquiry = (uuid) => {
  return request('GET', makePath(API_NAME, 'inquiry', uuid));
};

export const getServiceRequest = (uuid) => {
  return request('GET', makePath(API_NAME, uuid));
};

export function updateServiceInquiry(uuid, data) {
  return request('PUT', makePath(API_NAME, 'inquiry', uuid), {body: data});
}

export function updateServiceRequest(uuid, data) {
  return request('PATCH', makePath(API_NAME, uuid), {body: data});
}

export function updateServiceRequestStatus(uuid, data) {
  return request('PUT', makePath(API_NAME, uuid, 'event'), {body: data});
}

export function updateServiceRequestStatusForProviders(providerUuid, serviceRequestUuid, data) {
  return request('PUT', makePath(
    API_NAME, 'matches', providerUuid, serviceRequestUuid, 'event'), {body: data});
}

export const listAllMatches = (uuid, params) => {
  return request('GET', makePath(API_NAME, 'matches', uuid), {params: params});
};

export const listAllMatchingFilters = (providerUuid) => {
  return request('GET', makePath(API_NAME, 'matches', providerUuid, 'filters'));
};

export function getProviderServiceRequest(providerUuid, serviceRequestUuid) {
  return request('GET', makePath(
    API_NAME, 'matches', providerUuid, serviceRequestUuid));
}

export function claimServiceRequest(providerUuid, serviceRequestUuid, signature, action) {
  return request('PUT', makePath(API_NAME, 'claim', serviceRequestUuid),
    {params: {provider: providerUuid, signature, action}});
}

export const getAvailableServiceRequestCount = () => {
  return request('GET', makePath(API_NAME, 'available'));
};

export const checkServiceRequestClaimStatus = (providerUuid, serviceRequestUuid, signature, action) => {
  return request('GET', makePath(API_NAME, 'claim', serviceRequestUuid), {params: {provider: providerUuid, signature, action}});
};

export const sendOTP = data => {
  return request('POST', makePath(API_NAME, 'otp'), {body: data});
};

export const getSurvey = uuid => {
  return request('GET', makePath(API_NAME, 'survey', uuid));
};

export const updateSurvey = (uuid, data) => {
  return request('PUT', makePath(API_NAME, 'survey', uuid), {body: data});
};

export const getMatchingStats = providerUuid => {
  return request('GET', makePath(API_NAME, 'stats', providerUuid));
};

export const getMatchingFilterCounts = (uuid, params) => {
  return request('GET', makePath(API_NAME, 'matches', uuid, 'filters', 'counts'), {params: params});
};

export const getConnectServiceRequests = (uuid, params) => {
  return request('GET', makePath(API_NAME, 'connect', uuid), {params: params});
};