import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';


const BUTTON_NAME_GOOD = 'good';
const BUTTON_NAME_BAD = 'bad';


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '3px',
    display: 'flex',
    columnGap: '20px',
    padding: '5px 20px 4px 20px',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: theme.palette.common.tealish,
    boxShadow: 'rgb(145 158 171 / 15%) 0px 0px 4px 0px, rgb(145 158 171 / 15%) 2px -2px 20px 0px',
    position: 'absolute',
    background: theme.palette.common.white,
    zIndex: '1',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    lineHeight: '11px',
    rowGap: '2px',
    cursor: 'pointer',
  },
  icon: {
    display: 'block',
    fontSize: '16px',
  },
  positive: {
    color: ({state}) => state === BUTTON_NAME_GOOD && theme.palette.info.main,
    '&:hover': {
      color: theme.palette.info.main,
    },
  },
  negative: {
    color: ({state}) => state === BUTTON_NAME_BAD && theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
}));

const Rate = ({ value, className, onClick }) => {

  const [state, setState] = useState(value ? value : '');

  const classes = useStyles({state: state});

  const handleClick = value => {
    setState(prevState => {
      if (value === prevState) return '';
      return value;
    });
  };

  useEffect(() => {
    onClick(state);
  }, [state]);

  return (
    <div className={classNames(classes.root, className)}>
      <div
        className={classNames(classes.button, classes.positive)}
        onClick={() => handleClick(BUTTON_NAME_GOOD)}
      >
        <FontAwesomeIcon className={classes.icon} icon={faThumbsUp} />
        <div>{_.capitalize(BUTTON_NAME_GOOD)}</div>
      </div>
      <div
        className={classNames(classes.button, classes.negative)}
        onClick={() => handleClick(BUTTON_NAME_BAD)}
      >
        <FontAwesomeIcon className={classes.icon} icon={faThumbsDown} />
        <div>{_.capitalize(BUTTON_NAME_BAD)}</div>
      </div>
    </div>
  );
};

Rate.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string,
};

export default Rate;