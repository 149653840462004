import React  from 'react';

import _ from 'lodash';
import snakecaseKeys from 'snakecase-keys';
import { Link } from 'react-router-dom';

import QuestionContainer from './components/QuestionContainer';
import FlowContainer from './components/FlowContainer';
import UserPage from './components/UserPage';
import ProviderPage from './components/ProviderPage';
import BillingPage from './components/BillingPage';
import GoalPage from './components/GoalPage';
import AssetsStack from './components/AssetsStack';
import ProviderAsset from './components/ProviderAsset';
import {
  FIELD_FIRST_NAME,
  FIELD_LAST_NAME,
  FIELD_EMAIL,
  FIELD_PASSWORD,
  FIELD_BUSINESS_NAME,
  FIELD_JOB_ROLE,
  FIELD_PHONE,
  SIGNUP_FLOW_STEP_ONE,
  SIGNUP_FLOW_STEP_TWO,
} from './constants';
import { AuthAPI } from 'api';
import { getSessionStorageData } from './utils';


const flow = {
  Container: FlowContainer,
  QuestionContainer: QuestionContainer,
  pages: [
    {
      name: 'user',
      title: 'Supercharging your NDIS business is just a few steps away.',
      subtitle: 'First let’s get you setup so that you can be found in the NDIS ' +
        'in a way that works for you.',
      footer: <>
        By signing up, you agree to our&nbsp;
        <Link to="/terms" target="_blank" rel="noopener noreferrer">
          Terms & Conditions
        </Link> and&nbsp;
        <Link to="/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </Link>
      </>,
      RightComponent: AssetsStack,
      buttonText: 'Agree and Sign Up',
      progressbar: false,
      questions: [
        {
          Component: UserPage,
          field: 'user',
          isValid: answer => (!!answer?.[FIELD_FIRST_NAME] &&
            !!answer?.[FIELD_LAST_NAME] && !!answer?.[FIELD_EMAIL] && !!answer?.[FIELD_PASSWORD]),
        }
      ],
      onClick: (answer) => {
        const answerCopy = _.cloneDeep(answer);
        delete answerCopy?.user?.[FIELD_PASSWORD];
        const data = {
          ...answerCopy,
          user: {...answerCopy?.user, ...getSessionStorageData()}
        };
        AuthAPI.recordProviderSignupData(
          snakecaseKeys(data, { deep: true }), SIGNUP_FLOW_STEP_ONE)
          .catch(err => console.error(err));    // eslint-disable-line no-console
      }
    },
    {
      name: 'provider',
      title: 'Welcome 👋',
      subtitle: 'Help us personalise your account',
      RightComponent: ProviderAsset,
      buttonText: 'Next',
      progressbar: true,
      questions: [
        {
          Component: ProviderPage,
          field: 'provider',
          isValid: answer => (!!answer?.[FIELD_BUSINESS_NAME] &&
            !!answer?.[FIELD_JOB_ROLE] && !!answer?.[FIELD_PHONE]),
        }
      ],
      onClick: (answer) => {
        const answerCopy = _.cloneDeep(answer);
        delete answerCopy?.user?.[FIELD_PASSWORD];
        const data = {
          ...answerCopy,
          user: {...answerCopy?.user, ...getSessionStorageData()},
          provider: {...answerCopy?.provider, ...getSessionStorageData()},
        };
        AuthAPI.recordProviderSignupData(
          snakecaseKeys(data, { deep: true }), SIGNUP_FLOW_STEP_TWO)
          .catch(err => console.error(err));    // eslint-disable-line no-console
      }
    },
    {
      name: 'goal',
      title: 'What best describes your goal',
      buttonText: 'Next',
      pageWidth: '100%',
      questions: [
        {
          Component: GoalPage,
          field: 'goal',
          isValid: answer => !!answer,
        }
      ],
    },
    {
      name: 'billing',
      title: 'Try risk free and get your first month free',
      subtitle: 'Payment taken after 30 days, cancel anytime',
      buttonText: 'Next',
      hideButton: true,
      pageWidth: '100%',
      questions: [
        {
          Component: BillingPage,
          field: 'goal',
          isValid: answer => !!answer,
        }
      ],
    },
  ]
};

export default flow;
