import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';

import { pages } from './pages';
import { useSelector } from 'react-redux';

import { connectRole, adminRole } from 'lib/roles';


const BASE_URI = '/connect/:uuid/dashboard';
const BASE_KEY = '/connect/dashboard';
const ITEM_KEY = 'item';


export const routes = () => {

  const _routes = pages.map(page => {
    return (
      <Route
        key={`${BASE_KEY}/${page.uri}`}
        exact
        path={`${BASE_URI}/${page.uri}`}
        render={({
          match,
          location,
          history
        }) => {
          const providerUuid = match.params.uuid;
          const urlParams = new URLSearchParams(location.search);
          const item = urlParams.get(ITEM_KEY);

          const onSelect = value => (
            history.push({ search: `?${ITEM_KEY}=` + encodeURI(value) }));

          return (
            <page.Component
              providerUuid={providerUuid}
              isItemSelected={!!item}
              selectedItem={item}
              onItemSelect={onSelect}
            />
          );
        }}
      />
    );
  });

  const _homepageRedirect = (
    <Redirect
      key={BASE_KEY}
      exact
      from={BASE_URI}
      to={`${BASE_URI}/${pages[0].uri}`}
    />
  );

  const connectRedirect = [
    <Route
      key="/connect/dashboard-redirect"
      exact
      path="/connect/dashboard"
      component={ConnectRedirect}
    />
  ];

  return [_homepageRedirect, ...connectRedirect, ..._routes];
};

const ConnectRedirect = () => {
  const profile = useSelector(state => state.auth.profile) || {};
  const history = useHistory();
  let redirectTo = null;

  if (profile.roles) {
    const hasConnectAccessRole = profile.roles.find(
      (role) => {
        if (!role.has_connect_access) {
          return false;
        }
        return !!(role.role_names.includes(connectRole) || role.role_names.includes(adminRole));
      });
    if (hasConnectAccessRole) {
      redirectTo = `/connect/${hasConnectAccessRole.provider_uuid}/dashboard`;
      history.push(redirectTo);
    }
  } else {
    history.push('/profile');
  }
  return null;
};