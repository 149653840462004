import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { Tag, Badge } from 'antd';
import { faBadgeCheck } from '@fortawesome/pro-light-svg-icons';

import Card from './Card';


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

const Specialisations = ({ data, isLoading }) => {

  const _data = data ? data.filter(item => item.endorsements) : [];

  return (
    <Card
      title="Specialisations"
      icon={faBadgeCheck}
      isLoading={isLoading}
      noData={_.isEmpty(_data)}
    >
      <Container>
        {_data?.map((item, index) => (
          <Badge
            key={index}
            count={item.endorsements}
            style={{fontSize: '12px', fontWeight: '500'}}
            color="cyan"
          >
            <Tag style={{margin: 0}}>{item.name}</Tag>
          </Badge>
        ))}
      </Container>
    </Card>
  );
};

Specialisations.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default Specialisations;
