import equal from 'fast-deep-equal/es6/react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Get an array of unique key values from an array of objects
export const uniqueValuesFromObjectArray = (arr, key) => {
  return [...new Set(arr.map(item => item[key]))];
};

// Above but for ID values (used for POST/PATCH to API)
export const taxonomyListToIdArray = (arr) => {
  return uniqueValuesFromObjectArray(arr, 'id');
};

// This function converts data from a taxonomy tree format to a flat list
// of ID's. This is needed for components that allw editing of the taxonomy
// items linked to an object (e.g. a provider).
//
// This is used with API GET responses for taxonomy items which come as:
//  [{"id": 1, "name": "parent", "children": [
//       {"id": 7, "name": "child", ...{}
//  ], ...{}]
//
// API POST/PATCH inputs for taxonomy items require an ID list like:
// [1, 3, 5, 6, ...]

export const taxonomyTreeToIdArray = (tree) => {
  const nestedIds = tree.map(item => uniqueValuesFromObjectArray(item.children, 'id'));
  const ids = nestedIds.reduce((acc, val) => acc.concat(val), []);
  return ids;
};

export const taxonomyItemId = (itemName, taxonomy) => {
  let result = null;
  taxonomy.forEach(parent => {
    parent.children.forEach(child => {
      if (child.name === itemName) {
        result = parseInt(child.id);
      }
    });
  });
  return result;
};

// Convert an array of objects to a structured array with category as key
// and list of subcategories for each key
export const objectArrayToStructuredObject = (arr, key, subkey) => {
  let uniqueKeys = uniqueValuesFromObjectArray(arr, key);
  let structured = uniqueKeys.map(keyItem => {
    let newObj = {};
    let keyArray = arr.filter(item => item[key] === keyItem);
    let uniqueSubkeys = uniqueValuesFromObjectArray(keyArray, subkey);
    newObj[key] = keyItem;
    newObj[subkey] = uniqueSubkeys;
    return newObj;
  });
  return structured;
};

let componentUid = 0;
export const formatComponentId = (prefix) => {
  return `${prefix}-${++componentUid}`;
};

// Deep-compare two objects to create a new object which contains only
// the difference between the latest and the initial object. This is useful
// for API update processes where we want to submit only data that has changed
// via a PATCH request to avoid errors such as trying to update a linked
// attribute.
export const dataDiff = (initial, latest) => {
  const result = {};
  Object.keys(latest).forEach(k => {
    if (!equal(latest[k], initial[k])) {
      result[k] = latest[k];
    }
  });
  return result;
};

// Finds an item in an array by value of a specified attribute
// Useful for converting ID values from pickers to name values from taxonomies
// and vice versa
export const itemFromArray = (arr, field, value) => {
  return arr.find(v => v[field] === value);
};

// Converts 6 digit hex to 8 digit hex with opacity value.
export const getHexWithOpacity = (hex, percentage=100) => {
  const val = Math.round(percentage) / 100;
  const alpha = Math.round(val * 255);
  const opacityHex = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();

  return `${hex}${opacityHex}`;
};

export const getImageHeightAndSize = imageWidth => {
  const imageHeight = Math.round(imageWidth * 9/16);
  const imageSize = `@${imageWidth}x${imageHeight}`;

  return { imageHeight, imageSize };
};

export const setIntersection = (setA, setB) => {
  let _intersection = new Set();
  for (let elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
};

export const setDifference = (setA, setB) => {
  let _difference = new Set(setA);
  for (let elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
};

export const isMobilePhoneNumber = (value, country='AU') => {
  const phoneNumber = parsePhoneNumberFromString(value, country);
  if (!phoneNumber || !phoneNumber.isValid() || phoneNumber.getType() !== 'MOBILE') {
    return false;
  }
  return true;
};