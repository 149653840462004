import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import * as track from 'lib/track';


const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '10px',
    margin: '10px 0',
    '&:hover': {
      background: theme.palette.common.tealish,
    },
  },
}));

const ConnectMeButton = ({ show, uuid }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleClick = () => {
    track.sendEvent(track.CONNECT_ME_BUTTON_CLICK, track.EVENT_CLICK, {
      listing_uuid: uuid,
    });
    history.push(`/requestmatch?service_offering=${uuid}`);
  };

  if (!show) return null;

  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      className={classes.root}
      onClick={handleClick}
    >
      Connect with this Provider
    </Button>
  );
};

ConnectMeButton.propTypes = {
  show: PropTypes.bool,
  uuid: PropTypes.string,
};


export default ConnectMeButton;
