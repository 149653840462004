const jwt = require('jwt-simple');

/*
 * Extract data from the token to extend the auth store data.
 */
export const tokenData = (token) => {
  if (token === undefined) return {};
  const data = jwt.decode(token, '', true);
  return {
    socialLogin: data.social_login !== undefined,  // "True" if true, missing if false.
  };
};
