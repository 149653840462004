import styled from 'styled-components';
import style from 'style';

const Circle = styled.div`
  width: ${({diameter}) => diameter};
  height: ${({diameter}) => diameter};
  border: 2px solid ${style.colors.palette.white};
  border-radius: 50%;
`;

export default Circle;