import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Progress as _Progress, Skeleton } from 'antd';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';    // Using MUI to match with other stats cards
import dayjs from 'dayjs';

import style from 'style';


const Container = styled.div`
  width: 100%;
  height: 145px;
  border-radius: 16px;
  border: 1px solid ${style.colors.lightBorder};
  user-select: none;
  box-shadow: ${style.shadow(15)};
  background: ${style.colors.palette.white};
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const ResetDateContainer = styled.div`
  position: absolute;
  bottom: 3px;
  right: 5px;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
`;

const Footer = styled.div`
  border-top: 1px solid ${style.colors.lightBorder};
  margin-top: auto;
  height: 35px;
  color: ${style.colors.palette.battleshipGrey};
  font-size: 11px;
  font-weight: 500;
  padding: 0 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .icon {
    font-size: 13px;
    :hover {
      color: ${style.colors.info}
    }
  }
`;

const Progress = styled(_Progress)`
  .ant-progress-text {
    font-size: 32px;
    font-weight: 500;
    color: black !important;
  }
`;

const CreditStats = ({ availableCredits, totalCredits, isLoading, resetDate, ...props }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const hasNoCredits = availableCredits != null && availableCredits <= 0;

  const renderBody = () => (
    <Progress
      type="dashboard"
      percent={availableCredits === null ? 100 : (hasNoCredits? 3 : availableCredits / totalCredits * 100)}
      width={100}
      strokeColor={hasNoCredits ? style.colors.error : style.colors.success}
      format={() => (
        <div>
          {availableCredits === null ? (
            <div style={{fontSize: '12px'}}>UNLIMITED</div>
          ) : (
            <div style={{color: hasNoCredits && style.colors.error}}>
              {availableCredits}/{totalCredits}
            </div>
          )}
          <div style={{fontSize: '10px', fontWeight: 500, marginTop: '2px', color: style.colors.palette.battleshipGrey}}>
            Credits
          </div>
        </div>
      )}
    />
  );

  const renderLoading = () => (
    <div style={{width: '100%', height: '100px'}}>
      <Skeleton active />
    </div>
  );

  return (
    <Container {...props}>
      <InnerContainer>
        {!isLoading && (
          <ResetDateContainer>
            <div>
              Resets on:
            </div>
            <div>
              {dayjs(resetDate).format('MMM DD, YYYY')}
            </div>
          </ResetDateContainer>
        )}
        {isLoading ? renderLoading() : renderBody()}
      </InnerContainer>
      <Footer>
        Monthly Service Request Credits
        <Tooltip
          arrow
          title="Remaining monthly credits for service requests. Credits reset on
          the subscription renewal day of every month."
          placement="bottom-end"
          open={isTooltipOpen}
        >
          <div>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="icon"
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
              onClick={e => e.stopPropagation()}
            />
          </div>
        </Tooltip>
      </Footer>
    </Container>
  );
};

CreditStats.propTypes = {
  availableCredits: PropTypes.number,
  totalCredits: PropTypes.number,
  isLoading: PropTypes.bool,
  resetDate: PropTypes.string,
};


export default CreditStats;

