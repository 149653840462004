import React from 'react';
import { Route } from 'react-router-dom';

import ProviderDashboard from './pages/ProviderDashboard';




const routes = () => {
  return [
    <Route key="/portal/provider-dashboard" exact={false} path="/portal/provider-dashboard" component={ProviderDashboard} />,
  ];
};

export default routes;
