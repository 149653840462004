import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Typography, Button } from 'antd';

import style from 'style';
import _Container from '../Container';
import { currentProductUrl } from '../Navbar/components/utils';


const Main = styled.div`
  scroll-padding: 500px;
  overflow: hidden;
`;

const Top = styled.div`
  background: ${style.colors.primary};
  display: flex;
  justify-content: center;
  padding: 50px 0;
  
`;

const Bottom = styled.div`
  min-height: 50px;
`;

const Container = styled(_Container)`
  position: relative;
  .circle {
    position: absolute;
    &-left {
      left: -100px;
      bottom: -100px;
    }
    
    &-right {
      right: -100px;
      top: -125px;
    }
    
    @media (max-width: ${style.screens.tab}){
      &-left, &-right {
        display: none;
      }
    }
  }
`;

const LeftContainer = styled.div`
  position: relative;
  max-width: 400px;
  z-index: 100;
  .title {
    color: ${style.colors.palette.white};
    position: relative;
    margin-bottom: 50px;
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 50px;
      background: ${style.colors.secondary};
      left: 0;
      bottom: -25px;
    }
  }
  @media (max-width: ${style.screens.tab}){
    max-width: 100%;
    text-align: center;
     .title {
       &::after {
          left: 50%;
          transform: translateX(-50%);
        }
     }
  }
`;

const Text = styled.div`
  color: ${style.colors.palette.white};
  margin-bottom: 40px;
`;

const RightContainer = styled.div`
  position: absolute;
  bottom: -110px;
  right: 0;
  z-index: 100;
`;

const Circle = styled.div`
  width: ${({diameter}) => diameter};
  height: ${({diameter}) => diameter};
  border: 2px solid ${style.colors.palette.white};
  border-radius: 50%;
`;

const SectionTwo = ({ title, text, img, refProp, buttonText='PROVIDER SIGN UP' }) => {
  const history = useHistory();
  const goal = currentProductUrl() === '/connect' ? 'connect' : 'growth';
  const isDesktop = useMediaQuery({minWidth: style.screens.tab});

  return (
    <Main ref={refProp}>
      <Top>
        <Container size="medium">
          <LeftContainer>
            <Typography.Title level={isDesktop ? 1 : 2} className="title">
              {title}
            </Typography.Title>
            <Text>
              {text}
            </Text>
            <Button
              type="primary"
              size="large"
              onClick={() => history.push('/business/signup', { goal: goal })}
            >
              {buttonText}
            </Button>
          </LeftContainer>
          {isDesktop && (
            <RightContainer>
              <img src={img} alt="Service Request Dashboard" height="350px"/>
            </RightContainer>
          )}
          <Circle className="circle circle-left" diameter="100px" />
          <Circle className="circle circle-right" diameter="300px" />
        </Container>
      </Top>
      <Bottom />
    </Main>
  );
};

SectionTwo.propTypes ={
  refProp: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  img: PropTypes.string,
  buttonText: PropTypes.string,
};


export default SectionTwo;
