import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ServiceSuggestionCard from './ServiceSuggestionCard.js';
import { ProviderAPI } from '../../api';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    padding: '2rem 0 0 0',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },
  cards: {
    width: '100%',
    padding: '1rem 0',
  },
});

const SuggestedServices = ({query='', limit=5}) => {
  const classes = useStyles();
  const [data, setData] = React.useState([]);

  const fetchData = async () => {
    try {
      // Search for relevant services using the passed text first
      // but if there are none (or too few) show the top ones
      // from the full list
      let response;
      response = await ProviderAPI.searchProfiles(
        {search_text: query, limit: limit, accredited: true});
      if (response.size < 5) {
        response = await ProviderAPI.searchProfiles({limit: limit, accredited: true});
      }
      setData(response.payload);
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  };

  const renderCards = () => {
    return (
      <React.Fragment>
        {data.map((d, i) =>
          <ServiceSuggestionCard
            key={i}
            data={d}
          />
        )}
      </React.Fragment>
    );
  };

  React.useEffect(() => {
    fetchData();
  }, [query]); // eslint-disable-line

  return (
    <div className={classes.root}>
      <Typography variant="h2" color="primary">
        Check out these great services
      </Typography>
      <div className={classes.cards}>
        {renderCards()}
      </div>
    </div>
  );
};

SuggestedServices.propTypes = {
  query: PropTypes.string,
  limit: PropTypes.number,
};

export default SuggestedServices;
