import React, { useState }  from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Page from 'components/Page';
import { AuthAPI } from 'api';
import { AuthStore } from 'store';
import ProviderProfileForm from './components/ProviderProfileForm';

import AlertList from './components/AlertList';


const useStyles = makeStyles(() => ({
  container: {
    marginTop: '1rem',
  },
}));

const ProfilePage = ({dispatch, profile, isAuthenticated}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const query = new URLSearchParams(location.search);
  const code = query.get('code');
  const [action, setAction] = useState(query.get('action'));
  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState([]);
  
  // this block redirects to login and back here again if user is not logged in.
  if (!isAuthenticated) {
    history.replace('/providers/login', {redirectTo: location.pathname + location.search});
    return null;
  }

  if (!profile.is_provider) {
    history.replace('/profile');
    return null;
  }

  const addAlerts = (newAlerts) => {
    setAlerts(alerts => [...newAlerts, ...alerts]);
  };

  const removeAlert = (alert) => {
    setAlerts(alerts => alerts.filter(a => a !== alert));
  };

  const updateProfile = async (args) => {
    setLoading(true);
    try {
      const response = await AuthAPI.updateProviderProfile(args);
      const { profile, messages } = response.payload;
      dispatch(AuthStore.profile(profile));
      addAlerts(messages);
    } catch (err) {
      if (err.body) {
        const { message } = err.body;
        addAlerts([{severity: 'error', text: message}]);
      } else {
        addAlerts([{severity: 'error', text: 'An error occurred updating your profile.'}]);
      }
    } finally {
      setLoading(false);
      history.replace('/providers/profile');
    }
  };

  // if there is an action pending, take care of it.
  if (action && code) {
    setAction(undefined);
    updateProfile({action, code});
  }

  return (
    <Page title="Clickability Profile">
      <div className={classes.container}>
        <Container maxWidth="md">
          <AlertList alerts={alerts} onClose={removeAlert}/>
        </Container>
        <ProviderProfileForm
          profile={profile}
          loading={loading}
        />
      </div>
    </Page>
  );
};

ProfilePage.propTypes = {
  profile: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(ProfilePage);
