import React  from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import providerNewsletter from 'assets/provider-newsletter.png';
import providerLocation from 'assets/provider-location-services.png';
import providerSearch from 'assets/provider-search-ndis.png';


const AssetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: space-between;
  height: 100%;
`;


const AssetsStack = () => {

  return (
    <AssetsContainer>
      <Asset
        asset={providerNewsletter}
        title="20,000+ engaged community members"
      />
      <Asset
        asset={providerLocation}
        title="10,000+ services viewed per day"
      />
      <Asset
        asset={providerSearch}
        title="Seen by over half a million people searching for NDIS Providers"
      />
    </AssetsContainer>
  );
};

const AssetContainer = styled.div`
  --size: 220px;
  width: var(--size);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .title {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
`;

const Asset = ({ asset, title }) => {

  return (
    <AssetContainer>
      <img src={asset} width="160px" alt="asset" />
      <div className="title">
        {title}
      </div>
    </AssetContainer>
  );
};

Asset.propTypes = {
  asset: PropTypes.string,
  title: PropTypes.string,
};


export default AssetsStack;

