import Button from '@material-ui/core/Button';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  content: {
    maxWidth: 800,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  horizontal: {
    display: 'flex',
    flexWrap: 'wrap',
    '&>*': {
      margin: 10,
    }
  },

  color: {
    display: 'flex',
    width: 150,
  },
  swatch: {
    flexShrink: 0,
    position: 'relative',
    width: 48,
    height: 48,
    marginRight: 12,
    borderRadius: 4,
    border: 'solid 2px rgba(85, 77, 86, 0.2)',
  }
});



export default function StyleGuidePage() {
  const classes = useStyles();
  const theme = useTheme();

  const colorBox = (colorName, color) => {
    return (
      <div key={colorName} className={classes.color}>
        <div className={classes.swatch} style={{backgroundColor: color}}/>
        <div>
          <b>{colorName}</b>
          <br/>
          {color}
        </div>
      </div>
    );
  };

  const colorSection = (sectionName, colors) => {
    if (typeof colors !== 'object') return null;
    return (
      <div key={sectionName}>
        <Typography variant="h2" gutterBottom>
          {sectionName}
        </Typography>
        <div className={classes.horizontal}>
          {Object.keys(colors).map(colorName => colorBox(colorName, colors[colorName]))}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h1" gutterBottom>
          h1. Heading
        </Typography>
        <Typography variant="h2" gutterBottom>
          h2. Heading
        </Typography>
        <Typography variant="h3" gutterBottom>
          h3. Heading
        </Typography>
        <Typography variant="h4" gutterBottom>
          h4. Heading
        </Typography>
        <Typography variant="h5" gutterBottom>
          h5. Heading
        </Typography>
        <Typography variant="h6" gutterBottom>
          h6. Heading
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        </Typography>
        <Typography variant="body1" gutterBottom>
          body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
          unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
          dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="body2" gutterBottom>
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
          unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
          dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="button" display="block" gutterBottom>
          button text
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          caption text
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
          overline text
        </Typography>

        <hr />

        <Typography variant="h1" color="primary" gutterBottom>
          h1. Heading textSecondary
        </Typography>
        <Typography variant="h2" color="primary" gutterBottom>
          h2. Heading textSecondary
        </Typography>
        <Typography variant="h2" color="primary" gutterBottom>
          h3. Heading textSecondary
        </Typography>
        <Typography variant="h4" color="primary" gutterBottom>
          h4. Heading textSecondary
        </Typography>
        <Typography variant="h5" color="primary" gutterBottom>
          h5. Heading textSecondary
        </Typography>

        <hr />

        <div className={classes.horizontal}>
          <Button variant="contained">Default</Button>
          <Button variant="contained" color="primary">
            Primary
          </Button>
          <Button variant="contained" color="primary">
            Secondary
          </Button>
          <Button variant="contained" disabled>
            Disabled
          </Button>
          <Button variant="contained" color="primary" href="#contained-buttons">
            Link
          </Button>
        </div>

        <div className={classes.horizontal}>
          <Button variant="outlined">Default</Button>
          <Button variant="outlined" color="primary">
            Primary
          </Button>
          <Button variant="outlined" color="primary">
            Secondary
          </Button>
          <Button variant="outlined" disabled>
            Disabled
          </Button>
          <Button variant="outlined" color="primary" href="#outlined-buttons">
            Link
          </Button>
        </div>

        <div>
          <Button>Default</Button>
          <Button color="primary">Primary</Button>
          <Button color="primary">Secondary</Button>
          <Button disabled>Disabled</Button>
          <Button href="#text-buttons" color="primary">
            Link
          </Button>
        </div>

        <hr />

        <div>
          {Object.keys(theme.palette).map(section => colorSection(section, theme.palette[section]))}
        </div>

        <hr />

        <div className={classes.horizontal}>
          <pre>
            {JSON.stringify(theme, null, 2)}
          </pre>
        </div>

      </div>
    </div>
  );
}
