import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const StyledChip = withStyles((theme) => ({
  root: {
    margin: 2,
    backgroundColor: theme.palette.common.paleGrey,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '200px',
    },
  },
}))(Chip);

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 6,
    top: 6,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: 'white',
  },
}))(Badge);

const SpecialisationChip = ({specialisation, onClick}) => {
  return (
    <StyledBadge badgeContent={specialisation.endorsements} color="secondary">
      <StyledChip label={specialisation.name} onClick={onClick && (() => onClick(specialisation))}/>
    </StyledBadge>
  );
};

SpecialisationChip.propTypes = {
  specialisation: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

const SpecialisationChipList = ({specialisations, filtered=true, onClick}) => {
  if (filtered) {
    specialisations = specialisations.filter(s => s.endorsements);
  }
  return (
    <div>
      {specialisations.map(s => <SpecialisationChip key={s.id} specialisation={s} onClick={onClick}/>)}
    </div>
  );
};

SpecialisationChipList.propTypes = {
  specialisations: PropTypes.arrayOf(PropTypes.object).isRequired,
  filtered: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SpecialisationChip;

export { SpecialisationChipList };