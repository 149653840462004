import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    minHeight: '2rem',
  },
  label: {
    textTransform: 'capitalize'
  },
  button: {
    border: '3px solid #ff9800',
    display: 'flex',
    marginLeft: '13.5rem',
    marginTop: '-3rem',
    position: 'relative',
    transition: '0.5s',
    '&:hover': {
      backgroundColor: '#ff980038',
    },
  },
});


const AdminAlertButton = ({ data, isListing = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const itemType = isListing ? 'listing' : 'profile';

  const clickEditProfile = () => {
    history.push({ pathname: `/provider/${data.uuid}/admin/listings` });
  };

  const clickEditListing = () => {
    history.push({
      pathname: `/provider/${data.provider_uuid}/admin/listings`,
      search: `?item=${data.uuid}`
    });
  };

  const onClick = isListing ? clickEditListing : clickEditProfile;


  return (
    <div className={classes.root}>
      <Alert severity="info" color="warning" icon={<SettingsIcon fontSize="small" />}>
        <AlertTitle>Admin Access</AlertTitle>
          Click here to edit your {itemType}.
        <Button
          className={classes.button}
          classes={{ label: classes.label }}
          variant="outlined"
          size="medium"
          onClick={onClick}
        >
          Edit
        </Button>
      </Alert>
    </div>
  );
};

AdminAlertButton.propTypes = {
  data: PropTypes.object.isRequired,
  isListing: PropTypes.bool,
};

export default AdminAlertButton;
