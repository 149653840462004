import PropTypes from 'prop-types';
import { AuthStore } from '../store';
import { AuthAPI } from '../api';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

// This component manages the session timeout and token refresh.
// Before the token expires, it will be renewed refreshMarginMs
// before it expires.
//
// If there is no user activity (clicks, mouse moves, or key-down)
// within inactivityTimeoutMs, the session will be logged out.
// Inactivity and Token refresh are essentially independent operations.

const inactivityTimeoutMs = 60 * 1000 * 1000; // logout after this many ms
const minRefreshMarginMs = 60 * 1000;  // refresh no less than this many ms before expiry.
const maxRefreshMarginMs = 120 * 1000; // refresh no more than this many ms before expiry.

// We randomly distribute refreshing to avoid having all tabs perform a refresh in unison.
// (which works but creates an avoidable event burst as they all propagate their new token to everyone else)
// By spreading them out, the first can do the refresh and broadcast the new token, and the other tabs
// will just reset their refresh timers and continue without making a redundant API call.

const events = ['click', 'mousemove', 'keydown'];
let active = false;
let inactivityTimer;
let refreshTimer;

// Generic timer class, isolated here to simplify main code.
class Timer {
  constructor(callback) {
    this.callback = callback;
    this.timer = undefined;
  }

  cancel() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  start(durationMs) {
    this.cancel();
    this.timer = setTimeout(this.callback, durationMs);
  }
}

const TokenTimeout = ({children, dispatch, tokenExpiresAt}) => {
  const history = useHistory();

  // when the inactivity timer fires, logout
  const onInactivityTimeout = () => {
    history.push('/logout');
  };

  // when the refresh timer fires, request a new token
  const onRefreshTimeout = async () => {
    try {
      const { payload } = await AuthAPI.refresh();
      const { id, token_ttl } = payload;
      dispatch(AuthStore.refresh(id, token_ttl));
    } catch (err) {
      history.push('/logout');
    }
  };

  // when activity happens, restart the inactivity timer
  const onActive = () => {
    if (active) {
      inactivityTimer.start(inactivityTimeoutMs);
    }
  };

  // use the token timeout to set the refresh timer
  const startRefreshTimer = () => {
    if (tokenExpiresAt !== undefined) {
      const refreshMarginMs = minRefreshMarginMs + Math.random() * (maxRefreshMarginMs - minRefreshMarginMs);
      const refreshTimeoutMs = tokenExpiresAt - Date.now() - refreshMarginMs;
      refreshTimer.start(refreshTimeoutMs);
    }
  };

  // start monitoring activity events
  const start = () => {
    active = true;
    for (let e of events) document.addEventListener(e, onActive);
    inactivityTimer.start(inactivityTimeoutMs);
  };

  // stop monitoring activity events
  const stop = () => {
    active = false;
    for (let e of events) document.removeEventListener(e, onActive);
    inactivityTimer.cancel();
    refreshTimer.cancel();
  };

  // called when tokenExpiresAt changes.
  const update = () => {
    if (tokenExpiresAt === undefined) {
      if (active) stop();
    } else {
      if (!active) start();
      startRefreshTimer();  // do this even if active because token has changed
    }
  };

  // only called when the app is unmounted.
  const onUnmount = () => {
    stop();
  };

  // called when the component is mounted
  const onMount = () => {
    inactivityTimer = new Timer(onInactivityTimeout);
    refreshTimer = new Timer(onRefreshTimeout);
    return onUnmount();
  };

  useEffect(onMount, []);  // mount/unmount registration
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => update(), [tokenExpiresAt]);

  return children;
};

TokenTimeout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  tokenExpiresAt: PropTypes.number,
};

const mapStateToProps = state => {
  return {
    tokenExpiresAt: state.auth.tokenExpiresAt,
  };
};

export default connect(mapStateToProps)(TokenTimeout);
