import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import RichTextField from '../../../components/input/RichTextField';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '2rem 1rem 2rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'left',
    minHeight: '4rem',
  },
});

const OverviewContent = ({data}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {data.full_description === null ? (
        <Alert severity="info">There is no description for this provider yet. If this is your business please contact us to claim this listing!</Alert>
      ) : (
        <RichTextField inputValue={data.full_description}/>
      )}
    </div>
  );
};

OverviewContent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OverviewContent;
