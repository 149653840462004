import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ReviewResponseCardList from './ReviewResponseCardList.js';
import { timeSinceText } from '../../lib/time.js';
import { StarRating } from '../widgets';
import RichTextField from 'components/input/RichTextField.js';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: '1rem',
    cursor: 'pointer',
    boxShadow: 'none',
    borderRadius: '3px',
    border: `1px solid ${theme.palette.common.silver}`,
    padding: '0.5rem 1rem',
  },
  widgets: {
    display: 'flex',
    flexWrap: 'no-wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.5rem 0',
  },
  widgetText: {
    paddingLeft: '5px',
  },
  responses: {
    padding: 0,
    margin: 0,
  },
}));

const ReviewCard = ({ data, withResponses = true, providerName }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6" color="primary">{data.author_username}</Typography>
        <Box className={classes.widgets}>
          <StarRating rating={data.rating} />
          <Typography variant="subtitle2" color="primary" className={classes.widgetText}>{timeSinceText(data.published_at || data.created_at)}</Typography>
        </Box>
        <RichTextField inputValue={data.content} />
        {withResponses && !!data.comments.length &&
          <Box className={classes.responses}>
            <ReviewResponseCardList data={data.comments} providerName={providerName} />
          </Box>
        }
      </CardContent>
    </Card>
  );
};

ReviewCard.propTypes = {
  data: PropTypes.object.isRequired,
  withResponses: PropTypes.bool,
  providerName: PropTypes.string.isRequired,
};

export default ReviewCard;
