import React from 'react';
import { useParams } from 'react-router-dom';

import ConnectDashboard from '../../components/ConnectDashboard';
import Header from '../../components/Header';
import CompanyDetail from './components/CompanyDetail';


const Profile = () => {
  const { uuid: providerUuid } = useParams();
  return (
    <ConnectDashboard title="Company Profile">
      <Header text="Manage Company Profile" />
      <CompanyDetail providerUuid={providerUuid} />
    </ConnectDashboard>
  );
};


export default Profile;
