import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ReviewCount, StarRating } from '../widgets';

import Button from '../Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import React from 'react';
import placeholderImage from 'assets/physiotherapist-and-boy-holding-ball-260x147.png';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '3px',
    border: `1px solid ${theme.palette.common.silver}`,
    width: '30%',
    height: '37rem',
    [theme.breakpoints.down('md')]: {
      height: '40rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2rem',
      width: '100%',
      height: '57rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '2rem',
      width: '100%',
      height: '42rem',
    },
  },
  rootShortlisted: {
    borderRadius: '3px',
    border: `1px solid ${theme.palette.secondary.main}`,
    boxShadow: `0px 0px 2px 1px ${theme.palette.secondary.main}`,
    width: '30%',
    height: '37rem',
    [theme.breakpoints.down('md')]: {
      height: '40rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2rem',
      width: '100%',
      height: '57rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '2rem',
      width: '100%',
      height: '42rem',
    },
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '1rem',
    width: '100%',
    height: '100%',
    boxShadow: 'none',
  },
  coverImg: {
    flexShrink: 0, // prevents image resizing during page load
    width: '100%',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '1.4rem',
    height: '2.8rem',
    overflow: 'hidden',
    marginBottom: '1rem',
    width: '100%',
  },
  desc: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    lineHeight: '1.2rem',
    height: '3.6rem',
    overflow: 'hidden',
    marginBottom: '1rem',
    width: '100%',
  },
  keyPoints: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  keyPoint: {
    width: '80%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  check: {
    color: theme.palette.common.tealish,
    marginRight: '1rem',
  },
  ratings: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: '1rem',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
}));

const ListingMatchCard = ({ data, shortlisted = false, handleClick }) => {
  const classes = useStyles();
  const imageSize = '@200x112';
  const img = data.image_path ? data.image_path + imageSize : placeholderImage;
  const buttonLabel = shortlisted ? 'Shortlisted!' : 'Add to shortlist';
  const buttonColor = shortlisted ? 'secondary' : 'primary';
  const buttonPointer = shortlisted ? 'check' : 'plus';

  return (
    <div className={shortlisted ? classes.rootShortlisted : classes.root}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.coverImg}
          component="img"
          src={img}
          role="presentation"
        />
        <CardContent className={classes.content}>
          <Typography variant="h6" align="center" color="primary" component="div" className={classes.title}>{data.title}</Typography>
          <Typography variant="body2" align="center" component="div" className={classes.desc}>{data.short_description}</Typography>
          <Box className={classes.keyPoints}>
            {data.key_points.map((p, i) => (
              <div key={i} className={classes.keyPoint}>
                <CheckCircleIcon className={classes.check}/>
                <Typography variant="body2">{p}</Typography>
              </div>
            ))}
          </Box>
          <Box className={classes.ratings}>
            <StarRating rating={data.rating} />
            <ReviewCount count={data.review_count} />
          </Box>
          <Button
            label={buttonLabel}
            color={buttonColor}
            pointer={buttonPointer}
            onClick={handleClick}
            style={{color: 'white'}}
          />
        </CardContent>
      </Card>
    </div>
  );

};

ListingMatchCard.propTypes = {
  data: PropTypes.object.isRequired,
  shortlisted: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

export default ListingMatchCard;
