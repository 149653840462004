import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputContainer from './InputContainer';
import { formatComponentId } from 'lib/util.js';

const baseStyles = {
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'centre',
  },
  autocomplete: {
    width: '100%',
  },
};

const regularStyle = makeStyles(theme => ({
  ...baseStyles,
  input: {
    width: '100%',
    margin: 0,
    borderRadius: 4,
    borderWidth: '3px',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.silver}`,
    '&:focus-within': {
      borderColor: theme.palette.secondary.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      padding: 0,
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '0 6px',
    },
  }
}));

const errorStyle = makeStyles(theme => ({
  ...baseStyles,
  input: {
    width: '100%',
    margin: 0,
    borderRadius: 4,
    borderWidth: '3px',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.error.main}`,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      padding: 0,
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
    },
    '& .MuiOutlinedInput-root': {
      padding: '0 6px',
    },
  },
}));

const PredictiveDropdownInput = ({ label, value: initialValue, placeholder, options, errorText, helperText, onChange }) => {
  const [validationActive, setValidationActive] = useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(initialValue || '');
  const error = validationActive && errorText !== undefined && errorText !== '';
  const classes = error ? errorStyle() : regularStyle();
  const componentId = formatComponentId('predictive-dropdown-input', label);

  React.useEffect(() => {
    if (initialValue === null) {
      setValue('');
      setInputValue('');
    }
  }, [initialValue]);

  React.useEffect(() => {
    if (inputValue.length < 2) return;
  }, [inputValue]);

  const renderInput = (params) => {
    return (
      <TextField
        className={classes.input}
        placeholder={placeholder}
        variant="outlined"
        {...params}
      />
    );
  };

  return (
    <InputContainer
      label={label}
      componentId={componentId}
      validationActive={validationActive}
      errorText={errorText}
      helperText={helperText}
    >
      <Autocomplete
        options={options}
        renderInput={renderInput}
        className={classes.autocomplete}
        value={value}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, value) => {
          setValue(value);
          setValidationActive(true);
          onChange(value);
        }}
        onBlur={() => {
          setValidationActive(true);
        }}
      />
    </InputContainer>
  );
};

PredictiveDropdownInput.propTypes = {
  label: PropTypes.node,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  errorText: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  onChange: PropTypes.func.isRequired,
};

export default PredictiveDropdownInput;
