import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import { Typography, Tooltip } from 'antd';
import styled from 'styled-components';
import { faEnvelope, faPhoneAlt } from '@fortawesome/pro-duotone-svg-icons';

import Card from 'providerSite/components/Card';
import { ProviderAPI, ConnectAPI, useGetApi, useMutateApi } from 'providerSite/api';
import StarRating, { ReviewCount } from 'components/widgets';
import style from 'style';
import InfoDetail from './InfoDetail';
import Divider from './Divider';
import ContactDisplayer from './ContactDisplayer';
import ServiceRequestStatus from 'components/ServiceRequestStatus';


const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  row-gap: 10px;
  
`;

const ContactInfoContainer = styled.div`
  display: flex;
  column-gap: 15px;
  
  .contact-info {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    
    .title {
      font-size: 14px;
      font-weight: 500;
      color: ${style.colors.primary};
      opacity: 0.45;
    }
  }
`;

const Header = styled.div`
  .ant-typography {
    :hover {
      color: ${style.colors.secondary};
    }
  }
  .title {
    max-width: 320px;
    overflow: hidden;
  }
`;

const ProviderDetail = (
  {
    serviceRequestUuid,
    providerUuid,
    setPageLoading,
    status,
    notification
  }) => {

  const { uuid: connectProviderUuid } = useParams();

  const { isLoading, data } = useGetApi(ProviderAPI.GET_PROVIDER, {
    params: {
      uuid: providerUuid
    },
  });

  const mutation = useMutateApi(ConnectAPI.PATCH_NOTIFICATION, 'PATCH', {
    params: {
      providerUuid: connectProviderUuid,
      notificationUuid: notification?.uuid,
    },
  });

  useEffect(() => {
    if ( isLoading ) {
      setPageLoading(true);
    } else {
      setPageLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!notification || notification.isSeen) return;
    mutation.mutate({});
  }, [notification]);


  const renderTitle = (data) => {
    return (
      <TitleContainer>
        <Header>
          <div style={{width: 'max-content'}}>
            <Tooltip title="View Profile">
              <Link to={`/provider/${data.slug}`} target="_blank" rel="noreferrer">
                <Typography.Title className="title">{data.name}</Typography.Title>
              </Link>
            </Tooltip>
          </div>
          <RatingContainer>
            <StarRating rating={data.rating} />
            <ReviewCount
              count={data.reviewCount}
              onClick={() => (
                window.open(
                  `/provider/${data.slug}?tab=2`,
                  '_blank',
                  'noreferrer'
                ))}
            />
          </RatingContainer>
        </Header>
        <TitleRightContainer>
          <ServiceRequestStatus
            key={`ServiceRequestStatus-${serviceRequestUuid}`}
            serviceRequestUuid={serviceRequestUuid}
            status={status}
            availableOptions={[]}
            closureReasonOptions={[]}
          />
        </TitleRightContainer>
      </TitleContainer>
    );
  };

  const renderContactInfo = (data) => {
    return (
      <ContactInfoContainer>
        <div className="contact-info">
          <Typography.Text className="title">Phone</Typography.Text>
          <ContactDisplayer icon={faPhoneAlt} text={data.phone} />
        </div>
        <div className="contact-info">
          <Typography.Text className="title">Email</Typography.Text>
          <ContactDisplayer icon={faEnvelope} text={data.email} />
        </div>
      </ContactInfoContainer>
    );
  };

  return (
    <Card>
      {data && (
        <>
          {renderTitle(data)}
          <Divider />
          <InfoDetail
            title='Service area'
            text={data.serviceLocations.map((location) => location.name).join(', ')}
          />
          <Divider margin="15px"/>
          {renderContactInfo(data)}
        </>
      )}
    </Card>
  );
};

ProviderDetail.propTypes = {
  serviceRequestUuid: PropTypes.string,
  providerUuid: PropTypes.string,
  setPageLoading: PropTypes.func,
  status: PropTypes.string,
  notification: PropTypes.object,
};

export default ProviderDetail;