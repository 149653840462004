import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const StyledChip = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.tealish,
    color: theme.palette.common.white,
    fontWeight: 600,
    marginRight: '1rem',
  },
}))(Chip);

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '1rem 0',
  },
});

const ServiceChip = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StyledChip label={data.service} />
    </div>
  );
};

ServiceChip.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServiceChip;
