import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MailTo from '../../../components/MailTo';
import config from '../../../config';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '2rem',
  },
  item: {
    fontSize: '0.8rem',
    marginBottom: '0.5rem',
  },
  link: {
    color: theme.palette.primary.main,
  },
}));


const BlogListBlurb = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h2" color="primary">
        Are you our next Shakespeare?
      </Typography>
      <Typography variant="body2" component="div">
        <p>
          Your experience can help others navigate their way through services, disability, and well... life!
        </p>
        <p>
          Email <MailTo className={classes.link} email={config.socials.blogEmail}>{config.socials.blogEmail}</MailTo> to join our community of authors. Not a confident writer? Don’t worry, we&apos;ll work with you to make sure your article is top-notch.
        </p>
      </Typography>
    </div>
  );
};

export default BlogListBlurb;
