import ContactText from 'components/ContactText';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Page from '../../components/Page.js';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import backgroundImg from '../../assets/background-2.svg';
import logoIcon from '../../assets/logo-icon-dark-blue.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  background: {
    width: '100%',
    minHeight: '70vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundImage: `url(${backgroundImg})`
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '4rem 1.5rem',
    height: '100%',
    minHeight: '100%',
  },
  main: {
    paddingLeft: '4.4rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  text: {
    width: '60%',
    margin: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  img: {
    width: '4.4rem',
  },
  email: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const SiteDown = () => {
  const classes = useStyles();
  return (
    <Page title="Clickability - Page not found">
      <div className={classes.background}>
        <Container maxWidth="lg" fixed disableGutters={true}>
          <Grid container className={classes.content}>
            <Grid item md={12}>
              <img src={logoIcon} className={classes.img} alt="icon"/>
            </Grid>
            <Grid item md={12} className={classes.main}>
              <Typography variant="h1" color="primary" className={classes.text}>
                Hey! Seems you&apos;ve caught us  making some magic.
              </Typography>
              <Typography variant="body2" className={classes.text}>
                We&apos;re super busy behind doors readying the website for relaunch. Come back and visit us soon to experience even more functionality for participants and providers – all with a fresh new look.
              </Typography>
              <Typography variant="body2" className={classes.text}>
                If you want to find out more <ContactText/>.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

export default SiteDown;
