import React from 'react';

import styled from 'styled-components';

import config from 'config';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
  
  '& p': {
    max-width: 400px;
  },
  '& a': {
    font-size: 20px;
  }
  
  .promo-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 14px;
  }
  
  .promo-code {
    border: 1px solid #d4d4d4;
    border-radius: 14px;
    background-color: #f4f4f4;
    font-size: 20px;
    font-family: monospace;
    padding: 10px 20px;
    margin: 10px 0px;
  }
  
  .text-button {
    font-weight: 700;
    font-size: 18px;
  }
`;

const Giveaway = () => {

  return (
    <Container>
      <div className="promo-title">
        Go into the draw to Win an Apple Watch!
      </div>
      <p>
        While you wait, why not submit an entry into our giveaway? Your request came with 25 additional
        entries which can be claimed using the special code
      </p>
      <div className="promo-code">
        {config.features.iphoneGiveaway.code}
      </div>
      <a className="text-button" target="_blank" rel="noopener noreferrer" href={config.features.iphoneGiveaway.url}>
        Click here to enter
      </a>
      <p style={{fontSize: '13px'}}>
        Share it with your friends for even more chances to win!
      </p>
    </Container>
  );
};

Giveaway.propTypes = {};


export default Giveaway;

