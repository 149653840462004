import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import SpecialisationIcon from '@material-ui/icons/Flare';

const useStyles = makeStyles((theme) => ({
  chipList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));


const SpecialisationList = ({
  title,
  specialisations,
  active,
  onClick,
}) => {
  const classes = useStyles();
  if (specialisations.length === 0) return null;

  return (
    <div>
      <h3>{title}</h3>
      <div className={classes.chipList}>
        {specialisations.map(s => {
          const chipProps = active ? {} : {color: 'secondary'};
          return (
            <Chip 
              color="primary" 
              key={s.id} 
              label={s.name} 
              icon={<SpecialisationIcon />} 
              onClick={()=>onClick(s)}
              variant="outlined"
              {...chipProps}
            />
          );
        })}
      </div>
    </div>
  );
};

SpecialisationList.propTypes = {
  title: PropTypes.string.isRequired,
  specialisations: PropTypes.array.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};  

export default SpecialisationList;