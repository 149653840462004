import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import {default as StdButton} from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../components/Button';
import RoleList from './RoleList';
import { adminRole } from '../../../lib/roles';

const useStyles = makeStyles({
  content: {
    minWidth: 400,
  },
});

const UserRolesDialogue = ({initialRoles, isSelf, allRoles, subtitle, onChange, onCancel, busy=false}) => {

  const [userRoles, setUserRoles] = useState(initialRoles);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const classes = useStyles();

  const confirmRoleChange = () => {
    if (isSelf && initialRoles.includes(adminRole) && !userRoles.includes(adminRole)) {
      setShowConfirmation(true);
    } else {
      onChange(userRoles);
    }
  };

  return (
    <React.Fragment>
      <Dialog open={true} onClose={onCancel} aria-labelledby="form4-dialog-title">
        <DialogTitle id="form4-dialog-title">Select Roles</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>{subtitle}</DialogContentText>
          <RoleList editable={true} roleNames={userRoles} allRoles={allRoles} onChange={setUserRoles}/>
        </DialogContent>
        <DialogActions>
          <StdButton onClick={onCancel} color="primary">
            Cancel
          </StdButton>
          <Button onClick={confirmRoleChange} variant="contained" label="Save" color="primary" busy={busy} disabled={!userRoles.length }/>
        </DialogActions>
      </Dialog>

      <Dialog open={showConfirmation} onClose={() => showConfirmation(false)} aria-labelledby="form2-dialog-title">
        <DialogTitle id="form2-dialog-title">Revoke Your Admin Permissions?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to revoke your own admin permissions?  You must leave at least one administrator active.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StdButton onClick={() => setShowConfirmation(false)} color="primary">
            Cancel
          </StdButton>
          <Button onClick={() => {setShowConfirmation(false); onChange(userRoles);}} color="primary" variant="contained" label="Revoke"/>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
};

UserRolesDialogue.propTypes = {
  initialRoles: PropTypes.array.isRequired,
  isSelf: PropTypes.bool,
  allRoles: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  busy: PropTypes.bool,
  subtitle: PropTypes.string,
};

export default UserRolesDialogue;
