import QualifiedServiceIcon from 'components/QualifiedServiceIcon';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const ICONS = [
  'Support Work',
  'Plan Management',
  'Occupational Therapy',
  'Cleaning',
  'Speech Therapy',
  'Physiotherapy',
  'Psychology',
  'Psychosocial Recovery Coaching',
  'Support Coordination',
  'Specialist Support Coordination',
  'Gardening',
  'Behaviour Support',
];

const useStyles = makeStyles({
  root: {
    backgroundColor: '#888888',
    padding: 20,
  },
  label: {
    fontSize: 12,
  },
  container: {
    transform: 'scale(3)',  // scale rather than increase fontSize because icon class uses margin adjustments tuned for 12px size
    fontSize: 12,
    width: 'fit-content',
    backgroundColor: '#ffffff',
    color: '#000000',
    //fontSize: 100,
    margin: 80,
    padding: 5,
    '&>svg': {
      display: 'block',  // this is necessary to suppress white space below the svg within the container
    },
  }
});


export default function IconSheet() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {
        ICONS.map(serviceName => (
          <div key={serviceName} className={classes.icon}>
            <div className={classes.container}>
              <QualifiedServiceIcon.Regular serviceName={serviceName} />
            </div>
            <div className={classes.label}>
              {serviceName}
            </div>
          </div>
        ))
      }
    </div>
  );
}
