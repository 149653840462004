import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import ServiceRequestStatus from 'components/ServiceRequestStatus';
import verifiedIconBlack from 'assets/verified-icon-black.svg';
import verifiedIconDisabled from 'assets/verified-icon-silver.svg';
import verifiedIconGrey from 'assets/verified-icon-battleship-grey.svg';


const pageIndicatorStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: ({isInvalid}) => isInvalid ? theme.palette.grey[500] : theme.palette.common.darkSlateBlue,
    columnGap: '10px',
    paddingTop: '5px',
    minHeight: '40px',
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    '&:hover': {
      color: ({isDisabled}) => !isDisabled && theme.palette.common.tealish,
      cursor: ({isDisabled}) => isDisabled ? 'not-allowed' : 'pointer',
    },
  },
  circle: {
    height: '21px',
    width: '21px',
    background: theme.palette.common.white,
    borderRadius: '50%',
    marginLeft: '3px',

  },
  circleBordered: {
    border: '3px solid',
    borderColor: theme.palette.common.tealish,
  },
  icon: {
    marginTop: '5px',
    color: ({status, isInvalid}) => {
      if (isInvalid) return theme.palette.grey[400];
      switch (status) {
      case 'current':
        return theme.palette.common.tealish;
      case 'complete':
        return null;
      default:
        return theme.palette.common.white;
      }
    },
  },
}));

const pageIndicatorRemovableStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
  },
  trashIcon: {
    marginLeft: 'auto',
    fontSize: '20px',
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.common.dustyOrange,
      cursor: 'pointer',
    },
  },
}));

const pageIndicatorWithStatusStyles = makeStyles({
  upperContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
  },
  bottomContainer: {
    marginLeft: '35px',
    marginTop: '6px',
  },
  matchButton: {
    marginLeft: 'auto',
  },
});

const matchButtonStyles = makeStyles({
  icon: {
    cursor: ({isDisabled}) => isDisabled ? 'not-allowed' : 'pointer',
    width: '24px',
    height: '24px',
    marginTop: '10px'
  },
});

const PageIndicator = (
  {
    text,
    status,
    onClick,
    isInvalid=false,
    isDisabled=true
  }) => {
  const classes = pageIndicatorStyles({status: status, isDisabled: isDisabled, isInvalid: isInvalid});

  const renderIcon = () => {
    if (isInvalid) return <CheckCircleIcon className={classes.icon} />;
    switch (status) {
    case 'current':
      return <div className={classNames(classes.circle, classes.circleBordered)} />;
    case 'complete':
      return <CheckCircleIcon className={classes.icon} />;
    default:
      return <div className={classes.circle} />;
    }
  };

  const render = () => {
    return (
      <div className={classes.root}>
        <div>
          {renderIcon()}
        </div>
        <div className={classes.text} onClick={(isInvalid || isDisabled) ? () => {} : onClick}>{text}</div>
      </div>
    );
  };

  return (
    <>
      {isInvalid ? (<Tooltip title="No more information required" arrow>{render()}</Tooltip>) : render()}
    </>
  );
};

const PageIndicatorRemovable = (
  {
    text,
    status,
    onClick,
    onDeleteClick,
    isDisabled=true,
    isDeleteDisabled=false,
  }) => {
  const classes = pageIndicatorRemovableStyles();
  return (
    <div className={classes.root}>
      <PageIndicator text={text} status={status} onClick={onClick} isDisabled={isDisabled} />
      {!isDeleteDisabled && <DeleteForeverIcon className={classes.trashIcon} onClick={onDeleteClick}/>}
    </div>
  );
};

const PageIndicatorWithStatus = (
  {
    text,
    status,
    onClick,
    onMatchClick,
    isDisabled=true,
    serviceRequestStatus,
  }) => {
  const classes = pageIndicatorWithStatusStyles();
  return (
    <div>
      <div className={classes.upperContainer}>
        <PageIndicator text={text} status={status} onClick={onClick} isDisabled={isDisabled} />
        <MatchButton className={classes.matchButton} serviceRequestStatus={serviceRequestStatus} onClick={onMatchClick} />
      </div>
      <div className={classes.bottomContainer}>
        {serviceRequestStatus && <ServiceRequestStatus status={serviceRequestStatus}/>}
      </div>
    </div>
  );
};

const MatchButton = ({serviceRequestStatus, onClick, className}) => {

  const isDisabled = !['new', 'rematch'].includes(serviceRequestStatus);
  const regularIcon =  isDisabled ? verifiedIconDisabled : verifiedIconGrey;
  const [verifiedIcon, setVerifiedIcon] = useState(regularIcon);

  const classes = matchButtonStyles({isDisabled: isDisabled});

  useEffect(() => {
    setVerifiedIcon(regularIcon);
  }, [isDisabled]);   // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={className}>
      <Tooltip title={isDisabled ? 'Not Available' : 'Match Provider'}>
        <img
          className={classes.icon}
          src={verifiedIcon}
          alt="match-provider-icon"
          onMouseEnter={() => !isDisabled && setVerifiedIcon(verifiedIconBlack)}
          onMouseLeave={() => setVerifiedIcon(regularIcon)}
          onClick={val => {
            !isDisabled && onClick(val);
          }}
        />
      </Tooltip>
    </div>
  );
};

PageIndicator.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
};

PageIndicatorRemovable.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.string,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  isDeleteDisabled: PropTypes.bool,
};

PageIndicatorWithStatus.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.string,
  onClick: PropTypes.func,
  onMatchClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  serviceRequestStatus: PropTypes.string,
};

MatchButton.propTypes = {
  onClick: PropTypes.func,
  serviceRequestStatus: PropTypes.string,
  className: PropTypes.string,
};

export default {
  Regular: PageIndicator,
  Removable: PageIndicatorRemovable,
  WithStatus: PageIndicatorWithStatus,
};