import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Search from '../components/Search';
import HeaderFilter from './HeaderFilter';
import { getDefaultWidth, getColumnKey } from './utils';
import { Column, RowContainer } from './Row';
import style from 'style';


const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0;
`;

const HeaderRowContainer = styled(RowContainer)`
  border-top-left-radius: ${({ hasFilter }) => hasFilter ? 0 : style.borderRadius.large};
  border-top-right-radius: ${({ hasFilter }) => hasFilter ? 0 : style.borderRadius.large};
`;

const TableHeaderContainer = styled.div`
  margin-bottom: 15px;
`;

const RegularTitleContainer = styled.div`
  font-weight: 500;
  color: ${style.colors.primary};
  .icon {
    margin-right: 10px;
  }
`;

const TableHeader = ({
  columns,
  filters,
  onFilterClick,
  showSearchBar,
  onSearch,
  searchPlaceHolder,
}) => {
  return (
    <TableHeaderContainer>
      {showSearchBar && (
        <SearchContainer>
          <Search placeholder={searchPlaceHolder} onSearch={onSearch} />
        </SearchContainer>
      )}
      {filters && <HeaderFilter filters={filters} onFilterClick={onFilterClick}/>}
      <HeaderRowContainer hasFilter={!!filters}>
        {columns.map(column => (
          <Column key={getColumnKey(column)} width={column.width || getDefaultWidth(columns)}>
            {typeof column.title === 'function' ? column.title() : (
              <RegularTitleContainer>
                {column.icon && <FontAwesomeIcon className="icon" icon={column.icon}/>}
                {column.title}
              </RegularTitleContainer>
            )}
          </Column>
        ))}
      </HeaderRowContainer>
    </TableHeaderContainer>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })),
  filters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    count: PropTypes.number,
  })),
  onFilterClick: PropTypes.func,
  showSearchBar: PropTypes.bool,
  onSearch: PropTypes.func,
  searchPlaceHolder: PropTypes.string
};

export default TableHeader;