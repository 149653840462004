import React from 'react';

import { useParams, useHistory } from 'react-router-dom';

import HomeVariantTwo from './HomeVariantTwo';
import hicapsLogo from 'assets/hicaps-logo.png';
import hcpaLogo from 'assets/hcpa-logo.png';
import ndspLogo from 'assets/ndsp-logo.png';


const PROVIDERS = {
  hicaps: {
    name: 'HICAPS',
    logo: hicapsLogo,
    bottomText: null,
  },
  hcpa: {
    name: 'HCPA',
    logo: hcpaLogo,
    bottomText: 'Get access to over 130,000 people who are trying to find the perfect disability service provider.',
  },
  ndsp: {
    name: 'NDSP',
    logo: ndspLogo,
    bottomText: null,
  },
};


const HomeReferral = () => {
  const { provider } = useParams();
  const history = useHistory();

  const providerData = PROVIDERS[provider];

  if (!providerData) {
    history.push('/providers');
    return null;
  }

  return (
    <HomeVariantTwo
      topHeroText={`${providerData.name} & Clickability`}
      bottomHeroText={providerData.bottomText}
      secondaryLogo={providerData.logo}
    />
  );
};


export default HomeReferral;
