import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Radio } from 'antd';

import style from 'style';


const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${style.colors.textPrimary};
  font-size: 14px;
  column-gap: 20px;
`;

const QuestionContainer = styled.div`
  font-weight: 400;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`

`;

const Question = ({ children, onClick, isChecked }) => {

  return (
    <Container>
      <QuestionContainer>
        {children}
      </QuestionContainer>
      <ButtonContainer>
        <Radio.Group value={isChecked} onChange={onClick}>
          <Radio.Button value={isChecked ? isChecked : false}>Yes</Radio.Button>
        </Radio.Group>
      </ButtonContainer>
    </Container>
  );
};

Question.propTypes = {
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
};


export default Question;

