import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { getHexWithOpacity } from 'lib/util';


const useStyles = ({ color }) => {
  const classes = makeStyles({
    root: {
      background: color,
      borderRadius: '50%',
      height: '9px',
      width: '9px',
    },
    pulse: {
      boxShadow: `0 0 0 0 ${getHexWithOpacity(color, 100)}`,
      transform: 'scale(1)',
      animation: '$pulse 2s infinite',
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: `0 0 0 0 ${getHexWithOpacity(color, 70)}`,
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: `0 0 0 8px ${getHexWithOpacity(color, 0)}`,
      },
      '100%': {
        transform: 'scale(0.95)',
        boxShadow: `0 0 0 0 ${getHexWithOpacity(color, 0)}`,
      },
    }
  });
  return classes();
};

const Dot = ({ color, noPulse, ...props }) => {

  const classes = useStyles({color: color});

  return (
    <div className={noPulse ? classes.root : classNames(classes.root, classes.pulse)} {...props} />
  );
};

Dot.propTypes = {
  color: PropTypes.string,
  noPulse: PropTypes.bool,
};

export default Dot;