import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PasswordRecoveryForm from './components/PasswordRecoveryForm';
import { useHistory } from 'react-router-dom';
import AuthFormPage from './components/AuthFormPage';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/Button';

const useStyles = makeStyles({
  text: {
    marginTop: '1rem',
    marginBottom: '2rem',
    maxWidth: '15rem',
  },
  circle: {
    background: 'rgba(255,255,255,0.7)',
    width: '25rem',
    height: '25rem',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderColor: '#888888',
    borderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  }
});

const PasswordRecoveryPage = ({isProvider}) => {
  const history = useHistory();
  const recover = (email) => {
    history.push('/password/confirm', {email});
  };
  const classes = useStyles();
  const rightPanel = !isProvider && (
    <div className={classes.circle}>
      <Typography variant="h2" color="primary">
        Are you a service provider?
      </Typography>
      <Typography variant="body1" color="primary" className={classes.text}>
        This is the password reset page for <b>participants</b>.  If you are a service provider, please follow the link below.
      </Typography>
      <Button label="Service Provider Login" className={classes.button} to="/providers/login"/>
    </div>
  );
  const title = isProvider ? 'Service Provider Password Recovery' : 'Password Recovery';
  return (
    <AuthFormPage title="Clickability Password Recovery" rightPanel={rightPanel}>
      <PasswordRecoveryForm
        onComplete={recover}
        isProvider={isProvider}
        title={title}
      />
    </AuthFormPage>
  );
};

PasswordRecoveryPage.propTypes = {
  isProvider: PropTypes.bool,
};

export default PasswordRecoveryPage;
