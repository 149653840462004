import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
});

const QuestionContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

QuestionContainer.propTypes = {
  children: PropTypes.node,
};

export default QuestionContainer;
