import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Tooltip } from 'antd';
import styled from 'styled-components';

import style from 'style';


const ContactDisplayerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  padding: 20px 15px;
  border-radius: ${style.borderRadius.medium};
  border: 0.5px solid #DBDBE5;
  cursor: pointer;
  
  .contact-text {
    display: -webkit-box;
    color: ${style.colors.primary};
    font-size: 14px;
    margin: 0;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const ContactDisplayer = ({ icon, text }) => {
  const [visible, setVisible] = useState(false);

  const onContactClick = () => {
    navigator.clipboard?.writeText(text);
    setVisible(true);
    setTimeout(() => setVisible(false), 1000);
  };

  return (
    <Tooltip title="Copied" visible={visible}>
      <ContactDisplayerContainer onClick={onContactClick}>
        <FontAwesomeIcon icon={icon} />
        <Typography.Title className="contact-text">{text}</Typography.Title>
      </ContactDisplayerContainer>
    </Tooltip>
  );
};

ContactDisplayer.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string
};

export default ContactDisplayer;

