import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Map, TileLayer, Popup, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerImage from 'leaflet/dist/images/marker-icon.png';
import shadowImage from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';


const icon = L.icon({
  iconUrl: markerImage,
  iconAnchor:  [12, 41],
  shadowUrl: shadowImage,
});


const MarkerWithOpenPopup = props => {
  const initMarker = ref => {
    if (ref) {
      ref.leafletElement.openPopup();
    }
  };
  return <Marker ref={initMarker} icon={icon} {...props}/>;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },  
  title: {
    marginBottom: '1rem',
    color: theme.palette.primary.main,  
  },
  mapContainer: {
    height: '400px',
    width: '100%',
  },
}));

const LocationMap = ({isLoading, premises, selectedPremise}) => {
  const classes = useStyles();
  if (isLoading || premises.length === 0) {
    return null;
  }
  const premise = premises[selectedPremise];
  const position = [premise.latitude, premise.longitude];
  return (
    <div className={classes.root}>
      <Map center={position} zoom={16} className={classes.mapContainer} attributionControl={false}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerWithOpenPopup aria-label="Map marker of primary address" position={position}>
          <Popup offset={[0, -30]} aria-label={premise.formattedAddress}>{premise.formattedAddress}</Popup>
        </MarkerWithOpenPopup>
      </Map>
    </div>
  );
};

LocationMap.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  premises: PropTypes.array.isRequired,
  selectedPremise: PropTypes.number.isRequired,
};

export default LocationMap;
