import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  title: {
    padding: 0,
    margin: '0.5rem 0',
  },
});

const classNames = (...names) => {
  return names.filter(name => name !== undefined).join(' ');
};

const SectionTitle = ({title, variant='h3', className=undefined}) => {
  const classes = useStyles();
  return (
    <Typography variant={variant} color="primary" className={classNames(classes.title, className)}>
      {title}
    </Typography>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.node.isRequired,
  variant:  PropTypes.string,
  className: PropTypes.string,
};

export default SectionTitle;
