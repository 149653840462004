import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import style from 'style';


const NotificationBarContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: .5rem;
  background-color: ${style.colors.warning};
`;

const NotificationBar = ({children}) => {
  return (
    <NotificationBarContainer >
      {children}
    </NotificationBarContainer>
  );
};

NotificationBar.propTypes = {
  children: PropTypes.node
};

export default NotificationBar;