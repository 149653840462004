import moment from 'moment';
import store from '../store';
import { AnalyticsAPI } from '../api';

export * from './trackingConstants';

export const sendEvent = (componentName, eventType, args={}, providers=[]) => {
  const authState = store.getState().auth;
  const isAuthenticated = authState.isAuthenticated;
  const profile = store.getState().auth.profile;
  const userId = isAuthenticated ? profile.user_id : null;
  const socialLogin = isAuthenticated ? authState.socialLogin : null;
  const isProvider = (isAuthenticated && 'isProvider' in profile) ? profile.isProvider : null;
  const timestamp = moment.utc().toISOString();
  const url = window.location.href;
  const session = ('; '+document.cookie).split('; _ga=').pop().split(';').shift();
  const userAgent = window.navigator.userAgent;
  const event = {
    ui_timestamp: timestamp,
    component_name: componentName,
    event_type: eventType,
    session: session,
    url: url,
    user_id: userId,
    is_provider: isProvider,
    social_login: socialLogin,
    providers: providers,
    detail: args,
    user_agent: userAgent
  };
  AnalyticsAPI.sendTrackEvent(event);
};

export const EVENT_CLICK = 'click';
export const EVENT_VIEW = 'view';
