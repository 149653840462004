import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-duotone-svg-icons';
import styled from 'styled-components';


const ButtonStyled = styled(Button)`
  display: flex;
  column-gap: 5px;
  align-items: center;
  line-height: normal;
  padding-left: 0;
`;


const BackButton = ({ ...props }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <ButtonStyled type="link" onClick={() => history.push(location.pathname)} {...props}>
      <FontAwesomeIcon icon={faAngleLeft} />
      <Typography.Link>Back</Typography.Link>
    </ButtonStyled>
  );
};


export default BackButton;
