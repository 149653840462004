import * as QueryString from 'query-string';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { ProviderAPI } from 'api';
import {
  ACCESS_METHOD_ONLINE_SERVICE,
  ACCESS_METHOD_TELEHEALTH,
  ONLINE_SERVICE_FIELD, ONLINE_SERVICE_YES,
  PARTICIPANT_FIRST_NAME_FIELD,
  PLAN_TYPE_AGENCY_MANAGED,
  SERVICE_CATEGORY_ALLIED_HEALTH,
  SERVICE_CATEGORY_PLAN_MANAGEMENT,
  SERVICE_CATEGORY_SUPPORT_COORDINATION,
  SERVICE_FOR_TYPE_MYSELF,
  TELEHEALTH_FIELD, TELEHEALTH_YES
} from '../constants';


const SEARCH_URI = 'search';

export const genericTextboxValidator = value => !!value?.match(/^[a-zA-Z'\- ]+$/g);
export const freeTextValidator = value => !!value;
export const phoneNumberValidator = value => !!value && isValidPhoneNumber(value, 'AU');
export const dateValidator = value => !!value && value !== 'invalid';

export const formatListToString = list => {
  if (list.length > 2) {
    const firstPart = list.slice(0, -1).join(', ');
    return [firstPart, list[list.length - 1]].join(' and ');
  }
  return list.join(' and ');
};

const getParamsForServiceSearch = (services, ndis, location, telehealth, ageGroup) => {
  let params = {
    service: !!services.length && services[0].name,
    service_location: location,
    include_empty: true,
    age_group: ageGroup,
  };

  if (ndis === PLAN_TYPE_AGENCY_MANAGED) {
    params = {...params, ndis_registered: true};
  }

  if (telehealth === 'No') {
    params = {...params, access_method: '!Telehealth'};
  }

  return params;
};

export const checkServiceAvailability = async (services, ndis, location, telehealth, ageGroup) => {
  const params = getParamsForServiceSearch(services, ndis, location, telehealth, ageGroup);

  const { total } = await ProviderAPI.searchListings({...params, accredited: true, limit: 1});

  return !!total;
};

export const getDirectoryUrl = async (services, ndis, location, telehealth, ageGroup) => {
  // Do not filter if multiple services are given
  if (services.length > 1) return SEARCH_URI;

  const params = getParamsForServiceSearch(services, ndis, location, telehealth, ageGroup);

  const isServiceAvailable = await checkServiceAvailability(services, ndis, location, telehealth, ageGroup);

  if (isServiceAvailable) {
    return `${SEARCH_URI}?${QueryString.stringify(params)}`;
  }
  return SEARCH_URI;
};

export const lowercase = string => {
  if (!string.length) return string;
  return string.toLowerCase();
};

export const getPronoun = gender => {
  switch (gender) {
  case 'Female (she/her)':
    return 'she';
  case 'Male (he/him)':
    return 'he';
  default:
    return 'they';
  }
};

export const getFromMyselfServiceType = (answers, yes, no) => (
  answers?.servicesFor === SERVICE_FOR_TYPE_MYSELF ? yes : no
);

export const getYouOrPart = (answers) => (
  getFromMyselfServiceType(answers, 'you', answers[PARTICIPANT_FIRST_NAME_FIELD])
);

export const getYourOrParts = (answers) => (
  getFromMyselfServiceType(answers, 'your', `${answers[PARTICIPANT_FIRST_NAME_FIELD]}'s`)
);


export const isService = (answers, serviceName) => {
  if (!answers || !answers.servicesNeeded || !Array.isArray(answers.servicesNeeded)
    || !answers.servicesNeeded.length) {
    return false;
  }

  return answers.servicesNeeded[0].name === serviceName;
};

export const getServicesWithMapping = (qualifiedServicesTaxonomy, servicesTaxonomy) => {

  const qualifiedServicesCategories = qualifiedServicesTaxonomy.reduce((acc, item) => {
    return {...acc, [item.serviceName]: item.serviceCategoryName};
  }, {});

  const serviceMap = {};
  const services = servicesTaxonomy?.reduce((acc, item) => {
    const nestedServices = item.children.map(item => {
      const service = {id: item.id, name: item.name};
      if (item.name in qualifiedServicesCategories)
        service['qualifiedServiceCategory'] = qualifiedServicesCategories[item.name];
      serviceMap[item.name] = service;
      return service;
    });
    acc.push(...nestedServices);
    return acc;
  }, []);

  return { services, serviceMap };
};

export const isTelehealthAllowed = answers => (
  !!Array.isArray(answers?.servicesNeeded) &&
  !!answers.servicesNeeded.length &&
  answers.servicesNeeded[0]?.qualifiedServiceCategory === SERVICE_CATEGORY_ALLIED_HEALTH
);

export const isOnlineServiceAllowed = answers => (
  !!Array.isArray(answers?.servicesNeeded) &&
  !!answers.servicesNeeded.length &&
  [SERVICE_CATEGORY_PLAN_MANAGEMENT,
    SERVICE_CATEGORY_SUPPORT_COORDINATION].includes(answers.servicesNeeded[0]?.qualifiedServiceCategory)
);

export const getAccessMethodFilter = answers => (
  (isTelehealthAllowed(answers) || isOnlineServiceAllowed(answers)) ?
    (isTelehealthAllowed(answers) ? getTelehealthAccessMethodFilter(answers) : getOnlineServiceAccessMethodFilter(answers))
    : null
);

const getTelehealthAccessMethodFilter = answers => {
  if (!isTelehealthAllowed(answers) || answers?.[TELEHEALTH_FIELD] === TELEHEALTH_YES) return null;
  return '!' + ACCESS_METHOD_TELEHEALTH;
};

const getOnlineServiceAccessMethodFilter = answers => {
  if (!isOnlineServiceAllowed(answers) || answers?.[ONLINE_SERVICE_FIELD] === ONLINE_SERVICE_YES) return null;
  return '!' + ACCESS_METHOD_ONLINE_SERVICE;
};
