import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Popover, Rate, Steps as AntdSteps, Tag as AntdTag } from 'antd';

import style from 'style';
import Lottie from 'lottie-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentLines, faSync, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { useMediaQuery } from 'react-responsive';

import QualifiedServiceIcon from 'components/QualifiedServiceIcon';
import DateTimeFrom from 'components/DateTimeFrom';
import Dot from 'components/ServiceRequestStatus/Dot';
import ImagePanel from 'components/provider/ImagePanel';
import animationAsset from 'assets/hourglass.json';


const DOT_SIZE = '15px';


const Container = styled.div`
  border: 1px solid ${style.colors.lightBorder};
  border-radius: 22px;
  padding: 20px 25px 35px 25px;
  box-shadow: ${style.shadow(15)};
`;

const Header = styled.div`
  font-size: 18px;
`;

const Service = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  
  .icon-container {
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 6px;
  }

  .icon {
    width: 20px;
    color: ${style.colors.palette.black};
    font-size: 22px;
  }
  
  .service-name {
    font-weight: 700;
    color: black;
    font-size: 18px;
  }
  
  .date-time {
    font-size: 13px;
    color: #929292;
    font-weight: 500;
  }
`;

const Content = styled.div`
  margin-top: 30px;
  width: 100%;
`;

const Steps = styled(AntdSteps)`
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
  .ant-steps-item-tail {
    top: 3px;
    width: 95%;
    margin-left: 81px;
    
    ::after {
      height: 7px;
      border-radius: 8px;
      @media (max-width: 575px) {
        width: 7px !important;
        margin-left: 13px !important;
        margin-top: 4px !important;
      }
    }
    
    @media (max-width: 700px) {
      margin-left: 80px;
      width: 90%;
    }
  }
  .ant-steps-item-title {
    margin-top: 7px;
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
  }
  .ant-steps-item-description {
    font-size: 12px;
  }
`;


const ProviderCardContainer = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
  justify-content: space-around;
  
  @media (max-width: ${style.sizes.small}) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const ServiceCard = ({ data, location }) => {

  const count = data.data.providerCount;
  const maxCount = count < 3 ? count : 3;
  const isMatching = ['new', 'open'].includes(data?.status);

  const isMobile = useMediaQuery({query: '(max-width: 575px)'});

  const customDot = (dot, { index, status }) => {
    const stepData = {
      dot: null,
      text: null,
    };

    if (status === 'wait') {
      stepData.dot = <Dot color="#e2e2e2" style={{height: DOT_SIZE, width: DOT_SIZE}} noPulse />;
    } else if (status === 'process') {
      stepData.dot = <Dot color="#21bfaf" style={{height: DOT_SIZE, width: DOT_SIZE}} noPulse={!isMatching} />;
    } else {
      stepData.dot = <Dot color="#21bfaf" style={{height: DOT_SIZE, width: DOT_SIZE}} noPulse />;
    }

    if (index === 0) {
      stepData.text = <>You have successfully found <b>{count}</b> potential providers
        after performing a comprehensive search.</>;
    } else if (index === 1) {
      stepData.text = <>Our system is currently assessing the <b>{count}</b> providers
        you have found to determine if they can fulfill your requirements and have
        the necessary availability. <br /><br />We will begin presenting providers, with a maximum
        of <b>{maxCount}</b>, as we proceed through the matching process.</>;
    } else {
      stepData.text = <>The matching process is finished. You should have received
        up to <b>{maxCount}</b> providers from your search list
        interested in offering the service. If you haven&apos;t, then
        the <b>{count}</b> providers you found cannot provide the service
        at this time.</>;
    }

    return (
      <Popover
        overlayStyle={{maxWidth: '400px'}}
        overlayInnerStyle={{fontSize: '13px', padding: '0', fontWeight: 400}}
        content={stepData.text}
        placement={isMobile ? 'right' : 'top'}
      >
        <div style={{cursor: 'pointer'}}>{stepData.dot}</div>
      </Popover>
    );
  };


  const {Step} = Steps;
  const name = data.serviceName;
  return (
    <Container>
      <Header>
        <Service>
          <div className="icon-container">
            <QualifiedServiceIcon.Regular serviceName={name} className="icon"/>
          </div>
          <div>
            <div><span className="service-name">{name}</span> in {location.split('-')[1].replace(',', '')}</div>
            <div><DateTimeFrom value={data.createdAt} tooltipPlacement="bottom" className="date-time" /></div>
          </div>
        </Service>
      </Header>
      <Content>
        <Steps current={isMatching ? 1 : 2} progressDot={customDot} responsive={true}>
          <Step title="Provider Searching" description="Completed" />
          <Step title="Provider Matching" description={isMatching ? 'In-progress' : 'Completed'} />
          <Step title="Complete" />
        </Steps>
        <ProviderCardContainer>
          {data.providers.map((item, idx) => (<ProviderCard key={idx} data={item} />))}
          {Array.from(Array(maxCount - data.providers.length)).map((item, idx) => (<ProviderCard key={idx} empty notFound={!isMatching} />))}
        </ProviderCardContainer>
      </Content>
    </Container>
  );
};

ServiceCard.propTypes = {
  data: PropTypes.object,
  location: PropTypes.string,
};

const ProviderContainer = styled.div`
  width: 200px;
  border: 1px solid ${style.colors.lightBorder};
  border-radius: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 2px;
  padding-bottom: 10px;
  box-shadow: ${style.shadow(10)};
  height: 235px;
  cursor: ${({$empty}) => $empty ? 'not-allowed' : 'pointer'};
  background: ${({$empty}) => $empty && '#f3f3f3'};

  :hover {
    border-color: ${({$empty}) => !$empty && '#9cede6'};
  }

  .image {

  }

  .name {
    margin-top: 5px;
    font-weight: 700;
    padding: 0 10px;
  }

  .rating-review {

  }

  .review {
    font-size: 12px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    font-weight: 500;
    justify-content: center;
    margin-top: 3px;
    color: #787878;
  }

  .review-icon {
    color: ${style.colors.palette.dustyOrange};
    font-size: 16px;
    margin-bottom: 1px;
  }

  .empty-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 2px;
    font-size: 13px;
    color: #acacac;
    padding: 10px 10px 0 10px;
  }

`;

const Tag = styled(AntdTag)`
  margin: 0;
  font-size: 11px;
  margin-top: 10px;
`;

const ProviderCard = ({ data, empty, notFound }) => {

  const renderEmpty = () => (
    <div className="empty-container">
      {notFound ? (
        <>
          Provider could not be assigned
        </>
      ) : (
        <>
          <Lottie
            animationData={animationAsset}
            loop
            style={{
              marginBottom: '-5px',
              marginTop: '-20px',
              height: '80px',
            }}
          />
          <div>
          Provider not assigned yet
          </div>
          <div>
          Check back later
          </div>
        </>
      )}
    </div>
  );

  const isAccepted = data?.status === 'accepted';

  const render = () => (
    <>
      <div className="image">
        <ImagePanel data={data} useMobileLayout={false}/>
      </div>
      <div className="name">
        {data.name}
      </div>
      <div className="rating-review">
        <Rate disabled allowHalf value={data.rating ? data.rating : 0} style={{fontSize: '15px'}}/>
        <div className="review">
          <FontAwesomeIcon className="review-icon" icon={faCommentLines}/>
          {data.reviewCount} Reviews
        </div>
      </div>
      <Tag
        icon={(
          <FontAwesomeIcon
            icon={isAccepted ? faSync : faCircleCheck}
            spin={!!isAccepted}
            style={{marginRight: '5px'}}
          />
        )}
        color={isAccepted ? 'blue' : 'green'}
      >
        {isAccepted ? 'Contact you soon' : 'Already contacted'}
      </Tag>
    </>
  );

  return (
    <ProviderContainer
      $empty={empty}
      onClick={() => !empty && window.open(`/provider/${data?.slug}`, '_blank')}
    >
      {empty ? renderEmpty() : render()}
    </ProviderContainer>
  );
};

ProviderCard.propTypes = {
  data: PropTypes.object,
  empty: PropTypes.bool,
  notFound: PropTypes.bool,
};


export default ServiceCard;

