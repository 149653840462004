import AaaSearch from './modules/iframeWidget/AaaSearch';
import ClxSearch from './modules/iframeWidget/ClxSearch';
import Connect from './modules/connect';
import HicapsWidgetShowcase from './modules/widgetShowcase/HicapsWidgetShowcase';
import HomeReferral from './modules/home/HomeReferral';
import HomeVariantTwo from './modules/home/HomeVariantTwo';
import MpmSearch from './modules/iframeWidget/MpmSearch';
import NdspSearch from './modules/iframeWidget/NdspSearch';
import Directory from './modules/iframeWidget/Directory';
import ProductActivation from './modules/productActivation/ProductActivation';
import React from 'react';
import { Route } from 'react-router-dom';
import Signup from './modules/signup';
import { routes as authRoutes } from './modules/auth/routes';
import { routes as connectDashboardRoutes } from './modules/connectDashboard/routes';

export const publicRoutes = () => {
  return [
    <Route key="/providers" exact path="/providers" component={HomeVariantTwo} />,
    <Route key="/providers/ref" exact path="/providers/ref/:provider" component={HomeReferral} />,
    <Route key="/connect" exact path="/connect" component={Connect} />,
    <Route key="/connect/activation" exact path="/connect/activation" component={ProductActivation} />,
    <Route key="/business/signup" exact path="/business/signup" component={Signup} />,
    <Route key="/integration/widget/aaa" exact path="/integration/widget/aaa" component={AaaSearch} />,
    <Route key="/integration/widget/ndsp" exact path="/integration/widget/ndsp" component={NdspSearch} />,
    <Route key="/integration/widget/mpm" exact path="/integration/widget/mpm" component={MpmSearch} />,
    <Route key="/integration/widget/clx" exact path="/integration/widget/clx" component={ClxSearch} />,
    <Route key="/integration/directory" exact path="/integration/directory" component={Directory} />,
    <Route
      key="/integration/widget/showcase/hicaps"
      exact
      path="/integration/widget/showcase/hicaps"
      component={HicapsWidgetShowcase}
    />,
  ];
};

export const routes = () => {
  return [
    ...connectDashboardRoutes(),
    ...publicRoutes(),
    ...authRoutes(),
  ];
};
