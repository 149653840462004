import React, { useState } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { faEarthOceania } from '@fortawesome/pro-light-svg-icons';

import Card from './Card';
import LocationMap from 'components/location/LocationMap';
import style from 'style';


const Container = styled.div`
  .tile-container {
    margin-top: 20px;
  }
`;

const Locations = ({ data, isLoading }) => {
  const [selectedLocation, setSelectedLocation] = useState(0);

  return (
    <Card
      title="Premises"
      icon={faEarthOceania}
      isLoading={isLoading}
      noData={_.isEmpty(data)}
      collapsable={data?.length > 3}
      collapsableHeight="640px"
    >
      <Container>
        <LocationMap premises={data} selectedPremise={selectedLocation} isLoading={!data}/>
        <div className="tile-container">
          {data?.map((item, index) => (
            <LocationTile
              key={index}
              data={item}
              onClick={() => setSelectedLocation(index)}
            />
          ))}
        </div>
      </Container>
    </Card>
  );
};

Locations.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};


const LocationContainer = styled.div`
  display: flex;
  border-radius: ${style.borderRadius.large};
  align-items: center;
  column-gap: 15px;
  cursor: pointer;
  padding: 10px;
  :hover {
    background: ${style.colors.palette.paleGrey};
  }
  .icon {
    font-size: 20px;
  }
`;

const LocationTile = ({ data, onClick }) => {

  return (
    <LocationContainer onClick={onClick}>
      <div>
        <FontAwesomeIcon icon={faLocationDot} className="icon" />
      </div>
      <div>
        <div style={{fontWeight: 500}}>
          {data.name}
        </div>
        <div>
          {data.formattedAddress}
        </div>
      </div>
    </LocationContainer>
  );
};

LocationTile.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export default Locations;
