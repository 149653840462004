import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../../../components/SectionTitle.js';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { ProviderAPI } from '../../../api';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiAutocomplete-root': {
      width: '100%',
    },
  },
  input: {
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: theme.palette.common.paleGrey,
      paddingTop: 0,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 0,
    },
  },
}));

// lastQuery global is used to ensure we only update the options in response to the
// most recent request made.
let lastQuery = undefined;

const LocationFilter = ({value: initialValue, onEnter, title=null}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(initialValue || '');
  const [options, setOptions] = React.useState([]);
  const [focus, setFocus] = React.useState(false);
  const open = focus && (options.length > 1 || (options.length === 1 && value !== inputValue));

  React.useEffect(() => {
    if (initialValue === null) {
      setValue('');
      setInputValue('');
    }
  }, [initialValue]);

  React.useEffect(() => {
    lastQuery = inputValue;
    if (inputValue.length < 2) return;
    ProviderAPI.locationAutocomplete(inputValue).then(r => {
      // avoid updating options if inputValue has since changed
      if (inputValue === lastQuery) setOptions(r.payload);
    });
  }, [inputValue]);

  const renderInput = (params) => {
    return (
      <TextField
        className={classes.input}
        {...params}
        placeholder="Postcode or Suburb"
        variant="filled"
      />
    );
  };

  return (
    <div className={classes.root}>
      {title !== null ? (<SectionTitle title={title} />) : null}
      <Autocomplete
        options={options}
        open={open}
        autoComplete
        autoHighlight
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, value) => {
          setValue(value);
          onEnter(value);
        }}
        popupIcon={null}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        value={value}
        renderInput={renderInput}
      />

    </div>
  );
};

LocationFilter.propTypes = {
  value: PropTypes.string,
  onEnter: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default LocationFilter;
