import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
}));

const Title = ({ text, className='' }) => {
  const classes = useStyles();
  return <Typography variant="h1" className={classNames(classes.root, className)}>{text}</Typography>;
};

Title.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default Title;