import { request, makePath } from './http';
const API_NAME = 'file';

export function listFiles(accountType, accountUuid, query, limit, offset) {
  return request('GET', makePath(API_NAME, accountType, accountUuid), {params: {search: query, limit: limit, offset: offset}});
}

export function getDownloadUrl(accountType, accountUuid, fileUuid) {
  return request('GET', makePath(API_NAME, 'downloadurl', accountType, accountUuid, fileUuid));
}

export function getUploadUrl(accountType, accountUuid, fileName, contentType) {
  return request('GET', makePath(API_NAME, 'uploadurl', accountType, accountUuid), {params: {file_name: fileName, content_type: contentType}});
}

export function deleteFile(accountType, accountUuid, fileUuid) {
  return request('DELETE', makePath(API_NAME, accountType, accountUuid, fileUuid));
}

export function updateFile(accountType, accountUuid, fileUuid, {fileName=null, category=null}) {
  return request('PATCH', makePath(API_NAME, accountType, accountUuid, fileUuid), {body: {file_name: fileName, category: category}});
}

export function getInvoiceDownloadUrl(providerUuid, fileName) {
  return request('GET', makePath(API_NAME, 'invoice-downloadurl', providerUuid, fileName));
}
