import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Rate, Tag, Dropdown, Menu, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentLines } from '@fortawesome/pro-regular-svg-icons';
import { faShare } from '@fortawesome/pro-solid-svg-icons';
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

import accreditedImg from 'assets/accredited-provider-badge2.svg';
import ndis from 'assets/ndis-icon-round.svg';
import style from 'style';
import Card from './Card';
import defaultLogo from 'assets/physiotherapist-and-boy-holding-ball-455x256.png';
import { ORG_TYPE_ICON_MAP } from './constants';
import {
  SUBSCRIPTION_TIER_ACCREDITATION_PLUS,
  SUBSCRIPTION_TIER_ENTERPRISE,
} from 'lib/constants';


const pageUrl = window.location.href;
const menuTextStyle = {fontSize: '12px', fontWeight: '500'};
const menuIconStyle = {fontSize: '18px'};
const shareMenu = (
  <Menu
    items={[
      {
        key: '1',
        label: (
          <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`}>
            <span style={menuTextStyle}>Facebook</span>
          </a>
        ),
        icon: <FontAwesomeIcon style={menuIconStyle} color="#3b5998" icon={faFacebook} />,
      },
      {
        key: '2',
        label: (
          <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/share?url=${pageUrl}`}>
            <span style={menuTextStyle}>Twitter</span>
          </a>
        ),
        icon: <FontAwesomeIcon style={menuIconStyle} color="#00acee" icon={faTwitter} />,
      },
      {
        key: '3',
        label: (
          <a target="_blank" rel="noopener noreferrer" href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}>
            <span style={menuTextStyle}>LinkedIn</span>
          </a>
        ),
        icon: <FontAwesomeIcon style={menuIconStyle} color="#0072b1" icon={faLinkedin} />,
      },
    ]}
  />
);

const Container = styled.div`
  display: flex;
  column-gap: 30px;
  @media (max-width: ${style.sizes.large}) {
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
  }
`;

const LogoContainer = styled.div`
  min-width: 267px;
  .logo {
    max-height: 16rem;
    max-width: 100%;
    object-fit: contain;
  }
`;

const DetailsContainer = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${style.sizes.large}) {
    align-items: center;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  
  @media (max-width: ${style.sizes.large}) {
    align-items: center;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 26px;
    font-weight: 700;
  }
  .abn-logo {
    background: #474747; 
    color: white; 
    padding: 0 5px 0 3px; 
    font-size: 10px; 
    font-weight: 500; 
    border-radius: 4px; 
    margin-right: 5px; 
    font-style: italic;
  }
`;

const Bottom = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  .rating {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  .rating-text {
    font-weight: 700;
  }
  .reviews {
    font-size: 13px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    :hover {
      border-bottom: 1px solid #474747;
    }
  }
  .review-icon {
    margin-bottom: 3px;
    color: ${style.colors.palette.dustyOrange};
    font-size: 14px;
  }
`;

const ShareButton = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;

  :hover {
    border-bottom: 1px solid #474747;
  }

  .icon {
    font-size: 14px;
    margin-bottom: 3px;
    color: ${style.colors.info};
  }
`;

const VerificationIconContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  align-items: flex-end;
  justify-content: space-around;
  
  @media (max-width: ${style.sizes.large}) {
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    width: 100%;
  }
  
  .acc-image {
    margin-top: auto;
    filter: ${({isGrayAccImage}) => isGrayAccImage && 'grayscale(100%) brightness(95%)'};
    @media (max-width: ${style.sizes.large}) {
      margin-top: 0;
    }
  }
`;

const ABNContainer = styled.div`
  cursor: pointer;
  :hover {
    border-bottom: 1px solid black;
  }
`;

const NDISContainer = styled.div`
  display: flex;
  column-gap: 5px;
  justify-content: center;
  align-items: center;
  cursor: default;
  user-select: none;
  
  .text {
    margin-top: 5px;
    text-align: right;
    color: #6b2975;
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
  }
`;

const Header = ({ data, isLoading }) => {

  const logoWidth = 267;
  const logoHeight = 150;

  const logoSize = `@${logoWidth}x${logoHeight}`;
  const logo = data.imagePath ? data.imagePath + logoSize : defaultLogo;

  const isFullAccessTier = [
    SUBSCRIPTION_TIER_ACCREDITATION_PLUS,
    SUBSCRIPTION_TIER_ENTERPRISE,
  ].includes(data.subscription);

  const onABNClick = () => {
    window.open(
      `https://abr.business.gov.au/ABN/View?id=${data.abn}`,
      '_blank', 'noopener noreferrer');
  };

  return (
    <Card isLoading={isLoading}>
      <Container>
        <LogoContainer>
          <img
            width={logoWidth}
            height={logoHeight}
            src={logo}
            alt={`Provider ${data.name} logo`}
            className="logo"
            aria-label="Provider logo"
          />
        </LogoContainer>
        <DetailsContainer>
          <Top>
            <div className="title">
              {data.name}
            </div>
            <div style={{fontSize: '12px', display: 'flex', columnGap: '20px'}}>
              {!!data.organisationType && (
                <div>
                  <FontAwesomeIcon
                    icon={ORG_TYPE_ICON_MAP[data.organisationType]}
                    style={{marginRight: '5px', fontSize: '13px'}}
                  />
                  {data.organisationType}
                </div>
              )}
              {!!data.abn && (
                <ABNContainer onClick={onABNClick}>
                  <span className="abn-logo">
                    ABN
                  </span>
                  <span style={{fontSize: '11px'}}>{data.abn}</span>
                </ABNContainer>
              )}
            </div>
          </Top>
          <Bottom>
            <div className="rating">
              <div className="rating-text">
                <Tag style={{margin: 0}} color={data.rating >= 4 ? 'green' : 'gold'}>
                  {data.rating ? data.rating : 0}
                </Tag>
              </div>
              <Rate style={{fontSize: '16px'}} disabled allowHalf value={data.rating} />
            </div>
            <a style={{color: 'unset'}} href="#reviews">
              <div className="reviews">
                <FontAwesomeIcon className="review-icon" icon={faCommentLines} />
                {data.reviewCount} Reviews
              </div>
            </a>
            <Dropdown overlay={shareMenu}>
              <ShareButton>
                <FontAwesomeIcon className="icon" icon={faShare} />
                Share
              </ShareButton>
            </Dropdown>
          </Bottom>
        </DetailsContainer>
        <VerificationIconContainer isGrayAccImage={!isFullAccessTier}>
          {(!!data.ndisRegistered && isFullAccessTier) && (
            <Tooltip
              title="Registered NDIS provider"
              overlayInnerStyle={{
                fontSize: '10px',
                fontWeight: 500,
                padding: '5px 8px 2px 8px',
                margin: 0,
                minHeight: 0,
              }}
            >
              <NDISContainer>
                <div className="text">
                  Registered<br/>NDIS Provider
                </div>
                <img
                  src={ndis}
                  aria-label="Registered NDIS provider"
                  alt="Registered NDIS provider"
                  style={{width: '40px'}}
                />
              </NDISContainer>
            </Tooltip>
          )}
          {!!data.accredited && (
            <Tooltip
              title="Provider is accredited by Clickability"
              overlayInnerStyle={{
                fontSize: '10px',
                fontWeight: 500,
                padding: '5px 8px 2px 8px',
                margin: 0,
                minHeight: 0,
              }}
            >
              <img
                src={accreditedImg}
                aria-label="Provider is accredited by Clickability"
                alt="Provider is accredited by Clickability"
                style={{width: '110px'}}
                className="acc-image"
              />
            </Tooltip>
          )}
        </VerificationIconContainer>
      </Container>
    </Card>
  );
};

Header.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};


export default Header;

