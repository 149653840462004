import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

const MetaData = ({
  title=undefined,
  description=undefined,
  url=undefined,
  image=undefined,
  article=false,
}) => {
  return (
    <Helmet>
      {description && <meta name="description" content={description} />}
      <meta name="twitter:card" content="summary" />
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}

      {article && <meta property="og:type" content="article" />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      <meta property="og:url" content={url || window.location} />
      <link rel="canonical" href={url || window.location} />
    </Helmet>
  );
};

MetaData.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

export default MetaData;
