import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingTop: '1rem',
  },
  text: {
    width: '100%',
  },
}));

const ValidationErrorText = ({ active=false, errorText }) => {
  const classes = useStyles();
  if (!active || errorText === undefined || errorText === '') return null;
  return (
    <div className={classes.root}>
      <Typography variant="body2" color="error" className={classes.text}>
        {errorText}
      </Typography>
    </div>
  );
};

ValidationErrorText.propTypes = {
  active: PropTypes.bool,
  errorText: PropTypes.string,
};

export default ValidationErrorText;
