import React, { useRef } from 'react';

import Page from 'providerSite/components/Page';
import Hero from 'providerSite/components/LandingPage/Hero';
import SectionOne from 'providerSite/components/LandingPage/SectionOne';
import SectionThree from 'providerSite/components/LandingPage/SectionThree';
import Pricing from './components/Pricing';
import Benefits from './components/Benefits';
import style from 'style';
import heroImage from 'assets/connect-hero.png';
import SectionTwo from './components/SectionTwo';
import Questions from './components/Questions';
import Bottom from './components/Bottom';


const Connect = () => {

  const sectionTwoRef = useRef(null);

  return (
    <Page fullWidth>
      <Hero
        text={(
          <>
            <span className="underline">Simplify</span> your workflow with
            Connect&rsquo;s powerful dashboard
          </>
        )}
        img={heroImage}
        useIconBtn={true}
      />
      <SectionOne
        title="Clickability Connect is the go-to tool for Support Coordinators and LACs."
        text="We will put your service requests in front of thousands of providers
        so your participants get the supports they need.  Right now."
        onClick={() => {
        // Not using scrollIntoView() with scroll-margin (fixed header) due to
        // having overflow: hidden in the main container. As of now scroll-margin
        // has a bug that prevents it working with overflow: hidden containers.
          const top = sectionTwoRef.current?.offsetTop - parseInt(style.constants.navBarHeight);
          window.scroll({top: top});
        }}
      />
      <SectionTwo refProp={sectionTwoRef} />
      <SectionThree
        title={(
          <>
            Within minutes I heard from providers that had capacity to provide
            the service I was looking for, in the area I
            needed. <span className="underline">So quick and easy.</span>
          </>
        )}
      />
      <Benefits />
      <Pricing />
      <Questions />
      <Bottom />
    </Page>
  );
};

export default Connect;
