import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles( theme =>({
  root: {
    background: theme.palette.common.white,
    width: '100%',
    boxShadow: 'rgb(145 158 171 / 15%) 0px 0px 4px 0px, rgb(145 158 171 / 15%) 2px -2px 20px 0px',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: '#e2e2e2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '20px',
    color: theme.palette.primary.main,
    padding: '5px 20px',
  },
}));

const RowCard = ({children, className, onClick, tooltipText}) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltipText ? tooltipText : ''} placement="bottom">
      <div className={classNames(classes.root, className)} role="row" onClick={onClick}>
        {children}
      </div>
    </Tooltip>
  );
};

RowCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  tooltipText: PropTypes.string,
};

export default RowCard;