import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import NotificationBar from 'components/NotificationBar';
import { AuthAPI } from 'api';
import style from 'style';


const DEFAULT_ERROR_MESSAGE = 'An error occurred sending activation email.  Please try again later.';

const Message = styled(Typography)`
  color: ${style.colors.palette.black};
  font-size: 16px;
  
  span.link {
     text-decoration: underline;  
     cursor: pointer;
     
     &:hover {
      color: rgb(102, 60, 0);
     } 
  }
`;

const ErrorMessage = styled(Typography)`
  color: ${style.colors.error};
  font-size: 16px;
`;

const EmailResentNotification = ({ profile }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const sendEmail = async () => {
    try {
      setErrorMessage(null);
      setBusy(true);
      await AuthAPI.sendProviderActivateEmail(profile?.username);
      setBusy(false);
      setEmailSent(true);
    } catch (err) {
      setBusy(false);
      setEmailSent(false);
      console.error(err);  // eslint-disable-line no-console
      const message = (err.body && err.body.message) ? err.body.message : DEFAULT_ERROR_MESSAGE;
      setErrorMessage(message);
    }
  };

  const renderMessage = () => {
    if ( errorMessage ) {
      return (
        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>
      );
    }

    return (
      <Message variant="h2">
        You are yet to verify your email address.&nbsp;
        {busy ?
          'Email Sending...'
          : emailSent ?
            'Email Sent!' : (
              <span onClick={sendEmail} className="link">
                Didn&#39;t get the email? Resend it now.
              </span>
            )
        }
      </Message>
    );
  };

  return (
    <NotificationBar>
      {renderMessage()}
    </NotificationBar>
  );
};

EmailResentNotification.propTypes = {
  profile: PropTypes.object,
};

export default EmailResentNotification;