import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Map, TileLayer, Popup as _Popup, Marker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faEarthOceania,
} from '@fortawesome/pro-solid-svg-icons';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/pro-duotone-svg-icons';
import L from 'leaflet';
import styled from 'styled-components';

import style from 'style';
import ImagePanel from './provider/ImagePanel';
import {
  AutoComplete as AntdAutoComplete,
  Rate,
  Tag,
  Tooltip as TooltipAntd,
  Button as AntdButton,
} from 'antd';

import accreditedLogo from 'assets/accredited-provider-badge2.svg';
import ndis from 'assets/ndis-2.png';
import logoIcon from 'assets/logo-icon.svg';
import {
  SUBSCRIPTION_TIER_ACCREDITATION_PLUS,
  SUBSCRIPTION_TIER_ENTERPRISE,
} from '../lib/constants';
import { ProviderAPI } from '../api';
import UseMyLocation from './location/UseMyLocation';
import Spinner from './Spinner';
import { useGetApi, ProviderAPI as NewProviderAPI } from 'providerSite/api';
import * as track from 'lib/track';


const MAP_MIN_SIZE = '300px';
const MAP_MAX_SIZE = '600px';
const TEXTBOX_HEIGHT = '38px';
const FONT_SIZE = '13px';


const icon = L.icon({
  iconUrl: logoIcon,
  iconAnchor:  [35, 35],
  iconSize: [35, 35],
  className: 'leaflet-icon'
});

const Popup = styled(_Popup)`
  .leaflet-popup-content-wrapper {
    padding: 0;
    overflow: hidden;
  }
  .leaflet-popup-content {
    margin: 0;
    overflow: hidden;
    width: auto !important;
  }
  .leaflet-popup-close-button {
    display: none;
  }
`;

const Container = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid ${style.colors.lightBorder};
  box-shadow: ${style.shadow(20)};
  height: ${({isMaximised}) => isMaximised ? MAP_MAX_SIZE : MAP_MIN_SIZE};
  transition: height 0.3s;


  .leaflet-bar {
    border: none;
    opacity: 0.85;
  }

  .leaflet-bar a:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .leaflet-bar a:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .leaflet-touch .leaflet-bar a {
    width: 24px;
    height: 28px;
    line-height: 28px;
    color: #5a5a5a;
  }

  .leaflet-control-zoom-in, .leaflet-control-zoom-out {
    font-size: 16px;
  }

  .leaflet-icon {
    filter: drop-shadow(5px 5px 3px #5d5d5d);
  }

  .leaflet-icon-gray {
    filter: drop-shadow(5px 5px 3px #5d5d5d) grayscale(100%) brightness(95%)
  }

  .leaflet-tooltip.marker-label {
    background-color: ${style.colors.primary};
    padding: 3px 7px 5px 7px;
    line-height: 10px;
    border: transparent;
    box-shadow: none;
    font-size: 10px;
    font-weight: 700;
    color: white;
    filter: drop-shadow(2px 2px 2px #000000);
    border-radius: 10px;
  }

  .leaflet-tooltip-right:before {
    display: none;
  }

  .maximise-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 999;
    font-size: 18px;
    cursor: pointer;
    color: ${style.colors.secondary};
    filter: drop-shadow(1px 1px 1px #0e0e0e);

    :hover {
      color: ${style.colors.primary};
    }
  }
`;

const Backdrop = styled.div`
  background: ${style.colors.primary};
  opacity: 0.5;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const LoadingBackdrop = styled.div`
  background: #000000;
  opacity: 0.2;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  filter: grayscale(1);
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  .text {
    color: white;
    font-size: 13px;
    font-weight: 500;
    filter: drop-shadow(1px 1px 1px #0e0e0e);
    margin-top: -5px;
  }
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
`;

const OverlayContainer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 10px;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
`;

const AutoComplete = styled(AntdAutoComplete)`
  min-width: 210px;
  height: ${TEXTBOX_HEIGHT};
  font-size: ${FONT_SIZE};


  .ant-select-selector {
    height: ${TEXTBOX_HEIGHT} !important;
    border-color: ${style.colors.secondary} !important;
    border-radius: 12px !important;
    background: rgba(255, 255, 255, 0.85) !important;
  }

  .ant-select-selection-search-input {
    height: ${TEXTBOX_HEIGHT} !important;
    font-weight: 500 !important;
  }

  .ant-select-selection-placeholder {
    line-height: ${TEXTBOX_HEIGHT} !important;
    font-weight: 500 !important;
    color: #8d8d8d;
  }

  @media (max-width: ${style.sizes.large}) {
    min-width: 150px;
  }
`;

const Button = styled(AntdButton)`
  border-radius: 10px;
  font-size: 13px;
  line-height: 13px;
  opacity: 0.85;
  :disabled {
    color: ${style.colors.palette.white};
    background-color: ${style.colors.secondary};
    border-color: ${style.colors.secondary};
    :hover {
      background-color: ${style.colors.secondary};
      color: ${style.colors.palette.white};
      border-color: ${style.colors.secondary};
    }
  }
`;

const LocationInputContainer = styled.div`
  position: relative;

  .use-my-location {
    position: absolute;
    right: 4px;
    margin-top: 3px;
    color: ${style.colors.palette.white};
    filter: drop-shadow(1px 1px 1px #252525);
    flex-direction: row-reverse;
    column-gap: 3px;

    div {
      font-size: 11px;
      font-weight: 700;
    }
  }

  .use-my-location-button {
    :hover {
      color: ${style.colors.secondary};
    }
  }

  .use-my-location-spinner {
    color: ${style.colors.palette.white};
  }

  .use-my-location-icon {
    font-size: 16px;
  }

`;

const DirectoryMap = ({ providers, setLocation, param, onClear, isLoading }) => {

  const [viewport, setViewport] = useState(
    {center: [-25.213595517339673, 133.74578906958286], zoom: 3});
  const [isMaximised, setIsMaximised] = useState(false);
  const mapRef = useRef();
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationInputValue, setLocationInputValue] = useState('');
  const [locationValue, setLocationValue] = useState(null);

  const isMapOpen = !!param;

  const { data: _latLongData } = useGetApi(NewProviderAPI.GET_LAT_LONG_FROM_ADDRESS, {
    queryParams: {
      input: param,
    },
    enabled: !!isMapOpen,
    onError: err => console.error(err),    // eslint-disable-line no-console
  });

  const latLongData = _latLongData ? _latLongData : null;

  useEffect(() => {

    if (!latLongData) return;
    setViewport({center: [latLongData.lat, latLongData.lng], zoom: 13});

  }, [_latLongData]);

  useEffect(() => {

    if (!providers.length || latLongData) return;
    setViewport({center: providers?.[0].positions?.[0]?.coordinates, zoom: 13});

  }, [providers]);

  useEffect(() => {
    mapRef.current.leafletElement.invalidateSize(true);
  });

  useEffect(() => {
    if (locationInputValue.length < 2) return;
    ProviderAPI.locationAutocomplete(locationInputValue).then(r => {
      setLocationOptions(r.payload?.map(item => ({label: item, value: item})));
    });
  }, [locationInputValue]);

  useEffect(() => {
    setLocationValue(param ? param : '');
  }, [param]);

  const onLocationClick = location => {
    setLocationValue(location);
  };

  const renderController = () => {
    if (!isMapOpen && isLoading) return null;
    return (
      <>
        <LocationInputContainer>
          <AutoComplete
            allowClear
            filterOption={true}
            value={locationValue}
            options={locationOptions}
            size="large"
            onChange={val => setLocationValue(val)}
            onSearch={val => setLocationInputValue(val)}
            placeholder="Suburb or postcode"
            onClick={() => setIsMaximised(true)}
          />
          <UseMyLocation
            className="use-my-location"
            classNames={{
              button: 'use-my-location-button',
              spinner: 'use-my-location-spinner',
              icon: 'use-my-location-icon',
            }}
            onSuccess={onLocationClick}
          />
        </LocationInputContainer>
        <Button
          disabled={!locationValue}
          type="primary"
          onClick={() => {
            if (!locationValue) {
              onClear();
            } else {
              setLocation(locationValue ? locationValue : null);
            }
            track.sendEvent('directory map', 'click', { value: locationValue });
          }}
        >
          Browse Map
          <FontAwesomeIcon
            icon={faEarthOceania}
            style={{marginLeft: '5px', fontSize: '15px', verticalAlign: '-3px'}}
          />
        </Button>
      </>
    );
  };

  return (
    <Container isMaximised={isMaximised}>
      <Map
        ref={mapRef}
        viewport={viewport}
        style={{
          height: isMaximised ? MAP_MAX_SIZE : MAP_MIN_SIZE,
          width: '100%',
          transition: !isMaximised && 'height 0.3s',
          filter: isLoading && 'grayscale(1)',
        }}
        attributionControl={false}
        className="leaflet"
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {providers?.map(provider => (
          provider.positions?.map((positionData, idx) => (
            <Marker key={idx} position={positionData.coordinates} icon={icon}>
              <Tooltip
                direction="right"
                offset={[-3, -10]}
                opacity={1}
                permanent
                className="marker-label"
              >
                {provider.name}
              </Tooltip>
              <Popup offset={[-18, -30]}>
                <ProviderPopup data={provider} isPrivate={positionData.isPrivate} />
              </Popup>
            </Marker>
          ))
        ))}
      </Map>
      <FontAwesomeIcon
        icon={isMaximised ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter}
        className="maximise-icon"
        onClick={() => {
          setIsMaximised(prevState => !prevState);
        }}
      />
      {!isMapOpen ? (
        <>
          <Backdrop/>
          <ModalContainer>
            {renderController()}
          </ModalContainer>
        </>
      ) : (
        <OverlayContainer>
          {renderController()}
        </OverlayContainer>
      )}
      {isLoading && (
        <>
          <LoadingBackdrop />
          <SpinnerContainer>
            <Spinner size="70px" />
            <div className="text">Loading map ...</div>
          </SpinnerContainer>
        </>
      )}
    </Container>
  );
};

DirectoryMap.propTypes = {
  providers: PropTypes.array,
  setLocation: PropTypes.func.isRequired,
  param: PropTypes.object,
  onClear: PropTypes.func,
  isLoading: PropTypes.bool,
};


const ProviderPopupContainer = styled.div`
  padding-bottom: 2px;
  .header {
    width: 180px;
  }
  .title {
    font-size: 14px;
    font-weight: 700;
    color: ${style.colors.secondary};
    margin-bottom: 9px;
    user-select: none;
  }
  .content {
    padding: 10px;
  }
  .rating {
    display: flex;
    align-items: center;
    column-gap: 7px;
  }
  .rating-text {
    font-weight: 700;
  }
  .reviews {
    font-size: 12px;
    margin-top: 2px;
    color: ${style.colors.textPrimary};
  }
  .acc-image {
    margin: 2px;
    width: 70px;
    object-fit: scale-down;
    filter: ${({isGrayAccImage}) => isGrayAccImage && 'grayscale(100%) brightness(95%)'};
  }
  .badge-container {
    margin-top: 8px;
    display: flex;
    justify-content: space-around;
  }
  .location-status {
    font-size: 11px;
    margin-top: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    font-weight: 500;
    color: ${style.colors.textPrimary};
    cursor: default;
  }
`;

const ProviderPopup = ({ data, isPrivate }) => {
  const isFullAccessTier = [
    SUBSCRIPTION_TIER_ACCREDITATION_PLUS,
    SUBSCRIPTION_TIER_ENTERPRISE,
  ].includes(data?.subscription);

  const renderAccreditedLogo = () => {
    if (!data.accredited) return null;
    return (
      <TooltipAntd
        title="Provider is accredited by Clickability"
        overlayInnerStyle={{
          fontSize: '10px',
          fontWeight: 500,
          padding: '5px 8px 2px 8px',
          margin: 0,
          minHeight: 0,
        }}
      >
        <img
          src={accreditedLogo}
          alt="Clikability accredited provider icon"
          className="acc-image"
        />
      </TooltipAntd>
    );
  };

  const renderNDISLogo = () => {
    if (!data.ndisRegistered || !isFullAccessTier) return null;
    return (
      <TooltipAntd
        title="Provider is NDIS registered"
        overlayInnerStyle={{
          fontSize: '10px',
          fontWeight: 500,
          padding: '5px 8px 2px 8px',
          margin: 0,
          minHeight: 0,
        }}
      >
        <img
          src={ndis}
          aria-label="Provider is NDIS registered"
          style={{width: '35px', marginTop: '5px'}}
        />
      </TooltipAntd>
    );
  };

  return (
    <ProviderPopupContainer isGrayAccImage={!isFullAccessTier}>
      <div className="header">
        <ImagePanel data={data} useMobileLayout={false}/>
      </div>
      <div className="content">
        <a
          href={`/provider/${data.slug}`}
          style={{width: '100%'}}
        >
          <div className="title">
            {data.name}
          </div>
        </a>
        <div className="rating">
          <div className="rating-text">
            <Tag style={{margin: 0, fontSize: '11px', padding: '3px 4px', lineHeight: '11px'}}
              color={data.rating >= 4 ? 'green' : 'gold'}>
              {data.rating ? data.rating : 0}
            </Tag>
          </div>
          <Rate style={{fontSize: '12px'}} disabled allowHalf value={data.rating}/>
          <span className="reviews">({data.reviewCount})</span>
        </div>
        <div className="badge-container">
          {renderAccreditedLogo()}
          {renderNDISLogo()}
        </div>
        <TooltipAntd
          title={!isPrivate ?
            'Displaying exact location of the provider.' :
            'Displaying an approximate location positioned at the central point within ' +
            'this suburb. The provider has not disclosed the exact location for ' +
            'their service.'
          }
          overlayInnerStyle={{
            fontSize: '11px',
            fontWeight: 500,
            padding: '2px 8px 2px 8px',
            margin: 0,
            minHeight: 0,
          }}
        >
          <div className="location-status">
            <FontAwesomeIcon
              icon={isPrivate ? faCircleExclamation : faCircleCheck}
              style={{fontSize: '14px', color: isPrivate ? style.colors.warning : style.colors.success}}
            />
            {isPrivate ? 'Approximate' : 'Exact'} Location
          </div>
        </TooltipAntd>
      </div>
    </ProviderPopupContainer>
  );
};

ProviderPopup.propTypes = {
  data: PropTypes.object,
  isPrivate: PropTypes.bool,
};

export default DirectoryMap;

