/*
  NOTE: events will NOT fire until you set them up the GTM console.
  - create dataLayer variables for any arguments you pass in args
    - set the variable type to dataLayer
    - set the version to 2
  - create a new tag of type "Custom Event"
    - set the event name to match the value you will pass as eventName to sendEvent
    - select "Fire on All Custom Events"
  - create a new trigger of type "Universal Analytics"
    - track type is "Event"
    - set Category/Action/Label/Value to whatever values you want to push to GA
    - non-interaction hit is false
    - ga settings should be set to the variable defining your GA account
*/

export function sendEvent(eventName, args={}) {
  window.dataLayer.push({'event': eventName, ...args});
}
