import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Braintree, HostedField } from 'react-braintree-fields';
import { Button, Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard } from '@fortawesome/free-brands-svg-icons';

import style from 'style';
import Spinner from 'components/Spinner';
import * as track from 'lib/track';
import config from 'config';
import visaLogo from 'assets/visa-logo.svg';
import masterCardLogo from 'assets/mastercard-logo.svg';


const Container = styled.div`
  padding-top: 20px;
  
  .card-form {
    position: relative;
    border: 1px solid ${style.colors.lightBorder};
    border-radius: 14px;
    padding: 20px 22px 22px 22px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    box-shadow: ${style.shadow(10)};
    overflow: hidden;
  }
  
  .card-logo-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    column-gap: 10px;
  }
  
  .title {
    font-weight: 500;
    margin-bottom: 5px;
    margin-left: 2px;
  }
  
  .card-number-container {
    position: relative;
  }
  
  .card-container {
    position: absolute;
    top: 6px;
    right: 10px;
  }
  
  .braintree-hosted-field {
    border: 1px solid ${style.colors.lightBorder};
    height: 35px;
    border-radius: 10px;
    padding: 0 10px;
  }
  
  .cc-icon {
    font-size: 22px;
  }
  
  .loading-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #e3e3e37a;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CardPaymentHosted = (
  {
    clientToken,
    customerId,
    onComplete,
    isLoading,
    footer,
    tokenError,
    onError,
    signupError,
    trackingEmail,
  }) => {
  const [tokenize, setTokenizeFunc] = useState();
  const [cardType, setCardType] = useState('');
  const [error, setError] = useState(null);
  const [focusedFieldName, setFocusedField] = useState('');
  const numberField = useRef();
  const cvvField = useRef();
  const cardholderNameField = useRef();
  const [isValid, setIsValid] = useState(false);

  const [isAuthorisationLoading, setIsAuthorisationLoading] = useState(true);
  const [isBTSubmitLoading, setIsBTSubmitLoading] = useState(false);

  const onAuthorizationSuccess = () => {
    console.log('authorisation success');    // eslint-disable-line no-console
    setIsAuthorisationLoading(false);
    track.sendEvent(
      'signup-billing-braintree-cc-ui',
      'auth-complete',
      {email: trackingEmail}
    );
  };

  // eslint-disable-next-line
  const onDataCollectorInstanceReady = (collector) => {
    track.sendEvent(
      'signup-billing-braintree-cc-ui',
      'auth-started',
      {email: trackingEmail}
    );
  };

  const handleError = newError => {
    const errMsg = newError.message || String(newError);
    setError(errMsg);
    onError && onError(errMsg);
  };

  // eslint-disable-next-line
  const onFieldBlur = (field, event) => setFocusedField('');
  const onFieldFocus = (field, event) => setFocusedField(event.emittedBy);

  const onCardTypeChange = ({ cards }) => {
    if (1 === cards.length) {
      const [card] = cards;

      setCardType(card.type);

      if (card.code && card.code.name) {
        cvvField.current.setPlaceholder(card.code.name);
      } else {
        cvvField.current.setPlaceholder('CVV');
      }
    } else {
      setCardType('');
      cvvField.current.setPlaceholder('CVV');
    }
  };

  const handleComplete = payload => {
    setIsBTSubmitLoading(false);
    onComplete({
      billing_payment_method_nonce: payload.nonce,
      billing_customer_id: customerId,
    });
  };

  const getToken = () => {
    setIsBTSubmitLoading(true);
    tokenize().then(handleComplete).catch(handleError);
    track.sendEvent(
      'signup-billing-join-button',
      'click',
      {email: trackingEmail}
    );
  };

  const renderResult = (title, obj) => {
    if (!obj) { return null; }
    return (
      <>
        <Alert
          type="error"
          style={{marginBottom: '8px'}}
          message={(
            <>
              <b>{title}:</b>
              <pre>{JSON.stringify(obj, null, 4)}</pre>
            </>
          )}
        />
        <div style={{marginLeft: '2px', marginBottom: '10px', fontWeight: 500}}>
          Please contact us at <a href={`mailto:${config.socials.infoEmail}`}>{config.socials.infoEmail}</a> regarding
          this issue. We are confident we can help you.
        </div>
      </>
    );
  };

  const renderLoading = () => {
    if (!isAuthorisationLoading) return null;
    return (
      <div className="loading-container">
        <Spinner />
      </div>
    );
  };

  const renderCard = cardType => {
    switch (cardType) {
    case 'visa':
      return <FontAwesomeIcon className="cc-icon" icon={faCcVisa} />;
    case 'master-card':
      return <FontAwesomeIcon className="cc-icon" icon={faCcMastercard} />;
    default:
      return null;
    }
  };

  const checkValidity = data => {
    if (!data || !data.fields) setIsValid(false);
    if (
      data.fields.cardholderName.isValid &&
      data.fields.number.isValid &&
      data.fields.expirationDate.isValid &&
      data.fields.cvv.isValid
    ) setIsValid(true);
  };

  return (
    <Container>
      <Braintree
        authorization={clientToken}
        onAuthorizationSuccess={onAuthorizationSuccess}
        onDataCollectorInstanceReady={onDataCollectorInstanceReady}
        onError={handleError}
        onCardTypeChange={onCardTypeChange}
        getTokenRef={ref => setTokenizeFunc(() => ref)}
        onValidityChange={checkValidity}
        styles={{
          input: {
            'font-size': '14px',
          },
        }}
      >
        {renderResult('Error', (tokenError || error || signupError))}
        <div className="card-form">
          <div>
            <div className="title">Cardholder Name</div>
            <HostedField
              type="cardholderName"
              className={'cardholderName' === focusedFieldName ? 'focused' : ''}
              onBlur={onFieldBlur}
              onFocus={onFieldFocus}
              placeholder="Name on Card"
              ref={cardholderNameField}
            />
          </div>
          <div>
            <div className="title">Card Number</div>
            <div className="card-number-container">
              <HostedField
                type="number"
                className={'number' === focusedFieldName ? 'focused' : ''}
                onBlur={onFieldBlur}
                onFocus={onFieldFocus}
                placeholder="• • • •   • • • •   • • • •   • • • •"
                ref={numberField}
              />
              <div className="card-container">
                {renderCard(cardType)}
              </div>
            </div>
          </div>
          <div style={{display: 'flex', columnGap: '10px'}}>
            <div>
              <div className="title">Expiry Date (MM/YY)</div>
              <HostedField
                type="expirationDate"
                onBlur={onFieldBlur}
                onFocus={onFieldFocus}
                className={'expirationDate' === focusedFieldName ? 'focused' : ''}
                placeholder="MM/YY"
              />
            </div>
            <div>
              <div className="title">CVV</div>
              <HostedField type="cvv" placeholder="• • •" ref={cvvField}/>
            </div>
          </div>
          {renderLoading()}
          <div className="card-logo-container">
            <img src={visaLogo} alt="visa-logo" width="70px" />
            <img src={masterCardLogo} alt="master-card-logo" width="80px" style={{marginLeft: '-43px'}} />
          </div>
        </div>
      </Braintree>
      <div className="footer" style={{marginTop: '20px'}}>
        {footer}
        <Button
          type="primary"
          style={{
            width: '100%',
            fontSize: '15px',
            fontWeight: '700',
            height: '40px'
          }}
          loading={isLoading || isBTSubmitLoading}
          onClick={getToken}
          disabled={!isValid}
        >
          Join Clickability!
        </Button>
        <div style={{marginLeft: '2px', marginTop: '15px', fontSize: '13px', color: '#959595'}}>
          Please contact us at <a href={`mailto:${config.socials.infoEmail}`}>{config.socials.infoEmail}</a> for
          any assistance. We can help you with any issues.
        </div>
      </div>
    </Container>
  );
};

CardPaymentHosted.propTypes = {
  clientToken: PropTypes.string,
  customerId: PropTypes.string,
  onComplete: PropTypes.func,
  isLoading: PropTypes.bool,
  footer: PropTypes.node,
  tokenError: PropTypes.string,
  onError: PropTypes.func,
  signupError: PropTypes.string,
  trackingEmail: PropTypes.string,
};


export default CardPaymentHosted;

