import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import camelcaseKeys from 'camelcase-keys';

import Navbar from 'providerSite/components/Navbar';
import {actions, messages, statuses}  from '../components/constants';
import { ServiceRequestAPI } from 'api';
import MatchPanel from '../components/MatchPanel';
import ModalPage from 'components/ModalPage';

/*
 * Determine status from API message and action.
 */ 
const getStatus = (message, action) => {
  switch (message) {
  case messages.CLAIMED:
    return action === actions.ACCEPT ? statuses.ACCEPTED : statuses.DECLINED;
  case messages.ALREADY_ACCEPTED:
    return statuses.ALREADY_ACCEPTED;
  case messages.ALREADY_DECLINED:
    return statuses.ALREADY_DECLINED;
  case messages.CLOSED:
    return statuses.CLOSED;
  case messages.EXPIRED:
    return statuses.EXPIRED;
  case messages.AVAILABLE:
    return statuses.AVAILABLE;
  default:
    if (message && message.match(messages.NOT_ACCREDITED)) {
      return statuses.NOT_ACCREDITED;
    }
    return statuses.INVALID_REQUEST;
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '90vh',
    marginTop: '20px',
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '2rem auto 2rem auto',
    maxWidth: '60rem',
    overflow: 'hidden',
  },
  panel: {
    flex: '0 1 100%',
    minHeight: '20rem',
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 80px)',
    width: '100%',
  },
}));


const ClaimMatchPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const { uuid: requestUuid, action } = useParams();
  const {provider: providerUuid, signature, sms} = queryString.parse(location.search);
  const [status, setStatus] = useState();
  const [match, setMatch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    // If any of them is null, do not call the API
    if ([requestUuid, providerUuid, signature, action].some(item => !item)) {
      return;
    }

    // If an invalid action is provided (other than 'accept' or 'decline')
    if (![actions.ACCEPT, actions.DECLINE].includes(action)) {
      setStatus(getStatus(null, action));
      setIsLoading(false);
      return;
    }

    ServiceRequestAPI.checkServiceRequestClaimStatus(providerUuid, requestUuid, signature, action)
      .then(response => {
        setMatch(camelcaseKeys(response.payload, {deep: true}));
        setStatus(getStatus(response.message, action));
      })
      .catch(err => {
        setStatus(getStatus(err.body?.message, action));
      })
      .finally(() => setIsLoading(false));

  }, [requestUuid, providerUuid, signature, action, sms]);

  const handleButtonClick = (_action, callback) => {
    setIsButtonLoading(true);
    claimServiceRequest(_action, callback);
  };

  const claimServiceRequest = (_action, callback=null) => {
    ServiceRequestAPI.claimServiceRequest(providerUuid, requestUuid, signature, _action)
      .then(response => {
        setMatch(camelcaseKeys(response.payload, {deep: true}));
        setStatus(getStatus(response.message, _action));
      })
      .catch(err => setStatus(getStatus(err.body?.message, _action)))
      .finally(() => {
        setIsButtonLoading(false);
        callback && callback();
      });
  };

  const renderClaimPanel = () => {
    return (
      <div className={classes.container}>
        <MatchPanel
          classes={{root: classes.panel}}
          match={match}
          status={status}
          isBusy={isButtonLoading}
          onClick={handleButtonClick}
          providerUuid={providerUuid}
        />
      </div>
    );
  };

  const renderer = () => {
    if (isLoading)
      return <div className={classes.loader}><CircularProgress size={40} thickness={5}/></div>;
    return renderClaimPanel();
  };

  return (
    <ModalPage footer={false} HeaderComponent={Navbar} title="Claim Your Match">
      <div className={classes.root}>
        {renderer()}
      </div>
    </ModalPage>
  );
};

export default ClaimMatchPage;
