import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '2rem',
  },
  item: {
    fontSize: '1.125rem',
    lineHeight: 1.56,
    marginBottom: '1.25rem',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.text.secondary,
    },
  },
}));


const BlogLinkList = ({title, content}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h2" color="primary">
        {title}
      </Typography>
      <ul className={classes.list}>
        {content.map((item, index) =>
          <li key={index}>
            <Link className={classes.link} to={item.path}>
              <Typography variant="subtitle1" className={classes.item}>
                {item.title}
              </Typography>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

BlogLinkList.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired
};

export default BlogLinkList;
