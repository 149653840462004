import React from 'react';
import AuthFormPage from './components/AuthFormPage';
import ActivateForm from './components/ActivateForm';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthAPI } from '../../api';

const ActivatePage = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const username = query.get('username');
  const code = query.get('code');
  return (
    <AuthFormPage
      title="Clickability Account Activation"
      displayTitle={true}
    >
      <ActivateForm
        username={username}
        code={code}
        onComplete={() => history.push('/login?username=' + username)}
        activateApi={AuthAPI.activate}
      />
    </AuthFormPage>
  );
};

export default ActivatePage;
