import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { AuthAPI } from 'api';
import Typography from '@material-ui/core/Typography';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import Page from 'components/Page';


const DEFAULT_ERROR_MESSAGE = 'An error occurred activating your account.  Please try again later.';

const MessageContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  min-height: 400px;
  row-gap: 25px;
`;

const ProviderActivatePage = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [busy, setBusy] = useState(false);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const query = new URLSearchParams(useLocation().search);
  let username = query.get('username');
  username = username && username.replaceAll(' ', '+');
  const code = query.get('code');

  useEffect(() => {
    const activateProvider = async () => {
      try {
        setErrorMessage(null);
        setBusy(true);
        await AuthAPI.providerActivate(username, code);
      } catch ( err ) {
        const message = (err.body && err.body.message) ? err.body.message : DEFAULT_ERROR_MESSAGE;
        setErrorMessage(message);
      } finally {
        setBusy(false);
      }
    };
    activateProvider();
  }, []);

  const onBtnClick = () => {
    let url = '/providers/login?username=' + encodeURIComponent(username);
    if (isAuthenticated) {
      url = '/provider/dashboard';
    }
    history.push(url);
  };

  const renderMessage = () => {
    if (busy) {
      return <Spinner />;
    }
    if (errorMessage) {
      return <Typography variant="h2">{errorMessage}</Typography>;
    }
    return (
      <>
        <Typography variant='h2'>Congratulations! Your account has been successfully activated.</Typography>
        <Button
          label={isAuthenticated ? 'View Dashboard' : 'Go to Login'}
          onClick={onBtnClick}
        />
      </>
    );
  };

  return (
    <Page title="Clickability Provider Account Activation">
      <MessageContainer>
        {renderMessage()}
      </MessageContainer>
    </Page>
  );
};

export default ProviderActivatePage;
