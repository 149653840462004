import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import connectDashboard from 'assets/connect-dashboard.png';
import SectionTwoComponent from 'providerSite/components/LandingPage/SectionTwo';
import _List from 'providerSite/components/LandingPage/List';
import style from 'style';


const List = styled(_List)`
  @media (max-width: ${style.screens.tab}) {
    justify-content: center;
  }
`;

const SectionTwo = ({refProp}) => {
  return (
    <SectionTwoComponent
      title="Streamlined. Fast. Controlled."
      text={(
        <>
          <List>Find rated and reviewed providers with capacity</List>
          <List>Up to 3 provider responses - usually within minutes</List>
          <List>
            Built for Support Coordinators and LACs, so they
            can track all requests in one powerful dashboard
          </List>
        </>
      )}
      img={connectDashboard}
      refProp={refProp}
    />
  );
};

SectionTwo.propTypes = {
  refProp: PropTypes.object
};


export default SectionTwo;