import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@material-ui/core';

import { AuthAPI } from 'api';
import { AuthStore } from 'store';
import * as Validate from 'components/Validate';
import Button from 'components/Button';
import PasswordDialog from './PasswordDialog';
import AlertList from './AlertList';
import ConfigPanel, { ConfigPanelRow } from './ConfigPanel';


const StyledTableCell = withStyles(() => ({
  root: {
    border: 0
  }
}))(TableCell);

const ManageButton = ({ role }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const handleManageClick = (event, role) => {
    if (role.has_connect_access && role.has_accreditation_access) {
      setAnchorEl(event.currentTarget);
      return;
    }
    let url;
    const providerUuid = role.provider_uuid;
    if (location.state?.from === 'connect')
      url = `/connect/${providerUuid}/dashboard`;
    else
      url = `/provider/${providerUuid}/admin/listings`;
    history.push(url);
  };

  const handleMenuItemClick = (role, product) => {
    let url;
    if (product === 'connect')
      url = `/connect/${role.provider_uuid}/dashboard`;
    else
      url = `/provider/${role.provider_uuid}/admin/listings`;
    history.push(url);
  };

  if (!role.has_connect_access || !role.has_accreditation_access) {
    return (
      <Button
        label="Manage"
        variant="outlined"
        color="primary"
        onClick={(event) => handleManageClick(event, role)}
      />
    );
  }
  return (
    <>
      <Button
        label="Manage"
        variant="outlined"
        color="primary"
        onClick={(event) => handleManageClick(event, role)}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: -50,
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => handleMenuItemClick(role, 'accreditation')}
        >
          Accreditation Dashboard
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick(role, 'connect')}
        >
          Connect Dashboard
        </MenuItem>
      </Menu>
    </>
  );
};

ManageButton.propTypes = {
  role: PropTypes.object
};

const ProviderProfileForm = ({loading, profile, dispatch, currentUuid, isAdmin=false, setProfile=null}) => {
  const [alerts, setAlerts] = useState([]);
  const [username, setUsername] = useState(profile.username);
  const [usernameWarning, setUsernameWarning] = useState();
  const [email, setEmail] = useState(profile.email);
  const [emailWarning, setEmailWarning] = useState();
  const [firstName, setFirstName] = useState(profile.first_name);
  const [firstNameWarning, setFirstNameWarning] = useState();
  const [lastName, setLastName] = useState(profile.last_name);
  const [lastNameWarning, setLastNameWarning] = useState();
  const [contactPhone, setContactPhone] = useState(profile.contact_phone);
  const [contactPhoneWarning, setContactPhoneWarning] = useState();
  const [password, setPassword] = useState('');
  const [passwordWarning, setPasswordWarning] = useState();
  const [busy, setBusy] = useState();
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const apiUpdate = isAdmin ? AuthAPI.adminUpdateProviderProfile : AuthAPI.updateProviderProfile;
  const disabled = busy || loading;

  // if the profile gets updated, update the form.
  useEffect(() => setEmail(profile.email), [profile.email]);
  useEffect(() => setUsername(profile.username), [profile.username]);

  const addAlerts = (newAlerts) => {
    setAlerts(alerts => [...newAlerts, ...alerts]);
  };

  const removeAlert = (alert) => {
    setAlerts(alerts => alerts.filter(a => a !== alert));
  };

  const onSave = async (currentPassword=undefined) => {
    const valid = [
      Validate.validateEmail(email, setEmailWarning),
      Validate.validateUsername(username, setUsernameWarning),
      Validate.validateFirstName(firstName, setFirstNameWarning),
      Validate.validateLastName(lastName, setLastNameWarning),
      Validate.validatePhoneNumber(contactPhone, setContactPhoneWarning),
      Validate.validatePassword(password, setPasswordWarning, true),
    ];
    if (!valid.every(v=>v)) {
      addAlerts([{severity: 'error', text: 'There are errors in one or more of your changes. Please check the fields and try again.'}]);
      return;
    }

    // if the password is changing, prompt for old password.
    if (password !== '' && currentPassword === undefined && !isAdmin) {
      setPasswordDialogOpen(true);
      return;
    }

    // compose the arguments to be sent
    const updates = {};  // uuid required for admin only
    if (email !== profile.email) {
      updates.email = email;
    }
    if (password !== '') {
      updates.new_password = password;
      updates.password = currentPassword;
    }
    if (username !== profile.username) {
      updates.username = username;
    }
    if (firstName !== profile.first_name) {
      updates.first_name = firstName;
    }
    if (lastName !== profile.last_name) {
      updates.last_name = lastName;
    }
    if (contactPhone !== profile.contact_phone) {
      updates.contact_phone = contactPhone;
    }
    setAlerts([]);

    // if there is nothing to update, say so and abort
    if (Object.keys(updates).length === 0) {
      addAlerts([{severity: 'info', text: 'No changes to save.'}]);
      return;
    }

    // send update to the API
    setBusy(true);
    updates.uuid = profile.user_uuid;  // required for admin updates, ignored otherwise
    try {
      const response = await apiUpdate(updates);
      addAlerts(response.payload.messages);
      if (profile.user_uuid === currentUuid) {
        // if isAdmin, may or may not be profile of current user.
        dispatch(AuthStore.profile(response.payload.profile));
      }
      if (setProfile) {
        setProfile(response.payload.profile);
      }
      setPassword('');
    } catch (err) {
      if (err.body) {
        const { message } = err.body;
        addAlerts([{severity: 'error', text: message}]);
      } else {
        addAlerts([{severity: 'error', text: 'An error occurred updating your profile.'}]);
      }
    } finally {
      setBusy(false);
    }
  };

  return (
    <Container maxWidth="md">
      <ConfigPanel defaultExpanded={true} title="Organisations">
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {profile && profile.roles.map(role =>
                <TableRow key={role.provider_uuid}>
                  <StyledTableCell>
                    <Typography variant="h3" color="textPrimary">
                      <Link to={`/provider/${role.provider_slug}`} style={{textDecoration: 'none'}}>
                        {role.provider_name}
                      </Link>
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <ManageButton role={role}/>
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ConfigPanel>
      <form>
        <ConfigPanel defaultExpanded={true} title="User Account Details">
          <ConfigPanelRow label="First Name">
            <FormControl fullWidth={true}>
              <TextField
                type="text"
                autoComplete="given-name"
                value={firstName}
                variant="outlined"
                placeholder="first name"
                fullWidth={true}
                margin="normal"
                error={firstNameWarning !== undefined}
                helperText={firstNameWarning}
                onChange={e => setFirstName(e.target.value)}
                onBlur={() => Validate.validateFirstName(firstName, setFirstNameWarning)}
              />
            </FormControl>
          </ConfigPanelRow>

          <ConfigPanelRow label="Last Name">
            <FormControl fullWidth={true}>
              <TextField
                type="text"
                autoComplete="family-name"
                value={lastName}
                variant="outlined"
                placeholder="last name"
                fullWidth={true}
                margin="normal"
                error={lastNameWarning !== undefined}
                helperText={lastNameWarning}
                onChange={e => setLastName(e.target.value)}
                onBlur={() => Validate.validateLastName(lastName, setLastNameWarning)}
              />
            </FormControl>
          </ConfigPanelRow>

          <ConfigPanelRow label="Contact Telephone">
            <FormControl fullWidth={true}>
              <TextField
                type="text"
                autoComplete="tel-national"
                value={contactPhone}
                variant="outlined"
                placeholder="contact phone"
                fullWidth={true}
                margin="normal"
                error={contactPhoneWarning !== undefined}
                helperText={contactPhoneWarning}
                onChange={e => setContactPhone(e.target.value)}
                onBlur={() => Validate.validatePhoneNumber(contactPhone, setContactPhoneWarning)}
              />
            </FormControl>
          </ConfigPanelRow>

          <ConfigPanelRow label="Email Address">
            <FormControl fullWidth={true}>
              <TextField
                type="email"
                autoComplete="email"
                value={email}
                variant="outlined"
                placeholder="email address"
                fullWidth={true}
                margin="normal"
                error={emailWarning !== undefined}
                helperText={emailWarning}
                onChange={e => setEmail(e.target.value)}
                onBlur={() => Validate.validateEmail(email, setEmailWarning)}
              />
            </FormControl>
          </ConfigPanelRow>

          <ConfigPanelRow label="Username">
            <FormControl fullWidth={true}>
              <TextField
                type="text"
                autoComplete="username"
                value={username}
                variant="outlined"
                fullWidth={true}
                placeholder="username"
                margin="normal"
                error={usernameWarning !== undefined}
                helperText={usernameWarning}
                onChange={e => setUsername(e.target.value)}
                onBlur={() => Validate.validateUsername(username, setUsernameWarning)}
              />
            </FormControl>
          </ConfigPanelRow>

          <ConfigPanelRow label="Password">
            <FormControl fullWidth={true}>
              <TextField
                type="password"
                autoComplete="new-password"
                value={password}
                variant="outlined"
                placeholder="new password"
                fullWidth={true}
                margin="normal"
                error={passwordWarning !== undefined}
                helperText={passwordWarning}
                onChange={e => setPassword(e.target.value)}
                onBlur={() => Validate.validatePassword(password, setPasswordWarning, true)}
              />
            </FormControl>
          </ConfigPanelRow>

          <Grid xs={12} item container justify="flex-end">
            <Button label="Save" color="primary" onClick={()=>onSave()} disabled={disabled} busy={busy}/>
          </Grid>
        </ConfigPanel>
      </form>
      <AlertList alerts={alerts} onClose={removeAlert}/>
      <PasswordDialog
        open={passwordDialogOpen}
        onCancel={()=>setPasswordDialogOpen(false)}
        onSubmit={(password)=>{setPasswordDialogOpen(false); onSave(password);}}
      />
    </Container>
  );
};

ProviderProfileForm.propTypes = {
  currentUuid: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  setProfile: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    currentUuid: state.auth.profile.user_uuid,
  };
};

export default connect(mapStateToProps)(ProviderProfileForm);
