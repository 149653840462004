import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BlogListItem, { BlogListItemSkeleton } from './BlogListItem.js';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '1.625rem',
    fontWeight: 600,
    marginBottom: '1.25rem',
  },
  // root: {
  //   padding: '1rem',
  // },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  listItem: {
  },
  divider: {
    margin: '1rem 0 1rem 0',
  },
}));

export const BlogListSkeleton = ({count}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h2" color="primary" className={classes.title}>
        <Skeleton width={200}/>
      </Typography>
      <ul className={classes.list}>
        {[...Array(count)].map((_, i) =>
          <li key={i} className={classes.listItem}>
            <BlogListItemSkeleton/>
            <Divider variant="middle" className={classes.divider}/>
          </li>
        )}
      </ul>
    </div>
  );
};
BlogListSkeleton.propTypes = {
  count: PropTypes.number.isRequired,
};


const BlogList = ({title, content}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h2" color="primary" className={classes.title}>
        {title}
      </Typography>
      <ul className={classes.list}>
        {content.map(item =>
          <li key={item.path} className={classes.listItem}>
            <BlogListItem item={item} />
            <Divider variant="middle" className={classes.divider}/>
          </li>
        )}
      </ul>
    </div>
  );
};

BlogList.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.array.isRequired,
};

export default BlogList;
