import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import placeholderImage from '../../assets/physiotherapist-and-boy-holding-ball-pxfq-9-hy.png';
import {  StarRating } from '../../components/widgets';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    marginBottom: '1rem',
    boxShadow: 'none',
    borderRadius: '3px',
    padding: '0.5rem',
    border: `1px solid ${theme.palette.common.silver}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  link: {
    textDecoration: 'none',
    flexGrow: 1,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    '&:last-child': {
      padding: 0,
      paddingLeft: '0.7rem',
    },
  },
  img: {
    width: '30%',
    objectFit: 'cover',
  },
  title: {
    fontWeight: 500,
    width: '16rem', // text truncation only works with explicit length set
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  text: {
    fontSize: '0.8rem',
    width: '16rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ServiceSuggestionCard = ({data}) => {
  const classes = useStyles();
  const imageSize = '@200x112';
  const img = data.image_path ? data.image_path + imageSize : placeholderImage;
  return (
    <Link className={classes.link} to={`/provider/${data.slug}`}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.img}
          component="img"
          src={img}
          role="presentation"
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography noWrap variant="subtitle2" color="primary" className={classes.title}>
              {data.name}
            </Typography>
            <StarRating rating={data.rating} style={{padding: 0}}/>
            <Typography variant="body2" className={classes.text}>
              {data.short_description}
            </Typography>
          </CardContent>
        </div>
      </Card>
    </Link>
  );
};

ServiceSuggestionCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServiceSuggestionCard;
