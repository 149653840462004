import React from 'react';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

import style from 'style';
import _Container from 'providerSite/components/Container';
import { Link, useHistory } from 'react-router-dom';
import config from 'config';
import logo from 'assets/logo-white.png';


const Main = styled.div`
  padding: 50px 0 40px 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(20deg, rgba(5,14,64,1) 0%, rgba(22,17,48,1) 55%, rgba(25,25,25,1) 100%);
  margin-top: auto;
`;

const Container = styled(_Container)`
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: ${style.colors.palette.white};
  text-align: left;
  @media (max-width: ${style.screens.mobile}) {
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 20px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (max-width: ${style.screens.mobile}) {
    align-items: center;
    text-align: center;
    row-gap: 40px;
  }
  .icon {
    cursor: pointer;
    &:hover {
      color: ${style.colors.secondary};
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  column-gap: 20px;
  width: 30%;
  justify-content: space-between;
  @media (max-width: ${style.screens.tab}) {
    width: 40%;
  }
  @media (max-width: ${style.screens.mobile}) {
    width: 100%;
  }
  ul {
    list-style-type: none;
  }
  li {
    margin: 10px 0 10px -38px;
  }
  a {
    color: ${style.colors.palette.white};
    &:hover {
      color: ${style.colors.secondary};
    }
  }
`;

const LogoContainer = styled.div`
  @media (max-width: ${style.screens.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  column-gap: 30px;
  font-size: 20px;
`;

const Text = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Footer = () => {

  const history = useHistory();
  const handleSocialClick = url => window.open(`https://www.${url}`);

  return (
    <Main>
      <Container size="large" flex>
        <LeftContainer>
          <div>
            <Text>
              Find us
            </Text>
            <SocialContainer>
              <FontAwesomeIcon
                className="icon"
                icon={faFacebookF}
                onClick={() => handleSocialClick('facebook.com/clickability.com.au')}
              />
              <FontAwesomeIcon
                className="icon"
                icon={faTwitter}
                onClick={() => handleSocialClick('twitter.com/_clickability')}
              />
              <FontAwesomeIcon
                className="icon"
                icon={faLinkedinIn}
                onClick={() => handleSocialClick('linkedin.com/company/clickability---rated-reviewed-reliable')}
              />
              <FontAwesomeIcon
                className="icon"
                icon={faInstagram}
                onClick={() => handleSocialClick('instagram.com/_clickability/')}
              />
            </SocialContainer>
          </div>
          <LogoContainer>
            <img
              src={logo}
              alt="Clickability"
              width="150px"
              style={{cursor: 'pointer'}}
              onClick={() => history.push('/provider')}
            />
            <div style={{fontSize: '12px', marginTop: '7px'}}>
              Clickability © 2019-2024. All Rights Reserved.
            </div>
          </LogoContainer>
        </LeftContainer>
        <RightContainer>
          <div>
            <Text>
              Content
            </Text>
            <ul >
              <li>
                <Link target="_blank" rel="noopener noreferrer" to="/blog">Blog</Link>
              </li>
              <li>
                <Link
                  target="_blank" rel="noopener noreferrer" to="/content-guidelines">Content guidelines</Link>
              </li>
            </ul>
          </div>
          <div>
            <Text>
              Resources
            </Text>
            <ul >
              <li>
                <Link target="_blank" rel="noopener noreferrer" to="/about">About us</Link>
              </li>
              <li>
                <a href={config.link.faq}>Help &amp; Support</a>
              </li>
              <li>
                <Link target="_blank" rel="noopener noreferrer" to="/contact">Contact us</Link>
              </li>
              <li>
                <Link target="_blank" rel="noopener noreferrer" to="/terms">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link target="_blank" rel="noopener noreferrer" to="/privacy">Privacy statement</Link>
              </li>
            </ul>
          </div>
        </RightContainer>
      </Container>
    </Main>
  );
};


export default Footer;
