import * as Roles from '../../../lib/roles';

import AddIcon from '@material-ui/icons/Add';
import BillingUserIcon from '@material-ui/icons/Payment';
import Chip from '@material-ui/core/Chip';
import CustomerServiceIcon from '@material-ui/icons/Chat';
import DoneIcon from '@material-ui/icons/Done';
import IntakeIcon from '@material-ui/icons/Store';
import ListingManagerIcon from '@material-ui/icons/LocalOffer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNodes } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import ProviderAdminIcon from '@material-ui/icons/Security';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const iconMap = {
  [Roles.adminRole]: <ProviderAdminIcon fontSize="small"/>,
  [Roles.editorRole]: <ListingManagerIcon fontSize="small"/>,
  [Roles.reviewsRole]: <CustomerServiceIcon fontSize="small"/>,
  [Roles.billingRole]: <BillingUserIcon fontSize="small"/>,
  [Roles.intakeRole]: <IntakeIcon fontSize="small"/>,
  [Roles.connectRole]: <FontAwesomeIcon icon={faCircleNodes} />,
};

const StyledChip = withStyles({
  root: {
    marginRight: 4,
    marginBottom: 4,
  },
})(Chip);

const RoleList = ({roleNames, allRoles, editable=false, onChange=undefined}) => {

  const cleanRoles = (roleNames) => {
    // canonicalise the roles : if admin is present, we only need admin.
    if (roleNames.includes(Roles.adminRole)) {
      roleNames = [Roles.adminRole];
    }
    return roleNames;
  };

  const toggleRoles = (roleNames, roleName) => {
    const hasAdmin = roleNames.includes(Roles.adminRole);
    const isAdmin = roleName === Roles.adminRole;
    const checked = roleNames.includes(roleName);
    if (hasAdmin && !isAdmin) {
      roleNames = [roleName];
    } else if (checked) {
      roleNames = roleNames.filter(r => r !== roleName);
    } else {
      roleNames = [...roleNames, roleName];
    }
    return cleanRoles(roleNames);
  };

  if (!editable) {
    return (
      <div>
        {roleNames.map(roleName =>
          <StyledChip
            key={roleName}
            icon={iconMap[roleName]}
            label={roleName}
            color="primary"
            variant={'outlined'}
            deleteIcon={<DoneIcon />}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      {allRoles.map(role => {
        const selected = roleNames.includes(role.name);
        const toggle = ()=>onChange(toggleRoles(roleNames, role.name, !roleNames.includes(role.name)));
        return (
          <StyledChip
            key={role.name}
            icon={iconMap[role.name]}
            clickable={true}
            label={role.name}
            variant={'outlined'}
            color={selected ? 'primary': 'secondary'}
            deleteIcon={selected ? <DoneIcon /> : <AddIcon />}
            onClick={toggle}
            onDelete={toggle}
          />
        );
      })}
    </div>
  );
};

RoleList.propTypes = {
  roleNames: PropTypes.array.isRequired,
  allRoles: PropTypes.array,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RoleList;
