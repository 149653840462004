import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { TaxonomyStore } from 'store';
import SubTitle from './components/SubTitle';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginBottom: '40px',
  },
  infoBar: {
    marginTop: 'auto',
    marginBottom: '30px',
  },
  subTitle: {
    marginTop: '20px',
  },
  children: {
    marginTop: '10px',
  },
});

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-size: 38px;
  color: black;
  font-weight: 200;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
`;

const QuestionFlow = ({ children, title, subtitle }) => {
  const classes = useStyles();

  const qualifiedServices = useSelector(state => state.taxonomy.qualifiedServices);
  const ageGroups = useSelector(state => state.taxonomy.ageGroups);
  const services = useSelector(state => state.taxonomy.services);

  const dispatch = useDispatch();

  useEffect(() => {
    !qualifiedServices.length && dispatch(
      TaxonomyStore.refreshTaxonomy(TaxonomyStore.QUALIFIED_SERVICE_TAXONOMY)
    );
    !ageGroups.length && dispatch(
      TaxonomyStore.refreshTaxonomy(TaxonomyStore.AGE_GROUP_TAXONOMY)
    );
    !services.length && dispatch(
      TaxonomyStore.refreshTaxonomy(TaxonomyStore.SERVICE_TAXONOMY));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <Title>
        {title}
      </Title>
      {subtitle && <SubTitle text={subtitle} className={classes.subTitle}/>}
      <div className={classes.children}>{children}</div>
    </div>
  );
};

QuestionFlow.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

export default QuestionFlow;