import React from 'react';

import * as EmailValidator from 'email-validator';
import {
  EMAIL_FIELD,
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PARTICIPANT_EMAIL_FIELD,
  PARTICIPANT_FIRST_NAME_FIELD,
  PARTICIPANT_GENDER_FIELD,
  PARTICIPANT_LAST_NAME_FIELD,
  PARTICIPANT_PHONE_FIELD,
  PARTICIPANT_AGE_RANGE_FIELD,
  PHONE_FIELD,
  PLAN_TYPE_AGENCY_MANAGED,
  PLAN_TYPE_NOT_WITH_NDIS,
  PLAN_TYPE_PLAN_MANAGED,
  PLAN_TYPE_SELF_MANAGED,
  PLAN_TYPE_UNSURE,
  PLAN_TYPE_WAITING_FOR_APPROVAL,
  SERVICE_FOR_TYPE_MYSELF,
  RELATIONSHIP_FIELD,
  CONTACT_METHOD_FIELD,
  LANGUAGE_FIELD,
  INTERPRETER_REQUIRED_FIELD,
  COMMUNICATION_AIDS_FIELD,
  NDIS_PLAN_FIELD,
} from '../../../constants';
import {getYourOrParts} from '../../utils';
import TextboxQuestion from '../questionComponents/TextboxQuestion';
import DropdownQuestion from '../questionComponents/DropdownQuestion';
import YesNoQuestion from '../questionComponents/YesNoQuestion';
import {
  genericTextboxValidator,
  freeTextValidator,
  phoneNumberValidator,
} from '../../utils';


export const CONTACT_INFO_QUESTIONS = [
  {
    field: RELATIONSHIP_FIELD,
    Component: DropdownQuestion,
    text: 'Relationship to participant',
    options: ['Parent', 'Child', 'Partner', 'Relative', 'Support Coordinator', 'Other'],
    isValid: answer => !!answer,
    isHidden: answers => answers?.servicesFor === SERVICE_FOR_TYPE_MYSELF
  },
  {
    field: FIRST_NAME_FIELD,
    Component: TextboxQuestion,
    text: 'First name',
    isValid: genericTextboxValidator,
    shouldPreFill: answers => answers?.servicesFor === SERVICE_FOR_TYPE_MYSELF,
    preFillToField: PARTICIPANT_FIRST_NAME_FIELD,
  },
  {
    field: LAST_NAME_FIELD,
    Component: TextboxQuestion,
    text: 'Last name',
    isValid: genericTextboxValidator,
    shouldPreFill: answers => answers?.servicesFor === SERVICE_FOR_TYPE_MYSELF,
    preFillToField: PARTICIPANT_LAST_NAME_FIELD,
  },
  {
    field: PHONE_FIELD,
    Component: TextboxQuestion,
    text: 'Phone number',
    errorMessage: 'This doesn\'t look like a valid telephone number.',
    isValid: phoneNumberValidator,
    shouldPreFill: answers => answers?.servicesFor === SERVICE_FOR_TYPE_MYSELF,
    preFillToField: PARTICIPANT_PHONE_FIELD,
  },
  {
    field: EMAIL_FIELD,
    Component: TextboxQuestion,
    text: 'Email address',
    errorMessage: 'This doesn\'t look like a valid email.',
    isValid: answer => EmailValidator.validate(answer),
    shouldPreFill: answers => answers?.servicesFor === SERVICE_FOR_TYPE_MYSELF,
    isLowerCase: true,
    preFillToField: PARTICIPANT_EMAIL_FIELD,
  },
  {
    field: CONTACT_METHOD_FIELD,
    Component: DropdownQuestion,
    text: 'Preferred contact method',
    options: ['Phone call', 'SMS', 'Email', 'Any'],
    isValid: answer => !!answer,
  },
];

export const PARTICIPANT_DETAILS_QUESTIONS = [
  {
    field: PARTICIPANT_FIRST_NAME_FIELD,
    Component: TextboxQuestion,
    text: 'Participant\'s first name',
    isValid: genericTextboxValidator,
  },
  {
    field: PARTICIPANT_LAST_NAME_FIELD,
    Component: TextboxQuestion,
    text: 'Participant\'s last name',
    isValid: genericTextboxValidator,
  },
  {
    field: PARTICIPANT_AGE_RANGE_FIELD,
    Component: DropdownQuestion,
    text: 'Age range',
    optionsFromAnswers: answers => answers?._ageGroups,
    isValid: answer => !!answer,
  },
  {
    field: PARTICIPANT_GENDER_FIELD,
    Component: DropdownQuestion,
    text: 'The participant identifies as',
    options: ['Female (she/her)', 'Male (he/him)', 'Non-binary (they/them)',
      'Other', 'Prefer not to say'],
    isValid: answer => !!answer,
  },
  {
    field: NDIS_PLAN_FIELD,
    Component: DropdownQuestion,
    text: 'Who manages the funds in the NDIS plan?',
    options: [PLAN_TYPE_SELF_MANAGED, PLAN_TYPE_AGENCY_MANAGED, PLAN_TYPE_PLAN_MANAGED,
      PLAN_TYPE_WAITING_FOR_APPROVAL, PLAN_TYPE_NOT_WITH_NDIS, PLAN_TYPE_UNSURE],
    isValid: answer => !!answer,
  },
];

export const COMMUNICATION_QUESTIONS = [
  {
    field: LANGUAGE_FIELD,
    Component: TextboxQuestion,
    text: 'What language is spoken at home?',
    isValid: freeTextValidator,
  },
  {
    field: INTERPRETER_REQUIRED_FIELD,
    Component: YesNoQuestion,
    text: 'Is an interpreter required?',
    isValid: answer => !!answer,
  },
  {
    field: COMMUNICATION_AIDS_FIELD,
    Component: TextboxQuestion,
    text: 'Please let us know if there are communication aids used.',
    isOptional: () => true,
    placeholder: 'E.g. Communication board, hearing aids',
    isValid: () => true,
  },
];

export const NDIS_INFO_QUESTIONS = [
  {
    field: NDIS_PLAN_FIELD,
    Component: DropdownQuestion,
    text: 'How is your NDIS plan managed?',
    options: [PLAN_TYPE_SELF_MANAGED, PLAN_TYPE_AGENCY_MANAGED, PLAN_TYPE_PLAN_MANAGED,
      PLAN_TYPE_WAITING_FOR_APPROVAL, PLAN_TYPE_NOT_WITH_NDIS, PLAN_TYPE_UNSURE],
    isValid: answer => !!answer,
  },
];

export const DISABILITY_INFO_QUESTIONS = [
  {
    field: 'formalDiagnosis',
    Component: TextboxQuestion,
    // eslint-disable-next-line react/display-name
    text: answers => <>Please specify {getYourOrParts(answers)} formal diagnosis.</>,
    placeholder: 'E.g. ABI, Cerebral Palsy, Bipolar',
    isValid: freeTextValidator,
  },
  {
    field: 'impactOfTheDisability',
    Component: TextboxQuestion,
    // eslint-disable-next-line react/display-name
    text: answers => <>Is there anything you want the provider to know about the
      impact of {getYourOrParts(answers)} disability?</>,
    placeholder: 'E.g. often very fatigued, need appointment reminders, assistance with mobility',
    isOptional: () => true,
    isValid: () => true,
  },
  {
    field: 'behaviourSupportPlan',
    Component: YesNoQuestion,
    text: 'Is there a behaviour support plan in place? (if yes, we will connect ' +
      'you with NDIS Registered Providers)',
    isValid: answer => !!answer,
  },
  {
    field: 'behaviourSupportsNeeded',
    Component: TextboxQuestion,
    text: 'Please specify what additional supports the provider/s will need to ' +
      'offer to follow the plan.',
    isValid: freeTextValidator,
    isHidden: answers => answers?.behaviourSupportPlan !== 'Yes',
  },
];
