import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';
import * as qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as EmailValidator from 'email-validator';

import { AuthStore, TaxonomyStore } from 'store';
import FormFlow from 'components/formflow/FormFlow';
import {
  SERVICES_NEEDED_FIELD,
  LOCATION_FIELD,
  SERVICES_FOR_FIELD,
  NDIS_PLAN_FIELD,
  TELEHEALTH_FIELD,
  ONLINE_SERVICE_FIELD,
  EMAIL_FIELD,
  SERVICE_FOR_TYPE_MYSELF,
  SERVICE_FOR_TYPE_MY_CLIENT,
  SERVICE_FOR_TYPE_SOMEONE_I_CARE_FOR,
  AGE_RANGE_EARLY_CHILDHOOD,
  AGE_RANGE_CHILDREN,
  AGE_RANGE_YOUNG_PEOPLE,
  AGE_RANGE_ADULTS,
  AGE_RANGE_MATURE_AGE,
  PARTICIPANT_AGE_RANGE_FIELD,
  PLAN_TYPE_PLAN_MANAGED,
  PLAN_TYPE_AGENCY_MANAGED,
  PLAN_TYPE_SELF_MANAGED,
  PLAN_TYPE_WAITING_FOR_APPROVAL,
  PLAN_TYPE_NOT_WITH_NDIS,
  PLAN_TYPE_UNSURE,
  TELEHEALTH_YES,
  TELEHEALTH_NO,
  TELEHEALTH_UNSURE,
  ONLINE_SERVICE_YES,
  ONLINE_SERVICE_NO,
  ONLINE_SERVICE_UNSURE,
  FOR_FIELD,
  PARTICIPANT_FIRST_NAME_FIELD,
  FIRST_NAME_FIELD,
  FINAL_DETAILS_FIELD,
} from '../constants';
import Spinner from 'components/Spinner';
import * as track from 'lib/track';


const BrokerageFormFlow = ({flow, ...props}) => {

  const _taxonomyAgeGroups = useSelector(state => state.taxonomy.ageGroups);

  const dispatch = useDispatch();
  const [completedPages, setCompletedPages] = useState(new Set());
  const [services, setServices] = useState([]);
  const [serviceLocation, setServiceLocation] = useState();
  const [taxonomyAgeGroups, setTaxonomyAgeGroups] = useState();

  useEffect(() => {
    dispatch(TaxonomyStore.refreshTaxonomy(TaxonomyStore.QUALIFIED_SERVICE_TAXONOMY));
    dispatch(TaxonomyStore.refreshTaxonomy(TaxonomyStore.AGE_GROUP_TAXONOMY));
    dispatch(AuthStore.refreshProfile());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const taxonomyServices = useSelector(state => state.taxonomy.qualifiedServices);
  const location = useLocation();
  const _paramWithHash = location.hash;

  let serviceNameFromParam = null;
  let serviceLocationFromParam = null;
  let serviceForFromParam = null;
  let ageRangeFromParam = null;
  let ndisPlanFromParam = null;
  let srSessionFromParam = null;
  let telehealthFromParam = null;
  let onlineServiceFromParam = null;
  let emailFromParam = null;
  let firstNameFromParam = null;
  let participantFirstNameFromParam = null;

  // Service name is a must to pre-fill the data
  if (_paramWithHash.includes('?name')) {
    const params = qs.parse(_paramWithHash.split('?')[1]);

    serviceNameFromParam = params.name;
    serviceLocationFromParam = params.location;
    srSessionFromParam = params.sr_session;

    if (srSessionFromParam) {
      track.sendEvent(
        'service request flow',
        'resume',
        { se_session: srSessionFromParam }
      );
    }

    if ([
      SERVICE_FOR_TYPE_MY_CLIENT,
      SERVICE_FOR_TYPE_SOMEONE_I_CARE_FOR,
      SERVICE_FOR_TYPE_MYSELF
    ].includes(params.service_for)) {
      serviceForFromParam = params.service_for;
    }

    if ([
      AGE_RANGE_EARLY_CHILDHOOD,
      AGE_RANGE_CHILDREN,
      AGE_RANGE_YOUNG_PEOPLE,
      AGE_RANGE_ADULTS,
      AGE_RANGE_MATURE_AGE
    ].includes(params.age_range)) {
      ageRangeFromParam = params.age_range;
    }

    if ([
      PLAN_TYPE_PLAN_MANAGED,
      PLAN_TYPE_AGENCY_MANAGED,
      PLAN_TYPE_SELF_MANAGED,
      PLAN_TYPE_WAITING_FOR_APPROVAL,
      PLAN_TYPE_NOT_WITH_NDIS,
      PLAN_TYPE_UNSURE,
    ].includes(params.ndis_plan)) {
      ndisPlanFromParam = params.ndis_plan;
    }

    if ([TELEHEALTH_YES, TELEHEALTH_NO, TELEHEALTH_UNSURE].includes(params.telehealth)) {
      telehealthFromParam = params.telehealth;
    }

    if ([ONLINE_SERVICE_YES, ONLINE_SERVICE_NO, ONLINE_SERVICE_UNSURE].includes(params.online_service)) {
      onlineServiceFromParam = params.online_service;
    }

    if (params.email && EmailValidator.validate(params.email)) {
      emailFromParam = params.email;
    }

    if (params.first_name) {
      firstNameFromParam = params.first_name;
    }

    if (params.p_first_name) {
      participantFirstNameFromParam = params.p_first_name;
    }
  }

  useEffect(() => {
    if (!_taxonomyAgeGroups || !_taxonomyAgeGroups.length) return;

    setTaxonomyAgeGroups(_taxonomyAgeGroups?.map(item => item.name));
  }, [_taxonomyAgeGroups]);

  useEffect(() => {
    if (!serviceNameFromParam || services.length ||
      !location.hash.includes('#service?name=') || !taxonomyServices ||
      !taxonomyServices.length) return;

    let service;
    for (const ts of taxonomyServices) {
      if (ts.service_name.toLowerCase() === serviceNameFromParam.toLowerCase())
        service = ts;
    }
    if (service) {
      setServices([
        {
          id: service.service_id,
          name: service.service_name,
          qualifiedServiceCategory: service.service_category_name
        }
      ]);
    }
    if (serviceLocationFromParam)
      setServiceLocation(serviceLocationFromParam);
  }, [serviceNameFromParam, serviceLocationFromParam, taxonomyServices]);

  const _serviceForPreFilledData = {
    [FOR_FIELD]: serviceForFromParam,
    [EMAIL_FIELD]: emailFromParam,
    [FIRST_NAME_FIELD]: firstNameFromParam,
    [PARTICIPANT_FIRST_NAME_FIELD]: participantFirstNameFromParam,
  };

  const _finalDetailsPreFilledData = {
    [PARTICIPANT_AGE_RANGE_FIELD]: ageRangeFromParam,
    [NDIS_PLAN_FIELD]: ndisPlanFromParam,
    [FIRST_NAME_FIELD]: firstNameFromParam,
    [PARTICIPANT_FIRST_NAME_FIELD]: participantFirstNameFromParam,
  };

  const preFilledData = {
    [SERVICES_NEEDED_FIELD]: services,
    [LOCATION_FIELD]: serviceLocation,
    [SERVICES_FOR_FIELD]: _serviceForPreFilledData,
    [FINAL_DETAILS_FIELD]: _finalDetailsPreFilledData,
    [TELEHEALTH_FIELD]: telehealthFromParam,
    [ONLINE_SERVICE_FIELD]: onlineServiceFromParam,
    _sr_session: srSessionFromParam || uuidv4(),
    _ageGroups: taxonomyAgeGroups,
  };

  if ((serviceNameFromParam && !services.length) || !taxonomyServices ||
    !taxonomyServices.length || !taxonomyAgeGroups) return <Spinner />;
  return (
    <FormFlow
      flow={flow} onComplete={() => {}}
      completedPages={completedPages}
      setCompletedPages={setCompletedPages}
      forcedDefaults={preFilledData}
      {...props}
    />
  );
};

BrokerageFormFlow.propTypes = {
  flow: PropTypes.object,
};

export default BrokerageFormFlow;