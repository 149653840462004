import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SectionTitle from '../../../components/SectionTitle.js';
import MailTo from '../../../components/MailTo.js';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import * as track from '../../../lib/track';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1rem 0',
  },
  contactGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const ContactInfo = ({data}) => {
  const classes = useStyles();
  const schemifyUrl = url => (url.match('^https?://')) ? url : `https://${url}`;
  const providerUuid = 'title' in data ? data.provider_uuid : data.uuid;
  const handleClick = (item) => {
    track.sendEvent(`${item} link`, 'click', {}, [providerUuid]);
  };
  const isEmpty = (field) => field === undefined || field === null || field.trim() === '';

  return (
    <div className={classes.root}>
      <SectionTitle title="Contact information" />
      <div className={classes.contactGroup}>
        <List component="div" aria-label="Contact details list">
          {isEmpty(data.phone) ? null : (
            <ListItem>
              <ListItemIcon>
                <PhoneIcon color="primary" />
              </ListItemIcon>
              <ListItemText aria-label="Phone number">
                <Typography variant="body2">
                  {data.phone}
                </Typography>
              </ListItemText>
            </ListItem>
          )}
          {isEmpty(data.email) ? null : (
            <ListItem>
              <ListItemIcon>
                <EmailIcon color="primary" />
              </ListItemIcon>
              <MailTo email={data.email} className={classes.link} onClick={()=>handleClick('provider email')}>
                <ListItemText aria-label="Email address">
                  <Typography variant="body2">
                    {data.email}
                  </Typography>
                </ListItemText>
              </MailTo>
            </ListItem>
          )}
          {isEmpty(data.website) ? null : (
            <ListItem>
              <ListItemIcon>
                <LanguageIcon color="primary" />
              </ListItemIcon>
              <a href={schemifyUrl(data.website)} target="_blank" rel="noopener noreferrer" className={classes.link} onClick={()=>handleClick('provider website')}>
                <ListItemText aria-label="Website">
                  <Typography variant="body2">
                    {data.website}
                  </Typography>
                </ListItemText>
              </a>
            </ListItem>
          )}
        </List>
      </div>
    </div>
  );
};

ContactInfo.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactInfo;
