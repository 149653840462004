

export const validateAbn = (abn) => {
  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  const addWeighted = (p, v, i) => p + v * weights[i];
  if (abn === undefined || abn === null) {
    return 'Please provide an ABN';
  }
  const digits = abn.replace(/[^\d]/g, '').split('').map(Number);
  if (digits.length !== 11) {
    return 'Please provide an 11-digit ABN';
  }
  digits[0] -= 1;
  const sum = digits.reduce(addWeighted, 0);
  if (sum % 89 !== 0) {
    return 'That is not a valid ABN.';
  }
};

export const formatAbn = (abn) => {
  if (abn === undefined || abn === null) {
    return '';
  }
  const rawAbn = abn.replace(/[^\d]/g, '');
  return [
    rawAbn.substr(0, 2),
    rawAbn.substr(2, 3),
    rawAbn.substr(5, 3),
    rawAbn.substr(8, 3)
  ].join(' ').trim();
};
