import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'antd';

const baseStyle = {
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 0,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  row: {
    width: '100%',
    paddingBottom: '1.5rem',
  },
  alert: {
    width: '100%',
  },
  button: {
    minWidth: '9.3rem',
    marginTop: '1rem',
    marginRight: '1rem',
  },
};

const primaryStyle = makeStyles(theme => ({
  ...baseStyle,
  input: {
    backgroundColor: theme.palette.common.paleGrey,
  },
}));

const inverseStyle = makeStyles(theme => ({
  ...baseStyle,
  button: {
    minWidth: '9.3rem',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.common.paleGrey,
    }
  },
}));


const AuthForm = ({children, buttonLabel, buttonAction, disabled, busy, error, cssStyle='primary', onCancel}) => {
  const classes = (cssStyle === 'inverse') ? inverseStyle() : primaryStyle();
  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate autoComplete="off">
        {React.Children.map(children, child => child && <div key={child.props.id} className={classes.row}>{child}</div>)}
      </form>
      {error &&
        <Alert severity="error" className={classes.alert}>
          {error}
        </Alert>
      }
      <div className={classes.row}>
        <div style={{display: 'flex', columnGap: '10px'}}>
          {onCancel ? (<Button type="primary" ghost onClick={onCancel}>Cancel</Button>) : null}
          <Button style={{minWidth: '150px'}} type="primary" onClick={buttonAction} disabled={disabled} loading={busy}>{buttonLabel}</Button>
        </div>
      </div>
    </div>
  );
};

AuthForm.propTypes = {
  children: PropTypes.node.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  busy: PropTypes.bool,
  error: PropTypes.string,
  cssStyle: PropTypes.string,
  onCancel: PropTypes.func,
};

export default AuthForm;
