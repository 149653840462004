import Button from 'components/Button.js';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    marginBottom: '1rem',
  },
  navbar: {
  },
});

const FormFlowContainer = ({answers, onCancel, onComplete, setPageName, prev, next, complete, children}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {children}
      <div className={classes.navbar}>
        {prev &&
          <Button
            onClick={()=>setPageName(prev.name)}
            label="Back"
            pointer="left"
            variant="contained"
            disabled={prev === undefined}
          />
        }
        {next &&
          <Button
            onClick={()=>setPageName(next.name)}
            label="Next"
            pointer="right"
            variant="contained"
            disabled={!complete}
          />
        }
        {onCancel &&
          <Button
            onClick={onCancel}
            label="Cancel"
            pointer="none"
            variant="outlined"
          />
        }
        {!next &&
          <Button
            onClick={()=>onComplete(answers)}
            label="Submit"
            pointer="right"
            variant="contained"
            disabled={!complete}
          />
        }
      </div>
    </div>
  );
};

FormFlowContainer.propTypes = {
  answers: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
  setPageName: PropTypes.func.isRequired,
  prev: PropTypes.string,
  next: PropTypes.string,
  complete: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default FormFlowContainer;