import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import clickaAgentImg from 'assets/clicka-agent.png';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    columnGap: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: '20px',
    },
  },
  speechContainer: {
    width: '100%',
    minHeight: '70px',
    background: theme.palette.common.darkSlateBlue,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '12px',
    zIndex: '1',
    position: 'relative',
  },
  text: {
    position: 'relative',
    zIndex: '2',
    color: theme.palette.common.white,
    fontWeight: '500',
    padding: '20px',
  },
  corner: {
    '&:before': {
      content: '\'\'',
      width: '0px',
      height: '0px',
      position: 'absolute',
      borderLeft: '15px solid transparent',
      borderRight: `15px solid ${theme.palette.common.darkSlateBlue}`,
      borderTop: `15px solid ${theme.palette.common.darkSlateBlue}`,
      borderBottom: '15px solid transparent',
      left: '-16px',
      top: '0px',
      [theme.breakpoints.down('sm')]: {
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
        borderTop: '15px solid transparent',
        borderBottom: `15px solid ${theme.palette.common.darkSlateBlue}`,
        position: 'absolute',
        left: 'calc(50% - 15px)',
        top: '-29px',
      },
    },
  },
}));

export const SpeechBubble = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={clickaAgentImg} alt="clicka-agent" width="74" height="79" />
      <div className={classNames(classes.speechContainer, classes.corner)}>
        <div className={classes.text}>
          {text}
        </div>
      </div>
    </div>
  );
};

SpeechBubble.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default SpeechBubble;