import React from 'react';
import PropTypes from 'prop-types';
import AuthFormPage from './components/AuthFormPage';
import PasswordResetForm from './components/PasswordResetForm';
import { useHistory, useLocation } from 'react-router-dom';

const PasswordResetPage = ({isProvider}) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const username = query.get('username');
  const code = query.get('code');
  const reset = () => {
    history.push(isProvider ? '/providers/login' : '/login');
  };

  return (
    <AuthFormPage title="Clickability Password Reset">
      <PasswordResetForm
        username={username}
        code={code}
        onComplete={reset}
        isProvider={isProvider}
        title="Reset your password"
      />
    </AuthFormPage>
  );
};

PasswordResetPage.propTypes = {
  isProvider: PropTypes.bool.isRequired,
};

export default PasswordResetPage;
