import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NavButton from './NavButton';
import { NavButtonWithMenu } from './NavButton';
import PropTypes from 'prop-types';
import React from 'react';
import UserNavMenu from './UserNavMenu';
import { makeStyles } from '@material-ui/core/styles';
import { sendEvent } from 'lib/track';

const desktopStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  },
}));

const LinkItem = ({label, link, href, submenu,}) => {
  const onClick = () => sendEvent(`navbar ${label}`, 'click');
  if (submenu) {
    return <NavButtonWithMenu items={submenu}>{label}</NavButtonWithMenu>;
  }
  return <NavButton to={link} href={href} onClick={onClick}>{label}</NavButton>;
};
LinkItem.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  href: PropTypes.string,
  submenu: PropTypes.array,
};

const DesktopMenu = ({items, hideNav, hideAuth, toggleAuthMenu}) => {
  const classes = desktopStyles();
  return (
    <div className={classes.root}>
      <div>
        {hideNav ? null : items.map(item => <LinkItem key={item.label} {...item}/>)}
      </div>
      <div>
        {hideAuth ? null : (
          <UserNavMenu toggleAuthMenu={toggleAuthMenu}/>
        )}
      </div>
    </div>
  );
};

DesktopMenu.propTypes = {
  items: PropTypes.array.isRequired,
  hideNav: PropTypes.bool,
  hideAuth: PropTypes.bool,
  toggleAuthMenu: PropTypes.func.isRequired,
};

const mobileStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

const MobileMenu = ({hideNav, hideAuth, toggleMobileMenu}) => {
  const classes = mobileStyles();
  if (hideNav && hideAuth) return null;
  return (
    <div className={classes.root}>
      <IconButton
        edge="start"
        className={classes.NavButton}
        color="inherit"
        aria-label="open drawer"
        onClick={toggleMobileMenu}
      >
        <MenuIcon className={classes.icon}/>
      </IconButton>
    </div>
  );
};

MobileMenu.propTypes = {
  hideNav: PropTypes.bool,
  hideAuth: PropTypes.bool,
  toggleMobileMenu: PropTypes.func.isRequired,
};

const MenuControls = ({items, hideNav, hideAuth, toggleMobileMenu, toggleAuthMenu}) => {
  return (
    <React.Fragment>
      <DesktopMenu
        items={items}
        hideNav={hideNav}
        hideAuth={hideAuth}
        toggleAuthMenu={toggleAuthMenu}
      />
      <MobileMenu
        hideNav={hideNav}
        hideAuth={hideAuth}
        toggleMobileMenu={toggleMobileMenu}
      />
    </React.Fragment>
  );
};

MenuControls.propTypes = {
  items: PropTypes.array.isRequired,
  hideNav: PropTypes.bool,
  hideAuth: PropTypes.bool,
  toggleMobileMenu: PropTypes.func.isRequired,
  toggleAuthMenu: PropTypes.func.isRequired,
  servicesButtonRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

export default MenuControls;
