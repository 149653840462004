import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Badge as AntdBadge } from 'antd';

import style from 'style';


const FiltersContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: flex-end;
`;

const Tab = styled.div`
  position: relative;
  width: 100%;
  box-shadow: ${style.shadow(15)};
  border: 1px solid ${style.colors.lightBorder};
  border-bottom: none;
  border-top-right-radius: ${style.borderRadius.large};
  border-top-left-radius: ${style.borderRadius.large};
  height: ${({ selected }) => selected ? '35px' : '27px'};
  margin-top: ${({ selected }) => selected ? '0' : '8px'};
  background-color: ${({selected}) => selected ? 
    style.colors.palette.white : '#eeeeee'};
  font-size: 12px;
  font-weight: 500;
  color: ${({selected}) => selected ? 
    style.colors.primary : style.colors.palette.battleshipGrey};
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Badge = styled(AntdBadge)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
 
  .ant-badge-count {
    border: 1px solid ${style.colors.lightBorder};
    background: ${({selected}) => selected ? 
    style.colors.palette.white : '#eeeeee'};
    
    color: black;
    box-shadow: ${style.shadow(15)};
  }
`;

const HeaderFilter = ({
  filters,
  onFilterClick
}) => {
  const [selectedTab, setSelectedTab] = useState(filters[0].name);

  return (
    <FiltersContainer>
      {filters.map((item, index) => (
        <FilterTab
          key={index}
          item={item}
          selected={selectedTab === item.name}
          onTabClick={() => {
            if (selectedTab !== item.name) {
              setSelectedTab(item.name);
              onFilterClick(item);
            }
          }}
        />
      ))}
    </FiltersContainer>
  );
};

HeaderFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    count: PropTypes.number,
  })),
  onFilterClick: PropTypes.func
};

const FilterTab = ({
  item,
  selected,
  onTabClick
}) => {
  return (
    <Tab selected={selected} onClick={onTabClick}>
      <div>{item.name}</div>
      <Badge count={item.count} selected={selected} />
    </Tab>
  );
};

FilterTab.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  onTabClick: PropTypes.func
};


export default HeaderFilter;