import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { faLayerPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider } from 'antd';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';

import { ConnectAPI, useMutateApi } from 'providerSite/api';
import ParticipantDetailsForm from '../../../components/ParticipantDetailsForm';
import Header from '../../../components/Header';
import { checkCompleted } from '../../../components/ParticipantDetailsForm/utils';
import { useEmailVerificationCheck } from '../../../components/utils';
import { FIELD_EXTRA_DATA } from '../../../components/ParticipantDetailsForm/constants';


const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  column-gap: 20px;
  padding: 20px;
  .button {
    min-width: 150px;
  }
  .icon {
    margin-right: 10px;
  }
`;


const ParticipantCreator = ({ providerUuid }) => {

  const [data, setData] = useState({});
  const location = useLocation();
  const history = useHistory();

  useEmailVerificationCheck();

  const inServiceRequestFlow = !!location.state?.inServiceRequestFlow;

  const mutation = useMutateApi(ConnectAPI.POST_PARTICIPANT, 'POST', {
    params: {
      providerUuid: providerUuid,
    },
    invalidation: {
      exact: true,
    },
    onSuccess: (data) => {
      if (inServiceRequestFlow) {
        history.push('service-request', {participant: data.payload});
      } else {
        history.replace(location.pathname);
      }
      toast.success('Successfully created');
    },
  });

  const handleChange = (key, val) => {
    setData(prevState => {
      if( key === FIELD_EXTRA_DATA ) {
        return {...prevState, [key]: {
          ...prevState[key] || {},
          ...val
        }};
      }
      return {...prevState, [key]: val};
    });
  };

  return (
    <>
      <Header text="Create a New Participant" backButton={!inServiceRequestFlow} />
      <ParticipantDetailsForm handleChange={handleChange} />
      <Divider />
      <ButtonContainer>
        <Button
          className="button"
          size="large"
          type="primary"
          icon={<FontAwesomeIcon className="icon" icon={faLayerPlus} />}
          disabled={!checkCompleted(data)}
          onClick={() => mutation.mutate(data)}
          loading={mutation.isLoading}
        >
          {inServiceRequestFlow ? 'Continue' : 'Create'}
        </Button>
      </ButtonContainer>
    </>
  );
};


ParticipantCreator.propTypes = {
  providerUuid: PropTypes.string,
  backButton: PropTypes.bool,
};


export default ParticipantCreator;
