import {
  FIELD_AGE_GROUP,
  FIELD_CONTACT_PARTICIPANT,
  FIELD_EMAIL,
  FIELD_FIRST_NAME,
  FIELD_GENDER,
  FIELD_INTERPRETER_REQUIRED,
  FIELD_LANGUAGES,
  FIELD_LAST_NAME,
  FIELD_LOCATION,
  FIELD_PHONE,
  FIELD_PLAN_TYPE,
  FIELD_TELEHEALTH,
} from './constants';


const ALL_REQUIRED_FIELDS = [
  FIELD_AGE_GROUP,
  FIELD_CONTACT_PARTICIPANT,
  FIELD_EMAIL,
  FIELD_FIRST_NAME,
  FIELD_GENDER,
  FIELD_INTERPRETER_REQUIRED,
  FIELD_LANGUAGES,
  FIELD_LAST_NAME,
  FIELD_LOCATION,
  FIELD_PHONE,
  FIELD_PLAN_TYPE,
  FIELD_TELEHEALTH,
];


export const checkCompleted = (data, fullDataCheck=true) => {
  if (fullDataCheck && Object.keys(data).length < ALL_REQUIRED_FIELDS.length) return false;

  for (const field of ALL_REQUIRED_FIELDS) {
    if ((fullDataCheck || field in data) && !data[field]) return false;
    if (Array.isArray(data[field]) && !data[field].length) return false;
  }

  return true;
};