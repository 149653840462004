import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import * as track from 'lib/track';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '3rem 0 6rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none',
    zIndex: 0,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      padding: '1rem',
    },
  },
  content: {
    padding: '1rem 3rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      marginBottom: '1rem',
    },
  },
  header: {
    padding: '2rem',
    color: theme.palette.secondary.main,
    width: '100%',
  },
  services: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '2.563rem',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  service: {
    padding: '1.375rem 1.688rem',
    border: '2px solid transparent',
    boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.12)',
    borderRadius: '1.25rem',
    width: '21.25rem',
    height: '12.5rem',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      transition: 'transform 300ms ease-in-out',
    },
    '&:hover': {
      border: '2px solid #264F73',
      '& .MuiSvgIcon-root': {
        transform: 'translate(10px, 0px)',
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      marginBottom: '2rem',
    },
  },
  iconHolder: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    fontSize: '5rem',
    color: theme.palette.primary.main,
  },
}));


const Services = () => {
  const classes = useStyles();
  const history = useHistory();

  const onClick = (text, to) => {
    track.sendEvent(`${text} button`, 'click');
    history.push(to);
  };

  const renderService = (text, to) => (
    <div className={classes.service} onClick={()=>onClick(text, to)}>
      <Typography variant="h1" color="primary">
        {text}
      </Typography>
      <div className={classes.iconHolder}>
        <ArrowRightAltIcon className={classes.icon}/>
      </div>
    </div>
  );

  return (
    <div className={classes.root} aria-hidden="true">
      <Container maxWidth="lg" fixed disableGutters={true}>
        <div className={classes.content}>
          <Typography variant="h1" className={classes.header}>
            We help you navigate the NDIS.
          </Typography>
          <div className={classes.services}>
            {renderService('Get information about the NDIS', '/blog')}
            {renderService('Connect with NDIS providers', '/requestmatch')}
            {renderService('Browse NDIS providers', '/search')}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Services;
