import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Page from '../../components/Page';
import ProviderSignupForm from 'modules/auth/components/ProviderSignupForm';
import style from 'style';
import { useMutateApi } from 'providerSite/api';
import { POST_PROVIDER } from 'providerSite/api/auth';


const DEFAULT_ERROR_MESSAGE = 'An error occurred creating your account.  Please try again later.';

const ConnectSignupFormContainer = styled.div`
  margin: 1rem 0;
  max-width: ${style.sizes.small};
`;

const ConnectSignup = () => {
  const history = useHistory();
  const [busy, setBusy] = useState(false);
  const [signupError, setSignupError] = useState(null);
  const profile = useSelector(state => state.auth.profile);

  const {
    isLoading,
    mutate,
  } = useMutateApi(POST_PROVIDER, 'POST', {
    onSuccess: (_, variables) => {
      history.push('/signup/confirm', { email: variables.email });
    },
    onError: error => {
      let message = DEFAULT_ERROR_MESSAGE;
      if (error.body && error.body.message) {
        message = error.body.message;
      }
      setSignupError(message);
    }
  });

  useEffect(() => {
    if (isLoading) {
      setBusy(true);
    } else {
      setBusy(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (profile?.username) {
      history.push('/connect/activation');
    }
  }, [profile]);

  return (
    <Page>
      <ConnectSignupFormContainer>
        <ProviderSignupForm
          title="Create your Clickability Connect Account"
          buttonLabel="Sign up"
          loginLink="/providers/login"
          busy={busy}
          onComplete={data => mutate({ ...data, has_connect_access: true })}
          signupError={signupError}
          isConnect={true}
        />
      </ConnectSignupFormContainer>
    </Page>
  );
};

export default ConnectSignup;