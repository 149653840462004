import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { prettyDate } from '../../../lib/time';


const useStyles = makeStyles(theme => ({
  container: {
    padding: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0',
    },
  },
}));

const BlogPostTitle = ({content}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h1" color="primary">
        {content.title}
      </Typography>
      <div className={classes.subtitle}>
        <Typography variant="body2">
          {prettyDate(content.date)}
        </Typography>
      </div>
    </div>
  );
};

BlogPostTitle.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  })
};

export default BlogPostTitle;
