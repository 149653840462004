import React, { useState, useEffect, useRef }  from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as EmailValidator from 'email-validator';
import styled from 'styled-components';
import { useClickAway } from 'react-use';
import { toast } from 'react-hot-toast';

import { Textbox, Password } from 'providerSite/components/inputs';
import { setIndividualAnswer } from '../utils';
import {
  FIELD_FIRST_NAME,
  FIELD_LAST_NAME,
  FIELD_EMAIL,
  FIELD_PASSWORD
} from '../constants';
import { AuthAPI } from 'api';
import SpinnerModal from 'components/SpinnerModal';
import config from 'config';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  justify-content: center;
  row-gap: 10px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 20px;
  width: 100%;
`;

const nameValidator = value => !!value?.match(/^[a-zA-Z'\- ]+$/g) && value.length >= 2;


const UserPage = ({ onChange, value }) => {

  const history = useHistory();
  const { isAuthenticated } = useSelector(state => state.auth);
  const [data, setData] = useState(value ? value : {});
  const [isEmailAvailable, setIsEmailAvailable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailCurrentlyClicked, setIsEmailCurrentlyClicked] = useState(false);

  const emailInputRef = useRef(null);

  useClickAway(emailInputRef, () => {
    setIsEmailCurrentlyClicked(false);
  });

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    isAuthenticated && history.push('/providers/profile');
  }, [isAuthenticated]);

  const handleEmailLookup = val => {
    if (!EmailValidator.validate(val)) return false;
    setIsLoading(true);
    AuthAPI.providerAvailable({email: val})
      .then(res => res.payload)
      .then(res => {
        const isAvailable = !!res?.email;
        setIsEmailAvailable(isAvailable);
        setIndividualAnswer(setData, FIELD_EMAIL, isAvailable ? val : null);
      })
      .catch(() => {
        toast.error('Sorry, something went wrong! Please contact the customer support.');
      })
      .finally(() => setIsLoading(false));
  };

  // To make sure pre-filled email is available
  useEffect(() => {
    const emailValue = value?.[FIELD_EMAIL];
    if (!emailValue) return;
    handleEmailLookup(emailValue);
  }, []);

  return (
    <Container>
      <Row>
        <Textbox
          title="First Name"
          defaultValue={value?.[FIELD_FIRST_NAME]}
          onChange={val => setIndividualAnswer(setData, FIELD_FIRST_NAME, val)}
          validator={nameValidator}
          errorMsg="Please enter a valid first name"
          colouredBorder
        />
        <Textbox
          title="Last Name"
          defaultValue={value?.[FIELD_LAST_NAME]}
          onChange={val => setIndividualAnswer(setData, FIELD_LAST_NAME, val)}
          validator={nameValidator}
          errorMsg="Please enter a valid last name"
          colouredBorder
        />
      </Row>
      <Row>
        <Textbox
          fwdRef={emailInputRef}
          title="Your work email address"
          defaultValue={value?.[FIELD_EMAIL]}
          onChange={val => EmailValidator.validate(val) && !isEmailCurrentlyClicked && handleEmailLookup(val)}
          validator={val => EmailValidator.validate(val) && (isEmailAvailable === null || isEmailAvailable === true)}
          errorMsg={isEmailAvailable === false ?
            <>
              An account already exists for that email address,
              try <a href={`${config.website.URL}/providers/login`}>logging in</a> or&nbsp;
              <a href={`${config.website.URL}/providers/password/recover`}>forgot password</a>
            </>
            : 'Please enter a valid email address'}
          onBlur={e => handleEmailLookup(e.target.value)}
          onClick={() => setIsEmailCurrentlyClicked(true)}
          colouredBorder
        />
      </Row>
      <Row>
        <Password
          title="Password"
          defaultValue={value?.[FIELD_PASSWORD]}
          onChange={val => setIndividualAnswer(setData, FIELD_PASSWORD, val)}
          validator={val => val && val.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w\W]{8,}$/)}
          errorMsg="Password must be at least 8 characters and contain at least
          one each of: upper case, lower case, and digits."
          colouredBorder
        />
      </Row>
      <SpinnerModal isLoading={isLoading} />
    </Container>
  );
};

UserPage.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
};


export default UserPage;
