export const fetchData = async (params, apiFunc, setFunc, page, limit) => {
  try {
    const p = {...params, offset: (page * limit) - limit, limit};
    const { payload, total } = await apiFunc(p);
    const start = ((page * limit) - limit) + 1;
    let end = page * limit;
    if (end > total) {
      end = total;
    }
    const result = {
      isLoading: false,
      data: payload,
      total: total,
      pageCount: Math.ceil(total / limit),
      currentPage: page,
      paginationText: `Showing results ${start} to ${end} of ${total}`,
    };
    setFunc(result);
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
};
