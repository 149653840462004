import React from 'react';
import { Route } from 'react-router-dom';
const AdminPage = React.lazy(() => import('./pages/AdminPage'));

// modules return fragments containing routes.
// passing the component as a prop ensures match params are passed in props
// see https://stackoverflow.com/questions/46197178/cannot-read-property-params-of-undefined-react-router-4

const routes = () => {
  return [
    <Route key="/provider/admin/page" exact path="/provider/:uuid/admin/:page" component={AdminPage} />
  ];
};

export default routes;
