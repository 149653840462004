import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const StyledAlert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SnackbarAlert = (
  {
    open,
    message,
    handleClose,
    variant,
    autoHideDuration = 5000,
    anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  }) => {

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
    >
      <StyledAlert onClose={handleClose} severity={variant}>
        {message}
      </StyledAlert>
    </Snackbar>
  );
};

SnackbarAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  message: PropTypes.string,
  anchorOrigin: PropTypes.object,
  autoHideDuration: PropTypes.number,
};

export default SnackbarAlert;
