import {
  CallToAction,
  ConnectWithProviders,
  HowWeHelp,
  ServicesCovered,
} from 'modules/home/components';

import { Helmet } from 'react-helmet';
import Page from 'components/Page.js';
import PromoHero from './components/PromoHero.js';
import React from 'react';
import ServiceRequestSurvey from 'components/ServiceRequestSurvey';

const PromoPage = () => {

  // note : opengraph meta tags cannot use html entities, so we use \' instead of &apos;
  return (
    <Page title='Clickability Promotion'>
      <Helmet meta={[
        {property: 'og:url', content: 'https://clickability.com.au/ndis-services-giveaway' },
        {property: 'og:title', content: 'Clickability\'s Huge Giveaway- Click. Compare. Connect.' },
        {property: 'og:image', content: 'https://user-assets.out.sh/user-assets/2141091/k3eYZez0IALk7cg9/upload.png' },
        {property: 'twitter:card', content: 'summary_large_image' },
        {property: 'twitter:image:src', content: 'https://user-assets.out.sh/user-assets/2141091/k3eYZez0IALk7cg9/upload.png' },
        {property: 'og:description', content: 'Clickability is Australia\'s #1 marketplace for NDIS services helping participants to find ' +
         'their perfect disability support service is doing a huge giveaway! Clickability is changing the way people with disabilities ' +
         'find and manage their NDIS services. It\'s completely free to find a provider with our unique matching service.' },
      ]}/>
      <PromoHero />
      <HowWeHelp />
      <ServicesCovered />
      <ConnectWithProviders />
      <CallToAction />
      <ServiceRequestSurvey />
    </Page>
  );
};

export default PromoPage;
