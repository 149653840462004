import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import {
  faClock,
  faFlagCheckered,
  faLocationDot,
  faSignature,
  faSuitcase
} from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import camelcase from 'camelcase';
import styled from 'styled-components';

import Table from 'providerSite/components/Table';
import { useGetApi } from 'providerSite/api';
import {
  GET_CONNECT_SERVICE_REQUESTS,
  GET_CONNECT_SERVICE_REQUESTS_FILTER_COUNT
} from 'providerSite/api/serviceRequest';
import DateTimeStatus from 'components/DateTimeStatus';
import ServiceRequestStatus from 'components/ServiceRequestStatus';
import Dot from 'components/ServiceRequestStatus/Dot';
import style from 'style';
import QualifiedServiceIcon from 'components/QualifiedServiceIcon';

const DEFAULT_REFETCH_INTERVAL = 1000 * 60;   // 1 minute

const defaultFilters = [
  {
    name: 'All',
    paramKey: 'all',
    count: 0
  },
  {
    name: 'Open',
    paramKey: 'open',
    count: 0
  },
  {
    name: 'In Progress',
    paramKey: 'in_progress',
    count: 0
  },
  {
    name: 'Closed',
    paramKey: 'closed',
    count: 0
  },
  {
    name: 'Expired',
    paramKey: 'expired',
    count: 0
  },
];

const defaultOrderBy = '-created_at';

const NotificationContainer = styled.div`
  position: absolute;
  top: 0;
  left: -15px;
`;

const ServiceIconContainer = styled.div`
  color: ${style.colors.secondary};
`;

const ClickaConnectTable = ({ providerUuid, onRowClick }) => {
  const [currentTabFilter, setCurrentTabFilter] = useState(null);
  const [tabFilter, setTabFilter] = useState(defaultFilters);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const fastPolling = location.state?.fastPolling;
  const isFastPollingExpired = () => dayjs().diff(dayjs(fastPolling?.createdAt), 'second') > 30;

  const getRefetchInterval = (data) => {
    if (!fastPolling || isFastPollingExpired() ||
      data?.metaData?.count > fastPolling.serviceRequestCount) {
      return DEFAULT_REFETCH_INTERVAL;
    }
    // Fast polling interval
    return 1000 * 3;
  };

  useEffect(() => {
    fastPolling && isFastPollingExpired() && history.replace(location.pathname, {
      ...location.state,
      fastPolling: undefined,
    });
  });

  const { isLoading, data } = useGetApi(GET_CONNECT_SERVICE_REQUESTS, {
    params: {
      providerUuid
    },
    queryParams: {
      status: currentTabFilter,
      page: page,
      search: searchText,
      order_by: defaultOrderBy
    },
    staleTime: 0,   // No caching
    refetchOnWindowFocus: true,
    refetchInterval: getRefetchInterval,
  });

  const { isLoading: isFilterCountLoading, data: filterCountData } = useGetApi(
    GET_CONNECT_SERVICE_REQUESTS_FILTER_COUNT,
    {
      params: {
        providerUuid
      },
      queryParams: {
        status: currentTabFilter,
        page: page,
        search: searchText,
      }
    }
  );

  const handlePaginationClick = page => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setPage(page);
  };

  useEffect(() => {
    if (filterCountData && !isFilterCountLoading) {
      const newTabFilter = tabFilter.map((filter) => ({
        name: filter.name,
        paramKey: filter.paramKey,
        count: filterCountData[camelcase(filter.paramKey)]
      }));
      setTabFilter(newTabFilter);
    }
  }, [isFilterCountLoading, filterCountData]);

  const columns = [
    {
      title: '',
      dataIndex: 'hasNewNotification',
      width: '0%',
      render: ({ hasNewNotification }) => {
        if (hasNewNotification) {
          return (
            <NotificationContainer>
              <Dot color={style.colors.error}/>
            </NotificationContainer>
          );
        }
      }
    },
    {
      title: '',
      dataIndex: 'serviceIcon',
      width: '5%',
      render: ({ serviceName }) => (
        <ServiceIconContainer>
          <QualifiedServiceIcon.Regular serviceName={serviceName} />
        </ServiceIconContainer>
      ),
    },
    {
      title: 'Participant Name',
      dataIndex: 'participantName',
      width: '21%',
      icon: faSignature,
    },
    {
      title: 'Service',
      dataIndex: 'serviceName',
      width: '18%',
      icon: faSuitcase,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: '27%',
      icon: faLocationDot,
    },
    {
      title: 'Update',
      dataIndex: 'lastUpdated',
      icon: faClock,
      render: ({ updatedAt }) => {
        if (updatedAt) {
          return <DateTimeStatus value={updatedAt} />;
        }
      }
    },
    {
      title: 'Status',
      dataIndex: 'status.statusName',
      icon: faFlagCheckered,
      render: ({ uuid: serviceRequestUuid, status }) => {
        return (
          <ServiceRequestStatus
            key={`ServiceRequestStatus-${serviceRequestUuid}`}
            serviceRequestUuid={serviceRequestUuid}
            status={status.statusName}
            availableOptions={[]}
            closureReasonOptions={[]}
          />
        );
      }
    }
  ];
  return (
    <Table
      columns={columns}
      data={data?.data}
      metaData={data?.metaData}
      isLoading={isLoading}
      onPaginationClick={handlePaginationClick}
      onRowClick={onRowClick}
      filters={tabFilter}
      onFilterClick={tab => {
        setCurrentTabFilter(tab.paramKey);
        setPage(1);
      }}
      showSearchBar={true}
      onSearch={text => {
        setSearchText(text);
        setPage(1);
      }}
      searchPlaceHolder="Search name, modality or locality"
    />
  );
};

ClickaConnectTable.propTypes = {
  providerUuid: PropTypes.string,
  onRowClick: PropTypes.func,
};

export default ClickaConnectTable;
