import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Typography } from 'antd';
import styled from 'styled-components';

import clickaAgent from 'assets/stepper-two.png';
import style from 'style';


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  height: 100%;
  .title {
    color: ${style.colors.primary};
    margin: 30px 0 10px 0;
  }
  .button {
    margin-top: 70px;
  }
  .text {
    max-width: 700px;
    text-align: center;
  }
`;


const Exit = () => {

  const history = useHistory();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <Container>
      <img src={clickaAgent} alt="clicka-agent" width="226px" height="115px" />
      <Typography.Title className="title">
        Sorry, we couldn’t connect you with providers
      </Typography.Title>
      <Typography.Text className="text">
        We unfortunately haven’t been able to connect you directly with a provider
        this time, however we still have a directory with over 3000 providers to
        choose from – and you can use it for free. Click below to get started.
      </Typography.Text>
      <Button
        type="primary"
        size="large"
        onClick={() => history.push('/search')}
        className="button"
      >
        Browse Directory
      </Button>
    </Container>
  );
};

export default Exit;