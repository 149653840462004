import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
  },
  title: {
    fontSize: '16px',
    color: theme.palette.common.nightBlue,
  },
  subTitle: {
    fontSize: '14px',
    color: theme.palette.common.battleshipGrey,
    '& a': {
      textDecoration: 'none',
      color: theme.palette.common.tealish,
    },
    '& a:hover': {
      color: theme.palette.common.dustyOrange,
    },
  },
  children: {
    marginTop: '10px',
  },
}));

const Question = ({ children, title, subTitle, isOptional=() => false, answers }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {typeof title === 'function' ? title(answers) : title} {isOptional(answers) && '(optional)'}
      </div>
      <div className={classes.subTitle}>{subTitle}</div>
      <div className={classes.children}>{children}</div>
    </div>
  );
};

Question.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOptional: PropTypes.func,
  answers: PropTypes.object,
};

export default Question;