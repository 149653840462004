import Button from './Button';
import ContactText from 'components/ContactText';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';
import Page from './Page';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import backgroundImg from '../assets/background-2.svg';
import logoIcon from '../assets/logo-icon-dark-blue.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  background: {
    width: '100%',
    minHeight: '70vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundImage: `url(${backgroundImg})`
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '4rem 1.5rem',
    height: '100%',
    minHeight: '100%',
  },
  img: {
    width: '4.4rem',
  },
  main: {
    paddingLeft: '4.4rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  text: {
    width: '60%',
    margin: '1rem 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const ErrorPage = ({ title, children }) => {
  const classes = useStyles();
  return (
    <Page title={title}>
      <Helmet meta={[{ name: 'robots', content: 'noindex' }]} />
      <div className={classes.background}>
        <Container maxWidth="lg" fixed disableGutters={true}>
          <Grid container className={classes.content}>
            <Grid item md={12}>
              <img src={logoIcon} className={classes.img} alt="icon" />
            </Grid>
            <Grid item md={12} className={classes.main}>
              {children}
              <Typography variant="body2" className={classes.text}>
                If you need some help <ContactText/>.
              </Typography>
              <Button variant="contained" label="Back to home page" cssStyle="large" aria-label="back to home page" to="/" />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

ErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ErrorPage;
