import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import mobilePlaceholderImage from 'assets/physiotherapist-and-boy-holding-ball-600x338.png';
import placeholderImage from 'assets/physiotherapist-and-boy-holding-ball-285x161.png';

// CONFIGURATION OPTIONS
const imageDx = 265;  // adjust cover image size
const imageDy = Math.ceil(imageDx * 9 / 16);
const mobileImageDx = 600;
const mobileImageDy = Math.ceil(mobileImageDx * 9 / 16);

const useStyles = makeStyles(theme => ({
  coverImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  coverImgContainer: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
}));

const ImagePanel = ({data, useMobileLayout}) => {
  const imageSize = useMobileLayout ? `@${mobileImageDx}x${mobileImageDy}` : `@${imageDx}x${imageDy}`;
  const placeholder = useMobileLayout ? mobilePlaceholderImage : placeholderImage;
  const src = data.imagePath ? data.imagePath + imageSize : placeholder;
  const classes = useStyles();

  return (
    <div className={classes.coverImgContainer}>
      <img
        src={src}
        className={classes.coverImg}
        alt={`logo for ${data.title}`}
      />
    </div>
  );
};

ImagePanel.propTypes = {
  data: PropTypes.shape({
    imagePath: PropTypes.string,
    title: PropTypes.string,
  }),
  useMobileLayout: PropTypes.bool,
};

export default ImagePanel;
export {
  imageDx,
  imageDy,
  mobileImageDx,
  mobileImageDy,
};