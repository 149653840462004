import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as QueryString from 'query-string';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import * as track from 'lib/track';
import FilterDisplay from '../components/FilterDisplay';
import FilterPanel from '../components/FilterPanel.js';
import { ProviderAPI } from 'api';
import SectionTitle from 'components/SectionTitle.js';
import TabsPanel from '../components/TabsPanel';
import { fetchData } from './FetchData';
import { AuthStore } from 'store';
import { makeSubtitle } from '../utils';
import logo from 'assets/nav-logo.svg';
import DirectoryMap from 'components/DirectoryMap';
import { ProviderAPI as NewProviderAPI, useGetApi } from 'providerSite/api';
import DirectoryAdCTA from 'components/cta/DirectoryAdCTA';


const LIMIT = 20;
const EXTERNAL_DIR_MODE_LIMIT = 5;

const LIST_FILTERS = new Set([
  'service',
  'specialisation',
]);

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    padding: '2rem 1rem',
    minHeight: '65vh',
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 500,
    marginTop: '30px',
  },
  poweredBy: {
    marginLeft: '3px',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      alignItems: 'center',
    },
  },
}));

const defaultState = {
  data: [],
  total: 0,
  pageCount: 1,
  currentPage: 1,
  paginationText: '',
};

const Search = ({ location, externalDirectoryMode }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const scrollRef = React.useRef(null);
  const executeScroll = () => scrollToRef(scrollRef);

  const [providers, setProviders] = React.useState(defaultState);
  const [isLoading, setIsLoading] = React.useState(true);
  const [urlParamsForMap, setUrlParamsForMap] = useState({});

  const urlParams = QueryString.parse(location.search);
  const page = ('page' in urlParams) ? parseInt(urlParams.page) : 1;

  const _excluded_services = urlParams.excluded_services;
  const excludedServices = _excluded_services && _excluded_services.split(',');

  const { data: _initialProvidersMapData, isLoading: isMapLoading } = useGetApi(NewProviderAPI.GET_SEARCH_LISTINGS, {
    queryParams: {
      ...urlParamsForMap,
      limit: urlParams.service_location ? 20 : 5,
      excluded_services: _excluded_services,
      for_map: true,
    },
    onError: err => console.error(err),    // eslint-disable-line no-console
  });

  const { data: _providersMapData } = useGetApi(NewProviderAPI.GET_SEARCH_LISTINGS, {
    queryParams: {
      ...urlParamsForMap,
      limit: urlParams.service_location ? 9999 : 5,
      excluded_services: _excluded_services,
      for_map: true,
    },
    enabled: !!_initialProvidersMapData,
    initialData: _initialProvidersMapData,
    onError: err => console.error(err),    // eslint-disable-line no-console
  });

  const providersMapData = _providersMapData ? _providersMapData : [];

  // This listing API performs all the searching logic against listings, and
  // returns Provider Organisations for those listings.
  const getData = () => {
    setIsLoading(true);
    fetchData(
      {...urlParams, excluded_services: _excluded_services},
      ProviderAPI.searchListings,
      setProviders,
      page,
      externalDirectoryMode ? EXTERNAL_DIR_MODE_LIMIT : LIMIT
    ).finally(() => setIsLoading(false));
  };

  const pushSearch = (searchParams) => {
    const params = Object.keys(searchParams).reduce((acc, key) => {
      const value = searchParams[key];
      if (Array.isArray(value)) {
        const listItems = value.map(item => [key, item]);
        return [...acc, ...listItems];
      } else {
        acc.push([key, value]);
        return acc;
      }
    }, []);
    const query = new URLSearchParams(params).toString();
    history.push(`?${query}`);
    executeScroll();
  };

  const pushQuery = (key, value) => {
    if (urlParams[key] !== value) {
      delete urlParams['page']; // go to page 1 on any change other than page
    }
    if (value === undefined) {  // filter has been cleared
      delete urlParams[key];
      pushSearch(urlParams);
    } else if (value !== null) {
      urlParams[key] = value;
      pushSearch(urlParams);
    }
  };

  const onPageChange = (event, page) => {
    pushQuery('page', page);
    track.sendEvent('search result page', 'change', {value: page});
  };

  const filterClick = (key, value) => {
    pushQuery(key, value);
    track.sendEvent('search filter', 'select', {key: key, value: value});
  };

  const filterClose = (key) => {
    delete urlParams[key];
    pushSearch(urlParams);
    track.sendEvent('search filter', 'close', {key: key});
  };

  const _handleListFilterClick = (key, value) => {
    const existingValues = (key in urlParams) ?
      new Set(Array.isArray(urlParams[key]) ?
        urlParams[key] : [urlParams[key]])
      : new Set();
    if (existingValues.has(value)) {
      existingValues.delete(value);
      track.sendEvent('search filter', 'close', {key: key});
    } else {
      existingValues.add(value);
      track.sendEvent('search filter', 'select', {key: key, value: value});
    }
    urlParams[key] = Array.from(existingValues);
  };

  const _handleSingleFilterClick = (key, value) => {
    if (key in urlParams) {
      if (urlParams[key] === value) {
        delete urlParams[key];
        track.sendEvent('search filter', 'close', {key: key});
      } else {
        urlParams[key] = value;
        track.sendEvent('search filter', 'select', {key: key, value: value});
      }
    } else {
      urlParams[key] = value;
      track.sendEvent('search filter', 'select', {key: key, value: value});
    }
  };

  // It is a updated/new handle filter click function. Use this one for newer implementations.
  // This function takes care of both click/un-click functionality in single function.
  const handleFilterClick = (key, value) => {
    LIST_FILTERS.has(key) ? _handleListFilterClick(key, value) : _handleSingleFilterClick(key, value);
    pushSearch(urlParams);
  };

  const searchSubmit = (value) => {
    pushQuery('search_text', value);
    track.sendEvent('search text input', 'submit', {value: value});
  };

  const setLocation = (value) => {
    pushQuery('service_location', value);
    track.sendEvent('search location input', 'submit', {value: value});
  };

  const subtitle = `Found ${providers.total} results.`;
  const title = makeSubtitle(urlParams);

  React.useEffect(() => {
    getData();

    let _urlParams = {...urlParams};
    delete _urlParams['page'];
    if (!_.isEqual(_urlParams, urlParamsForMap)) {
      setUrlParamsForMap(_urlParams);
    }

  }, [location]); // eslint-disable-line

  React.useEffect(() => {
    if (!isLoading) {
      const providerUuids = providers.data.map(item => item.uuid);
      track.sendEvent('provider search results', 'view', {
        page: page,
      }, providerUuids);
    }
  }, [providers, isLoading]); // eslint-disable-line

  // Makes sure the profile is refreshed
  useEffect(() => {
    dispatch(AuthStore.refreshProfile());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" fixed disableGutters={true}>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <FilterPanel
              filterClick={filterClick}
              filterClose={filterClose}
              searchSubmit={searchSubmit}
              params={urlParams}
              setLocation={setLocation}
              handleFilterClick={handleFilterClick}
              excludedServices={excludedServices}
            />
            {externalDirectoryMode && (
              <div className={classes.poweredBy}>
                <div>
                  Powered by
                </div>
                <div>
                  <a href="https://www.clickability.com.au" target="_blank" rel="noopener noreferrer">
                    <img src={logo} width="200px" alt="Clickability"/>
                  </a>
                </div>
              </div>
            )}
          </Grid>
          <Grid item md={9} ref={scrollRef}>
            {!externalDirectoryMode && <DirectoryAdCTA />}
            <DirectoryMap
              providers={providersMapData}
              isLoading={isMapLoading}
              setLocation={setLocation}
              onClear={() => filterClose('service_location')}
              param={urlParams.service_location}
            />
            <SectionTitle title={title} variant="h1" className={classes.title}/>
            <Typography variant="subtitle2">{subtitle}</Typography>
            <FilterDisplay
              params={urlParams}
              handleFilterClick={handleFilterClick}
            />
            <TabsPanel
              providers={providers}
              isLoading={isLoading}
              externalDirectoryMode={externalDirectoryMode}
              onPageChange={onPageChange}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );

};

Search.propTypes = {
  location: PropTypes.object.isRequired,
  externalDirectoryMode: PropTypes.bool,
};

export default Search;
