import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { ReactComponent as SearchIcon } from '../../../icons/search.svg';

import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    zIndex: 1,
    padding: '2rem 0',
  },
  bar: {
    height: '40px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    border: `1px solid ${theme.palette.common.nightBlue}`,
    backgroundColor: theme.palette.common.white,
    paddingLeft: '0.5rem',
    paddingRight: '0.8125rem',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    '&>input::placeholder': {
      color: theme.palette.text.primary,
      opacity: 1,
    },
  },
  button: {
    cursor: 'pointer',
    color: theme.palette.common.nightBlue,
  }
}));

const BlogSearchBar = ({initialQuery='', onSubmit}) => {
  const [query, setQuery] = useState(initialQuery);

  const submit = () => {
    if (onSubmit && query !== '') {
      onSubmit(query);
    }
  };

  const onKeyUp = (event) => {
    if (event.keyCode === 13) {
      submit();
    }
  };


  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.bar}>
        <InputBase
          className={classes.input}
          placeholder="Search our blog"
          inputProps={{ 'aria-label': 'search-blog' }}
          value={query}
          onChange={event => setQuery(event.target.value)}
          onKeyUp={onKeyUp}
        />
        <SearchIcon onClick={submit} className={classes.button}/>
      </div>
    </div>
  );
};

BlogSearchBar.propTypes = {
  initialQuery: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default BlogSearchBar;
