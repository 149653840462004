import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import styled from 'styled-components';
import { Button } from 'antd';

import ParticipantSelector from './components/ParticipantSelector';
import ServiceSelector from './components/ServiceSelector';
import AdditionalQuestionFlow from './components/AdditionalQuestionFlow';
import Exit from './components/Exit';
import ConnectDashboard from '../../components/ConnectDashboard';
import { NAV_SIZE_MIN } from '../../components/constants';
import { checkServiceAvailability } from 'modules/brokerageFlow/flows/utils';
import { useEmailVerificationCheck } from '../../components/utils';


const Main = styled.div`
  .container {
    column-gap: 0;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;


const ServiceRequest = () => {

  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState({});
  const [tempNavSize, setTempNavSize] = useState();
  const [isContinueButtonLoading, setIsContinueButtonLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const service = data.service ? data.service : {};
  const participant = data.participant ? data.participant : {};

  useEmailVerificationCheck();

  const handleParticipantData = data => {
    setData(prevState => ({...prevState, participant: data}));
  };

  const handleServiceData = data => {
    setData(prevState => ({...prevState, service: data}));
  };

  const handleCheckServiceContinue = () => {
    setIsContinueButtonLoading(true);
    checkServiceAvailability(
      [{name: service.serviceName}], participant.planType, participant.location,
      participant.telehealth, participant.ageGroup)
      .then(isServiceAvailable => {
        if (isServiceAvailable) {
          // Moves to Dashboard Service Request Flow step
          setCurrentStep(prevState => prevState + 2);
          setTempNavSize(NAV_SIZE_MIN);
        } else {
          // Moves to Exit step
          setCurrentStep(prevState => prevState + 1);
        }
      })
      .catch(err => console.error(err))   // eslint-disable-line no-console
      .finally(() => setIsContinueButtonLoading(false));
  };

  const stepData = [
    {
      render: (
        <ParticipantSelector
          defaultData={data.participant}
          handleData={handleParticipantData}
        />
      ),
      isCompleted: !!data.participant,
    },
    {
      render: (
        <ServiceSelector
          defaultData={data.service}
          handleData={handleServiceData}
        />
      ),
      isCompleted: !!data.service,
      onContinue: handleCheckServiceContinue,
    },
    {
      render: <Exit />,
      isCompleted: !!data.service,
      hideButton: true,
    },
    {
      render: (
        <AdditionalQuestionFlow
          participantData={data.participant}
          serviceData={data.service}
        />
      ),
      hideButton: true,
    },
  ];

  useEffect(() => {
    currentStep !== stepData.length - 1 && !!location.hash &&
    history.replace(location.pathname, location.state);
  }, []);

  useEffect(() => {
    const _participantData = location.state?.participant;
    if (!_participantData) return;

    handleParticipantData(_participantData);
    setCurrentStep(1);
    history.replace(location.pathname);
  }, [location.state]);

  const step = stepData[currentStep];

  return (
    <Main>
      <ConnectDashboard
        title="Service Request"
        style={{margin: 0, padding: 0}}
        navSize={tempNavSize}
        containerClass="container"
      >
        <Container>
          {step.render}
          {!step.hideButton && (
            <ButtonContainer>
              <Button
                style={{marginTop: '20px'}}
                size="large"
                type="primary"
                disabled={currentStep === 0}
                onClick={() => setCurrentStep(prevState => prevState - 1)}
              >
                Back
              </Button>
              <Button
                style={{marginTop: '20px'}}
                size="large"
                type="primary"
                disabled={!step.isCompleted || isContinueButtonLoading}
                loading={isContinueButtonLoading}
                onClick={() => {
                  step.onContinue ?
                    step.onContinue() :
                    setCurrentStep(prevState => prevState + 1);
                }}
              >
                Continue
              </Button>
            </ButtonContainer>
          )}
        </Container>
      </ConnectDashboard>
    </Main>
  );
};

export default ServiceRequest;