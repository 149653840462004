import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../../../components/Markdown';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles({
  container: {
  },
  body: {
    '& img': {
      maxWidth: '100%',
      margin: '1rem',
    },
  },
  heroImage: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
    margin: 0,
    maxHeight: '30rem',
    marginBottom: 44,
  },
});

const BlogPostContent = ({content}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={content.featured_image} alt="" className={classes.heroImage}/>
      <Typography variant="body1" component="div" className={classes.body}>
        <Markdown content={content.body}/>
      </Typography>
    </div>
  );
};

BlogPostContent.propTypes = {
  content: PropTypes.shape({
    featured_image: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  })
};

export default BlogPostContent;
