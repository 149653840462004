import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { hasPermission } from 'lib/permissions';


export const hasPagePermission = (profile, providerUuid, permission) => {

  if (!profile?.username) return false;

  if (profile.is_site_admin) return true;

  const accessMap = profile.roles.reduce((acc, item) => {
    return {...acc, [item.provider_uuid]: item.has_connect_access};
  }, {});

  return accessMap[providerUuid] && (!permission ||
    hasPermission(profile, providerUuid, permission));
};

export const useEmailVerificationCheck = () => {
  const history = useHistory();
  const profile = useSelector(state => state.auth.profile);
  useEffect(() => {
    if (!profile?.activated) {
      history.push('/connect/dashboard');
    }
  }, [profile?.activated]);
};
