import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Page from '../../components/Page.js';
import Container from '@material-ui/core/Container';
import ParticipantLoginForm from './components/ParticipantLoginForm';
import { AuthAPI } from '../../api';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import backgroundImg from '../../assets/login-background.svg';

const useStyles = makeStyles(theme => ({
  background: {
    padding: '5rem 0 0 0',
    width: '100%',
    height: '56rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'contain',
    backgroundImage: `url(${backgroundImg})`,
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 0 0 0',
    },
  },
  circle: {
    borderRadius: '50%',
    width: '46.4rem',
    height: '46.4rem',
    marginTop: '-3rem',
    marginRight: '5rem',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      borderRadius: 0,
      padding: 0,
      width: '100%',
      height: 'auto',
      border: 'none',
    },
  },
  content: {
    width: '28rem',
    height: '38rem',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

const DEFAULT_PATH = '/profile/home';

// If path starts with a forbiddenPrefix return defaultPath, otherwise return path
const cleanRedirect = (path, defaultPath) => {
  const forbiddenPaths = ['/login', '/logout'];
  if (forbiddenPaths.some(forbiddenPath => path.startsWith(forbiddenPath))) {
    return defaultPath;
  }
  return path;
};

const ParticipantLoginPage = ({isAuthenticated}) => {
  const location = useLocation();
  // On successful login, we redirect to DEFAULT_PATH
  // if location.state.redirectTo is set to '/' or not defined.
  const redirectTo = location.state && location.state.redirectTo && location.state.redirectTo !== '/' ? location.state.redirectTo : DEFAULT_PATH;
  const [redirectPath] = useState(redirectTo);  // use state because we only want this set on first render.
  const classes = useStyles();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const username = query.get('username');
  // We may be already logged in, or logged in via another tab (via authSync),
  // in which case we should continue with our redirect.
  useEffect(() => {
    if (isAuthenticated) {
      history.push(cleanRedirect(redirectPath, DEFAULT_PATH));
    }
  }, [isAuthenticated, redirectPath, history]);

  return (
    <Page title="Clickability Login">
      <div className={classes.background}>
        <Container>
          <div className={classes.circle}>
            <div className={classes.content}>
              <ParticipantLoginForm
                // doesn't pass onComplete; isAuthenticated will trigger redirect on success.
                initialUsername={username}
                loginApi={AuthAPI.login}
                recoveryLink="/password/recover"
                signUpLink="/signup"
                title="Log in to Clickability"
                oauthState={{redirectTo}}
              />
            </div>
          </div>
        </Container>
      </div>
    </Page>
  );
};

ParticipantLoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(ParticipantLoginPage);
