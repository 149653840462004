
import { freeTextValidator, dateValidator, isService } from '../../flows/utils';
import {
  DROPDOWN_OPTION_DAILY,
  DROPDOWN_OPTION_FORTNIGHTLY,
  DROPDOWN_OPTION_ONE_OFF,
  DROPDOWN_OPTION_ONE_OFF_ASSESSMENT,
  DROPDOWN_OPTION_WEEKLY
} from '../../constants';
import Textbox from '../questionComponents/Textbox';
import Dropdown from '../questionComponents/Dropdown';
import Datepicker from '../questionComponents/Datepicker';
import MultipleChoice from '../questionComponents/MultipleChoice';


export const SW_GARDENING_CLEANING_QUESTIONS = [
  {
    field: 'serviceFrequency',
    Component: Dropdown,
    title: 'How often would you like this service?',
    options: [
      DROPDOWN_OPTION_DAILY,
      DROPDOWN_OPTION_WEEKLY,
      DROPDOWN_OPTION_FORTNIGHTLY,
      DROPDOWN_OPTION_ONE_OFF
    ],
    otherOption: true,
    isValid: answer => !!answer,
  },
  {
    field: 'startDate',
    Component: Datepicker,
    title: 'What is the preferred start date for this service?',
    disablePast: true,
    isValid: dateValidator,
  },
  {
    field: 'workerPreference',
    Component: Textbox,
    title: 'Do you have any preference in type of worker?',
    placeholder: 'E.g. gender, specialist knowledge, language spoken',
    isValid: () => true,
    isOptional: () => true,
  },
];

export const AH_QUESTIONS = [
  {
    field: 'serviceFrequency',
    Component: Dropdown,
    title: 'How often would you like this service?',
    options: [
      DROPDOWN_OPTION_DAILY,
      DROPDOWN_OPTION_WEEKLY,
      DROPDOWN_OPTION_FORTNIGHTLY,
      DROPDOWN_OPTION_ONE_OFF_ASSESSMENT
    ],
    otherOption: true,
    isValid: answer => !!answer,
  },
  // Only visible when the service is Occupational Therapy and service frequency
  // 'One-off Assessment' is selected
  {
    field: 'typeOfAssessment',
    Component: Dropdown,
    title: 'What type of Assessment do you need?',
    options: [
      'Functional Capacity',
      'Home Modifications',
      'Assistive Technology (AT)',
      'Supported Independent Living (SIL)',
      'Functional Behaviour Assessment',
      'Specialist Disability Accommodation (SDA)'
    ],
    otherOption: true,
    isHidden: answer => (!isService(answer, 'Occupational Therapy') ||
      answer.occupationalTherapyServiceFrequency !== DROPDOWN_OPTION_ONE_OFF_ASSESSMENT),
    isValid: answer => !!answer,
  },
  ...SW_GARDENING_CLEANING_QUESTIONS.slice(1),
];

export const SC_QUESTIONS = [
  {
    field: 'startDate',
    Component: Datepicker,
    disablePast: true,
    title: 'What is the preferred start date for this service?',
    isValid: dateValidator,
  },
  {
    field: 'receivedSimilarServicesBefore',
    Component: MultipleChoice,
    options: ['Yes', 'No'],
    title: 'Have you received these or similar services before?',
    isValid: answer => !!answer,
  },
  {
    field: 'alreadySpentBudget',
    Component: MultipleChoice,
    options: ['Yes', 'No'],
    title: 'Has some of your current budget already been spent on this service?',
    isValid: answer => !!answer,
    isHidden: answer => answer?.supportCoordinationReceivedSimilarServicesBefore !== 'Yes',
  },
  {
    field: 'workerPreference',
    Component: Textbox,
    title: 'Do you have any preference in type of worker?',
    placeholder: 'E.g. gender, specialist knowledge, language spoken',
    isValid: freeTextValidator,
  },
];

export const PM_QUESTIONS = [
  {
    field: 'specificOffer',
    Component: Textbox,
    title: 'Is there anything specific you want the Plan Manager to offer?',
    placeholder: 'E.g. Availability of a Participant App',
    isOptional: () => true,
    isValid: () => true,
  },
];

export const GENERAL_QUESTIONS = [
  {
    field: 'AdditionalInformation',
    Component: Textbox,
    title: 'Additional information specific to your request that will assist the provider',
    placeholder: 'E.g. Availability of a Participant App',
    isOptional: () => true,
    isValid: (answer) => !answer || answer.length <= 500,
    isMultiline: true,
    showCharacters: true,
    errorMessage: 'The entered text is longer than 500 characters',
    helperText: (answer) => `${answer.length} / 500 characters`
  },
];