import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobileAndroid, faClock } from '@fortawesome/pro-solid-svg-icons';

import DateTimeStatus from 'components/DateTimeStatus';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'rgb(145 158 171 / 15%) 0px 0px 4px 0px, rgb(145 158 171 / 15%) 2px -2px 20px 0px',
    borderRadius: '12px',
    border: '1px solid',
    padding: '25px 30px',
    borderColor: theme.palette.common.lightBorder,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '15px 20px',
    },
  },
  textContainer: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      marginBottom: '20px',
    },
  },
  headerText: {
    fontSize: '16px',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  bodyText: {
    color: theme.palette.primary.main,
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '45px',
    width: '365px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      columnGap: '30px',
    },
  },
  dataItem: {
    width: '80px',
  },
  datItemUpdated: {
    marginLeft: '-10px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
    },
  },
  dataItemLabel: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    fontSize: '15px',
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  headerItemLabelIcon: {
    marginTop: '-2px',
    fontSize: '16px',
  },
}));

const Notification = (
  {
    title,
    body,
    email,
    sms,
    updatedAt,
    onEmailCheck,
    onSMSCheck,
    smsDisabled,
    emailDisabled
  }) => {

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isEmailChecked, setIsEmailChecked] = useState(!!email);
  const [isSMSChecked, setIsSMSChecked] = useState(!!sms);

  useEffect(() => {
    setIsEmailChecked(!!email);
  }, [email]);

  useEffect(() => {
    setIsSMSChecked(!!sms);
  }, [sms]);

  const _handleCheck = (value, setterCb, checkerCb) => {
    setterCb(value);
    checkerCb(value);
  };

  const handleEmailChange = value => {
    _handleCheck(value, setIsEmailChecked, onEmailCheck);
  };

  const handleSMSChange = value => {
    _handleCheck(value, setIsSMSChecked, onSMSCheck);
  };

  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        <div className={classes.headerText}>
          {title}
        </div>
        <div className={classes.bodyText}>
          {body}
        </div>
      </div>
      <div className={classes.dataContainer}>
        <div className={classes.dataItem}>
          <FormControlLabel
            control={
              <Switch
                checked={isEmailChecked}
                onChange={(event) => handleEmailChange(event.target.checked)}
                color="primary"
                name="Email"
                disabled={!!emailDisabled}
              />
            }
            label={isEmailChecked ? 'On' : 'Off'}
          />
          {isMobile && (
            <div className={classes.dataItemLabel}>
              <FontAwesomeIcon icon={faEnvelope} className={classes.headerItemLabelIcon}/>
              <div>Email</div>
            </div>
          )}
        </div>
        <div className={classes.dataItem}>
          <FormControlLabel
            control={
              <Switch
                checked={isSMSChecked}
                onChange={(event) => handleSMSChange(event.target.checked)}
                color="primary"
                name="Sms"
                disabled={!!smsDisabled}
              />
            }
            label={isSMSChecked ? 'On' : 'Off'}
          />
          {isMobile && (
            <div className={classes.dataItemLabel}>
              <FontAwesomeIcon icon={faMobileAndroid} className={classes.headerItemLabelIcon}/>
              <div>SMS</div>
            </div>
          )}
        </div>
        <div className={classNames(classes.dataItem, classes.datItemUpdated)}>
          <DateTimeStatus value={updatedAt} />
          {isMobile && (
            <div className={classes.dataItemLabel} style={{marginTop: '10px'}}>
              <FontAwesomeIcon icon={faClock} className={classes.headerItemLabelIcon}/>
              <div>Updated</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

};

Notification.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  email: PropTypes.bool,
  sms: PropTypes.bool,
  updatedAt: PropTypes.string,
  onEmailCheck: PropTypes.func,
  onSMSCheck: PropTypes.func,
  smsDisabled: PropTypes.bool,
  emailDisabled: PropTypes.bool,
};

export default Notification;