import {
  AutoComplete as AntdAutoComplete,
  Button as AntdButton
} from 'antd';
import { ProviderAPI, TaxonomyAPI, useGetApi } from 'providerSite/api';
import React, { useState } from 'react';
import { faCaretDown, faLocationDot } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoBar from '../connectDashboard/pages/ServiceRequest/components/InfoBar';
import PropTypes from 'prop-types';
import QualifiedServiceIcon from 'components/QualifiedServiceIcon';
import Spinner from 'components/Spinner';
import config from 'config';
import logo from 'assets/nav-logo.svg';
import style from 'style';
import styled from 'styled-components';
import {useMediaQuery} from 'react-responsive';

const DOMAIN_PREFIX = 'mpm';

const ROYAL_BLUE_COLOR = '#1A3092';
const TEXTBOX_HEIGHT = '42px';
const FONT_SIZE = '15px';
const MOBILE_SCREEN_SIZE = '500px';

const EXCLUDED_SERVICES = new Set([
  'Plan Management'
]);

const FREQUENT_SERVICES = [
  'Psychology',
  'Occupational Therapy',
  'Support Work',
];

const Container = styled.div`
  height: 600px;
  width: 100%;
  background-color: #FAE2D5;
  display: flex;
  justify-content: center;
  padding: 25px 20px 25px 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 440px;
`;

const Title = styled.div`
  color: ${ROYAL_BLUE_COLOR};
  font-weight: 500;
  font-size: 26px;
  @media (max-width: ${MOBILE_SCREEN_SIZE}){
    text-align: center;
  }
`;

const SubTitle = styled.div`
  color: ${style.colors.palette.battleshipGrey};
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 8px;
  @media (max-width: ${MOBILE_SCREEN_SIZE}) {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 2px;
  }
`;

const TextboxContainer = styled.div`
  position: relative;
  
  .icon {
    color: ${ROYAL_BLUE_COLOR};
    position: absolute;
    right: 15px;
    pointer-events: none;
  }
  .service-icon {
    font-size: 20px;
    top: 10px;
  }
  .location-icon {
    font-size: 18px;
    top: 10px;
  }
`;

const Footer = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${ROYAL_BLUE_COLOR};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;
  margin-top: auto;
  
  .tagline {
    text-align: center;
    @media (max-width: ${MOBILE_SCREEN_SIZE}){
      font-size: 10px;
    }
  }
`;

const AutoComplete = styled(AntdAutoComplete)`
  height: ${TEXTBOX_HEIGHT};
  font-size: ${FONT_SIZE};
  .ant-select-selector {
    height: ${TEXTBOX_HEIGHT} !important;
    border-color: ${ROYAL_BLUE_COLOR} !important;
  }
  .ant-select-selection-search-input {
    height: ${TEXTBOX_HEIGHT} !important;
  }
  .ant-select-selection-placeholder {
    line-height: ${TEXTBOX_HEIGHT} !important;
    color: ${style.colors.palette.battleshipGrey};
  }
`;

const Button = styled(AntdButton)`
  background-color: ${ROYAL_BLUE_COLOR};
  border-color: ${ROYAL_BLUE_COLOR};
  :disabled {
    color: ${style.colors.palette.white};
    background-color: ${ROYAL_BLUE_COLOR};
    border-color: ${ROYAL_BLUE_COLOR};
    :hover {
      background-color: ${ROYAL_BLUE_COLOR};
      color: ${style.colors.palette.white};
      border-color: ${ROYAL_BLUE_COLOR};
    }
  }
`;

const TagListContainer = styled.div`
  display: flex;
  column-gap: 10px;
  width: 100%;
`;

const TagHeader = styled.div`
  width: 100%;
  color: ${style.colors.palette.battleshipGrey};
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 7px;
`;

const InfoBarStyled = styled(InfoBar)`
  margin-top: 10px;
  background: rgba(245, 245, 245, .50);
  border: 1px solid #a8aeca;
  box-shadow: ${style.shadow(5)};

  .text-container {
    font-size: 11px;
  }
`;

const MpmSearch = () => {

  const isMobile = useMediaQuery({query: `(max-width: ${MOBILE_SCREEN_SIZE})`});

  const [serviceData, setServiceData] = useState();
  const [serviceValue, setServiceValue] = useState(null);

  const [locationValue, setLocationValue] = useState();
  const [locationQuery, setLocationQuery] = useState(' ');
  const [isLocationSelected, setIsLocationSelected] = useState();

  const {
    data: _serviceTaxonomy,
    isLoading: isLanguageTaxonomyLoading
  } = useGetApi(TaxonomyAPI.GET_TAXONOMY, {
    queryParams: {
      name: 'qualified_service',
    },
    staleTime: 60,   // 1 hour
  });

  _serviceTaxonomy?.sort((a, b) => {
    const serviceA = a.serviceName.toUpperCase();
    const serviceB = b.serviceName.toUpperCase();
    if (serviceA < serviceB) return -1;
    if (serviceA > serviceB) return 1;
    return 0;
  });

  const _getKey = index => `Option Row - ${index}`;
  const { serviceTaxonomy, serviceTaxonomyMap } = _serviceTaxonomy?.reduce((acc, item, index) => {
    if (EXCLUDED_SERVICES.has(item.serviceName)) return acc;

    acc.serviceTaxonomy.push({
      label: (
        <Row
          key={_getKey(index)}
          name={item.serviceName}
        />
      ),
      value: item.serviceName,
      data: item,
    });
    acc.serviceTaxonomyMap[item.serviceName] = item;
    return acc;

  }, {serviceTaxonomy: [], serviceTaxonomyMap: {}}) || {};

  const { data: _locationData } = useGetApi(ProviderAPI.GET_LOCATION_AUTOCOMPLETE, {
    queryParams: {
      query: locationQuery,
      limit: 10,
    },
  });

  const handleServiceChange = value => {
    setServiceData(value);
    setServiceValue(null);
  };

  const handleServiceSelect = (value, option) => {
    setServiceData(value);
    setServiceValue(option.data);
  };

  const handleServiceLeaving = () => {
    serviceValue === null && setServiceData(null);
  };

  const handleServiceFocus = () => {
    serviceValue === null && setServiceData(' ');
  };

  const handleTagSelect = serviceName => {
    setServiceData(serviceName);
    setServiceValue(serviceTaxonomyMap[serviceName]);
  };

  const locationData = _locationData?.map(item => ({value: item}));

  const onLocationSearch = searchText => {
    if (!searchText) {
      setLocationQuery(' ');
      return;
    }
    setLocationQuery(searchText);
  };

  const handleLocationChange = value => {
    setLocationValue(value);
    setIsLocationSelected(false);
  };

  const handleLocationSelect = value => {
    setLocationValue(value);
    setIsLocationSelected(true);
  };

  const handleLocationLeaving = () => {
    isLocationSelected === false && setLocationValue(null);
  };

  const handleFilterOption = (inputValue, option) => (
    !!option?.value.toLowerCase()?.includes(inputValue?.trim()?.toLowerCase())
  );

  if (isLanguageTaxonomyLoading)
    return (
      <Container>
        <Content style={{justifyContent: 'center'}}>
          <Spinner />
        </Content>
      </Container>
    );

  return (
    <Container>
      <Content>
        <div>
          <Title>
            Find your perfect service provider
          </Title>
          <SubTitle>
            Clickability&apos;s free provider directory will get you from diagnosis
            (Psychology) to ongoing support (Occupational Therapy) and match you
            with accredited providers near you.
          </SubTitle>
        </div>
        <TextboxContainer>
          <AutoComplete
            value={serviceData}
            options={serviceTaxonomy}
            style={{width: '100%'}}
            size="large"
            onChange={handleServiceChange}
            onSelect={handleServiceSelect}
            onBlur={handleServiceLeaving}
            onFocus={handleServiceFocus}
            filterOption={handleFilterOption}
            placeholder="Select your service"
          />
          <FontAwesomeIcon className="icon service-icon" icon={faCaretDown} />
        </TextboxContainer>
        <div style={{marginBottom: '10px'}}>
          <TagHeader>Commonly requested</TagHeader>
          <TagListContainer>
            {FREQUENT_SERVICES.slice(0, (isMobile ? - 1 : FREQUENT_SERVICES.length))
              .map((item, index) => (
                <Tag key={`frequent-service-${index}`} name={item} onClick={handleTagSelect}/>
              ))}
          </TagListContainer>
        </div>
        <TextboxContainer style={{marginBottom: '20px'}}>
          <AutoComplete
            value={locationValue}
            options={locationData}
            style={{width: '100%'}}
            size="large"
            onSearch={onLocationSearch}
            onChange={handleLocationChange}
            onSelect={handleLocationSelect}
            onBlur={handleLocationLeaving}
            placeholder="Postcode"
          />
          <FontAwesomeIcon className="icon location-icon" icon={faLocationDot} />
        </TextboxContainer>
        <Button
          type="primary"
          size="large"
          disabled={!serviceValue || !locationValue}
          onClick={() => window.open(
            `https://${DOMAIN_PREFIX}.${config.website.URI}#service?name=${serviceValue?.serviceName}&location=${locationValue}`,
            '_blank')}
        >
          Find trusted providers
        </Button>
        {!isMobile && (
          <InfoBarStyled
            text="Clickability can connect you with available services.
            Please select which service you are after and we will connect
            you with providers."
          />
        )}
        <Footer>
          <div className="tagline">
            Provider search powered by
          </div>
          <img src={logo} width="180px" alt="clickability" />
          <div className="tagline">
            Australia’s largest network of Disability and Allied Health providers
          </div>
        </Footer>
      </Content>
    </Container>
  );
};

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 38px;
  
  .icon {
    width: 20px;
    color: ${ROYAL_BLUE_COLOR};
  }
`;

const Row = ({ name }) => {
  return (
    <RowContainer>
      <QualifiedServiceIcon.Regular serviceName={name} className="icon" />
      <div>{name}</div>
    </RowContainer>
  );
};

Row.propTypes = {
  name: PropTypes.string,
};

const TagContainer = styled.div`
  background: rgba(26, 48, 146, 0.10);
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid rgba(26, 48, 146, 0.25);
  box-shadow: ${style.shadow(15)};
  color: ${ROYAL_BLUE_COLOR};
  font-weight: 500;
  font-size: 11px;
  cursor: pointer;
  white-space: nowrap;
  flex-grow: 1;
  :hover {
    background: rgba(26, 48, 146, 0.20);
  }
  .icon {
    margin-top: -3px;
    margin-right: 7px;
    font-size: 13px;
  }
`;

const Tag = ({ name, onClick }) => {
  return (
    <TagContainer onClick={() => onClick(name)}>
      <QualifiedServiceIcon.Regular serviceName={name} className="icon" />
      {name}
    </TagContainer>
  );
};

Tag.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
};


export default MpmSearch;

