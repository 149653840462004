export const NAV_BAR_HEIGHT = '80px';

// Service Request Events
export const SERVICE_REQUEST_EVENT_OPEN = 'open';
export const SERVICE_REQUEST_EVENT_CLOSE = 'close';
export const SERVICE_REQUEST_EVENT_ACCEPT = 'accept';
export const SERVICE_REQUEST_EVENT_DECLINE = 'decline';
export const SERVICE_REQUEST_EVENT_EXPIRE = 'expire';
export const SERVICE_REQUEST_EVENT_CONTACT = 'contact';
export const SERVICE_REQUEST_EVENT_CANCEL = 'cancel';
export const SERVICE_REQUEST_EVENT_SEARCH = 'search';
export const SERVICE_REQUEST_EVENT_IN_PROGRESS = 'in_progress';
export const SERVICE_REQUEST_EVENT_COMPLETE = 'complete';
export const SERVICE_REQUEST_EVENT_RESERVE = 'reserve';

// Service Request Statuses
export const SERVICE_REQUEST_STATUS_NEW = 'new';
export const SERVICE_REQUEST_STATUS_OPEN = 'open';
export const SERVICE_REQUEST_STATUS_ACCEPTED = 'accepted';
export const SERVICE_REQUEST_STATUS_DECLINED = 'declined';
export const SERVICE_REQUEST_STATUS_CLOSED = 'closed';
export const SERVICE_REQUEST_STATUS_EXPIRED = 'expired';
export const SERVICE_REQUEST_STATUS_CANCELLED = 'cancelled';
export const SERVICE_REQUEST_STATUS_CONTACTED = 'contacted';
export const SERVICE_REQUEST_STATUS_IN_PROGRESS = 'in_progress';
export const SERVICE_REQUEST_STATUS_COMPLETE = 'complete';
export const SERVICE_REQUEST_STATUS_SUCCESSFUL = 'successful';
export const SERVICE_REQUEST_STATUS_UNSUCCESSFUL = 'unsuccessful';
