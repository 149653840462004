import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { faBars, faBarsStaggered } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import { Drawer as AntdDrawer } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthMenu from './AuthMenu';
import config from 'config';
import { getHexWithOpacity } from 'lib/util';
import logo from 'assets/logo-white.png';
import style from 'style';


const Main = styled.div`
  width: 15%;
  .iconButton {
    color: ${style.colors.palette.white};
    font-size: 20px;
  }
`;

const Drawer = styled(AntdDrawer)`
  top: ${style.constants.navBarHeight} !important;
  .ant-drawer-content-wrapper {
    width: 230px !important;
  }
  .ant-drawer-body {
    background: ${style.colors.primary};
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`;

const MenuItem = styled.div`
  color: ${style.colors.palette.white};
  font-weight: 600;
  padding: 15px 20px;
  cursor: pointer;
  &:hover {
    background: ${getHexWithOpacity(style.colors.palette.fauxIndigo, 40)};
  }
`;

const MenuItemBlank = styled.div`
  padding: 15px 20px;
  display: flex;
  justify-content: center;
`;

const LogoContainer = styled.div`
  margin-top: auto;
  height: 115px;
  width: 100%;
  padding-left: 15px;
  color: ${style.colors.palette.white};
`;

const MobileMenu = ({ userProfile }) => {

  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const isAuthenticated = !!userProfile.is_provider;

  const handleDrawerClick = () => setVisible(prevState => !prevState);
  const onClose = () => setVisible(false);

  return (
    <Main>
      <FontAwesomeIcon
        icon={visible ? faBarsStaggered : faBars}
        className="iconButton"
        onClick={handleDrawerClick}
      />
      <Drawer
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
        getContainer={false}
      >
        <MenuItem onClick={() => window.open(config.link.providerFaq)}>
          Faq
        </MenuItem>
        <MenuItem onClick={() => history.push('/contact')}>
          Contact us
        </MenuItem>
        {!isAuthenticated && (
          <MenuItemBlank>
            <AuthMenu hideProfile={isAuthenticated} userProfile={userProfile}/>
          </MenuItemBlank>
        )}
        <LogoContainer>
          <img
            src={logo}
            alt="Clickability"
            width="100px"
            style={{cursor: 'pointer'}}
            onClick={() => history.push('/provider')}
          />
          <div style={{fontSize: '10px', marginTop: '2px'}}>
            Clickability © 2019-2024. All Rights Reserved.
          </div>
        </LogoContainer>
      </Drawer>
    </Main>
  );
};

MobileMenu.propTypes = {
  userProfile: PropTypes.object,
};

export default MobileMenu;
