import styled from 'styled-components';
import style from 'style';

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${style.colors.lightBorder};
  border-radius: ${style.borderRadius.large};
  box-shadow: ${style.shadow(15)};
  padding: 5px 20px;
`;

const Column = styled.div`
  flex-basis: ${({ width }) => width};
  display: flex;
  align-items: center;
  min-height: 40px;
  position: relative;
`;

export {
  RowContainer,
  Column
};