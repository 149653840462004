import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassWaveform } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';


const Container = styled.div`
  padding: 7px 10px;
  border: 1px solid ${style.colors.info};
  border-radius: 12px;
  width: max-content;
  background: white;
  box-shadow: ${style.shadow(15)};
  color: ${style.colors.info};
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;

  :hover {
    background: ${style.colors.info};
    color: white;
    border-color: #1383dc;
  }

  .icon {
    margin-right: 7px;
    font-size: 14px;
  }
`;

const MoreServiceButton = ({ onClick, ...props }) => {

  return (
    <Container onClick={onClick} {...props}>
      <FontAwesomeIcon icon={faMagnifyingGlassWaveform} className="icon" beatFade />
      Search For More Services
    </Container>
  );
};

MoreServiceButton.propTypes = {
  onClick: PropTypes.func,
};


export default MoreServiceButton;

