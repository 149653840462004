import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { faMobileScreen, faShieldCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Button as AntdButton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import Recaptcha from 'react-recaptcha';
import { CircularProgress } from '@material-ui/core';

import Textbox from '../../components/Textbox';
import style from 'style';
import Spinner from 'components/Spinner';
import NotAvailable from '../components/NotAvailable';
import TextCloud from '../components/TextCloud';
import {
  EMAIL_FIELD,
  FINAL_DETAILS_FIELD,
  FIRST_NAME_FIELD,
  FOR_FIELD,
  LOCATION_FIELD,
  NDIS_PLAN_FIELD,
  ONLINE_SERVICE_FIELD,
  PARTICIPANT_AGE_RANGE_FIELD,
  PARTICIPANT_FIRST_NAME_FIELD,
  PHONE_FIELD,
  PROVIDER_COUNT_FIELD,
  PROVIDER_SEARCH_NAME_LOADING_FIELD,
  PROVIDER_SEARCH_NAMES_FIELD,
  RECAPTCHA_CODE_FIELD, SERVICE_FOR_TYPE_MYSELF,
  SERVICES_FOR_FIELD,
  SERVICES_NEEDED_FIELD,
  TELEHEALTH_FIELD,
} from '../../../constants';
import RecaptchaProvider from 'components/RecaptchaProvider';
import config from 'config';
import OTPModal from '../../DashboardFlow/components/OTPModal';
import * as track from 'lib/track';
import { ServiceRequestAPI } from 'api';
import SnackbarAlert from 'components/SnackbarAlert';
import { useMutateApi, ServiceRequestAPI as NewServiceRequestAPI } from 'providerSite/api';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
  width: 100%;
  opacity: ${({$isDomLoaded}) => $isDomLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;

  .tagcloud--item {
    color: #00d3c0;
    font-weight: 300;
    font-size: 14px;
    user-select: none;
    cursor: default;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));

    :nth-child(even) {
      color: transparent;
      text-shadow: 0 0 5px rgba(0, 211, 192, 0.7);
    }
  }

  .tagcloud {

  }

  .input {
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 2px;
    color: ${style.colors.textPrimary};
  }
`;

const Button = styled(AntdButton)`
  height: 50px;
  width: 100%;
  max-width: 300px;
  font-weight: 700;
  margin-top: -25px;
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-size: 22px;
  line-height: 22px;
  color: black;
  font-weight: 200;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
  
  .count {
    font-size: 30px;
    font-weight: 700;
    color: ${style.colors.secondary};
  }
`;

const Question = styled.div`
  font-size: 16px;
  font-weight: 400;
`;


const QuestionContainer = styled.div`
  display: flex; 
  align-items: center; 
  column-gap: 10px; 
  margin-bottom: 3px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
  
  .icon {
    font-size: 20px;
    margin-bottom: 2px;
    color: #1d5d8e;
  }
`;

const VerificationPage = ({ value, onChange, answers, setAnswers, source }) => {

  const reCaptchaRef = useRef();
  const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(true);
  const [isOPTSubmitLoading, setIsOPTSubmitLoading] = useState(false);
  const [isOTPOpen, setIsOTPOpen] = useState(false);
  const [submitError, setSubmitError] = useState();
  const [serviceInquiryUuid, setServiceInquiryUuid] = useState();
  const history = useHistory();

  const profile = useSelector(state => camelcaseKeys(state.auth.profile, {deep: true}));
  const userUuid = !!(profile && Object.keys(profile).length) && profile.userUuid;
  const accountType = profile && Object.keys(profile).length ?
    (profile.isProvider ? 'Provider' : 'Participant')
    : null;

  const isrDeleteMutation = useMutateApi(NewServiceRequestAPI.DELETE_INCOMPLETE_SERVICE_REQUEST, 'DELETE', {
    params: {
      sessionId: answers?._sr_session,
    },
  });

  const isLoading = answers?.[PROVIDER_SEARCH_NAME_LOADING_FIELD];

  const handleOnChange = value => {
    onChange(value);
  };

  // Updates reCAPTCHA answer
  const updateRecaptchaAnswer = value => (
    setAnswers(prevState => ({...prevState, [RECAPTCHA_CODE_FIELD]: value})));

  const handleOTPSuccess = () => {
    const data = {_sr_session: answers._sr_session};
    track.sendEvent('service request flow', 'complete', data);
    history.push(`/service-inquiry/${serviceInquiryUuid}`, {showAdditionalQuestions: true});
  };

  // Handles creating service request after passing the OTP verification
  const handleCreateRequest = otpCode => {
    const data = formatSubmitData();

    return ServiceRequestAPI.createRequest(
      snakecaseKeys({...data, otp: otpCode}, {deep: true}))
      .then(res => camelcaseKeys(res, {deep: true}))
      .then(res => setServiceInquiryUuid(res.payload.serviceInquiryUuid));
  };

  // Format data before sending it via API
  const formatSubmitData = () => ({
    services: answers[SERVICES_NEEDED_FIELD],
    [EMAIL_FIELD]: answers[SERVICES_FOR_FIELD][EMAIL_FIELD],
    [PHONE_FIELD]: answers[PHONE_FIELD],
    [LOCATION_FIELD]: answers[LOCATION_FIELD],
    [NDIS_PLAN_FIELD]: answers[FINAL_DETAILS_FIELD][NDIS_PLAN_FIELD],
    [PARTICIPANT_AGE_RANGE_FIELD]: answers[FINAL_DETAILS_FIELD][PARTICIPANT_AGE_RANGE_FIELD],
    [SERVICES_FOR_FIELD]: answers[SERVICES_FOR_FIELD][FOR_FIELD],
    [FIRST_NAME_FIELD]: answers[SERVICES_FOR_FIELD][FIRST_NAME_FIELD],
    [PARTICIPANT_FIRST_NAME_FIELD]: (
      answers[SERVICES_FOR_FIELD][FOR_FIELD] === SERVICE_FOR_TYPE_MYSELF ?
        answers[SERVICES_FOR_FIELD][FIRST_NAME_FIELD] :
        answers[SERVICES_FOR_FIELD][PARTICIPANT_FIRST_NAME_FIELD]
    ),
    [TELEHEALTH_FIELD]: answers[TELEHEALTH_FIELD],
    [ONLINE_SERVICE_FIELD]: answers[ONLINE_SERVICE_FIELD],
    [PROVIDER_COUNT_FIELD]: answers[PROVIDER_COUNT_FIELD],
    account_uuid: answers.account_uuid ? answers.account_uuid : (userUuid ? userUuid : null),
    account_type: answers.account_type ? answers.account_type : accountType,
    source: source,
  });

  // Handles the action when the final Submit button is clicked
  const handleOtpSubmit = () => {
    if (isOPTSubmitLoading) return;

    setIsOPTSubmitLoading(true);
    setSubmitError(null);

    const data = {
      phoneNumber: answers[PHONE_FIELD],
      recaptchaCode: answers[RECAPTCHA_CODE_FIELD],
    };

    ServiceRequestAPI.sendOTP(snakecaseKeys(data, {deep: true}))
      .then(() => {
        setIsOTPOpen(true);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      })
      .catch(err => {
        console.error(err);   // eslint-disable-line no-console
        setSubmitError(err.body?.message);
      })
      .finally(() => setIsOPTSubmitLoading(false));
  };

  const handleOtpCancel = () => {
    reCaptchaRef.current.reset();
    updateRecaptchaAnswer(null);
    setIsOTPOpen(false);
  };

  if (isLoading)
    return <Spinner />;

  if (!answers?.[PROVIDER_SEARCH_NAMES_FIELD]?.length)
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{maxWidth: '700px'}}>
          <NotAvailable
            name={answers?.[SERVICES_FOR_FIELD]?.name}
            hidePrimaryButton
            onSecondaryButtonClick={() => history.push('/search')}
          />
        </div>
      </div>
    );

  return (
    <RecaptchaProvider>
      <Container $isDomLoaded={!isLoading}>
        <Title>
          <span className="count">{answers?.[PROVIDER_COUNT_FIELD]}</span> Potential Providers Found
        </Title>
        {!!answers?.[PROVIDER_SEARCH_NAMES_FIELD]?.length && <TextCloud texts={answers?.[PROVIDER_SEARCH_NAMES_FIELD]}/>}
        <div style={{maxWidth: '450px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <QuestionContainer>
            <FontAwesomeIcon swapOpacity icon={faMobileScreen} className="icon" />
            <Question>
              Mobile Phone Number for SMS Verification
            </Question>
          </QuestionContainer>
          <Textbox
            placeholder="0450 000 000"
            onChange={handleOnChange}
            defaultData={value?.name}
            validator={value => !!value && isValidPhoneNumber(value, 'AU')}
            errorMsg="Please enter a valid Australian phone number"
            inputClassName="input"
            inputMode="tel"
          />
          <div style={{marginTop: '17px', fontSize: '13px', color: '#8b8b8b'}}>
            <FontAwesomeIcon
              icon={faShieldCheck}
              style={{marginRight: '7px', fontSize: '16px', color: '#8de164'}}
            />
            SMS verification ensures you are a real person, not a bot
          </div>
        </div>
        <Recaptcha
          ref={reCaptchaRef}
          sitekey={config.settings.RECAPTCHA_KEY}
          render="explicit"
          onloadCallback={() => {
            updateRecaptchaAnswer(null);
            setIsRecaptchaLoading(false);
          }}
          verifyCallback={(code) => updateRecaptchaAnswer(code)}
          expiredCallback={() => updateRecaptchaAnswer(null)}
        />
        {isRecaptchaLoading && <CircularProgress size={30}/>}
        <Button
          type="primary"
          onClick={() => {
            handleOtpSubmit();
            isrDeleteMutation.mutate({});
          }}
          disabled={!value || !answers[RECAPTCHA_CODE_FIELD]}
          loading={isOTPOpen}
        >
          <FontAwesomeIcon
            beat
            icon={faMagnifyingGlass}
            style={{fontSize: '15px', marginRight: '10px', '--fa-animation-duration': '2s'}}
          />
          Start Matching Providers!
        </Button>
        <OTPModal
          isOpen={isOTPOpen}
          phoneNumber={answers[PHONE_FIELD]}
          onComplete={handleCreateRequest}
          onSuccess={handleOTPSuccess}
          onCancel={handleOtpCancel}
          onPhoneNumberClick={handleOtpCancel}
          cancelText="I didn’t receive it - Update Phone Number"
        />
        <SnackbarAlert
          open={!!submitError}
          message={submitError}
          handleClose={() => setSubmitError(null)}
          variant="error"
          autoHideDuration={10000}
        />
      </Container>
    </RecaptchaProvider>
  );
};

VerificationPage.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  answers: PropTypes.object,
  setAnswers: PropTypes.func.isRequired,
  source: PropTypes.string,
};

export default VerificationPage;