import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputContainer from './InputContainer';

const useStyles = makeStyles(() => ({
  checkbox: {
    '& .MuiSvgIcon-root': {
      width: '2rem',
      height: '2rem',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  }
}));

const MultiselectInput = ({ label, options, value=[], errorText, helperText, onChange, disabled=false }) => {
  const classes = useStyles();
  const [validationActive, setValidationActive] = useState(false);
  // update(key, selected) returns a new list of options that are selected by updating
  // value to add or remove the key depending on the value of selected.
  // The value list will be in the same order as the keys in options.
  const update = (key, selected) => Object.entries(options).reduce((a, [k]) =>
    ((k === key && selected) || (k !== key && value.includes(k)) ? [...a, k] : a), []);
  return (
    <InputContainer
      label={label}
      helperText={helperText}
      errorText={errorText}
      validationActive={validationActive}
    >
      <div className={classes.container}>
        {Object.entries(options).map(([key, optionLabel]) =>
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                color="secondary"
                checked={value.includes[key]}
                onChange={event=>onChange(update(key, event.target.checked))}
                disabled={disabled}
                inputProps={{ 'aria-label': label }}
                className={classes.checkbox}
                onBlur={()=>setValidationActive(true)}
              />
            }
            label={optionLabel}
          />
        )}
      </div>
    </InputContainer>
  );
};

MultiselectInput.propTypes = {
  label: PropTypes.node.isRequired,
  checkboxLabel: PropTypes.string,
  options: PropTypes.object.isRequired,
  value: PropTypes.array,
  errorText: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default MultiselectInput;
