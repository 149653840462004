import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';

import Page from 'providerSite/components/Page';
import Hero from 'providerSite/components/LandingPage/Hero';
import SectionOne from 'providerSite/components/LandingPage/SectionOneVariant';
import SectionTwo from 'providerSite/components/LandingPage/SectionTwo';
import Pricing from './components/PricingVariantOne';
import ServiceRequests from './components/ServiceRequests';
import Questions from './components/Questions';
import Bottom from './components/Bottom';
import heroImage from 'assets/provider-hero-image-v1.png';
import srDashboard from 'assets/service-request-dashboard.png';
import bottomImg from 'assets/connect-bottom.png';
import {
  UTM_CAMPAIGN_KEY,
  UTM_CONTENT_KEY,
  UTM_ID_KEY,
  UTM_MEDIUM_KEY,
  UTM_SOURCE_KEY,
  HICAPS_BILLER_ID_KEY,
} from 'providerSite/constants';
import Partners from 'components/Partners';


const Home = ({ topHeroText, bottomHeroText, secondaryLogo }) => {

  const query = new URLSearchParams(useLocation().search);
  const sectionTwoRef = useRef(null);
  const buttonText = 'Get Started For Free';

  const setSessionStorageValue = key => {
    const paramData = query.get(key);
    paramData && window.sessionStorage.setItem(key, `${paramData}`);
  };

  useEffect(() => {
    setSessionStorageValue(UTM_SOURCE_KEY);
    setSessionStorageValue(UTM_MEDIUM_KEY);
    setSessionStorageValue(UTM_CAMPAIGN_KEY);
    setSessionStorageValue(UTM_ID_KEY);
    setSessionStorageValue(UTM_CONTENT_KEY);
    setSessionStorageValue(HICAPS_BILLER_ID_KEY);
  }, []);

  return (
    <Page secondaryLogo={secondaryLogo} fullWidth>
      <Hero
        text={(
          <>
            {topHeroText && <div style={{fontSize: '40px', fontWeight: '700'}}>{topHeroText}</div>}
            <div>Helping you be found in the <strong>NDIS</strong>.</div>
            {bottomHeroText && <div style={{fontSize: '26px'}}>{bottomHeroText}</div>}
          </>
        )}
        img={heroImage}
        useIconBtn={true}
        useImageCover={false}
        buttonText={buttonText}
      />
      <Partners />
      <SectionOne
        title="Supercharge your disability business today"
      />
      <SectionTwo
        refProp={sectionTwoRef}
        title="Visibility. Leads. Advertising. Content."
        img={srDashboard}
        text={(
          <>
            Get accredited with Clickability so you don’t have to spend thousands
            on flyers, conferences, relationship building, SEO and advertising.
            <br /><strong>Join us and be seen in the NDIS today!</strong>
          </>
        )}
        buttonText={buttonText}
      />
      <Pricing />
      <ServiceRequests />
      <Questions />
      <Bottom
        image={bottomImg}
        useIconBtn={true}
        buttonText={buttonText}
      />
    </Page>
  );
};

Home.propTypes = {
  topHeroText: PropTypes.string,
  bottomHeroText: PropTypes.string,
  secondaryLogo: PropTypes.string,
};


export default Home;
