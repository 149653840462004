import * as track from 'lib/track';

import React, { useEffect, useState, useRef } from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import PropTypes from 'prop-types';

const resolveIndirect = (value, args, defaultValue=undefined) => {
  if (value === undefined) return defaultValue;
  if (typeof value === 'function') {
    value = value(...args);
  }
  return value;
};

const FormFlow = (
  {
    flow,
    defaults,
    onComplete,
    onCancel,
    defaultsToLastValidPage=false,
    forcedDefaults={},
    ...props
  }) => {

  const location = useLocation();
  const history = useHistory();
  // TEMPORARY ANSWERS FOR DEV PURPOSE
  // const [answers, setAnswers] = useState(
  //   {
  //     ndisPlan: 'Self managed',
  //     servicesFor: 'Myself',
  //     location: '2127 - Sydney Olympic Park, NSW',
  //     servicesNeeded: [
  //       { 'name': 'Cleaning', 'id': 1 },
  //       { 'name': 'Speech Therapy', 'id': 2 },
  //       { 'name': 'Nursing Services', 'id': 3 },
  //       { 'name': 'Meals', 'id': 4 },
  //     ],
  //     _qualifiedServices: {
  //       matchedServices: [
  //         { 'name': 'Speech Therapy', 'categoryName': 'Allied Health' },
  //         { 'name': 'Cleaning', 'categoryName': 'Cleaning' }
  //       ],
  //       unMatchedServices: [
  //         { 'name': 'Nursing Services', 'categoryName': 'XYZ' },
  //         { 'name': 'Meals', 'categoryName': 'XYZ' },
  //       ]
  //     }});
  const [answers, setAnswers] = useState(forcedDefaults);
  const [lastDefaults, setLastDefaults] = useState(defaults);
  const [maxFocus, setMaxFocus] = useState({});
  const [progress, setProgress] = useState(0);
  const [seenPages, setSeenPages] = useState(new Set());
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalQuestionsAnswered, setTotalQuestionsAnswered] = useState(0);
  const flowControlButtonRef = useRef(null);

  const updateProgress = num => setProgress(prevState => Math.max(0, prevState + num));

  const scrollToFlowControlButton = () => flowControlButtonRef?.current?.scrollIntoView(false);

  const updateAnswer = (field, value) => setAnswers(
    prevState => ({...prevState, [field]: value})
  );

  const progressAction = {
    increase: () => {updateProgress(1);},
    decrease: () => {updateProgress(-1);},
    reset: () => {setProgress(0);},
    set: num => {setProgress(Math.max(0, num));}
  };

  // This logic handles the total number of valid questions exist and answered.
  // You will need to pass certain fields in the questions and pages to use this
  // functionality.
  useEffect(() => {
    const noOfQ = flow.pages.reduce((acc, page) => {
      if ((page.isHidden && page?.isHidden(answers)) || !page.questionCount)
        return acc;
      const _noOfQ = page.questions.reduce((acc, question) => {
        if ((!question.isHidden || !question.isHidden(answers)) && (!question.isOptional || !question.isOptional(answers)))
          return acc + 1;
        return acc;
      }, 0);
      return acc + _noOfQ;
    }, 0);
    setTotalQuestions(noOfQ);

    const noOfAnsweredQ = flow.pages.reduce((acc, page) => {
      if ((page.isHidden && page?.isHidden(answers)) || !page.questionCount)
        return acc;
      const _noOfAnsweredQ = page.questions.reduce((acc, question) => {
        if ((!question.isOptional || !question.isOptional(answers)) && question.isValid(answers[question.field]))
          return acc + 1;
        return acc;
      }, 0);
      return acc + _noOfAnsweredQ;
    }, 0);
    setTotalQuestionsAnswered(noOfAnsweredQ);
  }, [answers]); // eslint-disable-line react-hooks/exhaustive-deps

  // this logic allows us to load defaults from the API
  // and send them through after we have already rendered.
  if (defaults !== lastDefaults) {
    setAnswers({...defaults, ...answers});
    setLastDefaults(defaults);
  }

  const updateMaxFocus = (page, index) => {
    const max = Math.max(maxFocus[page.name] || -1, index);
    setMaxFocus({...maxFocus, [page.name]: max});
  };

  const firstUnanswered = (page) => {
    var maxFocused = maxFocus[page.name];
    if (maxFocused === undefined) maxFocused = -1;
    return page.questions.find((q, i) =>
      (q.isHidden === undefined || !q.isHidden(answers)) &&
      (i > maxFocused || !q.isValid(answers[q.field], answers))
    );
  };

  const pageComplete = (page) => {
    return page.questions.every(q =>
      (q.isHidden !== undefined && q.isHidden(answers)) ||
      q.isValid === undefined ||
      q.isValid(answers[q.field], answers)
    );
  };

  // find the named page, verifying it is reachable.  Defaults to first page.
  const currentPage = () => {
    const name = location.hash.substring(1);
    return reachablePage(name);
  };

  // Find the named page, verifying it is reachable.
  const reachablePage = name => {
    let lastValidPage = null;
    for (const page of flow.pages) {
      // if a page is not shown, don't consider it.
      if (page.isHidden !== undefined && page.isHidden(answers)) continue;
      // if a page matches by name, we have a winner
      if (page.name === name) return page;
      // If a page reached till here, then it is a valid page
      if (defaultsToLastValidPage) {
        if (page.isEndOfJourney) break;
        lastValidPage = page;
      }
      // if a page is not complete, don't consider pages after it.
      if (!pageComplete(page)) {
        break;
      }
    }
    return lastValidPage ? lastValidPage : flow.pages[0];
  };

  // determine the next page from the current page, considering page visibility
  const nextPage = (currentPage) => {
    // find the next page, assuming we are validly on <page>
    const pageIndex = flow.pages.findIndex(page => page.name === currentPage.name);
    for (const page of flow.pages.slice(pageIndex + 1)) {
      // if a page is not shown, don't consider it.
      if (page.isHidden !== undefined && page.isHidden(answers)) continue;
      return page;
    }
    return undefined;
  };

  // determine the previous page from the current page, considering page visibility
  const prevPage = (currentPage) => {
    // find the previous page, assuming we are validly on <page>
    let prev = undefined;
    for (const page of flow.pages) {
      if (page === currentPage) return prev;
      // if a page is not shown, don't consider it.
      if (page.isHidden !== undefined && page.isHidden(answers)) continue;
      prev = page;
    }
    return undefined;  // did not find current page in accessible pages.
  };

  const page = currentPage();
  const complete = pageComplete(page);
  const nextQuestion = firstUnanswered(page);
  const next = nextPage(page);
  const prev = prevPage(page);
  const Container = page.Container || flow.Container;
  const QuestionContainer = page.QuestionContainer || flow.QuestionContainer;
  const [pageName, setPageName] = useState(page.name);

  const helperFunctions = {
    pageComplete: pageComplete,
    nextPage: nextPage,
    prevPage: prevPage,
    reachablePage: reachablePage,
  };

  const renderQuestion = (index, question, answers, isNextQuestion) => {
    const {field, label, Component, isHidden, isValid, errorText, shouldPreFill, preFillToField, ...inputProps} = question;    // eslint-disable-line  no-unused-vars
    const hidden = isHidden !== undefined && isHidden(answers);
    // errorText is only resolved if isValid is defined and returns false.
    const resolvedIsValid = isValid === undefined || resolveIndirect(isValid, [answers[field], answers]);
    const resolvedErrorText = !resolvedIsValid ? resolveIndirect(errorText, [answers[field]]) : undefined;
    const handleSetAnswers = value => {
      setAnswers(() => {
        if (!shouldPreFill || !shouldPreFill(answers))
          return {...answers, [field]: value};
        return {...answers, [field]: value, [preFillToField]: value};
      });
    };

    return (
      <QuestionContainer key={field} isNextQuestion={isNextQuestion} hidden={hidden} onClick={()=>updateMaxFocus(page, index)} {...inputProps} >
        <Component
          value={answers[field]}
          label={resolveIndirect(label, [answers])}
          onChange={handleSetAnswers}
          errorText={resolvedErrorText}
          answers={answers}
          setAnswers={setAnswers}
          setPagename={setPageName}
          nextPage={next}
          progressAction={progressAction}
          isValid={isValid}
          flowControlButtonRef={flowControlButtonRef}
          scrollToFlowControlButton={scrollToFlowControlButton}
          updateAnswer={updateAnswer}
          {...inputProps}
          {...props}
        />
      </QuestionContainer>
    );
  };

  useEffect(() => {
    const hash = '#' + pageName;
    setSeenPages(prevState => prevState.add(pageName));
    if (location.hash !== hash) {
      history.push(location.search + hash, location.state);
    }
    track.sendEvent('formflow', 'page', {pageName, answers});
  }, [pageName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const pageNameFromLocation = location.hash.slice(1);
    if (!seenPages.has(pageNameFromLocation)) {
      const defaultPage = defaultsToLastValidPage ?
        reachablePage(pageNameFromLocation).name : flow.pages[0].name;
      history.replace(`${location.search}#${defaultPage}`, location.state);
      setPageName(defaultPage);
      return;
    }
    if (pageNameFromLocation !== pageName)
      setPageName(pageNameFromLocation);
  }, [location, flow]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container 
      flow={flow}
      answers={answers}
      setAnswers={setAnswers}
      onCancel={onCancel}
      onComplete={onComplete}
      setPagename={setPageName}
      prev={prev}
      next={next}
      currentPage={page}
      complete={complete}
      progress={progress}
      progressAction={progressAction}
      totalQuestions={totalQuestions}
      totalQuestionsAnswered={totalQuestionsAnswered}
      helperFunctions={helperFunctions}
      flowControlButtonRef={flowControlButtonRef}
      scrollToFlowControlButton={scrollToFlowControlButton}
      updateAnswer={updateAnswer}
      {...props}
    >
      {page.questions.map((q, index) => renderQuestion(index, q, answers, q === nextQuestion))}
    </Container>
  );
};

FormFlow.propTypes = {
  flow: PropTypes.object.isRequired,
  defaults: PropTypes.object,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  defaultsToLastValidPage: PropTypes.bool,
  forcedDefaults: PropTypes.object,
};

export default FormFlow;
