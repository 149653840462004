/*
library for supporting realtime notifications via websockets.
Usage:
Using an effect hook to create and release a subscription:

  useEffect  React.useEffect(() => {
      const handler = (event) => {
        setRefresh(refresh+1);  // force re-render
      };
      notify.subscribe(handler, ['me/file']);
      return () => notify.unsubscribe(handler);
    }, []);
*/
import { NotifyAPI } from '../api';
const subscriptions = new Map();
let socketUrl;
let socket;
let myScope;  // /provider/uuid or /participant/uuid string

// Scopes match if:
//   the key is equal to the scope
//   the key is equal to the scope with additional "/..." suffix
// If the scope starts with ".", it is replaced with the default user scope
const inScope = (scope, key) => {
  return key === scope ||
    key.startsWith(scope + '/') ||
    (scope.startsWith('.') && inScope(myScope + scope.substr(1), key));
};

// When a message arrives, parse it and send it to all subscribers.
const messageHandler = (event) => {
  const {action, scope} = JSON.parse(event.data);
  for (const [handler, scopes] of subscriptions.entries()) {
    if (scopes.some(handlerScope => inScope(handlerScope, scope))) {
      handler(action, scope);
    }
  }
};

const sendScopes = () => {
  let allScopes = [];
  for (const scopes of subscriptions.values()) {
    allScopes = allScopes.concat(scopes);
  }
  socket.send(JSON.stringify({scopes: allScopes}));
};

// create a websocket and attach handlers
const connect = async () => {
  // const { payload: {url} } = await NotifyAPI.getWebsocket();
  if (!socketUrl) {
    const result = await NotifyAPI.getWebsocket();
    const { payload: {url, scope} } = result;
    socketUrl = url;
    myScope = scope;
  }
  socket = new WebSocket(socketUrl);

  socket.onopen = () => {
    sendScopes();
  };

  socket.onclose = (event) => {
    socket = undefined;
    if (event.code === 1001) {
      // code will be 1000 if we closed the socket,
      // 1001 if it timed out on the remote end (and should be reconnected)
      setTimeout(connect, 500);
    }
  };

  socket.onmessage = messageHandler;
};

const disconnect = () => {
  if (socket) socket.close(1000);
};

export const subscribe = (handler, scopes) => {
  subscriptions.set(handler, scopes);
  const isEmpty = subscriptions.size === 0;
  if (!socket && !isEmpty) {
    connect();
  } else {
    sendScopes();
  }
};

export const unsubscribe = (handler) => {
  subscriptions.delete(handler);
  const isEmpty = subscriptions.size === 0;
  if (isEmpty) {
    disconnect();
  }
};

// disconnect and clear all handlers.
export const reset = () => {
  socketUrl = undefined;
  subscriptions.clear();
  disconnect();
};

export default {
  subscribe,
  unsubscribe,
  reset,
};
