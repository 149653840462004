import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';

import {capitalize} from 'utils/generic';
import Question from './Question';
import {lowercase} from '../../utils';
import {ERROR_CONTAINER_HEIGHT, QUESTION_BORDER_COLOR} from '../../../constants';


const useStyles = makeStyles(theme => ({
  root: {
  },
  textBox: {
    borderRadius: '8px',

    '& .MuiOutlinedInput-root': {
      background: theme.palette.common.paleGrey,
      // To stop mobile devices auto zoom while typing inputs
      [theme.breakpoints.down('sm')]: {
        '& input': {
          fontSize: '16px'
        },
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      borderColor: QUESTION_BORDER_COLOR,
    },

    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: '13px'
    }
  },
  errorContainer: {
    height: ERROR_CONTAINER_HEIGHT,
  },
}));

const TextboxQuestion = (
  {
    text,
    helperText,
    placeholder,
    onChange,
    isValid,
    value: defaultValue,
    isMultiline=false,
    errorMessage,
    isOptional,
    answers,
    isLowerCase=false,
  }) => {

  const [valid, setValid] = useState(isOptional || !!defaultValue);
  const [answer, setAnswer] = useState('');
  const classes = useStyles();
  const [textboxContentLength, setTextboxContentLength] = useState(defaultValue?.length ? defaultValue.length : 0);
  const [canShowError, setCanShowError] = useState(false);

  const stringModifierFunc = isLowerCase ? lowercase : capitalize;
  const multilineProps = {};

  if (isMultiline) {
    multilineProps['multiline'] = true;
    multilineProps['rows'] = 5;
  }

  return (
    <div className={classes.root}>
      <Question
        title={text}
        subTitle={typeof helperText === 'function' ?
          helperText(answer) : helperText}
        isOptional={isOptional}
        answers={answers}
      >
        <TextField
          onFocus={() => setCanShowError(false)}
          onBlur={() => setCanShowError(true)}
          rows={5}
          className={classes.textBox}
          placeholder={placeholder}
          defaultValue={defaultValue}
          error={canShowError && !valid}
          helperText={canShowError && !valid && (
            textboxContentLength === 0 ?
              'Please complete this field' : (errorMessage ?
                errorMessage : 'Enter a valid input')
          )}
          variant="outlined"
          fullWidth={true}
          onChange={event => {
            setTextboxContentLength(event.target.value.length);
            setAnswer(event.target.value);
            if (isValid(event.target.value)) {
              onChange(stringModifierFunc(event.target.value));
              setValid(true);
            }
            else {
              onChange('');
              setValid(false);
            }
          }}
          {...multilineProps}
        />
        {!(canShowError && !valid) && <div className={classes.errorContainer}/>}
      </Question>
    </div>
  );
};

TextboxQuestion.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.func.isRequired,
  isMultiline: PropTypes.bool,
  inputName: PropTypes.string,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  isOptional: PropTypes.func,
  answers: PropTypes.object,
  isLowerCase: PropTypes.bool,
};

export default TextboxQuestion;