import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import circleBackground from 'assets/circle-background.svg';
import connectWithProviders from 'assets/connect-with-providers.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    padding: '80px 20px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '32px',
    },
  },
  title: {
    fontSize: '28px',
    fontWeight: '600',
  },
  subTitle: {
    marginTop: '15px',
    fontSize: '18px',
    padding: '0 120px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '40px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  imgContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundImage: `url(${circleBackground})`,
    height: '340px',
    width: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '280px',
      width: '350px',
      marginBottom: '30px',
    },
  },
  img: {
    height: 'auto',
    width: '300px',
    [theme.breakpoints.down('sm')]: {
      width: '230px',
    },
  },
  detailsContainer: {
    maxWidth: '450px',
    textAlign: 'start',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  detailsTitle: {
    color: theme.palette.primary.main,
    fontWeight: '600',
    fontSize: '18px',
    marginBottom: '20px',
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    borderRadius: '10px',
  },
}));


const ConnectWithProviders = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" fixed disableGutters={true}>
        <div className={classes.container}>
          <div className={classes.imgContainer}>
            <img src={connectWithProviders} alt="connect" className={classes.img} />
          </div>
          <div className={classes.detailsContainer}>
            <Typography variant="h3" className={classes.detailsTitle}>
              Over 450,000 participants, carers and providers have used us to navigate the NDIS.
            </Typography>
            <div>
              “I’m very grateful for Clickability. Every time I’ve spoken to their team,
              they’ve been extremely helpful, patient and have brought a lot of clarity to
              any situation.” <br/><br/>Julie
            </div>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => history.push('/requestmatch')}
            >
              Connect me with providers
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};


export default ConnectWithProviders;