import React from 'react';
import PropTypes from 'prop-types';
import { ProviderAPI } from '../../api';
import { ProviderStore } from '../../store';
import { connect } from 'react-redux';
import ListingsCardList from './ListingsCardList.js';

const ProviderListings = ({providerUuid = null, data, isLoading, dispatch}) => {

  const fetchData = async () => {
    try {
      const { payload } = await ProviderAPI.getListings(providerUuid);
      dispatch(ProviderStore.setListings(payload));
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  };

  const setLoading = loadingState => {
    dispatch(ProviderStore.isLoading('listing', loadingState));    
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData().then(setLoading(false));
  }, []); // eslint-disable-line

  return (
    <ListingsCardList 
      isLoading={isLoading} 
      data={data} 
      nullMessage="There are no listings for this provider yet. Contact us to find out if they are right for you."
    />
  );

};

ProviderListings.propTypes = {
  providerUuid: PropTypes.string,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    data: state.provider.listings,
    isLoading: state.provider.listingsLoading,
  };
};

export default connect(mapStateToProps)(ProviderListings);
