import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import {hotjar} from 'react-hotjar';
import { makeStyles } from '@material-ui/core/styles';
import NavMenu from './navmenu';
import Footer from './Footer';
import * as track from '../lib/track';
import Overlay from './Overlay';
import { useSelector } from 'react-redux';
import config from 'config';


const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  content: {
    position: 'relative',
    zIndex: 1,
    padding: 0,
    margin: 0,
  },
});

/*
 * title construction:
 * For SEO purposes the page title will always include "Australian Disability Directory"
 * and Clickability.  We only append Clickability if it is not already present.
 */
const formatTitle = title => {
  const parts = [title, 'Australian Disability Directory'];
  if (title.search(/clickability/i) === -1) {
    parts.push('Clickability');
  }
  return parts.join(' | ');
};

const Page = ({children, title, contentStyle, ...props}) => {
  const classes = useStyles();
  const overlayOpen = useSelector(state => state.auth.overlayOpen);

  useEffect(() => {
    if (title) document.title = formatTitle(title);
  }, [title]);

  useEffect(() => {
    window.scrollTo(0, 0);
    track.sendEvent('page', 'view', {title: title});
    hotjar.initialize(config.hotjar.id, config.hotjar.sv);
  }, []); // eslint-disable-line

  return (
    <div className={classes.root} {...props}>
      <NavMenu aria-label="Header navigation bar and menu" />
      <div className={classes.content} style={contentStyle}>
        {children}
      </div>
      <Footer aria-label="Footer section and links"/>
      <Overlay open={overlayOpen}/>
    </div>
  );
};

// Must pass a single component. Wrap multiple components in React.Fragment to avoid warnings
Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  contentStyle: PropTypes.object,
};

export default Page;
