import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Menu from '../components/Menu';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  menu: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    padding: '2rem 0 2rem 1rem',
    borderRight: `1px solid ${theme.palette.common.silver}`,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    padding: '2rem 2rem',
  },
  alert: {
    width: '100%',
    padding: '1rem 0',
  }
}));

const SiteAdmin = ({children}) => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" fixed disableGutters={true}>
      <Grid container spacing={0}>
        <Grid item md={2} className={classes.menu}>
          <Typography variant="h6" color="primary">Site Admin</Typography>
          <Menu
          />
        </Grid>
        <Grid item md={10} className={classes.content}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

SiteAdmin.propTypes = {
  children: PropTypes.node.isRequired,
};


export default SiteAdmin;
