import * as EmailValidator from 'email-validator';
import * as gtm from '../../lib/gtm';

import React, { useState } from 'react';

import Alert from '@material-ui/lab/Alert';
import Button from '../Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import { LocationInput } from '../input';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Recaptcha from 'react-recaptcha';
import RecaptchaProvider from '../RecaptchaProvider';
import { Link as RouterLink } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import Typography from '@material-ui/core/Typography';
import config from '../../config';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { submitContactUsForm } from 'api/provider';

const CASE_ORIGIN_BY_ENQUIRY_REASON = {
  'Issue with Submitted Request': 'Web - Service Request Issue',
  'How do I find an NDIS provider for me': 'Web - Find NDIS Provider',
  'Cancel my Service Request': 'Web - Cancel Service Request',
  'Product Feedback': 'Web - Feedback',
  'Finding and Connecting with NDIS Providers': 'Web - Find Provider',
  'Promoting My business on Clickability': 'Web - Promote Business',
  'Accredited Provider - Account Issue': 'Web - Provider Account Issue',
  'Upgrade my account to Accredited': 'Web - Upgrade my account to Accredited',
  'Issue with log in to Provider profile': 'Web - Provider Login Issue'
};
const DEFAULT_ENQURIY_REASON = Object.keys(CASE_ORIGIN_BY_ENQUIRY_REASON)[4];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    margin: '1rem 0',
  },
  button: {
    margin: '1rem 0',
  },
  submitted: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.common.tealish,
    marginRight: '1rem',
  }
}));

const RequestForm = ({formName, isAuthenticated, user}) => {
  const classes = useStyles();
  const userName = isAuthenticated ? user.username : '';
  const userUuid = isAuthenticated ? user.user_uuid : '';
  const userEmail = isAuthenticated ? user.email : '';
  const [loading, setLoading] = useState(true);
  const [verifyCode, setVerifyCode] = useState();
  const [errors, setErrors] = useState({});
  const [emailOptIn, setEmailOptIn] = useState(true);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState({
    first_name: user?.first_name ? user.first_name : '',
    last_name: user?.last_name ? user.last_name : '',
    email: userEmail,
    phone: user?.contact_phone ? user.contact_phone : '',
    location: '',
    enquiry_reason: DEFAULT_ENQURIY_REASON,
    description: '',
    form_name: formName,
    user_uuid: userUuid,
    username: userName,
  });
  const phoneNumber = parsePhoneNumberFromString(data.phone, 'AU');
  const verified = verifyCode !== undefined;
  const setFormField = (attribute, value) => {
    let copy = Object.assign({}, data);
    copy[attribute] = value;
    setData(copy);
  };

  const handleChange = (value, key) => {
    setFormField(key, value);
  };

  const validateForm = () => {
    const errors = {
      email: data.email === '' ? 'Please provide an email address' : (!EmailValidator.validate(data.email) && 'That doesn\'t look like a valid email address.'),
      firstName: data.first_name.length < 2 && 'First name must have at least 2 characters.',
      lastName: data.last_name.length < 2 && 'Last name must have at least 2 characters.',
      phone: data.phone === '' ? 'Please provide a phone number' : ((!phoneNumber || !phoneNumber.isValid()) && 'That doesn\'t look like a valid phone number.'),
      location: data.location === '' && 'Please select a location',
      description: (data.description.split(' ').length) < 5 && 'Description must be at least 5 words',
    };

    setErrors(errors);
    const valid = !Object.values(errors).some(v => v);
    return valid;
  };

  const emailOptInHandler = () => {
    setEmailOptIn(!emailOptIn);
  };

  const postData = async () => {
    setLoading(true);
    const form_data = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: phoneNumber.formatNational().replace(/[- )(]/g,''),
      email_opt_in: emailOptIn,
      case_origin: CASE_ORIGIN_BY_ENQUIRY_REASON[data.enquiry_reason],
      verify_code: verifyCode,
      reason: data.enquiry_reason,
      description: data.description,
      user_uuid: data.user_uuid,
      username: data.username,
      location: data.location,
    };

    try {
      await submitContactUsForm(form_data);
      gtm.sendEvent('submit', {formName});
      setFormSubmitted(true);
      setLoading(false);
    } catch (e) {
      setError('An error occurred sending your enquiry.  Please try again later or call us.');
      setLoading(false);
    }
  };

  const submit = () => {
    if (!validateForm()) {
      setFormValid(false);
    } else {
      setFormValid(true);
      postData();
    }
  };

  if (formSubmitted) {
    return (
      <div className={classes.submitted}>
        <ThumbUpOutlinedIcon className={classes.icon} fontSize="large"/>
        <Typography variant="h6" color="primary">
          <b>All done! </b>Our team will be in touch with you shortly.
        </Typography>
      </div>
    );
  }

  return (
    <RecaptchaProvider>
      <form className={classes.root}>

        <FormControl className={classes.formControl}>
          <TextField
            required
            error={!!errors.firstName}
            placeholder="First name"
            label="First name"
            variant="outlined"
            value={data.first_name}
            onChange={e => handleChange(e.target.value, 'first_name')}
          />
          {errors.firstName && <Alert severity="error">{errors.firstName}</Alert>}
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            required
            error={!!errors.lastName}
            placeholder="Last name"
            label="Last name"
            variant="outlined"
            value={data.last_name}
            onChange={e => handleChange(e.target.value, 'last_name')}
          />
          {errors.lastName && <Alert severity="error">{errors.lastName}</Alert>}
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            required
            error={!!errors.email}
            placeholder="Email"
            label="Email"
            variant="outlined"
            value={data.email}
            onChange={e => handleChange(e.target.value, 'email')}
          />
          {errors.email && <Alert severity="error">{errors.email}</Alert>}
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            required
            error={!!errors.phone}
            placeholder="Phone number"
            label="Phone number"
            variant="outlined"
            value={data.phone}
            onChange={e => handleChange(e.target.value, 'phone')}
          />
          {errors.phone && <Alert severity="error">{errors.phone}</Alert>}
        </FormControl>

        <FormControl className={classes.formControl}>
          <LocationInput
            required
            onEnter={e => handleChange(e, 'location')}
            value={data.location}
            error={!!errors.location}
          />
          {errors.location && <Alert severity="error">{errors.location}</Alert>}
        </FormControl>

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Enquiry reason:</InputLabel>
          <Select
            value={data.enquiry_reason}
            onChange={e => handleChange(e.target.value, 'enquiry_reason')}
            label="Enquiry reason:"
          >
            {Object.keys(CASE_ORIGIN_BY_ENQUIRY_REASON).map(reason => 
              <MenuItem key={reason} value={reason}>{reason}</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            required
            error={!!errors.description}
            placeholder="Describe your needs"
            label="Describe your needs"
            variant="outlined"
            multiline
            rows="5"
            value={data.description}
            onChange={e => handleChange(e.target.value, 'description')}
          />
          {errors.description && <Alert severity="error">{errors.description}</Alert>}
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={<Checkbox checked={emailOptIn} onChange={emailOptInHandler} />}
            label="Inform me about updates, features and offers!"
          />
          <FormControlLabel
            control={<Checkbox checked={termsAgreed} onChange={() => setTermsAgreed(!termsAgreed)} />}
            label={
              (
                <Typography variant="body2">
                  I agree to Clickability&apos;s&nbsp;
                  <Link component={RouterLink} to="/terms">
                    <b>Terms and Conditions</b>
                  </Link>
                  &nbsp;&&nbsp;
                  <Link component={RouterLink} to="/privacy">
                    <b>Privacy Policy.</b>
                  </Link>
                </Typography>
              )
            }
          />
          {errors.terms && <Alert severity="error">{errors.terms}</Alert>}
        </FormControl>

        <div className={classes.formControl}>
          <Recaptcha
            sitekey={config.settings.RECAPTCHA_KEY}
            render="explicit"
            onloadCallback={() => setLoading(false)}
            verifyCallback={(code) => setVerifyCode(code)}
            expiredCallback={() => setVerifyCode(undefined)}
          />
        </div>

        <Button
          variant="contained"
          onClick={submit}
          className={classes.button}
          label="Submit"
          color="primary"
          disabled={loading || !verified || !termsAgreed}
          busy={loading}
        />
        {!formValid && <Alert severity="error">Some information you entered is not valid. Please update the fields highlighted in red and try again.</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
      </form>
    </RecaptchaProvider>
  );

};

RequestForm.propTypes = {
  formName: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.profile,
  };
};

export default connect(mapStateToProps)(RequestForm);
