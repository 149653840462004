import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../components/Page';
import Grid from '@material-ui/core/Grid';
import ContentContainer from '../../components/ContentContainer.js';
import Typography from '@material-ui/core/Typography';
import SuggestedServices from '../../components/suggestions/SuggestedServices';
import { TaxonomyStore } from '../../store';
import { connect } from 'react-redux';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '1rem',
  },
  services: {
    width: '100%',
    columnCount: '2',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      height: '100%',
    },
  },
  group: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    pageBreakInside: 'avoid',
    breakInside: 'avoid-column',
    padding: '1rem 0',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  subtitle: {
    fontWeight: 600,
    marginBottom: '1rem',
  },
  button: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    fontWeight: 400,
    textAlign: 'left',
    padding: '0.5rem 0',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
}));

const ServiceCategoriesPage = ({ dispatch, services }) => {
  const classes = useStyles();
  const history = useHistory();
  const [hover, setHover] = React.useState(null);

  const handleHoverChange = (value, action) => {
    if (action === 'enter') {
      setHover(value);
    } else {
      setHover(null);
    }
  };

  const handleClick = (value) => {
    history.push(`/search?service=${value}`);
  };

  services.sort((a, b) => a.id - b.id);

  React.useEffect(() => {
    dispatch(TaxonomyStore.refreshTaxonomy('services'));
    // eslint-disable-next-line
  }, []);

  return (
    <Page title="Clickability - Service categories">
      <ContentContainer>
        <Grid container spacing={6}>
          <Grid item md={8}>
            <div className={classes.content}>
              <Typography variant="h1" color="primary">
                Service categories
              </Typography>
              <Typography variant="body2">
                Clickability lists over 7,000 disability services across all support categories, helping you find everything you need in one. Just click on any of the links below to see the services on offer.
              </Typography>
              <Typography variant="body2">
                Don&apos;t want to search through our massive directory yourself? Let us do the work for you by using our&nbsp;
                <Link component={RouterLink} to="/requestmatch">
                  <b>matching service</b>
                </Link>!
              </Typography>
              <div className={classes.services}>
                {services.map(item => {
                  return (
                    <div className={classes.group} key={item.id}>
                      <Typography variant="h3" color="primary" className={classes.subtitle}>
                        {item.name}
                      </Typography>
                      {item.children.map(child => {
                        return (
                          <Button
                            key={child.id}
                            variant="text"
                            className={classes.button}
                            disableRipple
                            onClick={() => handleClick(child.name)}
                            onMouseEnter={() => handleHoverChange(child.name, 'enter')}
                            onMouseLeave={() => handleHoverChange(child.name, 'exit')}
                          >
                            {child.name}
                            {hover === child.name ? <ChevronRightIcon /> : <ChevronRightIcon visibility="hidden" />}
                          </Button>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <SuggestedServices limit={10} />
          </Grid>
        </Grid>
      </ContentContainer>
    </Page>
  );
};

ServiceCategoriesPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    services: state.taxonomy.services,
  };
};

export default connect(mapStateToProps)(ServiceCategoriesPage);

// {hover === child.name ? <ChevronRightIcon /> : <ChevronRightIcon visibility="hidden"/>}
