import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import ConnectDashboard from '../../components/ConnectDashboard';
import ParticipantList from './components/ParticipantList';
import ParticipantDetails from './components/ParticipantDetails';
import ParticipantCreator from './components/ParticipantCreator';
import { NEW_PARTICIPANT_KEY } from './constants';


const Participants = ({ isItemSelected, selectedItem, onItemSelect }) => {

  const { uuid: providerUuid } = useParams();

  if (!isItemSelected)
    return (
      <ConnectDashboard title="Participants">
        <ParticipantList providerUuid={providerUuid} onSelect={onItemSelect} />
      </ConnectDashboard>
    );

  return (
    <ConnectDashboard title="Participants">
      {selectedItem === NEW_PARTICIPANT_KEY ? (
        <ParticipantCreator providerUuid={providerUuid} />
      ) : (
        <ParticipantDetails
          providerUuid={providerUuid}
          participantUuid={selectedItem}
          onSelect={onItemSelect}
        />
      )}
    </ConnectDashboard>
  );
};


Participants.propTypes = {
  isItemSelected: PropTypes.bool,
  selectedItem: PropTypes.string,
  onItemSelect: PropTypes.func,
};


export default Participants;
