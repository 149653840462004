import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';

import CircularProgressWithLabel from './CircularProgressWithLabel';
import {NAV_BAR_HEIGHT} from 'components/constants';


const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.darkSlateBlue,
    height: `calc(85px + ${NAV_BAR_HEIGHT})`,
    paddingTop: NAV_BAR_HEIGHT,
    marginTop: `-${NAV_BAR_HEIGHT}`,
    borderRadius: '0px 0px 16px 16px',
    color: theme.palette.common.white,
    paddingLeft: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: '16px',
    marginLeft: '10px',
  },
}));

const ProgressPanel = ({ value=0 }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgressWithLabel value={value} />
      <div className={classes.text}>Matching complete</div>
    </div>
  );
};

ProgressPanel.propTypes = {
  value: PropTypes.number,
};

export default ProgressPanel;