import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import style from 'style';


const Choice = styled.div`
  background: white;
  border: 1px solid ${style.colors.lightBorder};
  padding: 10px 15px;
  border-radius: 16px;
  box-shadow: ${style.shadow(10)};
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  user-select: none;
  flex-grow: 1;

  ${({$isSelected}) => $isSelected && css`
    border-color: #76e8dc;
    background: #ecfffe;
  `}
  
  :hover {
    border-color: #76e8dc;
  }
  
  .icon-container {
    background: ${({$isSelected}) => $isSelected ? '#dff6f4' : '#f1f1f1'};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 6px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 750px;
  
  .title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  
  .choice-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .header {
    display: flex;
    column-gap: 7px;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2));
  }
`;

export const MultipleChoice = (
  {
    title,
    options,
    defaultData,
    onChange,
    isSingleChoice=false,
  }) => {

  const [selected, setSelected] = useState(defaultData && new Set(defaultData));

  useEffect(() => {
    if (!selected) return;
    if (!isSingleChoice) {
      onChange([...selected]);
    } else {
      onChange(selected.size ? [...selected][0] : null);
    }

  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnClick = item => {
    let newSet;

    setSelected(prevState => {
      newSet = new Set(prevState);

      if (prevState?.has(item)) {
        newSet.delete(item);
        return newSet;
      }

      if (isSingleChoice && newSet.size > 0)
        newSet = new Set();

      newSet.add(item);
      return newSet;
    });
  };

  return (
    <Container>
      <div className="header">
        <div className="title">{title}</div>
      </div>
      <div className="choice-container">
        {options.map((name, index) =>
          <Choice
            key={`multi-select-button-${index}`}
            onClick={() => handleOnClick(name)}
            $isSelected={!!selected?.has(name)}
          >
            {name}
          </Choice>
        )}
      </div>
    </Container>
  );
};

MultipleChoice.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isSingleChoice: PropTypes.bool,
  defaultData: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

export default MultipleChoice;