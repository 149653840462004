import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import React from 'react';

const pxToRem = px => `${px/16}rem`;

// https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=264f73&secondary.color=b1ff3c
const primaryColor = '#050E40';
const secondaryColor = '#21bfaf';
const textPrimary = '#545456';
const textSecondary = '#264f73';
const sanSerifFontFamily = 'Heebo, Helvetica, Roboto, Arial, sans-serif';

const theme = createTheme({
  overrides: {
    MuiDialogTitle: {
      root: {
        // DialogueTitle are h2, we want h1 style applied
        '& .MuiTypography-root': {
          fontSize: pxToRem(34),
          lineHeight: 1.18,
          fontWeight: 700,
          color: primaryColor,
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        width: '100%',
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
          marginBottom: '1rem',
          border: '1px solid rgba(0, 0, 0, .125)',
        },
        '&:before': {
          display: 'none',
        },
        '&:last-child': {
          marginBottom: '1rem',
        },
        '&$expanded': {
          '&:last-child': {
            marginBottom: '1rem',
          },
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: '#f5f5f7',
        '&:focus-within': {
          backgroundColor: '#f5f5f7',
        },
        '&$expanded': {
          backgroundColor: '#f5f5f7',
          borderBottom: '1px solid rgba(0, 0, 0, .125)',
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        padding: '0.9rem 1.2rem',
      },
    },
    MuiStepIcon: {
      root: {
        color: '#ccc',
        '&$active': {
          color: secondaryColor,
        },
        '&$completed': {
          color: secondaryColor,
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: sanSerifFontFamily,
        minWidth: '10rem',
      }
    },
    MuiInputLabel: {
      root: {
        fontFamily: sanSerifFontFamily,
        color: 'grey',
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: sanSerifFontFamily,
        color: 'grey',
        fontSize: '0.9375rem',
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: '0.9375rem',  // 15px for accessibility
      }
    }
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    common: {
      'black': '#000',
      'white': '#fff',
      'paleGrey': '#f5f5f7',
      'silver': '#d5d5d6',
      'battleshipGrey': '#74747b',
      'vermillion': '#da3510',
      'tealish': '#21bfaf',
      'dustyOrange': '#f26849',
      'squash': '#f2a922',
      'lightSeaGreen': '#99f2bf',
      'nightBlue': '#050e40',
      'darkSlateBlue': '#264f73',
      'textGrey': '#545456',
      'highlightBlue': '#E0F0FD',
      'fauxTiffanyBlue': '#00b7a6',
      'fauxIndigo': '#1d4666',
      'aliceBlue': '#ecf0f3',
      'lightBorder': '#e2e2e2',
      'blackSlate': '#191919'
    },
    background: {
      'default': '#fff',
    }
  },
  shadow: strength => (`rgb(145 158 171 / ${strength}%) 0px 0px 4px 0px, 
  rgb(145 158 171 / ${strength}%) 2px -2px 20px 0px`),
  typography: {
    fontFamily: sanSerifFontFamily,
    fontSize: 15,  // automatically converted to rem
    useNextVariants: true,
    color: '#ADBBCD',
    body1: {
      fontSize: pxToRem(15),
    },
    body2: {
      //fontSize: pxToRem(15),
    },
    h1: {
      fontSize: pxToRem(34),
      lineHeight: 1.18,
      fontWeight: 700,
    },
    h2: {
      fontSize: pxToRem(24),
      lineHeight: 1.25,
      fontWeight: 500,
    },
    h3: {
      fontSize: pxToRem(18),
      lineHeight: 1.33,
      fontWeight: 400,
    },
  },
});

const ThemeProvider = ({children}) => {
  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node
};

export default ThemeProvider;
