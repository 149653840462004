import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import style from 'style';
import logo from 'assets/logo-white.png';
import { currentProductUrl } from './utils';


const Main = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${style.screens.mobile}) {
    flex-direction: column;
  }
`;

const Tagline = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${style.colors.secondary};
  &::before {
    content: '|';
    font-weight: 200;
    font-size: 28px;
    margin: 0 8px;
    vertical-align: -1px;
  }
  &::after {
    content: ${({endContent}) => endContent && '"."'};
  }
   @media (max-width: ${style.screens.mobile}) {  
     &::before {
      content: none;
    }
     &::after {
       content: none;
     }
     font-size: 14px;
   }
`;

const Logo = ({ secondaryLogo }) => {
  const isMobile = useMediaQuery({maxWidth: style.screens.mobile});
  const history = useHistory();
  const currentUrl = currentProductUrl();

  const getTagline = () => {
    switch (currentUrl) {
    case '/connect':
      return 'Intermediaries';
    case '/providers':
      return 'Providers';
    default:
      return 'Business';
    }
  };

  return (
    <Main>
      <img
        src={logo}
        alt="Clickability"
        width={isMobile ? '150px' : '180px'}
        onClick={() => history.push('/')}
      />
      <Tagline
        endContent={!secondaryLogo}
        onClick={() => !secondaryLogo && (currentUrl && history.push(currentUrl))}
      >
        {secondaryLogo ? (
          <img
            src={secondaryLogo}
            alt="Clickability"
            width={isMobile ? '150px' : '180px'}
          />
        ) : (
          <>For {getTagline()}</>
        )}
      </Tagline>
    </Main>
  );
};

Logo.propTypes = {
  secondaryLogo: PropTypes.string,
};


export default Logo;
