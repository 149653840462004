import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import style from 'style';


const StyledButton = styled(Button)`
  color: ${style.colors.palette.white};
  background-color: ${style.colors.secondary};
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  
  .label-text {
    line-height: normal;
    color: ${style.colors.palette.white};
    font-size: 14px;
  }
`;

const AddButton = ({ text, onClick }) => {

  return (
    <StyledButton type="primary" size="large" onClick={onClick}>
      <FontAwesomeIcon icon={faPlus} />
      <Typography.Text className="label-text">{text}</Typography.Text>
    </StyledButton>
  );
};


AddButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};


export default AddButton;
