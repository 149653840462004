import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100px',
    width: '100%',
  },
  text: {
    fontSize: '16px',
    fontWeight: '500',
    color: theme.palette.common.darkSlateBlue,
    marginBottom: '6px',
  },
  input: {
    '& .Mui-error': {
      fontSize: '12px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid',
        borderRadius: '10px',
        borderColor: theme.palette.common.tealish,

      },
      '& input': {
        margin: '2px',
        fontSize: '15px',
        minWidth: '250px'
      },
    }
  },
}));

const TextBox = ({ text, placeholder, defaultValue, onChange, validator, errorText}) => {
  const classes = useStyles();

  const [valid, setValid] = useState(true);

  return (
    <div className={classes.root}>
      <div className={classes.text}>{text}</div>
      <TextField
        error={!valid}
        helperText={!valid && (errorText ? errorText : `Enter a valid ${text.toLowerCase()}`)}
        className={classes.input}
        placeholder={placeholder}
        variant="outlined"
        fullWidth={true}
        defaultValue={defaultValue}
        onChange={event => {
          if (validator(event.target.value)) {
            onChange(event.target.value);
            setValid(true);
          }
          else {
            onChange('');
            setValid(false);
          }
        }}
      />
    </div>
  );
};

TextBox.propTypes = {
  text: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  validator: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  errorText: PropTypes.string,
};

export default TextBox;