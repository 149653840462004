import React from 'react';
import { SvgIcon } from '@material-ui/core';

const NewsIcon = (props) => {
  return(
    <SvgIcon viewBox="0 0 250 250" {...props}>
      <g id="news" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(-35.000000, -36.000000)" fillRule="nonzero">
          <circle id="Mask" fill="#2EBCAE" transform="translate(160.500000, 160.500000) rotate(-23.250000) translate(-160.500000, -160.500000) " cx="160.5" cy="160.5" r="122.035345"></circle>
          <path d="M235.5,101.5 L281.619075,175.565335 C276.409851,217.120471 249.929961,254.896204 208.670407,272.622707 C191.65183,279.934465 173.889833,283.074175 156.526188,282.497359 L84,218.5 L235.5,101.5 Z" id="Combined-Shape" fill="#199D8E"></path>
        </g>
        <path d="M111.165644,48 L185,153.22293 L62.2392638,188 C52.7505112,186.81104 46.2269939,182.649682 42.6687117,175.515924 C39.1104294,168.382166 39.1104294,162.734607 42.6687117,158.573248 L111.165644,48 Z" id="Path-9" fill="#FFFFFF"></path>
        <path d="M85,178 C89.0868421,182.946784 92.4201754,186.280117 95,188 C97.5798246,189.719883 101.746491,191.38655 107.5,193 L138.5,184 C140.369311,180.517092 141.535977,177.350425 142,174.5 C142.464023,171.649575 142.464023,167.649575 142,162.5" id="Path-17" stroke="#FFFFFF" strokeWidth="8"></path>
        <line x1="142" y1="65" x2="157" y2="37" id="Line" stroke="#FFFFFF" strokeWidth="9" strokeLinecap="square"></line>
        <line x1="161.5" y1="93" x2="195.5" y2="65.5" id="Line" stroke="#FFFFFF" strokeWidth="9" strokeLinecap="square"></line>
        <line x1="182.25" y1="117.25" x2="213.5" y2="111.5" id="Line" stroke="#FFFFFF" strokeWidth="9" strokeLinecap="square"></line>
      </g>
    </SvgIcon>
  );
};

export default NewsIcon;
