import moment from 'moment';
import 'moment/locale/en-au';
import 'moment/locale/en-gb';


// pretiffy ISO date
export const prettyDate = (value, format = 'LL') => {
  return moment(value).format(format);
};

// pretiffy ISO date
export const prettyLocalDate = (value, format = 'LL') => {
  return moment.utc(value).local().format(format);
};

// generate 'days/months since timestamp' text
export const timeSinceText = (value) => {
  return moment.utc(value).fromNow();
};
