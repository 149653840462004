import { useLocation } from 'react-router-dom';

const PRODUCT_ACCREDITATION = 'accreditation';
const PRODUCT_CONNECT = 'connect';


export const currentProductUrl = () => {
  const path = useLocation();

  if (/\/connect/.test(path?.pathname)) {
    return '/connect';
  } else if (/\/providers/.test(path?.pathname))
    return '/providers';
  return null;
};

export const getProductDashboardUrl = (productName, userProfile) => {
  const connectUri = '/connect';
  const accreditationUri = '/providers';
  const { roles = [] } = userProfile;

  if (roles.length === 0) {
    return productName === PRODUCT_CONNECT ? connectUri : accreditationUri;
  } else if (roles.length === 1) {
    const role = roles[0];

    if (productName === PRODUCT_CONNECT
      && !role.has_connect_access)
      return connectUri;
    if (productName === PRODUCT_ACCREDITATION && !role.has_accreditation_access)
      return accreditationUri;

    const providerUuid = role.provider_uuid;

    return productName === PRODUCT_CONNECT ?
      `/connect/${providerUuid}/dashboard` : `/provider/${providerUuid}/admin/listings`;
  } else {
    if (productName === PRODUCT_CONNECT && !roles.some(item => item.has_connect_access))
      return connectUri;

    if (productName === PRODUCT_ACCREDITATION && !roles.some(item => item.has_accreditation_access))
      return accreditationUri;

    return '/providers/profile';
  }
};