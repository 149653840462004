import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const singleLevelStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    margin: 0,
    padding: '0 0.5rem',
  },
  list: {
    listStyleType: 'none',
    paddingInlineStart: 0,
  },
});

// We support de-selected on radio button groups by hooking the onClick callback
// instead of the onChange callback of the Radio element.  We will call our own
// onChange with undefined for a deselection, or item.id for a new selection.
// The need for allowDeselect will probably go away if we allow filters to be
// multiselect.

const SingleLevelArray = ({items, value=null, onChange, allowDeselect=false}) => {
  const classes = singleLevelStyles();
  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {items.map(item => {
          const checked = (value === item.id) ? true : false;
          return (
            <li key={item.id}>
              <FormControlLabel
                value={item.id}
                control={<Radio checked={checked} onClick={() => (allowDeselect || !checked) && onChange(checked ? undefined : item.id)}/>}
                label={item.name}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

SingleLevelArray.propTypes = {
  items: PropTypes.array.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  allowDeselect: PropTypes.bool.isRequired,
};

const dualLevelStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
  },
  group: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: '1rem',
    padding: '0 1rem',
    maxWidth: '220px',
  },
  header: {
    textAlign: 'left',
    textTransform: 'uppercase',
    fontSize: '0.9rem',
    fontWeight: '900',
    color: theme.palette.primary.main,
    marginBottom: '0.5rem',
  },
  list: {
    listStyleType: 'none',
    paddingInlineStart: 0,
  },
}));

const DualLevelArray = ({items, value=null, onChange, allowDeselect=false}) => {
  const classes = dualLevelStyles();
  return (
    <div className={classes.root}>
      {items.map(parent => {
        return (
          <div className={classes.group} key={parent.id}>
            <ul className={classes.list}>
              <li className={classes.header}>
                {parent.name}
              </li>
              {parent.children.map(child => {
                const checked = (value === child.id) ? true : false;
                return (
                  <FormControlLabel
                    value={child.id}
                    control={<Radio checked={checked} onClick={() => (allowDeselect || !checked) && onChange(checked ? undefined : child.id)}/>}
                    label={child.name}
                    key={child.id}
                  />
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

DualLevelArray.propTypes = {
  items: PropTypes.array.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  allowDeselect: PropTypes.bool.isRequired,
};

const RadioItemListArrays = ({items, value=null, onChange, levels='dual', allowDeselect=false}) => {
  if (levels === 'single') {
    return (<SingleLevelArray items={items} value={value} onChange={onChange} allowDeselect={allowDeselect} />);
  } else {
    return (<DualLevelArray items={items} value={value} onChange={onChange} allowDeselect={allowDeselect} />);
  }
};

RadioItemListArrays.propTypes = {
  items: PropTypes.array.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  levels: PropTypes.string,
  allowDeselect: PropTypes.bool.isRequired,
};

export default RadioItemListArrays;
