
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { CmsStore } from '../store';
import { useDispatch, useSelector } from 'react-redux';

const cleanPath = path => path.endsWith('/') ? path : path + '/';

// hooks-style accessor for cms pages.
const useContent = (path, pageType='page') => {
  const pages = useSelector(state => state.cms.pages);
  const dispatch = useDispatch();
  const cleanedPath = cleanPath(path);
  useEffect(() => {
    dispatch(CmsStore.getPage(cleanedPath, pageType));
  }, [cleanedPath, dispatch]);
  return pages[cleanedPath];
};

// HOC-style accessor for cms pages.
const ContentProvider = (props) => {
  const {match, history, pageType} = props;
  const Component = props.component;
  const path = cleanPath(match.url);
  useEffect(() => {
    if (match.url !== path) {
      history.replace(path);
    }
  }, [match.url]);

  const content = useContent(match.url, pageType);
  return <Component content={content}/>;
};

ContentProvider.propTypes = {
  component: PropTypes.func,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
  }),
  pageType: PropTypes.string
};

export { useContent };
export default ContentProvider;
