import { ReportAPI } from '../api';

const SET_LOADING = 'report/SET_LOADING';
const SET_REPORT_LIST = 'report/SET_REPORT_LIST';
const INVALIDATE_CACHE = 'report/INVALIDATE_CACHE';
const CACHE_TTL = 30 * 60 * 1000;  // long cache, but cache is invalidated at app reload.

const initialState = {
  reportList: [],
  lastLoaded: undefined,
  isLoading: true,
};

// Action Creators

export function setLoading(name, time) {
  return {
    type: SET_LOADING,
    name,
    time,
  };
}

export function setReportList(name, payload) {
  return {
    type: SET_REPORT_LIST,
    name,
    payload,
  };
}

export function invalidateCache() {
  return {
    type: INVALIDATE_CACHE,
  };
}

// Async action Creators

export function refreshReportList(name, force) {
  return async (dispatch, getState) => {
    const state = getState().report;
    const lastLoaded = state.lastLoaded;
    const now = Date.now();
    const age = now - lastLoaded;
    if (!force && age < CACHE_TTL) { // age might be NaN, which compares false
      return;
    }
    // We set lastLoaded here, before the request returns, so that
    // any additional requests that arrive while loading will not trigger more requests.
    dispatch(setLoading(name, now));
    try {
      const { payload } = await ReportAPI.getReports();
      dispatch(setReportList(name, payload));
    } catch (err) {
      dispatch(setLoading(name, undefined)); // needs loading.
    }
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case SET_LOADING:
    return {
      ...state,
      lastLoaded: action.time,
    };

  case SET_REPORT_LIST:
    return {
      ...state,
      reportList: action.payload,
    };

  case INVALIDATE_CACHE:
    return {
      ...state,
      lastLoaded: {},
    };

  default:
    return state;
  }
}
