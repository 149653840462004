import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {useMediaQuery} from 'react-responsive';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {makeStyles} from '@material-ui/core/styles';
import {Collapse} from '@material-ui/core';

import ProgressPanel from './ProgressPanel';
import BottomMobilePanel from './BottomMobilePanel';
import Dropdown from './Dropdown';
import PageIndicator from './PageIndicator';


const useStyles = makeStyles(theme => ({
  root: {
    width: '240px',
    background: '#f5f5f5',
    flexShrink: 0,
  },
  container: {
    padding: '30px 25px',
  },
  pageInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
  },
  servicePageInfoTitle: {
    marginTop: '50px',
    marginBottom: '10px',
    color: theme.palette.common.darkSlateBlue,
    fontWeight: '500',
  },
  addServiceButton: {
    marginTop: '40px',
    textTransform: 'inherit',
    backgroundColor: theme.palette.common.darkSlateBlue,
    color: theme.palette.common.white,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.common.tealish,
      color: theme.palette.common.paleGrey,
    },
  },
  servicesTitle: {
    marginTop: '15px',
    marginBottom: '8px',
    fontWeight: '500',
  },
  mobileNavContainer: {
    width: '260px',
    background: theme.palette.common.paleGrey,
  },
  mobileDrawer: {
    '& .MuiDrawer-paper': {
      background: theme.palette.common.paleGrey,
    },
  },
  hidden: {
    display: 'none',
  },
}));

const NavPanel = (
  {
    progressValue,
    pages=[],
    invalidServices=[],
    serviceList=[],
    onServiceSelect,
    OnPageInfoClick,
    onServiceDeleteClick,
    onMatchClick,
    isViewMode = false,
    serviceRequestStatusData={},
  }) => {
  const classes = useStyles();

  const [pageInfoComponents, setPageInfoComponents] = useState({generic: [], service: []});
  const [showAddServiceDropdown, setShowAddServiceDropdown] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const isMobile = useMediaQuery({query: '(max-width: 945px)'});

  useEffect(() => {
    const result = pages.reduce((acc, item) => {
      if (!item.isModalityQuestion) {
        acc.generic.push(
          <PageIndicator.Regular
            key={item.text}
            text={item.text}
            status={item?.status}
            isDisabled={item?.isDisabled}
            onClick={() => OnPageInfoClick(item.name)}
          />
        );
      } else {
        const pageIndicator = isViewMode ? (
          <PageIndicator.WithStatus
            key={item.text}
            text={item.text}
            status={item?.status}
            isDisabled={item?.isDisabled}
            onClick={() => OnPageInfoClick(item.name)}
            onMatchClick={() => onMatchClick(item.text)}
            serviceRequestStatus={serviceRequestStatusData[item.text]}
          />
        ) : (
          <PageIndicator.Removable
            key={item.text}
            text={item.text}
            status={item?.status}
            isDisabled={item?.isDisabled}
            onClick={() => OnPageInfoClick(item.name)}
            isDeleteDisabled={item.isDeleteDisabled}
            onDeleteClick={() => onServiceDeleteClick(item.text)}
          />
        );
        acc.service.push(pageIndicator);
      }
      return acc;
    }, {generic: [], service: []});
    setPageInfoComponents(result);
  }, [pages]);  // eslint-disable-line react-hooks/exhaustive-deps

  const render = () => {
    return (
      <div className={classes.root}>
        <ProgressPanel value={progressValue} />
        <div className={classes.container}>
          <div className={classes.pageInfoContainer}>
            {pageInfoComponents.generic}
          </div>
          <div className={classes.servicePageInfoTitle}>
              Service Request
          </div>
          <div className={classes.pageInfoContainer}>
            {pageInfoComponents.service}
            {invalidServices.map(item => (
              <PageIndicator.Regular
                key={item.name}
                text={item.name}
                isInvalid={true}
                onClick={() => {}}
              />
            ))}
          </div>
          {/*Uncomment to have add service button*/}
          {/*{!isViewMode && (*/}
          {/*  <Button*/}
          {/*    variant="contained"*/}
          {/*    fullWidth={true}*/}
          {/*    className={classes.addServiceButton}*/}
          {/*    onClick={() => setShowAddServiceDropdown(prevState => !prevState)}*/}
          {/*  >*/}
          {/*    Add Service*/}
          {/*  </Button>*/}
          {/*)}*/}
          <Collapse key="add-service-collapse" in={showAddServiceDropdown} classes={{hidden: classes.hidden}}>
            <div className={classes.servicesTitle}>Additional Services</div>
            <Dropdown
              options={serviceList}
              onChange={val => {
                setShowAddServiceDropdown(false);
                onServiceSelect(val);
              }}
            />
          </Collapse>
        </div>
      </div>
    );
  };

  const mobileRender = () => {
    return (
      <>
        <BottomMobilePanel
          value={progressValue}
          onClick={() => setIsDrawerOpen(true)}
          isClicked={isDrawerOpen}
        />
        <SwipeableDrawer
          SlideProps={{timeout: {enter: 700, exit: 500}}}
          anchor={'left'}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onOpen={() => setIsDrawerOpen(true)}
          className={classes.mobileDrawer}
        >
          <div className={classes.mobileNavContainer}>
            {render()}
          </div>
        </SwipeableDrawer>
      </>
    );
  };

  return (
    <>
      {isMobile ? mobileRender() : render()}
    </>
  );
};

NavPanel.propTypes = {
  progressValue: PropTypes.number,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  invalidServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  serviceList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onServiceSelect: PropTypes.func.isRequired,
  OnPageInfoClick: PropTypes.func.isRequired,
  onMatchClick: PropTypes.func.isRequired,
  onServiceDeleteClick: PropTypes.func.isRequired,
  isViewMode: PropTypes.bool,
  serviceRequestStatusData: PropTypes.object,
};

export default NavPanel;