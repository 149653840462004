import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

/*
 * This is a conditional routing implementation which conditionally intercepts routes.
 * If profile.activation_required is true, we call the render function.
 * Otherwise we render a switch with a list of routes passed as children.
 */

const ActivationSwitch = ({routes, render}) => {
  const profile = useSelector(state => state.auth.profile);
  if (profile && profile.activation_required) return render();
  return (
    <Switch>
      {routes}
    </Switch>
  );
};

ActivationSwitch.propTypes = {
  routes: PropTypes.node.isRequired,
  render: PropTypes.func.isRequired,
};

export default ActivationSwitch;
