import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import CssBaseline from '@material-ui/core/CssBaseline';

import './index.css';
import App from './App';
import ThemeProvider from './ThemeProvider';
import * as serviceWorker from './serviceWorker';
import config from './config';


if (config.sentry.DSN) {
  Sentry.init({dsn: config.sentry.DSN});
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render((
  <BrowserRouter basename="/">
    <ThemeProvider>
      <CssBaseline/>
      <App />
    </ThemeProvider>
  </BrowserRouter>
));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
