import React from 'react';
import PropTypes from 'prop-types';

const MailTo = ({email, children, ...props}) => {
  return (
    <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  );
};

MailTo.propTypes = {
  email: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  props: PropTypes.object,
};

export default MailTo;
