import React from 'react';

import QuestionsComponent from 'providerSite/components/LandingPage/Questions';
import config from 'config';


const Questions = () => {

  const questions = [
    {
      key: 1,
      header: 'How do I join Clickability?',
      content: (
        <p>
          To join, click&nbsp;
          <a
            href={`${config.website.URL}/providers/signup`}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>.
          You can then create a free, basic account that allows you to be
          seen in the directory and collect reviews. But for more exposure,
          we recommend upgrading to accreditation. This gives you preference
          in the directory search results and allows you to receive service
          requests from clients looking for services like yours.
        </p>
      )
    },
    {
      key: 2,
      header: 'What supports can I promote on Clickability?',
      content: (
        <p>
          People search our platform for all types of support services,
          including Allied Health, daily household tasks, Plan Management
          and Support Coordination.
        </p>
      )
    },
    {
      key: 3,
      header: 'Why should I be accredited?',
      content: (
        <p>
          Every day, hundreds of Australians use Clickability to find disability
          supports. For a small monthly fee, your business will gain more
          reach through our directory and direct leads from clients. We don’t
          charge commission on these leads, so you won’t pay more than the
          monthly fee.
        </p>
      )
    }
  ];

  return (
    <QuestionsComponent
      questions={questions}
    />
  );
};


export default Questions;
