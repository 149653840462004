import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor, Transforms, createEditor, Node } from 'slate';
import { withHistory } from 'slate-history';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import Typography from '@material-ui/core/Typography';
import { cx, css } from 'emotion';

// This component is built using Slate
// https://docs.slatejs.org/
// Comments are left here for posterity. Further optimisation is possible.

// import { Node } from 'slate';
//
// // Define a serializing function that takes a value and returns a string.
// const serialize = value => {
//   return (
//     value
//       // Return the string content of each paragraph in the value's children.
//       .map(n => Node.string(n))
//       // Join them all with line breaks denoting paragraphs.
//       .join('\n')
//   );
// };

// Define a deserializing function that takes a string and returns a value.
const parseText = string => {
  if (string === '' || string === null) {
    return [{ type: 'paragraph', children: [{ text: '' }] }];
  }
  // Return a value array of children derived by splitting the string.
  return string.split('\n').map(line => {
    return {
      children: [{ text: line }],
    };
  });
};

const Button = React.forwardRef(
  ({ className, active, reversed, ...props }, ref) => (
    <span
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          cursor: pointer;
          color: ${reversed
      ? active
        ? 'white'
        : '#535356'
      : active
        ? 'black'
        : '#ccc'};
        `
      )}
    />
  )
);
Button.displayName = 'RTEButton';
Button.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool.isRequired,
  reversed: PropTypes.string,
};

const Menu = React.forwardRef(
  ({ className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          & > * {
            display: inline-block;
          }

          & > * + * {
            margin-left: 15px;
          }
        `
      )}
    />
  )
);
Menu.displayName = 'RTEMenu';
Menu.propTypes = {
  className: PropTypes.string,
};

const Toolbar = React.forwardRef(
  ({ className, ...props }, ref) => (
    <Menu
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          width: 100%;
          position: relative;
          padding: 14px 20px 10px 20px;
          margin: 0;
          border-bottom: 1px solid #e2e2e2;
          margin-bottom: 20px;
        `
      )}
    />
  )
);
Toolbar.displayName = 'RTEToolbar';
Toolbar.propTypes = {
  className: PropTypes.string,
};

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const isJson = value => {
  try { JSON.parse(value); }
  catch (e) { return false; }
  return true;
};

const editableStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '0rem',
    border: 'none',
    borderRadius: '4px',
    '&:hover': {
      border: 'none',
    },
    '&:active': {
      border: 'none',
    },
  },
  label: {
    width: '100%',
    marginBottom: '0.5rem',
  },
  toolbar: {
    width: '100%',
  },
  input: {
    width: 'calc(100% - 30px)',
    maxWidth: '960px',
    padding: '0 20px',
    marginBottom: '1rem',
  },
  totalCount: {
    width: 'max-content',
    border: '1px solid',
    borderColor: props => props.showCharWarning ? '#f5222d' : '#e2e2e2',
    borderRadius: '6px',
    padding: '2px 8px',
    fontSize: '13px',
    color: props => props.showCharWarning ? '#f5222d' : '#9d9d9d',
  },
});

const displayStyles = makeStyles(theme => ({
  root: {
  },
  input: {
    width: '100%',
    padding: '0',
    cursor: 'text',
  },
  render: {
    display: '-webkit-box',
    '-webkit-line-clamp': props => props.seeMoreClicked ? 300 : 8,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    marginBottom: props => props.hasEllipsis && '10px',
  },
  seeMore: {
    position: 'absolute',
    bottom: '8px',
    right: '15px',
    fontSize: '13px',
    fontWeight: 500,
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

// Migrated DB values are text with some leftover html but will be changed to
// stringified JSON on save. This function will not be needed if all
// records were to be updated.
const parseRte = value => ((!(isJson(value))) || value === null) ? parseText(value) : JSON.parse(value);

// Returns concatenated plain text, excluding spaces or line breaks between block nodes.
// See Node.string https://docs.slatejs.org/api/nodes/node#node-string-root-node-string
const richTextToString = (richText) => {
  if (richText === null || richText === undefined) return '';
  try {
    // repackage as Editor, ref: https://docs.slatejs.org/concepts/02-nodes
    const editor = { children: JSON.parse(richText) };
    return Node.string(editor);
  } catch (e) {
    return richText;
  }
};

const MAX_CHAR_LIMIT = 3500;

const RichTextField = (
  {
    inputValue = '',
    label = null,
    allowEdit = false,
    onChange = null,
    id,
    className = ''
  }) => {

  const ref = useRef();
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const [seeMoreClicked, setSeeMoreClicked] = useState(false);

  const totalCharCount = allowEdit && getTotalCharCount(inputValue);
  const shouldShowCharCountWarning = !!totalCharCount && totalCharCount > MAX_CHAR_LIMIT;
  const classes = (allowEdit) ? editableStyles({showCharWarning: shouldShowCharCountWarning}) : displayStyles({
    hasEllipsis: hasEllipsis, seeMoreClicked: seeMoreClicked
  });
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const handleChange = value => allowEdit ? onChange(JSON.stringify(value)) : null;

  const checkEllipsis = () => {
    setHasEllipsis(
      ref?.current?.scrollHeight > ref?.current?.clientHeight + 2   // 2 is threshold
    );
  };

  useEffect(() => {
    if (allowEdit) return;
    checkEllipsis();
  });

  return (
    <Slate
      editor={editor}
      value={allowEdit ? parseRte(inputValue) : getCharacterLimitedContent(parseRte(inputValue), MAX_CHAR_LIMIT)}
      onChange={handleChange}
    >
      {label === null ? null : (
        <Typography variant="subtitle2" className={classes.label}>
          {label}
        </Typography>
      )}
      <div className={classes.root}>
        {allowEdit ? (
          <div className={classes.toolbar}>
            <Toolbar>
              <MarkButton format="bold">
                <FormatBoldIcon />
              </MarkButton>
              <BlockButton format="numbered-list">
                <FormatListNumberedIcon />
              </BlockButton>
              <BlockButton format="bulleted-list">
                <FormatListBulletedIcon />
              </BlockButton>
            </Toolbar>
          </div>
        ) : null}
        <div className={`${classes.input} ${className}`}>
          {allowEdit ? (
            <>
              <Editable
                id={id}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder="We are a trusted provider dedicated to..."
                spellCheck
                onKeyDown={event => {
                  for (const hotkey in HOTKEYS) {
                    if (isHotkey(hotkey, event)) {
                      event.preventDefault();
                      const mark = HOTKEYS[hotkey];
                      toggleMark(editor, mark);
                    }
                  }
                }}
              />
              <div className={classes.totalCount}>
                Character Count: {totalCharCount} / {MAX_CHAR_LIMIT}
              </div>
            </>

          ) : (
            <div ref={ref} className={classes.render}>
              <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                readOnly
              />
              {hasEllipsis && (
                <div onClick={() => setSeeMoreClicked(true)} className={classes.seeMore}>See more...</div>
              )}
            </div>
          )}
        </div>
      </div>
    </Slate>
  );
};
RichTextField.propTypes = {
  inputValue: PropTypes.string,
  label: PropTypes.string,
  allowEdit: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);
  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  });
  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });
  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
toggleBlock.propTypes = {
  editor: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
toggleMark.propTypes = {
  editor: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  });
  return !!match;
};
isBlockActive.propTypes = {
  editor: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};
isMarkActive.propTypes = {
  editor: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
};

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
  case 'bulleted-list':
    return <ul {...attributes}>{children}</ul>;
  case 'list-item':
    return <li {...attributes}>{children}</li>;
  case 'numbered-list':
    return <ol {...attributes}>{children}</ol>;
  default:
    return <p {...attributes}>{children}</p>;
  }
};
Element.propTypes = {
  attributes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  return <span {...attributes}>{children}</span>;
};
Leaf.propTypes = {
  attributes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  leaf: PropTypes.object.isRequired,
};

const BlockButton = ({ format, children }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {children}
    </Button>
  );
};
BlockButton.propTypes = {
  format: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
};

const MarkButton = ({ format, children }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {children}
    </Button>
  );
};
MarkButton.propTypes = {
  format: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
};

const getTotalCharCount = (inputValue) => {
  let count = 0;
  for (const item of parseRte(inputValue)) {
    for (const innerItem of item.children) {
      count += getCharCountRecursively(innerItem, 0);
    }
  }
  return count;
};

const getCharCountRecursively = (inp, count) => {
  if (!inp) return 0;
  if (typeof inp?.text?.length === 'number') return inp?.text?.length;

  for (const innerItem of inp.children) {
    count += getCharCountRecursively(innerItem, count);
  }

  return count;
};

// const getTextFromContent = (content) => {
//   if (Array.isArray(content)) {
//     return content.map(item => getTextFromContent(item)).join('');
//   }
//   if (typeof content === 'object' && content.children) {
//     return content.children.map(item => getTextFromContent(item)).join('');
//   }
//   return content.text || '';
// };

const getCharacterLimitedContent = (content, limit) => {
  let characterCount = 0;

  const truncateText = (text, remainingCharacters) => {
    if (characterCount >= limit) {
      return '';
    }
    if (text.length <= remainingCharacters) {
      characterCount += text.length;
      return text;
    }
    const truncatedText = text.slice(0, remainingCharacters);
    characterCount = limit;
    return truncatedText;
  };

  const traverseContent = (content, remainingCharacters) => {
    if (Array.isArray(content)) {
      const truncatedContent = [];
      for (let i = 0; i < content.length; i++) {
        const truncatedItem = traverseContent(content[i], remainingCharacters);
        if (truncatedItem) {
          truncatedContent.push(truncatedItem);
        }
        if (characterCount >= limit) {
          break;
        }
      }
      return truncatedContent.length > 0 ? truncatedContent : null;
    }

    if (typeof content === 'object' && content.children) {
      const truncatedChildren = content.children.map(item => traverseContent(item, remainingCharacters));
      return truncatedChildren.some(item => item !== null) ? { ...content, children: truncatedChildren } : {children: [{'text': ''}]};
    }

    if (content.text) {
      const truncatedText = truncateText(content.text, remainingCharacters - characterCount);
      return truncatedText ? { ...content, text: truncatedText } : null;
    }

    return content;
  };

  return traverseContent(content, limit - characterCount);
};

// const initialValue = [
//   {
//     type: 'paragraph',
//     children: [
//       { text: 'This is editable ' },
//       { text: 'rich', bold: true },
//       { text: ' text, ' },
//     ],
//   },
//   {
//     type: 'paragraph',
//     children: [
//       {
//         text:
//           'Since its rich text, you can do things like turn a selection of text ',
//       },
//       { text: 'bold', bold: true },
//       {
//         text:
//           ', or add a semantically rendered block quote in the middle of the page, like this:',
//       },
//     ],
//   },
//   {
//     type: 'block-quote',
//     children: [{ text: 'A wise quote.' }],
//   },
//   {
//     type: 'paragraph',
//     children: [{ text: 'Try it out for yourself!' }],
//   },
// ];

export default RichTextField;

export {
  richTextToString,
};
