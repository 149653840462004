import Container from '@material-ui/core/Container';
import ContentContainer from '../../components/ContentContainer.js';
import Grid from '@material-ui/core/Grid';
import {Helmet} from 'react-helmet';
import Markdown from '../../components/Markdown';
import Page from '../../components/Page.js';
import PropTypes from 'prop-types';
import React from 'react';
import RequestForm from '../../components/forms/RequestForm';
import Typography from '@material-ui/core/Typography';
import config from 'config';
import contentBackgroundImg from '../../assets/background-circles.svg';
import heroBackgroundImg from '../../assets/contact-page-background.svg';
import heroImg from '../../assets/contact-page-image.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hero: {
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: `url(${heroBackgroundImg})`,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '4rem 1rem',
    [theme.breakpoints.down('sm')]: {
      padding: '4rem 1rem 1rem 1rem',
    },
  },
  title: {
    width: '100%',
    textAlign: 'left',
    marginBottom: '1rem',
    color: theme.palette.common.white,
  },
  text: {
    textAlign: 'left',
    lineHeight: '2rem',
    color: theme.palette.common.highlightBlue,
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  img: {
    width: '15rem',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
  },
  contentBackground: {
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundImage: `url(${contentBackgroundImg})`,
  },
  formContainer: {
    width: '30rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }
}));

const ContactPage = ({content}) => {
  const classes = useStyles();
  if (!content) return null;
  return (
    <Page title={content.title}>
      <Helmet 
        link = {[{'rel': 'canonical', 'href': `${config.website.URL}${content.path}`}]}
      />
      <div className={classes.hero}>
        <Container maxWidth="lg" fixed disableGutters>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <div className={classes.content}>
                <Typography variant="h1" color="primary" className={classes.title}>
                  We would love to hear from you!
                </Typography>
                <Typography variant="h3" className={classes.text}>
                  We&apos;re here to help participants and providers navigate the NDIS. Whether you&apos;re an existing user or just interested in how we could help you, please contact us by phone, email or the online form below.
                </Typography>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={classes.imgContainer}>
                <img src={heroImg} alt="reviews" className={classes.img} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={classes.contentBackground}>
        <ContentContainer>
          <Markdown content={content.body}/>
          <div className={classes.formContainer}>
            <RequestForm formName="Contact Us Form"/>
          </div>
        </ContentContainer>
      </div>
    </Page>
  );
};

ContactPage.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })
};

export default ContactPage;
