import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { formatComponentId } from '../../lib/util.js';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    padding: 0,
    marginBottom: '0.5rem',
  },
  field: {
    marginRight: '0.5rem',
  },
});

const CheckboxInputField = ({label, value, onChange, ...props}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Checkbox
        id={formatComponentId('checkbox-input', label)}
        checked={value}
        onChange={onChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        className={classes.field}
        {...props}
      />
      <Typography>
        {label}
      </Typography>
    </div>
  );
};

CheckboxInputField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxInputField;
