import { request, makePath } from './http';
const API_NAME = 'analytics';

export function sendTrackEvent(data) {
  return request('POST', makePath(API_NAME, 'track', 'event'), {body: data});
}

export function providerValues(providerUuid, period) {
  return request('GET', makePath(API_NAME, 'provider', providerUuid, 'values'), {params: {period}});
}

export function providerSeries(providerUuid, metric) {
  return request('GET', makePath(API_NAME, 'provider', providerUuid, 'series'), {params: {metric}});
}
