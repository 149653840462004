import React from 'react';

import {
  NDIS_BLOG_URL,
  PAGE_COMMUNICATION,
  PAGE_CONTACT_INFO,
  PAGE_DISABILITY_INFO,
  PAGE_EXIT,
  PAGE_LOCATION,
  PAGE_NDIS_INFO,
  PAGE_NDIS_PLAN,
  PAGE_TELEHEALTH,
  PAGE_PARTICIPANT_DETAILS,
  PAGE_SERVICE,
  PAGE_SERVICE_FOR,
  PAGE_SUCCESS,
  PAGE_SUMMARY,
  PAGE_WHAT_HAPPENS_NEXT,
  PAGE_SUCCESS_DIRECT_REQUEST,
  PLAN_TYPE_AGENCY_MANAGED,
  PLAN_TYPE_PLAN_MANAGED,
  SERVICE_CATEGORY_ALLIED_HEALTH,
  SERVICE_CATEGORY_PLAN_MANAGEMENT,
  SERVICE_CATEGORY_SUPPORT_COORDINATION,
  SERVICE_CATEGORY_SUPPORT_WORK,
  TELEHEALTH_BLOG_URL,
  TELEHEALTH_FIELD,
  DIRECT_CONNECT_FIELD,
  SERVICES_NEEDED_FIELD,
  SERVICES_FOR_FIELD,
  NDIS_PLAN_FIELD,
  LOCATION_FIELD,
  TNC_URL,
  PAGE_FINAL_DETAILS,
  FINAL_DETAILS_FIELD,
  PHONE_FIELD,
  PAGE_VERIFICATION,
  FIRST_NAME_FIELD,
  EMAIL_FIELD,
  FOR_FIELD,
  PARTICIPANT_AGE_RANGE_FIELD,
  SERVICE_FOR_TYPE_MYSELF,
  PARTICIPANT_FIRST_NAME_FIELD,
} from './constants';
import {
  COMMUNICATION_QUESTIONS,
  CONTACT_INFO_QUESTIONS,
  DISABILITY_INFO_QUESTIONS,
  NDIS_INFO_QUESTIONS,
  PARTICIPANT_DETAILS_QUESTIONS
} from './flows/DashboardFlow/questions/generic';
import QuestionFlow from './flows/QuestionFlow';
import DashboardFlow from './flows/DashboardFlow';
import DashboardFlowContainer from './flows/DashboardFlow/DashboardFlowContainer';
import QuestionFlowContainer from './flows/QuestionFlow/QuestionFlowContainer';
import {getFromMyselfServiceType, getYouOrPart, getYourOrParts} from './flows/utils';
import Service from './flows/QuestionFlow/questionComponents/Service';
import ServiceFor from './flows/QuestionFlow/questionComponents/ServiceFor';
import NDISPlan from './flows/QuestionFlow/questionComponents/NDISPlan';
import Location from './flows/QuestionFlow/questionComponents/Location';
import Telehealth from './flows/QuestionFlow/questionComponents/Telehealth';
import WhatHappensNext from './flows/QuestionFlow/questionComponents/WhatHappensNext';
import Summary from './flows/QuestionFlow/questionComponents/Summary';
import Success from './flows/QuestionFlow/questionComponents/Success';
import Exit from './flows/QuestionFlow/questionComponents/Exit';
import FinalDetails from './flows/QuestionFlow/questionComponents/FinalDetails';
import VerificationPage from './flows/QuestionFlow/questionComponents/VerificationPage';



// QuestionFlow Pages

export const questionFlowPageService = {
  name: PAGE_SERVICE,
  progressbar: true,
  flowControlButtons: true,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  questions: [
    {
      field: SERVICES_NEEDED_FIELD,
      title: 'What service do you need?',
      Component: Service,
      isValid: answer => !!answer?.length,
    }
  ]
};

export const questionFlowPageServiceFor = {
  name: PAGE_SERVICE_FOR,
  progressbar: true,
  flowControlButtons: true,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  footer: <>By clicking next you agree to
        our <a href={TNC_URL} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>,
        and you give us consent to email you results related to your enquiry.</>,
  buttonScrollMargin: '130px',
  questions: [
    {
      field: SERVICES_FOR_FIELD,
      title: 'Who needs this service?',
      Component: ServiceFor,
      isValid: answer => {
        if (!answer || !answer[FOR_FIELD]) return false;
        if (answer[FOR_FIELD] === SERVICE_FOR_TYPE_MYSELF) {
          return !!answer[FIRST_NAME_FIELD] && !!answer[EMAIL_FIELD];
        } else {
          return !!answer[FIRST_NAME_FIELD] && !!answer[PARTICIPANT_FIRST_NAME_FIELD] && !!answer[EMAIL_FIELD];
        }
      },
    }
  ]
};

export const questionFlowPageNdisPlan = {
  name: PAGE_NDIS_PLAN,
  progressbar: true,
  flowControlButtons: true,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  footer: <>How your plan is managed will impact the types of providers you can access. If you’re not sure,
  please see <a href={NDIS_BLOG_URL} target="_blank" rel="noopener noreferrer">this helpful article</a> that
  explains each management type.</>,
  questions: [
    {
      field: NDIS_PLAN_FIELD,
      title: 'Who manages the funds in the NDIS plan?',
      Component: NDISPlan,
      isValid: (answer) => answer?.length,
    }
  ]
};

export const questionFlowPageFinalDetails = {
  name: PAGE_FINAL_DETAILS,
  progressbar: true,
  flowControlButtons: true,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  questions: [
    {
      field: FINAL_DETAILS_FIELD,
      title: 'A few more final details',
      Component: FinalDetails,
      isValid: answer => !!answer && !!answer?.[PARTICIPANT_AGE_RANGE_FIELD] && !!answer?.[NDIS_PLAN_FIELD],
    }
  ]
};

export const questionFlowPageVerification = {
  name: PAGE_VERIFICATION,
  progressbar: true,
  flowControlButtons: false,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  questions: [
    {
      field: PHONE_FIELD,
      title: 'Your Perfect Provider Awaits!',
      footer: <>Some providers offer this service online. Benefits include
        shorter wait-times and access anywhere.</>,
      Component: VerificationPage,
      isValid: (answer) => answer?.length,
    }
  ]
};

export const questionFlowPageLocation = {
  name: PAGE_LOCATION,
  progressbar: true,
  flowControlButtons: true,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  isComplete: (answer) => answer?.length,
  footer: 'Letting us know your location will ensure you are connected to providers who deliver services in your area.',
  questions: [
    {
      field: LOCATION_FIELD,
      title: 'Where do you need the service?',
      Component: Location,
      isValid: (answer) => answer?.length,
    }
  ]
};

export const questionFlowPageTelehealth = {
  name: PAGE_TELEHEALTH,
  progressbar: true,
  flowControlButtons: true,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  isHidden: answer => (
    Array.isArray(answer?.servicesNeeded) &&
    answer.servicesNeeded.length &&
    answer.servicesNeeded[0]?.qualifiedServiceCategory !== SERVICE_CATEGORY_ALLIED_HEALTH
  ),
  questions: [
    {
      field: TELEHEALTH_FIELD,
      title: 'Is Telehealth an option for this service?',
      footer: <>Telehealth is the delivery of health care online. Benefits
        include shorter wait-times, more practitioner options, and access
        anywhere. <a href={TELEHEALTH_BLOG_URL} target="_blank" rel="noopener noreferrer">Click Here</a> to
        find out more about how Telehealth works.</>,
      Component: Telehealth,
      isValid: (answer) => answer?.length,
    }
  ]
};

export const questionFlowPageWhatHappensNext = {
  name: PAGE_WHAT_HAPPENS_NEXT,
  flowControlButtons: false,
  hideBackButton: true,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  questions: [
    {
      field: 'whatHappensNext',
      title: 'Great, we just have a couple of more questions...',
      Component: WhatHappensNext,
      isValid: () => true,
    }
  ]
};

export const questionFlowPageSummary = {
  name: PAGE_SUMMARY,
  progressbarShowOnly: true,
  flowControlButtons: false,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  questions: [
    {
      field: '_qualifiedServices',
      title: 'Where should we send the results?',
      footer: <>By clicking next you agree to
        our <a href={TNC_URL} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>,
        and you give us consent to email you related to your enquiry.</>,
      Component: Summary,
      isValid: (answer) => !!answer,
    }
  ]
};

export const questionFlowPageSuccess = {
  name: PAGE_SUCCESS,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  isHidden: answers => !!answers[DIRECT_CONNECT_FIELD],
  isEndOfJourney: true,
  fullWidth: true,
  questions: [
    {
      field: 'success',
      title: 'Great, providers are now reviewing your request',
      subtitle: <>You will be contacted directly by up to 3 providers,
        <br/>so keep your phone close and check your email for more details. </>,
      Component: Success,
      isValid: () => true,
    }
  ]
};

export const questionFlowPageSuccessDirectRequest = {
  name: PAGE_SUCCESS_DIRECT_REQUEST,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  isHidden: answers => !answers[DIRECT_CONNECT_FIELD],
  isEndOfJourney: true,
  questions: [
    {
      field: 'success',
      title: 'Great, we\'ve sent your request!',
      subtitle: <>The provider will look at your request and get in touch in the
        next 48 hours to discuss their services</>,
      Component: Success,
      isValid: () => true,
    }
  ]
};

export const questionFlowPageExit = {
  name: PAGE_EXIT,
  Container: QuestionFlowContainer,
  QuestionContainer: QuestionFlow,
  isHidden: answers => (answers._qualifiedServices && !answers._qualifiedServices.unMatchedServices.length)
    || !!answers[DIRECT_CONNECT_FIELD],
  questions: [
    {
      field: 'exit',
      Component: Exit,
      isValid: () => true,
    }
  ]
};

// DashboardFlow Pages

export const dashboardFlowPageContactInfo = {
  name: PAGE_CONTACT_INFO,
  Container: DashboardFlowContainer,
  QuestionContainer: DashboardFlow,
  questionCount: true,
  data: {
    info: 'Contact Information',
    // eslint-disable-next-line react/display-name
    helpPrompt: () => <>We&apos;re now going to ask a few more specific questions,
      so that we can connect you with the perfect provider and they can get in touch
      with you directly to make sure it’s a good fit.</>,
  },
  questions: CONTACT_INFO_QUESTIONS,
};

export const dashboardFlowPageParticipantDetails = {
  name: PAGE_PARTICIPANT_DETAILS,
  Container: DashboardFlowContainer,
  QuestionContainer: DashboardFlow,
  questionCount: true,
  data: {
    info: 'Participant Details',
    // eslint-disable-next-line react/display-name
    helpPrompt: answers => <>Please tell us a bit more about the participant. The following
      questions will be asked by the provider to understand if&nbsp;
    <strong>{getFromMyselfServiceType(answers, 'you', 'they')}</strong>&nbsp;are
      eligible for some services.</>,
  },
  questions: PARTICIPANT_DETAILS_QUESTIONS,
};

export const dashboardFlowPageCommunication = {
  name: PAGE_COMMUNICATION,
  Container: DashboardFlowContainer,
  QuestionContainer: DashboardFlow,
  questionCount: true,
  data: {
    info: 'Communication',
    // eslint-disable-next-line react/display-name
    helpPrompt: answers => <>Tell us how the provider can best communicate with&nbsp;
      <strong>{getYouOrPart(answers)}</strong>.</>,
  },
  questions: COMMUNICATION_QUESTIONS,
};

export const dashboardFlowPageNdisInfo = {
  name: PAGE_NDIS_INFO,
  Container: DashboardFlowContainer,
  QuestionContainer: DashboardFlow,
  questionCount: true,
  data: {
    info: 'NDIS Information',
    // eslint-disable-next-line react/display-name
    helpPrompt: () => <>Tell us a bit more about the NDIS plan. Providers need
      this information in order to get you started with services.</>,
  },
  isHidden: answers => {
    const validCategories = new Set([SERVICE_CATEGORY_PLAN_MANAGEMENT,
      SERVICE_CATEGORY_SUPPORT_COORDINATION]
    );
    const validPlanTypes = new Set([PLAN_TYPE_AGENCY_MANAGED,
      PLAN_TYPE_PLAN_MANAGED]
    );
    return !(answers?._qualifiedServices?.matchedServices?.some(item => validCategories.has(item.categoryName))
    || validPlanTypes.has(answers?.ndisPlan));
  },
  questions: NDIS_INFO_QUESTIONS,
};

export const dashboardFlowPageDisabilityInfo = {
  name: PAGE_DISABILITY_INFO,
  Container: DashboardFlowContainer,
  QuestionContainer: DashboardFlow,
  questionCount: true,
  isHidden: answers => {
    const validCategories = new Set([SERVICE_CATEGORY_ALLIED_HEALTH,
      SERVICE_CATEGORY_SUPPORT_COORDINATION, SERVICE_CATEGORY_SUPPORT_WORK]
    );
    return !answers?._qualifiedServices?.matchedServices?.some(item => validCategories.has(item.categoryName));
  },
  data: {
    info: 'Disability Information',
    // eslint-disable-next-line react/display-name
    helpPrompt: answers => <>Please tell us a bit more about&nbsp;
      <strong>{getYourOrParts(answers)} disability</strong>. This information helps
      us and the provider understand the supports you need, so we get the right match.</>,
  },
  questions: DISABILITY_INFO_QUESTIONS,
};
