// The content is a massively nested ordered list.  List items are mostly <p>
// level 1: 1. Bold Title text
// level 2:   1.1 Normal text
// level 3:     (a) Normal text
// level 4+:       1. Normal text

const policyStyle = () => ({
  container: {

    padding: '2rem 0 4rem 0',

    // level 1

    '&>ol': {
      counterReset: 'section',
    },
    '&>ol>li': {
      counterIncrement: 'section',
      fontWeight: 700,
      fontSize: '1.1rem',
    },
    // only first paragraph is bold and large
    '&>ol>li>p:not(:first-child)': {
      fontWeight: 400,
      fontSize: '0.9rem',
      lineHeight: '1.43'
    },

    // level 2

    '&>ol>li>ol': {
      position: 'relative',
      listStyleType: 'none',
      counterReset: 'section',
      fontWeight: 400,
      fontSize: '0.9rem',
      lineHeight: '1.43'
    },
    '&>ol>li>ol>li::before': {
      counterIncrement: 'section',
      content: 'counters(section, ".") " "',
      position: 'absolute',
      left: 0,
    },
    // Whitespace is applied in Markdown for li>p but not for li>text.  Markdown only creates <p>
    // if there are multiple paragraphs in the <li>
    // This makes sure l2 paragraphs have equivalent whitespace even if they don't have <p> children.
    '&>ol>li>ol>li': {
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
    },

    // level 3

    '&>ol>li>ol>li>ol': {
      position: 'relative',
      listStyleType: 'none',
      counterReset: 'section',
      fontWeight: 400,
      fontSize: '0.9rem',
      lineHeight: '1.43'
    },
    '&>ol>li>ol>li>ol>li::before': {
      counterIncrement: 'section',
      content: '"(" counter(section, lower-alpha) ")"',
      position: 'absolute',
      left: 0,
    },

    // level 4

    '&>ol>li>ol>li>ol>li>ol': {
      position: 'relative',
      listStyleType: 'none',
      counterReset: 'section',
      fontWeight: 400,
      fontSize: '0.9rem',
      lineHeight: '1.43'
    },
    '&>ol>li>ol>li>ol>li>ol>li::before': {
      counterIncrement: 'section',
      content: 'counter(section, lower-roman) "."',
      position: 'absolute',
      left: 0,
    },

    '&li p': {  // to align items with section numbers
      marginTop: 0,
    },

  }
});

export default policyStyle;
