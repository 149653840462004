import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
  root: {
    width: '4rem',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '2.5rem',
  },
}));

const ValidationMark = ({ active=false, errorText }) => {
  const classes = useStyles();
  const valid = errorText === undefined || errorText === '';
  // return empty div if not using validation to maintain size consistency with other inputs
  return (
    <div className={classes.root}>
      {active ? (
        (valid ? (
          <CheckCircleIcon
            color="secondary"
            className={classes.icon}
          />
        ) : (
          <CancelIcon
            color="error"
            className={classes.icon}
          />
        ))
      ) : null}
    </div>
  );
};

ValidationMark.propTypes = {
  active: PropTypes.bool,
  errorText: PropTypes.string,
};

export default ValidationMark;
