import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { brokerageFlow } from '../flows';
import clickabilityLogo from 'assets/logo-tagline.svg';
import style from 'style';
import BrokerageFormFlow from './BrokerageFormFlow';


const BrokerageFlowPartner = (
  {
    source,
    title,
    FooterComponent,
    logo,
    partnerLink,
    excludedServices
  }) => {

  return (
    <Page title={title} Footer={FooterComponent} logo={logo} partnerLink={partnerLink}>
      <BrokerageFormFlow
        flow={brokerageFlow}
        source={source}
        excludedServices={excludedServices}
      />
    </Page>
  );
};

BrokerageFlowPartner.propTypes = {
  source: PropTypes.string.isRequired,
  title: PropTypes.string,
  FooterComponent: PropTypes.elementType,
  logo: PropTypes.string,
  partnerLink: PropTypes.string,
  excludedServices: PropTypes.array,
};

const PageContainer = styled.div`
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Page = ({ children, title, Footer, logo, partnerLink }) => {
  document.title = title;

  return (
    <PageContainer>
      <NavBar logo={logo} partnerLink={partnerLink} />
      <div style={{minHeight: '80vh'}}>
        {children}
      </div>
      <Footer />
    </PageContainer>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  Footer: PropTypes.elementType,
  logo: PropTypes.string,
  partnerLink: PropTypes.string,
};

const NavBarContainer = styled.div`
  width: 100%;
  z-index: 1;
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  .logo {
    width: auto;
    height: 40px;
  }
`;

const NavBarMain = styled.div`
  height: ${style.constants.navBarHeight};
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${style.shadow(35)};
  border-bottom: 2px solid;
  border-bottom-color: ${style.colors.palette.white};
  background-color: ${style.colors.palette.white};
  z-index: 1;
`;

const NavBar = ({ logo, partnerLink }) => {
  return (
    <NavBarMain>
      <NavBarContainer>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={{ pathname: 'https://clickability.com.au/' }}
        >
          <img src={clickabilityLogo} alt="clickability-logo" className="logo" />
        </Link>
        {partnerLink ? 
          <Link 
            target="_blank" 
            rel="noopener noreferrer"
            to={partnerLink ? { pathname: partnerLink } : '/'}>
            <img src={logo} alt="partner-logo" className="logo" />
          </Link>
          : <img src={logo} alt="partner-logo" className="logo" />
        }
      </NavBarContainer>
    </NavBarMain>
  );
};

NavBar.propTypes = {
  logo: PropTypes.string,
  partnerLink: PropTypes.string,
};

export default BrokerageFlowPartner;