import { useHistory, useLocation } from 'react-router-dom';

import AuthFormPage from './components/AuthFormPage';
import ContentContainer from '../../components/ContentContainer';
import React from 'react';

const PostSignupPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { email } = location.state || {};
  if (email === undefined) {
    history.push('/signup');
  }

  return (
    <AuthFormPage title="Clickability Signup">
      <ContentContainer maxWidth='sm' title="Check Your Email To Complete Signup">
        <p>
          We&apos;ve sent an email to <b>{email}</b> with a link to activate your account. Please check your junk and/or filtered folders in your inbox.
        </p>
        <p>
          Please follow the instructions in the email to set your password and get started.
        </p>
        <p>
          Thanks for signing up!
        </p>
      </ContentContainer>
    </AuthFormPage>
  );
};

export default PostSignupPage;
