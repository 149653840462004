import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';

import Card from './Card';
import RichTextField from 'components/input/RichTextField';


const Container = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 80;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Overview = ({ data, isLoading }) => {

  return (
    <Card
      title="Overview"
      icon={faCircleInfo}
      isLoading={isLoading}
      style={{position: 'relative'}}
      noData={_.isEmpty(data)}
    >
      <Container>
        <RichTextField inputValue={data}/>
      </Container>
    </Card>
  );
};

Overview.propTypes = {
  data: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Overview;
