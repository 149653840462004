import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { getImageHeightAndSize } from 'lib/util';
import placeholderImage from 'assets/physiotherapist-and-boy-holding-ball-285x161.png';


const imageWidth = 350;
const { imageHeight, imageSize } = getImageHeightAndSize(imageWidth);


const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid',
    borderColor: theme.palette.common.lightBorder,
    borderRadius: '12px',
    width: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.common.white,
    boxShadow: theme.shadow(15),
    overflow: 'hidden',
  },
  image: {
    width: imageWidth,
    height: imageHeight,
    border: '1px solid',
    borderColor: theme.palette.common.lightBorder,
    overflow: 'hidden',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '280px',
    overflow: 'hidden',
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: '500',
    color: theme.palette.primary.main,
    padding: '20px 0',
  },
}));

const Card = ({ title, imagePath }) => {

  const classes = useStyles();

  const imageSrc = imagePath ? (imagePath + imageSize) : placeholderImage;

  return (
    <div className={classes.root}>
      <img className={classes.image} src={imageSrc} alt={title}/>
      <div className={classes.title}>For {title}</div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  imagePath: PropTypes.string,
};

export default Card;