import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';

import style from 'style';


const Container = styled.div`
  position: relative;
  border: 1px solid ${style.colors.lightBorder};
  border-radius: 14px;
  width: 300px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${style.shadow(15)};
  font-size: 15px;
  color: #f6f6f6;
  cursor: ${({$disabled}) => $disabled ? 'not-allowed' : 'pointer'};
  user-select: none;

  background: linear-gradient(to right, #21bfaf 50%, #d7d7d7 50%) right bottom;
  background-size: 200% 100%;
  animation-name: left-to-right-animation;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  @keyframes left-to-right-animation {
    from {
      background-position: right bottom;
    }
    to {
      background-position: left bottom;
    }
  }
  
  :hover {
    filter: ${({$disabled}) => !$disabled && 'brightness(105%)'};
  }
  
  .loading-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
  }
`;

const AnimatedButton = ({ children, loading, disabled, onClick }) => {
  const isDisabled = disabled ? true : !!loading;

  return (
    <Container $disabled={isDisabled} onClick={() => !isDisabled && onClick()}>
      {children}
      {loading && <FontAwesomeIcon icon={faCircleNotch} className="loading-icon" spin />}
    </Container>
  );
};

AnimatedButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};


export default AnimatedButton;

