import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { faMapLocation } from '@fortawesome/pro-light-svg-icons';

import Card from './Card';
import LocationTree from './LocationTree';


const Container = styled.div`
  width: 100%;
`;

const ServiceLocations = ({ data, isLoading }) => {

  return (
    <Card
      title="Service Areas"
      icon={faMapLocation}
      isLoading={isLoading}
      noData={_.isEmpty(data)}
    >
      <Container>
        <LocationTree data={data} />
      </Container>
    </Card>
  );
};

ServiceLocations.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default ServiceLocations;
