import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import Question from './Question';
import MultiChoiceSelection from '../../components/MultiChoiceSelection';
import {ERROR_CONTAINER_HEIGHT, QUESTION_BORDER_COLOR} from '../../../constants';


const useStyles = makeStyles(theme => ({
  root: {
  },
  textBoxTitle : {
    marginTop: '15px',
    marginBottom: '15px',
  },
  textBox: {
    borderRadius: '8px',

    '& .MuiOutlinedInput-root': {
      background: theme.palette.common.paleGrey,
      // To stop mobile devices auto zoom while typing inputs
      [theme.breakpoints.down('sm')]: {
        '& input': {
          fontSize: '16px'
        },
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      borderColor: QUESTION_BORDER_COLOR,
    },

    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: '13px'
    }
  },
  multiChoice: {
    marginTop: '15px',
    justifyContent: 'left',
  },
  errorContainer: {
    height: `calc(${ERROR_CONTAINER_HEIGHT} - 3px)`,
    fontSize: '13px',
    color: theme.palette.error.main,
    marginLeft: '15px',
    marginTop: '3px',
  },
}));

const MultiChoiceQuestion = (
  {
    text,
    helperText,
    onChange,
    options,
    placeholder,
    value: defaultValue,
    isOptional,
    answers,
  }) => {

  const classes = useStyles();

  const optionsSet = new Set(options);

  const values = defaultValue?.reduce((acc, item) => {
    if (optionsSet.has(item))
      acc.multiChoice.push(item);
    else
      acc.text = item;
    return acc;
  }, {multiChoice: [], text: null});

  const [multiChoiceValues, setMultiChoiceValues] = useState(values && values.multiChoice);
  const [textValue, setTextValue] = useState(values && values.text);

  const isError = () => {
    return (Array.isArray(multiChoiceValues) && !multiChoiceValues.length && !textValue);
  };

  useEffect(() => {
    if (!multiChoiceValues && !textValue)
      return;

    const result = [];
    multiChoiceValues && result.push(...multiChoiceValues);
    textValue && result.push(textValue);
    onChange(result);
  }, [multiChoiceValues, textValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <Question title={text} subTitle={helperText} isOptional={isOptional} answers={answers}>
        <MultiChoiceSelection
          data={options}
          onChange={val => {
            setMultiChoiceValues(val);
          }}
          selectedValues={multiChoiceValues}
          className={classes.multiChoice}
        />
        <div className={classes.textBoxTitle}>Other, please specify</div>
        <TextField
          className={classes.textBox}
          defaultValue={textValue}
          placeholder={placeholder}
          variant="outlined"
          fullWidth={true}
          onChange={event => {
            setTextValue(event.target.value);
          }}
        />
        <div className={classes.errorContainer}>
          {isError() && 'Please select an option'}
        </div>
      </Question>
    </div>
  );
};

MultiChoiceQuestion.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  isOptional: PropTypes.func,
  answers: PropTypes.object,
};

export default MultiChoiceQuestion;