import { makeStyles, withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import React from 'react';

const StyledRating = withStyles(theme => ({
  iconFilled: {
    color: theme.palette.common.tealish,
  },
}))(Rating);

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignContent: 'left',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

const StarRating = ({rating=null, precision=0.5, readonly=true, ...props}) => {
  const classes = useStyles();
  const cleanedRating = (rating === null) ? 0 : rating;
  return (
    <div className={classes.root}>
      <StyledRating precision={precision} value={cleanedRating} readOnly={readonly} {...props}/>
    </div>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number,
  readonly: PropTypes.bool,
  precision: PropTypes.number,
};

export default StarRating;
