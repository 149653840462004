import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MailTo from '../../../components/MailTo';
import config from '../../../config';
import { ReactComponent as FacebookIcon } from '../../../icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../icons/twitter.svg';
import { ReactComponent as LinkedInIcon } from '../../../icons/linkedIn.svg';
import { ReactComponent as EmailIcon } from '../../../icons/envelope.svg';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem',
    paddingRight: '2rem',
  },
  list: {
    listStyleType: 'none',
    paddingInlineStart: 0,
  },
  listItem: {
    padding: 0,
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },

  },
  iconButton: {
    fontSize: '0.9rem',
    color: '#545456',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
  },
}));

const facebookLink = 'https://facebook.com/clickability.com.au';
const linkedInUrl = 'https://www.linkedin.com/company/clickability---rated-reviewed-reliable';
const twitterUrl = 'https://twitter.com/_clickability';

const BlogSocialLinks = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <a className={classes.link} href={facebookLink} target="_blank" rel="noopener noreferrer">
            <IconButton aria-label="facebook" className={classes.iconButton} >
              <FacebookIcon fontSize="default" className={classes.icon} />
            </IconButton>
            Facebook
          </a>
        </li>
        <li className={classes.listItem}>
          <a className={classes.link} href={twitterUrl} target="_blank" rel="noopener noreferrer">
            <IconButton aria-label="twitter" className={classes.iconButton} >
              <TwitterIcon fontSize="default" className={classes.icon} />
            </IconButton>
            Twitter
          </a>
        </li>
        <li className={classes.listItem}>
          <a className={classes.link} href={linkedInUrl} target="_blank" rel="noopener noreferrer">
            <IconButton aria-label="linkedin" className={classes.iconButton} >
              <LinkedInIcon fontSize="default" className={classes.icon} />
            </IconButton>
            LinkedIn
          </a>
        </li>
        <li className={classes.listItem}>
          <MailTo className={classes.link} email={config.socials.blogEmail}>
            <IconButton aria-label="email" className={classes.iconButton} >
              <EmailIcon fontSize="default" className={classes.icon} />
            </IconButton>
            Email
          </MailTo>
        </li>
      </ul>
    </div>
  );
};

export default BlogSocialLinks;
