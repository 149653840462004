import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import Modal from 'components/Modal';
import ColorButton from './ColorButton';


const useStyles = makeStyles( theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '12px',
    maxWidth: '600px',
    margin: '0 30px',
  },
  modalContainer: {
    padding: '5px 30px 30px 30px',
  },
  modalButtonContainer: {
    marginTop: '30px',
  },
  modalHeader: {
    fontSize: '20px',
    fontWeight: '600',
    borderBottom: '1px solid #e2e2e2',
    padding: '20px 30px 15px 30px',
    color: theme.palette.warning.main,
  },
  modalQuestion: {
    fontSize: '16px',
    fontWeight: '500',
    marginTop: '20px',
    marginBottom: '15px',
  },
  popupErrorModalHeader: {
    color: theme.palette.common.dustyOrange,
  },
  errorModalBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  errorPaper: {
    borderRadius: '12px',
    maxWidth: '600px',
    backgroundColor: theme.palette.error.main,
    boxShadow: 'none',
    position: 'fixed',
    bottom: '30px',
    left: '30px',
  },
  inlineClick: {
    textDecoration: 'underline',
    cursor: 'pointer',
  }
}));


const ErrorAlert = ({ error, errorMessage, errorMessages={}, handleErrorClose }) => {

  const classes = useStyles();

  const renderRegularAlertError = () => {
    return (
      <Modal
        isOpen={error}
        backdropClassName={classes.errorModalBackdrop}
        paperClassName={classes.errorPaper}
        onClick={e => e.stopPropagation()}
        onClose={handleErrorClose}
      >
        <Alert variant="filled" severity="error" onClose={handleErrorClose}>
          Error. Action cannot be completed. Please&nbsp;
          <span
            className={classes.inlineClick}
            onClick={() => window.location.reload()}
          >
            reload
          </span>
          &nbsp;the page.
          {errorMessage && (
            <div>
              {errorMessage}
            </div>
          )}
        </Alert>
      </Modal>
    );
  };

  const renderPopupErrorWithMsg = (header, body) => {
    return (
      <Modal
        modalClassName={classes.modal}
        isOpen={error}
        paperClassName={classes.paper}
        onClick={e => e.stopPropagation()}
        onClose={handleErrorClose}
      >
        <div
          className={classNames(classes.modalHeader, classes.popupErrorModalHeader)}
        >
          {header}
        </div>
        <div className={classes.modalContainer}>
          <div className={classes.modalQuestion}>{body}</div>
          <div className={classes.modalButtonContainer}>
            <ColorButton
              variant="contained"
              disableElevation
              onClick={handleErrorClose}
            >
            Close
            </ColorButton>
          </div>
        </div>
      </Modal>
    );
  };

  return (errorMessage in errorMessages ?
    renderPopupErrorWithMsg(errorMessages[errorMessage]?.header, errorMessages[errorMessage]?.body)
    : renderRegularAlertError());
};

ErrorAlert.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorMessages: PropTypes.object,
  handleErrorClose: PropTypes.func,
};

export default ErrorAlert;