import React from 'react';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import Page from 'providerSite/components/Page';
import FormFlow from 'components/formflow/FormFlow';
import flow from './flow';
import style from 'style';
import { FIELD_EMAIL } from './constants';


const Main = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 70px 0;
  @media (max-width: ${style.screens.mobile}){
    margin: 30px 0;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1000px;
`;

const Signup = () => {
  const location = useLocation();
  const showSpecialTier = new URLSearchParams(location.search).get('promo') === 'X6RGZCNR';

  const preFilledData = {
    goal: ['connect', 'growth'].includes(location.state?.goal) ? location.state?.goal : null,
    user: {
      [FIELD_EMAIL]: location.state?.email
    },
    _showSpecialTier: showSpecialTier,
  };

  return (
    <Page plain>
      <Main>
        <Container>
          <FormFlow
            flow={flow}
            onComplete={() => {}}
            forcedDefaults={preFilledData}
          />
        </Container>
      </Main>
    </Page>

  );
};


export default Signup;
