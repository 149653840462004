import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Button as AntdButton, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';
import BottomContainer from 'providerSite/components/LandingPage/Bottom';
import Text from 'providerSite/components/Text';
import _InputWithButton from 'providerSite/components/InputWithButton';
import { currentProductUrl } from 'providerSite/components/Navbar/components/utils';


const InputWithButton = styled(_InputWithButton)`
  
   justify-content: center;
  
  .ant-input {
    background: ${style.colors.palette.white};
    color: ${style.colors.palette.black};
  }
`;

const IconWithButton = styled(AntdButton)`
  margin-top: 30px;
  display: flex;
  column-gap: 6px;
  align-items: center;
  justify-content: center;
  line-height: normal;
`;

const Bottom = ({ image, useIconBtn = false, buttonText = 'GET STARTED' }) => {
  const history = useHistory();
  const inputRef = useRef(null);
  const currentUrl = currentProductUrl();
  const onSubmitHandler = () => {
    const inputEl = inputRef.current;
    history.push('/providers/signup', { email: inputEl.input?.value });
  };
  return (
    <BottomContainer img={image}>
      <Typography.Title className="title">
        <span style={{color: style.colors.secondary}}>Grow your business</span> with
        Clickability today!
      </Typography.Title>
      <Text style={{color: style.colors.palette.white, margin: '30px 0'}}>
        Create your business profile and start receiving service requests
      </Text>
      {useIconBtn ? (
        <IconWithButton
          type="primary"
          size="large"
          onClick={() => history.push(`${currentUrl}/signup`)}
        >
          {buttonText}
          <FontAwesomeIcon icon={faGreaterThan} color={style.colors.palette.white}/>
        </IconWithButton>
      ) : (
        <InputWithButton
          text={buttonText}
          inputProps={{placeholder: 'Your email address', ref: inputRef}}
          onSubmit={onSubmitHandler}
        />
      )}
    </BottomContainer>
  );
};

Bottom.propTypes = {
  useIconBtn: PropTypes.bool,
  buttonText: PropTypes.string,
  image: PropTypes.string
};


export default Bottom;
