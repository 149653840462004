import React from 'react';
import PropTypes from 'prop-types';

import { Tree } from 'antd';


const LocationTree = ({ data }) => {

  const treeData = data?.map(item => ({
    title: item.state,
    key: item.state,
    children: item.regions.map(region => ({
      title: region,
      key: region,
      isLeaf: true,
    })),
  }));

  return (
    <Tree.DirectoryTree
      selectable={false}
      showLine={{showLeafIcon: false}}
      showIcon={false}
      treeData={treeData}
    />
  );
};

LocationTree.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default LocationTree;
