import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import {Collapse, MenuItem, TextField} from '@material-ui/core';

import Question from './Question';
import {ERROR_CONTAINER_HEIGHT, QUESTION_BORDER_COLOR} from '../../../constants';


const useStyles = makeStyles(theme => ({
  root: {
  },
  dropdown: {
    minWidth: '150px',

    '& .MuiInput-underline:before': {
      border: 'none',
    },
  },
  dropdownContainer: {
    padding: '10px',
    background: theme.palette.common.paleGrey,
    border: '1px solid',
    borderColor: QUESTION_BORDER_COLOR,
    borderRadius: '8px',
    height: '55px',
    width: 'fit-content',

    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      outline: '0',
      border: 'none',
      boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}`,
    },

    '& .MuiInput-underline:before': {
      border: 'none',
    },

    '& .MuiInput-underline:after': {
      border: 'none',
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none'
    },

    '& .MuiSelect-select:focus': {
      background: 'none',
    },

    '& .MuiInputBase-input': {
      paddingTop: '9px',
    },
  },
  textBox: {
    marginTop: '15px',
    borderRadius: '8px',

    '& .MuiOutlinedInput-root': {
      background: theme.palette.common.paleGrey,
      // To stop mobile devices auto zoom while typing inputs
      [theme.breakpoints.down('sm')]: {
        '& input': {
          fontSize: '16px'
        },
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      borderColor: QUESTION_BORDER_COLOR,
    },

    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: '13px'
    }
  },
  hidden: {
    display: 'none',
  },
  errorContainer: {
    height: `calc(${ERROR_CONTAINER_HEIGHT} - 3px)`,
    fontSize: '13px',
    color: theme.palette.error.main,
    marginLeft: '15px',
    marginTop: '3px',
  },
}));

const DropdownQuestion = (
  {
    text,
    helperText,
    onChange,
    options,
    otherOption=false,
    value: defaultValue,
    isValid,
    errorMessage,
    placeholder,
    isOptional,
    answers,
    optionsFromAnswers=() => {},
  }) => {

  const _options = options ? options : optionsFromAnswers(answers);

  const classes = useStyles();
  const menuItemOther = 'Other, please specify';
  const optionsSet = new Set(_options);

  const isTextFieldItem = () => {
    if (!defaultValue) return false;
    return !optionsSet.has(defaultValue);

  };

  const [isOtherSelected, setIsOtherSelected] = useState(isTextFieldItem());
  const [valid, setValid] = useState(isOptional);
  const [canShowError, setCanShowError] = useState(false);
  const [textboxContentLength, setTextboxContentLength] = useState(0);

  return (
    <div className={classes.root}>
      <Question title={text} subTitle={helperText} isOptional={isOptional} answers={answers}>
        <div className={classes.dropdownContainer} tabIndex="0">
          <Select
            value={isOtherSelected ? menuItemOther : (defaultValue ? defaultValue : '')}
            onChange={event => {
              const val = event.target.value;
              if (val === menuItemOther) {
                setIsOtherSelected(true);
                onChange('');
                setValid(false);
                setTextboxContentLength(0);
              } else {
                setIsOtherSelected(false);
                onChange(val);
                setValid(true);
                setTextboxContentLength(val.length);
              }
            }}
            className={classes.dropdown}
          >
            {_options?.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            {otherOption && <MenuItem key={menuItemOther} value={menuItemOther}>{menuItemOther}</MenuItem>}
          </Select>
        </div>
        <Collapse key="dropdown-textfield-collapse" in={isOtherSelected} classes={{hidden: classes.hidden}}>
          <TextField
            onFocus={() => setCanShowError(false)}
            onBlur={() => setCanShowError(true)}
            className={classes.textBox}
            defaultValue={optionsSet.has(defaultValue) ? '' : defaultValue}
            placeholder={placeholder}
            variant="outlined"
            fullWidth={true}
            error={canShowError && !valid}
            helperText={canShowError && !valid && (
              textboxContentLength === 0 ?
                'Please complete this field' : (errorMessage ?
                  errorMessage : 'Enter a valid input')
            )}
            onChange={event => {
              const val = event.target.value;
              onChange(val);
              setTextboxContentLength(val.length);
              setValid(isValid(val));
            }}
          />
        </Collapse>
        {(!canShowError || (canShowError && valid)) && (
          <div className={classes.errorContainer} />
        )}
      </Question>
    </div>
  );
};

DropdownQuestion.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  isOptional: PropTypes.func,
  answers: PropTypes.object,
  otherOption: PropTypes.bool,
  isValid: PropTypes.func,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  optionsFromAnswers: PropTypes.func,
};

export default DropdownQuestion;