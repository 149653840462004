import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { formatComponentId } from '../../lib/util.js';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1rem 0',
  },
  label: {
    width: '100%',
    marginBottom: '0.5rem',
  },
  field: {
    minWidth: '10rem',
  },
});

const SelectInputField = ({label, value=null, options, ...selectProps}) => {
  const classes = useStyles();
  const inputValue = (value === null) ? '' : value;
  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      <Select
        id={formatComponentId('select-input', label)}
        value={inputValue}
        className={classes.field}
        variant="outlined"
        {...selectProps}
      >
        {Array.isArray(options) ?
          options.map(item => (<MenuItem key={item} value={item.toLowerCase()}>{item}</MenuItem>)) :
          Object.entries(options).map(([value, label]) => (<MenuItem key={value} value={value}>{label}</MenuItem>))
        }
      </Select>
    </div>
  );
};

SelectInputField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default SelectInputField;
