import { faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import BrokerageFlowPartner from './BrokerageFlowPartner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React from 'react';
import { Typography } from 'antd';
import config from 'config';
import logo from 'assets/partners/myp-logo-top.png';
import mypBottomLogo from 'assets/partners/myp-logo-bottom.png';
import style from 'style';
import styled from 'styled-components';

const BrokerageFlowMyp = () => {

  return (
    <BrokerageFlowPartner
      source="myp"
      title="MYP - Service Request"
      FooterComponent={Footer}
      logo={logo}
    />
  );
};

const FooterMain = styled.div`
  width: 100%;
  background-color: ${style.colors.primary};
  display: flex;
  justify-content: center;
`;

const FooterContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  
  @media (max-width: ${style.screens.mobile}) {
    flex-direction: column;
  }
`;

const MypBottomLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  .logo {
    width: 200px;
    height: auto;
  }
  .logoText {
    color: ${style.colors.palette.white};
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  
  .link {
    color: ${style.colors.palette.white};
    :hover {
        color: ${style.colors.secondary};
    }
  }
  
  @media (max-width: ${style.screens.mobile}) {
    text-align: center;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  column-gap: 30px;
  font-size: 20px;

  .icon {
    cursor: pointer;
    font-size: 25px;
    color: ${style.colors.palette.white};
    &:hover {
      color: ${style.colors.secondary};
    }
  }
  
  @media (max-width: ${style.screens.mobile}) {
   justify-content: center;
  }
`;

const LeftContainer = styled.div`
  ul {
    list-style-type: none;
    
    li {
      margin: 15px 0 15px -38px;
    }
    .link {
      color: ${style.colors.palette.white};
      :hover {
        color: ${style.colors.secondary};
      }
    }
  
  }

  @media (max-width: ${style.screens.mobile}) {
    text-align: center;
    margin: 25px 0;
  }
`;

const TextTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${style.colors.palette.white};
`;

const Footer = () => {
  const handleSocialClick = url => window.open(`https://www.${url}`);
  return (
    <FooterMain>
      <FooterContainer>
        <MypBottomLogoContainer>
          <Typography.Text className="logoText">
            Brought to you by
          </Typography.Text>
          <Link to="/">
            <img src={mypBottomLogo} alt="myp-bottom-logo" className="logo" />
          </Link>
        </MypBottomLogoContainer>
        <LeftContainer>
          <TextTitle>
            Resources
          </TextTitle>
          <ul>
            <li>
              <Link 
                target="_blank"
                rel="noopener noreferrer"
                to={{ pathname: config.link.participantFaq }}
              >
                <Typography className="link">Help & Support</Typography>
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                to={{ pathname: 'https://mypcorp.com.au/our-team/' }}
              >
                <Typography className="link">About myp</Typography>
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={{
                  pathname: 'https://clickability.com.au/about/'
                }}
              >
                <Typography className="link">About Clickability</Typography>
              </Link>
            </li>
          </ul>
        </LeftContainer>
        <RightContainer>
          <SocialContainer>
            <FontAwesomeIcon
              className="icon"
              icon={faFacebookSquare}
              onClick={() => handleSocialClick('facebook.com/MYPCorp')}
            />
            <FontAwesomeIcon
              className="icon"
              icon={faLinkedin}
              onClick={() => handleSocialClick('linkedin.com/company/myp-corporation/')}
            />
          </SocialContainer>
          <Link
            target="_blank"
            to={{ pathname: 'https://mypcorp.com.au/privacy-policy/' }}
          >
            <Typography className="link">myp Privacy Policy</Typography>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={{
              pathname: 'https://clickability.com.au/privacy/'
            }}
          >
            <Typography className="link">Clickability Privacy Policy</Typography>
          </Link>
        </RightContainer>
      </FooterContainer>
    </FooterMain>
  );
};

export default BrokerageFlowMyp;