import React from 'react';
import { useParams } from 'react-router-dom';

import ConnectDashboard from '../components/ConnectDashboard';
import Header from '../components/Header';
import UserManager from 'modules/admin/components/UserManager';


const User = () => {

  const { uuid: providerUuid } = useParams();

  return (
    <ConnectDashboard title="Users">
      <Header text="Manage Users and Access" />
      <UserManager providerUuid={providerUuid} />
    </ConnectDashboard>
  );
};


export default User;