import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import Dialog from '@material-ui/core/Dialog';
import {default as StdButton} from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => {
  return {
    paper: {
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderWidth: '5px',
    },
    button: {
      marginLeft: '1rem'
    },
    error:{
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover':{
        backgroundColor: theme.palette.error.dark
      },
      '&:disabled':{
        backgroundColor: theme.palette.error.light
      }
    }
  };
});

const Confirmation = ({onCancel, onConfirm, busy, title, message, error, action='OK', cancelLabel='Cancel', children, disabled=false}) => {
  const classes = styles();
  const buttonClass = error ? `${classes.button} ${classes.error}` : classes.button;
  return (
    <Dialog open={true} onClose={onCancel} aria-labelledby="form2-dialog-title" classes={{paper: classes.paper}}>
      <DialogTitle id="form2-dialog-title" className={classes.title}>{title}</DialogTitle>
      <DialogContent>
        {message ? <DialogContentText>{message}</DialogContentText> : children}
      </DialogContent>
      <DialogActions>
        <StdButton onClick={onCancel} color="primary">
          {cancelLabel}
        </StdButton>
        <Button onClick={onConfirm} color="primary" variant="contained" label={action} className={buttonClass} busy={busy} disabled={disabled}/>
      </DialogActions>
    </Dialog>
  );
};

Confirmation.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.node,
  children: PropTypes.node,
  action: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Confirmation;
