import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { getHexWithOpacity } from 'lib/util';


const useStyles = makeStyles( theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '8px',
    textAlign: 'center',
    width: '100%',
    padding: '8px 10px',
    cursor: 'pointer',
    fontSize: '13px',
    fontWeight: '500',
    '&:not(:last-child)': {
      borderBottom: '1px solid',
      borderBottomColor: ({color}) => getHexWithOpacity(color ? color : theme.palette.info.main, 20),
    },
    backgroundColor: ({color}) => getHexWithOpacity(color ? color : theme.palette.info.main, 7),
    color: ({color}) => color ? color : theme.palette.info.main,
    '&:hover': {
      backgroundColor: ({color}) => getHexWithOpacity(color ? color : theme.palette.info.main, 10),
    },
  },
  icon: {
    fontSize: '15px',
    marginTop: '-1px',
  },
}));


const OptionItem = ({ name, color, onClick, icon }) => {
  const classes = useStyles({color: color});
  return (
    <div
      className={classes.root}
      onClick={onClick}
    >
      <FontAwesomeIcon className={classes.icon} icon={icon} />
      <div>{name}</div>
    </div>
  );
};

OptionItem.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.object,
};

export default OptionItem;