import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Typography } from 'antd';

import style from 'style';


const InfoDetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  
  .section-title {
    font-size: 14px;
    color: ${style.colors.primary};
    font-weight: 500;
    opacity: 0.45;
  }
  
  .section-text {
    color: ${style.colors.primary};
    font-weight: 500;
  }
`;

const InfoDetail = ({title, text}) => {
  return (
    <InfoDetailContainer>
      <Typography.Text className="section-title">{title}</Typography.Text>
      <Typography.Text className="section-text">{text}</Typography.Text>
    </InfoDetailContainer>
  );
};

InfoDetail.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

export default InfoDetail;