import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '1rem',
  },  
  list: {
    listStyleType: 'none',
    padding: 0,
  },  
});

const LoadingCards = ({count=3, size=150}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {[...Array(count)].map((_, i) =>
          <li key={i}>
            <Skeleton animation="wave" height={size}/>
          </li>
        )}
      </ul>
    </div>
  );
};

LoadingCards.propTypes = {
  count: PropTypes.number,
  size: PropTypes.number,
};

export default LoadingCards;
