import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';


// This is an approximate max character count before we push an element to take
// more spans in the grid. Adjust according to the needs.
const MAX_CHAR_IN_A_SPAN = 12;

const useStyles = makeStyles(theme => ({
  root: {
    gridColumn: ({gridColumn}) => gridColumn && gridColumn,
    overflow: 'hidden',
  },
  container: {
    paddingRight: '25px'
  },
  title: {
    color: theme.palette.common.battleshipGrey,
    fontSize: '14px',
    fontWeight: '400',
    marginBottom: '5px',
    cursor: 'default',
  },
  details: {
    color: theme.palette.primary.main,
    fontSize: '15px',
    cursor: ({isMasked}) => isMasked && 'default',
  },
}));

const Data = (
  {
    title,
    details,
    className,
    gridColumn='span 1',
    maxGridColumnSpan=5,
    autoSpan=true,
    maxCharInSpan=MAX_CHAR_IN_A_SPAN,
    isCeiling=false,
  }) => {

  const isMasked = details && Array.from(details).every(item => item === '•');

  const getSpan = () => {
    if (!details || !title)
      return gridColumn;

    const getTextGrids = () => {
      const textGrid = Math.max(details.length, title.length) / maxCharInSpan;

      if (!isCeiling)
        return textGrid.toFixed();

      return Math.ceil(textGrid);
    };

    const textGrids = getTextGrids();

    const minGridColumn = parseInt(gridColumn.split(' ')[1]);

    if (textGrids <= minGridColumn)
      return gridColumn;

    if (textGrids > maxGridColumnSpan)
      return `span ${maxGridColumnSpan}`;

    return `span ${textGrids}`;
  };

  const classes = useStyles({
    gridColumn: autoSpan ? getSpan() : gridColumn,
    isMasked: isMasked
  });

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.container}>
        <div className={classes.title}>
          {title}
        </div>
        <Tooltip
          title={isMasked ? 'Information hidden until request has been accepted, ' +
            'and contact information revealed. ' : ''}
          placement="top"
        >
          <div className={classes.details}>
            {details ? details : 'N/A'}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

Data.propTypes = {
  title: PropTypes.string,
  details: PropTypes.string,
  className: PropTypes.string,
  gridColumn: PropTypes.string,
  maxGridColumnSpan: PropTypes.number,
  autoSpan: PropTypes.bool,
  maxCharInSpan: PropTypes.number,
  isCeiling: PropTypes.bool,
};


export default Data;
