import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFamilyDress } from '@fortawesome/pro-light-svg-icons';

import Card from './Card';
import { AGE_GROUPS_ICON_MAP } from './constants';


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const AgeGroup = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  
  .icon-container {
    min-width: 20px;
  }
`;

const AgeGroups = ({ data, isLoading }) => {

  return (
    <Card title="Age Groups" icon={faFamilyDress} isLoading={isLoading} noData={_.isEmpty(data)}>
      <Container>
        {data?.map((item, index) => (
          <AgeGroup key={index}>
            <div className="icon-container">
              <FontAwesomeIcon icon={AGE_GROUPS_ICON_MAP[item]} style={{fontSize: '18px'}} />
            </div>
            {item}
          </AgeGroup>
        ))}
      </Container>
    </Card>
  );
};

AgeGroups.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default AgeGroups;
