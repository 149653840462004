import { useEffect, useState } from 'react';

import Alert from '@material-ui/lab/Alert';
import { AuthAPI } from '../../api';
import ErrorPage from 'components/ErrorPage';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

const defaultErrorMessage = 'An unexpected error occurred verifying your email.  Please try again later.';
const loadingMessage = 'Verifying...';
const successMessage = 'Thank you for verifying your email address.  You are all set!';

const VerifyEmailPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');
  const [message, setMessage] = useState(loadingMessage);
  const [status, setStatus] = useState('info');

  useEffect(() => {
    const verifyEmail = async (code) => {
      setStatus('info');
      setMessage(loadingMessage);
      try {
        await AuthAPI.verifyEmail(code);
        setStatus('success');
        setMessage(successMessage);
      } catch (err) {
        const message = err.body && err.body.message ? err.body.message : defaultErrorMessage;
        setStatus('error');
        setMessage(<React.Fragment><div>Email verification failed with the following error:</div><div>{message}</div></React.Fragment>);
      }
    };
    verifyEmail(code);
  }, [code]);


  return (
    <ErrorPage title="Clickability Login">
      <Typography variant="h1">Verifying Email Address</Typography>
      <Alert severity={status}>
        {message}
      </Alert>
    </ErrorPage>
  );
};

export default VerifyEmailPage;
