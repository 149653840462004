import { useEffect } from 'react';
import { connect } from 'react-redux';
import { TaxonomyStore, CmsStore, ReportStore, AuthStore } from '../store';
import PropTypes from 'prop-types';


const StartupRefresh = ({ dispatch }) => {
  // force taxonomy reload whenever the app is reloaded to prevent sticky cache.
  useEffect(() => {
    dispatch(TaxonomyStore.invalidateCache());
    dispatch(CmsStore.invalidateCache());
    dispatch(ReportStore.invalidateCache());
    dispatch(AuthStore.showOverlay(false));
  }, [dispatch]);
  return null;
};

StartupRefresh.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(StartupRefresh);
