import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import OverviewContent from './OverviewContent.js';
import ProviderListings from 'components/listing/ProviderListings.js';
import Reviews from 'components/review/Reviews.js';
import { TabPane, a11yProps, StyledTab, StyledTabs, StyledTabScrollButton } from 'components/Tabs.js';
import ContactInfo from './ContactInfo.js';
import * as track from 'lib/track';
import ConnectMeButton from './ConnectMeButton';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.common.silver}`,
    // display: 'flex',
    // flexWrap: 'wrap',
  },
}));

const TabsPanel = ({data, tab=1, handleChange}) => {
  const classes = useStyles();
  const showListings = data.scope === 'provider';
  const providerUuid = 'title' in data ? data.provider_uuid : data.uuid;

  const handleClick = () => {
    track.sendEvent('contact details tab', 'click', {}, [providerUuid]);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <StyledTabs
          value={tab}
          onChange={handleChange}
          aria-label="Tabs"
          variant="scrollable"
          scrollButtons="on"
          ScrollButtonComponent={StyledTabScrollButton}
        >
          <StyledTab label="Overview" aria-label="Overview tab selector" {...a11yProps(0)} />
          <StyledTab label="Reviews" aria-label="Reviews tab selector" {...a11yProps(1)} />
          <StyledTab onClick={handleClick} label="Contact Information" aria-label="Contact Information tab selector" {...a11yProps(2)} />
          {showListings ? (<StyledTab label="Service Offerings" aria-label="Listings tab selector" {...a11yProps(3)} />) : null}
        </StyledTabs>
      </AppBar>
      <TabPane value={tab} index={0}>
        <OverviewContent
          data={data}
        />
      </TabPane>
      <TabPane value={tab} index={1}>
        <Reviews data={data}/>
      </TabPane>
      <TabPane value={tab} index={2}>
        <ContactInfo data={showListings ? data : data.provider_contact}/>
        <ConnectMeButton show={data?.has_direct_connect_access} uuid={data?.uuid} />
      </TabPane>
      {showListings ? (
        <TabPane value={tab} index={3}>
          <ProviderListings providerUuid={data.provider_uuid} />
        </TabPane>
      ) : null}
    </div>
  );
};

TabsPanel.propTypes = {
  data: PropTypes.object.isRequired,
  tab: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
};

export default TabsPanel;
