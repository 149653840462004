import {
  ReviewCount,
  ShareIconButton,
  StarRating,
} from 'components/widgets';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import ReviewSnippets from 'components/review/ReviewSnippets';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  starRating: {
    marginRight: '1rem',
  },
}));

const WidgetBar = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StarRating rating={data.rating} className={classes.starRating} />
      <ReviewCount count={data.review_count} />
      <ShareIconButton />
      <ReviewSnippets
        isLoading={false}
        data={data}
      />
    </div>
  );
};

WidgetBar.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WidgetBar;
