import React from 'react';
import PropTypes from 'prop-types';

import { faPhoneAlt, faEnvelope } from '@fortawesome/pro-duotone-svg-icons';
import { Typography, Avatar } from 'antd';

import Card from 'providerSite/components/Card';
import InfoDetail from './InfoDetail';
import ContactDisplayer from './ContactDisplayer';
import Divider from './Divider';
import styled from 'styled-components';
import style from 'style';


const RequestDetailTitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
  
  .avatar {
    background-color: rgb(135, 208, 104);
    font-weight: 500;
  }
  
  .participant-name {
    font-size: 18px;
    margin: 0;
    color: ${style.colors.primary};
  }
  .participant-location {
    font-size: 14px;
    color: ${style.colors.primary};
    opacity: 0.45;
  }
`;

const RequestDetailBodyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 10px;
  
  .section-title {
    font-size: 14px;
    color: ${style.colors.primary};
    opacity: 0.45;
  }
`;

const RequestDetail = ({ serviceInquiry, participant, serviceName }) => {
  const { data } = serviceInquiry;

  const {
    initials,
    name,
    location,
    phone,
    email,
    ageGroup,
  } = participant ? participant : {};

  const renderDetail = () => {
    const serviceDetailMap = {
      planStartDate: 'Plan start date',
    };

    return (
      <>
        <Divider margin={'10px'}/>
        <InfoDetail
          title="Service required"
          text={serviceName}
        />
        {Object.keys(data).map((key) => {
          if (Object.hasOwnProperty.call(serviceDetailMap, key)) {
            return (
              <React.Fragment key={key}>
                <Divider margin={'10px'}/>
                <InfoDetail
                  title={serviceDetailMap[key]}
                  text={data[key]}
                />
              </React.Fragment>
            );
          }
        })}
        <InfoDetail title="Age Range" text={ageGroup} />
      </>
    );
  };

  return (
    <Card title='Request Details' style={{minWidth: '240px'}}>
      <RequestDetailTitleContainer>
        <Avatar className="avatar" size={60}>
          {initials}
        </Avatar>
        <Typography.Title className="participant-name">{name}</Typography.Title>
        <Typography.Text className="participant-location">{location}</Typography.Text>
      </RequestDetailTitleContainer>
      <Divider />
      <RequestDetailBodyContainer>
        <Typography.Text className="section-title">Contact details</Typography.Text>
        <ContactDisplayer icon={faPhoneAlt} text={phone} />
        <ContactDisplayer icon={faEnvelope} text={email} />
        {data && renderDetail()}
      </RequestDetailBodyContainer>
    </Card>
  );
};

RequestDetail.propTypes = {
  serviceInquiry: PropTypes.object,
  serviceName: PropTypes.string,
  participant: PropTypes.object,
};

export default RequestDetail;