import accPlusIcon from 'assets/acc-plus-icon.svg';
import enterpriseIcon from 'assets/enterprise-icon.svg';
import basicIcon from 'assets/basic-icon.svg';

import benefitMapAsset from 'assets/benefit-map.png';
import benefitSrAsset from 'assets/benefit-sr.png';
import benefitAreaAsset from 'assets/benefit-area.png';
import benefitDirectoryAsset from 'assets/benefit-directory.png';
import benefitGalleryAsset from 'assets/benefit-gallery.png';
import benefitConnectAsset from 'assets/benefit-connect.png';


export const TIER_STARTER = 'Starter';
export const TIER_ACCREDITATION_PLUS = 'Accreditation Plus';
export const TIER_ENTERPRISE = 'Enterprise';
export const TIER_NICHE_AND_REGIONAL = 'Niche & Regional';


// WARNING: Should exactly match with Braintree plan!!
export const PLAN_ID_ANNUAL_BASIC_ACCREDITATION = '20230914-ANNUAL-BASIC-ACCREDITATION';
export const PLAN_ID_QUARTERLY_BASIC_ACCREDITATION = '20230914-QUARTERLY-BASIC-ACCREDITATION';

export const PLAN_ID_ANNUAL_PLUS_ACCREDITATION = '20230914-ANNUAL-PLUS-ACCREDITATION';
export const PLAN_ID_QUARTERLY_PLUS_ACCREDITATION = '20230914-QUARTERLY-PLUS-ACCREDITATION';

export const PLAN_ID_ANNUAL_ENTERPRISE_ACCREDITATION = '20230914-ANNUAL-ENTERPRISE-ACCREDITATION';
export const PLAN_ID_QUARTERLY_ENTERPRISE_ACCREDITATION = '20230914-QUARTERLY-ENTERPRISE-ACCREDITATION';

export const PLAN_ID_QUARTERLY_NICHE_AND_REGIONAL_ACCREDITATION = '20240924-QUARTERLY-NICHE-AND-REGIONAL-ACCREDITATION';


export const TIER_ICONS = {
  'Starter': basicIcon,
  'Accreditation Plus': accPlusIcon,
  'Enterprise': enterpriseIcon,
  'Niche & Regional': basicIcon,
};


const BENEFIT_INFO_MAP = {
  title: 'Appear at the top of the directory with a pin on the map',
  description: 'Participants can easily find you on the map and reveal your review ' +
    'stars, number of reviews and registration status on hover.',
  image: benefitMapAsset,
};

const BENEFIT_INFO_SR = {
  title: 'Service requests direct to your inbox',
  description: 'Participants submit their requirements directly to us, and we will ' +
    'send you additional information about the request. If you accept the opportunity, ' +
    'we will reveal the contact information of the client.',
  image: benefitSrAsset,
};

const BENEFIT_INFO_CONNECT = {
  title: 'Connect with participants across all platforms',
  description: 'Participants can easily connect with you and access your phone ' +
    'number via a large pop-up, as well as connect through social platforms, email, ' +
    'or visit your website. This will help pass on SEO authority and assist you ' +
    'in ranking higher on Google.',
  image: benefitConnectAsset,
};


const TIERS = [
  {
    name: TIER_STARTER,
    subTitle: 'To get started',
    description: 'Get started with finding NDIS participants with population ' +
      'coverage targeting of approximately 1 to 5 million.',
    price: {
      annually: 59,
      quarterly: 83,
    },
    billedPrice: {
      annually: 699,
      quarterly: 249,
    },
    billedType: {
      annually: 'Annually',
      quarterly: 'Quarterly',
    },
    icon: TIER_ICONS[TIER_STARTER],
    alternate: true,
    benefits: [
      {
        text: 'Up to 3 service requests per month',
        info: BENEFIT_INFO_SR,
      },
      {
        text: 'Advertise up to 3 services',
      },
      {
        text: 'Accreditation badge',
      },
      {
        text: 'Connect via direct connect button, social & web links only',
      },
      {
        text: 'Phone number shown',
        excluded: true,
      },
      {
        text: 'Map placement with pin',
        excluded: true,
        info: BENEFIT_INFO_MAP,
      },
      {
        text: 'Smaller geographic targeting',
        excluded: true,
      },
    ],
    btPlanId: {
      annually: PLAN_ID_ANNUAL_BASIC_ACCREDITATION,    // Should exactly match with Braintree plan
      quarterly: PLAN_ID_QUARTERLY_BASIC_ACCREDITATION,    // Should exactly match with Braintree plan
    },
    subscriptionTierName: 'starter',    // Should exactly match with backend db
  },
  {
    name: TIER_ACCREDITATION_PLUS,
    subTitle: 'Increase exposure',
    description: 'Perfect for growing NDIS businesses with population coverage ' +
      'targeting of around 5 to 10 million & advanced endorsement options.',
    price: {
      annually: 109,
      quarterly: 149,
    },
    billedPrice: {
      annually: 1299,
      quarterly: 447,
    },
    billedType: {
      annually: 'Annually',
      quarterly: 'Quarterly',
    },
    icon: TIER_ICONS[TIER_ACCREDITATION_PLUS],
    alternate: false,
    benefits: [
      {
        text: 'Up to 5 service requests per month',
        info: BENEFIT_INFO_SR,
      },
      {
        text: 'Advertise up to 5 services',
      },

      {
        text: 'Prominent map placement with pin at the top of the directory',
        info: BENEFIT_INFO_MAP,
      },
      {
        text: 'Larger prioritised directory listing with NDIS & Accreditation badging',
        info: {
          title: 'Larger directory listing & higher placement in the list',
          description: 'Your listing in the directory can display participant ' +
            'endorsements and includes a more prominent, coloured Accreditation ' +
            'logo and a Direct Connect feature that instantly sends you service ' +
            'requests from participants.',
          image: benefitDirectoryAsset,
        },
      },
      {
        text: 'Enhanced directory listing with Photo Gallery',
        info: {
          title: 'Enhanced profile listing including photo gallery',
          description: 'Within your profile, you can showcase your business’s services ' +
            'with a photo gallery containing dozens of images',
          image: benefitGalleryAsset,
        },
      },
      {
        text: 'Connect via phone, direct connect button social & SEO friendly web link',
        info: BENEFIT_INFO_CONNECT,
      },
      {
        text: 'Larger geolocation targeting',
        info: {
          title: 'Expand your reach with access to more local areas',
          description: 'Accreditation Plus accounts can select additional geographic ' +
            'areas to expand their coverage. With an up to 20 local areas available, ' +
            'the estimated population reach can be double that of our starter accounts.',
          image: benefitAreaAsset,
        },
      },
      {
        text: 'Nationwide coverage & unlimited services',
        excluded: true,
      },
    ],
    btPlanId: {
      annually: PLAN_ID_ANNUAL_PLUS_ACCREDITATION,   // Should exactly match with Braintree plan
      quarterly: PLAN_ID_QUARTERLY_PLUS_ACCREDITATION,   // Should exactly match with Braintree plan
    },
    subscriptionTierName: 'accreditation_plus',   // Should exactly match with backend db
  },
  {
    name: TIER_ENTERPRISE,
    subTitle: 'For big companies',
    description: 'Nationwide coverage and unlimited access to all platform features.',
    price: {
      annually: 333,
      quarterly: 499,
    },
    billedPrice: {
      annually: 3996,
      quarterly: 1497,
    },
    billedType: {
      annually: 'Annually',
      quarterly: 'Quarterly',
    },
    icon: TIER_ICONS[TIER_ENTERPRISE],
    alternate: true,
    benefits: [
      {
        text: 'Unlimited service requests',
        info: BENEFIT_INFO_SR,
      },
      {
        text: 'Advertise all your services',
      },
      {
        text: 'Everything in plus including map, gallery, larger directory listing',
        info: BENEFIT_INFO_MAP,
      },
      {
        text: 'Connect via phone, direct connect button social & SEO friendly web link',
        info: BENEFIT_INFO_CONNECT,
      },
      {
        text: 'Nationwide geolocation targeting',
      },
    ],
    btPlanId: {
      annually: PLAN_ID_ANNUAL_ENTERPRISE_ACCREDITATION,   // Should exactly match with Braintree plan
      quarterly: PLAN_ID_QUARTERLY_ENTERPRISE_ACCREDITATION,   // Should exactly match with Braintree plan
    },
    subscriptionTierName: 'enterprise',   // Should exactly match with backend db
  },
];

export const SPECIAL_TIER_NICHE_AND_REGIONAL = {
  name: TIER_NICHE_AND_REGIONAL,
  subTitle: 'Limited',
  description: 'For regional providers 150km+ outside of CBD locations servicing niche services.',
  price: {
    quarterly: 33,
  },
  billedPrice: {
    quarterly: 99,
  },
  billedType: {
    quarterly: 'Quarterly',
  },
  icon: TIER_ICONS[TIER_NICHE_AND_REGIONAL],
  btPlanId: {
    quarterly: PLAN_ID_QUARTERLY_NICHE_AND_REGIONAL_ACCREDITATION,    // Should exactly match with Braintree plan
  },
  subscriptionTierName: 'niche_and_regional',    // Should exactly match with backend db
};

export const PLAN_ID_TO_TIERS_MAP = {
  [PLAN_ID_ANNUAL_BASIC_ACCREDITATION]: TIERS[0],
  [PLAN_ID_QUARTERLY_BASIC_ACCREDITATION]: TIERS[0],

  [PLAN_ID_ANNUAL_PLUS_ACCREDITATION]: TIERS[1],
  [PLAN_ID_QUARTERLY_PLUS_ACCREDITATION]: TIERS[1],

  [PLAN_ID_ANNUAL_ENTERPRISE_ACCREDITATION]: TIERS[2],
  [PLAN_ID_QUARTERLY_ENTERPRISE_ACCREDITATION]: TIERS[2],

  [PLAN_ID_QUARTERLY_NICHE_AND_REGIONAL_ACCREDITATION]: SPECIAL_TIER_NICHE_AND_REGIONAL,
};

export default TIERS;
