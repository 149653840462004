import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

const BuzzsproutPlayer = ({id1, id2}) => {
  const divId = `buzzsprout-player-${id2}`;
  const scriptUrl = `https://www.buzzsprout.com/${id1}/${id2}.js`;
  return (
    <>
      <div id={divId} />
      <Helmet>
        <script 
          src={`${scriptUrl}?container_id=${divId}&player=small"`}
          type="text/javascript" 
          charSet="utf-8"
        />
      </Helmet>
    </>
  );
};

BuzzsproutPlayer.propTypes = {
  id1: PropTypes.number.isRequired,
  id2: PropTypes.number.isRequired,
};

export default BuzzsproutPlayer;
