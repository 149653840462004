import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import ProviderCard from './ProviderCard';
import StaticCard from './StaticCard';
import { setIntersection, setDifference } from 'lib/util';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    columnGap: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: '20px',
    },
  },
}));

const CardQuestion = (
  {
    providerDataFunc,
    otherData,
    value,
    disableRate,
    answers,
    onChange,
    setterCb,
    setAnswers,
    className,
  }) => {

  const classes = useStyles();
  const data = providerDataFunc(answers);
  const otherDataSet = new Set(otherData.map(item => item.key));
  const [selectedData, setSelectedData] = useState(new Set(value?.selection ? value.selection : []));
  const [rateData, setRateData] = useState(value?.rate ? value.rate : {});

  const handleSelection = providerUuid => {
    setSelectedData(prevState => {
      const _newSet = setDifference(prevState, otherDataSet);
      return _getUpdatedSet(providerUuid, _newSet);
    });
    setRateData(prevState => {
      delete prevState[providerUuid];
      return {...prevState};
    });
  };

  const handleOtherSelection = key => {
    setSelectedData(prevState => {
      const _newSet = _getUpdatedSet(key, new Set([...prevState]));

      return setIntersection(_newSet, new Set([key]));
    });
  };

  const handleRate = (providerUuid, value) => {
    setRateData(prevState => ({...prevState, [providerUuid]: value}));
  };

  const _getUpdatedSet = (value, existingSet) => {
    const _newSet = new Set([...existingSet]);
    if (_newSet.has(value))
      _newSet.delete(value);
    else {
      _newSet.add(value);
    }

    return _newSet;
  };

  useEffect(() => {
    onChange({selection: [...selectedData], rate: rateData});
    setterCb && setterCb(answers, setAnswers);
  }, [selectedData, rateData]);

  return (
    <div className={classNames(classes.root, className)}>
      {data?.map((item, index) => (
        <ProviderCard
          key={index}
          data={item}
          isSelected={selectedData.has(item.uuid)}
          rateValue={rateData[item.uuid]}
          onSelect={handleSelection}
          onRate={handleRate}
          disableRate={disableRate}
        />))}
      {otherData?.map((item, index) => (
        <StaticCard
          key={index}
          title={item.title}
          icon={item.icon}
          isSelected={selectedData.has(item.key)}
          onSelect={() => handleOtherSelection(item.key)}
        />))}
    </div>
  );
};

CardQuestion.propTypes = {
  title: PropTypes.string,
  providerDataFunc: PropTypes.func,
  otherData: PropTypes.arrayOf(PropTypes.object),
  answers: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.object,
  disableRate: PropTypes.bool,
  setterCb: PropTypes.func,
  setAnswers: PropTypes.func,
};

export default CardQuestion;