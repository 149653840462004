import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AuthAPI } from '../../../api';
import ProviderLoginForm from './ProviderLoginForm';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  loginContainer: {
    maxWidth: 400,
  },
});

const ProviderLoginContainer = ({initialUsername}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const onLogin = (profile) => {
    let redirectTo = '/providers/profile';
    if (location.state && location.state.redirectTo && location.state.redirectTo !== '/') {
      // if there has been an explicit redirect passed, use that.
      redirectTo = location.state.redirectTo;
    } else {
      for (const role of profile.roles) {
        if (role.has_accreditation_access && role.permissions.includes('Edit Provider')) {
          redirectTo = `/provider/${role.provider_uuid}/admin/listings`;
          break;
        }
        if (role.has_connect_access) {
          redirectTo = `/connect/${role.provider_uuid}/dashboard`;
          break;
        }
      }
    }
    history.push(redirectTo);
  };

  return (
    <div className={classes.loginContainer}>
      <ProviderLoginForm
        initialUsername={initialUsername}
        onComplete={onLogin}
        loginApi={AuthAPI.providerLogin}
        recoveryLink="/providers/password/recover"
        signUpLink="/providers/signup"
        title="Already Have a Provider Account?"
        titleVariant="h3"
      />
    </div>
  );
};

ProviderLoginContainer.propTypes = {
  initialUsername: PropTypes.string,
};

export default ProviderLoginContainer;
