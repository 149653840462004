import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import TickIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 5,
    minWidth: '10rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 'solid 2px #b5b5b5',
    borderRadius: '12px',
    paddingTop: 10, // room for icon
    paddingBottom: 10,
    color: theme.palette.text.disabled,
    '&.selected': {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
  },
  title: {
    lineHeight: 1.2,
  },
  block: {
    marginTop: '0.5rem',
    marginBottom: '0.3rem',
  },
  info: {
    lineHeight: 1.2,
    fontSize: '1.2rem',
  },
  amount: {
    fontSize: '1.5rem',
  },
  period: {
    fontSize: '1.0rem',
  },
  note: {
    fontSize: '0.8rem',
    color: '#888',
  },
  icon: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: 5,
    right: 5,
  },
}));

const PlanCard = ({plan, selected, onClick}) => {
  const classes = useStyles();
  const containerClass = selected ? `${classes.container} selected` : classes.container;
  const [title, amount, period, note, info] = plan.description;
  return (
    <ButtonBase className={containerClass} onClick={onClick}>
      {selected && <TickIcon className={classes.icon}/>}
      <div className={classes.title}>{title}</div>
      <div className={classes.block}>
        <span className={classes.amount}>{amount}</span>
        <span className={classes.period}>{period}</span>
        <div className={classes.note}>{note}</div>
      </div>
      <div className={classes.info}>&nbsp;{info}</div>
    </ButtonBase>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PlanCard;
