import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import snakecaseKeys from 'snakecase-keys';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import { Alert, Button as AntdButton } from 'antd';

import style from 'style';
import { Password, Textbox } from 'providerSite/components/inputs';
import connectDashboard from 'assets/connect-dashboard.png';
import { AuthAPI} from 'api';
import { AuthStore } from 'store';
import * as track from 'lib/track';


const CONNECT_BUSINESS_NAME_FILED = 'businessName';
const CONNECT_PASSWORD_FIELD = 'password';
const CONNECT_EMAIL_FIELD = 'email';


const Container = styled.div`
  border: 1px solid ${style.colors.lightBorder};
  border-radius: 22px;
  padding: 20px 35px;
  box-shadow: ${style.shadow(15)};
  background-image: linear-gradient(120deg, #f7f7f7 52%, ${style.colors.primary} 50%);
  
  .title {
    color: ${style.colors.palette.dustyOrange};
    font-size: 32px;
    font-weight: 700;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
  }
  
  .header-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    @media (max-width: ${style.sizes.large}) {
      align-items: center;
      text-align: center;
    }
  }
  
  .header {
    color: ${style.colors.palette.black};
    background: #f7f7f7;
    padding: 10px 10px 7px 0;
    border-radius: 18px;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    
    @media (max-width: ${style.sizes.large}) {
      align-items: center;
    }
  }
  
  .text-button {
    margin-top: 7px;
    margin-left: 10px;
    font-size: 12px;
    text-decoration: underline;
    color: ${style.colors.primary};
    cursor: pointer;
    width: max-content;
    :hover {
      color: ${style.colors.secondary};
    }
    @media (max-width: ${style.sizes.large}) {
      color: white;
    }
  }
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 20px;
  
  @media (max-width: ${style.sizes.large}) {
    flex-direction: column;
    row-gap: 50px;
    align-items: center;
  }
  
  .left-container {
    flex-grow: 1;
    width: 100%;
    max-width: 300px;
  }
  
  .image {
    width: 450px;
    
    @media (max-width: ${style.sizes.large}) {
      width: 100%;
      max-width: 450px;
    }
  }
`;

const Button = styled(AntdButton)`
  width: 100%;
  height: 50px;
  background-color: ${style.colors.secondary};
  color: ${style.colors.palette.blackSlate};
  border: none;
  max-width: 320px;
  margin-top: 20px;
  border-radius: 14px;
  
  
  :disabled {
    background: ${style.colors.secondary};
    border: none;
    box-shadow: none;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));
    
    :hover {
      background: ${style.colors.secondary};
    }
  }
`;


const ConnectPromotion = ({ apiData }) => {

  const [data, setData] = useState({});
  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const setDataValue = field => val => setData(prevState => (
    {...prevState, [field]: val})
  );

  useEffect(() => {
    setDataValue(CONNECT_EMAIL_FIELD)(apiData.email);
  }, [apiData]);

  const onSignup = async () => {
    setIsBusy(true);
    setError(null);

    const signupData = {
      email: data.email,
      username: data.email,
      firstName: apiData.firstName,
      lastName: apiData.lastName,
      contactPhone: apiData.phone,
      emailOptIn: true,
      organisation: data[CONNECT_BUSINESS_NAME_FILED],
      entityName: data[CONNECT_BUSINESS_NAME_FILED],
      hasConnectAccess: true,
      jobRole: null,
      password: data[CONNECT_PASSWORD_FIELD],
    };

    try {
      const { payload: signupPayload } = await AuthAPI.providerSignup({
        billing_plan_id: null,
        subscription_tier_name: 'free',
        ...snakecaseKeys(signupData, {deep: true}),
      });
      const providerUuid = signupPayload.uuid;

      track.sendEvent(
        'connect provider signup via sr dashboard',
        'submit', {providerUuid: providerUuid});

      const { payload } = await AuthAPI.providerLogin(
        data[CONNECT_EMAIL_FIELD], data[CONNECT_PASSWORD_FIELD]);
      const { profile, id, token_ttl } = payload;

      dispatch(AuthStore.login(profile, id, token_ttl));

      const url = `/connect/${providerUuid}/dashboard/participants`;
      history.push(url, {isNewUser: true});

    } catch(err) {
      setIsBusy(false);
      if (err.body) {
        const { message } = err.body;
        setError(message);
      } else {
        setError('An error occurred updating your profile.');
      }
    }
  };

  return (
    <Container>
      <div className="header-container">
        <div className="title">
          Are you a Support Coordinator?
        </div>
        <div className="header">
          Create an account with us to save your details for next time. Easily
          manage your participants, and submit service requests for
          them in <b>under 30 seconds</b>. It&apos; absolutely <u><b>FREE!</b></u>
        </div>
      </div>
      <InnerContainer>
        <div className="left-container">
          <Textbox
            title="Your Business Email"
            onChange={setDataValue(CONNECT_EMAIL_FIELD)}
            defaultValue={apiData.email}
            validator={EmailValidator.validate}
            errorMsg="Please enter a valid email address"
            type="email"
            colouredBorder
          />
          <Textbox
            title="Business Name"
            onChange={setDataValue(CONNECT_BUSINESS_NAME_FILED)}
            validator={value => !!value?.match(/^[\w\-@&# ]+$/g)}
            errorMsg="Please enter a valid name"
            colouredBorder
          />
          <Password
            title="Password"
            onChange={setDataValue(CONNECT_PASSWORD_FIELD)}
            validator={val => val && val.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w\W]{8,}$/)}
            errorMsg="Password must be at least 8 characters and contain at least
          one each of: upper case, lower case, and digits."
            colouredBorder
          />
        </div>
        <div className="image-container">
          <img src={connectDashboard} alt="Connect Dashboard" className="image" />
        </div>
      </InnerContainer>
      <div className="button-container">
        <Button
          type="primary"
          size="large"
          disabled={!data[CONNECT_BUSINESS_NAME_FILED] || !data[CONNECT_PASSWORD_FIELD]}
          loading={isBusy}
          onClick={onSignup}
        >
          Create Account
        </Button>
        <div
          className="text-button"
          onClick={() => (
            history.push(
              `/providers/login?username=${encodeURIComponent(apiData.email)}`
            ))}
        >
          Already have an account? Login!
        </div>
        {error && <Alert style={{marginTop: '20px'}} message={error} type="error"/>}
      </div>
    </Container>
  );
};

ConnectPromotion.propTypes = {
  apiData: PropTypes.object,
};


export default ConnectPromotion;

