import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../../components/Page.js';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import backgroundImg from '../../../assets/login-background.svg';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  background: {
    padding: '1rem 0 5rem 0',
    width: '100%',
    minHeight: '56rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 300px',
    backgroundSize: 'contain',
    backgroundImage: `url(${backgroundImg})`
  },
  title: {
    marginBottom: '1.5rem',
  },
  form: {
    width: '37rem',
    // marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const AuthFormPage = ({title, children, displayTitle=false, rightPanel=null}) => {
  const classes = useStyles();
  return (
    <Page title={title}>
      <div className={classes.background}>
        <Container>
          <Grid container spacing={0}>
            <Grid item md={7}>
              {displayTitle ? (
                <Typography variant="h1" color="primary" className={classes.title}>
                  {title}
                </Typography>
              ) : null}
              {children}
            </Grid>
            <Grid item md={5}>
              {rightPanel}
            </Grid>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

AuthFormPage.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  displayTitle: PropTypes.bool,
  rightPanel: PropTypes.node,
};

export default AuthFormPage;
