import React, { useState } from 'react';
import AuthForm from './AuthForm';
import AuthField from './AuthField';
import { AuthAPI } from '../../../api';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const DEFAULT_ERROR_MESSAGE = 'An error occurred resetting your password.  Please try again later.';
const BAD_LINK_MESSAGE = 'Password reset code is not valid.';

const PasswordResetForm = ({username, code, onComplete, isProvider, title}) => {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordWarning, setPasswordWarning] = useState();
  const [password2Warning, setPassword2Warning] = useState();
  const linkValid = username  && code;  // rejects null, undefined or empty strings
  const [resetError, setResetError] = useState(linkValid ? undefined : BAD_LINK_MESSAGE);
  const [busy, setBusy] = useState(false);
  const reset = async () => {
    if (!linkValid) {
      setResetError(BAD_LINK_MESSAGE);
      return;
    }
    setResetError();
    if (!validatePassword(true) || !validatePassword2(true)) return;
    try {
      setBusy(true);
      await AuthAPI.resetPassword(username, password, code, isProvider);
      setBusy(false);
      onComplete();
    } catch (err) {
      setBusy(false);
      console.error(err);  // eslint-disable-line no-console
      const message = (err.body && err.body.message) ? err.body.message : DEFAULT_ERROR_MESSAGE;
      setResetError(message);
    }
  };

  const validatePassword = (onSubmit=false) => {
    if (password === '' && onSubmit) {
      // only perform this validation if they are actually ready to submit
      setPasswordWarning('Password is required');
      return false;
    }
    setPasswordWarning();
    return true;
  };

  const validatePassword2 = (onSubmit=false) => {
    if (onSubmit) {
      if (password2 === '') {
        setPassword2Warning('Please repeat password here to confirm it.');
        return false;
      }
      if (password2 !== password) {
        setPassword2Warning('Passwords do not match');
        return false;
      }
    }
    setPassword2Warning();
    return true;
  };

  const disabled = !linkValid || passwordWarning !== undefined || password2Warning !== undefined;
  return (
    <AuthForm
      buttonLabel="Reset Password"
      buttonAction={reset}
      disabled={disabled}
      busy={busy}
      error={resetError}
    >
      <Typography variant="h1" color="primary">
        {title}
      </Typography>

      <AuthField
        id="username"
        label="Username"
        autoComplete="username"
        type="text"
        name="username"
        value={username}
        disabled={true}
      />

      <AuthField
        id="password"
        label="New Password"
        autoComplete="new-password"
        type="password"
        name="password"
        value={password}
        onChange={setPassword}
        error={passwordWarning}
        onBlur={() => validatePassword()}
      />

      <AuthField
        id="password2"
        label="Repeat password"
        autoComplete="new-password"
        type="password"
        name="password2"
        value={password2}
        onChange={setPassword2}
        error={password2Warning}
        onBlur={() => validatePassword2()}
        onEnter={() => !disabled && reset()}
      />

    </AuthForm>
  );
};

PasswordResetForm.propTypes = {
  username: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  isProvider: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default PasswordResetForm;
