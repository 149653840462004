import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from 'antd';
import * as track from 'lib/track';
import { useHistory } from 'react-router-dom';
import team from 'assets/cta-team.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1.5rem',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0.75rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  left: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxWidth: '35rem',
    paddingRight: '1rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginBottom: '2rem',
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text: {
    fontWeight: 700,
    color: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  button: {
    backgroundColor: '#99F2BF',
    color: theme.palette.common.darkSlateBlue,
    borderRadius: '0.75rem',
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.25)',
    padding: '0.7rem 1rem',
    fontSize: '1rem',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  team: {
    height: '7rem',
  },
}));

const SearchBrokerageCTA = () => {
  const classes = useStyles();
  const history = useHistory();

  const onClick = () => {
    track.sendEvent('Search page brokerage CTA button', 'click');
    history.push('/requestmatch');
  };

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Typography variant="h2" className={classes.text}>
          Save time. Let us help you connect with the right providers.
        </Typography>
        <Button type="primary" style={{marginTop: '15px', minWidth: '200px'}} onClick={onClick}>
          Get Started
        </Button>
      </div>
      <div className={classes.right}>
        <img src={team} alt="clickability-team" className={classes.team} />
      </div>
    </div>
  );
};

export default SearchBrokerageCTA;
