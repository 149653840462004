import {
  faFacebookSquare,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import BrokerageFlowPartner from './BrokerageFlowPartner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React from 'react';
import { Typography } from 'antd';
import config from 'config';
import footerLogo from 'assets/partners/mpm-logo-white-rgb.png';
import logo from 'assets/partners/mpm-logo-black.png';
import style from 'style';
import styled from 'styled-components';

const BrokerageFlowMpm = () => {

  return (
    <BrokerageFlowPartner
      source="mpm"
      title="My Plan Manager - Service Request"
      FooterComponent={Footer}
      logo={logo}
      partnerLink='https://myplanmanager.com.au/'
      excludedServices={['Plan Management']}
    />
  );
};

const FooterMain = styled.div`
  width: 100%;
  background-color: #1d1c35;
  display: flex;
  justify-content: center;
`;

const FooterContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  
  @media (max-width: ${style.screens.mobile}) {
    flex-direction: column;
  }
`;

const MypBottomLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  .logo {
    width: 250px;
    height: auto;
  }
  .logoText {
    color: ${style.colors.palette.white};
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  
  .link {
    color: ${style.colors.palette.white};
    :hover {
        color: ${style.colors.secondary};
    }
  }
  
  @media (max-width: ${style.screens.mobile}) {
    text-align: center;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  column-gap: 15px;
  font-size: 20px;

  .icon {
    cursor: pointer;
    font-size: 25px;
    color: ${style.colors.palette.white};
    &:hover {
      color: ${style.colors.secondary};
    }
  }
  
  @media (max-width: ${style.screens.mobile}) {
   justify-content: center;
  }
`;

const LeftContainer = styled.div`
  ul {
    list-style-type: none;
    
    li {
      margin: 15px 0 15px -38px;
    }
    .link {
      color: ${style.colors.palette.white};
      :hover {
        color: ${style.colors.secondary};
      }
    }
  }

  @media (max-width: ${style.screens.mobile}) {
    text-align: center;
    margin: 25px 0;
  }
`;

const TextTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #b4eb51;
`;

const Footer = () => {
  const handleSocialClick = url => window.open(url);
  return (
    <FooterMain>
      <FooterContainer>
        <MypBottomLogoContainer>
          <Link target="_blank" to={{ pathname: 'https://myplanmanager.com.au/' }}>
            <img src={footerLogo} alt="aaa-footer-logo" className="logo" />
          </Link>
        </MypBottomLogoContainer>
        <LeftContainer>
          <TextTitle>
            Resources
          </TextTitle>
          <ul>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={{ pathname: config.link.participantFaq }}
              >
                <Typography className="link">Help & Support</Typography>
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                to={{ pathname: 'https://myplanmanager.com.au/about-us/' }}
              >
                <Typography className="link">
                  About My Plan Manager
                </Typography>
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={{
                  pathname: 'https://clickability.com.au/about/'
                }}
              >
                <Typography className="link">About Clickability</Typography>
              </Link>
            </li>
          </ul>
        </LeftContainer>
        <RightContainer>
          <SocialContainer>
            <FontAwesomeIcon
              className="icon"
              icon={faLinkedin}
              onClick={() => handleSocialClick('https://www.linkedin.com/company/my-plan-manager')}
            />
            <FontAwesomeIcon
              className="icon"
              icon={faFacebookSquare}
              onClick={() => handleSocialClick('https://www.facebook.com/myplanmanager/')}
            />
          </SocialContainer>
          <Link
            target="_blank"
            to={{ pathname: 'https://myplanmanager.com.au/wp-content/uploads/2023/Privacy%20%26%20Dignity%20Policies%20and%20Procedures%20-%20My%20Plan%20Manager%20%28May%202023%29.pdf'}}
          >
            <Typography className="link">My Plan Manager Privacy Policy</Typography>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={{
              pathname: 'https://clickability.com.au/privacy/'
            }}
          >
            <Typography className="link">Clickability Privacy Policy</Typography>
          </Link>
        </RightContainer>
      </FooterContainer>
    </FooterMain>
  );
};

export default BrokerageFlowMpm;
