import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1rem 0',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  title: {
    width: '100%',
    marginBottom: '1rem',
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  link: {
    textDecoration: 'none',
    fontWeight: '500',
    color: theme.palette.primary.dark,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const PageTitles = ({ data }) => {
  const classes = useStyles();
  const title = data.scope === 'provider' ? data.name : data.title;

  const renderProfileSubtitle = () => {
    // Flatten child services to concatenated string
    let subtitle = '';
    if ('services' in data) {
      const nestedServices = data['services'].map(d => d.children);
      const children = nestedServices.reduce((acc, val) => acc.concat(val), []);
      const stringList = children.map(e => e.name).join(', ');
      subtitle = `Providing ${stringList} services.`;
    }
    if (subtitle === '') return null;
    return (
      <Typography variant="h3" color="primary" className={classes.subtitle}>
        {subtitle}
      </Typography>
    );
  };

  const renderListingSubtitle = () => {
    return (
      <Typography variant="h3" color="primary" className={classes.subtitle}>
        Provided by <Link className={classes.link} to={`/provider/${data.provider_slug}`}>{data.provider_name}</Link>.
      </Typography>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h1" color="primary" className={classes.title}>{title}</Typography>
      {data.scope === 'provider' ? renderProfileSubtitle() : renderListingSubtitle()}
    </div>
  );
};

PageTitles.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PageTitles;
