import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Spinner from 'components/Spinner';


const useStyles = makeStyles( theme =>({
  root: {
    minWidth: '100px',
    width: '100%',
    height: '145px',
    borderRadius: '16px',
    borderColor: '#e2e2e2',
    border: '1px solid',
    boxShadow: 'rgb(145 158 171 / 15%) 0px 0px 4px 0px, rgb(145 158 171 / 15%) 2px -2px 20px 0px',
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'uppercase',
    userSelect: 'none',
    background: theme.palette.common.white,
  },
  value: {
    fontSize: '40px',
    fontWeight: '500',
    color: ({color}) => color && color,
  },
  text: {
    fontSize: '13px',
    fontWeight: '500',
    color: theme.palette.common.battleshipGrey,
    whiteSpace: 'nowrap',
  },
  body: {
    padding: '10px 20px'
  },
  footer: {
    paddingRight: '20px',
    marginTop: 'auto',
    fontSize: '11px',
    fontWeight: '500',
    paddingLeft: '20px',
    height: '35px',
    borderRadius: '0 0 16px 16px',
    borderTop: '1px solid #e2e2e2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.common.battleshipGrey,
    textTransform: 'uppercase',
  },
  colorPrimary: {
    borderRadius: '20px',
    height: '6px',
    width: '60px',
    backgroundColor: theme.palette.grey[200],
  },
  bottomText: {
    fontSize: '10px',
    fontWeight: '600',
  },
  icon: {
    fontSize: '13px',
    '&:hover': {
      color: theme.palette.info.main,
    },
  },
}));

const StatsCard = (
  {
    text,
    value,
    color,
    bottomText,
    tooltipText,
    className,
    isLoading,
  }) => {

  const classes = useStyles({color: color});

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const getNum = value => typeof value === 'number' ? value : 0;

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.body}>
        <div className={classes.value}>{isLoading ? <Spinner size="57px" /> : getNum(value)}</div>
        <div className={classes.text}>{text}</div>
      </div>
      <div className={classes.footer}>
        {bottomText}
        <Tooltip arrow title={tooltipText} placement="bottom-end" open={!!tooltipText && isTooltipOpen}>
          <div>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className={classes.icon}
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
              onClick={e => e.stopPropagation()}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

StatsCard.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  bottomText: PropTypes.string,
  tooltipText: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default StatsCard;
