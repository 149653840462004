import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { ClickabilityWidget } from '@clickability/react-clickability-widget';


const VALID_ACCESS = [
  'clickability-test.com.au',
  'lvh.me',
  'localhost',
];

const Container = styled.div`
  margin: 20px;
  display: flex;
  column-gap: 30px;
  row-gap: 30px;
  flex-wrap: wrap;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`;


const HicapsWidgetShowcase = () => {

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!VALID_ACCESS.includes(window.location.hostname)) {
      window.open(window.location.origin, '_self');
    } else {
      setIsReady(true);
    }
  }, []);

  if (!isReady)
    return null;

  return (
    <Container>
      <div>
        <Title>
          Not Connected State
        </Title>
        <ClickabilityWidget
          accessToken="CLICKADEVB31A4D71D458A89C3C26"
          billerID="NO-BILLER-ID"
          schemes={['Test Schema']}
          postcodes={[2000]}
          modalities={['domestic service provider']}
          testEnv
        />
      </div>
      <div>
        <Title>
          Connected State
        </Title>
        <ClickabilityWidget
          accessToken="CLICKADEVB31A4D71D458A89C3C26"
          billerID="biller-id-123456789"
          schemes={['Test Schema']}
          postcodes={[2000]}
          modalities={['domestic service provider']}
          testEnv
        />
      </div>
    </Container>
  );
};


export default HicapsWidgetShowcase;
