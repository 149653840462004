import { Alert, Button as AntdButton } from 'antd';
import { AuthAPI, CmsAPI, ProviderAPI } from 'api';
import {
  DIRECT_CONNECT_FIELD,
  SERVICE_CATEGORY_ALLIED_HEALTH,
  SERVICE_CATEGORY_CLEANING,
  SERVICE_CATEGORY_GARDENING,
  SERVICE_CATEGORY_PLAN_MANAGEMENT,
  SERVICE_CATEGORY_SUPPORT_COORDINATION,
  SERVICE_CATEGORY_SUPPORT_WORK
} from '../../../constants';
import { Password, Textbox } from 'providerSite/components/inputs';
import React, {useEffect, useState} from 'react';
import { faSquare, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import {formatListToString, getDirectoryUrl} from '../../utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { AuthStore } from 'store';
import BlogPost from 'components/BlogPost';
import FlowButton from '../../components/FlowButton';
import FlowStepper from '../components/FlowStepper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'components/Loader';
import Lottie from 'components/Lottie';
import PropTypes from 'prop-types';
import ProviderCard from 'components/provider/ProviderCard';
import Spinner from 'components/Spinner';
import camelcaseKeys from 'camelcase-keys';
import config from 'config';
import connectDashboard from 'assets/connect-dashboard.png';
import dayjs from 'dayjs';
import snakecaseKeys from 'snakecase-keys';
import style from 'style';
import styled from 'styled-components';
import successConfetti from 'assets/success-confetti.json';
import {useHistory} from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const CONNECT_BUSINESS_NAME_FILED = 'businessName';
const CONNECT_PASSWORD_FIELD = 'password';


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  title: {
    textAlign: 'center',
    color: theme.palette.common.nightBlue,
    fontSize: '22px',
    fontWeight: '400',
  },
  subTitle: {
    marginTop: '10px',
    marginBottom: '30px',
    textAlign: 'center',
    color: theme.palette.common.battleshipGrey,
    fontSize: '18px',
    fontWeight: '400',
  },
  directoryContainer: {
    marginTop: '90px',
    display: 'flex',
    justifyContent: 'center',
    columnGap: '70px',
    color: theme.palette.common.nightBlue,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: '40px',
    },
  },
  directoryText: {
    maxWidth: '500px',
  },
  directoryHeader: {
    color: theme.palette.common.black,
    fontWeight: '700',
    fontSize: '22px',
  },
  directoryBody: {
    fontSize: '14px',
    fontWeight: '500',
  },
  browseDirButton: {
    background: theme.palette.common.darkSlateBlue,
    color: theme.palette.common.white,
    paddingLeft: '10px',
    paddingRight: '10px',
    minWidth: '150px',
    '&:hover': {
      background: theme.palette.common.tealish,
      color: theme.palette.common.paleGrey,
    }
  },
  blogContainerTitle: {
    marginTop: '90px',
    textAlign: 'center',
    color: theme.palette.common.nightBlue,
    fontSize: '20px',
    fontWeight: '400',
  },
  blogContainerSubTitle: {
    textAlign: 'center',
    fontSize: '16px',
    color: theme.palette.common.battleshipGrey,
  },
  blogPostsContainer: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: '40px',
      alignItems: 'center',
    },
  },
  confetti: {
    position: 'absolute',
    top: config.features.iphoneGiveaway.enabled ? 350 : 150,  // if the SVG covers the promo, it prevents click & copy actions
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  listCardContainer: {
    marginTop: '35px',
  },
  connectContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '30px',
    alignItems: 'center',
    columnGap: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: '50px',
    },
  },
  connectLeftContainer: {
    maxWidth: '500px',
  },
  buttonContainer: {
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textButton: {
    marginTop: '10px',
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    width: 'max-content',
  },
  connectHeader: {
    marginBottom: '25px',
    color: theme.palette.primary.main,
  },
  offerLinkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  offerTitle: {
    fontSize: '16px',
    color: theme.palette.common.battleshipGrey,
    marginBottom: '14px',
  },
  promoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '& p': {
      maxWidth: '400px',
    },
    '& a': {
      fontSize: '20px',
    }
  },
  promoTitle: {
    fontSize: '30px',
    fontWeight: 'bold',
    marginBottom: '14px',
  },
  promoCode: {
    border: 'solid 1px black',
    backgroundColor: '#f5f5f5',
    fontSize: '20px',
    fontFamily: 'monospace',
    padding: '10px 20px',
    margin: '10px 0px',
  },
}));

const offerStyles = makeStyles({
  root: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    marginRight: '5px',
  },
});

const Button = styled(AntdButton)`
  width: 100%;
  height: 50px;
  background-color: ${style.colors.palette.lightSeaGreen};
  color: ${style.colors.palette.blackSlate};
  border-color: ${style.colors.lightBorder};
  max-width: 400px;
  margin-top: 20px;
`;


const Success = ({ answers }) => {
  const history = useHistory();

  const serviceCatToTagMap = {
    [SERVICE_CATEGORY_ALLIED_HEALTH]: 'matching allied health',
    [SERVICE_CATEGORY_SUPPORT_COORDINATION]: 'matching support coordinator',
    [SERVICE_CATEGORY_SUPPORT_WORK]: 'matching support work',
    [SERVICE_CATEGORY_PLAN_MANAGEMENT]: 'matching plan management',
    [SERVICE_CATEGORY_CLEANING]: 'matching cleaning',
    [SERVICE_CATEGORY_GARDENING]: 'matching gardening',
  };

  const [dirUrl, setDirUrl] = useState();
  const [isDirUrlLoading, setIsDirUrlLoading] = useState(true);
  const [blogList, setBlogList] = useState([]);
  const profile = useSelector(state => state.auth.profile);
  const isDirectConnect = !!answers[DIRECT_CONNECT_FIELD];
  const handleBrowseDirClick = () => history.push(dirUrl);

  const shouldPromoteConnect = () => {
    // If not a support coordinator then DO NOT promote
    if (answers?.relationship !== 'Support Coordinator') return false;

    // If not a logged-in user then DO promote
    if (!profile?.username) return true;

    // If no roles available then DO promote
    if (!profile?.roles) return true;

    for (const role of profile.roles) {
      // If has connect access then DO NOT promote
      if (role.has_connect_access) return false;
    }

    // If reached here, then DO promote
    return true;
  };

  useEffect(() => {
    getDirectoryUrl(
      answers._qualifiedServices.unMatchedServices,
      answers.ndisPlan,
      answers.location,
      answers.telehealth).then(res => {
      setDirUrl(res);
      setIsDirUrlLoading(false);
    });

    const categoryName = answers._qualifiedServices.matchedServices.length ?
      answers._qualifiedServices.matchedServices[0].categoryName : '';
    const tag = serviceCatToTagMap[categoryName];

    CmsAPI.getBlogSearch('', tag, 3, 0)
      .then((response) => {
        setBlogList(camelcaseKeys(response.payload, {deep: true}));
      });

  }, [answers]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isDirUrlLoading) return <Loader />;

  const signUpDataForConnect = {
    email: answers.email,
    username: answers.email,
    firstName: answers.firstName,
    lastName: answers.lastName,
    contactPhone: answers.phone,
    emailOptIn: true,
  };

  const connectParticipantData = {
    firstName: answers.participantFirstName,
    lastName: answers.participantLastName,
    location: answers.location,
    email: '',
    phone: '',
    ageGroup: answers.participantAgeRange,
    gender: answers.participantGender,
    languages: answers.language ?
      answers.language.replaceAll(' ', '').split(',')
      : ['English'],
    interpreterRequired: answers.interpreterRequired,
    communicationAids: answers.communicationAids,
    telehealth: answers.telehealth,
    data: {
      onlineService: answers.onlineService,
    },
    planType: answers.ndisPlan,
    planStartDate: answers.planStartDate &&
      dayjs(answers.planStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    planDuration: answers.ndisPlanPeriod,
    contactParticipant: 'Me',
  };

  return (
    <SuccessComponent
      services={answers._qualifiedServices}
      blogPosts={blogList}
      onBrowseDirClick={handleBrowseDirClick}
      isDirectConnect={isDirectConnect}
      listingData={isDirectConnect ?
        snakecaseKeys(answers[DIRECT_CONNECT_FIELD], {deep: true}) : {}}
      shouldPromoteConnect={shouldPromoteConnect()}
      signUpDataForConnect={signUpDataForConnect}
      connectParticipantData={connectParticipantData}
    />
  );
};

const SuccessComponent = (
  {
    services,
    onBrowseDirClick,
    blogPosts,
    listingData,
    isDirectConnect,
    shouldPromoteConnect,
    signUpDataForConnect,
    connectParticipantData,
  }) => {
  const classes = useStyles();

  const [connectData, setConnectData] = useState({});
  const [connectError, setConnectError] = useState(null);
  const [isConnectBusy, setIsConnectBusy] = useState(false);
  const [offersData, setOffersData] = useState([]);
  const [directConnectProviderData, setDirectConnectProviderData] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const useMobileLayout = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    ProviderAPI.getOffers('service_request')
      .then(r => r.payload)
      .then(r => setOffersData(r))
      .catch(err => console.error(err));    // eslint-disable-line no-console

    if (!isDirectConnect) return;
    ProviderAPI.getProfile(listingData.provider_uuid)
      .then(r => r.payload)
      .then(r => setDirectConnectProviderData(r))
      .catch(err => console.error(err));    // eslint-disable-line no-console
  }, []);

  const renderDirectoryContainer = () => {
    return (
      <div className={classes.directoryContainer}>
        <div className={classes.directoryText}>
          <div className={classes.directoryHeader}>
            Continue to the Directory for {formatListToString(services.unMatchedServices.map(item => item.name))}
          </div>
          <div className={classes.directoryBody}>
            We haven’t been able to match you directly
            with {formatListToString(services.unMatchedServices.map(item => item.name))} this time,
            however please try searching our directory of over 3000+ reviewed and rated providers
            for more options.
          </div>
        </div>
        <FlowButton
          label="Browse directory"
          pointer={null}
          onClick={onBrowseDirClick}
          className={classes.browseDirButton}
        />
      </div>
    );
  };

  const renderBlogPosts = () => {

    return (
      <div>
        <div className={classes.blogContainerTitle}>
          You might be interested in...
        </div>
        <div className={classes.blogContainerSubTitle}>
          Visit our blog for NDIS resources, participant stories, and more.
        </div>
        <div className={classes.blogPostsContainer}>
          {!blogPosts.length && <Loader/>}
          {blogPosts.map((item, index) => (
            <BlogPost key={index} text={item.title} imageUrl={item.featuredImage} path={item.path} />
          ))}
        </div>
      </div>
    );
  };

  const renderGenericBody = () => (
    <>
      {!!offersData && Array.isArray(offersData) && !!offersData.length && (
        <div className={classes.offerLinkContainer}>
          <div className={classes.offerTitle}>
            While you wait, checkout these great partners and offers!
          </div>
          {offersData.map((item, index) => (
            <Offer key={`offer-${index}`} text={item?.details?.text} url={item?.details?.url}/>
          ))}
        </div>
      )}
      <FlowStepper activeStep={3} />
    </>
  );

  const renderIphoneGiveawayBody = () => (
    <>
      <div className={classes.promoContainer}>
        <div className={classes.promoTitle}>
          Go into the draw to Win an Apple Watch!
        </div>
        <p>
          While you wait, why not submit an entry into our giveaway? Your request came with 25 additional 
          entries which can be claimed using the special code
        </p>
        <div className={classes.promoCode}>
          {config.features.iphoneGiveaway.code}
        </div>
        <a target="_blank" rel="noopener noreferrer" href={config.features.iphoneGiveaway.url}>
          Click here to enter.
        </a>
        <p>
          Share it with your friends for even more chances to win!
        </p>
      </div>
      <FlowStepper activeStep={3} />
    </>
  );

  const renderDirectConnectBody = () => {
    if (!directConnectProviderData) return <Spinner />;
    return (
      <div className={classes.listCardContainer}>
        <ProviderCard data={directConnectProviderData} />
      </div>
    );
  };


  const setConnectDataValue = (field, val) => setConnectData(prevState => (
    {...prevState, [field]: val})
  );

  const onConnectSignup = async () => {
    setIsConnectBusy(true);
    setConnectError(null);

    const data = {
      ...signUpDataForConnect,
      organisation: connectData[CONNECT_BUSINESS_NAME_FILED],
      entityName: connectData[CONNECT_BUSINESS_NAME_FILED],
      hasConnectAccess: true,
      jobRole: null,
      password: connectData[CONNECT_PASSWORD_FIELD],
    };

    try {
      const { payload: signupPayload } = await AuthAPI.providerSignup({
        billing_plan_id: null,
        subscription_tier_name: 'free',
        ...snakecaseKeys(data, {deep: true}),
      });
      const providerUuid = signupPayload.uuid;

      const { payload } = await AuthAPI.providerLogin(data.username, data.password);
      const { profile, id, token_ttl } = payload;

      dispatch(AuthStore.login(profile, id, token_ttl));

      await ProviderAPI.createConnectParticipant(
        providerUuid, snakecaseKeys(connectParticipantData, {deep: true})
      );

      const url = `/connect/${providerUuid}/dashboard/participants`;
      history.push(url, {isNewUser: true});

    } catch(err) {
      setIsConnectBusy(false);
      if (err.body) {
        const { message } = err.body;
        setConnectError(message);
      } else {
        setConnectError('An error occurred updating your profile.');
      }
    }
  };

  const renderConnectPromotion = () => (
    <div className={classes.connectContainer}>
      <div className={classes.connectLeftContainer}>
        <div className={classes.connectHeader}>
          Create an account with us to save your details for next time. Easily
          manage your participants and submit service requests in <b>under 60 seconds</b>.
        </div>
        <Textbox
          title="Business Name"
          onChange={val => setConnectDataValue(CONNECT_BUSINESS_NAME_FILED, val)}
          validator={value => !!value?.match(/^[\w\-@&# ]+$/g)}
          errorMsg="Please enter a valid name"
          colouredBorder
        />
        <Password
          title="Password"
          onChange={val => setConnectDataValue(CONNECT_PASSWORD_FIELD, val)}
          validator={val => val && val.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w\W]{8,}$/)}
          errorMsg="Password must be at least 8 characters and contain at least
          one each of: upper case, lower case, and digits."
          colouredBorder
        />
        <div className={classes.buttonContainer}>
          <Button
            type="primary"
            size="large"
            disabled={!connectData[CONNECT_BUSINESS_NAME_FILED] ||
            !connectData[CONNECT_PASSWORD_FIELD]}
            loading={isConnectBusy}
            onClick={onConnectSignup}
          >
            Create Account
          </Button>
          <div
            className={classes.textButton}
            onClick={() => (
              history.push(
                `/providers/login?username=${encodeURIComponent(signUpDataForConnect.email)}`
              ))}
          >
            Already have an account? Login!
          </div>
          {connectError && <Alert style={{marginTop: '20px'}} message={connectError} type="error"/>}
        </div>
      </div>
      <div>
        <img src={connectDashboard} alt="Connect Dashboard" width={useMobileLayout ? '100%' : '550px'} />
      </div>
    </div>
  );

  const renderBody = () => {
    if (config.features.iphoneGiveaway.enabled) return renderIphoneGiveawayBody();
    if (isDirectConnect) return renderDirectConnectBody();
    if (shouldPromoteConnect) return renderConnectPromotion();
    return renderGenericBody();
  };

  const renderFooter = () => {
    if (isDirectConnect) return renderBlogPosts();
    if(shouldPromoteConnect) return null;
    if (!services.unMatchedServices.length) return renderBlogPosts();
    return renderDirectoryContainer();
  };

  return (
    <div className={classes.root}>
      <Lottie
        animation={successConfetti}
        height="200px"
        className={classes.confetti}
        options={{loop: 1}}
      />
      {renderBody()}
      {renderFooter()}
    </div>
  );
};

const Offer = ({ text, url }) => {

  const classes = offerStyles();
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className={classes.root}
      onClick={() => window.open(url, '_blank')}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      <FontAwesomeIcon icon={isHovering ? faSquareCheck : faSquare} className={classes.icon} />
      {text}
    </div>
  );
};

Offer.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
};

SuccessComponent.propTypes = {
  services: PropTypes.object.isRequired,
  onBrowseDirClick: PropTypes.func,
  blogPosts: PropTypes.arrayOf(PropTypes.object),
  listingData: PropTypes.object,
  isDirectConnect: PropTypes.bool,
  shouldPromoteConnect: PropTypes.bool,
  signUpDataForConnect: PropTypes.object,
  connectParticipantData: PropTypes.object,
};


Success.propTypes = {
  answers: PropTypes.object,
};

export default Success;