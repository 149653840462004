import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ReviewCard from './ReviewCard.js';
import LoadingCards from '../LoadingCards.js';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '2rem 0 1rem 0',
  },
});

const ReviewCardList = ({isLoading, reviews, providerName}) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <LoadingCards />
    );
  }

  if (reviews.length === 0) {
    return (
      <div className={classes.root}>
        <Alert severity="info">There are no reviews for this provider yet. Be the first to share your experience and help others!</Alert>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {reviews.map((item, i) =>
        <ReviewCard key={i} data={item} withResponses={true} providerName={providerName}/>
      )}
    </div>
  );
};

ReviewCardList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  reviews: PropTypes.array,
  providerName: PropTypes.string.isRequired,
};

export default ReviewCardList;
