import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMedkit,
} from '@fortawesome/pro-solid-svg-icons';
import Typography from '@material-ui/core/Typography';

import QualifiedServiceIcon from 'components/QualifiedServiceIcon';
import circleBgLeft from 'assets/circle-bg.svg';
import circleBgRight from 'assets/circle-bg-2.svg';


export const SERVICES = [
  {
    name: 'Occupational Therapy',
    urlKey: 'ndis-occupational-therapy'
  },
  {
    name: 'Support Coordination',
    urlKey: 'ndis-support-coordination'
  },
  {
    name: 'Plan Management',
    urlKey: 'ndis-plan-management'
  },
  {
    name: 'Support Work',
    urlKey: 'ndis-support-work'
  },
  {
    name: 'Speech Therapy',
    urlKey: 'ndis-speech-pathology'
  },
  {
    name: 'Cleaning',
    urlKey: 'ndis-cleaning'
  },
  {
    name: 'Gardening',
    urlKey: 'ndis-garden-maintenance'
  },
  {
    name: 'Psychology',
    urlKey: 'ndis-psychology'
  },
  {
    name: 'Physiotherapy',
    urlKey: 'ndis-physiotherapy'
  },
  {
    name: 'Other',
    urlKey: 'requestmatch'
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: 'linear-gradient(20deg, rgba(5,14,64,1) 0%, rgba(22,17,48,1) 55%, rgba(25,25,25,1) 100%)',
    width: '100%',
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  title: {
    fontSize: '28px',
    fontWeight: '600',
    color: theme.palette.common.white,
  },
  subTitle: {
    marginTop: '15px',
    fontSize: '18px',
    padding: '0 120px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
  tileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
    marginTop: '30px',
  },
  leftCircle: {
    zIndex: 0,
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    backgroundSize: '200px',
    backgroundImage: `url(${circleBgLeft})`,
    height: '200px',
    width: '120px',
    left: '0px',
    bottom: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '60px',
      bottom: '50px',
    },
  },
  rightCircle: {
    zIndex: 0,
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom',
    backgroundSize: '450px',
    backgroundImage: `url(${circleBgRight})`,
    height: '370px',
    width: '200px',
    right: '0px',
    top: '0px',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '250px',
      width: '100px',
      top: '80px',
    },
  },
  container: {
    position: 'relative',
    zIndex: 1,
    padding: '100px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px',
    },
  },
}));


const ServicesCovered = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.leftCircle} />
      <Container maxWidth="lg" fixed disableGutters={true} className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          We&apos;ve got you covered
        </Typography>
        <div className={classes.subTitle}>
          Looking for specific services but not sure whether they&apos;re covered in your
          NDIS plan? We&apos;re here to help you navigate the NDIS and find trusted providers.
        </div>
        <div className={classes.tileContainer}>
          {SERVICES.map(item => <ServiceTile key={item.urlKey} serviceName={item.name} urlKey={item.urlKey} />)}
        </div>
      </Container>
      <div className={classes.rightCircle} />
    </div>
  );
};

const serviceTileStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'rgb(145 158 171 / 15%) 0px 0px 4px 0px, rgb(145 158 171 / 15%) 2px -2px 20px 0px',
    border: '1px solid',
    borderColor: theme.palette.common.lightBorder,
    width: '200px',
    height: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '30px',
    cursor: 'pointer',
    padding: '0 10px',
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      border: '2px solid',
    },
    [theme.breakpoints.down('sm')]: {
      width: '170px',
      height: '150px',
    },
  },
  iconContainer: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '26px',
    filter: 'drop-shadow(0 0 2px #545456)',
  },
  text: {
    marginTop: '10px',
    color: theme.palette.primary.main,
    fontWeight: '500',
    fontSize: '16px',
  },
}));

const ServiceTile = ({serviceName, urlKey}) => {
  const classes = serviceTileStyles();

  return (
    <Link className={classes.root} to={`/${urlKey}`}>
      <div className={classes.iconContainer}>
        {serviceName !== 'Other' ? (
          <QualifiedServiceIcon.Regular className={classes.icon} serviceName={serviceName}/>
        ) : <FontAwesomeIcon icon={faMedkit} className={classes.icon} /> }
      </div>
      <Typography variant="h3" className={classes.text}>
        {serviceName}
      </Typography>
    </Link>
  );
};

ServiceTile.propTypes = {
  serviceName: PropTypes.string,
  urlKey: PropTypes.string,
};

export default ServicesCovered;