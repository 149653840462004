const colors = {
  primary: '#050E40',
  secondary: '#21bfaf',
  textPrimary: '#545456',
  textSecondary: '#264f73',
  success: '#52c41a',
  warning: '#faad14',
  error: '#f5222d',
  info: '#2196f3',
  lightBorder: '#e2e2e2',
  palette: {
    black: '#000',
    blackSlate: '#191919',
    white: '#fff',
    paleGrey: '#f5f5f7',
    silver: '#d5d5d6',
    battleshipGrey: '#74747b',
    vermillion: '#da3510',
    dustyOrange: '#f26849',
    squash: '#f2a922',
    lightSeaGreen: '#99f2bf',
    darkSlateBlue: '#264f73',
    textGrey: '#545456',
    highlightBlue: '#E0F0FD',
    fauxTiffanyBlue: '#00b7a6',
    fauxIndigo: '#1d4666',
    aliceBlue: '#ecf0f3',
    red: '#ff4d4f',
    nightBlue: '#050e40',
  },
};

const shadow = strength => (`rgb(145 158 171 / ${strength}%) 0px 0px 4px 0px, 
  rgb(145 158 171 / ${strength}%) 2px -2px 20px 0px`);

const borderRadius = {
  large: '12px',
  medium: '8px',
  small: '6px',
};

const sizes = {
  full: '100%',
  large: '1280px',
  medium: '1050px',
  small: '850px'
};

const screens = {
  mobile: '730px',
  tab: '960px',
};

const constants = {
  navBarHeight: '65px',
};

const zIndexes = {
  navBar: 999,
};


export default {
  colors,
  shadow,
  constants,
  sizes,
  zIndexes,
  borderRadius,
  screens,
};
