import React, {useState} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Question from './Question';
import {ERROR_CONTAINER_HEIGHT, QUESTION_BORDER_COLOR} from '../../../constants';


const DATE_FORMAT = 'DD/MM/YYYY';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: ({isEmptyField}) => !isEmptyField && ERROR_CONTAINER_HEIGHT,
  },
  datePicker: {
    margin: '0',
    padding: '10px',
    background: theme.palette.common.paleGrey,
    border: '1px solid',
    borderColor: QUESTION_BORDER_COLOR,
    borderRadius: '8px',
    height: '55px',
    minWidth: '250px',

    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      outline: '0',
      border: 'none',
      boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}`,
    },

    '& .MuiFormHelperText-root.Mui-error': {
      display: ({canShowError}) => !canShowError && 'none',
      fontSize: '13px',
      marginTop: '15px',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: ({canShowError}) => !canShowError && theme.palette.primary.main,
    },
    // To stop mobile devices auto zoom while typing inputs
    '& .MuiInputBase-input': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
  },
  errorContainer: {
    height: `calc(${ERROR_CONTAINER_HEIGHT} - 3px)`,
    fontSize: '13px',
    color: theme.palette.error.main,
    marginLeft: '15px',
    marginTop: '3px',
  },
}));

const DateQuestion = (
  {
    text,
    helperText,
    onChange,
    value: defaultValue,
    disableFuture,
    disablePast,
    isOptional,
    answers,
  }) => {

  const [isEmptyField, setIsEmptyField] = useState(false);
  const [canShowError, setCanShowError] = useState(false);

  const classes = useStyles({isEmptyField: isEmptyField, canShowError: canShowError});

  const formatDate = date => date && moment(date).format(DATE_FORMAT);
  const convertToDate = date => moment(date, DATE_FORMAT).toDate();

  const handleDateChange = (date) => {
    setIsEmptyField(date === null);
    moment(date).isValid() ? onChange(formatDate(date)) : onChange('invalid');
  };

  return (
    <div className={classes.root}>
      <Question title={text} subTitle={helperText} isOptional={isOptional} answers={answers}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            onFocus={() => setCanShowError(false)}
            onBlur={() => setCanShowError(true)}
            tabIndex="0"
            className={classes.datePicker}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={defaultValue ? convertToDate(defaultValue) : null}  // Need to pass 'null' for no selection on the first render
            autoOk={true}
            minDateMessage="Date cannot be in the past"
            maxDateMessage="Date cannot be in the future"
            onChange={handleDateChange}
            disableFuture={disableFuture}
            disablePast={disablePast}
          />
        </MuiPickersUtilsProvider>
        {isEmptyField && (
          <div className={classes.errorContainer}>
            {canShowError && 'Please complete this field'}
          </div>
        )}
      </Question>
    </div>
  );
};

DateQuestion.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  isOptional: PropTypes.func,
  answers: PropTypes.object,
};

export default DateQuestion;