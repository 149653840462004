import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Input as AntdInput,
  Select as AntdSelect,
  AutoComplete as AntdAutoComplete,
  Radio as AntdRadio,
} from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useGetApi, ProviderAPI } from 'providerSite/api';
import style from 'style';

dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
const AntdDatePicker = generatePicker(dayjsGenerateConfig);


const TEXTBOX_HEIGHT = '42px';
const FONT_SIZE = '15px';
const MOBILE_FONT_SIZE = '16px';

const Title = styled.div`
  color: ${style.colors.primary};
  font-weight: 500;
  margin-bottom: 8px;
`;

const ErrorContainer = styled.div`
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  margin-top: 5px;
  color: ${style.colors.palette.red};
`;

const Container = styled.div`
  width: 100%;
`;

const Input = styled(AntdInput)`
  height: ${TEXTBOX_HEIGHT};
  font-size: ${FONT_SIZE};
  border-color: ${({colouredBorder}) => colouredBorder && style.colors.secondary};
  @media (max-width: ${style.screens.mobile}){
    font-size: ${MOBILE_FONT_SIZE};
  }
`;

const InputTextArea = styled(Input.TextArea)`
  height: ${TEXTBOX_HEIGHT};
  font-size: ${FONT_SIZE};
  border-color: ${({colouredBorder}) => colouredBorder && style.colors.secondary};
  @media (max-width: ${style.screens.mobile}){
    font-size: ${MOBILE_FONT_SIZE};
  }
`;

const InputPassword = styled(AntdInput.Password)`
  height: ${TEXTBOX_HEIGHT};
  border-color: ${({colouredBorder}) => colouredBorder && style.colors.secondary};
  & input.ant-input {
    font-size: ${FONT_SIZE} !important;
    @media (max-width: ${style.screens.mobile}){
      font-size: ${MOBILE_FONT_SIZE} !important;
    }
  }
`;

const AutoComplete = styled(AntdAutoComplete)`
  height: ${TEXTBOX_HEIGHT};
  font-size: ${FONT_SIZE};
  @media (max-width: ${style.screens.mobile}){
    font-size: ${MOBILE_FONT_SIZE};
  }
  .ant-select-selector {
    height: ${TEXTBOX_HEIGHT} !important;
  }
  .ant-select-selection-search-input {
    height: ${TEXTBOX_HEIGHT} !important;
  }
`;

const Select = styled(AntdSelect)`
  height: ${TEXTBOX_HEIGHT};
  font-size: ${FONT_SIZE};
  @media (max-width: ${style.screens.mobile}){
    font-size: ${MOBILE_FONT_SIZE};
  }
  .ant-select-selector {
    height: ${TEXTBOX_HEIGHT} !important;
    border-color: ${({colouredBorder}) => colouredBorder && style.colors.secondary} !important;
  }
  .ant-select-selection-item {
    line-height: ${TEXTBOX_HEIGHT} !important;
  }
  &.ant-select-multiple.ant-select-lg .ant-select-selection-item {
    border-radius: 6px;
    line-height: 24px !important;
    background-color: rgba(5, 14, 64, 0.1);
    height: 25px;
    font-size: 14px;
  }
`;

const RadioGroup = styled(AntdRadio.Group)`
  width: 100%;
  height: ${TEXTBOX_HEIGHT};
  display: flex;
  .ant-radio-button-wrapper {
    display: block;
    text-align: center;
    flex-grow: 2;
    height: ${TEXTBOX_HEIGHT};
    line-height: ${TEXTBOX_HEIGHT};
    font-size: ${FONT_SIZE};
    font-weight: 500;
    @media (max-width: ${style.screens.mobile}){
      font-size: ${MOBILE_FONT_SIZE};
    }
    :hover {
      background-color: #43ccba;
      color: ${style.colors.palette.white}
    }
  }
  .ant-radio-button-wrapper:first-child {
    margin-right: 5px;
  }
  .ant-radio-button-wrapper:last-child {
    margin-left: 5px;
  }
`;

const DatePickerStyled = styled(AntdDatePicker)`
  &.ant-picker-large .ant-picker-input > input {
    font-size: ${FONT_SIZE};
    line-height: ${FONT_SIZE};
    @media (max-width: ${style.screens.mobile}){
      font-size: ${MOBILE_FONT_SIZE};
      line-height: ${MOBILE_FONT_SIZE};
    }
  }
  &.ant-picker {
    height: ${TEXTBOX_HEIGHT};
    width: 100%;
  }
`;


export const Textbox = (
  {
    title,
    defaultValue,
    onChange,
    onBlur,
    onClick,
    validator,
    errorMsg,
    colouredBorder,
    fwdRef,
    type,
    placeholder,
    multiline,
    multilineRows,
    style,
    titleStyle,
  }) => {

  const [data, setData] = useState(defaultValue);

  const isError = isValidationError(data, validator);

  useEffect(() => {
    if ([undefined, null].includes(defaultValue)) return;
    setData(defaultValue);
  }, [defaultValue]);

  const handleChange = event => {
    const value = event.target.value;
    setData(value);
    isValidationError(value, validator) ? onChange(null) : onChange(value);
  };

  const InputComponent = multiline ? InputTextArea : Input;

  return (
    <Container ref={fwdRef}>
      <Title style={titleStyle}>{title}</Title>
      <InputComponent
        size="large"
        onChange={handleChange}
        value={data}
        status={getValidationStatus(data, validator)}
        colouredBorder={colouredBorder}
        onBlur={onBlur}
        onClick={onClick}
        type={type}
        placeholder={placeholder}
        rows={!!multiline && multilineRows}
        style={style}
      />
      <ErrorContainer>
        {isError && errorMsg}
      </ErrorContainer>
    </Container>
  );
};

export const Password = (
  {
    title,
    defaultValue,
    onChange,
    validator,
    errorMsg,
    colouredBorder
  }) => {

  const [data, setData] = useState(defaultValue);

  const isError = isValidationError(data, validator);

  useEffect(() => {
    if ([undefined, null].includes(defaultValue)) return;
    setData(defaultValue);
  }, [defaultValue]);

  const handleChange = event => {
    const value = event.target.value;
    setData(value);
    isValidationError(value, validator) ? onChange(null) : onChange(value);
  };

  return (
    <Container>
      <Title>{title}</Title>
      <InputPassword
        size="large"
        onChange={handleChange}
        value={data}
        status={getValidationStatus(data, validator)}
        colouredBorder={colouredBorder}
      />
      <ErrorContainer>
        {isError && errorMsg}
      </ErrorContainer>
    </Container>
  );
};

export const LocationTextBox = ({ title, defaultValue, onChange }) => {

  const [data, setData] = useState(defaultValue);
  const [query, setQuery] = useState(' ');
  const [isSelected, setIsSelected] = useState();

  const { data: _locationData } = useGetApi(ProviderAPI.GET_LOCATION_AUTOCOMPLETE, {
    queryParams: {
      query: query,
      limit: 10,
    },
  });

  const errorMsg = 'Please select a valid location';

  const locationData = _locationData?.map(item => ({value: item}));

  const onSearch = searchText => {
    setQuery(searchText);
  };

  useEffect(() => {
    if ([undefined, null].includes(defaultValue)) return;
    setData(defaultValue);
  }, [defaultValue]);

  const handleChange = value => {
    setData(value);
    onChange(null);
    setIsSelected(false);
  };

  const handleSelect = value => {
    setData(value);
    setIsSelected(true);
    onChange(value);
  };

  const handleLeaving = () => {
    isSelected === false && setData(null);
  };

  return (
    <Container>
      <Title>{title}</Title>
      <AutoComplete
        value={data}
        options={locationData}
        style={{width: '100%'}}
        size="large"
        onSearch={onSearch}
        onChange={handleChange}
        onSelect={handleSelect}
        onBlur={handleLeaving}
      />
      <ErrorContainer>
        {isSelected === false && errorMsg}
      </ErrorContainer>
    </Container>
  );
};

export const Dropdown = (
  {
    title,
    options,
    defaultValue,
    onChange,
    multiple,
    validator,
    errorMsg,
    colouredBorder,
  }) => {
  const _emptyValue = multiple && [];
  const [data, setData] = useState(defaultValue ? defaultValue : _emptyValue);

  const isError = isValidationError(data, validator);

  useEffect(() => {
    if ([undefined, null].includes(defaultValue)) return;
    setData(defaultValue);
  }, [defaultValue]);

  const handleChange = value => {
    setData(value);
    isValidationError(value, validator) ? onChange(null) : onChange(value);
  };

  return (
    <Container>
      <Title>{title}</Title>
      <Select
        mode={multiple && 'multiple'}
        style={{width: '100%'}}
        size="large"
        value={data}
        onChange={handleChange}
        colouredBorder={colouredBorder}
      >
        {options?.map((item, index) => (
          <Select.Option key={index} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>
      <ErrorContainer>
        {isError && errorMsg}
      </ErrorContainer>
    </Container>
  );
};

export const Radio = ({ title, options, defaultValue, onChange, validator, errorMsg }) => {
  const [data, setData] = useState(defaultValue);

  const isError = isValidationError(data, validator);

  useEffect(() => {
    if ([undefined, null].includes(defaultValue)) return;
    setData(defaultValue);
  }, [defaultValue]);

  const handleChange = event => {
    const value = event.target.value;
    setData(value);
    isValidationError(value, validator) ? onChange(null) : onChange(value);
  };

  return (
    <Container>
      <Title>{title}</Title>
      <RadioGroup buttonStyle="solid" size="large" value={data} onChange={handleChange}>
        {options?.map((item, index) => (
          <AntdRadio.Button key={index} value={item}>{item}</AntdRadio.Button>
        ))}
      </RadioGroup>
      <ErrorContainer>
        {isError && errorMsg}
      </ErrorContainer>
    </Container>
  );
};

export const DatePicker = ({ title, defaultValue, onChange }) => {

  const renderFormat = 'DD/MM/YYYY';
  const dbFormat = 'YYYY-MM-DD';

  const [data, setData] = useState(defaultValue);

  useEffect(() => {
    if ([undefined, null].includes(defaultValue)) return;
    setData(defaultValue);
  }, [defaultValue]);

  const handleChange = (_, value) => {
    const _val = value && dayjs(value, renderFormat).format(dbFormat);
    setData(_val);
    onChange(_val);
  };

  return (
    <Container>
      <Title>{title}</Title>
      <DatePickerStyled
        allowClear={false}
        size="large"
        onChange={handleChange}
        format={renderFormat}
        value={data && dayjs(data, dbFormat)}
      />
      <ErrorContainer />
    </Container>
  );
};

Textbox.propTypes = {
  title: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  validator: PropTypes.func,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  colouredBorder: PropTypes.bool,
  onBlur: PropTypes.func,
  fwdRef: PropTypes.node,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  multilineRows: PropTypes.number,
  style: PropTypes.object,
  titleStyle: PropTypes.object,
};

Password.propTypes = {
  title: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validator: PropTypes.func,
  errorMsg: PropTypes.string,
  colouredBorder: PropTypes.bool,
};

LocationTextBox.propTypes = {
  title: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Dropdown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  validator: PropTypes.func,
  errorMsg: PropTypes.string,
  colouredBorder: PropTypes.bool,
};

Radio.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validator: PropTypes.func,
  errorMsg: PropTypes.string,
};

DatePicker.propTypes = {
  title: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const getValidationStatus = (data, validator) => {
  if ([undefined, null].includes(data) || !validator) return;
  return !validator(data) && 'error';
};

const isValidationError = (data, validator) => getValidationStatus(data, validator) === 'error';
