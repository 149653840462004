import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import camelcase from 'camelcase';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {CircularProgress} from '@material-ui/core';
import Recaptcha from 'react-recaptcha';
import dayjs from 'dayjs';
import { notification } from 'antd';

import DashboardFlow from './';
import {MODALITY_EXTENSION_QUESTION_MAP, MODALITY_QUESTION_MAP} from './modalityMappers';
import {AuthStore, TaxonomyStore} from 'store';
import {ServiceRequestAPI} from 'api';
import Loader from 'components/Loader';
import {
  PHONE_FIELD,
  RECAPTCHA_CODE_FIELD,
  TERMS_AND_CONDITION_FIELD,
  DIRECT_CONNECT_FIELD,
  PAGE_CONTACT_INFO,
} from '../../constants';
import NavPanel from './components/NavPanel';
import SpeechBubble from './components/SpeechBubble';
import TermsAndCondition from './components/TermsAndCondition';
import FlowButton from '../components/FlowButton';
import RecaptchaProvider from 'components/RecaptchaProvider';
import config from 'config';
import SnackbarAlert from 'components/SnackbarAlert';
import OTPModal from './components/OTPModal';
import * as track from 'lib/track';


const _baseBottomNavButton = {
  height: '40px',
  fontSize: '14px',
  fontWeight: '500',
  width: '120px',
  boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.25)',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '70vh',
    display: 'flex',
  },
  container: {
    display: 'flex',
    width: '100%',
    maxWidth: '900px',
    flexDirection: 'column',
    padding: '30px 30px 0 30px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '15px',
    },
  },
  containerHeader: {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '35px',
    },
  },
  containerTitle: {
    fontSize: '34px',
    fontWeight: '700',
    color: theme.palette.primary.main,
    marginBottom: '25px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  flowContainer: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },
  finalPageBottomContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '30px',
  },
  bottomNavContainer: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '30px 20px',
    columnGap: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
      padding: '30px 0'
    },
  },
  bottomNavButton: {
    ..._baseBottomNavButton,
    backgroundColor: ({isLastPage}) => (
      isLastPage ? theme.palette.common.tealish : theme.palette.common.darkSlateBlue),
    color: ({isLastPage}) => (
      isLastPage ? theme.palette.common.paleGrey : theme.palette.common.white),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: ({isLastPage}) => (
        !isLastPage ? theme.palette.common.tealish : theme.palette.common.darkSlateBlue),
      color: ({isLastPage}) => (
        !isLastPage ? theme.palette.common.paleGrey : theme.palette.common.white),
    },
  },
  bottomNavCancelButton: {
    ..._baseBottomNavButton,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.common.darkSlateBlue,
    color: theme.palette.primary.main,
    '&:hover': {
      border: 'none',
      backgroundColor: theme.palette.common.tealish,
      color: theme.palette.common.paleGrey,
    },
  },
  bottomNavMatchButton: {
    ..._baseBottomNavButton,
    backgroundColor: theme.palette.common.squash,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.tealish,
      color: theme.palette.common.paleGrey,
    },
  },
}));

const DashboardFlowContainer = (
  {
    children,
    flow,
    answers,
    setAnswers,
    currentPage,
    next,
    setPagename,
    complete,
    completedPages,
    setCompletedPages,
    totalQuestions,
    totalQuestionsAnswered,
    helperFunctions,
    connectMode,
    providerUuid,
    source,
    isViewMode=false,
    serviceRequestStatusData={},
  }) => {

  const isEndOfFlow = () => !currentPage?.isEndOfJourney && next?.isEndOfJourney;

  const classes = useStyles({isLastPage: isEndOfFlow()});

  const { uuid } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const qualifiedServicesTaxonomy = camelcaseKeys(useSelector(
    state => state.taxonomy.qualifiedServices), {deep: true});
  const profile = useSelector(state => camelcaseKeys(state.auth.profile, {deep: true}));
  const userUuid = !!(profile && Object.keys(profile).length) && profile.userUuid;
  const accountType = profile && Object.keys(profile).length ?
    (profile.isProvider ? 'Provider' : 'Participant')
    : null;

  const [dashboardFlowOnlyPages, setDashboardFlowOnlyPages] = useState([]);
  const [navPanelPageData, setNavPanelPageData] = useState([]);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  // These maps help to categorise service specific questions before sending to the backend
  const [serviceQuestionKeys, setServiceQuestionKeys] = useState({});
  const [serviceQuestionKeysToNamesMap, setServiceQuestionKeysToNamesMap] = useState({});
  const [updateSnackbarStatus, setUpdateSnackbarStatus] = useState('');
  const [serviceRequestUuids, setServiceRequestUuids] = useState();
  const [submitError, setSubmitError] = useState();
  const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(true);
  const [isOPTSubmitLoading, setIsOPTSubmitLoading] = useState(false);
  const [isOTPOpen, setIsOTPOpen] = useState(false);
  const reCaptchaRef = useRef();

  // Inits Service Taxonomy and User Profile redux stores on the component load to
  // make sure they exist
  useEffect(() => {
    !qualifiedServicesTaxonomy.length && dispatch(
      TaxonomyStore.refreshTaxonomy(TaxonomyStore.QUALIFIED_SERVICE_TAXONOMY)
    );
    dispatch(AuthStore.refreshProfile());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // When current page changes, scroll to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [currentPage]);

  // Anytime a new service is added/removed, the system dynamically generates the
  // dashboard flow pages
  useEffect(() => {
    generateDynamicFlowPages();
    const _dashboardFlowOnlyPages = flow.pages.filter(
      item => item.QuestionContainer === DashboardFlow && !(!!item.isHidden && item.isHidden(answers))
    );
    setDashboardFlowOnlyPages(_dashboardFlowOnlyPages);
  }, [answers?.servicesNeeded, answers?._qualifiedServices, answers?.ndisPlan]); // eslint-disable-line react-hooks/exhaustive-deps

  // Creates initial Page Indicator data
  useEffect(() => {
    setNavPanelPageData(
      dashboardFlowOnlyPages.map(
        item => ({
          name: item.name,
          text: item.data.info,
          isModalityQuestion: !!item.isModalityQuestion,
          isDeleteDisabled: answers?._qualifiedServices.matchedServices.length <= 1
        }))
    );
  }, [dashboardFlowOnlyPages]); // eslint-disable-line react-hooks/exhaustive-deps

  // Updates Page Indicators
  useEffect(() => {
    setNavPanelPageData(prevState => {
      prevState.forEach(item => {
        item['isDisabled'] = item.name !== helperFunctions.reachablePage(item.name).name;

        if (item.name === currentPage.name) {
          item['status'] = 'current';
          item['isDisabled'] = true;
        } else if (completedPages.has(item.name)) {
          item['status'] = 'complete';
        }  else {
          item['status'] = '';
        }
      });

      return [...prevState];
    });

  }, [currentPage, completedPages, dashboardFlowOnlyPages, complete]);  // eslint-disable-line react-hooks/exhaustive-deps

  // When current page changes or current page's complete status changes, it
  // adds/removes the page to/from completedPages state.
  useEffect(() => {
    setCompletedPages(prevState => {
      if (complete) {
        prevState.add(currentPage.name);
      } else {
        prevState.delete(currentPage.name);
      }
      return new Set(prevState);
    });
  }, [currentPage, complete]);  // eslint-disable-line react-hooks/exhaustive-deps

  // When the current page changes, it sets the page name to the current page.
  // It's related to URL routing.
  useEffect(() => {
    setPagename(currentPage.name);
  }, [currentPage]);  // eslint-disable-line react-hooks/exhaustive-deps

  const getFormattedField = (name, fieldName) => camelcase(`${name}_${fieldName}`);

  const updateServiceQuestionKeys = (serviceName, questionKey, questionName) => {
    setServiceQuestionKeys(prevState => {
      if (!(serviceName in prevState)) {
        prevState[serviceName] = [];
      }
      prevState[serviceName].push(questionKey);
      return {...prevState};
    });

    setServiceQuestionKeysToNamesMap(prevState => (
      {...prevState, [questionKey]: questionName})
    );
  };

  // Generates and populates service request uuid map for view mode
  useEffect(() => {
    if (!isViewMode)
      return;

    ServiceRequestAPI.getServiceInquiry(uuid)
      .then(r => camelcaseKeys(r, {deep: true}))
      .then(r => {
        const serviceToUuidMap = r.payload.serviceRequests.reduce((acc, item) => {
          return {...acc, [item.serviceName]: item.uuid};
        }, {});
        setServiceRequestUuids(serviceToUuidMap);
      })
      .catch(err => console.error(err));    // eslint-disable-line no-console
  }, [uuid]);   // eslint-disable-line react-hooks/exhaustive-deps

  // Creates a list of service pages from the answers
  const _getServicePages = () => {
    setServiceQuestionKeys({});
    setServiceQuestionKeysToNamesMap({});
    return answers?._qualifiedServices.matchedServices.map(item => {
      const newPage = {...flow.modalityPageTemplate};
      const formattedName = item.name.toLowerCase().replaceAll(' ', '-');
      newPage.name = formattedName;
      newPage.data = {info: item.name, helpPrompt: newPage.data.helpPrompt};
      newPage.questions = [...MODALITY_QUESTION_MAP[item.categoryName].map(q => {
        const _field = getFormattedField(formattedName, q.field);
        updateServiceQuestionKeys(item.name, _field, q.field);
        return {...q, field: _field};
      })];
      if (item.categoryName in MODALITY_EXTENSION_QUESTION_MAP) {
        const extraQuestions = [...MODALITY_EXTENSION_QUESTION_MAP[item.categoryName].map(q => {
          const _field = getFormattedField(formattedName, q.field);
          updateServiceQuestionKeys(item.name, _field, q.field);
          return {...q, field: _field};
        })];
        newPage.questions = [...newPage.questions, ...extraQuestions];
      }
      return newPage;
    });
  };

  // Generates and pushes service pages into the flow
  const generateDynamicFlowPages = () => {
    const servicePages = _getServicePages();
    const {genericPages, endOfJourneyPages} = flow.pages.reduce((acc, item) => {
      if (item.isEndOfJourney) {
        acc.endOfJourneyPages.push(item);
      } else if (!item.isModalityQuestion) {
        acc.genericPages.push(item);
      }
      return acc;
    }, {genericPages: [], endOfJourneyPages: []});

    flow.pages = [...genericPages, ...servicePages, ...endOfJourneyPages];
  };

  // Handles the action to delete service when the delete button is clicked
  const handleServiceDeleteClick = (serviceIdentifier) => {
    setAnswers(prevState => {
      const matchedServices = [...prevState._qualifiedServices.matchedServices.filter(
        item => item.name !== serviceIdentifier)];
      const _qualifiedServices = {...prevState._qualifiedServices};
      _qualifiedServices.matchedServices = matchedServices;
      const servicesNeeded = [...prevState.servicesNeeded.filter(item => item.name !== serviceIdentifier)];
      return {...prevState, _qualifiedServices: _qualifiedServices, servicesNeeded: servicesNeeded};
    });
  };

  // Handles the action to add service when the Add Service button is clicked
  const handleServiceAdd = service => {
    setAnswers(prevState => {
      const _qualifiedServices = {...prevState._qualifiedServices};
      _qualifiedServices.matchedServices = [..._qualifiedServices.matchedServices, service];
      const servicesNeeded = [...prevState.servicesNeeded, {id: service.id, name: service.name}];
      return {...prevState, _qualifiedServices: _qualifiedServices, servicesNeeded: servicesNeeded};
    });
  };

  // Handles the action to go to the next page when the Next button is clicked
  const handleClickNext = () => {
    setCompletedPages(prevState => {
      prevState.add(currentPage.name);
      return new Set(prevState);
    });
    setPagename(next.name);
  };

  // Handles the action when a Page Indicator is clicked
  const handlePageIndicatorOnClick = (pageInfoName) => {
    setPagename(pageInfoName);
  };

  // Handles match click on view mode
  const handleMatchClick = serviceName => {
    const serviceRequestUuid = serviceRequestUuids[serviceName];
    const location = answers?.location;
    const url = `/search?matching=${serviceRequestUuid}&service=${serviceName}&service_location=${location}`;

    history.push(url);
  };

  // Format data before sending it via API
  const formatSubmitData = () => {
    const serviceQuestionAnswers = answers._qualifiedServices.matchedServices.reduce((acc, item) => {
      const _answers = serviceQuestionKeys[item.name].reduce((acc, key) => {
        if (answers[key] === undefined || answers[key] === null)
          return acc;
        return {...acc, [serviceQuestionKeysToNamesMap[key]]: answers[key]};
      }, {});
      return {...acc, [item.name]: _answers};
    }, {});

    const serviceQuestionKeysList = Object.keys(serviceQuestionKeys).reduce((acc, key) => {
      return [...acc, ...serviceQuestionKeys[key].map(item => item)];
    }, []);

    const data = {
      ...answers,
      services: answers._qualifiedServices.matchedServices.map(item => {
        return {name: item.name, id: item.id};
      }),
      account_uuid: answers.account_uuid ? answers.account_uuid : (userUuid ? userUuid : null),
      account_type: answers.account_type ? answers.account_type : accountType,
      service_question_answers: serviceQuestionAnswers,
    };

    if (answers[DIRECT_CONNECT_FIELD])
      data['direct_connect'] = answers[DIRECT_CONNECT_FIELD].uuid;

    ['_qualifiedServices', 'servicesNeeded', '_ageGroups',
      DIRECT_CONNECT_FIELD, RECAPTCHA_CODE_FIELD,
      TERMS_AND_CONDITION_FIELD, ...serviceQuestionKeysList].forEach(key => {
      delete data[key];
    });

    if (source) data['source'] = source;

    return data;
  };

  // Handles the action when the final Submit button is clicked
  const handleOtpSubmit = () => {
    if (isOPTSubmitLoading) return;

    setIsOPTSubmitLoading(true);
    setSubmitError(null);

    const data = {
      phoneNumber: answers[PHONE_FIELD],
      recaptchaCode: answers[RECAPTCHA_CODE_FIELD],
    };

    ServiceRequestAPI.sendOTP(snakecaseKeys(data, {deep: true}))
      .then(() => {
        setIsOTPOpen(true);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      })
      .catch(err => {
        console.error(err);   // eslint-disable-line no-console
        setSubmitError(err.body?.message);
      })
      .finally(() => setIsOPTSubmitLoading(false));
  };
  
  // Handles creating service request after passing the OTP verification
  const handleCreateRequest = otpCode => {
    const data = formatSubmitData();

    return ServiceRequestAPI.createRequest(
      snakecaseKeys({...data, otp: otpCode}, {deep: true}));
  };

  // Handles the action when the final Update button is clicked (in view mode)
  const handleUpdate = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setIsUpdateLoading(true);

    const data = formatSubmitData();

    ServiceRequestAPI.updateServiceInquiry(uuid, snakecaseKeys(data, {deep: true}))
      .then(() => setUpdateSnackbarStatus('success'))
      .catch(err => {
        console.error(err);   // eslint-disable-line no-console
        setUpdateSnackbarStatus('error');
      })
      .finally(() => setIsUpdateLoading(false));
  };

  // Service list which can be added via Add Service button
  const getToBeAddedServiceList = () => {
    const servicesAlreadyAdded = new Set(answers._qualifiedServices.matchedServices.map(item => item.name));
    return qualifiedServicesTaxonomy?.reduce((acc, item) => {
      if (!servicesAlreadyAdded.has(item.serviceName))
        acc.push({id: item.serviceId, name: item.serviceName, categoryName: item.serviceCategoryName});
      return acc;
    }, []);
  };

  // Clicka Connect submission
  const handleConnectSubmission = () => {
    setIsOPTSubmitLoading(true);
    setSubmitError(null);
    ServiceRequestAPI.getConnectServiceRequests(providerUuid, {page_size: 1})
      .then(r => camelcaseKeys(r.payload, {deep: true}))
      .then((data) => {
        _handleCreateConnectRequest(data.metaData.count);
      })
      .catch(err => console.error(err));    // eslint-disable-line no-console
  };

  const _handleCreateConnectRequest = serviceRequestCount => {
    handleCreateRequest(null)
      .then(() => {
        history.push('connect', {
          fromServiceRequestSubmission: true,
          fastPolling: {
            createdAt: dayjs().toISOString(),
            serviceRequestCount: serviceRequestCount,
          },
        });
        notification.success({
          message: 'Service Request created successfully!',
          description: 'It might take a few moments to show-up on your dashboard.',
          duration: 10,
          placement: 'bottomLeft',
        });
      })
      .catch(err => {
        console.error(err);   // eslint-disable-line no-console
        setSubmitError(err.body?.message);
      }).finally(() => setIsOPTSubmitLoading(false));
  };

  // Updates reCAPTCHA answer
  const updateRecaptchaAnswer = value => (
    setAnswers(prevState => ({...prevState, [RECAPTCHA_CODE_FIELD]: value})));

  const handleUpdateSnackBarClose = () => setUpdateSnackbarStatus('');

  const handleOTPSuccess = () => {
    const data = {_sr_session: answers._sr_session};
    track.sendEvent('service request flow', 'complete', data);
    setPagename(next.name);
  };

  const renderBottomNavButtons = () => {
    return (isViewMode ? (
      <>
        <FlowButton
          onClick={() => history.push('/siteadmin/service-matching')}
          label="Cancel"
          pointer="none"
          className={classes.bottomNavCancelButton}
        />
        <FlowButton
          onClick={handleUpdate}
          label="Update"
          pointer="none"
          disabled={!complete}
          className={classes.bottomNavButton}
        />
      </>
    ) : (
      !!next && (
        <FlowButton
          onClick={
            isEndOfFlow()
              ? (connectMode ? handleConnectSubmission : handleOtpSubmit)
              : handleClickNext
          }
          label={isEndOfFlow() ? 'Submit' : 'Next'}
          pointer="none"
          disabled={isEndOfFlow() ? !(complete && answers[TERMS_AND_CONDITION_FIELD] && answers[RECAPTCHA_CODE_FIELD]) : !complete}
          className={classes.bottomNavButton}
          busy={isOPTSubmitLoading}
        />
      )
    )
    );
  };

  if (isUpdateLoading || isViewMode && !serviceRequestUuids)
    return <Loader />;

  return (
    <RecaptchaProvider>
      <Container maxWidth="lg" fixed disableGutters={true}>
        <div className={classes.root}>
          <NavPanel
            progressValue={totalQuestions ? (totalQuestionsAnswered / totalQuestions) * 100  : 0}
            pages={navPanelPageData}
            invalidServices={answers._qualifiedServices.unMatchedServices}
            serviceList={getToBeAddedServiceList()}
            onServiceSelect={handleServiceAdd}
            OnPageInfoClick={handlePageIndicatorOnClick}
            onServiceDeleteClick={handleServiceDeleteClick}
            onMatchClick={handleMatchClick}
            isViewMode={isViewMode}
            serviceRequestStatusData={serviceRequestStatusData}
          />
          <div className={classes.container}>
            <div className={classes.containerHeader}>
              <div className={classes.containerTitle}>{currentPage.data.info}</div>
              <SpeechBubble text={currentPage.data?.helpPrompt(answers)} />
            </div>
            <div className={classes.flowContainer}>
              {children}
            </div>
            {isEndOfFlow() && (
              <div className={classes.finalPageBottomContainer}>
                <TermsAndCondition
                  answers={answers}
                  setAnswers={setAnswers}
                  hideCheckboxes={connectMode}
                />
                <Recaptcha
                  ref={reCaptchaRef}
                  sitekey={config.settings.RECAPTCHA_KEY}
                  render="explicit"
                  onloadCallback={() => {
                    updateRecaptchaAnswer(null);
                    setIsRecaptchaLoading(false);
                  }}
                  verifyCallback={(code) => updateRecaptchaAnswer(code)}
                  expiredCallback={() => updateRecaptchaAnswer(null)}
                />
                {isRecaptchaLoading && <CircularProgress size={30}/>}
              </div>
            )}
            <div className={classes.bottomNavContainer}>
              {renderBottomNavButtons()}
            </div>
          </div>
        </div>
        <Snackbar
          open={!!updateSnackbarStatus}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          autoHideDuration={10000}
          onClose={handleUpdateSnackBarClose}
        >
          <MuiAlert elevation={6} variant="filled" onClose={handleUpdateSnackBarClose} severity={updateSnackbarStatus}>
            {updateSnackbarStatus === 'success' ? 'Successfully updated'
              // This needs to have double condition like this else it will show the
              // second text when we close the popup due to css transition delay
              : (updateSnackbarStatus === 'error' ? 'Failed to update' : '')
            }
          </MuiAlert>
        </Snackbar>
        <SnackbarAlert
          open={!!submitError}
          message={submitError}
          handleClose={() => setSubmitError(null)}
          variant="error"
          autoHideDuration={10000}
        />
        <OTPModal
          isOpen={isOTPOpen}
          phoneNumber={answers[PHONE_FIELD]}
          onComplete={handleCreateRequest}
          onSuccess={handleOTPSuccess}
          onCancel={() => {
            reCaptchaRef.current.reset();
            updateRecaptchaAnswer(null);
            setIsOTPOpen(false);
          }}
          onPhoneNumberClick={() => {
            setIsOTPOpen(false);
            setPagename(PAGE_CONTACT_INFO);
          }}
        />
      </Container>
    </RecaptchaProvider>
  );
};

DashboardFlowContainer.propTypes = {
  children: PropTypes.node.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  flow: PropTypes.object.isRequired,
  setPagename: PropTypes.func.isRequired,
  currentPage: PropTypes.object.isRequired,
  next: PropTypes.object,
  complete: PropTypes.bool,
  progress: PropTypes.number,
  progressAction: PropTypes.object.isRequired,
  totalQuestions: PropTypes.number,
  totalQuestionsAnswered: PropTypes.number,
  completedPages: PropTypes.instanceOf(Set).isRequired,
  setCompletedPages: PropTypes.func.isRequired,
  helperFunctions: PropTypes.object,
  isViewMode: PropTypes.bool,
  serviceRequestStatusData: PropTypes.object,
  connectMode: PropTypes.bool,
  providerUuid: PropTypes.string,
  source: PropTypes.string,
};

export default DashboardFlowContainer;