import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {MenuItem} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {

  },
  dropdown: {
    width: '100%',
    '& .MuiInput-underline:before': {
      border: 'none',
    },
  },
  dropdownContainer: {
    padding: '10px',
    background: theme.palette.grey[50],
    border: '1px solid',
    borderColor: theme.palette.common.darkSlateBlue,
    borderRadius: '8px',
    height: '55px',

    '& .MuiInput-underline:before': {
      border: 'none',
    },

    '& .MuiInput-underline:after': {
      border: 'none',
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none'
    },

    '& .MuiSelect-select:focus': {
      background: 'none',
    },

    '& .MuiInputBase-input': {
      paddingTop: '9px',
    },
  }
}));

const Dropdown = (
  {
    options,
    onChange,
    className,
  }) => {

  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.dropdownContainer}>
        <FormControl fullWidth>
          <Select
            value={''}
            renderValue={item => item.name}
            onChange={event => onChange(event.target.value)}
            className={classes.dropdown}
          >
            {options.map(item => <MenuItem key={item.name} value={item}>{item.name}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default Dropdown;