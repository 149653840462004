import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobileAndroid, faClock } from '@fortawesome/pro-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';

import Notification from './Notification';
import Loader from 'components/Loader';
import {ProviderAPI} from 'api';
import SpinnerModal from 'components/SpinnerModal';
import SnackbarAlert from 'components/SnackbarAlert';
import Typography from '@material-ui/core/Typography';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { isMobilePhoneNumber } from 'lib/util';


const useStyles = makeStyles(theme => ({
  header: {
    background: theme.palette.common.white,
    width: '100%',
    boxShadow: 'rgb(145 158 171 / 15%) 0px 0px 4px 0px, rgb(145 158 171 / 15%) 2px -2px 20px 0px',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: theme.palette.common.lightBorder,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: theme.palette.primary.main,
    padding: '15px 70px',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '60px',
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    fontSize: '15px',
    fontWeight: '500',
  },
  headerItemIcon: {
    marginTop: '-2px',
    fontSize: '17px',
  },
  notificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '30px',
  },
  paper: {
    backdropFilter: 'blur(20px)',
    boxShadow: theme.shadows[5],
    borderRadius: '12px',
    maxWidth: '600px',
    marginLeft: '200px',
    marginTop: '-200px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 30px',
    },
  },
  modalContainer: {
    padding: '5px 30px 30px 30px',
  },
  modalButtonContainer: {
    display: 'flex',
    marginTop: '30px',
  },
  modalHeader: {
    fontSize: '20px',
    fontWeight: '500',
    textShadow: '1px 1px 2px #dfdfdf',
    borderBottom: '1px solid #e2e2e2',
    padding: '20px 30px 15px 30px',
    color: theme.palette.warning.main,
  },
  modalQuestion: {
    fontSize: '16px',
    marginTop: '20px',
    marginBottom: '15px',
    textShadow: '1px 1px 2px #dfdfdf',
  },
}));

const NotificationSettings = ({ providerUuid, userUuid, profile, isConnect = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const [notificationConfig, setNotificationConfig] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showSMSNotificationPopup, setShowSMSNotificationPopup] = useState(false);
  const [isCheckBusy, setIsCheckBusy] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    ProviderAPI.getNotificationConfig(providerUuid, userUuid)
      .then(r => camelcaseKeys(r.payload, {deep: true}))
      .then(r => setNotificationConfig(r))
      .finally(() => setIsLoading(false))
      .catch(err => console.error(err));   //eslint-disable-line no-console
  }, [providerUuid]);

  const handleCheck = (notificationType, notification, value) => {

    if (value && notificationType === 'sms' && !isMobilePhoneNumber(profile.contact_phone)) {
      setShowSMSNotificationPopup(true);
      return null;
    }

    const data = {
      notificationType: notificationType,
      notification: notification,
      active: value,
    };
    setIsCheckBusy(true);
    setError(false);
    ProviderAPI.updateNotificationConfig(providerUuid, userUuid, snakecaseKeys(data))
      .then(r => camelcaseKeys(r.payload, {deep: true}))
      .then(r => setNotificationConfig(r))
      .catch(err => {
        console.error(err);    //eslint-disable-line no-console
        setError(true);
      })
      .finally(() => {
        setShowConfirmationPopup(true);
        setIsCheckBusy(false);
      });
  };

  const renderNotifications = () => {
    if (isConnect) {
      return (
        <>
          {notificationConfig.connectServiceRequest && (
            <Notification
              title="Connect Service Request Notification"
              body="Specify how you want to receive notifications about
              the service requests you submit."
              email={notificationConfig.connectServiceRequest?.email?.active}
              sms={false}
              updatedAt={notificationConfig.connectServiceRequest?.email?.updatedAt}
              onEmailCheck={(value) => handleCheck('email', 'connect_service_request', value)}
              smsDisabled
            />
          )}
        </>
      );
    }
    return (
      <>
        {notificationConfig.serviceRequest && (
          <Notification
            title="Service Request Notification"
            body="You can tell us which services you want to receive request for by
            updating your service settings."
            email={notificationConfig.serviceRequest?.email?.active}
            sms={notificationConfig.serviceRequest?.sms?.active}
            updatedAt={
              getLatestDate(
                notificationConfig.serviceRequest?.email?.updatedAt,
                notificationConfig.serviceRequest?.sms?.updatedAt
              )}
            onEmailCheck={(value) => handleCheck('email', 'service_request', value)}
            onSMSCheck={(value) => handleCheck('sms', 'service_request', value)}
          />
        )}
        {notificationConfig.serviceRequestWeeklyDigest && (
          <Notification
            title="Weekly Service Request Digest"
            body="A weekly summary of all the service requests that have matched with your offerings."
            email={notificationConfig.serviceRequestWeeklyDigest?.email?.active}
            sms={notificationConfig.serviceRequestWeeklyDigest?.sms?.active}
            updatedAt={notificationConfig.serviceRequestWeeklyDigest?.email?.updatedAt}
            onEmailCheck={(value) => handleCheck('email', 'service_request_weekly_digest', value)}
            onSMSCheck={(value) => handleCheck('sms', 'service_request_weekly_digest', value)}
            smsDisabled={true}
          />
        )}
      </>
    );
  };

  if (isLoading || !providerUuid || !userUuid)
    return <Loader />;

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerContainer}>
          <div className={classes.headerItem}>
            <FontAwesomeIcon icon={faEnvelope} className={classes.headerItemIcon}/>
            <div>Email</div>
          </div>
          <div className={classes.headerItem}>
            <FontAwesomeIcon icon={faMobileAndroid} className={classes.headerItemIcon}/>
            <div>SMS</div>
          </div>
          <div className={classes.headerItem}>
            <FontAwesomeIcon icon={faClock} className={classes.headerItemIcon}/>
            <div>Updated</div>
          </div>
        </div>
      </div>
      <div className={classes.notificationsContainer}>
        {renderNotifications()}
      </div>
      <SpinnerModal isLoading={isCheckBusy} />
      <SnackbarAlert
        open={showConfirmationPopup}
        handleClose={() => setShowConfirmationPopup(false)}
        message={error ?
          'Something went wrong, please try again!' :
          'Notification successfully updated'
        }
        variant={error ? 'error' : 'success'}
      />
      <Modal
        isOpen={showSMSNotificationPopup}
        paperClassName={classes.paper}
      >
        <div className={classes.modalHeader}>Attention!</div>
        <div className={classes.modalContainer}>
          <div className={classes.modalQuestion}>Mobile number required</div>
          <Typography variant="body1" className={classes.modalNotification}>
            The number you have saved in your User Profile is not a mobile number, and
            therefore SMS notifications won’t be received. Please update your phone
            number before switching on this feature.
          </Typography>
          <div className={classes.modalButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              label="Update Number"
              onClick={() => {
                setShowSMSNotificationPopup(false);
                history.push('/providers/profile');

              }}
              pointer="none"
            />
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setShowSMSNotificationPopup(false)}
              pointer="none"
              label='Update Later'
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const getLatestDate = (dateA, dateB) => {
  const _dateA = new Date(dateA);
  const _dateB = new Date(dateB);

  return _dateA > _dateB ? dateA: dateB;
};

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
  };
};

NotificationSettings.propTypes = {
  providerUuid: PropTypes.string,
  userUuid: PropTypes.string,
  profile: PropTypes.object,
  isConnect: PropTypes.bool
};

export default connect(mapStateToProps)(NotificationSettings);