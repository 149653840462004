import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { ProviderAPI } from 'api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyItems: 'center',
    color: theme.palette.common.darkSlateBlue,
  },
  button: {
    display: 'flex',
    '&:hover': {
      color: ({isLoading}) => !isLoading && theme.palette.common.nightBlue,
      cursor: ({isLoading}) => isLoading ? 'not-allowed' : 'pointer',
    }
  },
  text: {
    fontSize: '14px',
    fontWeight: 500,
    marginLeft: '2px',
  },
  icon: {
    fontSize: '20px',
    marginTop: '-1px',
  },
  spinner: {
    marginLeft: '8px',
    color: theme.palette.common.darkSlateBlue,
  },
}));

const UseMyLocation = ({onSuccess, onLoading=()=>{}, className='', classNames={}}) => {
  const [loading, setLoading] = useState(false);

  const classes = useStyles({isLoading: loading});

  const handleOnClick = () => {
    if (loading) return;
    onLoading(true);
    setLoading(true);
    navigator.geolocation.getCurrentPosition(position => {
      ProviderAPI.locationFromLatLng(position.coords.latitude, position.coords.longitude).then(r => {
        if (r.status_code !== 200) {
          console.error(r); // eslint-disable-line no-console
          return;
        }
        onSuccess(r.payload);
      }).catch(error => {
        console.error(error); // eslint-disable-line no-console
      }).finally(() => {
        onLoading(false);
        setLoading(false);
      });
    });
  };

  return (
    <div className={`${classes.root} ${className}`}>
      <div className={`${classes.button} ${classNames.button}`} onClick={handleOnClick}>
        <LocationOnIcon className={`${classes.icon} ${classNames.icon}`} />
        <div className={classes.text}>Use my location</div>
      </div>
      {loading && <CircularProgress className={`${classes.spinner} ${classNames.spinner}`} size={17} thickness={5}/>}
    </div>
  );
};

UseMyLocation.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onLoading: PropTypes.func,
  className: PropTypes.string,
  classNames: PropTypes.object,
};

export default UseMyLocation;