import React from 'react';
import PropTypes from 'prop-types';

import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Card from './Card';
import Data from './Data';


const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gridRowGap: '20px',
  },
  divider: {
    borderBottom: '1px solid #f5f5f5',
    gridColumn: '1 / -1',
  },
  skeleton: {
    gridColumn: '1 / -1',
  },
});

const Funding = ({data, isLoading}) => {

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const serviceInquiry = data.serviceInquiry ? data.serviceInquiry : {};
  const serviceInquiryData = serviceInquiry.data ? serviceInquiry.data : {};

  const renderBody = () => {
    return (
      <>
        <Data
          title="Management"
          details={serviceInquiryData.ndisPlan}
          gridColumn={isMobile ? 'span 5' : 'span 3'}
          maxGridColumnSpan={10}
        />
        <Data
          title="Plan start date"
          details={serviceInquiryData.planStartDate}
          gridColumn={isMobile ? 'span 5' : 'span 3'}
          maxGridColumnSpan={10}
        />
        <Data
          title="NDIS plan period"
          details={serviceInquiryData.ndisPlanPeriod}
          gridColumn={isMobile ? 'span 5' : 'span 3'}
          maxGridColumnSpan={10}
          isCeiling={!!isMobile}
        />
      </>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Skeleton animation="wave" height={70} className={classes.skeleton} />
      </>
    );
  };

  return (
    <Card title="Funding" className={classes.root}>
      {isLoading ? renderLoading() : renderBody()}
    </Card>
  );
};

Funding.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};


export default Funding;
