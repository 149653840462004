import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import style from 'style';


const Container = styled.div`
  display: flex;
  column-gap: 10px;
  filter: drop-shadow(-1px 1px 2px #c1c1c1);
`;

const ProgressBar = ({ steps }) => {

  return (
    <Container>
      {steps?.map((item, idx) => (<Step key={idx} data={item} />))}
    </Container>
  );
};

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
};

const StepContainer = styled.div`
  background: ${({$isComplete, $isCurrent, $bgColor}) => $isComplete || $isCurrent ? $bgColor : '#efefef'};
  flex-grow: 1;
  height: 50px;
  clip-path: polygon(75% 0%, 85% 50%, 75% 100%, 0% 100%, 10% 50%, 0% 0%);
  margin-right: -7.5%;
  cursor: ${({$isComplete, $isCurrent}) => $isComplete && !$isCurrent ? 'pointer' : 'not-allowed'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8.5%;
  padding-left: 3.5%;
  width: 25%;
  overflow: hidden;
  color: ${({$isComplete, $isCurrent}) => $isComplete || $isCurrent ? '#167b6d' : '#7e7e7e'};
  font-weight: 500;
  
  @media (max-width: ${style.sizes.small}) {
      margin-right: -9.5%;
    }
  
  ${({$isComplete, $isCurrent, $bgColor}) => ($isComplete || $isCurrent) && css`
    background: linear-gradient(to right, ${$bgColor} 50%, #efefef 0);
    background-size: 200% 100%;
    background-position: right;
    animation: makeItfadeIn 0.5s 0s forwards;
    @keyframes makeItfadeIn {
      100% {
        background-position: left;
      }
    }
  `}
  
  ${({$isComplete, $isCurrent}) => ($isComplete && !$isCurrent) && css`
    :hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  `}

  :first-child {
    background: #5ABCAF;
    clip-path: polygon(70% 0%, 95% 50%, 70% 100%, 0% 100%, 0% 50%, 0% 0%);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    flex-grow: 0;
    margin-right: -3.6%;
    width: 5%;
    cursor: default;
    
    @media (max-width: ${style.sizes.small}) {
      margin-right: -5.5%;
    }
    
    .content {
      padding-left: 0;
    }
  }

  :last-child {
    background: #B7E9E2;
    clip-path: polygon(33% 0%, 100% 0%, 100% 100%, 33% 100%, 45% 50%);
    margin-left: -8.5%;
    margin-right: 0px;
    border-radius: 16px;
    flex-grow: 1;
    padding-right: 0px;
    padding-left: 10.5%;
    width: 20%;
    cursor: default;
    
    .content {
      padding-left: 0;
    }
  }
  
  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    text-align: center;
    
    @media (max-width: ${style.sizes.small}) {
      font-size: 10px;
      padding-left: 8%;
    }
  }
`;

const Step = ({ data }) => {

  return (
    <StepContainer
      $isComplete={data.isComplete}
      $isCurrent={data.isCurrent}
      $bgColor={data.bgColor}
      onClick={() => data.isComplete && data.onClick()}
    >
      <div className="content">
        {data.node}
      </div>
    </StepContainer>
  );
};

Step.propTypes = {
  data: PropTypes.object,
};


export default ProgressBar;

