import TextInput from './TextInput';
import CheckboxInput from './CheckboxInput';
import RadioInput from './RadioInput';
import DateInput from './DateInput';
import SuburbInput from './SuburbInput';
import TagInput from './TagInput';
import CurrencyInput from './CurrencyInput';
import PhoneNumberInput from './PhoneNumberInput';
import MultilineTextInput from './MultilineTextInput';
import NumberInput from './NumberInput';
import MultiselectInput from './MultiselectInput';

import * as EmailValidator from 'email-validator';
import { validateParticipantNumber } from 'lib/ndis';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// if arg is a function, return unchanged, otherwise return a function that returns arg
const makePredicate = value => typeof value === 'function' ? value : () => value;

const isEmptyString = str => str === undefined || str === null || str.trim() === '';
const isNotEmptyString = str => str !== undefined && str !== null && str.trim() !== '';
const isNotUndefined = value => value !== undefined;
const phoneNumberErrorText = (str) => {
  if (str === undefined || str === null || str.trim() === '') {
    return 'Please provide a value phone number.';
  }
  const phoneNumber = parsePhoneNumberFromString(str, 'AU');
  if (!phoneNumber || !phoneNumber.isValid()) {
    return 'That doesn\'t look like a valid phone number.';
  }
};

const textInput = ({field, label, isValid=isNotEmptyString, errorText='Please provide a value', ...props}) => {
  return {
    Component: TextInput,
    field,
    label,
    isValid: makePredicate(isValid),
    errorText,
    ...props,
  };
};

const multilineTextInput = ({field, label, isValid=isNotEmptyString, errorText='Please provide a value.', ...props}) => {
  return {
    Component: MultilineTextInput,
    field,
    label,
    isValid: makePredicate(isValid),
    errorText,
    ...props,
  };
};

const checkboxInput = ({field, label, isValid=true, errorText, ...props}) => {
  return {
    Component: CheckboxInput,
    field,
    label,
    isValid: makePredicate(isValid),
    errorText,
    ...props,
  };
};

const multiselectInput = ({field, label, errorText='Please select at least one option', isValid=true, ...props}) => {
  return {
    Component: MultiselectInput,
    field,
    label,
    isValid: makePredicate(isValid),
    errorText,
    ...props,
  };
};

const numberInput = ({field, label, isValid=isNotEmptyString, errorText='Please enter a number.', ...props}) => {
  return {
    Component: NumberInput,
    field,
    label,
    isValid: makePredicate(isValid),
    errorText,
    ...props,
  };
};

const radioInput = ({field, label, errorText='Please select one option', isValid=isNotUndefined, ...props}) => {
  return {
    Component: RadioInput,
    field,
    label,
    isValid: makePredicate(isValid),
    errorText,
    ...props,
  };
};

const yesNoInput = ({field, label, ...props}) => {
  return {
    Component: RadioInput,
    options: {no: 'No', yes: 'Yes'},
    field,
    label,
    isValid: (value) => value !== undefined,
    errorText: (value) => value === undefined ? 'Please select Yes or No.' : undefined,
    ...props,
  };
};

const yesNoUnsureInput = ({field, label, ...props}) => {
  return {
    Component: RadioInput,
    options: {no: 'No', yes: 'Yes', unsure: 'Unsure'},
    field,
    label,
    isValid: (value) => value !== undefined,
    errorText: (value) => value === undefined ? 'Please select one answer.' : undefined,
    ...props,
  };
};

const dateInput = ({field, label, ...props}) => {
  return {
    Component: DateInput,
    field,
    label,
    isValid: value => value && value.isValid && value.isValid(),
    errorText: value => (value && value.isValid && value.isValid()) ? 'Please enter a valid date.' : undefined,
    ...props,
  };
};

const suburbInput = ({field, label, ...props}) => {
  return {
    Component: SuburbInput,
    field,
    label,
    isValid: value => !isEmptyString(value),
    errorText: value => isEmptyString(value) ? 'Please enter a suburb or postcode' : undefined,
    ...props,
  };
};

const tagInput = ({field, label, isValid=true, errorText, ...props}) => {
  return {
    Component: TagInput,
    field,
    label,
    isValid: makePredicate(isValid),
    errorText,
    ...props,
  };
};

// specializations

const emailInput = ({field, label, autoComplete='email', ...props}) => {
  return {
    Component: TextInput,
    field,
    label,
    autoComplete,
    isValid: (value) => !isEmptyString(value) && EmailValidator.validate(value),
    errorText: (value) => isEmptyString(value) ? 'Please provide an email address' : (!EmailValidator.validate(value) ? 'That doesn\'t look like a valid email address' : undefined),
    ...props,
  };
};

const phoneInput = ({field, label, autoComplete='tel-national', ...props}) => {
  return {
    Component: TextInput,
    field,
    label,
    autoComplete,
    isValid: (value) => phoneNumberErrorText(value) === undefined,
    errorText: (value) => phoneNumberErrorText(value),
    ...props,
  };
};

const phoneInput2 = ({field, label, ...props}) => {
  return {
    Component: PhoneNumberInput,
    field,
    label,
    isValid: (value) => phoneNumberErrorText(value) === undefined,
    errorText: (value) => phoneNumberErrorText(value),
    ...props,
  };
};

const ndisParticipantNumberInput = ({field, label, ...props}) => {
  return {
    Component: TextInput,
    field,
    label,
    isValid: (value) => validateParticipantNumber(value) === undefined,
    errorText: (value) => validateParticipantNumber(value),
    ...props,
  };
};

const currencyInput = ({field, label, ...props}) => {
  return {
    Component: CurrencyInput,
    field,
    label,
    isValid: (value) => !isEmptyString(value),
    errorText: 'Please enter an amount.',
    ...props,
  };
};

export {
  textInput,
  multilineTextInput,
  checkboxInput,
  radioInput,
  multiselectInput,
  yesNoInput,
  yesNoUnsureInput,
  dateInput,
  suburbInput,
  tagInput,
  emailInput,
  phoneInput,
  phoneInput2,
  ndisParticipantNumberInput,
  currencyInput,
  numberInput,
};
