import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';

import {formatListToString} from '../../utils';
import FlowButton from '../../components/FlowButton';


const useStyles = makeStyles(theme => ({
  subTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: '400',
  },
  bottomText: {
    marginTop: '10px',
    textAlign: 'center',
    fontSize: '16px',
    marginBottom: '50px',
    color: theme.palette.common.battleshipGrey,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const WhatHappensNext = ({ answers, setPagename, nextPage }) => {

  const classes = useStyles();

  return (
    <>
      <div className={classes.subTitle}>
        We’re now going to ask a few more specific questions, so that
        <br/>we can connect you with the right {formatListToString([
          ...answers._qualifiedServices.matchedServices.map(item => item.name)
        ])} options for you.
      </div>
      <div className={classes.bottomText}>
        Average time <strong>5 minutes</strong> to complete.
      </div>
      <div className={classes.buttonContainer}>
        <FlowButton
          label="I'm ready"
          pointer={null}
          onClick={() => setPagename(nextPage.name)}
        />
      </div>
    </>
  );
};

WhatHappensNext.propTypes = {
  answers: PropTypes.object,
  className: PropTypes.string,
  nextPage: PropTypes.object,
  setPagename: PropTypes.func.isRequired,
};

export default WhatHappensNext;