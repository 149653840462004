import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import camelcaseKeys from 'camelcase-keys';
import { useSelector } from 'react-redux';

import { getServicesWithMapping } from '../../utils';
import animationAsset from 'assets/woman-supporting-disabled-friend.json';
import {
  ONLINE_SERVICE_FIELD,
  TELEHEALTH_FIELD,
} from '../../../constants';
import SearchBox from '../components/SearchBox';
import ServiceSelection from '../components/ServiceSelection';


const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  
  opacity: ${({$isDomLoaded}) => $isDomLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  
  .lottie-container {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));
  }
`;

const Service = (
  {
    value,
    onChange,
    setAnswers,
    source,
    excludedServices,
    scrollToFlowControlButton,
  }
) => {

  const planManagers = ['myp', 'ndsp', 'mpm'];
  const serviceSelectionServices = planManagers.includes(source) ?
    [
      'Occupational Therapy',
      'Psychology',
      'Support Work',
      'Speech Therapy',
      'Support Coordination',
      'Cleaning',
      'Gardening',
    ] : [
      'Occupational Therapy',
      'Psychology',
      'Support Work',
      'Speech Therapy',
      'Plan Management',
      'Support Coordination',
      'Cleaning',
      'Gardening',
    ];
  const servicesTaxonomy = useSelector(state => state.taxonomy.services);
  const qualifiedServicesTaxonomy = useSelector(state => state.taxonomy.qualifiedServices);

  const [selectedValue, setSelectedValue] = useState(value ? value : []);
  const [serviceMap, setServiceMap] = useState({});
  const [services, setServices] = useState([]);

  useEffect(() => {
    const { services: _services, serviceMap: _serviceMap } = getServicesWithMapping(
      camelcaseKeys(qualifiedServicesTaxonomy, {deep: true}),
      camelcaseKeys(servicesTaxonomy, {deep: true})
    );
    if (excludedServices && Array.isArray(excludedServices)) {
      const excludedServicesSet = new Set(excludedServices);
      const __services = _services.filter(item => {
        return !excludedServicesSet.has(item.name);
      });
      setServices(__services);
    } else {
      setServices(_services);
    }
    setServiceMap(_serviceMap);
  }, [servicesTaxonomy, qualifiedServicesTaxonomy]);

  useEffect(() => {
    onChange(selectedValue);
  }, [selectedValue]);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = value => {
    setAnswers(prevState => ({...prevState, [TELEHEALTH_FIELD]: null, [ONLINE_SERVICE_FIELD]: null}));
    setSelectedValue(value ? [value] : []);
    scrollToFlowControlButton();
  };

  const serviceSelections = serviceSelectionServices.map(key => serviceMap[key]);

  const [isDomLoaded, setIsDomLoaded] = useState(false);

  return (
    <Container $isDomLoaded={isDomLoaded}>
      <div className="lottie-container">
        <Lottie
          loop
          onDOMLoaded={() => setIsDomLoaded(true)}
          animationData={animationAsset}
          style={{width: '350px', marginTop: '-30px', marginBottom: '-12px'}}
        />
      </div>
      <SearchBox
        title="Select a service"
        options={services}
        placeholder="Support Coordination, Gardening ..."
        selectedValue={selectedValue.length ? selectedValue[0] : null}
        onSearchChange={handleChange}
        onSelect={scrollToFlowControlButton}
      />
      <ServiceSelection
        title="Commonly requested"
        data={serviceSelections}
        onSelect={handleChange}
      />
    </Container>
  );
};

Service.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  setAnswers: PropTypes.func,
  source: PropTypes.string,
  excludedServices: PropTypes.array,
  scrollToFlowControlButton: PropTypes.func.isRequired,
};

export default Service;