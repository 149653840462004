import './App.less';

import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import SiteDown from './SiteDown';
import StartupRefresh from './components/StartupRefresh';
import TokenTimeout from './components/TokenTimeout';
import { persistor } from './store';
import restrictAccess from './lib/restrictAccess';
import routes, { subdomainRoutes } from './routes';
import store from './store';
import { queryClient } from 'providerSite/api/reactQuery';
import { PusherContext, useInitPusher } from 'lib/pusher';


export default function App() {

  const subdomain = window.location.hostname.split('.')[0];
  const isValidSubdomain = Object.keys(subdomainRoutes).includes(subdomain);

  const pusher = useInitPusher();

  React.useEffect(()=>{
    restrictAccess();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <SiteDown down={false}>
          <PusherContext.Provider value={pusher}>
            <PersistGate loading={<div>Wait</div>} persistor={persistor}>
              <Router>
                <StartupRefresh/>
                <TokenTimeout>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                      {isValidSubdomain ? subdomainRoutes[subdomain] : routes}
                    </Switch>
                  </React.Suspense>
                </TokenTimeout>
              </Router>
              <Toaster position="bottom-left" toastOptions={{duration: 5000}} />
            </PersistGate>
          </PusherContext.Provider>
        </SiteDown>
      </Provider>
    </QueryClientProvider>
  );
}
