import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import { ProviderAPI } from 'api';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiAutocomplete-root': {
      width: '100%',
    },
  },
  input: {
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: theme.palette.common.paleGrey,
      paddingTop: 0,
      borderRadius: '8px',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 0,
    },
  },
  search: {
    backgroundColor: theme.palette.common.paleGrey,
    borderRadius: '8px',
  },
  spinner: {
    padding: 5,
  },
}));


const ProviderSearch = ({onClick, placeholder='Search for providers by name'}) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ProviderAPI.getProviderAutocomplete(inputValue).then(r  => {
      setOptions(r.payload);
      setLoading(false);
    });

  }, [inputValue]);

  const renderInput = (params) => {
    return (
      <Box display="flex" alignItems="center" className={classes.search}>
        <Box flexGrow={1}>
          <TextField
            className={classes.input}
            {...params}
            placeholder={placeholder}
            variant="filled"
          />
        </Box>
        <Box className={classes.spinner}>
          {loading && <CircularProgress size={20} thickness={5}/>}
        </Box>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        options={options}
        getOptionLabel={option => `${option.name} (${option.slug})`}
        autoComplete
        autoHighlight
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, value) => {
          const uuid = value?.uuid;
          uuid && onClick(uuid);
        }}
        popupIcon={null}
        renderInput={renderInput}
      />
    </div>
  );
};

ProviderSearch.propTypes = {
  onClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default ProviderSearch;
