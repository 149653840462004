import ItemList from './ItemList.js';
import { ItemListContainer } from './ItemList';
import PropTypes from 'prop-types';
import React from 'react';
import SectionDivider from '../../../components/SectionDivider.js';
import SocialLinks from './SocialLinks.js';
import { SpecialisationChipList } from 'components/specialisation/SpecialisationChip';
import TextContent from './TextContent.js';
import TreeListDisplay from '../../../components/tree/TreeListDisplay.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
});

const ListingInfoPanel = ({ data }) => {
  const classes = useStyles();
  let showSocialLinks = false;
  if ('provider_contact' in data) {
    if (data.provider_contact.facebook_url !== null || data.provider_contact.twitter_url !== null || data.provider_contact.instagram_url !== null) {
      showSocialLinks = true;
    }
  }

  return (
    <div className={classes.root}>
      {data.service_locations && data.service_locations.length ? (
        <React.Fragment>
          <SectionDivider />
          <TreeListDisplay title="Service locations" data={data.service_locations} />
        </React.Fragment>
      ) : null}
      {data.specialisations && data.specialisations.length ? (
        <React.Fragment>
          <SectionDivider />
          <ItemListContainer title="Specialisations">
            <SpecialisationChipList specialisations={data.specialisations} />
          </ItemListContainer>
        </React.Fragment>
      ) : null}
      {data.access_methods && data.access_methods.length ? (
        <React.Fragment>
          <SectionDivider />
          <ItemList title="Access methods" data={data.access_methods} />
        </React.Fragment>
      ) : null}
      {data.minimum_session_time === null ? null : (
        <React.Fragment>
          <SectionDivider />
          <TextContent title="Minimum Amount" data={data.minimum_session_time} capitalize />
        </React.Fragment>
      )}
      {data.minimum_weekly_time === null ? null : (
        <React.Fragment>
          <SectionDivider />
          <TextContent title="Minimum Weekly Amount" data={data.minimum_weekly_time} capitalize />
        </React.Fragment>
      )}
      {data.age_groups && data.age_groups.length ? (
        <React.Fragment>
          <SectionDivider />
          <ItemList title="Age groups" data={data.age_groups} />
        </React.Fragment>
      ) : null}
      {showSocialLinks ? (
        <React.Fragment>
          <SectionDivider />
          <SocialLinks data={data.provider_contact} />
        </React.Fragment>
      ) : null}
      <SectionDivider />
      <ItemList title="Languages" data={data.languages} />
    </div>
  );
};

ListingInfoPanel.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ListingInfoPanel;
