import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import * as EmailValidator from 'email-validator';
import { isValidPhoneNumber } from 'libphonenumber-js';

import SpinnerModal from 'components/SpinnerModal';
import { useGetApi, TaxonomyAPI } from 'providerSite/api';
import { Textbox, Dropdown, Radio, LocationTextBox, DatePicker } from 'providerSite/components/inputs';
import {
  PLAN_TYPE_PLAN_MANAGED,
  PLAN_TYPE_AGENCY_MANAGED,
  PLAN_TYPE_NOT_WITH_NDIS,
  PLAN_TYPE_WAITING_FOR_APPROVAL,
  PLAN_TYPE_SELF_MANAGED,
  PLAN_TYPE_UNSURE,
  TELEHEALTH_YES,
  TELEHEALTH_NO,
  TELEHEALTH_UNSURE,
  PLAN_PERIOD_ONE_YEAR,
  PLAN_PERIOD_TWO_YEARS,
  PLAN_PERIOD_THREE_YEARS,
  ONLINE_SERVICE_YES,
  ONLINE_SERVICE_NO,
  ONLINE_SERVICE_UNSURE,
} from 'modules/brokerageFlow/constants';
import {
  FIELD_AGE_GROUP,
  FIELD_COMMUNICATION_AIDS,
  FIELD_CONTACT_PARTICIPANT,
  FIELD_EMAIL,
  FIELD_FIRST_NAME,
  FIELD_GENDER,
  FIELD_INTERPRETER_REQUIRED,
  FIELD_LANGUAGES,
  FIELD_LAST_NAME,
  FIELD_LOCATION,
  FIELD_PHONE,
  FIELD_PLAN_TYPE,
  FIELD_TELEHEALTH,
  FIELD_PLAN_START_DATE,
  FIELD_PLAN_DURATION,
  FIELD_ONLINE_SERVICE,
  FIELD_EXTRA_DATA,
} from './constants';



const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 20px;
  width: 100%;
  max-width: 700px;
`;

const nameValidator = value => !!value?.match(/^[a-zA-Z'\- ]+$/g);


const ParticipantDetailsForm = ({ data, handleChange }) => {

  const {
    data: ageGroupTaxonomy,
    isLoading: isAgeGroupTaxonomyLoading
  } = useGetApi(TaxonomyAPI.GET_TAXONOMY, {
    queryParams: {
      name: 'age_group',
    },
    staleTimeMinute: 60,   // 1 hour
  });

  const {
    data: languageTaxonomy,
    isLoading: isLanguageTaxonomyLoading
  } = useGetApi(TaxonomyAPI.GET_TAXONOMY, {
    queryParams: {
      name: 'language',
    },
    staleTimeMinute: 60,   // 1 hour
  });

  const availableAgeRanges = ageGroupTaxonomy?.map(item => item.name);
  const availableLanguages = languageTaxonomy?.map(item => item.name);
  const availableGenders = [
    'Female (she/her)',
    'Male (he/him)',
    'Non-binary (they/them)',
    'Other',
    'Prefer not to say',
  ];
  const availablePlanTypes = [
    PLAN_TYPE_SELF_MANAGED,
    PLAN_TYPE_PLAN_MANAGED,
    PLAN_TYPE_AGENCY_MANAGED,
    PLAN_TYPE_WAITING_FOR_APPROVAL,
    PLAN_TYPE_NOT_WITH_NDIS,
    PLAN_TYPE_UNSURE,
  ];

  const [planType, setPlanType] = useState();

  useEffect(() => {
    if (!data?.[FIELD_PLAN_TYPE]) return;
    setPlanType(data?.[FIELD_PLAN_TYPE]);
  }, [data?.[FIELD_PLAN_TYPE]]);

  return (
    <FormContainer>
      <Row>
        <Textbox
          title="First Name"
          defaultValue={data?.[FIELD_FIRST_NAME]}
          onChange={val => handleChange(FIELD_FIRST_NAME, val)}
          validator={nameValidator}
          errorMsg="Please only use valid characters"
        />
        <Textbox
          title="Last Name"
          defaultValue={data?.[FIELD_LAST_NAME]}
          onChange={val => handleChange(FIELD_LAST_NAME, val)}
          validator={nameValidator}
          errorMsg="Please only use valid characters"
        />
      </Row>
      <Row>
        <LocationTextBox
          title="Location"
          defaultValue={data?.[FIELD_LOCATION]}
          onChange={val => handleChange(FIELD_LOCATION, val)}
        />
      </Row>
      <Row>
        <Textbox
          title="Email"
          defaultValue={data?.[FIELD_EMAIL]}
          onChange={val => handleChange(FIELD_EMAIL, val)}
          validator={EmailValidator.validate}
          errorMsg="Please enter a valid email address"
        />
        <Textbox
          title="Phone"
          defaultValue={data?.[FIELD_PHONE]}
          onChange={val => handleChange(FIELD_PHONE, val)}
          validator={value => !!value && isValidPhoneNumber(value, 'AU')}
          errorMsg="Please enter a valid phone number"
        />
      </Row>
      <Row>
        <Dropdown
          title="Age range"
          options={availableAgeRanges}
          defaultValue={data?.[FIELD_AGE_GROUP]}
          onChange={val => handleChange(FIELD_AGE_GROUP, val)}
        />
        <Dropdown
          title="Identifies As"
          options={availableGenders}
          defaultValue={data?.[FIELD_GENDER]}
          onChange={val => handleChange(FIELD_GENDER, val)}
        />
      </Row>
      <Row>
        <Dropdown
          title="Language(s) spoken"
          options={availableLanguages}
          multiple
          defaultValue={data?.[FIELD_LANGUAGES]}
          onChange={val => handleChange(FIELD_LANGUAGES, val)}
        />
      </Row>
      <Row>
        <Radio
          title="Is an interpretator required"
          options={['Yes', 'No']}
          defaultValue={data?.[FIELD_INTERPRETER_REQUIRED]}
          onChange={val => handleChange(FIELD_INTERPRETER_REQUIRED, val)}
        />
      </Row>
      <Row>
        <Textbox
          title="Communication aids required if any? (optional)"
          defaultValue={data?.[FIELD_COMMUNICATION_AIDS]}
          onChange={val => handleChange(FIELD_COMMUNICATION_AIDS, val)}
        />
      </Row>
      <Row>
        <Radio
          title="Is Telehealth an option?"
          options={[TELEHEALTH_YES, TELEHEALTH_NO, TELEHEALTH_UNSURE]}
          defaultValue={data?.[FIELD_TELEHEALTH]}
          onChange={val => handleChange(FIELD_TELEHEALTH, val)}
        />
      </Row>
      <Row>
        <Radio
          title="Is Online Service an option?"
          options={[ONLINE_SERVICE_YES, ONLINE_SERVICE_NO, ONLINE_SERVICE_UNSURE]}
          defaultValue={data?.data?.[FIELD_ONLINE_SERVICE]}
          onChange={val => handleChange(FIELD_EXTRA_DATA, {
            [FIELD_ONLINE_SERVICE]: val
          })}
        />
      </Row>
      <Row>
        <Dropdown
          title="Plan Type"
          options={availablePlanTypes}
          defaultValue={data?.[FIELD_PLAN_TYPE]}
          onChange={val => {
            handleChange(FIELD_PLAN_TYPE, val);
            setPlanType(val);
          }}
        />
      </Row>
      {(planType === PLAN_TYPE_PLAN_MANAGED || planType === PLAN_TYPE_AGENCY_MANAGED) && (
        <Row>
          <DatePicker
            title="Plan start date"
            defaultValue={data?.[FIELD_PLAN_START_DATE]}
            onChange={val => handleChange(FIELD_PLAN_START_DATE, val)}
          />
          <Dropdown
            title="Plan duration"
            options={[PLAN_PERIOD_ONE_YEAR, PLAN_PERIOD_TWO_YEARS, PLAN_PERIOD_THREE_YEARS]}
            defaultValue={data?.[FIELD_PLAN_DURATION]}
            onChange={val => handleChange(FIELD_PLAN_DURATION, val)}
          />
        </Row>
      )}
      <Row>
        <Radio
          title="Direct all provider communication for service requests to"
          options={['Me', 'Participant']}
          defaultValue={data?.[FIELD_CONTACT_PARTICIPANT]}
          onChange={val => handleChange(FIELD_CONTACT_PARTICIPANT, val)}
        />
      </Row>
      <SpinnerModal isLoading={isAgeGroupTaxonomyLoading || isLanguageTaxonomyLoading} />
    </FormContainer>
  );
};


ParticipantDetailsForm.propTypes = {
  data: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
};


export default ParticipantDetailsForm;
