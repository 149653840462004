import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import BrokerageFlow from './pages/BrokerageFlow';
import BrokerageFlowAaa from './pages/BrokerageFlowAaa';
import BrokerageFlowClx from './pages/BrokerageFlowClx';
import BrokerageFlowMpm from './pages/BrokerageFlowMpm';
import BrokerageFlowMyp from './pages/BrokerageFlowMyp';
import BrokerageFlowNdsp from './pages/BrokerageFlowNdsp';
import BrokerageFlowViewMode from './pages/BrokerageFlowViewMode';
import Dashboard from './Dashboard';


const routes = () => {
  return [
    <Route key="/requestmatch" exact path="/requestmatch" component={BrokerageFlow}/>,
    <Route key="/requestmatch/view" exact path="/requestmatch/view/:uuid" component={BrokerageFlowViewMode}/>,
    <Route key="/service-inquiry" exact path="/service-inquiry/:uuid" component={Dashboard}/>,
    /*
      The following routes are to assist testing locally, where the domain name is not easily changed.
      You can test the customised service request flow at e.g. http://lvh.me:3000/requestmatch/aaa
    */
    <Route key="/requestmatch/aaa" exact path="/requestmatch/aaa" component={BrokerageFlowAaa}/>,
    <Route key="/requestmatch/clx" exact path="/requestmatch/clx" component={BrokerageFlowClx}/>,
    <Route key="/requestmatch/mpm" exact path="/requestmatch/mpm" component={BrokerageFlowMpm}/>,
    <Route key="/requestmatch/mpy" exact path="/requestmatch/mpy" component={BrokerageFlowMyp}/>,
    <Route key="/requestmatch/ndsp" exact path="/requestmatch/ndsp" component={BrokerageFlowNdsp}/>,
  ];
};

export const mypRoutes = () => (
  [
    <Route exact path="/" key="myp-home" component={BrokerageFlowMyp} />,
    <Redirect to="/" from="/" key="redirect" />,
  ]
);

export const aaaRoutes = () => (
  [
    <Route exact path="/" key="aaa-home" component={BrokerageFlowAaa} />,
    <Redirect to="/" from="/" key="redirect" />,
  ]
);

export const clxRoutes = () => (
  [
    <Route exact path="/" key="clx-home" component={BrokerageFlowClx} />,
    <Redirect to="/" from="/" key="redirect" />,
  ]
);

export const ndspRoutes = () => (
  [
    <Route exact path="/" key="ndsp-home" component={BrokerageFlowNdsp} />,
    <Redirect to="/" from="/" key="redirect" />,
  ]
);

export const mpmRoutes = () => (
  [
    <Route exact path="/" key="mpm-home" component={BrokerageFlowMpm} />,
    <Redirect to="/" from="/" key="redirect" />,
  ]
);

export default routes;
