import React from 'react';
import PropTypes from 'prop-types';

import { Textbox } from 'providerSite/components/inputs';
import FileUpload from './Upload';
import {
  FIELD_ABN,
  FIELD_TRADING_NAME,
  FIELD_REGISTERED_NAME,
  FIELD_IMAGE_PATH
} from './contants';


const CompanyDetailForm = ({data, onUpdate, setFile}) => {

  return (
    <>
      <Textbox
        title='Trading Name'
        defaultValue={data[FIELD_TRADING_NAME]}
        onChange={(val) => onUpdate(FIELD_TRADING_NAME, val)}
      />
      <Textbox
        title='ABN'
        defaultValue={data[FIELD_ABN]}
        onChange={(val) => onUpdate(FIELD_ABN, val)}
      />
      <Textbox
        title='Registered Name'
        defaultValue={data[FIELD_REGISTERED_NAME]}
        onChange={(val) => onUpdate(FIELD_REGISTERED_NAME, val)}
      />
      <FileUpload
        title="Profile Image"
        defaultValue={data[FIELD_IMAGE_PATH]}
        multiple={false}
        setFile={setFile}
        onDelete={() => onUpdate(FIELD_IMAGE_PATH, null)}
      />
    </>
  );
};

CompanyDetailForm.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
  setFile: PropTypes.func,
};


export default CompanyDetailForm;