import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';


const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid',
    borderColor: ({isSelected}) => isSelected ? theme.palette.common.nightBlue : theme.palette.common.lightBorder,
    borderRadius: '12px',
    height: '170px',
    width: '190px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: ({disabled}) => disabled ? theme.palette.grey[50] : theme.palette.common.white,
    boxShadow: ({disabled}) => disabled ? 'none' :
      'rgb(145 158 171 / 15%) 0px 0px 4px 0px, rgb(145 158 171 / 15%) 2px -2px 20px 0px',
    padding: '5px 10px 10px 10px',
    cursor: ({disabled}) => disabled ? 'not-allowed' : 'pointer',
    position: 'relative',
    '&:hover': {
      borderColor: ({disabled}) => !disabled && theme.palette.secondary.main,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxContainer: {
    color: theme.palette.common.nightBlue,
    fontSize: '22px',
    marginLeft: 'auto',
    visibility: ({isSelected}) =>  !isSelected && 'hidden',
  },
}));

const Card = ({ children, isSelected, onSelect, disabled }) => {

  const theme = useTheme();

  const checkboxRef = useRef();

  const classes = useStyles({isSelected: isSelected, disabled: disabled});

  const handleMouseEnter = () => {
    if (disabled) return;
    if (checkboxRef?.current) {
      checkboxRef.current.style.color = theme.palette.secondary.main;
      checkboxRef.current.style.visibility = 'visible';
    }
  };
  const handleMouseLeave = () => {
    if (disabled) return;
    if (checkboxRef?.current) {
      checkboxRef.current.style.color = theme.palette.common.nightBlue;
      if (!isSelected) checkboxRef.current.style.visibility = 'hidden';
    }
  };

  useEffect(() => {
    if ((disabled || !isSelected) && checkboxRef?.current) {
      checkboxRef.current.style.visibility = 'hidden';
    }
  }, [disabled, isSelected]);

  return (
    <div
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => !disabled && onSelect()}
    >
      <div ref={checkboxRef} className={classes.checkboxContainer}>
        <FontAwesomeIcon icon={faCheckCircle} />
      </div>
      <div className={classes.contentContainer}>
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Card;