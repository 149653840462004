import {
  UTM_CAMPAIGN_KEY,
  UTM_CONTENT_KEY,
  UTM_ID_KEY,
  UTM_MEDIUM_KEY,
  UTM_SOURCE_KEY,
  HICAPS_BILLER_ID_KEY,
} from 'providerSite/constants';

export const setIndividualAnswer = (setAnswersCb, fieldName, value) => {
  setAnswersCb(prevState => ({...prevState, [fieldName]: value}));
};

export const getSessionStorageData = () => ({
  [UTM_SOURCE_KEY]: window.sessionStorage.getItem(UTM_SOURCE_KEY),
  [UTM_MEDIUM_KEY]: window.sessionStorage.getItem(UTM_MEDIUM_KEY),
  [UTM_CAMPAIGN_KEY]: window.sessionStorage.getItem(UTM_CAMPAIGN_KEY),
  [UTM_ID_KEY]: window.sessionStorage.getItem(UTM_ID_KEY),
  [UTM_CONTENT_KEY]: window.sessionStorage.getItem(UTM_CONTENT_KEY),
  [HICAPS_BILLER_ID_KEY]: window.sessionStorage.getItem(HICAPS_BILLER_ID_KEY),
});

