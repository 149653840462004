import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
// import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  text: {
    margin: '0.5rem 0',
  },
  link: {
    fontWeight: 600,
  },
});

const ClaimProfile = ({isAuthenticated, data}) => {
  const classes = useStyles();
  if (isAuthenticated || data.claimed) return null;
  const claimLink = `/providers/claimprofile/${data.provider_uuid}`;
  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.text}>
        Is this your business? You can&nbsp;
        <Link component={RouterLink} to={claimLink} className={classes.link}>
          Claim this Profile
        </Link>&nbsp;for free.
      </Typography>
    </div>
  );
};

ClaimProfile.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(ClaimProfile);
