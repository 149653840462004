import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Card from './Card';
import Rate from './Rate';
import { getImageHeightAndSize } from 'lib/util';
import StarRating from 'components/widgets';
import defaultImage from 'assets/physiotherapist-and-boy-holding-ball-150x84.png';


const imageWidth = 100;
const { imageHeight, imageSize } = getImageHeightAndSize(imageWidth);


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  rate: {
    left: '41.5px',
    top: '-22px',
  },
  image: {
    width: imageWidth,
    height: imageHeight,
    border: '1px solid',
    borderColor: theme.palette.common.lightBorder,
    borderRadius: '10px',
    overflow: 'hidden',
  },
  ratingContainer: {
    display: 'flex',
    columnGap: '5px',
    alignItems: 'center',
    marginTop: '-2px',
  },
  reviewCount: {
    fontSize: '11px',
    fontWeight: '500',
    marginTop: '3px',
  },
  rating: {
    fontSize: '11px',
    fontWeight: '500',
  },
  title: {
    marginTop: '7px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '170px',
    overflow: 'hidden',
    textAlign: 'center',
    fontSize: '13px',
  },
}));

const ProviderCard = (
  {
    data,
    isSelected,
    isDisabled,
    disableRate,
    rateValue,
    onSelect,
    onRate,
  }) => {

  const classes = useStyles();

  const handleSelect = () => {
    data && onSelect(data.uuid);
  };

  const handleRate = value => {
    data && onRate(data.uuid, value);
  };

  return (
    <div className={classes.root}>
      {!disableRate && isSelected && (
        <Rate className={classes.rate} value={rateValue} onClick={handleRate} />)}
      <Card disabled={isDisabled} isSelected={isSelected} onSelect={handleSelect}>
        <>
          <img
            className={classes.image}
            src={data.imagePath ? data.imagePath + imageSize : defaultImage}
            alt={data.name}
          />
          <div className={classes.title}>{data.name}</div>
          <div className={classes.rating}>Rated {data.rating ? data.rating.toFixed(1) : 0}</div>
          <div className={classes.ratingContainer}>
            <StarRating rating={data.rating} size="small"/>
            <div className={classes.reviewCount}>({data.reviewCount})</div>
          </div>
        </>
      </Card>
    </div>
  );
};

ProviderCard.propTypes = {
  data: PropTypes.object,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSelect: PropTypes.func,
  onRate: PropTypes.func,
  rateValue: PropTypes.string,
  disableRate: PropTypes.bool,
};

export default ProviderCard;