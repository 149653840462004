import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { getHexWithOpacity } from 'lib/util';
import { getDefaultWidth, getRowValue, getColumnKey, getRowKey } from './utils';
import { Column, RowContainer } from './Row';
import style from 'style';


const TableBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const BodyRowContainer = styled(RowContainer)`
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    background: ${getHexWithOpacity(style.colors.palette.highlightBlue, 50)};
  }
`;

const TableBody = ({ data, columns, onRowClick }) => {
  const defaultWidth = getDefaultWidth(columns);
  const columnIndexMap = useMemo(() => columns.reduce(
    (result, nextColumn) => {
      if ( nextColumn.dataIndex ) {
        result[nextColumn.dataIndex] = {
          width: defaultWidth,
          ...nextColumn
        };
      }
      return result;
    }, { defaultIndex: { width: defaultWidth } }), [columns]);

  return (
    <TableBodyContainer>
      {data.map(row => (
        <BodyRowContainer key={`row-${getRowKey(row)}`} onClick={() => onRowClick(row)}>
          {columns.map(column => (
            <Column
              key={`column-${getRowKey(row) + getColumnKey(column)}`}
              width={columnIndexMap[column.dataIndex || 'defaultIndex'].width}
            >
              {typeof column.render === 'function' ?
                column.render(row) : getRowValue(row, column.dataIndex)}
            </Column>
          ))}
        </BodyRowContainer>
      ))}
    </TableBodyContainer>
  );
};

TableBody.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })),
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string
  })),
  onRowClick: PropTypes.func
};

export default TableBody;