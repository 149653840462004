import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputContainer from './InputContainer';
import { formatComponentId } from 'lib/util.js';

const useStyles = makeStyles(() => ({
  checkbox: {
    // '& .MuiSvgIcon-root': {
    //   width: '2rem',
    //   height: '2rem',
    // },
  },
}));

const CheckboxInput = ({ label, checkboxLabel='', value=false, helperText, onChange, disabled=false }) => {
  const classes = useStyles();
  const componentId = formatComponentId('checkbox-input');
  return (
    <InputContainer
      label={label}
      componentId={componentId}
      validationActive={false}
      helperText={helperText}
    >
      <FormControlLabel
        control={
          <Checkbox
            color="secondary"
            checked={value}
            onChange={event=>onChange(event.target.checked)}
            disabled={disabled}
            id={componentId}
            inputProps={{ 'aria-label': label }}
            className={classes.checkbox}
            size="small"
          />
        }
        label={checkboxLabel}
      />
    </InputContainer>
  );
};

CheckboxInput.propTypes = {
  label: PropTypes.node.isRequired,
  checkboxLabel: PropTypes.string,
  value: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxInput;
