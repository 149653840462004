import React, {useEffect} from 'react';
import {
  SERVICE_FOR_TYPE_MYSELF,
  TERMS_AND_CONDITION_FIELD,
  PARTICIPANT_SMS_OPT_IN_FIELD,
} from '../../../constants';

import CheckboxQuestion from '../questionComponents/CheckboxQuestion';
import Link from '@material-ui/core/Link';
import { PhoneContact } from 'components/ContactText';
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {Typography} from '@material-ui/core';
import config from 'config';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tNc: {
    fontSize: '14px',
    marginBottom: '15px',
  },
  header: {
    marginBottom: '15px',
    color: theme.palette.primary.main,
  },
}));

const TermsAndCondition = ({answers, setAnswers, hideCheckboxes}) => {
  const classes = useStyles();

  const updateAnswer = (field, value) => setAnswers(prevState => ({...prevState, [field]: value}));

  useEffect(() => {
    if (answers?.servicesFor === SERVICE_FOR_TYPE_MYSELF) {
      updateAnswer(TERMS_AND_CONDITION_FIELD, true);
    }
  }, [answers?.servicesFor]); // eslint-disable-line react-hooks/exhaustive-deps

  // conditionally include this message if a phone number is configured.  Insert a space otherwise to separate sentences.
  const phoneContact = config.phone ?
    <React.Fragment> If you need help, you can speak to our friendly team by calling <PhoneContact/>. </React.Fragment> : ' ';

  const renderCheckboxes = () => (
    <>
      <CheckboxQuestion
        text="I also want SMS notifications. Notify me by SMS which providers will contact me."
        isOptional={true}
        onChange={value => updateAnswer(PARTICIPANT_SMS_OPT_IN_FIELD, value)}
        value={answers[PARTICIPANT_SMS_OPT_IN_FIELD]}
        preChecked={true}
      />
      {answers?.servicesFor !== SERVICE_FOR_TYPE_MYSELF && (
        <CheckboxQuestion
          text="The participant consents to providing personal information to Clickability"
          onChange={value => updateAnswer(TERMS_AND_CONDITION_FIELD, value)}
          value={answers[TERMS_AND_CONDITION_FIELD]}
        />
      )}
    </>
  );

  return (
    <div>
      <Typography variant="h2" className={classes.header}>Great, almost done!</Typography>
      <div className={classes.tNc}>
        We will share the information you provided with service providers who we think can best meet your needs.
        {phoneContact}
        All information you provide will be handled in accordance with our &#8203;
        <Link component={RouterLink} to="/privacy" target="_blank" rel="noopener noreferrer">
          <b>Privacy Policy</b>
        </Link>
        . By continuing, you agree to our &#8203;
        <Link component={RouterLink} to="/terms" target="_blank" rel="noopener noreferrer">
          <b>Terms and Conditions</b>
        </Link>.
      </div>
      {!hideCheckboxes && renderCheckboxes()}
    </div>
  );
};

TermsAndCondition.propTypes = {
  answers: PropTypes.object,
  setAnswers: PropTypes.func,
  hideCheckboxes: PropTypes.bool,
};

export default TermsAndCondition;