import React, { useState } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components';
import { faImages } from '@fortawesome/pro-light-svg-icons';
import { Image } from 'antd';

import Card from './Card';


const Container = styled.div`
  .tile-container {
    margin-top: 20px;
  }

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 24%));
  grid-gap: 11px;
  .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;


const Gallery = ({ data, isLoading }) => {

  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(0);

  return (
    <Card
      title="Photo Gallery"
      icon={faImages}
      isLoading={isLoading}
      noData={_.isEmpty(data)}
    >
      <Container>
        <Image.PreviewGroup
          rootClassName="preview-image"
          preview={{
            visible,
            current,
            onVisibleChange: (visible, prevVisible) => {
              setVisible((!(prevVisible && !visible)));
            },
          }}
        >
          {data.map((item, idx) => (
            <Image
              key={idx}
              preview={{
                visible: false,
              }}
              height={210}
              width={210}
              src={item.url}
              rootClassName="image"
              style={{
                objectFit: 'cover'
              }}
              onClick={() => {
                setCurrent(idx);
                setVisible(true);
              }}
            />
          ))}
        </Image.PreviewGroup>
      </Container>
    </Card>
  );
};

Gallery.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};


export default Gallery;
