import { withStyles } from '@material-ui/core/styles';
import { OutlinedInput } from '@material-ui/core';

const CustomOutlinedInput = withStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    borderRadius: 4,
    borderWidth: '3px',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.silver}`,
    padding: 0,
    margin: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      padding: 0,
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
    },
    '& .MuiChip-root': {
      border: `2px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.common.white,
      fontSize: '0.9rem',
    },
  },
  focused: {
    borderColor: theme.palette.secondary.main,
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  input: {
    borderRadius: 0,
    padding: '10px',
    margin: 0,
    '&:-internal-autofill-selected': {
      backgroundColor: theme.palette.common.white,
    },
  },
  adornedStart: {
    alignItems: 'center',
    paddingLeft: '0.5rem',
    '& .MuiInputBase-root': {
      margin: 0,
    },
  },
}))(OutlinedInput);

export default CustomOutlinedInput;
