import React from 'react';
import {useSelector} from 'react-redux';
import { ReviewCount, StarRating } from '../widgets';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import ImagePanel from '../provider/ImagePanel';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import SpecialisationPanel from '../provider/SpecialisationPanel';
import Typography from '@material-ui/core/Typography';
import accreditedLogo from 'assets/accredited-provider-badge2.svg';
import { imageDx } from '../provider/ImagePanel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SettingsIcon from '@material-ui/icons/Settings';
import * as track from 'lib/track';

// CONFIGURATION OPTIONS
const lineClamp = 3;  // adjust number of lines of description text
const mobileLineClamp = 4;

const ListingType = PropTypes.shape({
  image_path: PropTypes.string,
  title: PropTypes.string.isRequired,
  short_description: PropTypes.string,
});

const ServiceChip = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.tealish,
    color: theme.palette.common.white,
    margin: '2px',
    fontWeight: 600,
    fontSize: '0.75rem',
  },
}))(Chip);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    '&:hover': {
      cursor: ({disableClick}) => !disableClick && 'pointer',
    },
    position: 'relative',
  },
  sponsored: {
    position: 'absolute',
    top: '-25px',
    right: 0,
    color: theme.palette.common.squash,
    fontSize: '12px',
    fontWeight: '400',
    textShadow: '1px 1px #e8e8e8',
    cursor: 'default',
    border: '1px solid',
    borderColor: theme.palette.common.squash,
    padding: '0 4px',
    borderRadius: '6px',
  },
  iconContainer: {
    marginTop: '10px',
    marginRight: '10px',
  },
  checkbox: {
    marginTop: '5px',
  },
  card: {
    textDecoration: 'none',
    marginBottom: '2rem',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: ({isSponsored}) => isSponsored ? theme.palette.common.squash : theme.palette.common.lightBorder,
    '&:hover': {
      border: ({disableClick}) => !disableClick && `1px solid ${theme.palette.primary.main}`,
    },
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '1rem',
    },
    backgroundColor: theme.palette.common.white,
    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 4px 0px, rgb(145 158 171 / 20%) 2px -2px 20px 0px',
  },
  titleText: {
    lineHeight: 1.2,
    marginBottom: '0.2rem',
    marginTop: '0.2rem',
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
    },
  },
  descriptionText: {
    //  these lines are all required for line clamping
    // ---->
    WebkitLineClamp: lineClamp,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    // <----
    padding: '0rem 0.5rem 0.2rem 1.0rem',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      WebkitLineClamp: mobileLineClamp,
      padding: '1.5rem 1rem',
    },
  },
  mobileReviewRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  filledRow: {
    display: 'flex',
    flexGrow: '1',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  coverImgCell: {
    display: 'flex',
    borderRadius: '8px',
    flex: `0 0 ${imageDx}px`,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flex: '0 1 auto',  // restore to default
    }
  },
  titleCell: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: '0.5rem 0.5rem 0.5rem 1.0rem',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    }
  },
  orgTitle: {
    lineHeight: 0.75,
    paddingLeft: '0.5rem'
  },
  serviceCell: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0.2rem',
  },
  ratingCell: {
    display: 'flex',
    paddingLeft: '1rem',
  },
  accreditationCell: {
    flex: '0 0 120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  settings: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '10px',
    marginRight: '10px',
  },
  settingsIcon: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  specialisationCell: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: `1px solid ${theme.palette.common.silver}`,
    padding: '0.5rem',
  },
  accreditedImg: {
    margin: 2,
    width: 110,
    objectFit: 'scale-down',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0',
    },
  },
}));

const titleText = (data, classes) => <Typography variant="h6" color="primary" className={classes.titleText}>{data.title}</Typography>;
const bodyText = (data, classes) => <Typography variant="body2" className={classes.descriptionText}>{data.short_description}</Typography>;
const orgTitle = (data, classes) => <Typography variant="subtitle1" className={classes.orgTitle}>{data.provider_name}</Typography>;
const accreditedImage = (data, classes) => {
  if (!data.accredited) return null;
  return (
    <img
      src={accreditedLogo}
      aria-label="Provider is accredited by Clickability"
      alt="Clikability accredited provider icon"
      className={classes.accreditedImg}
    />
  );
};

const ListingCard = ({data, isMatchingMode, matchingData, handleMatchingData, disableClick}) => {
  const history = useHistory();
  const theme = useTheme();
  const useMobileLayout = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({disableClick: disableClick, isSponsored: data?.is_sponsored});
  const endorsedSpecialisations = data.specialisations.filter(s => s.endorsements);

  const profile = useSelector(state => state.auth.profile);

  const handleCardClick = event => {
    if (disableClick) return;
    event.stopPropagation();
    const _url = `/listing/${data.slug}`;
    history.push(_url);
    track.sendEvent(track.DIRECTORY_SERVICE_OFFERING_CLICK, track.EVENT_CLICK, {
      listing_uuid: data?.uuid,
    }, [data?.provider_uuid]);
  };
  const handleSettingsClick = event => {
    event.stopPropagation();
    history.push(`/provider/${data.provider_uuid}/admin/listings`);
  };

  const handleCheckbox = checked => {
    const _data = {
      providerUuid: data.provider_uuid,
      name: data.title,
      image: data.image_path,
    };
    handleMatchingData(checked, _data);
  };

  const desktopLayout = () => {
    return (
      <div className={classes.root} onClick={handleCardClick}>
        {data?.is_sponsored && <div className={classes.sponsored}>Sponsored</div>}
        <div className={classes.card}>
          <div className={classes.row}>
            <div className={classes.coverImgCell}>
              <ImagePanel data={data} useMobileLayout={false}/>
            </div>
            {/* cell 2 contains 2 rows each with 2 columns */}
            <div className={classes.col}>
              <div className={classes.row}>
                <div className={classes.titleCell}>
                  {titleText(data, classes)}
                </div>
                <div className={classes.accreditationCell}>
                  {isMatchingMode && (
                    <Checkbox
                      className={classes.checkbox}
                      onChange={event => handleCheckbox(event.target.checked)}
                      onClick={event => event.stopPropagation()}
                      checked={data.provider_uuid in matchingData}
                      disabled={Object.keys(matchingData).length > 2 && !(data.provider_uuid in matchingData)}
                    />
                  )}
                  {accreditedImage(data, classes)}
                </div>
              </div>

              <div className={classes.orgTitle}>
                {orgTitle(data, classes)}
              </div>

              <div className={classes.row}>
                <div className={classes.ratingCell}>
                  <StarRating rating={data.rating} />
                  <ReviewCount count={data.review_count} />
                </div>
                <div className={classes.serviceCell}>
                  {data.service && <ServiceChip label={data.service}/>}
                </div>
              </div>

              <div className={classes.filledRow}>
                {bodyText(data, classes)}
                {profile?.is_site_admin && (
                  <div className={classes.settings}>
                    <Tooltip title="Edit Provider Profile">
                      <SettingsIcon onClick={handleSettingsClick} className={classes.settingsIcon}/>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
          {endorsedSpecialisations.length>0 &&
            <div className={classes.specialisationCell}>
              <SpecialisationPanel specialisations={endorsedSpecialisations}/>
            </div>
          }
        </div>
      </div>
    );
  };

  const mobileLayout = () => {
    return (
      <div className={classes.root} onClick={handleCardClick}>
        {data?.is_sponsored && <div className={classes.sponsored}>Sponsored</div>}
        <div className={classes.card}>
          <div className={classes.coverImgCell}>
            <ImagePanel data={data} useMobileLayout={true}/>
          </div>
          <div className={classes.row}>
            <div className={classes.titleCell}>
              {titleText(data, classes)}
            </div>
          </div>
          <div className={classes.titleCell}>
            {orgTitle(data, classes)}
          </div>
          <div className={classes.mobileReviewRow}>
            <div>
              <StarRating rating={data.rating} />
              <ReviewCount count={data.review_count} />
            </div>
            {data.service && <ServiceChip label={data.service}/>}
            {accreditedImage(data, classes)}
          </div>
          <div className={classes.row}>
            {bodyText(data, classes)}
          </div>
          {endorsedSpecialisations.length>0 &&
            <div className={classes.specialisationCell}>
              <SpecialisationPanel specialisations={endorsedSpecialisations}/>
            </div>
          }
        </div>
      </div>
    );
  };

  return useMobileLayout ? mobileLayout() : desktopLayout();
};

ListingCard.propTypes = {
  data: ListingType.isRequired,
  isMatchingMode: PropTypes.bool,
  matchingData: PropTypes.object,
  handleMatchingData: PropTypes.func.isRequired,
  disableClick: PropTypes.bool,
};

export default ListingCard;
