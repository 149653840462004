
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { AutoComplete as AntdAutoComplete } from 'antd';
import styled from 'styled-components';

import { ProviderAPI } from 'api';
import UseMyLocation from 'components/location/UseMyLocation';
import style from 'style';


const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  .bottom-container {
    width: 100%;
    max-width: 750px;
    padding-left: 5px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media (max-width: ${style.sizes.small}) {
      flex-direction: column;
    }
  }
  
  .use-my-location {
    margin-top: 7px;
    color: ${style.colors.secondary};
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
  }
  
  .use-my-location-button {
    div {
      font-size: 12px;
      font-weight: 700;
    }
    :hover {
      color: ${style.colors.primary};
    }
  }
  
  .use-my-location-spinner {
    color: ${style.colors.primary};
  }
  
  .use-my-location-icon {
    font-size: 15px;
    margin-top: 1px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 750px;
  box-shadow: ${style.shadow(10)};
  border: 1px solid ${style.colors.lightBorder};
  padding: 15px 20px 15px 20px;
  border-radius: 20px;
  .title {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;

const AutoComplete = styled(AntdAutoComplete)`
  margin-bottom: 5px;
  font-size: 14px;
  
  .ant-select-selector {
    border-bottom: 1px solid ${style.colors.lightBorder} !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }
  .ant-select-selection-item {
    height: auto !important;
    margin-top: -4px;
  }

  .ant-select-selection-search {
    left: 0 !important;
  }
  
  .ant-select-selection-search-input {
    font-weight: 500 !important;
    @media (max-width: ${style.sizes.small}) {
      font-size: 16px;
    }
  }
  
  @media (max-width: ${style.sizes.large}) {
    min-width: 150px;
  }
`;

const LocationSearchBox = ({ title, selectedValue, onSearchChange, onSelect, bottomRightNode }) => {

  const [locationOptions, setLocationOptions] = useState([]);
  const [locationInputValue, setLocationInputValue] = useState('');
  const [locationValue, setLocationValue] = useState(selectedValue ? selectedValue : null);

  useEffect(() => {
    if (locationInputValue.length < 2) return;
    ProviderAPI.locationAutocomplete(locationInputValue).then(r => {
      setLocationOptions(r.payload?.map(item => ({label: item, value: item})));
    });
  }, [locationInputValue]);

  const onLocationClick = location => {
    setLocationValue(location);
    onSearchChange(location);
    onSelect && onSelect();
  };

  const handleSelect = value => {
    onSearchChange(value);
    onSelect && onSelect();
  };

  return (
    <Main>
      <Container>
        <div className="title">{title}</div>
        <AutoComplete
          allowClear
          filterOption={true}
          value={locationValue}
          options={locationOptions}
          style={{width: '100%'}}
          size="large"
          onChange={val => setLocationValue(val)}
          onSearch={val => setLocationInputValue(val)}
          onSelect={handleSelect}
          placeholder="Enter a suburb or postcode"
          bordered={false}
        />
      </Container>
      <div className="bottom-container">
        <UseMyLocation
          className="use-my-location"
          classNames={{
            button: 'use-my-location-button',
            spinner: 'use-my-location-spinner',
            icon: 'use-my-location-icon',
          }}
          onSuccess={onLocationClick}
        />
        {bottomRightNode}
      </div>
    </Main>
  );
};

LocationSearchBox.propTypes = {
  title: PropTypes.string,
  selectedValue: PropTypes.string,
  onSearchChange: PropTypes.func,
  onSelect: PropTypes.func,
  bottomRightNode: PropTypes.node,
};

export default LocationSearchBox;