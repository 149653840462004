import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import LocationMap from './LocationMap.js';
import LocationList from './LocationList.js';
import SectionTitle from '../SectionTitle.js';
import { ProviderAPI } from '../../api';
import { ProviderStore } from '../../store';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '1rem 0',
  },  
  title: {
    marginBottom: '1rem',
    color: theme.palette.primary.main,  
  },
  loading: {
    width: '100%',
    padding: '1rem',
  },  
}));

const LocationPanel = ({providerUuid, isLoading, data, dispatch}) => {
  const classes = useStyles();
  const [selectedPremise, setSelectedPremise] = React.useState(0);

  // eslint-disable-next-line  
  const selectPremise = key => event => {
    setSelectedPremise(key);
  };

  const fetchData = async () => {
    dispatch(ProviderStore.isLoading('premises', true));
    try {
      const { payload } = await ProviderAPI.getPremises(providerUuid);
      dispatch(ProviderStore.setPremises(payload));      
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Skeleton animation="wave" height={400}/>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <SectionTitle title="Locations" />
      <LocationMap isLoading={isLoading} premises={data} selectedPremise={selectedPremise}/>
      <LocationList isLoading={isLoading} premises={data} onClick={selectPremise}/>
    </div>
  );
};

LocationPanel.propTypes = {
  providerUuid: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    data: state.provider.premises,
    isLoading: state.provider.premisesLoading,
  };
};

export default connect(mapStateToProps)(LocationPanel);
