import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'center',
  },
  contentContainer: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  children: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '70px',
    margin: '30px 0',
  },
  progress: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '5px',
    color: theme.palette.common.black,
  },
  title: {
    fontSize: '28px',
    lineHeight: '28px',
    fontWeight: '500',
    textAlign: 'center',
    color: theme.palette.primary.main,
    marginBottom: '20px',
  },
  buttonContainer: {
    justifySelf: 'flex-end',
    display: 'flex',
    columnGap: '20px',
    justifyContent: ({isFinalPage}) => isFinalPage ? 'center' : 'flex-end',
  },
  button: {
    width: '200px',
    borderRadius: '10px',
  },
}));

const FlowContainer = (
  {
    children,
    flow,
    providerData,
    setPagename,
    currentPage,
    next,
    prev,
    complete,
    progress,
    progressAction,
    setAnswers,
    answers,
    onComplete,
    surveyUuid,
  }) => {


  const classes = useStyles({isFinalPage: !next});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [currentPage]);

  useEffect(() => {
    providerData && setAnswers(prevState => (
      {...prevState, _providerData: providerData, _surveyUuid: surveyUuid}
    ));
  }, [providerData, surveyUuid]);


  const handleNextClick = () => {
    setPagename(next.name);
    progressAction.increase();
  };

  const handleBackClick = () => {
    setPagename(prev.name);
    progressAction.decrease();
  };

  const renderFlowControlButtons = () => {
    return (
      <>
        <Button
          className={classes.button}
          style={{width: '150px'}}
          variant="outlined"
          onClick={handleBackClick}
          disabled={!prev}
        >
          Back
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleNextClick}
          disabled={!complete}
        >
          {currentPage.submitButton ? 'Submit' : 'Next'}
        </Button>
      </>
    );
  };

  const renderExitButton = () => {
    return (
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={onComplete}
        disabled={!complete}
      >
        Exit
      </Button>
    );
  };

  const totalValidPages = flow.pages.filter(item => (!item.isHidden || !item.isHidden(answers))
    && item.progress).length;

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        {currentPage.progress && (
          <div className={classes.progress}>
            Question {progress + 1} of {totalValidPages}
          </div>
        )}
        <div className={classes.title}>{currentPage.title}</div>
        <div className={classes.children}>
          {children}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        {next ? renderFlowControlButtons() : renderExitButton()}
      </div>
    </div>
  );
};

FlowContainer.propTypes = {
  children: PropTypes.node.isRequired,
  flow: PropTypes.object.isRequired,
  providerData: PropTypes.arrayOf(PropTypes.object),
  setPagename: PropTypes.func.isRequired,
  setAnswers: PropTypes.func,
  currentPage: PropTypes.object.isRequired,
  next: PropTypes.object,
  prev: PropTypes.object,
  complete: PropTypes.bool,
  progress: PropTypes.number,
  progressAction: PropTypes.object.isRequired,
  answers: PropTypes.object,
  onComplete: PropTypes.func,
  surveyUuid: PropTypes.string,
};

export default FlowContainer;