const API_STACK = 'provider/';

export const GET_PROVIDER = API_STACK + ':uuid';
export const PATCH_PROVIDER = API_STACK + ':uuid';
export const GET_LOCATION_AUTOCOMPLETE = API_STACK + 'location/autocomplete';
export const POST_LISTING = API_STACK + ':uuid/listing';
export const GET_LISTING = API_STACK + 'listing/:uuid';
export const PATCH_LISTING = API_STACK + ':providerUuid/listing/:listingUuid';

export const GET_SEARCH_LISTINGS = API_STACK + 'listing';

export const GET_PROVIDER_IMAGE_UPLOAD_URL = API_STACK + ':uuid' + '/imageuploadurl';
export const GET_PROVIDER_GALLERY_IMAGES = API_STACK + ':uuid' + '/images/gallery';

export const GET_PROVIDER_V2 = API_STACK + 'v2/:slug';
export const GET_PROVIDER_SERVICES_WITH_METADATA_V2 = API_STACK + 'v2/:slug/services';
export const GET_PROVIDER_PREMISES = API_STACK + ':slug/premise';
export const GET_PROVIDER_SPECIALISATIONS = API_STACK + 'v2/:slug/specialisations';

export const GET_ADDRESS_AUTOCOMPLETION = API_STACK + 'address';
export const GET_LAT_LONG_FROM_ADDRESS = API_STACK + 'address/lat-long';

export const GET_ALL_PROVIDERS_FOR_OPERATION = API_STACK + 'operation/providers';
export const GET_PROVIDER_FOR_OPERATION = API_STACK + 'operation/providers/:uuid';