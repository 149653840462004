import React from 'react';
import { Route } from 'react-router-dom';
import LogsPage from './pages/Logs';

const AdminReportListPage = React.lazy(() => import('./pages/AdminReportListPage'));
const AdminReportPage = React.lazy(() => import('./pages/AdminReportPage'));
const BillingAdminPage = React.lazy(() => import('./pages/BillingAdminPage'));
const ProviderAdminPage = React.lazy(() => import('./pages/ProviderAdminPage'));
const ReviewModerationPage = React.lazy(() => import('./pages/ReviewModerationPage'));
const UserAdminPage = React.lazy(() => import('./pages/UserAdminPage'));
const BillingOverdueAdminPage = React.lazy(() => import('./pages/BillingOverdueAdminPage'));
const ServiceMatchingPage = React.lazy(() => import('./pages/ServiceMatchingPage'));
const BillingOperationsPage = React.lazy(() => import('./pages/BillingOperationsPage'));


// modules return fragments containing routes.
// passing the component as a prop ensures match params are passed in props
// see https://stackoverflow.com/questions/46197178/cannot-read-property-params-of-undefined-react-router-4

const routes = () => {
  return [
    <Route key="/siteadmin/user" exact={false} path="/siteadmin/user/:type?/:uuid?" component={UserAdminPage} />,
    <Route key="/siteadmin/reviewmoderation" exact={false} path="/siteadmin/reviewmoderation" component={ReviewModerationPage} />,
    <Route key="/siteadmin/provider" exact={false} path="/siteadmin/provider" component={ProviderAdminPage} />,
    <Route key="/siteadmin/reports" exact={true} path="/siteadmin/reports" component={AdminReportListPage} />,
    <Route key="/siteadmin/reports" exact={true} path="/siteadmin/reports/:reportName?" component={AdminReportPage} />,
    <Route key="/siteadmin/billing" exact={true} path="/siteadmin/billing" component={BillingAdminPage} />,
    <Route key="/siteadmin/billing/overdue" exact={true} path="/siteadmin/billing/overdue" component={BillingOverdueAdminPage} />,
    <Route key="/siteadmin/service-matching" exact path="/siteadmin/service-matching" component={ServiceMatchingPage} />,
    <Route key="/siteadmin/billing/operations" exact={true} path="/siteadmin/billing/operations" component={BillingOperationsPage} />,
    <Route key="/siteadmin/logs" exact={true} path="/siteadmin/logs" component={LogsPage} />,
  ];
};

export default routes;
