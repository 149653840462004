import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {
  AutoComplete as AntdAutoComplete,
  Typography,
} from 'antd';
import styled from 'styled-components';

import { TaxonomyAPI, useGetApi } from 'providerSite/api';
import style from 'style';
import QualifiedServiceIcon from 'components/QualifiedServiceIcon';
import SpinnerModal from 'components/SpinnerModal';
import InfoBar from './InfoBar';


const TEXTBOX_HEIGHT = '52px';
const FONT_SIZE = '15px';
const FREQUENT_SERVICES = [
  'Plan Management',
  'Cleaning',
  'Gardening',
  'Psychology',
];


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  height: 100%;
  max-width: 700px;
`;

const TagHeader = styled.div`
  width: 100%;
  color: ${style.colors.palette.battleshipGrey};
  font-size: 13px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 7px;
`;

const TagListContainer = styled.div`
  display: flex;
  column-gap: 10px;
  width: 100%;
`;

const AutoComplete = styled(AntdAutoComplete)`
  height: ${TEXTBOX_HEIGHT};
  font-size: ${FONT_SIZE};
  .ant-select-selector {
    height: ${TEXTBOX_HEIGHT} !important;
  }
  .ant-select-selection-search-input {
    height: ${TEXTBOX_HEIGHT} !important;
  }
  .ant-select-selection-placeholder {
    line-height: ${TEXTBOX_HEIGHT} !important;
  }
`;

const ServiceSelector = ({ defaultData, handleData }) => {

  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState(null);

  const {
    data: _serviceTaxonomy,
    isLoading: isLanguageTaxonomyLoading
  } = useGetApi(TaxonomyAPI.GET_TAXONOMY, {
    queryParams: {
      name: 'qualified_service',
    },
    staleTime: 60,   // 1 hour
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    if (!defaultData) return;
    setData(defaultData.serviceName);
    setSelectedData(defaultData);
  }, [defaultData]);

  useEffect(() => {
    handleData(selectedData);
  }, [selectedData]);

  const _getKey = index => `Option Row - ${index}`;
  const { serviceTaxonomy, serviceTaxonomyMap } = _serviceTaxonomy?.reduce((acc, item, index) => {
    acc.serviceTaxonomy.push({
      label: (
        <Row
          key={_getKey(index)}
          name={item.serviceName}
        />
      ),
      value: item.serviceName,
      data: item,
    });
    acc.serviceTaxonomyMap[item.serviceName] = item;
    return acc;
  }, {serviceTaxonomy: [], serviceTaxonomyMap: {}}) || {};

  const handleChange = value => {
    setData(value);
    setSelectedData(null);
  };

  const handleSelect = (value, option) => {
    setData(value);
    setSelectedData(option.data);
  };

  const handleTagSelect = serviceName => {
    setData(serviceName);
    setSelectedData(serviceTaxonomyMap[serviceName]);
  };

  const handleLeaving = () => {
    selectedData === null && setData(null);
  };

  return (
    <Container>
      <Typography.Title>
        Select a Service
      </Typography.Title>
      <AutoComplete
        allowClear
        filterOption={true}
        value={data}
        options={serviceTaxonomy}
        style={{width: '100%'}}
        size="large"
        onChange={handleChange}
        onSelect={handleSelect}
        onBlur={handleLeaving}
        placeholder="Physiotherapy, Support Work ..."
      />
      <TagHeader>Commonly requested</TagHeader>
      <TagListContainer>
        {FREQUENT_SERVICES.map((item, index) => (
          <Tag key={`frequent-service-${index}`} name={item} onClick={handleTagSelect}/>
        ))}
      </TagListContainer>
      <InfoBar
        style={{marginTop: '60px'}}
        text="Clickability can connect you with available services.
        Please select which service you are after and we will connect
        you with providers."
      />
      <SpinnerModal isLoading={isLanguageTaxonomyLoading} />
    </Container>
  );
};

ServiceSelector.propTypes = {
  defaultData: PropTypes.object,
  handleData: PropTypes.func,
};

const TagContainer = styled.div`
  background: rgba(33, 191, 175, 0.10);
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid rgba(33, 191, 175, 0.25);
  box-shadow: ${style.shadow(15)};
  color: rgba(33, 191, 175, 1);;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  flex-grow: 1;
  :hover {
    background: rgba(33, 191, 175, 0.20);
  }
  .icon {
    margin-top: -3px;
    margin-right: 10px;
  }
`;

const Tag = ({ name, onClick }) => {
  return (
    <TagContainer onClick={() => onClick(name)}>
      <QualifiedServiceIcon.Regular serviceName={name} className="icon" />
      {name}
    </TagContainer>
  );
};

Tag.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
};

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 38px;
  
  .icon {
    width: 20px;
  }
`;

const Row = ({ name }) => {
  return (
    <RowContainer>
      <QualifiedServiceIcon.Regular serviceName={name} className="icon" />
      <div>{name}</div>
    </RowContainer>
  );
};

Row.propTypes = {
  name: PropTypes.string,
};

export default ServiceSelector;