import _ from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { request } from 'api/http';
import config from 'config';

export * as ProviderAPI from './provider';
export * as ConnectAPI from './connect';
export * as TaxonomyAPI from './taxonomy';
export * as AuthAPI from './auth';
export * as ReviewAPI from './review';
export * as AnalyticsAPI from './analytics';
export * as BillingAPI from './billing';
export * as ServiceRequestAPI from './serviceRequest';
export * as ReportAPI from './report';


// Ref: https://tanstack.com/query/v4/docs/guides/queries
export const useGetApi = (endpoint, options={}) => {

  const params = options.params ? options.params : {};
  const queryParams = _getQueryParams(options);

  delete options.params;
  delete options.queryParams;

  // Converts stale time to minutes for easier usage
  if ('staleTimeMinute' in options)
    options['staleTime'] = options.staleTimeMinute * 1000 * 60;

  const { endpointKey, endpointUrl } = _formatEndpoint(endpoint, params);
  const queryFn = () => (
    request('GET', endpointUrl, {...(!_.isEmpty(queryParams) && {params: queryParams})})
      .then(r => camelcaseKeys(r, {deep: true}))
  );

  const selectFn = data => {
    response = {message: data.message, statusCode: data.statusCode};
    return data.payload;
  };

  let response = undefined;
  const query = useQuery({
    queryKey: [endpointKey, queryParams],
    queryFn: queryFn,
    select: selectFn,
    ...options,
  });

  query['response'] = response;

  return query;
};


// Ref: https://tanstack.com/query/v4/docs/guides/mutations
export const useMutateApi = (endpoint, method, options={}) => {

  if (!['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase()))
    throw new Error(`Invalid method: ${method} provided`);

  const queryClient = useQueryClient();

  const invalidation = options.invalidation ? options.invalidation :
    {exact: false, endpoint: undefined, params: {}};
  const params = options.params ? options.params : {};
  const queryParams = _getQueryParams(options);

  delete options.params;
  delete options.queryParams;
  delete options.invalidation;

  const { endpointKey, endpointUrl } = _formatEndpoint(endpoint, params);

  let invalidationKey = endpointKey;
  if (Array.isArray(invalidation)) {
    invalidationKey = invalidation.reduce((acc, item) => {
      const {endpointKey} = _formatEndpoint(
        item.endpoint, item.params);
      acc.push(endpointKey);
      return acc;
    }, []);
  } else if (!invalidation.exact) {
    const { endpointKey } = _formatEndpoint(
      invalidation.endpoint, invalidation.params);
    invalidationKey = endpointKey;
  }

  const mutationFn = data => {
    const _data = snakecaseKeys(data, {deep: true});
    return request(
      method.toUpperCase(),
      endpointUrl,
      {params: queryParams, body: _data})
      .then(r => camelcaseKeys(r, {deep: true}));
  };

  const successFn = (data, variables, context) => {
    options.onSuccess && options.onSuccess(data, variables, context);
    if (Array.isArray(invalidationKey)) {
      for (const key of invalidationKey) {
        queryClient.invalidateQueries([key]);
      }
    } else {
      queryClient.invalidateQueries([invalidationKey]);
    }
  };

  const mutation = useMutation({
    ...options,
    mutationFn: mutationFn,
    onSuccess: successFn,
  });

  const response = {
    message: mutation.data?.message,
    statusCode: mutation.data?.statusCode
  };

  delete mutation.data?.message;
  delete mutation.data?.statusCode;

  mutation['response'] = response;

  return mutation;
};

const _formatEndpoint = (endpoint, params) => {
  if (!endpoint || endpoint === '')
    return {endpointKey: null, endpointUrl: null};

  let _endpoint = endpoint;

  Object.keys(params).forEach(key => {
    _endpoint = _endpoint.replace(`:${key}`, params[key]);
  });

  if (_endpoint.includes(':'))
    throw new Error(`Missing params in the API endpoint: ${_endpoint}`);

  const _endpointUrl = config.api.URL + '/' + _endpoint;

  return {endpointKey: _endpoint, endpointUrl: _endpointUrl};
};

const _getQueryParams = options => {
  const _queryParams = options.queryParams ? options.queryParams : {};
  return options.skipQueryParamCaseConversion ? _queryParams :
    snakecaseKeys(_queryParams, {deep: true});
};
