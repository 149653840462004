import {
  faCarBuilding,
  faEarthOceania,
  faLaptopMobile,
  faLocationDot,
  faPeopleGroup,
  faPerson,
  faBriefcase,
  faChildReaching,
  faChild,
  faPersonCane,
  faPersonWalking,
} from '@fortawesome/pro-regular-svg-icons';


export const ACCESS_METHOD_ICON_MAP = {
  'Group': faPeopleGroup,
  'Online service': faEarthOceania,
  'Telehealth': faLaptopMobile,
  'We come to you': faCarBuilding,
  'You come to us': faLocationDot,
};


export const AGE_GROUPS_ICON_MAP = {
  'Early Childhood (0-7 years)': faChildReaching,
  'Children (8-16 years)': faChild,
  'Young people (17-21 years)': faPerson,
  'Adults (22-59 years)': faPersonWalking,
  'Mature Age (60+ years)': faPersonCane,
};

export const ORG_TYPE_ICON_MAP = {
  'Sole Trader': faPerson,
  'Company': faBriefcase,
};