import { createContext, useContext, useEffect, useRef, useState } from 'react';

import Pusher from 'pusher-js';

import { ProviderAPI } from 'api';
import config from 'config';

// Enable this to see Pusher logs on console.
// Pusher.log = msg => console.log(msg); // eslint-disable-line

export const PusherContext = createContext(null);

export const useInitPusher = () => {

  const [ pusher, setPusher ] = useState(null);

  useEffect(() => {

    const _pusher = new Pusher(config.pusher.key, {
      channelAuthorization: {
        customHandler: ({socketId, channelName}, callback) => {
          ProviderAPI.authPusher({socket_id: socketId, channel_name: channelName})
            .then(r => callback(null, {auth: r.payload.auth}))
            .catch(err => callback(err, null));
        },
      },
      cluster: config.pusher.cluster,
    });

    setPusher(_pusher);

  }, []);

  return pusher;
};


export const usePusher = (channel, event, onUpdate) => {
  const pusher = useContext(PusherContext);
  const pusherChannelRef = useRef(null);

  useEffect(() => {
    if (!pusher) return;

    const pusherChannel = pusher.subscribe(channel);
    pusherChannel.bind(event, data => {
      onUpdate(data);
    });

    pusherChannelRef.current = pusherChannel;

    return (() => {
      pusher.unsubscribe(channel);
    });
  }, [pusher]);

  const handleTrigger = data => {
    const _data = data ? data : 'trigger';
    pusherChannelRef.current.trigger(event, _data);
  };

  return {push: handleTrigger};
};
