import { useEffect, useState } from 'react';

import FormFlow from 'components/formflow/FormFlow';
import { FormFlowContainer } from 'components/formflow/default';
import PropTypes from 'prop-types';
import React from 'react';
import { ServiceRequestAPI } from 'api';
import {
  checkboxInput,
} from 'components/formflow/default/questions';
import specialisationFlows from './SpecialisationFlows';

const unimplementedFlow = {
  Container: FormFlowContainer,
  pages: [
    {
      name: 'page1',
      title: 'Not Implemented',
      narrative: 'Something something',
      questions: [
        checkboxInput({
          field: 'delclare_true',
          label: 'I declare that my answers to the following questions are true and correct, and understand that any misinformation will result in Clickability removing my endorsement.',
          isValid: (value) => value,
        }),
      ]
    }
  ]
};

const allFields = (flow) => {
  const fields = [];
  for (const page of flow.pages) {
    for (const question of page.questions) {
      fields.push(question.field);
    }
  }
  return fields;
};


const SpecialisationForm = ({
  specialisation,
  onSuccess,
  onCancel,
}) => {

  const [defaults, setDefaults] = useState({});
  const scope = `SpecialisationSelfAssessment:${specialisation.name}`;
  const flow = specialisationFlows[specialisation.name] || unimplementedFlow;

  useEffect(() => {
    const getCachedResponses = async() => {
      const fields = allFields(flow);
      const { payload } = await ServiceRequestAPI.getCachedResponses({fields, scope});
      setDefaults(payload.responses);
    };
    getCachedResponses();
  }, [specialisation, flow, scope]);

  const saveDefaults = callback => async(values) => {
    ServiceRequestAPI.setCachedResponses({values, scope});
    callback(values);
  };
  
  return (
    <FormFlow 
      flow={flow} 
      defaults={defaults}
      onCancel={onCancel}
      onComplete={saveDefaults(onSuccess)}
    />
  );
};

SpecialisationForm.propTypes = {
  specialisation: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SpecialisationForm;