import React from 'react';
import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function PasswordDialog({open, onCancel, onSubmit}) {
  const [password, setPassword] = useState('');

  useEffect(() => setPassword(''), [open]); // clear password on open and close

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Password Required</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your current password to proceed.
        </DialogContentText>
        <TextField
          autoFocus
          value={password}
          onChange={e=>setPassword(e.target.value)}
          variant="outlined"
          margin="dense"
          id="password"
          placeholder="Current Password"
          type="password"
          autoComplete="current-password"
          fullWidth
          onKeyUp={e => e.keyCode === 13 && password !== '' && onSubmit(password)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          disabled={password===''}
          onClick={()=>onSubmit(password)}
          color="primary"
          variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
