import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import {
  Avatar as AntdAvatar,
  Button as AntdButton,
  Menu as AntdMenu,
  Dropdown,
} from 'antd';

import styled from 'styled-components';
import {
  faAngleDown,
  faArrowRightFromBracket,
  faCircleBolt,
  faFaceGrin
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProviderSearch from 'components/ProviderSearch';
import { getHexWithOpacity } from 'lib/util';
import style from 'style';
import { currentProductUrl, getProductDashboardUrl } from './utils';


const Main = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  width: 100%;
  @media (max-width: ${style.screens.mobile}) {
    column-gap: 5px;
    justify-content: space-between;
    flex-direction: ${({isAuthenticated}) => !isAuthenticated && 'row-reverse'};
  }
`;

const Button = styled(AntdButton)`
  font-weight: 600;
  height: 40px;
  border: none;
  min-width: 100px;
  box-shadow: none;
  @media (max-width: ${style.screens.mobile}) {
    min-width: 80px;
    height: 32px;
  }
`;

const DashboardButton = styled(AntdButton)`
  color: ${style.colors.palette.white};
  font-weight: 500;
  font-size: 13px;
  height: 25px;
  padding: 0;
  min-width: 100px;
  box-shadow: none;
  border: none;
  margin-right: 10px;
  @media (max-width: ${style.screens.mobile}) {
    width: 100%;
    height: 30px;
  }
`;

const TextButton = styled(Button)`
  border: 1px solid;
`;

const Avatar = styled(AntdAvatar)`
  border: 2px solid ${style.colors.palette.red};
  cursor: pointer;
  transition: border 0.5s;

  &:hover {
    border-color: ${style.colors.secondary};
  }

  box-shadow: ${style.shadow(50)};
`;

const OpacityReversedIcon = styled(FontAwesomeIcon)`
  --fa-primary-opacity: 0.4;
  --fa-secondary-opacity: 1;
`;

const Menu = styled(AntdMenu)`
  background: ${style.colors.primary};
  overflow: hidden;
  top: 15px;

  .ant-dropdown-menu-item {
    min-width: 190px;
    color: ${style.colors.palette.white};
    font-weight: 500;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;

    &:hover {
      background: ${getHexWithOpacity(style.colors.palette.fauxIndigo, 40)};
    }

    &:first-child {
      background: ${style.colors.primary};;
      cursor: default;
      border-bottom: 1px solid;
      border-color: ${getHexWithOpacity(style.colors.secondary, 10)};
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }

  .ant-dropdown-menu-item-icon {
    margin-right: 18px;
    font-size: 18px;
    margin-top: -4px;
  }
`;

const ViewProfileLink = styled(Link)`
  font-size: 13px;
  color: ${style.colors.secondary};
  :hover {
    color: ${style.colors.palette.white};
  }
`;

const AuthMenu = ({ userProfile = {}, isAuthenticated, hideDashboardButton, hideProfile }) => {

  const isMobile = useMediaQuery({maxWidth: style.screens.mobile});
  const [showSubMenu, setShowSubMenu] = useState(true);
  const history = useHistory();
  const currentUrl = currentProductUrl();
  const currentProductName = currentUrl && currentUrl.slice(1);
  const goal = currentUrl === '/connect' ? 'connect' : 'growth';

  const renderNonAuthMenu = () => (
    <>
      <TextButton
        ghost
        onClick={() => history.push('/providers/login', {
          redirectTo: currentUrl === '/connect' ? '/connect/dashboard' : null
        })}
      >
        Login
      </TextButton>
      <Button type="primary" onClick={() => history.push('business/signup', {goal})}>
        Sign Up
      </Button>
    </>
  );

  const renderAuthMenu = () => {

    const handleDashboardClick = () => {
      const url = getProductDashboardUrl(currentProductName, userProfile);
      history.push(url, {from: currentProductName});
    };

    const getInitials = () => {
      if (userProfile.first_name && userProfile.last_name)
        return (userProfile.first_name[0] + userProfile.last_name[0]).toUpperCase();
      if (userProfile.first_name && userProfile.first_name.length > 1)
        return userProfile.first_name.slice(0, 2).toUpperCase();
      if (userProfile.last_name && userProfile.last_name.length > 1)
        return userProfile.last_name.slice(0, 2).toUpperCase();
      return userProfile.username.slice(0, 2).toUpperCase();
    };

    return (
      <>
        {!hideDashboardButton && (
          <DashboardButton
            type="primary"
            onClick={handleDashboardClick}
          >
            Dashboard
          </DashboardButton>
        )}
        {!hideProfile && (
          <Dropdown
            overlay={authenticatedMenu(userProfile)}
            trigger={isMobile ? ['click'] : ['hover']}
          >
            <Avatar
              size="large"
              style={{backgroundColor: style.colors.palette.red}}
            >
              {getInitials()}
            </Avatar>
          </Dropdown>
        )}
      </>
    );
  };

  const authenticatedMenu = (profile) => {
    let subMenu = [];
    let siteAdminMenu = [];
    if (showSubMenu) {
      subMenu = [
        {
          key: '3',
          label: (
            <Link
              to={getProductDashboardUrl('accreditation', userProfile)}
              style={{paddingLeft: '10px'}}
            >
              Accreditation
            </Link>
          ),
        },
        {
          key: '4',
          label: (
            <Link
              to={{
                pathname: getProductDashboardUrl('connect', userProfile),
                state: {from: 'connect'}
              }}
              style={{paddingLeft: '10px'}}
            >
              Connect
            </Link>
          ),
        },
      ];
    }
    if (profile.is_site_admin) {
      siteAdminMenu = [
        {
          key: '6',
          label: (
            <Link to="/siteadmin/user">
              Site Admin
            </Link>
          ),
          icon: <OpacityReversedIcon icon={faFaceGrin}/>,
        },
        {
          key: '7',
          label: (
            <Link to="/siteadmin/service-matching">
              Service Matching
            </Link>
          ),
          icon: <OpacityReversedIcon icon={faCircleBolt}/>,
        },
        {
          key: '8',
          label: (
            <div onClick={event => event.stopPropagation()}>
              <ProviderSearch
                placeholder='Providers...'
                onClick={(uuid) => {
                  const _url = currentProductName === 'connect' ?
                    `/connect/${uuid}/dashboard` : `/provider/${uuid}/admin/profile`;
                  history.push(_url);
                }}
              />
            </div>
          )
        }
      ];
    }
    return (
      <Menu
        items={[
          {
            key: '1',
            label: (
              <div>
                <div>
                  {`${profile.first_name} ${profile.last_name}`}
                </div>
                <ViewProfileLink
                  to={{
                    pathname: '/providers/profile',
                    state: {from: currentProductName}}}
                >
                  View Profile
                </ViewProfileLink>
              </div>

            ),
          },
          {
            key: '2',
            label: (
              <div onClick={event => {
                event.stopPropagation();
                setShowSubMenu(prevShow => !prevShow);
              }}>
                <span>
                  Switch to
                </span>
                <span style={{marginLeft: '10px'}}>
                  <FontAwesomeIcon icon={faAngleDown}/>
                </span>

              </div>
            ),
          },
          ...subMenu,
          {
            key: '5',
            label: (
              <Link to="/logout">
                Log out
              </Link>
            ),
            icon: <FontAwesomeIcon icon={faArrowRightFromBracket}/>,
          },
          ...siteAdminMenu
        ]}
      />
    );
  };

  return (
    <Main isAuthenticated={isAuthenticated}>
      {isAuthenticated ? renderAuthMenu() : renderNonAuthMenu()}
    </Main>
  );
};

AuthMenu.propTypes = {
  userProfile: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  hideDashboardButton: PropTypes.bool,
  hideProfile: PropTypes.bool,
};


export default AuthMenu;
