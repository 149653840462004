import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from 'style';


const Main = styled.div`
  scroll-margin-top: 20px;
  width: 100%;
`;

const Header = styled.div`
  margin-left: 5px;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  column-gap: 7px;
  align-items: center;
  .title {
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2));
  }
  .icon {
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2));
    font-size: 16px;
    margin-bottom: 2px;
  }
`;

const Container = styled.div`
  border-radius: ${style.borderRadius.large};
  border: 1px solid;
  border-color: ${({$hasError}) => $hasError ? style.colors.error : style.colors.lightBorder};
  box-shadow: ${style.shadow(15)};
  padding: 10px 20px 20px 20px;
  width: 100%;
`;

const Card = (
  {
    children,
    title,
    icon,
    error,
    ...props
  }) => {

  return (
    <Main>
      <Header>
        {icon && <FontAwesomeIcon className="icon" icon={icon} />}
        <div className="title">{title}</div>
      </Header>
      <Container {...props} $hasError={error}>
        {children}
      </Container>
    </Main>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.object,
};


export default Card;

