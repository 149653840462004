import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from './InputLabel';
import Helper from './Helper';
import ValidationMark from './ValidationMark';
import ValidationErrorText from './ValidationErrorText';

const useStyles = makeStyles({
  root: {
    marginBottom: '10px',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
});

const InputContainer = ({ label, validationActive, errorText, componentId, helperText, children, ...InputLabelProps }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <InputLabel
          value={label}
          htmlFor={componentId}
          {...InputLabelProps}
        />
        <Helper helperText={helperText} />
      </div>
      <div className={classes.content}>
        {children}
        <ValidationMark
          active={validationActive}
          errorText={errorText}
        />
      </div>
      <ValidationErrorText
        active={validationActive}
        errorText={errorText}
      />
    </div>
  );
};

InputContainer.propTypes = {
  label: PropTypes.node,
  validationActive: PropTypes.bool,
  errorText: PropTypes.string,
  componentId: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default InputContainer;
