import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel as BaseInputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  text: {
    fontSize: '15px',
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
  labelContainer: {
    width: '100%',
  },
}));

const InputLabel = ({ value, htmlFor }) => {
  const classes = useStyles();
  return (
    <div className={classes.labelContainer}>
      <BaseInputLabel htmlFor={htmlFor}>
        <div className={classes.text}>
          {value}
        </div>
      </BaseInputLabel>
    </div>
  );
};

InputLabel.propTypes = {
  value: PropTypes.node,
  htmlFor: PropTypes.string,
};

export default InputLabel;
