import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import {
  MODALITY_EXTENSION_QUESTION_MAP,
  MODALITY_QUESTION_MAP,
} from '../modalityMappers';
import style from 'style';


const Container = styled.div`
  max-width: 500px;
  width: calc(50% - 80px);
  flex-grow: 1;
  
  @media (max-width: ${style.sizes.small}) {
    width: 100%;
  }
`;


const ServiceSpecificQuestions = ({ categoryName, setAnswer }) => {
  let serviceQuestions = [...MODALITY_QUESTION_MAP[categoryName]];
  if (categoryName in MODALITY_EXTENSION_QUESTION_MAP) {
    const extraQuestions = [...MODALITY_EXTENSION_QUESTION_MAP[categoryName]];
    serviceQuestions = [...serviceQuestions, ...extraQuestions];
  }

  const questions = [];
  for (const question of serviceQuestions) {
    const Component = question.Component;
    questions.push(
      <Container key={`${question.field}-div`}>
        <Component key={question.field} {...question} onChange={setAnswer(question.field)} />
      </Container>
    );
  }
  return <>{questions}</>;
};

ServiceSpecificQuestions.propTypes = {
  categoryName: PropTypes.string,
  setAnswer: PropTypes.func,
};

export default ServiceSpecificQuestions;

