import React from 'react';
import config from 'config';
import style from 'style';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { currentProductUrl } from './utils';

const Main = styled.div`
  display: flex;
  align-items: center;
  color: ${style.colors.palette.white};
  padding: 0 20px;
  column-gap: 20px;
  font-weight: 500;
  margin-top: 3px;
`;

const MenuText = styled.div`
  cursor: pointer;
  &:hover {
    color: ${style.colors.secondary};
  }
`;

const Menu = () => {

  const history = useHistory();
  const currentUrl = currentProductUrl();
  const faqLink = currentUrl === '/connect' ?
    config.link.connectFaq : config.link.providerFaq;
  return (
    <Main>
      <MenuText onClick={() => window.open(faqLink)}>
        FAQ
      </MenuText>
      <MenuText onClick={() => history.push('/contact')}>
        Contact us
      </MenuText>
    </Main>
  );
};


export default Menu;
