import { Redirect, Route } from 'react-router-dom';

import ActivatePage from './ActivatePage';
import ActivationSwitch from './components/ActivationSwitch';
import LoginPage from './LoginPage';
import LogoutPage from './LogoutPage';
import OAuthActivatePage from './OAuthActivatePage';
import OAuthLoginPage from './OAuthLoginPage';
import PasswordConfirmationPage from './PasswordConfirmationPage';
import PasswordRecoveryPage from './PasswordRecoveryPage';
import PasswordResetPage from './PasswordResetPage';
import PostSignupPage from './PostSignupPage';
import ProviderActivatePage from './ProviderActivatePage';
import ProviderCallbackPage from './ProviderCallbackPage';
import ProviderClaimProfilePage from './ProviderClaimProfilePage2';
import ProviderInvitePage from './ProviderInvitePage';
import ProviderLoginPage from './ProviderLoginPage';
import ProvidersProfilePage from './ProviderProfilePage';
import React from 'react';
import SignUpPage from './SignUpPage';
import VerifyEmailPage from './VerifyEmailPage';
import VerifyPassportEmailPage from './VerifyPassportEmailPage';

// TODO: rename to replace ProviderSignupPage
// TODO: rename to replace ProviderClaimProfilePage

// modules return fragments containing routes.

const participant = (Component) => {
  const hoc = (props) => <Component isProvider={false}  {...props}/>;
  hoc.displayName = 'participant';
  return hoc;
};
const provider = (Component) => {
  const hoc = (props) => <Component isProvider={true} {...props}/>;
  hoc.displayName = 'provider';
  return hoc;
};

const routes = () => {
  return [
    // participant only
    <Route key="/login" exact path="/login" component={LoginPage}/>,
    <Route key="/oauth/login" exact path="/oauth/login" component={OAuthLoginPage}/>,

    <Route key="/signup" exact path="/signup" component={SignUpPage}/>,
    <Route key="/activate" exact path="/activate" component={ActivatePage}/>,
    <Route key="/password/recover" exact path="/password/recover" component={participant(PasswordRecoveryPage)}/>,
    <Route key="/password/confirm" exact path="/password/confirm" component={PasswordConfirmationPage}/>,
    <Route key="/password/reset" exact path="/password/reset" component={participant(PasswordResetPage)}/>,
    // <Route key="/profile" exact path="/profile" component={ProfilePage}/>,
    // provider only
    <Route key="/passport/verifyemail" exact path="/passport/verifyemail" component={VerifyPassportEmailPage} />,
    <Route key="/verifyemail" exact path="/verifyemail" component={VerifyEmailPage}/>,
    <Route key="/providers/login" exact path="/providers/login" component={ProviderLoginPage}/>,
    <Redirect to="/business/signup" exact from="/providers/signup" key="provider-signup-redirect" />,
    <Route key="/providers/claimprofile" exact path="/providers/claimprofile/:uuid" component={ProviderClaimProfilePage}/>,
    <Route key="/providers/activate" exact path="/providers/activate" component={ProviderActivatePage}/>,
    <Route key="/providers/password/recover" exact path="/providers/password/recover" component={provider(PasswordRecoveryPage)}/>,
    <Route key="/providers/password/confirm" exact path="/providers/password/confirm" component={PasswordConfirmationPage}/>,
    <Route key="/providers/password/reset" exact path="/providers/password/reset" component={provider(PasswordResetPage)}/>,
    <Route key="/providers/profile" exact path="/providers/profile" component={ProvidersProfilePage}/>,
    <Route key="/providers/invite" exact path="/providers/invite" component={ProviderInvitePage}/>,
    // callback isn't currently used, will be used for OAuth signin
    <Route key="/providerauth/callback" exact path="/providerauth/callback" component={ProviderCallbackPage}/>,
    // shared between participant and provider
    <Route key="/signup/confirm" exact path="/signup/confirm" component={PostSignupPage}/>,
    <Route key="/logout" exact path="/logout" component={LogoutPage}/>,
  ];
};

export default routes;

export const ActivationRoute = (subroutes) =>
  <Route key="activation" render={()=><ActivationSwitch routes={subroutes} render={()=><OAuthActivatePage/>}/>}/>;
