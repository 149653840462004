import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Collapse, Input, Button } from 'antd';
import ReactJson from 'react-json-view';

import SiteAdmin from '../components/SiteAdmin';
import Page from 'components/Page';
import withAdminOnly from '../components/AdminOnly';
import { AnalyticsAPI, useGetApi } from 'providerSite/api';


const Container = styled.div`
  width: 100%;
  min-height: 70vh;
`;


const LogsPage = () => {

  const [ logApiId, setLogApiId ] = useState();
  const [ isLogApiReady, setIsLogApiReady ] = useState(false);

  const { data: logApiData, isFetching: isLogApiDataFetching } = useGetApi(AnalyticsAPI.GET_LOG_API, {
    params: {
      id: logApiId,
    },
    enabled: isLogApiReady,
    onSuccess: () => setIsLogApiReady(false),
  });

  const [ logEventId, setLogEventId ] = useState();
  const [ isLogEventReady, setIsLogEventReady ] = useState(false);

  const { data: logEventData, isFetching: isLogEventDataFetching } = useGetApi(AnalyticsAPI.GET_LOG_EVENT, {
    params: {
      id: logEventId,
    },
    enabled: isLogEventReady,
    onSuccess: () => setIsLogEventReady(false),
  });

  return (
    <Page title="Logs">
      <SiteAdmin>
        <Container>
          <Collapse accordion>
            <Collapse.Panel key={1} header="Log API">
              <LogExplorer
                id={logApiId}
                data={logApiData}
                onChange={setLogApiId}
                onClick={setIsLogApiReady}
                isLoading={isLogApiDataFetching}
              />
            </Collapse.Panel>
            <Collapse.Panel key={2} header="Log Event">
              <LogExplorer
                id={logEventId}
                data={logEventData}
                onChange={setLogEventId}
                onClick={setIsLogEventReady}
                isLoading={isLogEventDataFetching}
              />
            </Collapse.Panel>
          </Collapse>
        </Container>
      </SiteAdmin>
    </Page>
  );
};

const LogExplorerContainer = styled.div`
  .display {
    margin-top: 15px;
    padding-left: 10px;
  }
`;

const LogExplorer = ({ id, data, onChange, onClick, isLoading }) => {

  return (
    <LogExplorerContainer>
      <Input.Group compact>
        <Input
          style={{
            width: '100px',
          }}
          allowClear
          placeholder="Id"
          onChange={e => onChange(e.target.value)}
        />
        <Button
          type="primary"
          onClick={() => id && onClick(true)}
          loading={isLoading}
          disabled={!id}
        >
          Retrieve
        </Button>
      </Input.Group>
      {data && (
        <div className="display">
          <ReactJson src={data} collapseStringsAfterLength={70} collapsed={1}/>
        </div>
      )}
    </LogExplorerContainer>
  );
};


LogExplorer.propTypes = {
  id: PropTypes.number,
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};


export default withAdminOnly(LogsPage);
