import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import MUIModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles( theme =>({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
  },
}));

const Modal = ({children, isOpen, modalClassName, paperClassName, backdropClassName, ...props}) => {

  const classes = useStyles();

  return (
    <MUIModal
      className={classNames(classes.modal, modalClassName)}
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 500, className: backdropClassName}}
      {...props}
    >
      <Fade in={isOpen}>
        <div className={classNames(classes.paper, paperClassName)}>
          {children}
        </div>
      </Fade>
    </MUIModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  modalClassName: PropTypes.string,
  paperClassName: PropTypes.string,
  backdropClassName: PropTypes.string,
};

export default Modal;