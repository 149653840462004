import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import InputContainer from './InputContainer';
import { formatComponentId } from 'lib/util.js';

// this is how we override the US-centric date formatting at the top of the month calendar.
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return date.format('D MMMM', { locale: this.locale });
  }
}

const baseStyles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'centre',
  },
  picker: {
    width: '48%',
    padding: 0,
    margin: 0,
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiInputBase-root': {
      margin: 0,
      borderRadius: 4,
      borderWidth: '3px',
      '& .MuiInputBase-input': {
        padding: '10px 12px',
      },
    },
    '& .MuiIconButton-root': {
      padding: '3px',
      marginRight: '4px',
      '& .MuiSvgIcon-root': {
        fontSize: '1.4rem',
      },
    },
  },
};

const regularStyle = makeStyles(theme => ({
  ...baseStyles,
  picker: {
    ...baseStyles.picker,
    '& .MuiInputBase-root': {
      margin: 0,
      borderRadius: 4,
      borderWidth: '3px',
      border: `1px solid ${theme.palette.common.silver}`,
      '& .MuiInputBase-input': {
        padding: '10px 12px',
      },
    },
    '& .MuiInputBase-root.Mui-focused': {
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const errorStyle = makeStyles(theme => ({
  ...baseStyles,
  picker: {
    ...baseStyles.picker,
    '& .MuiInputBase-root': {
      margin: 0,
      borderRadius: 4,
      borderWidth: '3px',
      border: `1px solid ${theme.palette.error.main}`,
      '& .MuiInputBase-input': {
        padding: '10px 12px',
      },
    },
    '& .MuiInputBase-root.Mui-focused': {
      borderColor: theme.palette.error.main,
    },
    '& .MuiTypography-colorError': {
      display: 'none',
    },
  },
}));


const DateTimeInput = ({ label, value, onChange, errorText, helperText, format='DD/MM/yyyy' }) => {
  const [validationActive, setValidationActive] = useState(false);
  const error = validationActive && errorText !== undefined && errorText !== '';
  const classes = error ? errorStyle() : regularStyle();
  const componentId = formatComponentId('datetime-input', label);
  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils}>
      <InputContainer
        label={label}
        componentId={componentId}
        validationActive={validationActive}
        errorText={errorText}
        helperText={helperText}
      >
        <Grid container justify="space-between">
          <KeyboardDatePicker
            className={classes.picker}
            value={value === undefined || value === '' ? null : value}
            onChange={onChange}
            onBlur={()=>setValidationActive(true)}
            format={format}
            placeholder="DD/MM/YYYY"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardTimePicker
            className={classes.picker}
            margin="normal"
            value={value === undefined || value === '' ? null : value}
            onChange={onChange}
            placeholder="MM:HH AM"
            onBlur={()=>setValidationActive(true)}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </Grid>
      </InputContainer>
    </MuiPickersUtilsProvider>
  );
};

DateTimeInput.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.object,
  errorText: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
};

export default DateTimeInput;
