import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';
import {
  AutoComplete as AntdAutoComplete,
  Typography,
  Avatar,
  Divider,
} from 'antd';

import { ConnectAPI, useGetApi } from 'providerSite/api';
import style from 'style';
import AddButton from '../../../components/AddButton';
import InfoBar from './InfoBar';


const TEXTBOX_HEIGHT = '52px';
const FONT_SIZE = '15px';


const Container = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 700px;
`;

const AutoComplete = styled(AntdAutoComplete)`
  height: ${TEXTBOX_HEIGHT};
  font-size: ${FONT_SIZE};
  .ant-select-selector {
    height: ${TEXTBOX_HEIGHT} !important;
  }
  .ant-select-selection-search-input {
    height: ${TEXTBOX_HEIGHT} !important;
  }
  .ant-select-selection-placeholder {
    line-height: ${TEXTBOX_HEIGHT} !important;
  }
`;


const ParticipantSelector = ({ defaultData, handleData }) => {

  const { uuid: providerUuid } = useParams();
  const [data, setData] = useState();
  const [query, setQuery] = useState(' ');
  const [selectedData, setSelectedData] = useState(null);
  const history = useHistory();

  const { data: _participantData } = useGetApi(ConnectAPI.GET_PARTICIPANTS, {
    params: {
      providerUuid: providerUuid,
    },
    queryParams: {
      page: 1,
      search: query,
      page_size: 10,
    }
  });

  useEffect(() => {
    if (!defaultData) return;
    setData(defaultData.name);
    setSelectedData(defaultData);
  }, [defaultData]);

  useEffect(() => {
    handleData(selectedData);
  }, [selectedData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const _getKey = index => `Option Row - ${index}`;
  const participantData = _participantData?.data.map((item, index) => ({
    label: (
      <Row
        key={_getKey(index)}
        firstName={item.firstName}
        lastName={item.lastName}
        location={item.location}
      />
    ),
    value: item.name,
    data: item,
    key: _getKey(index),
  }));

  const onSearch = searchText => {
    setQuery(searchText);
  };

  const handleChange = value => {
    setData(value);
    setSelectedData(null);
  };

  const handleSelect = (value, option) => {
    setData(value);
    setSelectedData(option.data);
  };

  const handleLeaving = () => {
    selectedData === null && setData(null);
  };

  const handleCreateNewParticipant = () => {
    history.push('participants?item=new-participant', {inServiceRequestFlow: true});
  };

  return (
    <Container>
      <Typography.Title>
        Select a Participant
      </Typography.Title>
      <AutoComplete
        allowClear
        value={data}
        options={participantData}
        style={{width: '100%'}}
        size="large"
        onSearch={onSearch}
        onChange={handleChange}
        onSelect={handleSelect}
        onBlur={handleLeaving}
        placeholder="Search participants ..."
      />
      <Divider style={{margin: '30px 0'}} plain>OR</Divider>
      <AddButton
        text="Create a new participant"
        onClick={handleCreateNewParticipant}
      />
      <InfoBar
        style={{marginTop: '50px'}}
        text="Participant's details will be used to pre-fill the service request.
        You will be able to modify details in-place if needed."
      />
    </Container>
  );
};

ParticipantSelector.propTypes = {
  defaultData: PropTypes.object,
  handleData: PropTypes.func,
};

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 38px;
`;

const LocationContainer = styled.div`
  margin-left: auto;
  color: ${style.colors.palette.battleshipGrey};
  font-size: 13px;
`;

const Row = ({ firstName, lastName, location }) => {
  const name = `${firstName} ${lastName}`;
  return (
    <RowContainer>
      <Avatar
        style={{backgroundColor: '#87d068'}}
      >
        {`${firstName[0]}${lastName[0]}`}
      </Avatar>
      <div>{name}</div>
      <LocationContainer>{location}</LocationContainer>
    </RowContainer>
  );
};

Row.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  location: PropTypes.string,
};

export default ParticipantSelector;