import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import Page from 'providerSite/components/Page';
import SideNav from './SideNav';
import { ConnectAPI, useGetApi } from 'providerSite/api';
import EmailResentNotification from 'modules/admin/components/EmailResentNotification';
import { AuthStore } from 'store';



const Container = styled.div`
  display: flex;
  height: 100%;
  column-gap: 10px;
`;

const PageContainer = styled.div`
  flex-grow: 2;
  padding: 10px;
  margin: 50px 0;
  min-height: 75vh;
`;

const ConnectDashboard = ({ children, title, navSize, containerClass, ...props }) => {
  const { uuid: providerUuid } = useParams();
  const profile = useSelector(state => state.auth.profile);
  const dispatch = useDispatch();
  const [ pulseItems, setPulseItems ] = useState(new Set([]));
  const { data: connectNotifications } = useGetApi(ConnectAPI.GET_NOTIFICATIONS, {
    params: {
      providerUuid: providerUuid,
    },
  });

  useEffect(() => {
    dispatch(AuthStore.refreshProfile());
  }, []);

  useEffect(() => {
    if (!connectNotifications) return;
    setPulseItems(prevState => {
      if (connectNotifications.notifications) {
        prevState.add('connect');
      } else {
        prevState.delete('connect');
      }
      return new Set([...prevState]);
    });
  }, [connectNotifications]);

  return (
    <Page title={title}>
      {profile?.activated || <EmailResentNotification profile={profile}/>}
      <Container className={containerClass}>
        <SideNav navSize={navSize} pulseItems={pulseItems} />
        <PageContainer {...props}>
          {children}
        </PageContainer>
      </Container>
    </Page>
  );
};

ConnectDashboard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  navSize: PropTypes.string,
  containerClass: PropTypes.string,
};


export default ConnectDashboard;
