import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Lottie from 'lottie-react';

import LocationSearchBox from '../../components/LocationSearchBox';
import animationAsset from 'assets/map-navigation.json';
import {
  EXTRA_PROVIDER_COUNT_FIELD,
  TELEHEALTH_FIELD,
  ONLINE_SERVICE_FIELD,
  ANSWER_YES,
  ANSWER_NO,
} from '../../../constants';
import { isTelehealthAllowed } from '../../utils';
import TelehealthOnlineService from './TelehealthOnlineService';


const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  opacity: ${({$isDomLoaded}) => $isDomLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  
  .lottie-container {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
  }
`;


const Location = ({ value, onChange, scrollToFlowControlButton, answers, updateAnswer }) => {

  const [isDomLoaded, setIsDomLoaded] = useState(false);

  const renderBottomRightNode = () => {
    const count = answers[EXTRA_PROVIDER_COUNT_FIELD];
    const field = isTelehealthAllowed(answers) ? TELEHEALTH_FIELD : ONLINE_SERVICE_FIELD;

    return (
      <TelehealthOnlineService
        isTelehealthAllowed={isTelehealthAllowed(answers)}
        checked={answers[field] === ANSWER_YES}
        onChange={e => updateAnswer(field, e.target.checked ? ANSWER_YES : ANSWER_NO)}
        count={count}
      />
    );
  };

  return (
    <Container $isDomLoaded={isDomLoaded}>
      <div className="lottie-container">
        <Lottie
          loop
          animationData={animationAsset}
          onDOMLoaded={() => setIsDomLoaded(true)}
          style={{width: '280px', marginBottom: '20px'}}
        />
      </div>
      <LocationSearchBox
        title={'Enter the service location'}
        onSearchChange={onChange}
        selectedValue={value}
        onSelect={scrollToFlowControlButton}
        bottomRightNode={renderBottomRightNode()}
      />
    </Container>
  );
};

Location.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  scrollToFlowControlButton: PropTypes.func.isRequired,
  answers: PropTypes.object,
  updateAnswer: PropTypes.func.isRequired,
};

export default Location;